import React, {useEffect, useState} from 'react';
import {BackButton, Header, SubNavigationDropdown} from './';
import {Button, DropdownItem, Row, Dropdown, DropdownMenu, DropdownToggle} from 'reactstrap';
import {useHistory, useParams, Redirect} from "react-router-dom";
import {constants} from "../utils";
import {api} from "../helpers";

export default function ManufacturerAdminHeader(props) {
    const [loading, setLoading] = useState(false);
    const [buildFeature, setBuildFeature] = useState(false);
    const [buildFeaturePreview, setBuildFeaturePreview] = useState(false);
    const [redirectTo, setRedirectTo] = useState('');

    const history = useHistory();
    const {id} = useParams();
    const mfgId = id
      ? id
      : props.mfgId;

    const PATHNAMES = constants.PATH_NAMES;

    const getMfgEnableBuildStatus = () => {
      api.fetch(`manufacturer/MfgHasBuildFeature/${mfgId}`).then(r => {
        if(!r.data) return;
        setBuildFeature(r.data.enableBuildFeature);
        setBuildFeaturePreview(r.data.enableBuildFeaturePreview);
      });
    };

    useEffect(getMfgEnableBuildStatus, []);

    if (redirectTo) {
      return (<Redirect to={redirectTo} />);
    }

    const isDetailsRoute = window.location.href.match(/\/admin\/manufacturer\/\d+$/);
    return (
    <Header toolbar admin mfgName={props.manufacturerName} loading={loading} mfgAdmin>
      <div className='d-flex justify-content-center me-0'>
        {isDetailsRoute
          ? null
          : (<Button active={props.details} className='cf-sidebar' 
                onClick={() => setRedirectTo(`/admin/manufacturer/${mfgId}`)}>
              Details
            </Button>)
        }
          <SubNavigationDropdown buttonText='Relationships'>
              <DropdownItem onClick={() => setRedirectTo(`/admin/manufacturer/${mfgId}/companies`)}>
                Companies
              </DropdownItem>
              <DropdownItem onClick={() => setRedirectTo(`/admin/manufacturer/${mfgId}/stores`)}>
                Stores
              </DropdownItem>
              <DropdownItem onClick={() => setRedirectTo(`/admin/manufacturer/${mfgId}/users`)}>
                Users
              </DropdownItem>
          </SubNavigationDropdown>
          <SubNavigationDropdown buttonText='Builds'>
              <DropdownItem  onClick={() => setRedirectTo(`/admin/manufacturer/${mfgId}/add-ons`)}>
                Add Ons
              </DropdownItem>
              <DropdownItem onClick={() => setRedirectTo(`/admin/manufacturer/${mfgId}/bundles`)}>
                Bundles
              </DropdownItem>
              {buildFeature || buildFeaturePreview
                ? (<>
                  <DropdownItem onClick={() => setRedirectTo(`/admin/manufacturer/${mfgId}/build-steps`)}>
                    Build Steps
                  </DropdownItem>
                  <DropdownItem onClick={() => setRedirectTo(`/admin/manufacturer/${mfgId}/builders`)}>
                    Builders
                  </DropdownItem>
                  <DropdownItem onClick={() => setRedirectTo(`/admin/manufacturer/${mfgId}/inspections`)}>
                    Inspections
                  </DropdownItem></>) 
                : null
              }
          </SubNavigationDropdown>
          <SubNavigationDropdown buttonText='Product Options'>
              <DropdownItem onClick={() => setRedirectTo(`/admin/manufacturer/${mfgId}/products`)}>
                Products
              </DropdownItem>
              <DropdownItem onClick={() => setRedirectTo(`/admin/manufacturer/${mfgId}/unit-types`)}>
                Materials
              </DropdownItem>
              <DropdownItem onClick={() => setRedirectTo(`/admin/manufacturer/${mfgId}/colors`)}>
                Colors
              </DropdownItem>
          </SubNavigationDropdown>
      </div>
    </Header>
    );
}