import React, { useEffect, useMemo, useRef, useState } from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import SignaturePad from "react-signature-canvas";
import { useAppStore } from "../hooks";
import { BsFillXCircleFill } from "react-icons/bs";
import { SIGNATURE_TYPES } from "../constants";
import { dataURLtoBlob } from "../utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import useWindowSize from "../hooks/useWindowSize";

export default function SignatureModal({ completeSignature, handleSaveSignature }) {
  const { width, height } = useWindowSize();
  const MOBILE_SIZE = (width <= 768) || (height <= 450);
  const IS_LANDSCAPE = window.matchMedia("(orientation: landscape)").matches;
  const [createNewSign, setCreateNewSign] = useState(false);
  const [isSignatureDrawn, setIsSignatureDrawn] = useState(false);

  const isOpen = useAppStore((x) => x.signatureModalOpen);
  const setIsOpen = useAppStore((x) => x.setSignatureModalOpen);
  const signatureDataUrls = useAppStore((x) => x.signatureDataUrls);
  const setSignatureDataUrls = useAppStore((x) => x.setSignatureDataUrls);
  const initialDataUrls = useAppStore((x) => x.initialDataUrls);
  const setInitialDataUrls = useAppStore((x) => x.setInitialDataUrls);
  const selectedSignatureIndex = useAppStore((x) => x.selectedSignatureIndex);
  const setSelectedSignatureIndex = useAppStore(
    (x) => x.setSelectedSignatureIndex
  );
  const signatureLocations = useAppStore((x) => x.signatureLocations);
  const setSignatureLocations = useAppStore((x) => x.setSignatureLocations);
  const signatureLocationEditing = useAppStore(
    (x) => x.signatureLocationEditing
  );
  const isEditingAccept = useAppStore((x) => x.isEditingAccept);
  const contractId = useAppStore(x => x.contractId);

  const signaturePadRef = useRef(null);

  const isInitial =
    signatureLocationEditing?.type === SIGNATURE_TYPES.initial ||
    signatureLocationEditing?.type === SIGNATURE_TYPES.initialBinary;

  const dataUrls = useMemo(
    () => (isInitial ? initialDataUrls : signatureDataUrls),
    [isInitial, initialDataUrls, signatureDataUrls]
  );
  const setDataUrls = useMemo(
    () => (isInitial ? setInitialDataUrls : setSignatureDataUrls),
    [isInitial, setInitialDataUrls, setSignatureDataUrls]
  );

  const handleDraw = () => {
    setIsSignatureDrawn(true);
  }

  useEffect(() => {
    if (signaturePadRef.current) signaturePadRef.current.clear();
    setSelectedSignatureIndex(dataUrls.length - 1);
  }, [signatureDataUrls, initialDataUrls]);

  function clear() {
    signaturePadRef.current.clear();
  }

  function saveSignature() {
    setDataUrls([
      ...dataUrls,
      signaturePadRef.current.getTrimmedCanvas().toDataURL("image/png"),
    ]);
  }

  function removeSavedSignature(i) {
    const copy = [...dataUrls];
    copy.splice(i, 1);
    setDataUrls(copy);

    const copySignatureLocations = [...signatureLocations];
    copySignatureLocations.forEach((location) => {
        if (location.img === dataUrls[i]) {
            location.img = undefined;
        }
        if (location.img2 === dataUrls[i]) {
            location.img2 = undefined;
        }
    });
    setSignatureLocations(copySignatureLocations);
  }

  function sign() {
    setSignatureLocations([
      ...signatureLocations.filter((x) => x.id !== signatureLocationEditing.id),
      ...(isEditingAccept
        ? [
            {
              ...signatureLocationEditing,
              img: dataUrls[selectedSignatureIndex],
              img2: undefined,
            },
          ]
        : [
            {
              ...signatureLocationEditing,
              img: undefined,
              img2: dataUrls[selectedSignatureIndex],
            },
          ]),
    ]);
    const blob =  dataURLtoBlob(dataUrls[selectedSignatureIndex]);
    handleSaveSignature(contractId, signatureLocationEditing.id, blob, isEditingAccept);
    completeSignature(signatureLocationEditing.id, !isEditingAccept);
    setIsOpen(false);
  }

  function saveAndApplySignature() {
    const newSignatureDataUrl = signaturePadRef.current.getTrimmedCanvas().toDataURL("image/png");
    setDataUrls([
      ...dataUrls,
      newSignatureDataUrl
    ]);
    setSignatureLocations([
      ...signatureLocations.filter((x) => x.id !== signatureLocationEditing.id),
      ...(isEditingAccept
        ? [{ ...signatureLocationEditing, img: newSignatureDataUrl, img2: undefined }]
        : [{ ...signatureLocationEditing, img: undefined, img2: newSignatureDataUrl}]),
    ]);
    const blob =  dataURLtoBlob(newSignatureDataUrl);
    handleSaveSignature(contractId, signatureLocationEditing.id, blob, isEditingAccept);
    completeSignature(signatureLocationEditing.id, !isEditingAccept);
    setIsSignatureDrawn(false);
    setCreateNewSign(false);
    setIsOpen(false);
  }

  return (
    <Modal isOpen={isOpen} centered className={`${IS_LANDSCAPE && MOBILE_SIZE ? 'modal-fullscreen' : 'modal-xl'}`}>
      {/* Modal Title & Close Button */}
      <ModalHeader>
        <div className="d-flex flex-row align-items-center justify-content-between">
          <div>{isInitial ? "Initial" : "Sign"} Document</div>
          <div style={{right: 20, position: "absolute"}}>
          <FontAwesomeIcon icon={'times-circle'} onClick={() => {setIsOpen(false); setCreateNewSign(false);}} />
          </div>
        </div>
      </ModalHeader>

      {/* Modal Body - Signature Pad & Saved Signatures */}
      <ModalBody>
        {dataUrls && dataUrls.length > 0 ? (
          <div>
            <Button 
              color="light" 
              onClick={() => setCreateNewSign(true)} 
              style={{display: createNewSign ? 'none' : 'block'}}>
              Create New {isInitial ? "Initial" : "Signature"}
            </Button>
          </div>
        ) : (
          <SignaturePad
            ref={signaturePadRef}
            canvasProps={{
              style: {
                width: "100%",
                height: "100%",
                border: "2px solid black",
                borderRadius: "4px",
              },
            }}
            onBegin={handleDraw}
          />
        )}
        {createNewSign ? (
          <SignaturePad
          ref={signaturePadRef}
          canvasProps={{
            style: {
              width: "100%",
              height: "100%",
              border: "2px solid black",
              borderRadius: "4px",
            },
          }}
          onBegin={handleDraw}
        />      
        ) : null}
        {Array.isArray(dataUrls) && dataUrls.length && !createNewSign ? (
          <>
            <hr />
            <h6>Saved {isInitial ? "Initials" : "Signatures"}</h6>
            {dataUrls.map((x, i) => (
              <div key={i} className="position-relative d-inline">
                <img
                  key={i}
                  src={x}
                  alt="Signature"
                  className={
                    "border rounded me-2 mb-2" +
                    (selectedSignatureIndex === i
                      ? " border-3 border-primary"
                      : "")
                  }
                  style={{ height: "40px" }}
                  title="Select"
                  onClick={() => setSelectedSignatureIndex(i)}
                />
                <div
                  className="bg-light d-flex justify-content-center align-items-center text-danger rounded-full position-absolute"
                  style={{
                    width: "18px",
                    height: "18px",
                    top: "-16px",
                    right: 0,
                  }}
                  title="Remove"
                  onClick={() => removeSavedSignature(i)}
                >
                  <BsFillXCircleFill />
                </div>
              </div>
            ))}
          </>
        ) : null}
      </ModalBody>

      {/* Modal Buttons - Save/Apply Signature */}
      <ModalFooter>
        {dataUrls && dataUrls.length && !createNewSign ? (
          <Button 
            color="primary" 
            onClick={sign}
            className="shadow-sm"
          >
            Apply Saved Signature
          </Button>
        ) : (
          <div className="d-flex justify-content-end">
            <Button
              size="sm"
              color="light"
              onClick={clear}
              className="me-3 shadow-sm"
            >
              Clear
            </Button>
            <Button
              color="primary"
              onClick={saveAndApplySignature}
              className="shadow-sm"
              disabled={!isSignatureDrawn}
            >
              Save and Apply {isInitial ? "Initial" : "Signature"}
            </Button>
          </div>
        )}
      </ModalFooter>
    </Modal>
  );
}
