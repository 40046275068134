import React from "react";
import {
  Button, ButtonGroup,
  Row, Col, Input, Label, ModalHeader, ModalBody, ModalFooter, Modal
} from "reactstrap";

export default function ModelModal({modelNumber, changeModelNumber, setShowModelModal, setModelNumber}) {
	return (<Modal isOpen fullscreen="sm" autoFocus={false}
            toggle={() => {
              setModelNumber("");
              setShowModelModal(false);
            }}>
    <ModalHeader>
      Set RTO ID
    </ModalHeader>
    <ModalBody>
      <Input type='text' maxLength="25" autoFocus={true} value={modelNumber}
        onKeyDown={(e) => {
          if (e.which === 13) {
            changeModelNumber();
          }
        }}
        onChange={(e) => setModelNumber(e.target.value)} />
    </ModalBody>
    <ModalFooter>
      <Row className={'d-flex justify-content-end'}>
        <Col>
          <ButtonGroup className="float-end">
            <Button color='primary' onClick={changeModelNumber}>Save</Button>
            <Button onClick={() => {
              setModelNumber("");
              setShowModelModal(false);
            }}>Close</Button>
          </ButtonGroup>
        </Col>
      </Row>
    </ModalFooter>
  </Modal>);
}