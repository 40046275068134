import React, { Fragment, useState, useEffect, useRef, useContext } from "react";
import {
    Row,
    Col,
    FormGroup,
    Form,
    Input, InputGroup,
    Label,
    Button, ButtonGroup,
    Table, Modal, ModalHeader, ModalBody, ModalFooter,
    Alert as RSAlert, FormFeedback
} from "reactstrap";
import Alert from "react-s-alert-v3";
import classnames from 'classnames';
import Select from "react-select";
import DatePicker from "react-datepicker";
import moment from "moment";
import { api, ui_helpers, date_helpers } from "../helpers";
import _ from "lodash";
import { storage, constants, UserContext } from "../utils";
import { DebounceInput } from "react-debounce-input";

const emptyRepo = {
    id: null,
    unitInventoryId: null,
    modelNumber: "",
    originalDealer: null,
    originalDealerOtherText: "",
    originalCustomerFirstName: "",
    originalCustomerLastName: "",
    originalCustomerLicenseNumber: "",
    originalCustomerSSN: "",
    originalCustomerSSNDisplay: "",
    originalSalespersonName: "",
    originalPrice: null,
    deliveredOn: date_helpers.formatDateToShortDate(moment()),
    depoOn: new Date(),
    pickupFee: null,
    redeliveryFee: null,
    refurbFee: null,
    commission: null,
    rentalRevenue: null,
    otherRevenue: null
};

const UsedBuildingDetails = (props) => {
    const [inventory, setInventory] = useState(props.inventory);
    const [repo, setRepo] = useState(props.repoData || emptyRepo);
    const [dealerList, setDealerList] = useState(props.dealerList);

    useEffect(() => formatRepo(props.repoData), [props.repoData]);

    function formatRepo(repoToFormat) {
      if (!repoToFormat || _.isEmpty(repoToFormat) || !props.dealerList) return;
      let dealer = constants.OTHER_OPTION;
      if (repoToFormat.originalDealer && repoToFormat.originalDealer.value) {
        dealer = _.find(props.dealerList, d => d.value === repoToFormat.originalDealer.value);
      } else if (repoToFormat.originalDealerId && _.some(props.dealerList, d => d.value === repoToFormat.originalDealerId)) {
        dealer = _.find(props.dealerList, d => d.value === repoToFormat.originalDealerId);
      } else if (repoToFormat.originalDealerId && repoToFormat.originalDealerName) {
        dealer = {value: repoToFormat.originalDealerId, label: repoToFormat.originalDealerName};
      }
      setRepo({
        ...repoToFormat,
        originalDealer: dealer,
        repoOnDisplay: repoToFormat.repoOn
            ? date_helpers.formatDateToShortDate(repoToFormat.repoOn)
            : null,
        deliveredOn: repoToFormat.deliveredOn,
        repoOn: repoToFormat.repoOn
            ? date_helpers.formatDateToShortDate(repoToFormat.repoOn)
            : new Date()
      });
    }

    function onChange(fieldName, inputValue) {
      let updatedRepo = _.cloneDeep(repo);
      //if (fieldName === 'originalDealer') {
        // updatedRepo.originalDealerId = inputValue.value;
        //console.log('updating', inputValue, props.dealerList, _.find(props.dealerList, d => d.value === inputValue.value))
      //}
      if (fieldName === 'repoOn') {
        inputValue = date_helpers.formatDateToShortDate(inputValue)
      }
      updatedRepo[fieldName] = inputValue;
      //setRepo(updatedRepo);
      props.setRepoData(updatedRepo);
    }
    
    return (
        <div>
            <Row>
                <Col>
                    <Label for="originalStore">Original Store{' '}{!props.readOnly && ui_helpers.requiredStar()}</Label>
                    {props.readOnly ? (
                      <Input
                        type="text"
                        name="originalStore"
                        className="my-1"
                        readOnly
                        value={repo.originalDealerName === constants.OTHER 
                            || (repo.originalDealer && repo.originalDealer.label === constants.OTHER)
                            || !repo.originalDealerName
                          ? repo.originalDealerOtherText
                          : repo?.originalDealerName}
                      />
                    ) : (<>
                          <Select
                            options={props.dealerList}
                            value={repo?.originalDealer}
                            onChange={(e) => onChange("originalDealer", e)}
                          />
                          {repo && repo.originalDealer && repo.originalDealer.label === constants.OTHER
                            ? <Input
                                type="text"
                                name="originalDealerOtherText"
                                className="my-1"
                                readOnly={props.readOnly}
                                value={repo?.originalDealerOtherText}
                                placeholder="Original Store Name"
                                onChange={(e) => onChange("originalDealerOtherText", e.target.value)}
                              />
                              : null
                          }
                        </>
                    )}
                    <Label for="originalSalesperson">Original Salesperson{' '}{!props.readOnly && ui_helpers.requiredStar()}</Label>
                    <Input
                        type="text"
                        name="originalSalesperson"
                        className="my-1"
                        readOnly={props.readOnly}
                        value={repo?.originalSalespersonName}
                        onChange={(e) => onChange("originalSalespersonName", e.target.value)}
                    />
                    <Label for="originalCustomerFN">Original Customer First Name{' '}{!props.readOnly && ui_helpers.requiredStar()}</Label>
                    <Input
                        type="text"
                        name="originalCustomerFN"
                        className="my-1"
                        readOnly={props.readOnly}
                        value={repo?.originalCustomerFirstName}
                        onChange={(e) => onChange("originalCustomerFirstName", e.target.value)}
                    />
                    <Label for="orignalCustomerLN">Original Customer Last Name{' '}{!props.readOnly && ui_helpers.requiredStar()}</Label>
                    <Input
                        type="text"
                        name="originalCustomerLN"
                        className="my-1"
                        readOnly={props.readOnly}
                        value={repo?.originalCustomerLastName}
                        onChange={(e) => onChange("originalCustomerLastName", e.target.value)}
                    />
                    <Label for="originalCustomerSSN">Original Customer SSN</Label>
                    <Input
                        type="text"
                        name="originalCustomerSSN"
                        className="my-1"
                        readOnly={props.readOnly}
                        value={props.readOnly ? repo.originalCustomerSSNDisplay : repo?.originalCustomerSSN}
                        onChange={(e) => onChange("originalCustomerSSN", e.target.value)}
                        onBlur={(e) => onChange("originalCustomerSSN", ui_helpers.formatSSNField(e.target.value))}
                    />
                    <Label for="originalCustomerLicense#">Original Customer License #</Label>
                    <Input
                        type="text"
                        name="originalCustomerLicense#"
                        className="my-1"
                        readOnly={props.readOnly}
                        value={repo?.originalCustomerLicenseNumber}
                        onChange={(e) => onChange("originalCustomerLicenseNumber", e.target.value)}
                    />
                </Col>
                <Col>
                  <Row>
                    <Col xs={3}>
                      <Label for="repoOn">Date Returned{' '}{!props.readOnly && ui_helpers.requiredStar()}</Label>
                      {props.readOnly ? (
                        <Input 
                          type="text"
                          name="dateReturned"
                          className="my-1 form-control"
                          readOnly
                          value={repo && date_helpers.formatDateToShortDate(repo.repoOn)}
                        />
                      ): (
                        <div>
                          <DatePicker
                            showMonthDropdown
                            showYearDropdown
                            withPortal
                            className="my-1"
                            dropdownMode="select"
                            name="repoOn"
                            value={repo && repo.repoOn}
                            onChange={(e) => onChange("repoOn", e)}
                            readOnly={props.readOnly}
                          />
                        </div>
                      )}
                    </Col>
                    <Col xs={3}>
                      <Label for="deliveredOn">Contract Date{' '}{!props.readOnly && ui_helpers.requiredStar()}</Label>
                      {props.readOnly ? (
                        <Input 
                          type="text"
                          name="contractDate"
                          className="my-1"
                          readOnly
                          value={repo && date_helpers.formatDateToShortDate(repo.deliveredOn)}
                        />
                      ): (
                        <DebounceInput
                          type="text"
                          min={4}
                          name="contractDate"
                          className="form-control"
                          debounceTimeout={300}
                          value={repo ? repo.deliveredOn : ""}
                          onChange={(e) => onChange("deliveredOn", e.target.value)}
                          onBlur={(e) => onChange("deliveredOn", ui_helpers.formatDateField(e.target.value))}
                        />)}
                    </Col>
                  </Row>
                  <Row>
                    <Col xs="8" style={{ backgroundColor: "#FCEFCE", borderRadius: "10px" }} className="p-2 mb-2">
                            <Row className="d-flex flex-row mt-1">
                                <p style={{ width: "40%" }} className="m-0 pnl-red">(Original Price){' '}{!props.readOnly && ui_helpers.requiredStar()}</p>
                                <Input
                                    type="text"
                                    name="originalPrice"
                                    className="pnl-textbox"
                                    readOnly={props.readOnly}
                                    value={props.readOnly ? ui_helpers.formatCurrency(repo && repo.originalPrice) : repo && repo.originalPrice}
                                    onChange={(e) => onChange("originalPrice", e.target.value)}
                                />
                            </Row>
                            <Row className="d-flex flex-row mt-1">
                                <p style={{ width: "40%" }} className="m-0 pe-1 pnl-green">Rental Revenue{' '}{!props.readOnly && ui_helpers.requiredStar()}</p>
                                <Input
                                    type="text"
                                    name="rentalRevenue"
                                    className="pnl-textbox"
                                    readOnly={props.readOnly}
                                    value={props.readOnly ? ui_helpers.formatCurrency(repo && repo.rentalRevenue) : repo && repo.rentalRevenue}
                                    onChange={(e) => onChange("rentalRevenue", e.target.value)}
                                />
                            </Row>
                            <Row className="d-flex flex-row mt-1">
                                <p style={{ width: "40%"}} className="m-0 pe-1 pnl-green">Other Revenue{' '}{!props.readOnly && ui_helpers.requiredStar()}</p>
                                <Input
                                    type="text"
                                    name="otherRevenue"
                                    className="pnl-textbox"
                                    readOnly={props.readOnly}
                                    value={props.readOnly ? ui_helpers.formatCurrency(repo && repo.otherRevenue) : repo && repo.otherRevenue}
                                    onChange={(e) => onChange("otherRevenue", e.target.value)}
                                />
                            </Row>
                            <Row className="d-flex flex-row mt-1">
                                <p style={{ width: "40%" }} className="m-0 pe-1 pnl-red">(Pickup Fee){' '}{!props.readOnly && ui_helpers.requiredStar()}</p>
                                <Input
                                    type="text"
                                    name="pickupFee"
                                    className="pnl-textbox"
                                    readOnly={props.readOnly}
                                    value={props.readOnly ? ui_helpers.formatCurrency(repo && repo.pickupFee) : repo && repo.pickupFee}
                                    onChange={(e) => onChange("pickupFee", e.target.value)}
                                />
                            </Row>
                            <Row className="d-flex flex-row mt-1">
                                <p style={{ width: "40%" }} className="m-0 pe-1 pnl-red">(Refurb Fee){' '}{!props.readOnly && ui_helpers.requiredStar()}</p>
                                <Input
                                    type="text"
                                    name="refurbFee"
                                    className="pnl-textbox"
                                    readOnly={props.readOnly}
                                    value={props.readOnly ? ui_helpers.formatCurrency(repo && repo.refurbFee) : repo && repo.refurbFee}
                                    onChange={(e) => onChange("refurbFee", e.target.value)}
                                />
                            </Row>
                            <Row className="d-flex flex-row mt-1">
                                <p style={{ width: "40%" }} className="m-0 pe-1 pnl-red">(Redelivery Fee){' '}{!props.readOnly && ui_helpers.requiredStar()}</p>
                                <Input
                                    type="text"
                                    name="redeliveryFee"
                                    className="pnl-textbox"
                                    readOnly={props.readOnly}
                                    value={props.readOnly ? ui_helpers.formatCurrency(repo && repo.redeliveryFee) : repo && repo.redeliveryFee}
                                    onChange={(e) => onChange("redeliveryFee", e.target.value)}
                                />
                            </Row>
                            <Row className="d-flex flex-row mt-1">
                                <p style={{ width: "40%" }} className="m-0 pe-1 pnl-red">(Commission){' '}{!props.readOnly && ui_helpers.requiredStar()}</p>
                                <Input
                                    type="text"
                                    name="commission"
                                    className="pnl-textbox"
                                    readOnly={props.readOnly}
                                    value={props.readOnly ? ui_helpers.formatCurrency(repo && repo.commission) : repo && repo.commission}
                                    onChange={(e) => onChange("commission", e.target.value)}
                                />
                            </Row>
                            <Row className="d-flex flex-row mt-1">
                                <p style={{ width: "40%" }} className="m-0 pe-1 pnl-green">Sale Price</p>
                                <Input
                                    type="text"
                                    name="salePrice"
                                    className="pnl-textbox"
                                    readOnly
                                    value={props.contractSalePrice ? ui_helpers.formatCurrency(props.contractSalePrice) : "$0.00"}
                                />
                            </Row>
                            <Row className="d-flex flex-row mt-1">
                                <p style={{ width: "40%" }} className="m-0 pe-1">P &amp; L</p>
                                <Input
                                    type="text"
                                    name="rentalRevenue"
                                    className={classnames({"pnl-red": props.pl < 0}, "pnl-textbox")}
                                    readOnly
                                    value={props.pl ? ui_helpers.formatCurrency(props.pl) : ""}
                                />
                            </Row>
                            </Col>
                    </Row>
                </Col>
            </Row>
        </div>
    );
}
export default UsedBuildingDetails;