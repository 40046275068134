import React from "react";

function Grid({ id }) {
  return (
    <g>
      <defs>
        <pattern id={id || "grid"}>
          <pattern
            id={id || "Pattern"}
            x="0"
            y="0"
            width="12"
            height="12"
            patternUnits="userSpaceOnUse"
          >
            <rect
              x="0"
              y="0"
              width="12"
              height="12"
              fill="none"
              stroke="lightgrey"
              strokeWidth="0.3"
            />
          </pattern>
        </pattern>
      </defs>
      <rect
        x={-500 * 12}
        y={-500 * 12}
        width={1000 * 12}
        height={1000 * 12}
        fill={id ? `url(#${id})` : "url(#Pattern)"}
      />
    </g>
  );
}

export default Grid;
