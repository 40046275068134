function displaySettingsReducer(state, { type, payload }) {
  switch (type) {
    case "INCREMENT_FONT":
      return { ...state, fontSize: state.fontSize + 0.5 };
    case "DECREMENT_FONT":
      return { ...state, fontSize: state.fontSize - 0.5 };
    case "TOGGLE_GRID":
      return { ...state, showGrid: !state.showGrid };
    case "TOGGLE_RULERS":
      return { ...state, showRulers: !state.showRulers };
    case "SET_RULER_OFFSET":
      return { ...state, rulerOffset: payload };
    default:
      return state;
  }
}

export default displaySettingsReducer;
