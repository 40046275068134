import moment from "moment";

const YMD = "YYYY-MM-DD";
const YYMMDD = "YYMMDD";
const MD = "MM/DD";
const MDY = "MM/DD/YY";
const MDYND = "MMDDYY";
const MDY4 = "MM/DD/YYYY";
const YMDHMS = "YYYY-MM-DD HH:mm:ss";
const SERIAL = "DDMMYYYY";
const VALID_DATE_FORMAT_LIST = [
    MDY4,
    "M/D/YY",
    "MM/D/YY",
    MDY,
    "MM/D/YYYY",
    "M/D/YYYY",
    YMD
  ];

const verifyDate = (s) => {
  if (!s) return null;
  const test = moment(s, VALID_DATE_FORMAT_LIST);
  return test.isValid() ? test : null;
};

export default {
  MD: MD,
  MDY: MDY,
  YMD: YMD,
  MDY4: MDY4,
  YYMMDD: YYMMDD,
  MDYND: MDYND,
  isValidDate(s) {
    const test = moment(s, VALID_DATE_FORMAT_LIST, true);
    return test.isValid();
  },
  isDateValid(dateString) {
    return verifyDate(dateString) !== null;
  },
  getSelectedDate(date, fmt = null) {
     if (date) {
       return (fmt) 
         ? moment(date, fmt) 
         : moment(date);
     }
     return null;
   },
   getValidDate(date) {
     if (date) {
       const test = moment(date, VALID_DATE_FORMAT_LIST, true);
       if (test.isValid()) {
         return moment(date);
       } else {
         return null;
       }
     }
     return null;
   },
   formatDateForServer(date, fmt = MDY) {
     if (date) {
       if (moment.isMoment(date)) return date.format(YMD);
       return moment(date, fmt).format(YMD);
     }
     return date;
   },
   formatDateTimeForServer(dateTime) {
     if (dateTime) {
       if (moment.isMoment(dateTime)) return dateTime.format(YMDHMS);
       return moment(dateTime).format(YMDHMS);
     }
     return dateTime;
   },
   parseTime(s) {
     let m = moment("2000-01-01 " + s, [
       "YYYY-MM-DD h:mm",
       "YYYY-MM-DD HH:mm",
       "YYYY-MM-DD HHmm",
       YMDHMS
     ]);
     if (m.isValid()) {
       return m.format("HH:mm");
     }
     return s;
   },
   parseDate(s, fmt = YMD) {
     const validated = verifyDate(s);
     if (validated) {
       if (validated.year() > new Date().getFullYear()) {
         validated.year(validated.year() - 100);
       }
       return validated.format(fmt);
     } else {
       return s;
     }
   },
   dateTimeFormat(dateTime) {
     if (dateTime) {
       return moment
         .utc(dateTime)
         .local()
         .format("MM/DD/YYYY h:mm a");
     }
     return dateTime;
   },
   shortYearDateTimeFormat(dateTime) {
     if (dateTime) {
       return moment
         .utc(dateTime)
         .local()
         .format("MM/DD/YY h:mm a");
     }
     return dateTime;
   },
   createTimeList() {
    // 5AM, 15 min increments, until 10PM
    let tmp = moment({hour: 5});
    let list = [];
    list.push({ value: 0, label: " - " });
    while (tmp.hour() <= 22) {
      list.push({ value: parseInt(tmp.format("HHmm"), 10), label: tmp.format("h:mm a") });
      tmp = tmp.add(15, 'm');
    }
    return list;
  },
  format24hTime(time) {
     if (time) {
       return moment(time, "HH:mm:ss").format("HH:mm");
     }
     return time;
   },
  formatTime(time) {
     if (time) {
       return moment(time, "HH:mm:ss").format("hh:mm a");
     }
     return time;
   },
   formatDateToShortDate(date) {
     if (date) {
       return moment(date).format(MDY4);
     }
     return date;
   },
   formatDateToTinyDate(date) {
     if (date) {
       return moment(date).format(MDY);
     }
     return date;
   },
   toMinDateFormat(date) {
     if (date) {
       return moment(date).format(MD);
     }
     return date;
   },
   getHumanizedDateDisplay(date) {
     if (date) {
       return moment.utc(date).fromNow();
     }
     return date;
   },
   getSerialNumberDisplay(date){
      if(date) {
          return moment(date).format(MDYND);
      }
      return date;
   },
   formatDayOfWeekAndTime(dateTime) {
     if (dateTime) {
       return moment
         .utc(dateTime)
         .local()
         .format("LLLL");
     }
     return dateTime;
   },
   getLocalHumanizedDateDisplay(date) {
     if (date) {
       return moment(date).fromNow();
     }
     return date;
   },
  daysAgoFromNow(date){
    if (date) {
      const then = moment(date);
      return moment().diff(then, 'days');
    }
  },
  isBefore(a, b) {
    return moment(a).isBefore(moment(b), 'day');
  },
  getStringFromMoment(m) {
    if (m) return m.format('YYYY-MM-DD');
    return null;
  },
  calculateAge(m) {
    var totalMonths = moment().diff(m, 'months');
    return parseInt(totalMonths / 12);
  },
  calculateAgeInDays(m) {
    var days = moment().diff(m, 'days');
    return parseInt(days, 10);
  },
  getCurrentDate(fmt = null) {
      if (fmt) return moment().format(fmt);
      return moment().format(MDY4);
  },
  dateToMoment(d, fmt = null) {
      if (fmt) return moment(d).format(fmt);
      return moment(d);
  },
  addDays(days, date = null) {
    var result = date ? new Date(date) : new Date();
    result.setDate(result.getDate() + days);
    return result;
  },
  timeUntilPasscodeExpires(expiresAt) {
    if (expiresAt) {
      const now = moment();
      const formattedNow = moment(this.dateTimeFormat(now));
      const expires = moment(this.dateTimeFormat(expiresAt));
      const diffInMinutes = expires.diff(formattedNow, 'minutes');
      const diffInHours = expires.diff(formattedNow, 'hours');
      const diffInDays = expires.diff(formattedNow, 'days');

      if (diffInDays > 0) {
        return `Expires in: ${diffInDays} day${diffInDays > 1 ? 's' : ''}`;
      } else if (diffInHours > 0) {
        return `Expires in: ${diffInHours} hour${diffInHours > 1 ? 's' : ''}`;
      } else if (diffInMinutes > 0) {
        return `Expires in: ${diffInMinutes} minute${diffInMinutes > 1 ? 's' : ''}`;
      } else {
        return 'Expired! Please reset your passcode.';
      }
    }
  },
};
