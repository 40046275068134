import _ from "lodash";

export default {
    check_string_length(string, length) {
        if (string.length > length) {
            return false;
        } else {
            return true;
        }
    },
    is_phone_number(phone_number) {
        let raw_phone_number = "";
        if (_.includes(phone_number, ".")) {
            raw_phone_number = phone_number.split(".").join("");
        } else if (_.includes(phone_number, "-")) {
            raw_phone_number = phone_number.replace(/-+/g, "");
        } else {
            raw_phone_number = phone_number;
        }
        if (raw_phone_number.length === 10) {
            return true;
        } else {
            return false;
        }
    },
    is_zip_code(zip_code) {
        if (zip_code.length === 5 && _.toInteger(zip_code) === parseInt(zip_code, 10)) {
            return true;
        } else {
            return false;
        }
    },
    is_int(int) {
        if (_.toInteger(int) === parseInt(int, 10)) {
            return true;
        } else {
            return false;
        }
    },
    is_decimal(decimal) {
        if (_.contains(decimal, ".")) {
            const split_decimal = decimal.split(".");
            if (split_decimal.length === 2 && _.isInteger(split_decimal[0]) && _.isInteger(split_decimal[1])) {
                return true;
            } else {
                return false;
            }
        } else {
            if (decimal.isInteger()) {
                return true;
            } else {
                return false;
            }
        }
    },
    is_email(email) {
        if (!email) return false;
        if (_.includes(email, "@") && _.includes(email, ".")) {
            const split_email = email.split(/[@.]+/);
            if (split_email.length >= 3 &&
                split_email[0].length !== 0 &&
                split_email[1].length !== 0 &&
                split_email[2].length !== 0) {
                return true;
            } else {
                return false;
            }

        } else {
            return false;
        }
    }
};