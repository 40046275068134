import React, { Component, Fragment, useState } from "react";
import { Row, Collapse, Button, Col, ButtonGroup } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {library} from "@fortawesome/fontawesome-svg-core";
import {faBackspace} from "@fortawesome/free-solid-svg-icons";
library.add(
    faBackspace,
)

export default function FilterSet(props) {
    const [collapse, setCollapse] = useState(!props.open || true);

    // constructor(props) {
    //     super(props);
    //     this.state = {
    //         collapse: !(props.open || true),
    //         clearFilters: props.clearFilters
    //     };
    //     this.toggleFilter = this.toggleFilter.bind(this);
    //     this.getFilterDisplay = this.getFilterDisplay.bind(this);
    // }

    const toggleFilter = () => {
        setCollapse(!collapse);
    }

    const getFilterDisplay = (filters) => {
        if (!filters || !filters.length || filters.length === 0) {
            return null;
        }
        const filterDisplay = filters
            .map(x =>
                x.value || x.startDate || x.endDate
                    ? x.filteredDisplay
                    : null
            )
            .join(" | ");

        if (!filterDisplay) {
            return "";
        }

        return ` - ${filterDisplay}`;
    }


    return (
        <div className="mb-1">
            <Row>
                <Col>
                    {props.customFilterClassName && (
                        <span className="">Filters</span>
                    )}
                    <span
                        title="Click here to toggle filter display"
                        className={
                            props.customFilterClassName
                                ? props.customFilterClassName
                                : "filter-toggle"
                        }
                        onClick={() => toggleFilter()}
                    >
                        <FontAwesomeIcon icon="filter" /> Filters
                    </span>
                    <span className="filter-display">
                        {getFilterDisplay(props.filters)}
                    </span>
                    {props.filters && props.filters.length !== 0 && (
                        <Button
                            className="l2DangerOutline ms-4 xs"
                            size="sm"
                            style={{ marginTop: "-6px", marginBottom: "4px" }}
                            onClick={() => props.clearFilters()}
                        >
                            <FontAwesomeIcon
                                icon="backspace"
                                className="mr-2 ml-0 mt-0 mb-0"
                            />{" "}
                            Clear
                        </Button>
                    )}
                </Col>
            </Row>
            <Collapse isOpen={collapse}>
                <Row className='d-flex justify-items-center'>
                    {props.children}
                </Row>
            </Collapse>
        </div>
    );
}
