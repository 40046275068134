import React, { useState, useEffect, Fragment } from "react";
import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Table,
  Tooltip,
} from "reactstrap";
import Draggable from "react-draggable";
import Alert from "react-s-alert-v3";
import classnames from 'classnames';
import _ from "lodash";
import Select from 'react-select';
import { api, ui_helpers } from "../helpers";
import { useContractStore } from "../hooks";
import { constants } from "../utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const CalculatorTable = (props) => {
  function resolvePropsProductType() {
    return props.productType
      ? props.productType
      : _.find(constants.PRODUCT_TYPES, pt => pt.value === constants.PRODUCT_TYPE_IDS.STORAGE_BUILDING);
  }

  const contractCompanyId = useContractStore(x => x.companyId);
  const contractRegionId = useContractStore(x => x.regionId);

  const [unitPrice, setUnitPrice] = useState(props.unitPrice || 2900);
  const [taxRateString, setTaxRateString] = useState(props.taxRateString || String(props.taxRate));
  const [taxRate, setTaxRate] = useState(props.taxRate);
  const [atLeast14Wide, setAtLeast14Wide] = useState(props.atLeast14Wide || false);
  const [atLeast16Wide, setAtLeast16Wide] = useState(props.atLeast16Wide || false);
  const [includeLDW, setIncludeLDW] = useState(props.includeLDW === undefined ? true : props.includeLDW);
  const [customDownPayment, setCustomDownPayment] = useState(props.customDownPayment || "0");
  const [promotionCode, setPromotionCode] = useState(props.promotionCode || "");
  const [infoTooltipOpen, setInfoTooltipOpen] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const [productType, setProductType] = useState(resolvePropsProductType())
  const [selectedTerm, setSelectedTerm] = useState(props.selectedTermId);
  const [showTotalFinancingFees, setShowTotalFinancingFees] = useState(false);
  const [hasExtraDownPayment, setHasExtraDownPayment] = useState(false);
  const [terms, setTerms] = useState([]);
  const [tainted, setTainted] = useState(true);
  const [lastPayload, setLastPayload] = useState(null);
  const [region, setRegion] = useState(null);
  const [rtoCompanyName, setRtoCompanyName] = useState(null);
  const [calculationWarning, setCalculationWarning] = useState('');

  useEffect(() => {
    if (props.onSetUnitPrice) {
      props.onSetUnitPrice(unitPrice);
      props.onSetTaxRate(props.isTaxExempt ? 0.0 : taxRate);
      props.onSetAtLeast14Wide(atLeast14Wide);
      props.onSetAtLeast16Wide(atLeast16Wide);
      props.onSetIncludeLDW(includeLDW);
      props.onSetCustomDownPayment(customDownPayment);
      props.onSetPromotionCode(promotionCode);
      props.onSetProductType(productType ? productType : resolvePropsProductType());
      props.onSetShowTotalFinancingFees(showTotalFinancingFees);
    }
    if (!props.showInputs) {
      onCalculate();
    }
  }, [unitPrice, taxRate, atLeast14Wide, atLeast16Wide, includeLDW, props.companyId,
    customDownPayment, productType, showTotalFinancingFees, promotionCode, props.isTaxExempt])

  useEffect(() => {
    if (!props.onResultsDisplayed) return;
    props.onResultsDisplayed(!tainted);
  }, [tainted]);

  useEffect(() => {
    if (!props.showInputs) {
      setUnitPrice(props.unitPrice);
      setTaxRate(props.isTaxExempt ? 0.0 : props.taxRate);
      setAtLeast14Wide(props.atLeast14Wide);
      setAtLeast16Wide(props.atLeast16Wide);
      setIncludeLDW(props.includeLDW);
      setCustomDownPayment(props.customDownPayment);
      setProductType(resolvePropsProductType());
      setPromotionCode(props.promotionCode);
      if(props.taxRateString){
        setTaxRateString(props.taxRateString);
      }
    }
  }, [props.unitPrice, props.taxRate, props.atLeast14Wide, props.atLeast16Wide, 
    props.includeLDW, props.customDownPayment, props.productType, props.promotionCode, props.isTaxExempt])

  function getCellClasses(termId, bold) {
    return classnames("num-value", {
      "bold-row": bold,
      "cell-highlight": termId === selectedTerm,
      "bg-light": termId === constants.CONTRACT_TERM_IDS.T24,
      "bg-med-light": termId === constants.CONTRACT_TERM_IDS.T36,
      "bg-med-dark": termId === constants.CONTRACT_TERM_IDS.T48,
      "bg-med-darker": termId === constants.CONTRACT_TERM_IDS.T60,
      "bg-med-darkest": termId === constants.CONTRACT_TERM_IDS.T72,
      "bg-darker": termId === constants.CONTRACT_TERM_IDS.OTHER
    });
  }

  function getPayload() {
    let resolvedRegionId = region ? parseInt(region.value, 10) : null;
    if (!resolvedRegionId && props.regionId) {
      resolvedRegionId = props.regionId;
    }
    return {
      TaxRateString: props.isTaxExempt ? "0.0" : taxRate,
      ProductTypeId: productType ? productType.value : props.productType,
      UnitPrice: unitPrice || 0.0,
      IncludeDLW: includeLDW,
      IdentifyTotalFinancingFees: showTotalFinancingFees,
      InitialPayment: customDownPayment,
      IsAtLeast14FeetWide: atLeast14Wide,
      IsAtLeast16FeetWide: atLeast16Wide,
      PromoCode: promotionCode,
      RegionId: contractRegionId || resolvedRegionId, //resolvedRegionId,
      CompanyId: contractCompanyId || props.companyId // props.companyId ? props.companyId : null
    };
  }

  function onBlur(value, fieldName) {
    switch(fieldName) {
      case 'taxRateString':
        const {rate, str} = ui_helpers.taxRateFromString(value);
        setTaxRate(rate);
        setTaxRateString(str);
        // let rate = parseFloat(value);
        // if (rate > 1.0) {
        //   rate /= 100.0;
        // }
        // rate = parseFloat(rate.toFixed(5));
        // setTaxRate(rate);
        // const s = (rate * 100.0).toFixed(3);
        // setTaxRateString(s);
        // storage.setItem('lastTaxRate', s);
        break;
    }
  }

  function onCalculate() {
    const payload = getPayload();    
    if (_.isEqual(payload, lastPayload)) return;
    setLastPayload(payload);
    api.post('contract/PaymentCalculator', payload).then(r => {
      if (r.data.success) {
        setTerms(r.data.message);
        setRtoCompanyName(getRTOCompanyName(r.data.message));
        // should be an array
        let warning = '';
        if (r.data.message && r.data.message.length) {
          warning = r.data.message[0].warning;
        }
        setCalculationWarning(warning);
        setTainted(false);
        if (selectedTerm && props.onSelectedTermUpdate) {
          props.onSelectedTermUpdate(
            _.find(r.data.message, t => t.id === selectedTerm));
        }
      } else {
        Alert.error(r.data.message); // "Could not resolve calculations - an unexpected error occurred");
        //console.error(r.data.message);
      }
    }).catch((e) => console.error(e));    
  }

  function onChangeTerm(newTermId) {
    setSelectedTerm(newTermId);
    if (props.onSelectedTermUpdate) {
      props.onUpdateSelectedTerm(_.find(terms, t => t.id === newTermId));
    }
  }

  function getRTOCompanyName(terms){
    let term = _.head(terms);
    if(!term) return null;
    return term.rtoCompanyName;
  }

  function resolveMinRequiredForDelivery(t) {
    // if (props.showInputs) {
    //   return t.requiredForDeliveryAmount + (props.sideFees || 0.0);
    // }
    return t.requiredForDeliveryAmount + (props.sideFees || 0.0);
    // return t.requiredForDeliveryAmount_NOPR + (props.sideFees || 0.0);
  }

  return (
  <Row>
    {props.showInputs
      ? (<Col xs="5">
          <Form id="calcForm" className="p-3">
            {props.regions && props.regions.length
              ? (<FormGroup className="mt-3">
                  <Label>Region:</Label>
                  <Select
                    options={props.regions}
                    value={region}
                    onChange={(e) => {
                      setTainted(true);
                      setRegion(e);
                    }}
                  />
                </FormGroup>)
              : null
            }            
            <FormGroup>
              <Label>Product Type:</Label>
              <Select
                options={_.reject(constants.PRODUCT_TYPES, pt => pt.value === constants.SELECT_OPTION_ID)}
                value={productType}
                onChange={(e) => {
                  setTainted(true);
                  setProductType(e);
                }}
              />
            </FormGroup>
            <FormGroup>
              <Label for="unitPrice">Price:</Label>
              <Input
                type="number"
                bsSize="sm"
                name="unitPrice"
                value={unitPrice}
                onChange={(e) => {
                  setTainted(true);
                  setUnitPrice(e.target.value);
                }}
              />
            </FormGroup>
            {props.hideTax ? null : 
            <FormGroup>
              <Label for="salesTax">Sales Tax:</Label>
              <Input
                type="text"
                bsSize="sm"
                maxLength="8" 
                value={taxRateString} 
                onChange={(e) => {
                  setTainted(true);
                  setTaxRateString(e.target.value);
                }} 
                onBlur={(e) => {
                  setTainted(true);
                  onBlur(e.target.value, 'taxRateString');
                }}
              />
            </FormGroup>}
            <div className="d-flex justify-content-between">
              <div>
                <Input type="checkbox" checked={atLeast14Wide} onChange={(e) => {
                  setTainted(true);
                  setAtLeast14Wide(!atLeast14Wide);
                }} /> 14' Wide
              </div>
              <div>
                <Input type="checkbox" checked={atLeast16Wide} onChange={(e) => {
                  setTainted(true);
                  setAtLeast16Wide(!atLeast16Wide);
                }} /> 16' Wide
              </div>
              <div>
                <Input type="checkbox" checked={includeLDW} onChange={(e) => {
                  setTainted(true);
                  setIncludeLDW(!includeLDW);
                }} /> Include LDW
              </div>
            </div>
            <FormGroup className="mt-3">
              <Row>
                <Col xs="6">
                  <Label>Custom Down Payment:</Label>
                  <Input
                    type="text"
                    bsSize="sm"
                    name="customDownPayment"
                    value={customDownPayment}
                    onChange={(e) => {
                      setTainted(true);
                      setCustomDownPayment(e.target.value);
                    }}
                    onBlur={(e) => {
                      setTainted(true);
                      const newTextValue = e.target.value;
                      if (parseInt(newTextValue, 10)) {
                        setCustomDownPayment(newTextValue);
                      } else {
                        setCustomDownPayment("0");
                      }
                    }}
                  />
                </Col>
                <Col xs="6">
                  <Label>Promo Code:</Label>
                  <Input
                    type="text"
                    bsSize="sm"
                    name="promoCode"
                    value={promotionCode}
                    onChange={(e) => {
                      setTainted(true);
                      setPromotionCode(e.target.value);
                    }}
                  />
                </Col>
              </Row>
            </FormGroup>
            <div className="d-grid">
              <Button color="primary" className="mt-2" disabled={!tainted} onClick={onCalculate}>
                <FontAwesomeIcon icon="sync" /> Calculate
              </Button>
            </div>
          </Form>
        </Col>)
      : null
    }
    <Col>
      {tainted
        ? <div style={{fontWeight: "bold", fontSize: "20pt", textAlign: "center"}}>Click Calculate to show results</div>
        : (<Table size="sm" id="calcResults">
              <thead>
                <tr>
                  <th className="bg-light">{props.showRTOCompanyName ? rtoCompanyName : null}</th>
                  {_.map(terms, t => (<th key={t.id} className={getCellClasses(t.id)}>{t.name}</th>))}
                </tr>
              </thead>
              <tbody>
                {showDetails ? (
                  <>
                    <tr>
                      <td colSpan="1" className="bg-light-blue"></td>
                      <td
                        colSpan={terms.length}
                        className="text-center bg-med-light-blue text-light cursor-pointer"
                        onClick={() => setShowDetails(!showDetails)}
                      >
                        <FontAwesomeIcon icon="arrow-up" />
                        <em> Click for fewer details </em>
                        <FontAwesomeIcon icon="arrow-up" />
                      </td>
                    </tr>
                    <tr>
                      <td className="bg-light">Monthly Payment</td>
                      {_.map(terms, t => (<td key={t.id} className={getCellClasses(t.id)}>
                        {ui_helpers.formatCurrency(t.monthlyPaymentAmount)}</td>))}
                    </tr>
                    {props.hideTax
                      ? null
                      : (<Fragment>
                          <tr>
                            <td className="bg-light">Sales Tax ({props.isTaxExempt ? "0.00" : taxRateString}%)</td>
                            {_.map(terms, t => (<td key={t.id} className={getCellClasses(t.id)}>
                              {props.isTaxExempt ? "$0.00" : ui_helpers.formatCurrency(t.salesTaxAmount)}</td>))}
                          </tr>
                          <tr>
                            <td className="bg-light">Total {props.isTaxExempt ? "" : "w/Tax"}</td>
                            {_.map(terms, t => (<td key={t.id} className={getCellClasses(t.id)}>
                              {ui_helpers.formatCurrency(t.totalAmountWithTax)}</td>))}
                          </tr>
                      </Fragment>)
                    }
                    <tr>
                      <td className="bg-light">Liability Damage Waiver (LDW)</td>
                      {_.map(terms, t => (<td key={t.id} className={getCellClasses(t.id)}>
                        {ui_helpers.formatCurrency(t.appliedDLWAmount)}</td>))}
                    </tr>
                  </>
                ) : (
                  <tr className="bg-light-blue">
                    <td colSpan="1"></td>
                    <td
                      colSpan={terms.length}
                      className="text-center cursor-pointer"
                      onClick={() => setShowDetails(!showDetails)}
                    >
                      <FontAwesomeIcon icon="arrow-down" />
                      <em> Click for more details </em>
                      <FontAwesomeIcon icon="arrow-down" />
                    </td>
                  </tr>
                )}
                {props.hideTax ? null :
                    <tr>
                      <th className="bg-light"> Payment {props.isTaxExempt ? "" : "w/ Tax"} &amp; LDW</th>
                        {_.map(terms, t => (<td key={t.id} className={getCellClasses(t.id, true)}>
                          {ui_helpers.formatCurrency(t.totalAmountWithTaxAndDLW)}</td>))}
                    </tr>
                }
                <tr>
                  <td className="bg-light">
                    Standard Security Deposit
                    <FontAwesomeIcon
                      icon="info"
                      id="securityDepositInfo"
                      className="text-muted ms-2"
                    />
                    <Tooltip
                      flip
                      target="securityDepositInfo"
                      isOpen={infoTooltipOpen}
                      toggle={() => setInfoTooltipOpen(!infoTooltipOpen)}
                    >
                      $0 When Purchase Reserve Applies
                    </Tooltip>
                  </td>
                  {_.map(terms, t => (<td key={t.id} className={getCellClasses(t.id)}>
                    {ui_helpers.formatCurrency(t.minimumSecurityDepositAmount)}</td>))}
                </tr>
                <tr>
                  <td className="bg-light">Total Rental Cost (Before Tax)</td>
                  {_.map(terms, t => (<td key={t.id} className={getCellClasses(t.id)}>
                    {ui_helpers.formatCurrency(t.totalRentalAmount)}</td>))}
                </tr>
                {showTotalFinancingFees
                  ? (<tr>
                      <td className="bg-light">Total Cost of Lease Services</td>
                      {_.map(terms, t => (<td key={t.id} className={getCellClasses(t.id)}>
                        {ui_helpers.formatCurrency(t.totalFinancingFeesAmount)}</td>))}
                    </tr>)
                  : null
                }
                {hasExtraDownPayment
                  ? (<tr>
                      <td className="bg-light">Add'l Down Payment Required</td>
                      {_.map(terms, t => (<td key={t.id} className={getCellClasses(t.id)}>{t.extraDownPaymentMessage}</td>))}
                    </tr>)
                  : (<tr>
                      <td className="bg-light">Minimum Required for Delivery</td>
                      {_.map(terms, t => (<td key={t.id} className={getCellClasses(t.id)}>
                        {ui_helpers.formatCurrency(resolveMinRequiredForDelivery(t))}</td>))}
                    </tr>)
                }
                {_.some(terms, t => t.purchaseReserveAmount)
                  ? (<tr>
                      <td className="bg-light">Purchase Reserve Applied</td>
                      {_.map(terms, t => (<td key={t.id} className={getCellClasses(t.id)}>
                        {ui_helpers.formatCurrency(t.purchaseReserveAmount)}</td>))}
                    </tr>)
                  : null
                }
                {calculationWarning
                  ? (<tr>
                      <td className="bg-light" />
                      <td colSpan={terms.length} className="bg-dark text-warning">
                        {calculationWarning}
                      </td>
                    </tr>)
                  : null
                }
                {props.termsSelectable
                  ? (<tr>
                      <td className="bg-light"></td>
                      {_.map(terms, t => (<td className="bg-light" key={t.id}>
                        <Button color="primary" block onClick={() => onChangeTerm(t.id, true)}>
                          Select
                        </Button>
                      </td>))}
                    </tr>)
                  : null
                }
              </tbody>
            </Table>)
      }
    </Col>
  </Row>
  );
};

export default CalculatorTable;