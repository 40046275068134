import React, { useState, useEffect } from "react";
import { Button, Row, Col, Input, Form, Alert } from "reactstrap";
import { CREDIT_CARD_TYPES, TOP_LEVEL_VIEWS } from "../constants";
import { useAppStore } from "../hooks";
import { formatPhoneField, trim } from "../utils";
import { constants } from "../../../utils";
import RecurringPaySignatureModal from "./RecurringPaySignatureModal";

export default function RecurringPayments({
  completeRecurringPaySignature,
  handleSaveRecurringPaySignature,
  sendPaymentInfo,
  enableRecurring,
}) {
  const setCurrentView = useAppStore((state) => state.setCurrentView);
  const paperlessEmail = useAppStore((x) => x.paperlessEmail);
  const defaultPaymentData = useAppStore((x) => x.contractPaymentData);
  const setRecurringPaySignatureModalOpen = useAppStore((x) => x.setRecurringPaySignatureModalOpen);
  const isRecurringAccept = useAppStore((x) => x.isRecurringAccept);
  const setIsRecurringAccept = useAppStore((x) => x.setIsRecurringAccept);
  const [showPaymentForm, setShowPaymentForm] = useState(false);
  const [warnings, setWarnings] = useState([]);
  const [paymentData, setPaymentData] = useState({
    achAccountNo: "",
    achFullName: "",
    achRoutingNo: "",
    achAccountType: "", // constants.BANKING_ACCOUNT_TYPES[0].label,
    achBankName: '',
    ccExpiryMonth: "",
    ccExpiryYear: "",
    ccFullName: "",
    ccNumber: "",
    creditCardType: "", // CREDIT_CARD_TYPES[0].value,
    paymentDayOfMonth: "",
    recurringBillingAddress: defaultPaymentData?.address,
    recurringBillingCity: defaultPaymentData?.city,
    recurringBillingEmail: paperlessEmail,
    recurringBillingPhone: defaultPaymentData?.phone,
    recurringBillingState: defaultPaymentData?.state,
    recurringBillingZip: defaultPaymentData?.zip,
  });

  useEffect(() => {
    if (isRecurringAccept) {
      sendPaymentInfo(paymentData);
      setIsRecurringAccept(false);
      setCurrentView(TOP_LEVEL_VIEWS.SIGNING);
    }
  }, [isRecurringAccept, paymentData])

  function onChange(value, fieldName) {
    const newData = {
      ...paymentData,
      [fieldName]: value,
    };
    setPaymentData(newData);
  }

  function handleNo() {
    setCurrentView(TOP_LEVEL_VIEWS.SIGNING);
  }

  function handleYes() {
    enableRecurring();
    setShowPaymentForm(true);
  }

  function handleSubmit() {
    const _warnings = [];
    if (
      trim(paymentData.ccNumber).length < 4 &&
      trim(paymentData.achAccountNo).length < 4
    ) {
      _warnings.push(
        "Enter a valid account number - either credit card or ACH."
      );
    }
    if (trim(paymentData.ccNumber).length >= 4) {
      // credit card validation
      if (!paymentData.creditCardType) {
        _warnings.push("Select a credit card type.");
      }
    } else {
      // ACH validation
      if (trim(paymentData.achRoutingNo).length < 4) {
        _warnings.push("Enter a valid ACH routing number.");
      }
      if (trim(paymentData.achFullName).length < 4) {
        _warnings.push("Enter a valid ACH account holder full name.");
      }
      if (_.trim(paymentData.achBankName).length < 4) {
        _warnings.push("Enter a valid ACH bank name.");
      }
    }
    if (_warnings.length > 0) {
      setWarnings(_warnings);
      return;
    }
    if (paperlessEmail && !paymentData.recurringBillingEmail) {
      paymentData.recurringBillingEmail = paperlessEmail;
    }
    setRecurringPaySignatureModalOpen(true);
  }

  function onBlur(value, fieldName) {
    switch (fieldName) {
      case "paymentDayOfMonth":
        let intVal = parseInt(value, 10);
        if (intVal < 1 || intVal > 28) {
          value = "";
          onChange(value, fieldName);
        }
        break;
      case "recurringBillingPhone":
        onChange(formatPhoneField(value), fieldName);
        break;
      // case 'ccExpiryMonth':
      // case 'ccNumber':
      // case 'ccExpiryYear':
      // case 'recurringBillingEmail':
      // case 'recurringBillingZip':
      // 	break;
    }
  }

  return (
    <>
      <div className="vw-100 vh-100 position-fixed d-flex flex-column align-items-center justify-content-center text-center xs:overflow-y-auto lg:overflow-none">
        {!showPaymentForm ? (
          <>
            <h3>Would you like to set up recurring payments?</h3>
            <div className="my-5">
              <Button
                size="lg"
                color="dark"
                className="me-3"
                onClick={handleNo}
              >
                No, thanks
              </Button>
              <Button
                size="lg"
                color="primary"
                className="ms-3"
                onClick={handleYes}
              >
                Yes!
              </Button>
            </div>
          </>
        ) : (
          <>
            <h3 style={{marginTop: 20}}>Enter payment information:</h3>
            <div className="m-3 text-start" style={{overflow: "auto"}}>
              <Form id="recurring-payment-form" className="form-horizontal">
                <div
                  style={{
                    backgroundColor: "#E5E5FF",
                    borderRadius: "4px",
                    padding: "4px",
                    fontSize: "0.85rem",
                  }}
                >
                  <div style={{ fontWeight: "bold", fontSize: "16px" }}>
                    AUTHORIZATION
                  </div>
                  <div>
                    I authorize the charge or charges to my account as outlined
                    in this form. I understand and agree that the monthly charge
                    will continue until the total amount due is collected or
                    until I revoke this authorization. This authorization is
                    also applicable to any new account information, payments,
                    and/or payment dates provided by me in future for the
                    purpose of completing my account.
                  </div>
                  <div>
                    <span style={{ fontWeight: "bold", color: "#ff0000" }}>
                      CHANGE OF INFORMATION:
                    </span>{" "}
                    I agree to notify verbally or in writing to the above
                    address fifteen (15) or more days prior to any change to the
                    account and/or closing of the account or any change or
                    situation that may affect charging the payment.
                  </div>
                  <div>
                    <span style={{ fontWeight: "bold", color: "#ff0000" }}>
                      CANCELLATION:
                    </span>{" "}
                    Upon payment in full, I understand that the RTO company will
                    suspend these payments and provide me with a receipt showing
                    that I have acquired ownership of the rented property. I can
                    also request cancellation at any time by phone.
                  </div>
                </div>
                {warnings?.length > 0 ? (
                  <Alert color="danger" className="p-1 px-3 mt-3">
                    <ul className="list-unstyled">
                      {warnings.map((w) => (
                        <li key={w}>{w}</li>
                      ))}
                    </ul>
                  </Alert>
                ) : null}
                <Row className="mb-1 mt-1">
                  <Col sm="3" className="pt-1">
                    <label className="control-label">Preferred Due Date</label>
                  </Col>
                  <Col sm="9">
                    <Input
                      type="number"
                      bsSize="sm"
                      maxLength="2"
                      value={paymentData.paymentDayOfMonth}
                      style={{ width: "50px" }}
                      onChange={(e) => onChange(e.target.value, "paymentDayOfMonth")}
                      onBlur={(e) => onBlur(e.target.value, "paymentDayOfMonth")}
                    />
                  </Col>
                </Row>
                {paperlessEmail
                  ? null
                  : (<Row className="mb-1">
                      <Col sm="3">
                        <label className="control-label">Email</label>
                      </Col>
                      <Col sm="9">
                        <Input
                          type="text"
                          bsSize="sm"
                          value={paymentData.recurringBillingEmail}
                          maxLength="150"
                          placeholder="Enter email address here for paperless billing"
                          onChange={(e) =>
                            onChange(e.target.value, "recurringBillingEmail")
                          }
                          onBlur={(e) =>
                            onBlur(e.target.value, "recurringBillingEmail")
                          }
                        />
                      </Col>
                    </Row>)
                }                
                <Row className="mb-1">
                  <Col sm="3">
                    <label className="control-label">Phone</label>
                  </Col>
                  <Col sm="9">
                    <Input
                      type="text"
                      bsSize="sm"
                      value={paymentData.recurringBillingPhone}
                      maxLength="20"
                      onChange={(e) =>
                        onChange(e.target.value, "recurringBillingPhone")
                      }
                      onBlur={(e) =>
                        onBlur(e.target.value, "recurringBillingPhone")
                      }
                    />
                  </Col>
                </Row>
                <Row className="mb-1">
                  <Col sm="3">
                    <label className="control-label">Address</label>
                  </Col>
                  <Col sm="9">
                    <Input
                      type="text"
                      bsSize="sm"
                      value={paymentData.recurringBillingAddress}
                      maxLength="200"
                      style={{
                        width: "300px",
                        marginBottom: "2px",
                        marginLeft: "0",
                      }}
                      placeholder="Billing Address"
                      onChange={(e) =>
                        onChange(e.target.value, "recurringBillingAddress")
                      }
                    />
                    <div>
                      <Input
                        type="text"
                        bsSize="sm"
                        value={paymentData.recurringBillingCity}
                        maxLength="50"
                        style={{
                          width: "147px",
                          float: "left",
                          marginLeft: "0",
                          clear: "both",
                        }}
                        placeholder="City"
                        onChange={(e) =>
                          onChange(e.target.value, "recurringBillingCity")
                        }
                      />
                      <div
                        style={{
                          float: "left",
                          width: "15px",
                          marginTop: "10px",
                        }}
                      >
                        ,{" "}
                      </div>
                      <Input
                        type="text"
                        bsSize="sm"
                        value={paymentData.recurringBillingState}
                        maxLength="20"
                        style={{ width: "45px", float: "left" }}
                        placeholder="ST"
                        onChange={(e) =>
                          onChange(e.target.value, "recurringBillingState")
                        }
                      />
                      <Input
                        type="text"
                        bsSize="sm"
                        value={paymentData.recurringBillingZip}
                        maxLength="20"
                        style={{
                          width: "90px",
                          marginLeft: "3px",
                          float: "left",
                        }}
                        placeholder="Zip"
                        onChange={(e) =>
                          onChange(e.target.value, "recurringBillingZip")
                        }
                        onBlur={(e) =>
                          onBlur(e.target.value, "recurringBillingZip")
                        }
                      />
                    </div>
                  </Col>
                </Row>
                <Row className="mb-1">
                  <Col sm="3">
                    <label className="control-label">Credit Card</label>
                  </Col>
                  <Col sm="9">
                    <div style={{ width: "60%", fontSize: "0.9rem" }}>
                      <Input
                        type="select"
                        bsSize="sm"
                        value={paymentData.creditCardType}
                        onChange={(e) =>
                          onChange(e.target.value, "creditCardType")
                        }
                      >
                        <option value="">
                          -
                        </option>
                        {CREDIT_CARD_TYPES.map((c) => (
                          <option key={c.label} value={c.value}>
                            {c.label}
                          </option>
                        ))}
                      </Input>
                    </div>
                    <div className="mt-1">
                      <Input
                        type="text"
                        bsSize="sm"
                        value={paymentData.ccNumber}
                        maxLength="20"
                        style={{
                          width: "200px",
                          marginLeft: "0",
                          float: "left",
                        }}
                        placeholder="Card Number"
                        onChange={(e) => onChange(e.target.value, "ccNumber")}
                        onBlur={(e) => onBlur(e.target.value, "ccNumber")}
                      />
                      <span
                        style={{
                          float: "left",
                          padding: "10px 2px 4px 2px",
                          fontSize: "0.9rem",
                        }}
                      >
                        expires
                      </span>
                      <Input
                        type="text"
                        bsSize="sm"
                        value={paymentData.ccExpiryMonth}
                        maxLength="2"
                        style={{ width: "40px", float: "left" }}
                        placeholder="MM"
                        onChange={(e) =>
                          onChange(e.target.value, "ccExpiryMonth")
                        }
                        onBlur={(e) => onBlur(e.target.value, "ccExpiryMonth")}
                      />
                      <Input
                        type="text"
                        bsSize="sm"
                        value={paymentData.ccExpiryYear}
                        maxLength="4"
                        style={{
                          width: "60px",
                          marginLeft: "1px",
                          float: "left",
                        }}
                        placeholder="YYYY"
                        onChange={(e) =>
                          onChange(e.target.value, "ccExpiryYear")
                        }
                        onBlur={(e) => onBlur(e.target.value, "ccExpiryYear")}
                      />
                      <br />
                      <Input
                        type="text"
                        bsSize="sm"
                        value={paymentData.ccFullName}
                        maxLength="70"
                        style={{ float: "left" }}
                        placeholder="Cardholder Full Name"
                        onChange={(e) => onChange(e.target.value, "ccFullName")}
                      />
                    </div>
                  </Col>
                </Row>
                <Row className="mb-1">
                  <Col sm="3">
                    <label className="control-label">ACH</label>
                  </Col>
                  <Col sm="9">
                    <Input
                      type="text"
                      bsSize="sm"
                      value={paymentData.achRoutingNo}
                      maxLength="50"
                      style={{ marginBottom: "2px", float: "left" }}
                      placeholder="Routing Number"
                      onChange={(e) => onChange(e.target.value, "achRoutingNo")}
                    />
                    <Input
                      type="text"
                      bsSize="sm"
                      value={paymentData.achAccountNo}
                      maxLength="50"
                      style={{ marginBottom: "2px", float: "left" }}
                      placeholder="Account Number"
                      onChange={(e) => onChange(e.target.value, "achAccountNo")}
                    />
                    <Input
                      type="text"
                      bsSize="sm"
                      value={paymentData.achFullName}
                      maxLength="70"
                      style={{ float: "left" }}
                      placeholder="Account Holder Full Name"
                      onChange={(e) => onChange(e.target.value, "achFullName")}
                    />
                  </Col>
                </Row>
                <Row className="mb-1">
                  <Col sm="3">
                    <label className="control-label">Account Type</label>
                  </Col>
                  <Col sm="9">
{/*                    <Select
                      options={constants.BANKING_ACCOUNT_TYPES}
                      onChange={(option) => onChange(option.label, 'achAccountType')}
                      value={_.find(constants.BANKING_ACCOUNT_TYPES, a => a.label === paymentData.achAccountType)}
                    />
*/}
                      <Input
                        type="select"
                        bsSize="sm"
                        value={paymentData.achAccountType}
                        onChange={(e) =>
                          onChange(e.target.value, "achAccountType")
                        }
                      >
                        <option value="">
                          -
                        </option>
                        {constants.BANKING_ACCOUNT_TYPES.map((c) => (
                          <option key={c.label} value={c.label}>
                            {c.label}
                          </option>
                        ))}
                      </Input>
                  </Col>
                </Row>
                <Row className="mb-1">
                  <Col sm={{size: 9, offset: 3}}>
                    <Input type='text'
                      placeholder="Bank Name"
                      bsSize="sm"
                      value={paymentData.achBankName}
                      maxLength="70"
                      onChange={(e) => onChange(e.target.value, 'achBankName')}
                    />
                  </Col>
                </Row>
                <Row className="w-100" style={{marginBottom: 20}}>
                  <Col sm={8}></Col>
                  <Col sm={4}>
                    <Button size="lg" className="float-end m-1" color="secondary" onClick={() => setCurrentView(TOP_LEVEL_VIEWS.SIGNING)}>
                      Nevermind
                    </Button>
                    {/* me-3 */}
                    <Button size="lg" className="float-end m-1" color="primary" onClick={handleSubmit}>
                      Proceed to Signing!
                    </Button>
                  </Col>
                </Row>
              </Form>
            </div>
          </>
        )}
      </div>
      <RecurringPaySignatureModal
        completeRecurringPaySignature={completeRecurringPaySignature}
        handleSaveRecurringPaySignature={handleSaveRecurringPaySignature}
      />
    </>
  );
}
