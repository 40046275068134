import React, { useMemo } from "react";
import { Button } from "reactstrap";
import { useAppStore } from "../hooks";
import Logo from "../assets/logo.png";
import { BsCaretLeftFill, BsCaretRightFill } from "react-icons/bs";
import classNames from "classnames";

export default function PdfToolbarTop({isVisible}) {
  const showOverlays = useAppStore((x) => x.showOverlays);
  const toggleShowOverlays = useAppStore((x) => x.toggleShowOverlays);
  const signatureLocations = useAppStore((x) => x.signatureLocations);
  const contractId = useAppStore((x) => x.contractId);

  const currentSignatureLocationIndex = useMemo(() => {
    const firstIntersecting = signatureLocations.findIndex(
      (x) => x.isIntersecting
    );
    return firstIntersecting >= 0 ? firstIntersecting : 0;
  }, [signatureLocations]);

  function prevSignature() {
    const prevIndex = currentSignatureLocationIndex - 1;
    let el;
    if (prevIndex >= 0 && prevIndex <= signatureLocations.length) {
      el = document.getElementById("sl-" + prevIndex);
    } else {
      el = document.getElementById("sl-" + (signatureLocations.length - 1));
    }
    if (el) el.scrollIntoView(true);
  }

  function nextSignature() {
    const nextIndex = currentSignatureLocationIndex + 1;
    let el;
    if (nextIndex >= 0 && nextIndex < signatureLocations.length) {
      el = document.getElementById("sl-" + nextIndex);
    } else {
      el = document.getElementById("sl-0");
    }
    if (el) el.scrollIntoView(true);
  }

  return (
    <div
      className={classNames("w-100 pe-4 bg-light position-fixed shadow p-2 justify-content-between align-items-center", isVisible ? 'd-flex' : 'd-none')}
      style={{ top: 0, left: 0, height: "4rem", zIndex: 998, margin: 0 }}
    >
      <img src={Logo} className="h-100" />
      <div className="d-flex align-items-center">
        {currentSignatureLocationIndex === -1 ? null : (
          <Button
            color="light"
            className="p-0"
            title="Previous Signature"
            onClick={prevSignature}
          >
            <BsCaretLeftFill />
          </Button>
        )}
        <div className="px-3">
          <strong className="text-danger">
            {signatureLocations.filter((x) => x.img || x.img2).length}/
            {signatureLocations.length}{" "}
          </strong>
          Signatures Completed
        </div>
        {currentSignatureLocationIndex === -1 ? null : (
          <Button
            color="light"
            className="p-0"
            title="Next Signature"
            onClick={nextSignature}
          >
            <BsCaretRightFill />
          </Button>
        )}
      </div>
      <Button color="dark" size="sm" onClick={toggleShowOverlays}>
        {showOverlays ? "Hide Overlays" : "Show Overlays"}
      </Button>
    </div>
  );
}
