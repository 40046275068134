import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import _ from "lodash";
import {
  Button, Col,
  Form,
  FormGroup,
  Input,
  InputGroup,
  Label, Row,
} from "reactstrap";
import CurrencyInput from "react-currency-input-field";
import { api } from "../utils";
import { object_helpers } from "../helpers";
import { constants } from "../utils";
import {ConfirmDeleteModal} from "./index";
import Alert from "react-s-alert-v3";
const { deepCopyObject } = object_helpers;

export default function EditSubProductInfo({mfgId, existingSubProduct, productTypeOpts}) {
  const [isLoading, setIsLoading] = useState(true);
  const [subProduct, setSubProduct] = useState(existingSubProduct || {});
  const [message, setMessage] = useState(constants.ALERT_OBJECT);
  const [confirmDelete, setConfirmDelete] = useState(false);

  function handleChange(property, value) {
    const newSubProduct = deepCopyObject(subProduct);
    newSubProduct[property] = value;
    setSubProduct(newSubProduct);
  }

  async function handleSubmit() {
    const payload = {
      id: subProduct.id,
      manufacturerId: mfgId,
      name: subProduct.name,
      abbr: subProduct.abbr,
      productTypeId: subProduct.productTypeId,
    };
    const res = await api.post("manufacturer/SaveSubProduct", payload);
    if (!res.data.success) {
      Alert.error("res.data.message");
      return;
    }
    Alert.success("Changes saved successfully");
  }

  function toggleActive() {
    api.post(`manufacturer/ToggleSubProductActive/${subProduct.id}`).then(r => {
      if(!r.data.success) {
        Alert.error(r.data.message);
        return;
      }
      Alert.success(`Successfully deleted ${subProduct.name}`);
      setConfirmDelete(false);
    });
  }

  return (
    <>
      <ConfirmDeleteModal open={confirmDelete} onCancelCallback={() => setConfirmDelete(false)} onDeleteCallback={toggleActive}/>
      <h3>Product Info:</h3>
      {message.message ? (
        <Alert flavor={message.flavor} className="text-center">
          {message.message}
        </Alert>
      ) : null}
      <Form>
        <FormGroup>
          <Label>Product Name:</Label>
          <Input
            type="text"
            placeholder="Enter Name"
            value={subProduct.name}
            onChange={(e) => handleChange("name", e.target.value)}
          />
        </FormGroup>
        <FormGroup>
          <Label>Abbreviation:</Label>
          <Input
            type="text"
            placeholder="Enter Abbreviation"
            value={subProduct.abbr}
            onChange={(e) => handleChange("abbr", e.target.value)}
          />
        </FormGroup>
        <FormGroup>
          <Label>Product Type:</Label>
          <Input
            type="select"
            value={subProduct.productTypeId}
            onChange={(e) => handleChange("productTypeId", e.target.value)}
          >
            {productTypeOpts &&
              _.map(productTypeOpts, (o) => (
                <option key={o.id} value={o.id}>
                  {o.name}
                </option>
              ))}
          </Input>
        </FormGroup>
        <Row className={'justify-content-start'}>
          <Col sm={"2"} className={'justify-content-center'}>
            <Button color="primary" size="lg" onClick={handleSubmit} className={'float-center'}>
              Save Changes
            </Button>
          </Col>
          <Col sm={"1"}>
            <Button className={subProduct.deactivatedAt ? 'bg-success border-success' : 'btn-delete'} size={'lg'} onClick={(e) =>
                setConfirmDelete(true)}>
              {subProduct.deactivatedAt ? 'Revive' : 'Delete'}
            </Button>
          </Col>
        </Row>
      </Form>
    </>
  );
}