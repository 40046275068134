import React, {useState, useEffect, Fragment} from "react";
import {
  Row,
  Col,
  FormGroup,
  Form,
  Input,
  Label,
  InputGroup,
  Button,
} from "reactstrap";
import _ from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { api, ui_helpers } from "../helpers";
import { constants } from "../utils";
import classnames from 'classnames';
import Select from 'react-select'
import CFLogo from "../assets/img/CFLogo.png";

const defaultCustomerData = (existing) => {
  if (!existing)
    existing = {};
  return {
    propertyOwner: (existing.propertyOwner || false),
    landOwnerFirstName: (existing.landOwnerFirstName || ""),
    landOwnerLastName: (existing.landOwnerLastName || ""),
    landOwnerAddress1: (existing.landOwnerAddress1 || ""),
    landOwnerAddress2: (existing.landOwnerAddress2 || ""),
    landOwnerCity: (existing.landOwnerCity || ""),
    landOwnerState: (existing.landOwnerState || ""),
    landOwnerZip: (existing.landOwnerZip || ""),
    landOwnerPhone: (existing.landOwnerPhone || ""),
    landOwnerEmail: (existing.landOwnerEmail || ""),
    employmentType: existing.employmentType,
    employerName: (existing.employerName || ""),
    employerPhone: (existing.employerPhone || ""),
    contact1FirstName: (existing.contact1FirstName || ""),
    contact1LastName: (existing.contact1LastName || ""),
    contact1Phone: (existing.contact1Phone || ""),
    contact1Email: (existing.contact1Email || ""),
    contact1Relationship: (existing.contact1Relationship || ""),
    contact2FirstName: (existing.contact2FirstName || ""),
    contact2LastName: (existing.contact2LastName || ""),
    contact2Phone: (existing.contact2Phone || ""),
    contact2Email: (existing.contact2Email || ""),
    contact2Relationship: (existing.contact2Relationship || ""),
    corenterFirstName: (existing.corenterFirstName || ""),
    corenterLastName: (existing.corenterLastName || ""),
    corenterAddress1: (existing.corenterAddress1 || ""),
    corenterAddress2: (existing.corenterAddress2 || ""),
    corenterCity: (existing.corenterCity || ""),
    corenterState: (existing.corenterState || ""),
    corenterZip: (existing.corenterZip || ""),
    corenterDateOfBirth: (existing.corenterDateOfBirth || ""),
    corenterPhone: (existing.corenterPhone || ""),
    corenterEmail: (existing.corenterEmail || "")
    // employerName: "Dudley's",
    // employerPhone: "336-782-4684",
    // contact1FirstName: "Ali",
    // contact1LastName: "Tahbaz",
    // contact1Phone: "336-782-4684",
    // contact1Email: (existing.contact1Email || ""),
    // contact1Relationship: "me",
  };
};

const CustomerInformation2 = (props) => {
  const defaultCustomer = defaultCustomerData(props.contractData);
  const [customerData, setCustomerData] = useState(defaultCustomer);
  let initialContactCount = 2;
  while(defaultCustomer[`contact${initialContactCount}LastName`] !== undefined) {
    initialContactCount++;
  }
  const [contactCount, setContactCount] = useState(initialContactCount-1);
  const [hasCorenter, setHasCorenter] = useState(false);
  const [screenWarnings, setScreenWarnings] = useState({});

  useEffect(() => {
    const defaultCustomer = defaultCustomerData(props.contractData);
    setCustomerData(defaultCustomer);
    let initialContactCount = 2;
    while(defaultCustomer[`contact${initialContactCount}LastName`] !== undefined) {
      initialContactCount++;
    }
    setContactCount(initialContactCount-1);
  }, [props.contractData])

  useEffect(() => {
    let newScreenWarnings = {};
    _.each(props.fieldWarnings, w => Object.assign(newScreenWarnings, {[w.field]: w.text}))
    setScreenWarnings(newScreenWarnings);
  }, [props.fieldWarnings])

  function onBlur(value, fieldName) {
    switch(fieldName) {
      case 'corenterDateOfBirth':
        setCustomerData({
          ...customerData,
          [fieldName]: ui_helpers.formatDateField(value)
        });
        break;
      case 'corenterPhone':
        setCustomerData({
          ...customerData,
          [fieldName]: ui_helpers.formatPhoneField(value)
        });
        break;
      case 'employerPhone':
      case 'landOwnerPhone':
      case 'contact1Phone':
      case 'contact2Phone':
      case 'contact3Phone':
      case 'contact3Phone':
        setCustomerData({
          ...customerData,
          [fieldName]: ui_helpers.formatPhoneField(value)
        });
      break;
    }
  }

  function onChange(value, fieldName) {
    const newData = {
      ...customerData,
      [fieldName]: value
    };
    setCustomerData(newData);
    props.onSetData(newData);
  }

  function onAddCorenter() {
    setHasCorenter(true);
  }

  function onRemoveCorenter() {
    setHasCorenter(false);
  }

  return (
    <Form className="mt-2">
      <Row>
        <Col>
          <div className='mb-1' style={{cursor: "pointer"}} onClick={(e) => {
              e.stopPropagation();
              onChange(!customerData.propertyOwner, 'propertyOwner');
            }} >
            <Input type="checkbox" checked={customerData.propertyOwner} onChange={(e) => {
              e.stopPropagation();
              onChange(!customerData.propertyOwner, 'propertyOwner');
            }} /> Property Owner
          </div>
          {customerData.propertyOwner
            ? null
            : (<Fragment>
                <div className="mb-2">
                  <Label>
                    Landowner Details
                  </Label>
                  <Row>
                    <Col xs="1"><div className="star-box">{ui_helpers.requiredStar()}</div></Col>
                    <Col>
                      <Input type="text" bsSize="sm" maxLength="25" placeholder="Landowner First Name"  style={{width: "90%"}}
                        title={screenWarnings.landOwnerFirstName ? screenWarnings.landOwnerFirstName : null}
                        className={classnames({invalid: (screenWarnings.landOwnerFirstName ? true : false)})}
                        value={customerData.landOwnerFirstName} onChange={(e) => onChange(e.target.value, 'landOwnerFirstName')} />
                    </Col>
                  </Row>
                </div>
                <div className="mb-2">
                  <Row>
                    <Col xs="1"><div className="star-box">{ui_helpers.requiredStar()}</div></Col>
                    <Col>
                      <Input type="text" bsSize="sm" maxLength="25" placeholder="Landowner Last Name"  style={{width: "90%"}}
                        title={screenWarnings.landOwnerLastName ? screenWarnings.landOwnerLastName : null}
                        className={classnames({invalid: (screenWarnings.landOwnerLastName ? true : false)})}
                        value={customerData.landOwnerLastName} onChange={(e) => onChange(e.target.value, 'landOwnerLastName')} />
                    </Col>
                  </Row>
                </div>
                <div className="mb-1">
                  <Row>
                    <Col xs="1"><div className="star-box">{ui_helpers.requiredStar()}</div></Col>
                    <Col>
                      <Input type="text" bsSize="sm" maxLength="100" placeholder="Landowner Address 1" style={{width: "90%"}} 
                        title={screenWarnings.landOwnerAddress1 ? screenWarnings.landOwnerAddress1 : null}
                        className={classnames({invalid: (screenWarnings.landOwnerAddress1 ? true : false)})}
                        value={customerData.landOwnerAddress1} onChange={(e) => onChange(e.target.value, 'landOwnerAddress1')} />
                    </Col>
                  </Row>
                </div>
                <div className="mb-1">
                  <Row>
                    <Col xs="1"><div className="star-box mr-4" ></div></Col>
                    <Col>
                      <Input
                        type="text"
                        bsSize="sm"
                        maxLength="100"
                        placeholder="Landowner Address 2 (optional)" style={{width: "90%"}}
                        value={customerData.landOwnerAddress2} onChange={(e) => onChange(e.target.value, 'landOwnerAddress2')} 
                      />
                    </Col>
                  </Row>
                </div>
                <div className="mb-2">
                  <Row>
                    <Col xs="1"><div className="star-box">{ui_helpers.requiredStar()}</div></Col>
                    <Col>
                      <Row>
                        <Col>
                          <Input bsSize="sm" maxLength="50" type="text" placeholder="City" style={{float: "left", width: "45%"}}
                            title={screenWarnings.landOwnerCity ? screenWarnings.landOwnerCity : null}
                            className={classnames({invalid: (screenWarnings.landOwnerCity ? true : false)})}
                            value={customerData.landOwnerCity} onChange={(e) => onChange(e.target.value, 'landOwnerCity')} />
                          <Input bsSize="sm" maxLength="2" type="text" placeholder="ST" style={{float: "left", width: "2.3rem", marginLeft: "2px"}}
                            title={screenWarnings.landOwnerState ? screenWarnings.landOwnerState : null}
                            className={classnames({invalid: (screenWarnings.landOwnerState ? true : false)})}
                            value={customerData.landOwnerState} onChange={(e) => onChange(e.target.value, 'landOwnerState')} />
                          <Input bsSize="sm" maxLength="20" type="text" placeholder="Zip" style={{float: "left", width: "3.5rem", marginLeft: "2px"}}
                            title={screenWarnings.landOwnerZip ? screenWarnings.landOwnerZip : null}
                            className={classnames({invalid: (screenWarnings.landOwnerZip ? true : false)})}
                            value={customerData.landOwnerZip} onChange={(e) => onChange(e.target.value, 'landOwnerZip')} />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </div>
                <div className="mb-2">
                  <Row>
                    <Col xs="1">
                      <div className="star-box">{ui_helpers.requiredStar()}</div>
                    </Col>
                    <Col>
                      <Input type="tel" placeholder="Landowner Phone" bsSize="sm" maxLength="20" style={{width: "90%"}} 
                        title={screenWarnings.landOwnerPhone ? screenWarnings.landOwnerPhone : null}
                        className={classnames({invalid: (screenWarnings.landOwnerPhone ? true : false)})}
                        value={customerData.landOwnerPhone} onBlur={(e) => onBlur(e.target.value, 'landOwnerPhone')} onChange={(e) => onChange(e.target.value, 'landOwnerPhone')} />
                    </Col>
                  </Row>
                </div>
                <div className="mb-2">
                  <Row>
                    <Col xs="1">
                      <div className="star-box mr-4" ></div>
                    </Col>
                    <Col>
                      <Input type="email" placeholder="Landowner Email" bsSize="sm" maxLength="150" style={{width: "90%"}}  value={customerData.landOwnerEmail} onChange={(e) => onChange(e.target.value, 'landOwnerEmail')} />
                    </Col>
                  </Row>
                </div>
                </Fragment>)
          }
        </Col>
        <Col className="form-col-even">
          <div className="mb-2">
            <Label>
              Employment Type {ui_helpers.requiredStar()}
            </Label>
            <div title={screenWarnings.employmentType ? screenWarnings.employmentType : null}>
              <Select
                options={props.employmentTypes}
                classNamePrefix={screenWarnings.employmentType ? "invalidSelect" : null}
                value={customerData.employmentType}
                onChange={(x) => onChange(x, 'employmentType')}
              />
            </div>
          </div>
          {customerData.employmentType && constants.EMPLOYMENT_TYPES_REQUIRING_EMPLOYER.includes(customerData.employmentType.label)
            ? (<Fragment>
                <div className="mb-2">
                  <Label>Employer Details</Label>
                  <Row>
                    <Col xs='1'><div className="star-box">{ui_helpers.requiredStar()}</div></Col>
                    <Col>
                      <Input type="text" bsSize="sm" placeholder="Business Name" 
                        title={screenWarnings.employerName ? screenWarnings.employerName : null}
                        className={classnames({invalid: (screenWarnings.employerName ? true : false)})}
                        value={customerData.employerName} maxLength="100" onChange={(e) => onChange(e.target.value, 'employerName')} />
                    </Col>
                  </Row>
                </div>
                <div className="mb-2">
                  <Row>
                    <Col xs="1">
                      <div className="star-box mr-4" ></div>
                    </Col>
                    <Col>
                      <Input type="text" bsSize="sm" placeholder="Business Phone" maxLength="20" 
                        value={customerData.employerPhone} onBlur={(e) => onBlur(e.target.value, 'employerPhone')} onChange={(e) => onChange(e.target.value, 'employerPhone')} />
                    </Col>
                  </Row>
                </div>
              </Fragment>)
            : null
          }
        </Col>
        <Col>
          {_.map(_.range(1, contactCount + 1), cidx => {
            const starBox = (<div className="star-box">{cidx === 1 ? ui_helpers.requiredStar() : <span>&nbsp;</span>}</div>);
            return (<div key={cidx}>
              <Label>
                Additional Contact {cidx}
              </Label>
              <div className="mb-1">
                {starBox}
                <Input type="text" bsSize="sm" style={{width: "90%"}} placeholder="First Name" maxLength="25" 
                  title={screenWarnings[`contact${cidx}FirstName`] ? screenWarnings[`contact${cidx}FirstName`] : null}
                  className={classnames({invalid: (screenWarnings[`contact${cidx}FirstName`] ? true : false)})}
                  value={customerData[`contact${cidx}FirstName`]} onChange={(e) => onChange(e.target.value, `contact${cidx}FirstName`)} />
              </div>
              <div className="mb-1">
                {starBox}
                <Input type="text" bsSize="sm" style={{width: "90%"}} placeholder="Last Name" maxLength="25" 
                  title={screenWarnings[`contact${cidx}LastName`] ? screenWarnings[`contact${cidx}LastName`] : null}
                  className={classnames({invalid: (screenWarnings[`contact${cidx}LastName`] ? true : false)})}
                  value={customerData[`contact${cidx}LastName`]} onChange={(e) => onChange(e.target.value, `contact${cidx}LastName`)} />
              </div>
              <div className="mb-1">
                {starBox}
                <Input type="text" bsSize="sm" style={{width: "90%"}} placeholder="Phone" maxLength="20" 
                  title={screenWarnings[`contact${cidx}Phone`] ? screenWarnings[`contact${cidx}Phone`] : null}
                  className={classnames({invalid: (screenWarnings[`contact${cidx}Phone`] ? true : false)})}
                  value={customerData[`contact${cidx}Phone`]} onBlur={(e) => onBlur(e.target.value, `contact${cidx}Phone`)}  onChange={(e) => onChange(e.target.value, `contact${cidx}Phone`)} />
              </div>
              <div className="mb-1">
                <div className="star-box">&nbsp;</div>
                <Input type="text" bsSize="sm" style={{width: "90%"}} placeholder="Email (Optional)" maxLength="150" 
                  title={screenWarnings[`contact${cidx}Email`] ? screenWarnings[`contact${cidx}Email`] : null}
                  className={classnames({invalid: (screenWarnings[`contact${cidx}Email`] ? true : false)})}
                  value={customerData[`contact${cidx}Email`]} onChange={(e) => onChange(e.target.value, `contact${cidx}Email`)} />
              </div>
              <div className="mb-1">
                {starBox}
                <Input type="text" bsSize="sm" style={{width: "90%"}} placeholder="Relationship" maxLength="100" 
                  title={screenWarnings[`contact${cidx}Relationship`] ? screenWarnings[`contact${cidx}Relationship`] : null}
                  className={classnames({invalid: (screenWarnings[`contact${cidx}Relationship`] ? true : false)})}
                  value={customerData[`contact${cidx}Relationship`]} onChange={(e) => onChange(e.target.value, `contact${cidx}Relationship`)} />
              </div>
            </div>)})}
          <Button color="info" className="float-end text-light mb-1" size="sm" onClick={() => setContactCount(contactCount+1)}>
            <FontAwesomeIcon icon='plus' /> Add Additional Contact
          </Button>
        </Col>
        <Col className="form-col-even">
          <Row>
            <Col>
              {hasCorenter
                ? (<Fragment>
                    <Button color="danger" className="text-light" size="sm" onClick={onRemoveCorenter}>
                      Remove Corenter
                    </Button>
                    <div className="mt-2">
                      <Label>
                        Corenter Details
                      </Label>
                    </div>
                    <div className="mb-1">
                      <Input type="text" bsSize="sm" maxLength="25" autoComplete="off" placeholder="Corenter First Name" 
                        title={screenWarnings.corenterFirstName ? screenWarnings.corenterFirstName : null}
                        className={classnames({invalid: (screenWarnings.corenterFirstName ? true : false)})}
                        value={customerData.corenterFirstName} onChange={(e) => onChange(e.target.value, 'corenterFirstName')} />
                    </div>
                    <div className="mb-1">
                      <Input type="text" bsSize="sm" maxLength="25" autoComplete="off" placeholder="Corenter Last Name" value={customerData.corenterLastName} onChange={(e) => onChange(e.target.value, 'corenterLastName')} />
                    </div>
                    <div className="mb-1">
                      <Input type="text" bsSize="sm" maxLength="100" placeholder="Corenter Address 1" value={customerData.corenterAddress1} onChange={(e) => onChange(e.target.value, 'corenterAddress1')} />
                    </div>
                    <div className="mb-1">
                      <Input
                        type="text"
                        bsSize="sm"
                        maxLength="100"
                        placeholder="Corenter Address 2 (optional)"
                        value={customerData.corenterAddress2} onChange={(e) => onChange(e.target.value, 'corenterAddress2')} 
                      />
                    </div>
                    <div>
                      <Row>
                        <Col xs={6}>
                          <Input bsSize="sm" maxLength="50" type="text" placeholder="City" value={customerData.corenterCity} onChange={(e) => onChange(e.target.value, 'corenterCity')} />
                        </Col>
                        <Col xs={2}>
                          <Input bsSize="sm" maxLength="2" type="text" placeholder="ST" value={customerData.corenterState} onChange={(e) => onChange(e.target.value, 'corenterState')} />
                        </Col>
                        <Col xs={4}>
                          <Input bsSize="sm" maxLength="20" type="text" placeholder="Zip" value={customerData.corenterZip} onChange={(e) => onChange(e.target.value, 'corenterZip')} />
                        </Col>
                      </Row>
                    </div>
                    <div className="mb-2">
                      <Label>
                        Corenter Date of Birth
                      </Label>
                      <Input type="text" bsSize="sm" value={customerData.corenterDateOfBirth} onBlur={(e) => onBlur(e.target.value, 'corenterDateOfBirth')} onChange={(e) => onChange(e.target.value, 'corenterDateOfBirth')} />
                    </div>
                    <div className="mb-2">
                      <Label>Corenter Phone</Label>
                      <Input type="tel" bsSize="sm" maxLength="20" value={customerData.corenterPhone} onBlur={(e) => onBlur(e.target.value, 'corenterPhone')} onChange={(e) => onChange(e.target.value, 'corenterPhone')} />
                    </div>
                    <div className="mb-2">
                      <Label>Corenter Email</Label>
                      <Input type="email" bsSize="sm" maxLength="150" 
                        title={screenWarnings.corenterEmail ? screenWarnings.corenterEmail : null}
                        className={classnames({invalid: (screenWarnings.corenterEmail ? true : false)})}
                        value={customerData.corenterEmail} onChange={(e) => onChange(e.target.value, 'corenterEmail')} />
                    </div>
                   </Fragment>)
                : (<Button color="dark" size="sm" onClick={onAddCorenter}>
                    Add Corenter
                  </Button>)
              }
            </Col>
          </Row>
        </Col>
      </Row>
    </Form>
  );
};

export default CustomerInformation2;
