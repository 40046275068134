import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Badge, Button} from "reactstrap";

export default function CommentBell(props) {
    return (<>
    <button className={'btn-bell'} onClick={props.openCommentModal}>
        <FontAwesomeIcon icon={'bell'} className='bell' size={'2x'}/>
        {props.commentCount > 0
        ? (<sup><Badge color={'danger'} className={'notification-icon'}>
                <span className={'w-100'}>{props.commentCount}</span>
            </Badge></sup>)
        : null}
    </button>
    </>);
}