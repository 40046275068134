import React, {useState, useEffect, Fragment} from 'react';
import {FilterSearch, Loading, MfgAdmin, ResultHeader, SelectFilter} from "./";
import {
    Container, Row, Col, Modal, ModalHeader,
    ModalBody, ModalFooter, Button, Alert,
    Input, Card, CardHeader, CardBody, Table, FormGroup, Label, ButtonGroup
} from "reactstrap";
import Select from 'react-select';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {api, constants} from '../utils';
import {Link, useParams} from "react-router-dom";
import _ from "lodash";
import {filter_helpers, ui_helpers} from "../helpers";
import CurrencyInput from "react-currency-input-field";

// const AddOnCard = ({addOn}) => {
//     const applyAddOnToBundle = () => {
//     };
//     return (
//         <Card sm={2} body className={`m-2 addOn-card`} onClick={() => applyAddOnToBundle()}>
//             <Row>
//                 <Col sm={1}><Input type='checkbox' onChange={(value) => applyAddOnToBundle()}/></Col>
//                 <Col className='d-flex justify-content-start'><strong>{ui_helpers.replaceTextIfTooLong(12, addOn.addOnName)}</strong></Col>
//             </Row>
//         </Card>
//     );
// };

const BundleRow = ({b, editCallback, subProducts, toggleBundleActive}) => {
  const [open, setOpen] = useState(false);
  const [addOnOptions, setAddOnOptions] = useState([]);
  const [assocAddOns, setAssocAddons] = useState([]);

  const getProductNames = (productId) => {
    if(!subProducts) return;
    let foundElement = _.find(subProducts, s =>  {return s.id === productId});
    if(!foundElement) return '';
    return foundElement.name;
  };

  // const getAssociatedAddOns = () => {
  //   const newAssoc = _.filter(addOns, a => a.subProductId === b.manufacturerSubProductId);
  //   setAssocAddons(newAssoc);
  // };
  // useEffect(getAssociatedAddOns, [addOns])

    // async function handleChange(a) {
    //     const filtered = _.filter(addOnOptions, (x) => x.addOnId !== a.addOnId);
    //     a.isLoading = true;
    //     setAddOnOptions(_.sortBy([...filtered, a], ["addOnName"]));
    //     if (a.manufacturerSubProductAddOnId !== null) {
    //         const res = await api.post(
    //             `Manufacturer/ToggleUnitOptionActive/${a.manufacturerSubProductAddOnId}`
    //         );
    //     } else {
    //         let payload = {
    //             addOnId: a.addOnId,
    //             manufacturerSubProductId: subProduct.id,
    //         };
    //         const res = await api.post("Manufacturer/SaveSubProductOption", payload);
    //     }
    //     getMfgSubProductAddOnOptions();
    // }

    return (<tr key={`a-${b.id}`}>
      <td>{b.name}</td>
      <td>{getProductNames(b.manufacturerSubProductId)}</td>
      <td>
          <ButtonGroup className='float-end'>
              <Button size={'sm'} className='bg-secondary' onClick={() => editCallback(b)}>
                <FontAwesomeIcon icon={'edit'}/>
              </Button>
              {b.deactivatedAt === null
                ? <Button size={'sm'} className='btn-delete' onClick={() => toggleBundleActive(b.id)}>
                    <FontAwesomeIcon icon={'trash-alt'} />
                  </Button>
                : <Button size={'sm'} className='bg-success border-success' onClick={() => toggleBundleActive(b.id)}>
                    <FontAwesomeIcon icon={'heartbeat'} />
                  </Button>}
          </ButtonGroup>
      </td>
    </tr>);
    /*open
        ? <tr key={`ba-${b.id}`}>
            <td colSpan={5} className='p-0 m-0'>
                <div className='addOn-container'>
                    {assocAddOns && assocAddOns.length > 0  ? _.map(assocAddOns, a => {
                       return (<AddOnCard addOn={a}/>);
                    })
                        : <div className={'w-100 m-2 text-center'}>No add-ons configured for this product.</div>}
                </div>
            </td>
        </tr>
        : null
    */
};

const defaultBundle = {
    id: 0,
    name: '',
    price: 0,
    manufacturerSubProductList: [],
    selectedProducts: [],
};

export default function AddOnBundleManagement(props) {
  const params = useParams();
  const mfgId = params.mfgId
    ? params.mfgId
    : props.mfgId;
  const [bundles, setBundles] = useState([]);
  const [bundle, setBundle] = useState(defaultBundle);
  const [subProducts, setSubProducts] = useState([]);
  const [message, setMessage] = useState(constants.ALERT_OBJECT);
  const [filters, setFilters] = useState([]);
  const [clear, setClear] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [canClear, setCanClear] = useState(false);
  const [bundleAddOnOpen, setBundleAddOnOpen] = useState(false);
  const [subAddOns, setSubAddOns] = useState([]);
  const [manufacturerName, setManufacturerName] = useState('');
  const [loading, setLoading] = useState(false);

    const getSubProducts = () => {
      const payload = {
        manufacturerId: mfgId,
        activeOnly: true
      };
      api.post('manufacturer/ListOfSubProducts', payload).then(r => {
        if (r.data.success) {
          setSubProducts(
            ui_helpers.idNameToValueLabel(r.data.message.subProductList));
          setManufacturerName(r.data.message.manufacturerName);
        } else {
          console.error(r.data.message);
        }
      }).catch(err => console.error(err));
    };

    const saveBundle = () => {
        const payload = {
          ...bundle,
          manufacturerId: mfgId,
          manufacturerSubProductList: _.map(bundle.selectedProducts, s => s.id),
        };
        api.post('Manufacturer/SaveMfgBundle', payload).then(r => {
          if(!r.data.success) {
            Alert.error(r.data.message);
            return;
          }
          refreshData();
          closeModal();
        }).catch(api.catchHandler);
    };

    const updateBundle = () => {
      const payload = {
        ...bundle,
        manufacturerId: mfgId
      };
      api.post('Manufacturer/SaveMfgBundle', payload).then(r => {
        if (!r.data.success) {
          Alert.error(r.data.message);
          return;
        }
        refreshData();
        closeModal();
      }).catch(api.catchHandler);
    };

    const refreshData = () => {
      setLoading(true);
      let payload = {
        manufacturerId: mfgId,
        activeOnly: true,
        name: ''
      };
      _.each(filters, filter => payload[filter.filterName] = filter.value);
      api.post(`manufacturer/ListBundles`, payload).then(r => {
        if (r.data && r.data.success) {
          setBundles(r.data.message.list);
        } else {
          console.error(r.data.message);
          setMessage({
            message: 'There was an error retrieving the Bundles List. Please Try again.', 
            flavor: constants.flavor.error
          });
        }
      }).finally(() => setLoading(false));
    };

    useEffect(getSubProducts, []);
    useEffect(refreshData, [filters])

    function toggleBundleActive(bundleId) {
      api.post(`manufacturer/ToggleBundleActive/${bundleId}`, {}).then(r => {
        if (r.data && r.data.success) {
          refreshData();
        } else {
          console.error(r.data.message);
          setMessage({
            message: 'There was an error updating the bundle. Please Try again.', 
            flavor: constants.flavor.error
          });
        }
      });
    }

    function onFilterChange(changedFilter) {
      const filterChanges = filter_helpers.getFilters(filters, changedFilter);
      if(filterChanges.length === 0)
          setCanClear(false);
      else if(filterChanges.length > 0)
          setCanClear(true);

      setFilters(filterChanges);
    }

    const onBundleChange = (fieldName, fieldValue) => {
      let newBundle = Object.assign({}, bundle);
      newBundle[fieldName] = fieldValue;
      setBundle(newBundle);
    };

    const closeModal = () => {
      setBundleAddOnOpen(false);
      setIsEditing(false);
      setBundle(defaultBundle);
    };

    const editBundle = (item) => {
      setIsEditing(true);
      setBundle(item);
      setBundleAddOnOpen(true);
    };

    const handleMultiSelect = (selections) => {
      const newBundle = {
        ...bundle,
        selectedProducts: [...selections],
      };
      setBundle(newBundle);
    };

    const getSelectedValue = () => {
      if (bundle.manufacturerSubProductList && bundle.manufacturerSubProductList.length)
        return _.filter(subProducts, x => _.find(bundle.manufacturerSubProductList,m => m === x.value))
      return null;
    };

    return (<>
      <Modal isOpen={bundleAddOnOpen} centered>
        <ModalHeader>
            {isEditing ? ` Edit ${bundle.name}` : 'Add New Bundle'}
        </ModalHeader>
        <ModalBody>
          <FormGroup>
              <Label>Name</Label>
              <Input type='text' value={bundle.name} maxLength={100} name='name' onChange={(e) => onBundleChange(e.target.name, e.target.value)} />
              {bundle.id
                ? null
                : (<Fragment>
                    <Label>Associated Product</Label>
                    <Select
                      options={subProducts}
                      onChange={handleMultiSelect}
                      value={bundle.selectedProducts}
                      isMulti
                    />
                  </Fragment>)
              }
          </FormGroup>
        </ModalBody>
        <ModalFooter>
            <Row>
                <Col className='d-flex flex-row justify-content-end'>
                    <Col className='d-flex justify-content-end'>
                        <Button color='secondary' onClick={closeModal}>
                          Cancel
                        </Button>
                    </Col>
                    <Col className='ms-1 d-flex justify-content-end'>
                        <Button color='primary' className='float-end' onClick={() => bundle.id === 0 ? saveBundle() : updateBundle()}>
                          Save
                        </Button>
                    </Col>
                </Col>
            </Row>
        </ModalFooter>
      </Modal>
    <MfgAdmin mfgId={mfgId} tabName="BUNDLES">
      <Container fluid>
        <Row>
          <Col>
            <h2 className="py-2">
              <Link to={`/admin/manufacturer/${mfgId}`}>{manufacturerName}</Link> Bundles
            </h2>
          </Col>
          <Col>
              <FilterSearch>
                <Row>
                    <Col>
                        <SelectFilter
                          displayName='Products'
                          filterName='manufacturerSubProductId'
                          options={subProducts}
                          onChangeCallback={onFilterChange}
                          value={filter_helpers.get_value('productTypeId', filters)} 
                          isSingleSelect
                        />
                    </Col>
                </Row>
              </FilterSearch>
          </Col>
        </Row>
        <Card>
            <CardHeader>
                <Row className='d-flex align-items-center'>
                    <Col>
                        <ResultHeader heading='Bundles' totalResults={bundles.length}/>
                    </Col>
                    <Col>
                        <Button className='bg-success float-end' onClick={() => setBundleAddOnOpen(true)}>
                          <FontAwesomeIcon icon='plus' />
                        </Button>
                    </Col>
                </Row>
            </CardHeader>
            <Table bordered striped>
                <thead>
                <tr>
                    <th>Name</th>
                    <th>Product</th>
                    <th/>
                </tr>
                </thead>
                <tbody>
                {loading
                  ? (<tr><td colSpan={4}><Loading/></td></tr>)
                  : bundles && bundles.length && subProducts
                    ? _.map(bundles, b => 
                        (<BundleRow 
                          b={b}
                          key={b.id}
                          subProducts={subProducts}
                          editCallback={editBundle}
                          toggleBundleActive={toggleBundleActive}
                        />))
                    : (<tr><td colSpan={4} className={'text-center text-bold'}>
                        Click the green button on the top right to create a new Bundle.
                      </td></tr>)
                  }
                </tbody>
            </Table>
        </Card>
      </Container>
    </MfgAdmin>
  </>);
}