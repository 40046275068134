import React, { useState } from "react";
import {
  ConfirmModal,
  DocumentArea,
  PageNumberDisplay,
  PdfToolbarTop,
  SignatureModal,
} from ".";

export default function SignatureCapture({
  completeSignature,
  finalizeContract,
  handleSaveSignature,
  handleConfirm,
  signingContract,
}) {
  const [isVisible, setIsVisible] = useState(!signingContract);
  const toggleVisibility = () => {
    setIsVisible((prev) => !prev);
  }

  return (
    <div className="d-flex">
      <PdfToolbarTop isVisible={isVisible} />
      <DocumentArea handleToggle={toggleVisibility} />
      <PageNumberDisplay isVisible={isVisible} />
      <SignatureModal
        completeSignature={completeSignature}
        handleSaveSignature={handleSaveSignature}
      />
      <ConfirmModal
        finalizeContract={finalizeContract}
        handleConfirm={handleConfirm}
      />
    </div> 
  );
}
