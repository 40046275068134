import React, {useContext, useEffect, useLayoutEffect, useState} from "react";
import { Link } from "react-router-dom";
import {
    Alert,
    Button, Input, InputGroup,
    Navbar,
    NavbarBrand,
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Toolbar, MainNav } from "./";
import { confirmAlert } from "react-confirm-alert";
import { constants, UserContext, api } from "../utils";
import loading from "./Loading";
const PATHNAMES = constants.PATH_NAMES;

export default function Header({ toolbar, children, message, setMessage, inventory, invoiceNumber, agreementNumber, mfgName, loading, ...rest }) {
  const [comments, setComments] = useState([]);
  const [commentCount, setCommentCount] = useState(0);
  const [page, setPage] = useState(1);
  const [logo, setLogo] = useState(null);
  const [showComments, setShowComments] = useState(false);
  const { logIn, logOut, currentUser, impersonating, clearImpersonation } = useContext(UserContext);

  useEffect(() => {
    if(!currentUser) return;
    let isActive = true;
    refreshComments(isActive);
    return () => {
      isActive = false;
    };
  }, [page]);

  useEffect(() => {
    api.getCachedImage('LOGO').then(image => setLogo(image));
  }, []);

  const refreshComments = (isActive, newPage = null) => {
    let resolvedPage = page;
    if (newPage) {
      resolvedPage = newPage;
      setPage(newPage);
    }
    api.post('home/GetLatestUnseenComments', resolvedPage).then((r) => {
      if (!r.data || !r.data.commentList) return;
      if (isActive) {
        setComments(r.data.commentList);
        setCommentCount(r.data.totalCount);
        setPage(r.data.commentList.pageNumber);
      }
    });
  }

  function markCommentAsSeen(comment) {
    let payloadArray = [];
    payloadArray.push(comment);
    api.post('home/MarkCommentsSeen', payloadArray).then(() => refreshComments(true));
  }

  function markAllSeen(totalCount) {
    confirmAlert({
      title: "Mark All Read",
      message: `${totalCount} comments will be marked as read. Are you sure?`,
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            api.post('home/MarkAllCommentsSeen', {}).then(() => refreshComments(true, 1));            
          }
        },
        {
          label: "No"
        }
      ]
    });    
  }

  function undoMarkAsSeen() {
    api.fetch('home/UndoSeen').then(() => refreshComments(true));
  }

  return (
    <>
      <Navbar id="topmostnav">
        <NavbarBrand tag={Link} to={PATHNAMES.HOME}>
          <img src={logo} alt="RTO360 Logo" />
        </NavbarBrand>
        {currentUser ? (
          <div id="userMenu">
            <span className="d-none d-md-inline-block">
              Signed in as:
              <Link to={PATHNAMES.USER_PROFILE} className="navbar-link me-2">
                <FontAwesomeIcon icon="user" className="ms-2 me-1" />
                {currentUser.firstName} {currentUser.lastName}
              </Link>
            </span>
            <Button
              size="sm"
              color="light"
              id="signoutLink"
              className="navbar-link"
              onClick={() => logOut()}
            >
              <FontAwesomeIcon icon="sign-out-alt" className="me-1" />
              Logout
            </Button>
              {impersonating ?
              <Button size='sm' color='warning' className='navbar-link ms-1' onClick={clearImpersonation}>Stop Impersonating</Button>
              : null}
          </div>
        ) : null}
      </Navbar>
      <MainNav
        comments={comments} 
        markAsSeen={markCommentAsSeen}
        markAllSeen={markAllSeen}
        refresh={refreshComments} 
        undoMarkAsSeen={undoMarkAsSeen}
        onSetPageCallback={setPage}
        inventory={inventory}
        invoiceNumber={invoiceNumber}
        agreementNumber={agreementNumber} 
        mfgName={mfgName}
        loading={loading}
        storeName={rest.storeName}
        companyName={rest.companyName}
        mfgAdmin={rest.mfgAdmin}
        storeAdmin={rest.storeAdmin}
        companyAdmin={rest.companyAdmin}
      />
      {toolbar ? <Toolbar>{children}</Toolbar> : null}
    </>
  );
}
