import React, { useEffect, useState } from "react";
import { Alert, Button, Form, Input } from "reactstrap";
import { getSignatureLocations } from "../api";
import Logo from "../assets/logo.png";
import { TOP_LEVEL_VIEWS } from "../constants";
import { useAppStore } from "../hooks";
import { Loading } from ".";
import { API_URL } from "../constants";
import { isNil, sortByProperties, contractDataPromise } from "../utils";
import { useParams } from "react-router";

export default function Home() {
  const contractIdFromParams = useParams()?.contractId;

  const currentView = useAppStore((x) => x.currentView);
  const setCurrentView = useAppStore((x) => x.setCurrentView);
  const filePath = useAppStore((x) => x.filePath);
  const setFilePath = useAppStore((x) => x.setFilePath);
  const setContractId = useAppStore((x) => x.setContractId);
  const setContractPaymentData = useAppStore((x) => x.setContractPaymentData);
  const setCompanyName = useAppStore((x) => x.setCompanyName);
  const signatureLocations = useAppStore((x) => x.signatureLocations);
  const setSignatureLocations = useAppStore((x) => x.setSignatureLocations);
  const setPaperlessEmail = useAppStore((x) => x.setPaperlessEmail);
  const setIsContractCashSale = useAppStore((x) => x.setIsContractCashSale);

  const [userInput, setUserInput] = useState(contractIdFromParams || "");
  const [loading, setLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState(null);

  useEffect(() => {
    if (!isNil(filePath) && signatureLocations?.length > 0 && currentView !== TOP_LEVEL_VIEWS.HOME) {
      setCurrentView(TOP_LEVEL_VIEWS.WATCHING_VIDEO);
    }
  }, [filePath, signatureLocations]);

  function handleSubmit(e) {
    e.preventDefault();
    if (!userInput || userInput.length < 3) {
      setErrorMsg('Please provide a valid contract code.');
      return false;
    } else {
      setErrorMsg('');
    }
    setLoading(true);
    getSignatureLocations(userInput).then(r2 => {
      if (r2.success) {
        return {
          contractId: r2.data.data.item2,
          locationsResult: r2.data.data.item1
        }
      } 
      else {
        setErrorMsg(r2.msg)
        throw new Error(r2.msg);
      }
    })
    .then(contractDataPromise)
    .then(({contractId, locationsResult, companyName, defaultEmail, contractPaymentData, isCashSale}) => {
      isCashSale ? setCurrentView(TOP_LEVEL_VIEWS.SIGNING) : setCurrentView(TOP_LEVEL_VIEWS.WATCHING_VIDEO);
      setContractId(contractId);
      setFilePath(API_URL + "Signature/PreviewContract/" + contractId);
      setSignatureLocations(
        sortByProperties(locationsResult, ["pageIndex", "yPosition"]).map(
          (x, i) => ({ ...x, isIntersecting: true })));
      setCompanyName(companyName);
      setPaperlessEmail(defaultEmail);
      setContractPaymentData(contractPaymentData);
      setIsContractCashSale(isCashSale);
    })
    .catch(error => {
      setErrorMsg(error.message || 'Error getting contract data. Please try again.');
    })
    .finally(() => setLoading(false));
  }

  if (loading) return (<Loading />);

  return (
    <div className="vw-100 vh-100 position-fixed d-flex flex-column align-items-center justify-content-center text-center">
      <div className="d-flex flex-column align-items-center justify-content-center">
        <img src={Logo} className="mb-5" />
        {errorMsg && <Alert color="danger">{errorMsg}</Alert>}
        <Form className="d-flex flex-column align-items-center justify-content-center" onSubmit={handleSubmit}>
          <h3 className="mb-3">Enter Contract Code to get started:</h3>
          <Input
            bsSize="lg"
            className="mb-3"
            style={{ maxWidth: "12rem" }}
            value={userInput}
            onChange={(e) => setUserInput(e.target.value)}
            maxLength={4}
          />
          <Button color="primary" size="lg">
            Submit
          </Button>
        </Form>
      </div>
    </div>
  );
}
