import React, {useContext, useEffect, useState} from "react";
import { Link } from "react-router-dom";
import {
  UncontrolledDropdown,
  DropdownToggle,
  Button,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Row,
  Col,
  Container,
  CardHeader,
  Card,
  CardBody
} from 'reactstrap';
import { Header, Footer, CalculatorModal } from ".";
import AsyncSelect from 'react-select/async';
import Select from 'react-select'
import FloorImg from '../assets/img/Floorplan.jpg';
import _ from 'lodash';
import {api} from '../utils';

import {constants, UserContext} from "../utils";
const PATHNAMES = constants.PATH_NAMES;

export default function BuildCertify({builders, inventory, assignBuilderCallback, assignedBuilder}) {
  const [selectedBuilder, setSelectedBuilder] = useState({});
  const [builderList, setBuilderList] = useState(null);

  useEffect(() => {
    if (builders) {
      const builderList = _.map(builders, b => ({
        ...b,
        label: `${b.firstName} ${b.lastName}`,
        value: b.id
      }));
      setBuilderList(builderList);
      if (assignedBuilder) {
        setSelectedBuilder(
          _.find(builderList, b => assignedBuilder.completedBy === b.label));        
      }
    } else {
      setBuilderList(null);
    }
  }, [builders]);

  return (
    <Container className="mt-5">
      <Card>
        <CardHeader>
          <h3 className="mt-2">Assign Builder</h3>
        </CardHeader>
        <CardBody>
          <Row >
            <Col xs="8" className="pt-2">
              <Select
                options={builderList}
                onChange={(value) => setSelectedBuilder(value)}
                value={builderList ? selectedBuilder : ''}
                placeHolder={'Select Builder'}
              />
              <div>
                <p className="model-selected">Product: {inventory ? inventory.unitManufacturerSubProductText : ''}</p>
              </div>
            </Col>
            <Col xs="4">
              <Button className="my-2 bg-success border-success" onClick={() => assignBuilderCallback(selectedBuilder)}>
                Save
              </Button>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </Container>
  );
}


