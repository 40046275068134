import React, {useEffect, useState} from 'react';
import {Button, ButtonGroup, Pagination, PaginationItem, PaginationLink} from 'reactstrap';
import {ui_helpers} from "../helpers";


const Pager = (props) => {
    const [pageCount, setPageCount] = useState(0);

    if (!props.onSetPageCallback) {
        console.warn('Add a callBack function property on the pager that accepts a page param');
    }

    useEffect(() => {
        getTotalPageCount();
    }, [props.totalResults]);

    const getTotalPageCount = () => {
      let totalPages = ui_helpers.getPageCount(props.totalResults, props.pageSize);
      setPageCount(totalPages);
    };

    return (
        <div className='ps-2 my-2'>
            <small className="text-muted">
                Page {props.pageNumber} of {pageCount ? pageCount : 1}
            </small>
            {pageCount > 1
                ? (<ButtonGroup className={'ms-3'}>
                    <Button disabled={props.pageNumber === 1 || props.totalResults === 0} onClick={() => props.onSetPageCallback(1)} size={'sm'}>
                        First
                    </Button>
                    <Button disabled={props.pageNumber === 1 || props.totalResults === 0} onClick={() => props.onSetPageCallback(props.pageNumber - 1)} size={'sm'}>
                        Prev
                    </Button>
                    <Button disabled={props.pageNumber === pageCount || props.totalResults === 0} onClick={() => props.onSetPageCallback(props.pageNumber + 1)} size={'sm'}>
                        Next
                    </Button>
                    <Button  disabled={props.pageNumber === pageCount || props.totalResults === 0} onClick={() => props.onSetPageCallback(pageCount)} size={'sm'}>
                        Last
                    </Button>
                </ButtonGroup>)
                : null
            }
        </div>
    );
};

export default Pager;