export const editingContexts = {
  subProductTemplate: "subProductTemplate",
  skuTemplate: "skuTemplate",
  contract: "contract",
  inventory: "inventory",
};

export const porchTypes = {
  none: 1,
  end: 2,
  side: 3,
  fourWallDeluxe1: 4,
  fourWallDeluxe2: 5,
  twoWallCorner1: 6,
  insetSide: 7,
};

export const COLOR_TYPES = {
  BASE: 1,
  TRIM: 2,
  ROOF: 3,
  DOOR: 4,
  SHUTTERS: 5,
  FLOWER_BOX: 6,
  PORCH: 7
};

export const porchTypeOptions = [
  {
    value: porchTypes.none,
    label: "None",
  },
  {
    value: porchTypes.end,
    label: "End",
  },
  {
    value: porchTypes.side,
    label: "Side",
  },
  {
    value: porchTypes.fourWallDeluxe1,
    label: "Deluxe 1 (4-walls)",
  },
  {
    value: porchTypes.fourWallDeluxe2,
    label: "Deluxe 2 (4-walls)",
  },
  {
    value: porchTypes.twoWallCorner1,
    label: "Corner (2-walls)",
  },
  {
    value: porchTypes.insetSide,
    label: "Inset Side",
  },
];

export const addOnTypes = {
  textNote: -1,
  other: 0,
  singleDoor: 1,
  doubleDoor: 2,
  window: 3,
  singleWorkbench: 4,
  shelving: 5,
  outlet: 6,
  light: 7,
  breakerBox: 8,
  switch: 9,
  doubleWorkbench: 10,
  loft: 11,
  doubleOutlet: 12
};

export const addOnTypeOptions = [
  {
    value: addOnTypes.other,
    label: "Other",
  },
  {
    value: addOnTypes.singleDoor,
    label: "Single Door",
  },
  {
    value: addOnTypes.doubleDoor,
    label: "Double Door",
  },
  {
    value: addOnTypes.window,
    label: "Window",
  },
  {
    value: addOnTypes.singleWorkbench,
    label: "Single Workbench",
  },
  {
    value: addOnTypes.shelving,
    label: "Shelving",
  },
  {
    value: addOnTypes.outlet,
    label: "Electrical Outlet",
  },
  {
    value: addOnTypes.doubleOutlet,
    label: "Double Outlet",
  },
  {
    value: addOnTypes.light,
    label: "Light",
  },
  {
    value: addOnTypes.breakerBox,
    label: "Breaker Box",
  },
  {
    value: addOnTypes.switch,
    label: "Electrical Switch",
  },
  {
    value: addOnTypes.doubleWorkbench,
    label: "Double Workbench",
  },
  {
    value: addOnTypes.loft,
    label: "Loft",
  },
];

export const swingTypes = {
  none: 0,
  leftHand: 1,
  rightHand: 2,
  leftHandActive: 3,
  rightHandActive: 4,
  active: 5,
};

export const singleDoorSwingTypeOptions = [
  {
    value: swingTypes.none,
    label: "None",
  },
  {
    value: swingTypes.leftHand,
    label: "Left-Hand",
  },
  {
    value: swingTypes.rightHand,
    label: "Right-Hand",
  },
];

export const doubleDoorSwingTypeOptions = [
  {
    value: swingTypes.none,
    label: "None",
  },
  {
    value: swingTypes.active,
    label: "Active",
  },
  {
    value: swingTypes.leftHandActive,
    label: "Left-Hand Active",
  },
  {
    value: swingTypes.rightHandActive,
    label: "Right-Hand Active",
  },
];

export const unitsOfMeasure = {
  each: 1,
  linearFt: 2,
  sqFt: 3,
  percentOfBase: 4
};

export const windowAdditionalOptionTypes = {
  none: null,
  flowerbox: 1,
  shutters: 2,
};

export const windowAdditionalOptionTypeOptions = [
  {
    value: windowAdditionalOptionTypes.flowerbox,
    label: "Flowerbox",
  },
  {
    value: windowAdditionalOptionTypes.shutters,
    label: "Shutters",
  }
];

export const doorAdditionalOptionTypes = {
  none: null,
  customColor: 3
};

export const doorAdditionalOptionTypeOptions = [
  {
    value: doorAdditionalOptionTypes.customColor,
    label: "Custom Color"
  }
];

export const boundingBoxOffset = 3;
