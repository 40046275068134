import React, { useReducer } from "react";
import { AppStateContext } from "../contexts";
import { getInitialAppState } from "../init";
import { appStateReducer } from "../reducers";

function AppStateProvider({ optionColors, porchColorId, children }) {
  const [state, dispatch] = useReducer(appStateReducer, getInitialAppState(optionColors, porchColorId));

  return (
    <AppStateContext.Provider value={[state, dispatch]}>
      {children}
    </AppStateContext.Provider>
  );
}

export default AppStateProvider;
