import React from "react";
import { Spinner } from "reactstrap";

export default function Loading() {
  return (
    <div
      className="vw-100 vh-100 bg-body position-fixed d-flex flex-column align-items-center justify-content-center text-center"
      style={{ zIndex: 999 }}
    >
      <Spinner color="primary" />
      <p>Loading...</p>
    </div>
  );
}
