import React from "react";
import { Main } from "./components";
import { addOnTypes, editingContexts } from "./floorplanConstants";
import {
  AddOnsProvider,
  AppStateProvider,
  ControlTargetProvider,
  DisplaySettingsProvider,
  EditingContextProvider,
  ExteriorProvider,
  InfoProvider,
} from "./state/providers";
import { v4 as uuid } from "uuid";
import { Button } from "reactstrap";

function FloorplanTool({
  editingContext,
  exterior,
  addOnOpts,
  selectedAddOns,
  saveCallback,
  onExit,
  bundleOptions,
  rulerOffset,
  fontSize, 
  hasExistingFloorPlan,
  modelName,
  optionColors
}) {
  const errors = [];
  if (!editingContext) errors.push("No editing context specified.");
  if (!editingContexts[editingContext])
    errors.push("Editing context value not recognized.");
  if (!addOnOpts || addOnOpts.length < 1)
    errors.push("No Add-On options provided.");
  if (!exterior.width || exterior.width < 12)
    errors.push("Exterior dimensions must be at least 1ft.");
  if (!exterior.height || exterior.height < 12)
    errors.push("Exterior dimensions must be at least 1ft.");

  if (errors.length >= 1)
    return (
      <div className="text-center">
        <h1 className="text-danger">ERROR</h1>
        <h3>
          The floorplan tool encountered one or more errors during
          initialization.
        </h3>
        <ul>
          {errors.map((e) => (
            <li>{e}</li>
          ))}
        </ul>
        <Button size="lg" onClick={onExit}>
          Exit
        </Button>
      </div>
    );
  const selectedAddOnsWithIds = selectedAddOns.map((x) => ({
    ...x,
    domId: x.uniqueAddOnId || uuid()
  }));

  return (
    <div id="floorplanTool">
      <EditingContextProvider editingContext={editingContext}>
        <InfoProvider>
          <AppStateProvider optionColors={optionColors || []} porchColorId={exterior.porchColorId}>
            <DisplaySettingsProvider rulerOffset={rulerOffset} fontSize={fontSize}>
              <ExteriorProvider exterior={exterior}>
                <ControlTargetProvider>
                  <AddOnsProvider
                    addOnOpts={addOnOpts}
                    selectedAddOns={selectedAddOnsWithIds}
                    bundleOpts={bundleOptions}
                  >
                    <Main saveCallback={saveCallback} onExit={onExit} hasExistingFloorPlan={hasExistingFloorPlan} modelName={modelName} />
                  </AddOnsProvider>
                </ControlTargetProvider>
              </ExteriorProvider>
            </DisplaySettingsProvider>
          </AppStateProvider>
        </InfoProvider>
      </EditingContextProvider>
    </div>
  );
}

export default FloorplanTool;
