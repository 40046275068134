import { useWindowSize } from "@react-hook/window-size";
import React, { useEffect, useRef, useState } from "react";
import { ReactSVGPanZoom } from "react-svg-pan-zoom";
import styles from "../floorplanTool.module.css";
import {
  useActions,
  useAddOns,
  useAppState,
  useControlTarget,
  useDisplaySettings,
  useExterior,
  useMousePosition,
  usePointerCoordinates,
} from "../hooks";
import { AddOn, Exterior, Grid, Rulers } from "./";

function DrawingCanvas() {
  const Viewer = useRef(null);
  const [tool, onChangeTool] = useState("none");
  const [value, onChangeValue] = useState({});
  const [vWidth, vHeight] = useWindowSize();
  const { x, y } = useMousePosition();
  const [, setCoordinates] = usePointerCoordinates();
  const [displaySettings] = useDisplaySettings();
  const [exterior, exteriorDispatch] = useExterior();
  const [, appStateDispatch] = useAppState();
  const [{ selectedAddOns }] = useAddOns();
  const actions = useActions({ exteriorDispatch, appStateDispatch });

  const [, setControlTarget] = useControlTarget();

  useEffect(() => {
    Viewer.current.setPointOnViewerCenter(
      exterior.width / 2,
      exterior.height / 2,
      2.5
    );
  }, [Viewer]);

  useEffect(() => {
    setCoordinates({
      x: (x - displaySettings.sidebarWidth - value.e) / value.a,
      y: (y - displaySettings.headerHeight - value.f) / value.d,
    });
  }, [x, y, value]);

  function handleMouseDown(e) {
    setControlTarget(e.originalEvent.target.id);
  }

  function handleMouseUp(e) {
    actions.setIsMovingAddOn(false);
    actions.setIsRotatingAddOn(false);
    actions.setIsResizingAddOn(false);
    actions.setIsDraggingPorchHandle(false);
  }

  return (
    <div
      style={{
        marginLeft: displaySettings.sidebarWidth,
        marginTop: displaySettings.headerHeight,
      }}
    >
      <ReactSVGPanZoom
        width={
          vWidth -
          displaySettings.sidebarWidth -
          displaySettings.secondarySidebarWidth
        }
        height={vHeight - displaySettings.headerHeight}
        ref={Viewer}
        value={value}
        onChangeValue={onChangeValue}
        tool={tool}
        onChangeTool={onChangeTool}
        detectAutoPan={false}
        background="#fff"
        miniatureProps={{ background: "#fff" }}
        toolbarProps={{ SVGAlignX: "center", SVGAlignY: "center" }}
        className="mainSVG"
        onClick={() => actions.setIsMovingAddOn(false)}
        onMouseDown={handleMouseDown}
        onTouchStart={handleMouseDown}
        onMouseUp={handleMouseUp}
        onTouchEnd={handleMouseUp}
        scaleFactorMax={8}
        scaleFactorMin={1}
      >
        <svg width={exterior.width} height={exterior.height}>
          {displaySettings.showGrid ? <Grid /> : null}
          {displaySettings.showRulers ? (
            <Rulers displaySettings={displaySettings} exterior={exterior} />
          ) : null}
          <text
            textAnchor="middle"
            className={styles.disclaimerTxt}
            fontSize={displaySettings.fontSize}
            x={exterior.width / 2}
            y={0 - displaySettings.rulerOffset - displaySettings.fontSize * 2}
          >
            This drawing may not be exact, it is designed to show a general
            layout and placement of options.
          </text>
          <Exterior />
          {selectedAddOns.map((a) =>
            a.hide ? null : <AddOn key={a.domId} {...a} />
          )}
        </svg>
      </ReactSVGPanZoom>
    </div>
  );
}

export default DrawingCanvas;
