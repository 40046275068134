import api from "../utils/api.js";
import _ from "lodash";

const findFilter = (filterName, filters) => {
  return _.find(filters, x => {
    return x ? x.filterName === filterName : false;
  });
};

export default {
  //gets a new filters array based on the new filter being sent back to the page from the filter component
  getFilters(state_filters, new_filter) {
    if (!new_filter || !new_filter.filterName) return state_filters;
    const filterIndex = _.findIndex(state_filters, x => x.filterName === new_filter.filterName);
    let filters = [];
    if (filterIndex === -1) {
      filters = new_filter.value === null
        ? state_filters.slice()
        : [...state_filters, new_filter];
    } else {
      filters = Object.assign([], state_filters, { [filterIndex]: new_filter });
    }
    return _.filter(filters, x => 
      x.value === 0
      || x.value
      || (x.values && x.values.length && x.values.length && x.values[0])
      || x.startDate
      || x.endDate);
  },

  filtersToObject(filters) {
    let o = {};
    _.each(filters, f => {
      o[f.filterName] = f.value;
    });
    return o;
  },

  removeFilter(filters, removeFilterName) {
    let newFilters = filters.slice();
    const filterIndex = _.findIndex(newFilters, x => x.filterName === removeFilterName);
    newFilters.splice(filterIndex, 1);
    return newFilters;
  },

  getSelectValue(filterName, filters) {
    const filter = findFilter(filterName, filters);
    return filter ? { label: filter.value, value: filter.value } : null;
  },

  getFilter(filterName, filters) {
    const filterItem = _.find(filters, x => {
      return x ? x.filterName === filterName : false;
    });
    return filterItem; 
  },

  getFilterValue(filters, filterName, defaultValue = null) {
    const filter = _.find(filters, x => x.filterName === filterName);
    return filter === undefined
      ? defaultValue
      : filter.value;
  },

  get_value(name, filters) {
    const filterElement = _.find(filters, (f) => f.filterName === name);
     //console.log(filterElement, "filterElements", name, filters)
    if (filterElement) {
      if (filterElement.value) {
        return filterElement.value;
      }
      return filterElement.values;
    }
    return "";
  },

  getFilterMultiValues(filters, filterName) {
    const filter = findFilter(filterName, filters);
    return filter ? filter.values : [];
  },

  removeHTMLTags(text) {
    return text
      ? String(text)
          .replace(/<[^>]+>/gm, "")
          .replace(/&nbsp;/g, " ")
          .replace(/&lsquo;/g, "'")
          .replace(/&rsquo;/g, "'")
          .replace(/&#39;/g, "'")
          .replace(/&#34;/g, '"')
          .replace(/&amp;/g, "&")
          .replace(/&quot;/g, '"')
      : "";
  },

  transform_filters(filters = []) {
    return filters.reduce((acc, item, i) => {
      acc[item.filterName] = item.value || item.values;
      return acc;
    }, {});
  },

  saveSearch(area, searchFilters, callback = null) {
    const payload = {
      serializedSearch: searchFilters,
      area: area
    };
    api
      .post("Reference/SaveSearch", payload)
      .then(() => {
        if(callback && typeof callback === "function"){
          callback();
        }
      })
      .catch(error => error);
  },
  getParsedSavedSearchJSON(savedSearchJson){
    return JSON.parse(savedSearchJson)
  },
  getResortSortFromSavedSearchJSON(savedSearchJson){
    const savedSearch = JSON.parse(savedSearchJson)
    return savedSearch.resolvedSort;
  },
  getFiltersFromSavedSearchJSON(savedSearchJson){
    const savedSearch = JSON.parse(savedSearchJson)
    return savedSearch.filters;
  },
  storeSaveSearch(filters, sort, pageNumber, quickFilterKey = null) {
    const searchCriteria = {
      resolvedSort: {
        ...sort,
        page: pageNumber
      },
      filters: filters,
      quickFilterKey: quickFilterKey
    };
    return JSON.stringify(searchCriteria);
  }
};

