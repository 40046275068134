import { useState, useEffect } from "react";
import * as signalR from "@microsoft/signalr";

const useSignalR = (hubUrl) => {
  const [connection, setConnection] = useState(null);
  const [connectionError, setConnectionError] = useState(null);
  const [isConnected, setIsConnected] = useState(false);

  useEffect(() => {
    const newConnection = new signalR.HubConnectionBuilder()
      .withUrl(hubUrl)
      .build();

    setConnection(newConnection);

    const startConnection = async () => {
      try {
        await newConnection.start();
        setIsConnected(true);
      } catch (error) {
        setConnectionError(error);
      }
    };

    startConnection();

    return () => {
      if (newConnection) {
        newConnection.stop();
      }
    };
  }, [hubUrl]);

  const useHubMethod = (methodName, callback) => {
    useEffect(() => {
      const handleCallback = (args) => {
        callback(args);
      };

      if (connection && isConnected) {
        connection.on(methodName, handleCallback);
      }

      return () => {
        if (connection && isConnected) {
          connection.off(methodName, handleCallback);
        }
      };
    }, [connection, isConnected, methodName, callback]);
  };

  return { connection, connectionError, isConnected, useHubMethod };
};

export default useSignalR;
