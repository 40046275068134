const initialDisplaySettings = {
  headerHeight: 48,
  sidebarWidth: 360,
  secondarySidebarWidth: 280,
  alertHeight: 270,
  showGrid: true,
  showRulers: true,
  rulerOffset: 3 * 12,
  fontSize: 4,
  scale: 32,
};

export default initialDisplaySettings;
