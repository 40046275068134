import React, {useState, useEffect} from 'react';
import DatePicker from 'react-datepicker';
import {DebounceInput} from "react-debounce-input";
import moment from "moment";
import {date_helpers} from "../helpers";

export default function DateFilter(props){
  const [value, setValue] = useState(props.value);

  useEffect(() => {
    setValue('');
  }, [props.clear]);

  useEffect(() => {
    if (props.value) {
      setValue(moment(props.value));
    } else {
      setValue('');
    }
  }, [props.value]);

  function handleChange(date) {
    if (date) {
      const newValue = moment(date);
      const shortDate = date_helpers.formatDateToShortDate(newValue);
      const filteredDisplay = newValue
          ? `${props.displayName} ${shortDate}`
          : '';
      props.onChangeCallback({
        filterName: props.filterName,
        value: shortDate,
        filteredDisplay
      });
      setValue(newValue);
    } else {
      setValue(date);
      props.onChangeCallback({
        filterName: props.filterName,
        value: '',
        filteredDisplay: ''
      });
    }
  }

let dateValue = value;
if (dateValue && !moment.isMoment(dateValue)) {
  dateValue = moment(dateValue).toDate();
} else if (dateValue) {
  dateValue = dateValue.toDate();
}

return (<span className='d-flex flex-column'>
        <span className='filter-caption'>
          {props.displayName}
        </span>
      <div className="datePickerDiv">
        <DatePicker
          className='form-control'
          selected={dateValue}
          onChange={handleChange}
          showMonthDropdown
          showYearDropdown
          isClearable
          withPortal
          dropdownMode="select"
        />
      </div>
    </span>);
}