import React from "react";
import { boundingBoxOffset } from "../floorplanConstants";
import styles from "../floorplanTool.module.css";
import { useControlTarget, useMovability } from "../hooks";

function BreakerBox(props) {
  const [transform, handleMove] = useMovability(props);
  const [controlTarget] = useControlTarget();

  const active = controlTarget.includes(props.domId);

  return (
    <g transform={transform}>
      <text
        x={0}
        y={3}
        fontSize={8}
        fontWeight="bold"
        fontFamily="sans-serif"
        textAnchor="middle"
        className={styles.unSelectable}
      >
        B
      </text>
      <rect
        x={-6}
        y={-6}
        width={12}
        height={12}
        stroke="black"
        strokeWidth={0.5}
        fill="none"
      />
      <rect
        id={props.domId}
        style={{ cursor: active ? "move" : "pointer" }}
        x={-6 - boundingBoxOffset}
        y={-6 - boundingBoxOffset}
        width={12 + boundingBoxOffset * 2}
        height={12 + boundingBoxOffset * 2}
        fill={active ? "rgba(0,0,255,0.05)" : "rgba(0,0,0,0)"}
        stroke={active ? "#0d6efd" : "none"}
        strokeDasharray={2}
        strokeWidth={0.5}
        onMouseDown={handleMove}
        onTouchStart={handleMove}
      />
    </g>
  );
}

export default BreakerBox;
