import React, { useState } from 'react';
import { Redirect } from "react-router-dom";
import { Row, Col, Container, Card, CardHeader, CardBody, Input, Label, ButtonGroup, Button } from "reactstrap";
import { api, ui_helpers, constants } from "../helpers";
import Cookies from 'js-cookie';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Alert from "react-s-alert-v3";

export default function CompanyAddEdit(props){
    const [company, setCompany] = useState(constants.EMPTY_COMPANY);
    const [addEditCompany, setAddEditCompany] = useState(true);
    // const [onSaveCallback, setOnSaveCallback] = useState(props.onSaveCallback);

    const updateCompanyState = (fieldName, value) => {
        let newCompany = Object.assign({}, company);
        newCompany[fieldName] = value;
        setCompany(newCompany);
    }

    // const onBlur = (value, fieldName) => {
    //     let newCompany = Object.assign({}, company);
    //     newCompany[fieldName] = ui_helpers.capitalizeField(value);
    //     setCompany(newCompany);
    // }

    const saveCompanyDetails = () => {
      if (!company.name) {
        Alert.warning('Provide at least a name to store a company');
        return;
      }
      let payload = {
        Name: company.name,
        Alias: company.name.toLowerCase(), //change
        RemitTo1: company.remitTo1,
        RemitTo2: company.remitTo2,
        RemitTo3: company.remitTo3,
        RemitTo4: company.remitTo4
      };
      if (company.Id === null || company.Id === undefined) {
        payload.Id = 0;
      } else {
        payload.Id = company.id;
      }
      api.post("company/SaveCompanyDetails", payload).then(response => {
        if (response.data.success) {
            setAddEditCompany(false);
            props.onSaveCallback(false);
        }
        else 
        {
          Alert.error("There was an error updating the company details");
        }
      })
      .catch(error =>
        Alert.error("There was an error updating the company details")
      );
    }

    const cancelEditCompany = () => {
        props.onSaveCallback(false);
    }
        const displayCompany = company;
    return (
        <Container fluid>
            <Card>
                <CardHeader>
                    <Row>
                        <Col>
                            <h3>{company ? company.name : "New Company"} Details</h3>
                        </Col>
                        <Col>
                            <ButtonGroup className="float-end">
                                <Button
                                    size="sm"
                                    className="btn-success cf-primary"
                                    style={{ color: "white" }}
                                    onClick={() => saveCompanyDetails()}
                                >
                                    <FontAwesomeIcon icon="save" />{" "}
                                    Save
                                </Button>
                                <Button
                                    size="sm"
                                    className="btn-secondary"
                                    onClick={() => cancelEditCompany()}
                                >
                                    <FontAwesomeIcon icon="times-circle" />{" "}
                                    Close
                                </Button>
                            </ButtonGroup>
                        </Col>
                    </Row>
                </CardHeader>
                <CardBody>
                            <Row>
                                <Col>
                                    <Label>Company Name</Label>
                                    <Input
                                        type="text"
                                        name="name"
                                        value={company.name}
                                        maxLength="100"
                                        // onBlur={(e) => onBlur(e.target.value, 'name')}
                                        onChange={(event) =>
                                            updateCompanyState(event.target.name, event.target.value)
                                        }
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Label>Address 1</Label>
                                    <Input
                                        type="text"
                                        name="remitTo1"
                                        maxLength="100"
                                        value={company.remitTo1}
                                        onChange={(event) =>
                                            updateCompanyState(event.target.name, event.target.value)
                                        }
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Label>Address 2</Label>
                                    <Input
                                        type="text"
                                        name="remitTo2"
                                        maxLength="100"
                                        value={company.remitTo2}
                                        onChange={(event) =>
                                            updateCompanyState(event.target.name, event.target.value)
                                        }
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Label>City State, Zip</Label>
                                    <Input
                                        type="text"
                                        name="remitTo3"
                                        maxLength="100"
                                        value={company.remitTo3}
                                        onChange={(event) =>
                                            updateCompanyState(event.target.name, event.target.value)
                                        }
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Label>Phone Number, Fax Number, Email</Label>
                                    <Input
                                        type="text"
                                        name="remitTo4"
                                        maxLength="100"
                                        value={company.remitTo4}
                                        onChange={(event) =>
                                            updateCompanyState(event.target.name, event.target.value)
                                        }
                                    />
                                </Col>
                            </Row>
                </CardBody>
            </Card>
        </Container>
    );
}