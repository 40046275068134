import { addOnTypes } from "../floorplanConstants";
import {
  BreakerBox,
  DoubleDoor,
  Light,
  Loft,
  OtherAddOn,
  Outlet,
  DoubleOutlet,
  Shelving,
  SingleDoor,
  Switch,
  TextNote,
  Window,
  Workbench,
} from "./";
import React from "react";

function AddOn(props) {
  if (props.type === addOnTypes.singleDoor) return <SingleDoor {...props} />;
  if (props.type === addOnTypes.doubleDoor) return <DoubleDoor {...props} />;
  if (props.type === addOnTypes.window) return <Window {...props} />;
  if (props.type === addOnTypes.light) return <Light {...props} />;
  if (props.type === addOnTypes.switch) return <Switch {...props} />;
  if (props.type === addOnTypes.outlet) return <Outlet {...props} />;
  if (props.type === addOnTypes.doubleOutlet) return <DoubleOutlet {...props} />;
  if (props.type === addOnTypes.breakerBox) return <BreakerBox {...props} />;
  if (props.type === addOnTypes.singleWorkbench)
    return <Workbench {...props} />;
  if (props.type === addOnTypes.doubleWorkbench)
    return <Workbench double {...props} />;
  if (props.type === addOnTypes.loft) return <Loft {...props} />;
  if (props.type === addOnTypes.textNote) return <TextNote {...props} />;
  if (props.type === addOnTypes.shelving) return <Shelving {...props} />;
  return <OtherAddOn {...props} />;
}

export default AddOn;
