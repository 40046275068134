import React, { useEffect } from "react";
import { boundingBoxOffset } from "../floorplanConstants";
import styles from "../floorplanTool.module.css";
import {
  useActions,
  useAddOns,
  useAppState,
  useControlTarget,
  useDisplaySettings,
  useExterior,
  usePointerCoordinates,
} from "../hooks";
import useMovability from "../hooks/useMovability";
import { snap, toFeetInchesString } from "../utils";
import ControlPoint from "./ControlPoint";

let startCoords = { x: 0, y: 0 };
let startProps = { width: 0, height: 0 };

function Loft(props) {
  const [displaySettings] = useDisplaySettings();
  const [controlTarget, setControlTarget] = useControlTarget();
  const [transform, handleMove, handleRotate] = useMovability(props);
  const [appState, appStateDispatch] = useAppState();
  const [, addOnsDispatch] = useAddOns();
  const [coordinates] = usePointerCoordinates();
  const [exterior] = useExterior();
  const actions = useActions({ appStateDispatch, addOnsDispatch });

  useEffect(() => {
    if (
      appState.isResizingAddOn &&
      controlTarget === "resize1-" + props.domId
    ) {
      const newWidth = snap(
        startProps.width + (startCoords.x - coordinates.x),
        12
      );
      actions.updateAddOn({
        ...props,
        width: newWidth,
      });
    } else if (
      appState.isResizingAddOn &&
      controlTarget === "resize2-" + props.domId
    ) {
      const newWidth = snap(
        startProps.width + (coordinates.x - startCoords.x),
        12
      );
      actions.updateAddOn({
        ...props,
        x: props.x + (newWidth - props.width),
        width: newWidth,
      });
    }
  }, [coordinates, controlTarget, appState.isResizingAddOn]);

  const active =
    controlTarget === props.domId ||
    controlTarget === "resize1-" + props.domId ||
    controlTarget === "resize2-" + props.domId;

  function handleResizeMousedown() {
    actions.setIsResizingAddOn(true);
    startCoords = coordinates;
    startProps = props;
  }

  return (
    <g transform={transform}>
      <rect
        x={exterior.width - props.width}
        y={0}
        width={props.width}
        height={exterior.height}
        stroke="black"
        strokeWidth={1}
        strokeDasharray={[1, 2]}
        fill="none"
      />
      <rect
        id={props.domId}
        onMouseDown={handleMove}
        onTouchStart={handleMove}
        style={{ cursor: active ? "move" : "pointer" }}
        x={exterior.width - props.width - boundingBoxOffset}
        y={-boundingBoxOffset}
        width={props.width + boundingBoxOffset * 2}
        height={exterior.height + boundingBoxOffset * 2}
        fill={active ? "rgba(0,0,255,0.05)" : "rgba(0,0,0,0)"}
        stroke={active ? "#0d6efd" : "none"}
        strokeDasharray={2}
        strokeWidth={0.5}
      />
      <text
        className={styles.svgTxt}
        x={exterior.width - props.width + displaySettings.fontSize}
        y={exterior.height - (exterior.height/4) - displaySettings.fontSize}
        fontSize={displaySettings.fontSize}
        textAnchor="start"
      >
        Loft : {toFeetInchesString(props.width)}
      </text>
      {active ? (
        <>
          <ControlPoint
            id={"resize1-" + props.domId}
            cx={exterior.width - props.width - boundingBoxOffset}
            cy={exterior.height / 2}
            onMouseDown={handleResizeMousedown}
            onTouchStart={handleResizeMousedown}
          />
          <ControlPoint
            id={"resize2-" + props.domId}
            cx={exterior.width + boundingBoxOffset}
            cy={exterior.height / 2}
            onMouseDown={handleResizeMousedown}
            onTouchStart={handleResizeMousedown}
          />
        </>
      ) : null}
    </g>
  );
}

export default Loft;
