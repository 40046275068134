import React from "react";
import styles from "../floorplanTool.module.css";
import { useControlTarget } from "../hooks";

function ControlPoint({
  id,
  cx,
  cy,
  r = 3,
  transform = "",
  onClick,
  onMouseDown,
}) {
  const [controlTarget] = useControlTarget();

  return (
    <circle
      id={id}
      className={`${styles.controlPoint} ${
        controlTarget === id ? styles.controlPointActive : ""
      }`}
      cx={cx}
      cy={cy}
      r={r}
      onMouseDown={onMouseDown}
      onTouchStart={onMouseDown}
      onClick={onClick}
      transform={transform}
    />
  );
}

export default ControlPoint;
