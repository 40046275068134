import _ from "lodash";

export default function CCSwipeParser(s) {
  try {
    var arr = s.split('^');
    this.IsCreditOrDebit = arr[0].substring(1, 2).toUpperCase() === "B";
    var firstDelimiterPos = s.indexOf('^');
    this.CCNumber = _.trim(arr[0].substring(2, firstDelimiterPos));
    this.ExpiryMonth = arr[2].substring(2, 4);
    this.ExpiryYear = arr[2].substring(0, 2);
    this.ServiceCode = arr[2].substring(4, 7);
    var nameArr = arr[1].split('/');
    this.FirstName = _.trim(nameArr[1]);
    this.LastName = _.trim(nameArr[0]);
  } catch (err) {
    this.ErrorMessage = 'There was an error reading the card. Please try again.';
  }
}
