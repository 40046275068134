import React, { Component, Fragment } from 'react';
import { Row, Col, Container, Label, Input, Card, CardHeader, CardBody, ButtonGroup, Button } from "reactstrap";
import { api, ui_helpers, constants } from "../helpers";
import ToggleButton from "react-toggle-button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { UserAddEdit, DropZone, EditStore } from "../components";
import Alert from "react-s-alert-v3";

const getRegions = () => {
  return api.fetch('reference/RegionList')
    .then(r => ({ states: ui_helpers.idNameToValueLabel(r.data.data) }))
    .catch(error => Alert.error("There was an error loading the state list"));
};

export default class StoreAddEdit extends Component {
    constructor(props) {
      super(props);
      this.state = {
        store: constants.EMPTY_STORE,
        companies: [],
        states: [],
        newUser: false,
        storeDto: null,
        checkUserValid: false,
        validUser: false,
        onSaveCallback: props.onSaveCallback
      };
      this.saveStoreDetails = this.saveStoreDetails.bind(this);
    }

    componentDidMount() {
      api.fetch('company/Companies').then(r => { 
        this.setState({companies: ui_helpers.idNameToValueLabel(r.data.data) });
      }).catch(error => Alert.error("There was an error loading the company list"));
      getRegions().then(o => this.setState(o));
    }

    componentDidUpdate(prevProps, prevState) {
      if (prevState.validUser !== this.state.validUser) {
        if (this.state.validUser) {
          this.saveStoreDetails()
        }
      }
    }

    isFormValid(s, cashSaleHeaderImage) {
      let warnings = [];
      if (s.name === "") {
        warnings.push("Provide a name for this new store in order to save.");
      }
      if (s.abbr === "") {
        warnings.push("Provide an abbreviation for this new store in order to save.");
      }
      if (s.isOwner && s.enableCashSales && !s.company?.cashSaleHeaderImageExisted && !cashSaleHeaderImage) {
        warnings.push("Either provide a cash sale header image or choose to disable cash sales.");
      }
      if (s.isOwner && (!s.company?.states || s.company?.states.length === 0)) {
        warnings.push("Select at least 1 state in which this store does business.");
      }
      if (warnings.length) {
        Alert.warning(warnings.join(' '));
        return null;
      }
      return warnings.length === 0;
    }

    isUserFormValid() {
      this.setState({ checkUserValid: true });
      return this.state.validUser;
    }

    saveStoreDetails(store, cashSaleHeaderImage) {
      if (!this.isFormValid(store, cashSaleHeaderImage)) return;
      if (this.state.newUser && !this.isUserFormValid()) return;
      let vm = Object.assign({}, store);
      if (vm.isOwner)
      {
        vm.company = Object.assign({}, vm.company);
        vm = ui_helpers.prepareStoreCompanyPayload(vm);
      }
      api.post("store/SaveDealerDetails", vm).then(response => {
        if (response.data.success) {
          let newStore = ui_helpers.shapeStore(response.data.data, this.state.companies) 
          if (this.state.newUser) {
            this.setState({ storeDto: newStore, cashSaleHeaderImage: null });
            this.props.onSaveCallback(false);
          } else {
            if (vm.isOwner && cashSaleHeaderImage) {
              api.post_form_data('Company/SaveHeader', 
                ui_helpers.buildCaptiveHeaderPayload(newStore.companyId, cashSaleHeaderImage))
              .then(r => {
                if (!r.data.success) {
                  Alert.warning("There was an error saving the cash sale header image")
                }
                this.setState({ store: newStore });
                this.props.onSaveCallback(false);
              }).catch(error => console.error(error));
            } else {
              this.setState({ store: newStore });
              this.props.onSaveCallback(false);
            }
          }
        }
        else 
        {
          Alert.error(response.data.message);
        }
      }).catch(error => {
        console.error(error);
        Alert.error("There was an error saving the store details.");
      });
    }

    render() {
      const isSysAdmin = ui_helpers.isSystemAdministrator(this.props.currentUser);
      return (
            <Container fluid>
                <Card>
                    <CardHeader>
                        <Row>
                            <Col>
                                <h3>{this.state.store ? this.state.store.name : "New Store"} Details</h3>
                            </Col>
                            <Col>
                                <ButtonGroup className="float-end">
                                    <Button
                                        size="sm"
                                        className="btn-success cf-success"
                                        style={{ color: "white" }}
                                        onClick={() => this.setState({
                                            newUser: !this.state.newUser
                                        })}
                                    >
                                        <FontAwesomeIcon icon={this.state.newUser ? "minus" : "plus"} />{" "}
                                        User
                                    </Button>
                                </ButtonGroup>
                            </Col>
                        </Row>
                    </CardHeader>
                    <CardBody>
                      {this.state.companies.length
                        ? (<EditStore
                            store={this.state.store}
                            states={this.state.states}
                            companies={this.state.companies}
                            isSysAdmin={isSysAdmin}
                            isRTOAdmin={isSysAdmin || ui_helpers.isAnyRTOCompanyAdmin(this.props.currentUser)}
                            onSave={(s, cashSaleHeaderImage) => this.saveStoreDetails(s, cashSaleHeaderImage)}
                            onCancel={() => this.props.onSaveCallback()}
                          />)
                        : null
                      }
                    </CardBody>
                    <CardBody>
                        {this.state.newUser && (
                          <UserAddEdit
                            currentUser={this.props.currentUser}
                            returnRoute=""
                            computedMatch={this.props.computedMatch}
                            manufacturer={false}
                            store={true}
                            storeDto={this.state.storeDto}
                            multiAdd={true}
                            validCallback={() => this.setState({
                                validUser: true
                            })}
                            invalidCallback={() => this.setState({
                                validUser: false,
                                checkUserValid: false
                            })}
                            checkUserValid={this.state.checkUserValid}
                            cancel={() => this.setState({
                                newUser: false
                            })}
                          />
                        )}
                     </CardBody>
                </Card>
            </Container>
        );
    }
}