import React, {useState, useEffect, useContext, Fragment, useLayoutEffect} from 'react';
import {
  Button, ButtonGroup,
  Col,
  Row,
  Modal,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Input,
  Label,
  Alert
} from 'reactstrap';
import CurrencyInput from "react-currency-input-field";

export default function InventoryDiscountModal({setShowDiscountModal, discount, setDiscount, applyDiscount, isWebVisible}) {
	return (<Modal isOpen fullscreen="sm" toggle={() => setShowDiscountModal(false)}>
    <ModalHeader>
      Discount This Inventory
    </ModalHeader>
    <ModalBody>
      <Label>New Price</Label>
      <CurrencyInput className='form-control' prefix='$' value={discount} onValueChange={(e) => setDiscount(e)}/>
      {isWebVisible
        ? (<Alert color="warning" className="mt-1 mb-0">This item is being sold on the website. Be sure to update that price separately if necessary.</Alert>)
        : null
      }
    </ModalBody>
    <ModalFooter>
      <Row className={'d-flex justify-content-end'}>
        <Col>
          <ButtonGroup className="float-end">
            <Button color='primary' onClick={applyDiscount}>Save</Button>
            <Button onClick={() => setShowDiscountModal(false)}>Close</Button>
          </ButtonGroup>
        </Col>
      </Row>
    </ModalFooter>
  </Modal>);
}