import React from "react";
import { boundingBoxOffset } from "../floorplanConstants";
import styles from "../floorplanTool.module.css";
import { useControlTarget, useDisplaySettings, useMovability } from "../hooks";
import { ControlPoint } from "./";

function TextNote(props) {
  const [displaySettings] = useDisplaySettings();
  const [controlTarget, setControlTarget] = useControlTarget();
  const [transform, handleMove, handleRotate] = useMovability(props);

  const width =
    props.name.length * (displaySettings.fontSize / 1.7) +
    boundingBoxOffset * 2;
  const height = displaySettings.fontSize + boundingBoxOffset * 2;

  const active = controlTarget.includes(props.domId);

  return (
    <g transform={transform}>
      <text
        className={styles.svgTxt}
        fontSize={displaySettings.fontSize}
        textAnchor="middle"
      >
        {props.name}
      </text>
      <rect
        id={props.domId}
        style={{ cursor: active ? "move" : "pointer" }}
        onMouseDown={handleMove}
        onTouchStart={handleMove}
        x={-(width / 2)}
        y={-(height / 2) - displaySettings.fontSize / 3}
        width={width}
        height={height}
        fill={active ? "rgba(0,0,255,0.05)" : "rgba(0,0,0,0)"}
        stroke={active ? "#0d6efd" : "none"}
        strokeDasharray={2}
        strokeWidth={0.5}
      />
      {active ? (
        <>
          <line
            x1={0}
            y1={-(height / 2) - displaySettings.fontSize / 3}
            x2={0}
            y2={-(height / 2) - displaySettings.fontSize / 3 - 18}
            stroke="#0d6efd"
            strokeWidth={0.5}
          />
          <ControlPoint
            id={"rotate-" + props.domId}
            cx={0}
            cy={-(height / 2) - displaySettings.fontSize / 3 - 18}
            onClick={() => setControlTarget("rotate-" + props.domId)}
            onMouseDown={handleRotate}
            onTouchStart={handleRotate}
          />
          <text
            x={6}
            y={-(height / 2) - displaySettings.fontSize / 3 - 12}
            className={styles.svgTxt}
            fontSize={displaySettings.fontSize * 1.5}
            fill="#0d6efd"
          >
            {Math.round(props.rotation)}°
          </text>
        </>
      ) : null}
    </g>
  );
}

export default TextNote;
