import React, { useEffect } from "react";
import { v4 as uuid } from "uuid";
import { porchTypeOptions, porchTypes } from "../floorplanConstants";
import styles from "../floorplanTool.module.css";
import {
  useActions,
  useAppState,
  useControlTarget,
  useDisplaySettings,
  useExterior,
  usePointerCoordinates,
} from "../hooks";
import {
  cornersToPathReducer,
  radiansToDegrees,
  snap,
  toFeetInchesString,
} from "../utils";
import { ControlPoint } from "./";

const controlPoint1Id = uuid();
const controlPoint2Id = uuid();
const controlPoint3Id = uuid();

function DeluxePorch() {
  const [exterior, exteriorDispatch] = useExterior();
  const [appState, appStateDispatch] = useAppState();
  const [coordinates] = usePointerCoordinates();
  const [controlTarget] = useControlTarget();
  const [displaySettings] = useDisplaySettings();
  const actions = useActions({ exteriorDispatch, appStateDispatch });

  useEffect(() => {
    if (exterior.porchType === porchTypes.fourWallDeluxe1) {
      if (appState.isDraggingPorchHandle && controlTarget === controlPoint1Id) {
        actions.setPorchMax(snap(coordinates.x, 6));
        actions.setPorchWall1Length(snap(exterior.height - coordinates.y, 6));
      }
      if (appState.isDraggingPorchHandle && controlTarget === controlPoint2Id) {
        actions.setPorchWall2Length(snap(exterior.porchMax - coordinates.x, 6));
        actions.setPorchWall1Length(snap(exterior.height - coordinates.y, 6));
      }
      if (appState.isDraggingPorchHandle && controlTarget === controlPoint3Id) {
        actions.setPorchMin(snap(coordinates.x, 6));
        actions.setPorchWall4Length(snap(coordinates.y, 6));
      }
      // forceWall3Integer();
    }
    if (exterior.porchType === porchTypes.fourWallDeluxe2) {
      if (appState.isDraggingPorchHandle && controlTarget === controlPoint1Id) {
        actions.setPorchMin(snap(coordinates.x, 6));
        actions.setPorchWall1Length(snap(exterior.height - coordinates.y, 6));
      }
      if (appState.isDraggingPorchHandle && controlTarget === controlPoint2Id) {
        actions.setPorchWall2Length(snap(exterior.porchMax - coordinates.x, 6));
        actions.setPorchWall4Length(snap(coordinates.y, 6));
      }
      if (appState.isDraggingPorchHandle && controlTarget === controlPoint3Id) {
        actions.setPorchMax(snap(coordinates.x, 6));
        actions.setPorchWall4Length(snap(coordinates.y, 6));
      }
      // forceWall3Integer();
    }
  }, [controlTarget, coordinates]);

  let corners = [];

  if (exterior.porchType === porchTypes.fourWallDeluxe1) {
    corners = [
      {
        x: exterior.porchMin,
        y: 0,
      },
      {
        x: exterior.width,
        y: 0,
      },
      {
        x: exterior.width,
        y: exterior.height,
      },
      {
        x: exterior.porchMax,
        y: exterior.height,
      },
      {
        x: exterior.porchMax,
        y: exterior.height - exterior.porchWall1Length,
        controlTarget: controlPoint1Id,
      },
      {
        x: exterior.porchMax - exterior.porchWall2Length,
        y: exterior.height - exterior.porchWall1Length,
        controlTarget: controlPoint2Id,
      },
      {
        x: exterior.porchMin,
        y: exterior.porchWall4Length,
        controlTarget: controlPoint3Id,
      },
      {
        x: exterior.porchMin,
        y: 0,
      },
    ];
  } else if (exterior.porchType === porchTypes.fourWallDeluxe2) {
    corners = [
      {
        x: exterior.porchMax,
        y: 0,
      },
      {
        x: exterior.width,
        y: 0,
      },
      {
        x: exterior.width,
        y: exterior.height,
      },
      {
        x: exterior.porchMin,
        y: exterior.height,
      },
      {
        x: exterior.porchMin,
        y: exterior.height - exterior.porchWall1Length,
        controlTarget: controlPoint1Id,
      },
      {
        x: exterior.porchMax - exterior.porchWall2Length,
        y: exterior.porchWall4Length,
        controlTarget: controlPoint2Id,
      },
      {
        x: exterior.porchMax,
        y: exterior.porchWall4Length,
        controlTarget: controlPoint3Id,
      },
      {
        x: exterior.porchMax,
        y: 0,
      },
    ];
  }

  const path = corners.reduce(cornersToPathReducer, "M ");

  return (
    <g>
      <path
        d={path}
        stroke={appState.editingPorch ? "#0d6efd" : "black"}
        strokeWidth={2}
        fill="none"
      />
      {appState.editingPorch
        ? corners
            .filter((c) => c.controlTarget)
            .map((c) => (
              <ControlPoint
                key={c.controlTarget}
                id={c.controlTarget}
                className={c.controlTarget}
                cx={c.x}
                cy={c.y}
                onMouseDown={() => actions.setIsDraggingPorchHandle(true)}
                onTouchStart={() => actions.setIsDraggingPorchHandle(true)}
              />
            ))
        : null}
      <text
        className={styles.svgTxt}
        fontSize={displaySettings.fontSize}
        x={displaySettings.fontSize}
        y={
          exterior.porchType === porchTypes.fourWallDeluxe1
            ? exterior.height - displaySettings.fontSize * 5
            : displaySettings.fontSize * 1.5
        }
      >
        <tspan>
          Porch:{" "}
          {porchTypeOptions.find((o) => o.value === exterior.porchType)?.label}
        </tspan>
        <tspan x={displaySettings.fontSize} dy={displaySettings.fontSize}>
          Wall 1: {toFeetInchesString(exterior.porchWall1Length)}
        </tspan>
        <tspan x={displaySettings.fontSize} dy={displaySettings.fontSize}>
          Wall 2: {toFeetInchesString(exterior.porchWall2Length)}
        </tspan>
        <tspan x={displaySettings.fontSize} dy={displaySettings.fontSize}>
          Wall 3: {toFeetInchesString(exterior.porchWall3Length)} (
          {Math.floor(
            radiansToDegrees(
              Math.asin(
                (exterior.porchMax -
                  exterior.porchMin -
                  exterior.porchWall2Length) /
                  exterior.porchWall3Length
              )
            )
          )}
          °)
        </tspan>
        <tspan x={displaySettings.fontSize} dy={displaySettings.fontSize}>
          Wall 4: {toFeetInchesString(exterior.porchWall4Length)}
        </tspan>
      </text>
    </g>
  );
}

export default DeluxePorch;
