import React, {useEffect, useState} from 'react';
import {Button, Card, Col, FormGroup, Label, Row, Table} from "reactstrap";
import Select from "react-select";
import _, {differenceBy} from "lodash";
import {api, constants} from "../utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {ui_helpers} from "../helpers";
import {FaFontAwesome} from "react-icons/fa";

export default function BundleInfo({onChange, selectedSku, manufacturer, data, bundleContext, updateAddons }) {
const [selectedBundle, setSelectedBundle] = useState(null);
const [skuBundles, setSkuBundles] = useState([]);
const [bundleAddOns, setBundleAddOns] = useState([]);
const [selectedBundles, setSelectedBundles] = useState([]);
const [lastBundleUrl, setLastBundleUrl] = useState("");

useEffect(() => {
  if (_.isEmpty(selectedSku) || _.some(skuBundles, b => b.manufacturerSubProductSKUId !== selectedSku.id))
  {
    setSkuBundles([]);
    setBundleAddOns([]);
    setSelectedBundle(null);
    setSelectedBundles([]);
  }
  refreshSkuBundles((newBundleList) => {
    setSkuBundles(newBundleList);
    if (!data || !data.contractAddOns) return;
    const bundleIds = _.chain(data.contractAddOns)
      .filter(x => x.bundleId)
      .map(x => x.bundleId)
      .uniq()
      .value();
    let resolvedBundleList = data.associatedBundles || [];
    for(let i = 0; i < bundleIds.length; i++) {
      if (!_.some(resolvedBundleList, bl => bl.id === bundleIds[i])) {
        const additionalBundle = _.find(newBundleList, x => x.id === bundleIds[i]);
        if (additionalBundle) {
          resolvedBundleList.push(additionalBundle);
        }
      }
    }
    addIncomingBundlesToList(resolvedBundleList);
  });
}, [selectedSku, data?.associatedBundles, data?.contractAddOns]);

useEffect(() => {
  if(!selectedBundle) return;
  getBundleAddOns();
}, [selectedBundle]);

function addIncomingBundlesToList(bundles) {
  if (!bundles || !bundles.length) return;
  //if bundle already exists in selection then don't add it; if it doesn't then add it
  const filterMatch = differenceBy(bundles, selectedBundles, 'id');
  let newBundles = selectedBundles.slice();
  if (filterMatch.length) {
    newBundles = [...selectedBundles, ...filterMatch];
  }
  setSelectedBundles(newBundles);
}

function getBundleAddOns() {
  if (_.isEmpty(selectedSku)) return;
  api.fetch(`contract/GetSKUBundleAddOns/${selectedBundle.id}`).then(r => {
    if(!r.data.success) return;
    setBundleAddOns(r.data.message);
  }).catch(err => console.error(err));
}

function refreshSkuBundles(onSuccess) {
  if (_.isEmpty(selectedSku) || selectedSku.value === constants.OTHER_ID) return;
  const url = bundleContext === constants.BUNDLE_CONTEXT.contract
    ? 'contract/ListSKUBundles'
    : 'Inventory/ListSKUBundles';
  const skuId = selectedSku.id || selectedSku.value;
  const requestBundleUrl = `${url}/${skuId}`;
  if (lastBundleUrl === requestBundleUrl) {
    if (onSuccess) {
      onSuccess(skuBundles);
    }
    return;
  }
  setLastBundleUrl(requestBundleUrl);
  api.post(url, {
    activeOnly: true,
    manufacturerSkuId: skuId
  }).then(r => {
    if (!r.data) return;
    if (onSuccess) {
      onSuccess(r.data);
    }
  }).catch(api.catchHandler);
}

function addAddOnsToUnit() {
  if (!selectedBundle) return;
  let newAddOnList = [];
  if (bundleContext === constants.BUNDLE_CONTEXT.contract) {
    let newContractUnit = { ...data };
    newContractUnit.contractAddOns = [...newContractUnit.contractAddOns, ...convertBundlesToAddOns(bundleAddOns)];
    newContractUnit.bundlePrice = selectedBundle.price;
    onChange(newContractUnit);
    newAddOnList = newContractUnit.contractAddOns;
  }
  else if (bundleContext === constants.BUNDLE_CONTEXT.inventory) 
  {
    let newInventory = _.cloneDeep(data);
    newInventory.unitOptionsList = [...newInventory.unitOptionsList, ...convertBundlesToAddOns(bundleAddOns)];
    newInventory.bundlePrice = selectedBundle.price;
    onChange(newInventory);
    newAddOnList = newInventory.unitOptionsList;
  }
  updateAddons(newAddOnList);
  selectBundle();
}

function selectBundle() {
  let newBundles = selectedBundles.slice();
  newBundles.push(selectedBundle);
  setSelectedBundles(newBundles);
  setBundleAddOns([]);
  setSelectedBundle(null);
}

function removeBundleItem(bundleAddOns) {
  if (bundleContext === constants.BUNDLE_CONTEXT.contract) {
    let newContractUnit = { ...data };
    newContractUnit.contractAddOns = _.filter(newContractUnit.contractAddOns, a => a.bundleId !== bundleAddOns.id);
    //newContractUnit.unitPrice -= bundleAddOns.price;
    //pricing updates will happen in floorplan or manually if non build feature
    newContractUnit.bundlesToDelete.push(bundleAddOns.id);
    newContractUnit.associatedBundles = _.filter(newContractUnit.associatedBundles, b => b.id !== bundleAddOns.id);
    onChange(newContractUnit);
  }
  else if (bundleContext === constants.BUNDLE_CONTEXT.inventory) {
    let newInventory =  { ...data };
    newInventory.unitOptionsList = _.filter(newInventory.unitOptionsList, u => u.bundleId !== bundleAddOns.id);
    newInventory.associatedBundles = _.filter(newInventory.associatedBundles, b => b.id !== bundleAddOns.id);
    // newInventory.retailPrice -= bundleAddOns.price;
    //pricing updates will happen in floorplan or manually if non build feature
    newInventory.bundlesToDelete = newInventory.bundlesToDelete
        ? [...newInventory.bundlesToDelete, bundleAddOns.id]
        : [bundleAddOns.id];
    onChange(newInventory);
    updateAddons(newInventory.unitOptionsList);
  }
  setSelectedBundles(
    _.reject(selectedBundles, (nb) => nb.id === bundleAddOns.id));
  //refreshSkuBundles();
}

function convertBundlesToAddOns(bundledAddOns) {
  let result = [];
  _.each(bundledAddOns, b => {
    const a = b.addon;
    for(let i=0; i<b.qty; i++) {
      result.push({
        id: 0,
        addOnId: a.id,
        bundleId: b.allowedSkuBundleId,
        price: 0.00,
        name: a.name,
        description: a.description,
        seq: -1,
        showInFloorPlan: a.showInFloorPlan,
        hasDimensions: a.hasDimensions,
        length: a.length,
        width: a.width
      });
    }
  });
  return result;
}

  return (
      <>
          {skuBundles && Array.isArray(skuBundles) ?
              (<div className='pe-1'>
              <Label>
                Bundles
              </Label>
              <Row>
                <Col>
                  <Select options={_.map(skuBundles, d => ({...d, value: d.id, label: d.bundleName}))}
                    value={selectedBundle}
                    onChange={(x) => setSelectedBundle(x)}
                    defaultValue={{value: 0, label: 'Select a Bundle'}}
                    isDisabled={!skuBundles.length}
                  />
                </Col>
                <Col sm={'2'}>
                  <Button color="success" className="text-light float-end" onClick={addAddOnsToUnit}>
                    <FontAwesomeIcon icon="plus-circle" />
                  </Button>
                </Col>
              </Row>
              <div>
                  {/*Selected Bundles*/}
                  <Table>
                    <tbody>
                  {selectedBundles && selectedBundles.length
                    ? _.map(selectedBundles, (s, idx) => ((<tr key={`bundle-${s.id}-${idx}`}>
                        <td>{s.bundleName}</td>
                        <td>{ui_helpers.formatCurrency(s.price)}</td>
                        <td>
                          <Button color="danger" size="sm" className='text-white float-end' onClick={() => removeBundleItem(s)}>
                            <FontAwesomeIcon icon='times-circle' />
                          </Button>
                        </td>
                      </tr>)))
                    : null
                  }
                  </tbody>
                </Table>
              </div>
              <div className='mt-4 bg-light'>
              {selectedBundle &&
                <Table size='sm' striped>
                  <thead>
                  <tr>
                    <th>
                      {selectedBundle.bundleName} - {ui_helpers.formatCurrency(selectedBundle.price)}
                    </th>
                  </tr>
                  </thead>
                  <tbody>
                  {selectedBundle && bundleAddOns && bundleAddOns.length
                    ? _.map(_.filter(bundleAddOns, x => x.allowedSkuBundleId === selectedBundle.id),
                        d => (<tr key={d.addon.name}>
                          <td>{d.qty} x {d.addon.name}</td>
                        </tr>)) 
                    : null
                  }
                  </tbody>
                </Table>}
              </div>
          </div>)
        :null}
      </>
    );
}