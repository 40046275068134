import React from "react";
import { Table } from "reactstrap";
import _ from "lodash";

export default function HistoryList({historyList}) {
	return (<Table size="sm">
      <tbody>
        {_.map(historyList, h => 
          (<tr key={h.id} className="comment-row">
            <td>{h.name}</td>
            <td style={{width: "180px"}}>{h.occurredAtLocalized}</td>
            <td style={{width: "150px"}}>{h.performedByUserFullName}</td>
          </tr>))}
      </tbody>
    </Table>);
}

