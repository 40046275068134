import React, { Fragment, useState, useEffect, useRef } from "react";
import {
  Row,
  Col,
  FormGroup,
  Form,
  Input,
  InputGroup,
  Label,
  Button,
  ButtonGroup,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Alert as RSAlert,
} from "reactstrap";
import Alert from "react-s-alert-v3";
import classnames from "classnames";
import { confirmAlert } from "react-confirm-alert";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { api, ui_helpers, date_helpers } from "../helpers";
import _ from "lodash";
import { storage, constants } from "../utils";
import Select from "react-select";

const defaultPaymentData = (d) => {
  return {
    recurringBillingEmail: d.recurringBillingEmail,
    paymentDayOfMonth: d.paymentDayOfMonth || d.paymentDueDayOfMonth,
    recurringBillingPhone: d.recurringBillingPhone || d.customerPhone1,
    recurringBillingAddress: d.recurringBillingAddress || d.billingAddress1,
    recurringBillingCity: d.recurringBillingCity || d.billingCity,
    recurringBillingState: d.recurringBillingState || d.billingState,
    recurringBillingZip: d.recurringBillingZip || d.billingZip,
    creditCardType: d.creditCardType,
    ccNumber: d.ccNumber,
    ccExpiryMonth: d.ccExpiryMonth,
    ccExpiryYear: d.ccExpiryYear,
    ccFullName: d.ccFullName,
    achRoutingNo: d.achRoutingNo,
    achAccountNo: d.achAccountNo,
    achFullName: d.achFullName,
    achAccountType: constants.BANKING_ACCOUNT_TYPES[0].label,
    achBankName: ''
  };
};

const RecurringPaymentModal = (props) => {
  const [paymentData, setPaymentData] = useState(
    defaultPaymentData(props.recurringData)
  );

  function onChange(value, fieldName) {
    const newData = {
      ...paymentData,
      [fieldName]: value,
    };
    setPaymentData(newData);
  }

  function onBlur(value, fieldName) {
    switch (fieldName) {
      case "paymentDayOfMonth":
        let intVal = parseInt(value, 10);
        if (intVal < 1 || intVal > 28) {
          value = "";
          onChange(value, fieldName);
        }
        break;
      case "recurringBillingPhone":
        onChange(ui_helpers.formatPhoneField(value), fieldName);
        break;
      // case 'ccExpiryMonth':
      // case 'ccNumber':
      // case 'ccExpiryYear':
      // case 'recurringBillingEmail':
      // case 'recurringBillingZip':
      // 	break;
    }
  }

  function onCommit() {
    if (
      _.trim(paymentData.ccNumber).length < 4 &&
      _.trim(paymentData.achAccountNo).length < 4
    ) {
      Alert.warning(
        "Enter a valid account number - either credit card or ACH."
      );
      return;
    }
    if (_.trim(paymentData.ccNumber).length >= 4) {
      // credit card validation
      if (!paymentData.creditCardType) {
        Alert.warning("Select a credit card type.");
        return;
      }
    } else {
      // ACH validation
      if (_.trim(paymentData.achRoutingNo).length < 4) {
        Alert.warning("Enter a valid ACH routing number.");
        return;
      }
      if (_.trim(paymentData.achFullName).length < 4) {
        Alert.warning("Enter a valid ACH account holder full name.");
        return;
      }
      if (_.trim(paymentData.achBankName).length < 4) {
        Alert.warning("Enter a valid ACH bank name.");
        return;
      }
    }
    if (props.onSetRecurringData) {
      props.onSetRecurringData(paymentData);
    }
  }

  return (
    <Modal isOpen={true} centered className="modal-lg">
      <ModalHeader>Recurring Payment Data</ModalHeader>
      <ModalBody>
        <Form id="recurring-payment-form" className="form-horizontal">
          <div
            style={{
              backgroundColor: "#E5E5FF",
              borderRadius: "4px",
              padding: "4px",
              fontSize: "0.85rem",
            }}
          >
            <div style={{ fontWeight: "bold", fontSize: "16px" }}>
              AUTHORIZATION
            </div>
            <div>
              I authorize the charge or charges to my account as outlined in
              this form. I understand and agree that the monthly charge will
              continue until the total amount due is collected or until I revoke
              this authorization. This authorization is also applicable to any
              new account information, payments, and/or payment dates provided
              by me in future for the purpose of completing my account.
            </div>
            <div>
              <span style={{ fontWeight: "bold", color: "#ff0000" }}>
                CHANGE OF INFORMATION:
              </span>{" "}
              I agree to notify verbally or in writing to the above address
              fifteen (15) or more days prior to any change to the account
              and/or closing of the account or any change or situation that may
              affect charging the payment.
            </div>
            <div>
              <span style={{ fontWeight: "bold", color: "#ff0000" }}>
                CANCELLATION:
              </span>{" "}
              Upon payment in full, I understand that the RTO company will
              suspend these payments and provide me with a receipt showing that
              I have acquired ownership of the rented property. I can also
              request cancellation at any time by phone.
            </div>
          </div>
          <Row className="mb-1 mt-1">
            <Col sm="3" className="pt-1">
              <label className="control-label">Payment Day</label>
            </Col>
            <Col sm="9">
              <Input
                type="number"
                bsSize="sm"
                maxLength="2"
                value={paymentData.paymentDayOfMonth}
                style={{ width: "50px" }}
                onChange={(e) => onChange(e.target.value, "paymentDayOfMonth")}
                onBlur={(e) => onBlur(e.target.value, "paymentDayOfMonth")}
              />
            </Col>
          </Row>
          <Row className="mb-1">
            <Col sm="3">
              <label className="control-label">Email</label>
            </Col>
            <Col sm="9">
              <Input
                type="text"
                bsSize="sm"
                value={paymentData.recurringBillingEmail}
                maxLength="150"
                placeholder="Enter email address here for paperless billing"
                onChange={(e) =>
                  onChange(e.target.value, "recurringBillingEmail")
                }
                onBlur={(e) => onBlur(e.target.value, "recurringBillingEmail")}
              />
            </Col>
          </Row>
          <Row className="mb-1">
            <Col sm="3">
              <label className="control-label">Phone</label>
            </Col>
            <Col sm="9">
              <Input
                type="text"
                bsSize="sm"
                value={paymentData.recurringBillingPhone}
                maxLength="20"
                onChange={(e) =>
                  onChange(e.target.value, "recurringBillingPhone")
                }
                onBlur={(e) => onBlur(e.target.value, "recurringBillingPhone")}
              />
            </Col>
          </Row>
          <Row className="mb-1">
            <Col sm="3">
              <label className="control-label">Address</label>
            </Col>
            <Col sm="9">
              <Input
                type="text"
                bsSize="sm"
                value={paymentData.recurringBillingAddress}
                maxLength="200"
                style={{ width: "300px", marginBottom: "2px", marginLeft: "0" }}
                placeholder="Billing Address"
                onChange={(e) =>
                  onChange(e.target.value, "recurringBillingAddress")
                }
              />
              <div>
                <Input
                  type="text"
                  bsSize="sm"
                  value={paymentData.recurringBillingCity}
                  maxLength="50"
                  style={{
                    width: "147px",
                    float: "left",
                    marginLeft: "0",
                    clear: "both",
                  }}
                  placeholder="City"
                  onChange={(e) =>
                    onChange(e.target.value, "recurringBillingCity")
                  }
                />
                <div
                  style={{ float: "left", width: "15px", marginTop: "10px" }}
                >
                  ,{" "}
                </div>
                <Input
                  type="text"
                  bsSize="sm"
                  value={paymentData.recurringBillingState}
                  maxLength="20"
                  style={{ width: "45px", float: "left" }}
                  placeholder="ST"
                  onChange={(e) =>
                    onChange(e.target.value, "recurringBillingState")
                  }
                />
                <Input
                  type="text"
                  bsSize="sm"
                  value={paymentData.recurringBillingZip}
                  maxLength="20"
                  style={{ width: "90px", marginLeft: "3px", float: "left" }}
                  placeholder="Zip"
                  onChange={(e) =>
                    onChange(e.target.value, "recurringBillingZip")
                  }
                  onBlur={(e) => onBlur(e.target.value, "recurringBillingZip")}
                />
              </div>
            </Col>
          </Row>
          <Row className="mb-1">
            <Col sm="3">
              <label className="control-label">Credit Card</label>
            </Col>
            <Col sm="9">
              <div style={{ width: "60%", fontSize: "0.9rem" }}>
                <Select
                  options={constants.CREDIT_CARD_TYPES}
                  value={paymentData.creditCardType}
                  onChange={(x) => onChange(x, "creditCardType")}
                />
              </div>
              <div className="mt-1">
                <Input
                  type="text"
                  bsSize="sm"
                  value={paymentData.ccNumber}
                  maxLength="20"
                  style={{ width: "200px", marginLeft: "0", float: "left" }}
                  placeholder="Card Number"
                  onChange={(e) => onChange(e.target.value, "ccNumber")}
                  onBlur={(e) => onBlur(e.target.value, "ccNumber")}
                />
                <span
                  style={{
                    float: "left",
                    padding: "10px 2px 4px 2px",
                    fontSize: "0.9rem",
                  }}
                >
                  expires
                </span>
                <Input
                  type="text"
                  bsSize="sm"
                  value={paymentData.ccExpiryMonth}
                  maxLength="2"
                  style={{ width: "40px", float: "left" }}
                  placeholder="MM"
                  onChange={(e) => onChange(e.target.value, "ccExpiryMonth")}
                  onBlur={(e) => onBlur(e.target.value, "ccExpiryMonth")}
                />
                <Input
                  type="text"
                  bsSize="sm"
                  value={paymentData.ccExpiryYear}
                  maxLength="4"
                  style={{ width: "60px", marginLeft: "1px", float: "left" }}
                  placeholder="YYYY"
                  onChange={(e) => onChange(e.target.value, "ccExpiryYear")}
                  onBlur={(e) => onBlur(e.target.value, "ccExpiryYear")}
                />
                <br />
                <Input
                  type="text"
                  bsSize="sm"
                  value={paymentData.ccFullName}
                  maxLength="70"
                  style={{ float: "left" }}
                  placeholder="Cardholder Full Name"
                  onChange={(e) => onChange(e.target.value, "ccFullName")}
                />
              </div>
            </Col>
          </Row>
          <Row className="mb-1">
            <Col sm="3">
              <label className="control-label">ACH</label>
            </Col>
            <Col sm="9">
              <Input
                type="text"
                bsSize="sm"
                value={paymentData.achRoutingNo}
                maxLength="50"
                style={{ marginBottom: "2px", float: "left" }}
                placeholder="Routing Number"
                onChange={(e) => onChange(e.target.value, "achRoutingNo")}
              />
              <Input
                type="text"
                bsSize="sm"
                value={paymentData.achAccountNo}
                maxLength="50"
                style={{ marginBottom: "2px", float: "left" }}
                placeholder="Account Number"
                onChange={(e) => onChange(e.target.value, "achAccountNo")}
              />
              <Input
                type="text"
                bsSize="sm"
                value={paymentData.achFullName}
                maxLength="70"
                style={{ float: "left" }}
                placeholder="Account Holder Full Name"
                onChange={(e) => onChange(e.target.value, "achFullName")}
              />
            </Col>
          </Row>
          <Row className="mb-1">
            <Col sm="3">
              <label className="control-label">Account Type</label>
            </Col>
            <Col sm="9">
              <Select
                options={constants.BANKING_ACCOUNT_TYPES}
                onChange={(option) => onChange(option.label, 'achAccountType')}
                value={_.find(constants.BANKING_ACCOUNT_TYPES, a => a.label === paymentData.achAccountType)}
              />
            </Col>
          </Row>
          <Row className="mb-1">
            <Col sm={{size: 9, offset: 3}}>
              <Input type='text'
                placeholder="Bank Name"
                bsSize="sm"
                value={paymentData.achBankName}
                maxLength="70"
                onChange={(e) => onChange(e.target.value, 'achBankName')}
              />
            </Col>
          </Row>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Row>
          <Col className="d-flex flex-row justify-content-end">
            <Col className="d-flex justify-content-end">
              <Button color="secondary" onClick={props.onCancel}>
                Cancel
              </Button>
            </Col>
            <Col className="ms-1 d-flex justify-content-end">
              <Button color="primary" className="float-end" onClick={onCommit}>
                Save
              </Button>
            </Col>
          </Col>
        </Row>
      </ModalFooter>
    </Modal>
  );
};
export default RecurringPaymentModal;
