import React from "react";
import { porchTypes } from "../floorplanConstants";
import { useAppState, useExterior } from "../hooks";
import {
  CornerPorch,
  DeluxePorch,
  EndPorch,
  SidePorch,
  InsetSidePorch,
} from "./";

function Exterior() {
  const [appState] = useAppState();
  const [exterior] = useExterior();

  return (
    <g>
      <rect
        x={0}
        y={0}
        width={exterior.width}
        height={exterior.height}
        fill="none"
        stroke="grey"
        strokeWidth={2}
        strokeDasharray={[4, 4]}
      />
      {exterior.porchType === porchTypes.none ? (
        <rect
          x={0}
          y={0}
          width={exterior.width}
          height={exterior.height}
          fill="none"
          stroke={appState.editingPorch ? "#0d6efd" : "black"}
          strokeWidth={2}
        />
      ) : null}
      {exterior.porchType === porchTypes.end ? <EndPorch /> : null}
      {exterior.porchType === porchTypes.side ? <SidePorch /> : null}
      {exterior.porchType === porchTypes.twoWallCorner1 ? (
        <CornerPorch />
      ) : null}
      {exterior.porchType === porchTypes.insetSide ? <InsetSidePorch /> : null}
      {![
        porchTypes.none,
        porchTypes.end,
        porchTypes.side,
        porchTypes.twoWallCorner1,
        porchTypes.insetSide
      ].includes(exterior.porchType) ? (
        <DeluxePorch />
      ) : null}
    </g>
  );
}

export default Exterior;
