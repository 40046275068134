import { create } from "zustand";
import { TOP_LEVEL_VIEWS, SIGNATURE_TYPES } from "../constants";
import { sortByProperties } from "../utils";

const useAppStore = create((set, get) => ({
  currentView: TOP_LEVEL_VIEWS.IDLE,
  setCurrentView: (newView) => set((state) => ({ ...state, currentView: newView })),
  contractId: null,
  setContractId: (n) => set((state) => ({ ...state, contractId: n })),
  companyName: null,
  setCompanyName: (n) => set((state) => ({ ...state, companyName: n })),
  paperlessEmail: null,
  setPaperlessEmail: (n) => set((state) => ({ ...state, paperlessEmail: n })),
  contractPaymentData: null,
  setContractPaymentData: (n) => set((state) => ({ ...state, contractPaymentData: n })),
  templateId: null,
  setTemplateId: (n) => set((state) => ({ ...state, templateId: n })),
  filePath: "",
  setFilePath: (s) => set((state) => ({ ...state, filePath: s })),
  numPages: 0,
  setNumPages: (n) => set((state) => ({ ...state, numPages: n })),
  showOverlays: true,
  toggleShowOverlays: () =>
    set((state) => ({ ...state, showOverlays: !state.showOverlays })),
  currentPageIndex: 0,
  setCurrentPageIndex: (n) =>
    set((state) => ({ ...state, currentPageIndex: n })),
  signatureLocations: [],
  setSignatureLocations: (newSls) =>
    set((state) => ({
      ...state,
      signatureLocations: sortByProperties(newSls, ["pageIndex", "yPosition"]),
    })),
  signatureLocationEditing: null,
  setSignatureLocationEditing: (newSl) =>
    set((state) => ({ ...state, signatureLocationEditing: newSl })),
  signatureModalOpen: false,
  setSignatureModalOpen: (isOpen) =>
    set((state) => ({ ...state, signatureModalOpen: isOpen })),
  confirmModalOpen: false,
  setConfirmModalOpen: (isOpen) =>
    set((state) => ({ ...state, confirmModalOpen: isOpen })),
  isEditingAccept: false,
  setIsEditingAccept: (b) => set((state) => ({ ...state, isEditingAccept: b })),
  signatureDataUrls: [],
  setSignatureDataUrls: (arr) =>
    set((state) => ({ ...state, signatureDataUrls: arr })),
  initialDataUrls: [],
  setInitialDataUrls: (arr) =>
    set((state) => ({ ...state, initialDataUrls: arr })),
  selectedSignatureIndex: 0,
  setSelectedSignatureIndex: (n) =>
    set((state) => ({ ...state, selectedSignatureIndex: n })),
  signatureLocationEditing: null,
  setSignatureLocationEditing: (newSl) =>
    set((state) => ({ ...state, signatureLocationEditing: newSl })),
  landscapeModeModalOpen: false,
  setLandscapeModeModalOpen: (isOpen) =>
    set((state) => ({ ...state, landscapeModeModalOpen: isOpen })),
  recurringPaySignatureModalOpen: false,
  setRecurringPaySignatureModalOpen: (isOpen) =>
    set((state) => ({...state, recurringPaySignatureModalOpen: isOpen })),
  signDataUrl: null,
  setSignDataUrl: (dataUrl) => 
    set((state) => ({...state, signDataUrl: dataUrl })),
  isRecurringAccept: false,
  setIsRecurringAccept: (res) => 
    set((state) => ({ ...state, isRecurringAccept: res })),
  isContractCashSale: false,
  setIsContractCashSale: (res) =>
    set((state) => ({ ...state, isContractCashSale: res })),
}));

export default useAppStore;
