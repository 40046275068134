import useSignalR from "./useSignalR";
import { useEffect, useState } from "react";

export default function usePosHub(contractId) {
  const [sessionStarted, setSessionStarted] = useState(false);
  const [completedSignatures, setCompletedSigntaures] = useState([]);
  const [contractFinalized, setContractFinalized] = useState(false);
  const [paymentInfo, setPaymentInfo] = useState(null);
  const [recurringEnabled, setRecurringEnabled] = useState(false);

  const { connection, connectionError, isConnected, useHubMethod } =
    useSignalR("/Pos");

  useEffect(() => {
    if (isConnected && contractId) {
      connection.invoke("JoinGroup", contractId.toString());
    }
  }, [isConnected, contractId]);

  useHubMethod("SignatureCompleted", (_, signatureLocationId) => {
    setCompletedSigntaures((prev) => [...prev, Number(signatureLocationId)]);
  });

  useHubMethod("SignatureSessionStarted", () => {
    setSessionStarted(true);
  });

  useHubMethod("ContractFinalized", () => {
    setContractFinalized(true);
  });

  useHubMethod("SendPaymentInfo", (p) => {
    setPaymentInfo(p);
  });

  useHubMethod("RecurringEnabled", () => {
    setRecurringEnabled(true);
  });

  return {
    sessionStarted,
    completedSignatures,
    contractFinalized,
    paymentInfo,
    recurringEnabled,
  };
}
