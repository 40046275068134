import axios from 'axios';
import { API_URL } from "./constants";

class ApiResponse {
  /**
   * @param {boolean} success
   * @param {string} msg
   * @param {any} data
   */
  constructor(success, msg, data) {
    this.success = success;
    this.msg = msg;
    this.data = data;
  }
}

class SuccessResponse extends ApiResponse {
  /**
   * @param {any} data
   */
  constructor(data) {
    super(true, null, data);
  }
}

class FailureResponse extends ApiResponse {
  /**
   * @param {any} msg
   */
  constructor(msg) {
    super(false, msg, null);
  }
}

function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

function apiFetch(path) {
  return fetch(API_URL + path);
}

function apiPost(path, data) {
  return axios({
    method: 'post',
    url: `${API_URL}${path}`,
    data: data,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  });
}

export async function resolveContractIdFromToken(token) {
  const res = await apiPost("Signature/GetContractByToken/" + token);
  if (res.data.success) {
    return new SuccessResponse(res.data.message);
  }
  return new FailureResponse(res.data.message);
}

/**
 * @param {string} contractNumber
 */
// export async function getContract(contractNumber) {
//   const res = await apiFetch("Signature/FindContractById/" + contractNumber);
//   if (res.ok) {
//     const deserializedRes = await res.json();
//     if (!deserializedRes.contractId)
//       return new FailureResponse("No contract id.");
//     if (!deserializedRes.key) return new FailureResponse("No key.");
//     return new SuccessResponse(deserializedRes);
//   }
//   return new FailureResponse("Contract not found.");
// }

export async function getSignatureLocations(contractId) {
  const res = await apiFetch("Signature/GetSignatureLocations/" + contractId);
  if (res.ok) {
    const deserializedRes = await res.json();
    // if (!deserializedRes.contractId)
    //   return new FailureResponse("No template id.");
    // if (!deserializedRes.key) return new FailureResponse("No key.");
    return new SuccessResponse(deserializedRes);
  }
  return new FailureResponse("Unable to retrieve signature locations for this contract.");
}

export async function getContractData(contractId) {
  const res = await apiFetch("Signature/GetContractData/" + contractId);
  if (res.ok) {
    const deserializedRes = await res.json();
    return new SuccessResponse(deserializedRes.data);
  }
  return new FailureResponse("Contract data could not be retrieved.");
}
