import React, {useEffect, useState, Fragment} from "react";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import _ from "lodash";

export default function SelectFilter(props) {
  function resolveValue() {
    if (props.value && _.isArray(props.value)) {
      return _.reject(props.value, x => x === undefined);
    } else if (props.value) {
      return [props.value];
    }
    return [];
  }

  function onChange(selections) {
      let values = [];
      let labels = [];
      let filteredDisplay = '';
      if (selections && _.isArray(selections))
      {
          values = selections.length
              ? _.map(selections, x => x.value)
              : [];
          labels = selections.length
              ? _.map(selections, x => x.label)
              : [];
          let valueDisplay = labels.join(', ');
          filteredDisplay =
              values && valueDisplay
                  ? `${props.displayName}: ${valueDisplay}`
                  : '';
          //setValue(values);
          if (props.isSingleSelect)
          {
              const newValue = values && values.length ? values[0] : null;
              const label = labels && labels.length ? labels[0] : null;
              props.onChangeCallback({
                  filterName: props.filterName,
                  value: newValue,
                  label: label,
                  filteredDisplay: filteredDisplay
              });
          }
          else
          {
              props.onChangeCallback({
                  filterName: props.filterName,
                  value: values,
                  label: labels,
                  filteredDisplay: filteredDisplay
              });
          }
      } else if (selections) {
          values = [selections.value];
          labels = [selections.label];
          let valueDisplay = labels.join(', ');
          filteredDisplay = `${props.displayName}: ${selections.label}`;
          //setValue(values);
          if (props.isSingleSelect)
          {
              props.onChangeCallback({
                  filterName: props.filterName,
                  value: values[0],
                  label: labels[0],
                  filteredDisplay: filteredDisplay
              });
          }
          else
          {
              props.onChangeCallback({
                  filterName: props.filterName,
                  value: values,
                  label: labels,
                  filteredDisplay: filteredDisplay
              });
          }
      }
      else 
      {
          //If cross is clicked set values to empty
          //setValue({label: '', value: ''});
          props.onChangeCallback({
              filterName: props.filterName,
              value: null,
              label: null,
              filteredDisplay: ''
          });
      }
  }

  if (!props.options) return (<span/>);
  const value = resolveValue();
  const isMulti = !props.isSingleSelect;
  let selectedValue = null;
  if (isMulti && value && value.length) {
    selectedValue = _.filter(props.options, x => _.some(value, y => y === x.value));
  } else if (!isMulti && value && _.isArray(value) && value.length) {
    const valueArrayElement1 = value[0];
    selectedValue = _.isObject(valueArrayElement1)
      ? valueArrayElement1
      : _.find(props.options, x => x.value === valueArrayElement1);
  }
  return (<Fragment>
    <span className="filter-caption">{props.displayName}</span>
    <Select
      closeMenuOnSelect={!isMulti}
      hideSelectedOptions={!isMulti}
      isMulti={isMulti}
      isClearable
      isDisabled={props.isDisabled}
      options={props.options}
      onChange={onChange}
      value={selectedValue}
      onBlurResetsInput={false}
      onSelectResetsInput={false}
      onCloseResetsInput={false}
      classNamePrefix="react-select"
    />
  </Fragment>);
}
