import React, { useState, useEffect } from "react";
import { Button } from "reactstrap";
import { TOP_LEVEL_VIEWS } from "../constants";
import { useAppStore } from "../hooks";
import ReactPlayer from "react-player";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Logo from "../assets/logo.png";
import useWindowSize from "../hooks/useWindowSize";

export default function IntroVideo() {
  const setCurrentView = useAppStore((state) => state.setCurrentView);
  const companyName = useAppStore((x) => x.companyName);
  const [videoURL, setVideoURL] = useState('');
  const [canProceed, setCanProceed] = useState(true);
  const [isPlaying, setIsPlaying] = useState(true);
  const { width } = useWindowSize();
  const MOBILE_WIDTH = width <= 768;

  useEffect(() => {
    // this is a hack, but we were not told of multiple videos being presented, varying by company until after completion
    if (companyName) {
      const name = companyName.replaceAll(/ /g, '').toLowerCase();
      if (name.startsWith('carefree')) {
        setVideoURL(`https://app.rto360.com/Content/pos-video-carefree.mp4`);
      } else if (name.startsWith('rentabarn')) {
        setVideoURL(`https://app.rto360.com/Content/pos-video-rentabarn.mp4`);
      } else if (name.startsWith('dutchboy')) {
        setVideoURL(`https://app.rto360.com/Content/pos-video-dutchboy.mp4`);
      } else {
        setCurrentView(TOP_LEVEL_VIEWS.PAPERLESS_BILLING);
      }      
    }
  }, [companyName])

  function handleSubmit() {
    setCurrentView(TOP_LEVEL_VIEWS.PAPERLESS_BILLING);
  }

  return (
    <div className="w-full h-full d-flex flex-column align-items-center justify-content-center text-center xs:overflow-y-auto lg:overflow-none">
      <div className="embed-responsive mb-5">
      <img src={Logo} className="my-5" />
        {/* <video controls className="embed-responsive-item" /> */}
          <ReactPlayer
            url={videoURL}
            playing={isPlaying}
            onEnded={() => setCanProceed(true)}
            controls={true}
            width={MOBILE_WIDTH ? '100vw' : 'auto'}
          />
        {/* <div id="video-controls" style={{backgroundColor: "#999", opacity: 0.5}}>
          <button
            style={{
              float: "left", 
              width: "4%", 
              height: "100%", 
              border: "none", 
              cursor: "pointer", 
              background: "transparent"
            }}
            title={isPlaying ? "Pause" : "Play"}
            onClick={() => setIsPlaying((prior) => !prior)}
          >
            {isPlaying ? <FontAwesomeIcon icon='pause' /> : <FontAwesomeIcon icon='play' />}
          </button>
        </div> */}
      </div>
      <Button
        color="primary"
        size="lg"
        onClick={handleSubmit}
        disabled={!canProceed}
        style={{marginBottom: 10}}
      >
        Proceed
      </Button>
    </div>
  );
}
