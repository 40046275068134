import React, { Component, useState, useEffect } from "react";
import { DebounceInput } from "react-debounce-input";

export default function TextFilter(props) {
    const [value, setValue] = useState();

    const onChange = (value) => {
        let resolvedValue = value;
        if (props.forceInt) {
            resolvedValue = parseInt(value);
            if (isNaN(resolvedValue)) {
                resolvedValue = null;
            }
        }
        let filteredDisplay = '';
        if (resolvedValue) {
            if (props.useEqualsDisplayName) {
                filteredDisplay = `${props.displayName}: ${resolvedValue}`;
            } else {
                filteredDisplay = `${props.displayName} ${props.descriptionPrefix ? props.descriptionPrefix : 'starts with'}:  ${resolvedValue} `
            }
        } else {
            filteredDisplay = null;
        }

        props.onChangeCallback({
            filterName: props.filterName,
            value: resolvedValue,
            filteredDisplay: filteredDisplay
        });
    }
        return (
            <span>
                <span
                    className={
                        props.customFilterClassName
                            ? props.customFilterClassName
                            : "filter-caption"
                    }
                >
                    {props.displayName}
                </span>
                <DebounceInput
                    className="form-control"
                    minLength={props.minLength || 2}
                    value={props.value}
                    debounceTimeout={props.debounceTimeout || 300}
                    onChange={event => onChange(event.target.value)}
                />
            </span>
        );
    }