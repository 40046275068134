import React, { useState, useEffect} from "react";
import {
    Row,
    Col,
    Input,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    ButtonGroup,
    Button,
    InputGroupText,
    InputGroup,
    UncontrolledAlert,
    Table
} from "reactstrap"
import { constants, api } from '../utils';
import { ui_helpers } from '../helpers';
import Select from "react-select";
import _ from "lodash";
import Alert from "react-s-alert-v3";
import CreatableSelect from "react-select/creatable";

export default function SellInventoryModal(props) {
    const [company, setCompany] = useState(null);
    const [discount, setDiscount] = useState(null);
    const [discountPrice, setDiscountPrice] = useState(props.retailPrice 
      ? props.retailPrice.toFixed(2)
      : props.retailPrice);
    const [companyList, setCompanyList] = useState([]);
    const [externalCompanyList, setExternalCompanyList] = useState([]);
    const [showExternalInputBox, setShowExternalInputBox] = useState(false);
    const [continuedAccess, setContinuedAccess] = useState(true);
    const [tainted, setTainted] = useState(true);
    // const [externalCompany, setExternalCompany] = useState(""); //For companies outside of the system
    const [selectedExternalCompany, setSelectedExternalCompany] = useState(null); //For companies outside of the system
    const [message, setMessage] = useState(null);
    const [firstInventoryId, setFirstInventoryId] = useState(null);

    useEffect(initializeItems, [props.wholesaleItems, props.inventoryId])

    useEffect(() => {
      api.fetch("Reference/CaptiveRelatedCompanyList").then(r => {
        if (r.data && r.data.success) {
          let list = ui_helpers.idNameToValueLabel(r.data.data);
          list.unshift({ value: 0, label: constants.OTHER });
          setCompanyList(list);
        }
      });
      api.fetch("Reference/ExternalCompanyList").then(r => {
        if (r.data && r.data.success) {
          let list = ui_helpers.idNameToValueLabel(r.data.data);
          setExternalCompanyList(list);
        }
      });
      api.post("Reference/ManufacturerWholesaleDiscountPercent", {}).then(res => {
        if (res && res.data.success) {
          const discountPct = res.data.message;
          setDiscount(discountPct);
          initializeItems(discountPct);
        }
      })
    }, [])

    function resolveItemPrice(w) {
      return w.contractSalePrice
        ? w.contractSalePrice
        : w.retailPrice;
    }

    function totalItemPrice() {
      if (!props.wholesaleItems || props.wholesaleItems.length === 0) return parseFloat(props.retailPrice);
      return _.sumBy(props.wholesaleItems, resolveItemPrice);
    }

    function initializeItems(discountPct = null) {
      if (props.inventoryId) {
        setFirstInventoryId(props.inventoryId);
      } else if (props.wholesaleItems && props.wholesaleItems.length) {
        setFirstInventoryId(props.wholesaleItems[0].id);
      } else {
        return;
      }
      // if (!props.wholesaleItems || !props.wholesaleItems.length) return;
      if (discountPct) {
        calculateDiscountPrice(discountPct);
      } else {
        setDiscountPrice(
          totalItemPrice());
      }
    }

    function getLastCompanySaleType(selectedOption, isCompany){
      if(!selectedOption && !firstInventoryId) return;
      if(selectedOption.value === 0) return
      let payload = {
        companyId: isCompany 
          ? parseInt(selectedOption.value, 10)
          : null,
        externalCompanyId: !isCompany 
          ? parseInt(selectedOption.value, 10)
          : null,
        unitInventoryId: firstInventoryId
      }
      api.post("reference/GetLastCompanySaleType", payload)
      .then(res => {
        if(res.data && res.data.success){
          setContinuedAccess(res.data.message === constants.SALE_TERM_IDS.IMMEDIATE_WITH_ACCESS);
        }
      })
    }
    
    function resetState() {
      setCompany(null);
      setDiscount(null);
      // setSaleTerm(null);
      setShowExternalInputBox(false);
      //setExternalCompany('');
      setMessage('');
    }

    function validate() {
        if (company === null && selectedExternalCompany === null) {
            setMessage({ message: "Please select a company.", flavor: "danger" });
            return false;
        }
        // if (!saleTerm || saleTerm === null) {
        //     setMessage({ message: "Please select a sale term.", flavor: "danger" });
        //     return false;
        // }
        return true;
    }

    function onSave() {
        if (!validate()) return;
        let payload = {
          UnitInventoryId: props.inventoryId,
          CompanyId: company && company.label !== constants.OTHER ? company.value : null,
          // only immediate or immediate with retained are available through the UI
          SaleTermsId: continuedAccess
            ? constants.SALE_TERM_IDS.IMMEDIATE_WITH_ACCESS
            : constants.SALE_TERM_IDS.IMMEDIATE,
          ExternalCompanyName: selectedExternalCompany ? selectedExternalCompany.label : null,
          Discount: discount && parseFloat(discount)
            ? (parseFloat(discount) / 100.0)
            : null
        };
        api.post("Inventory/SaveInventorySale", payload).then(r => {
          if (r.data && r.data.success) {
            Alert.success("Sale request has been saved.");
            props.callback();
            props.toggle(); 
            resetState();
          } else {
            setMessage({ message: r.data.message || "There was an error creating the sale request.", flavor: "danger" });
          }
        })
        .catch(err => {
          setMessage({message: "An error occurred. Sale request cannot be saved at this time.", flavor: "danger" });
        })
    }

    function onSaveMulti() {
        if (!validate()) return;
        const payload = {
          UnitInventoryIdList: props.wholesaleItems && props.wholesaleItems.length
            ? _.map(props.wholesaleItems, x => x.id)
            : [props.inventoryId],
          CompanyId: company && company.label !== constants.OTHER ? company.value : null,
          // only immediate or immediate with retained are available through the UI
          SaleTermsId: continuedAccess
            ? constants.SALE_TERM_IDS.IMMEDIATE_WITH_ACCESS
            : constants.SALE_TERM_IDS.IMMEDIATE,
          ExternalCompanyName: selectedExternalCompany ? selectedExternalCompany.label : null,
          Discount: discount && parseFloat(discount)
            ? (parseFloat(discount) / 100.0)
            : null
        };
        api.post('Inventory/BulkSellInventory', payload).then(r => {
          if (r.data.success) {
            Alert.success("Sale request has been saved.");
            resetState();
            props.toggle();
            props.callback();
          } else {
            setMessage({ message: r.data.message, flavor: 'danger' });
          }
        });
    }

    function calculateDiscountPercent(discountPrice) {
      if (props.wholesaleItems || props.multi || !tainted) return;
      const p = discountPrice && parseFloat(discountPrice) 
        ? parseFloat(discountPrice)
        : null;
      setDiscount(p === null
        ? '0'
        : (((props.retailPrice - discountPrice) / props.retailPrice) * 100.0).toFixed(6)
      );
      setTainted(false);
    }

    function calculateDiscountPrice(discountPct) {
      if (!tainted) return;
      const d = discountPct && parseFloat(discountPct) 
        ? (parseFloat(discountPct) / 100.0)
        : null;
      let newPrice = props.retailPrice;
      if (props.wholesaleItems) {
        newPrice = totalItemPrice();
        if (d !== null) {
          let priceTotal = 0.0;
          _.each(props.wholesaleItems, p => { 
            const prc = resolveItemPrice(p);
            priceTotal = priceTotal + (prc - (prc * d));
          });
          newPrice = priceTotal;
        }
      } else {
        if (d !== null) {
          newPrice = parseFloat(newPrice - (newPrice * d));
        }
      }
      setTainted(false);
      setDiscountPrice(newPrice.toFixed(2));
    }

    return (
        <div>
            <Modal isOpen fullscreen="md" size="lg" toggle={() => props.toggle(false)}>
                <ModalHeader toggle={() => props.toggle(false)}>Propose Sale</ModalHeader>
                <ModalBody>
                    {message && <UncontrolledAlert color={message.flavor} >{message.message}</UncontrolledAlert>}
                    {!firstInventoryId && (!props.wholesaleItems || !props.wholesaleItems.length)
                      ? <UncontrolledAlert color='danger'>None of the selected items can be sold.</UncontrolledAlert>
                      : null
                    }
                    {props.wholesaleItems
                      ? (<Row>
                          <Col xs="3">Sale Item(s)</Col>
                          <Col>
                            <Table>
                              <thead>
                                <tr>
                                  <th>Description</th>
                                  <th className="text-end">Price</th>
                                </tr>
                              </thead>
                              <tbody>
                                {_.map(props.wholesaleItems, w => (
                                  <tr key={w.serialNumber}>
                                    <td>{w.serialNumber}
                                      <br/><span style={{fontStyle: 'italic'}}>{`${w.resolvedWidthFeet}' W x ${w.resolvedLengthFeet}' L`}</span>
                                    </td>
                                    <td className="text-end">{ui_helpers.formatCurrency(resolveItemPrice(w))}</td>
                                  </tr>
                                ))}
                              </tbody>
                              <tfoot>
                                <tr>
                                  <td colSpan="2" className="text-end fw-bold">
                                    {ui_helpers.formatCurrency(totalItemPrice())}
                                  </td>
                                </tr>
                              </tfoot>
                            </Table>
                          </Col>
                        </Row>)
                      : null
                    }
                    <Row>
                      <Col xs="3" className="p-2">Company</Col>
                      <Col>
                          <Select
                              options={companyList}
                              value={company}
                              onChange={(e) =>
                                  {
                                      if (e.value === 0) {
                                          setShowExternalInputBox(true);
                                      } else {
                                          setShowExternalInputBox(false);
                                          //setExternalCompany(null);
                                      }
                                      setCompany(e);
                                      getLastCompanySaleType(e, true);
                                  }
                              }
                          />
                          {showExternalInputBox &&
                              <Row className="mt-2">
                                <Col>
                                    <CreatableSelect
                                        isClearable
                                        value={selectedExternalCompany}
                                        options={externalCompanyList}
                                        onChange={(e) => {
                                            setSelectedExternalCompany(e);
                                            getLastCompanySaleType(e, false);
                                        }}
                                        onCreateOption={(e) => {
                                            if (e !== "") {
                                              setSelectedExternalCompany({ label: e, value: e });
                                            }
                                        }}
                                        placeholder="Type to search or create"
                                    />
                                </Col>
                              </Row>
                          }
                      </Col>
                    </Row>
                    <Row>
                      <Col xs="3" className="p-2">Discount</Col>
                      <Col>
                        <InputGroup>
                          <Input
                            type="text"
                            value={discount || '0'}
                            style={{textAlign: 'right'}}
                            onChange={(e) => {
                              setDiscount(e.target.value);
                              setTainted(true);
                            }}
                            onBlur={() => calculateDiscountPrice(discount)}
                          />
                          <InputGroupText>%</InputGroupText>
                        </InputGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs="3" className="p-2">Discounted Price</Col>
                      <Col>
                        <Input
                          type="text"
                          className="fw-bold text-end"
                          disabled={props.multi}
                          // value={discountPrice ? ui_helpers.formatCurrency(discountPrice) : ui_helpers.formatCurrency(totalItemPrice())} 
                          value={discountPrice ? discountPrice : totalItemPrice()} 
                          onChange={(e) => {
                            setDiscountPrice(e.target.value);
                            setTainted(true);
                          }}
                          onBlur={() => calculateDiscountPercent(discountPrice)}
                        />
                      </Col>
                    </Row>
                    <Row className="p-2">
                      <Col xs="12">
                        <Input
                          type="checkbox"
                          checked={continuedAccess}
                          onChange={(e) => setContinuedAccess(e.target.checked)}
                        /> Enable continued management of this item after the sale.
                      </Col>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <ButtonGroup>
                        <Button color="secondary" className="text-light" onClick={() => props.toggle(false) }>Cancel</Button>
                        <Button color="primary" className="text-light" 
                          disabled={!firstInventoryId}
                          onClick={() => { 
                            if (props.multi) { 
                              onSaveMulti() 
                            } else { 
                              onSave() 
                            }
                          }}>Save</Button>
                    </ButtonGroup>
                </ModalFooter>
            </Modal>
        </div>
    )
}