import React, {useContext, useState} from "react";
import { Link } from "react-router-dom";
import Select from "react-select";
import { Container, 
    TabContent, 
    Table, 
    TabPane, 
    Nav, 
    NavItem, 
    NavLink, 
    Card, 
    Button, 
    CardTitle, 
    CardText, 
    Row, 
    Col, 
    Form, 
    FormGroup, 
    Label,
    ButtonGroup, 
    Accordion,
    Collapse,
    CardBody,
    Input } from 'reactstrap';
import { Header } from ".";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classnames from 'classnames';
import {constants, UserContext} from "../utils";







export default function BuildAddOnManagement() {

    const [showAddNew, setShowAddNew] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [addOnDescription, setAddOnDescription] = useState("Single Wood Door");
    const [addOnWidth, setAddOnWidth] = useState('50"');
    const [addOnPrice, setAddOnPrice] = useState("$100.00");
    const [addOnBundleName, setAddOnBundleName] = useState("Premium");

    return (
        <Container>
            <Header />
                <Row>
                    <Col className="d-flex justify-content-center my-3">
                        <h3>AddOn Management</h3>
                    </Col>
                </Row>
                <Row>
                    <Col className="p-0">
                        <Button className="btn-success" onClick={() => setShowAddNew(true)}>
                            New AddOn{' '}
                            <FontAwesomeIcon icon="plus" />
                        </Button>
                    </Col>
                </Row>
                {showAddNew ? (
                <Row className="my-3">
                    <Row className="my-1">
                        <Col xs="2">
                            <Label for="addOnDescription">Description</Label>
                        </Col>
                        <Col>
                            <Input type="text" name="addOnDescription" ></Input>
                        </Col>
                    </Row>
                    <Row className="my-1">
                        <Col xs="2">
                            <Label for="addOnPrice">Width</Label>
                        </Col>
                        <Col>
                            <Input type="text" name="addOnPrice" ></Input>
                        </Col>
                    </Row>
                    <Row className="my-1">
                        <Col xs="2">
                            <Label for="addOnPrice">Price</Label>
                        </Col>
                        <Col>
                            <Input type="text" name="addOnPrice" ></Input>
                        </Col>
                    </Row>
                    <Row className="my-1">
                        <Col xs="2">
                            <Label for="addOnPrice">Bundle Name?</Label>
                        </Col>
                        <Col>
                            <Input type="text" name="addOnPrice" ></Input>
                        </Col>
                    </Row>
                    <Row className="my-3">
                        <Col className="p-0 d-flex justify-content-end">
                            <Button className="btn-success" onClick={() => setShowAddNew(false)}>
                                Save
                            </Button>
                        </Col>
                    </Row>
                </Row>
                ) : null}
                <Row className="my-3">
                    <Col>
                        <Row className="">
                            <h5 className="addon-headers p-0">AddOns</h5>
                        </Row>
                        <Row>
                            <Table borderless>
                                <thead>
                                    <tr>                                                                           
                                        <th style={{width: "50%"}}>Description</th>
                                        <th style={{width: "10%"}}>Width</th>
                                        <th style={{width: "10%"}}>Price</th>
                                        <th style={{width: "10%"}}>Bundle?</th>
                                        <th style={{width: "20%"}}></th>
                                    </tr>
                                </thead>
                                <tbody>              
                                    {!isOpen ? (                                                             
                                    <tr>                                        
                                        <td>{addOnDescription}</td>
                                        <td>{addOnWidth}</td>
                                        <td>{addOnPrice}</td>
                                        <td>{addOnBundleName}</td>
                                        <td>
                                            <ButtonGroup>
                                                <Button color="success" onClick={() => setIsOpen(!isOpen)}>
                                                    <FontAwesomeIcon icon="edit" />
                                                </Button>
                                                <Button color="danger">
                                                    <FontAwesomeIcon icon="times-circle" />
                                                </Button>
                                            </ButtonGroup>
                                        </td>                                              
                                    </tr>
                                    ) : 
                                    (
                                        <tr>
                                            <td>
                                                <Input type="text" value={addOnDescription}></Input>
                                            </td>
                                            <td>
                                                <Input type="text" value={addOnWidth}></Input>
                                            </td>
                                            <td>
                                                <Input type="text" value={addOnPrice}></Input>
                                            </td>
                                            <td>
                                                <Input type="text" value={addOnBundleName}></Input>
                                            </td>
                                            <td>
                                                <Button color="success" onClick={() => {setIsOpen(!isOpen)}}>
                                                    Save
                                                </Button>
                                            </td>
                                        </tr>
                                    )}
                                    {/* <tr>
                                        <td>
                                            <Collapse isOpen={isOpen}>                                                
                                                <Input type="text">
                                                </Input>                                                   
                                            </Collapse>
                                        </td>
                                        <td>
                                            <Collapse isOpen={isOpen}>                                               
                                                <Input type="text">
                                                </Input>                                                   
                                            </Collapse>
                                        </td>
                                        <td>
                                            <Collapse isOpen={isOpen}>                                               
                                                <Input type="text">
                                                </Input>                                                  
                                            </Collapse>
                                        </td>
                                        <td>
                                            <Collapse isOpen={isOpen}>                                               
                                                <Input type="text">
                                                </Input>                                                  
                                            </Collapse>
                                        </td>
                                        <td>
                                            <Collapse isOpen={isOpen}>
                                                <Button color="success"  onClick={() => setIsOpen(false)}>
                                                    Save
                                                </Button>
                                            </Collapse>
                                        </td>
                                    </tr>                                                                                             */}
                                    <tr>
                                        <td>24" x 36" Window</td>
                                        <td>24"</td>
                                        <td>$60.00</td>
                                        <td>N/A</td>      
                                        <td>
                                            <ButtonGroup>
                                                <Button color="success">
                                                    <FontAwesomeIcon icon="edit" />
                                                </Button>
                                                <Button color="danger">
                                                    <FontAwesomeIcon icon="times-circle" />
                                                </Button>
                                            </ButtonGroup>
                                        </td>                                       
                                    </tr>
                                    <tr>
                                        <td>Motion Light</td>
                                        <td>12"</td>
                                        <td>$175.00</td>   
                                        <td>Basic</td>    
                                        <td>
                                            <ButtonGroup>
                                                <Button color="success">
                                                    <FontAwesomeIcon icon="edit" />
                                                </Button>
                                                <Button color="danger">
                                                    <FontAwesomeIcon icon="times-circle" />
                                                </Button>
                                            </ButtonGroup>
                                        </td>                                      
                                    </tr>
                                    <tr>
                                        <td>100 AMP Breaker Box</td>
                                        <td>12"</td>
                                        <td>$260.00</td>   
                                        <td>Electrical</td>    
                                        <td>
                                            <ButtonGroup>
                                                <Button color="success">
                                                    <FontAwesomeIcon icon="edit" />
                                                </Button>
                                                <Button color="danger">
                                                    <FontAwesomeIcon icon="times-circle" />
                                                </Button>
                                            </ButtonGroup>
                                        </td>                                      
                                    </tr>
                                    <tr>
                                        <td>Spray Foam Floor(Board ft)</td>
                                        <td>N/A</td>
                                        <td>$2.10</td>   
                                        <td>Premium</td>    
                                        <td>
                                            <ButtonGroup>
                                                <Button color="success">
                                                    <FontAwesomeIcon icon="edit" />
                                                </Button>
                                                <Button color="danger">
                                                    <FontAwesomeIcon icon="times-circle" />
                                                </Button>
                                            </ButtonGroup>
                                        </td>                                      
                                    </tr>
                                </tbody>
                            </Table>
                        </Row>
                    </Col>
                </Row>
                {/* <Row className="my-3">
                    <Col>
                        <Row>
                            <h5 className="addon-headers p-0">Windows</h5>
                        </Row>
                        <Row>
                            <Table borderless>
                                <thead>
                                    <tr>                                                                           
                                        <th>Description</th>
                                        <th>Price</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>24" x 36" Window</td>
                                        <td>$60.00</td>      
                                        <td>
                                            <Button color="success">
                                                <FontAwesomeIcon icon="edit" />
                                            </Button>
                                            <Button color="danger">
                                                <FontAwesomeIcon icon="times-circle" />
                                            </Button>
                                        </td>                                       
                                    </tr>
                                </tbody>
                            </Table>
                        </Row>
                    </Col>
                </Row>
                <Row className="my-3">
                    <Col>
                        <Row>
                            <h5 className="addon-headers p-0">Miscellaneous</h5>
                        </Row>
                        <Row>
                            <Table borderless>
                                <thead>
                                    <tr>                                                                           
                                        <th style={{width: "50%"}}>Description</th>
                                        <th>Price</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Motion Light</td>
                                        <td>$175.00</td>       
                                        <td>
                                            <Button color="success">
                                                <FontAwesomeIcon icon="edit" />
                                            </Button>
                                            <Button color="danger">
                                                <FontAwesomeIcon icon="times-circle" />
                                            </Button>
                                        </td>                                      
                                    </tr>
                                </tbody>
                            </Table>
                        </Row>
                    </Col>
                </Row> */}
        </Container>
    );
}