import React, {Fragment, useState, useEffect, useContext} from 'react';
import {EditStore, Header, SubNavigationDropdown, UserAddEdit} from './';
import {api, constants, date_helpers, ui_helpers} from "../helpers";
import {UserContext} from "../utils";
import {useConsole} from '../hooks';
import Alert from "react-s-alert-v3";
import _ from "lodash";
import { Loading } from "./";
import {
    Button,
    ButtonGroup,
    Card, CardBody,
    CardHeader,
    Col,
    Container, DropdownItem, Label,
    Nav,
    NavItem,
    NavLink,
    Row,
    TabContent, Table,
    TabPane
} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import classnames from "classnames";
import Select from "react-select";
import ToggleButton from "react-toggle-button";
import {useParams} from "react-router-dom";

const tabNames = {
    Details: "1",
    Companies: "2",
    Manufacturers: "3",
    Users: "4"
    // Reports: "5"
};

const DataRow = props => {
  return (
    <Row className="rowSpacing">
      <Col xs="4">
        <strong>{props.header}</strong>
      </Col>
      <Col xs="8">{props.data}</Col>
    </Row>);
};

export default function StoreAdmin(props) {
  const {id} = useParams();
  const [activeTab, setActiveTab] = useState(tabNames.Details);
  const [routeStoreId, setRouteStoreId] = useState(id);
  const [store, setStore] = useState(constants.EMPTY_STORE);
  const [isEditingStore, setIsEditingStore] = useState();
  const [newUser, setNewUser] = useState(false);
  const [users, setUsers] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [states, setStates] = useState();
  const [storeCompanies, setStoreCompanies] = useState([]);
  const [companyToSave, setCompanyToSave] = useState({});
  const [manufacturers, setManufacturers] = useState([]);
  const [storeManufacturers, setStoreManufacturers] = useState([]);
  const [manufacturerToSave, setManufacturerToSave] = useState({});
  const [reportGroups, setReportGroups] = useState([]);
  const [unshapedProductList, setUnshapedProductList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isRTOAdmin, setIsRTOAdmin] = useState(false);
  const [isSysAdmin, setIsSysAdmin] = useState(false);
  const [isAnyCompanyAdmin, setIsAnyCompanyAdmin] = useState(false);
  const [isDealerAdmin, setIsDealerAdmin] = useState(false);
  const [activeStoreCompaniesOnly, setActiveStoreCompaniesOnly] = useState(true);
  const [loadingStoreCompanies, setLoadingStoreCompanies] = useState(false);
  const [editingStoreRegions, setEditingStoreRegions] = useState(null);
  const [companyDealerPriorities, setCompanyDealerPriorities] = useState([]);
  const [companyRegions, setCompanyRegions] = useState([]);
  const [selectedStates, setSelectedStates] = useState([]);
  const [priorities, setPriorities] = useState([]);

  const userCtx = useContext(UserContext);

  useEffect(() => {        
    getStoreDetails();
    getCompaniesList();
    getRegions();
    getStoreCompanies();
    getManufacturers();
    getStoreUsers();
    getManufacturerDealerLots();
    getCompanyDealerRegionPriorities();
  }, []);

  useEffect(() => {
    if (!store) return;
    getPermissions();
  }, [store])

    function toggle(tab) {
      if (activeTab !== tab) {
        setActiveTab(tab);
      }
    }

const shapeCompanyRegions = (list) => {
  if (list && list.length && states && states.length) {
    let newCompanyRegions = [];
    const groupedByRegion = _.groupBy(list, (e) => e.regionId);
    for (const regionId in groupedByRegion) {
      const regionKey = parseInt(regionId, 10);
      const region = _.find(states, (s) => s.id === regionKey);
      newCompanyRegions.push({
        regionId: regionKey,
        regionName: region.label,
        companyList: groupedByRegion[regionId]
      });
    }
    return {
      companyRegionList: _.sortBy(newCompanyRegions, r => r.regionName),
      selectedStateList: _.map(states, s => ({
        ...s, 
        selected: _.some(newCompanyRegions, cr => cr.regionId === s.id)
      }))
    };
  } else {
    return [];
  }
};

  const getCompanyRegions = (companyId) => {
    return api
      .fetch(`company/CompanyRegionsList/${companyId}`)
      .then((r) => {
        const {companyRegionList, selectedStateList} = shapeCompanyRegions(r.data.data)
        setCompanyRegions(companyRegionList);
        setSelectedStates(selectedStateList);
      })
      .catch((error) =>
        Alert.error("There was an error loading the company state list")
      );
  };

    function getPermissions() {
      const rtoAdmin = ui_helpers.isAnyRTOCompanyAdmin(userCtx.currentUser);
      setIsRTOAdmin(rtoAdmin);
      setIsSysAdmin(
        ui_helpers.isSystemAdministrator(userCtx.currentUser));
      setIsDealerAdmin(
        ui_helpers.isDealerAdministrator(userCtx.currentUser, routeStoreId));
      setIsAnyCompanyAdmin(
        rtoAdmin || ui_helpers.isAnyCompanyAdministrator(userCtx.currentUser));
    }

    //Store Details Screen
    function getStoreDetails() {
        if(loading) return;
        setLoading(true);
        api
          .fetch(`store/GetStoreDetails/${routeStoreId}`)
          .then(response => {
              setStore(ui_helpers.shapeStore(response.data.data, companies));
          })
          .catch(error => Alert.error("There was an error loading the store details."))
          .finally(() => setLoading(false));
    }

    function toggleStoreEdit() {
        // this.setState({
        //     isEditingStore: !this.state.isEditingStore
        // });
        setIsEditingStore(true);
    }

    function saveStoreDetails(store, cashSaleHeaderImage) {
        let warnings = [];
        if (store.name === "") {
            warnings.push("Provide a name for this new store in order to save.");
        }
        if (store.abbr === "") {
            warnings.push("Provide an abbreviation for this new store in order to save.");
        }
        if (store.isOwner && store.enableCashSales && !store.company?.cashSaleHeaderImageExisted && !cashSaleHeaderImage) {
            warnings.push("Either provide a cash sale header image or choose to disable cash sales.");
        }
        if (!store.id && store.isOwner && (!store.company?.states || store.company?.states.length === 0)) {
            warnings.push("Select at least 1 state in which this store does business.");
        }
        if (warnings.length) {
            Alert.warning(warnings.join(' '));
            return null;
        }
        if (warnings.length > 0) return;
        let vm = Object.assign({}, store);
        if (vm.isOwner)
        {
            vm.company = Object.assign({}, vm.company);
            vm = ui_helpers.prepareStoreCompanyPayload(vm);
        }
        api.post("store/SaveDealerDetails", vm).then(response => {
            if (response.data.success) {
                let newStore = ui_helpers.shapeStore(response.data.data, companies)
                if (vm.isOwner && cashSaleHeaderImage) {
                    api.post_form_data('Company/SaveHeader',
                        ui_helpers.buildCaptiveHeaderPayload(newStore.companyId, cashSaleHeaderImage))
                        .then(r => {
                            if (!r.data.success) {
                                Alert.warning("There was an error saving the cash sale header image")
                            }
                            //   this.setState({ store: newStore, isEditingStore: false });
                            setStore(newStore);
                            setIsEditingStore(false);
                        }).catch(error => console.error(error));
                }
                //   this.setState({ store: newStore, isEditingStore: false });
                setStore(newStore);
                setIsEditingStore(false);
            }
            else
            {
                Alert.error(response.data.message);
            }
        }).catch(error => {
            console.error(error);
            Alert.error("There was an error saving the store details.");
        });
    }

    //Store Companies Screen
    function getCompaniesList() {
      api.fetch("company/Companies").then(response => {
        setCompanies(ui_helpers.idNameToValueLabel(response.data.data));
      }).catch(error => Alert.error("There was an error retrieving companies."));
    }

    function getStoreCompanies() {
      setLoadingStoreCompanies(true);
      api.fetch(`store/DealerCompanyList/${routeStoreId}`).then(response => {
        setStoreCompanies(response.data.data);
      })
      .catch(error => Alert.error("There was an error retrieving the store companies."))
      .finally(() => setLoadingStoreCompanies(false));
    }

    const getRegions = () => {
        return api.fetch('reference/RegionList')
            .then(r => { 
                setStates(ui_helpers.idNameToValueLabel(r.data.data))
            })
            .catch(error => Alert.error("There was an error loading the state list"));
    };

    function getSelectedCompany(company) {
        const selected_option = _.find(companies, x => x.label === company.label);
        if (selected_option) {
            return [selected_option];
        }
        return null;
    }

    function setSelectedCompany(option) {
        setCompanyToSave({
            value: option.value,
            label: option.label
        });
    }

    function saveCompanyToStore() {
        const companyDealer = {
            CompanyId: companyToSave.value,
            DealerId: _.toInteger(routeStoreId)
        };
        api
            .post("company/AddCompanyDealer", companyDealer)
            .then(response => {
                setStoreCompanies([...storeCompanies, response.data.data]);
                setCompanyToSave({});
            })
            .catch(error => Alert.error("There was an error adding the company."));
    }

    function activateCompanyDealer(company) {
        const companies = storeCompanies.slice();
        const foundCompany = _.find(companies, x => x.id === company.id);
        const CompanyDealerDto = {
            Id: company.id,
            CompanyId: company.companyId,
            DealerId: company.dealerId
        }
        api
            .post("company/ActivateCompanyDealer", CompanyDealerDto)
            .then(response => {
                const activated = response.data.data;
                foundCompany.deactivatedAt = activated.deactivatedAt;
                foundCompany.deactivatedByUserId = activated.deactivatedByUserId;
                setStoreCompanies(companies);
            })
            .catch(error => Alert.error("There was an error activating the company store"));
    }

    function deactivateCompanyDealer(company) {
        const companies = storeCompanies.slice();
        const foundCompany = _.find(companies, x => x.id === company.id);
        api.post("company/DeactivateCompanyDealer", {
          Id: company.id,
          CompanyId: company.companyId,
          DealerId: company.dealerId
        }).then(response => {
          const deactivated = response.data.data;
          foundCompany.deactivatedAt = deactivated.deactivatedAt;
          foundCompany.deactivatedByUserId = deactivated.deactivatedByUserId;
          setStoreCompanies(companies);
        })
        .catch(error => Alert.error("There was an error deactivating the company store"));
    }

    //Manufacturer Products Screen
    function getManufacturers() {
        api
            .fetch("company/ManufacturerList")
            .then(response => {
                // this.setState({
                //     manufacturers: ui_helpers.idNameToValueLabel(response.data.data)
                // });
                setManufacturers(ui_helpers.idNameToValueLabel(response.data.data));
            })
            .catch(error => Alert.error("There was an error retrieving manufacturers."));
    }

    function getManufacturerDealerLots() {
        api
            .fetch(`store/GetManufacturerDealerLots/${routeStoreId}`)
            .then(response => {
                // this.setState({
                //     unShapedProductList: response.data.data,
                //     storeManufacturers: this.shapeManufactureProductsList(response.data.data)
                // });
                setUnshapedProductList(response.data.data);
                setStoreManufacturers(shapeManufactureProductsList(response.data.data));
            })
            .catch(error => Alert.error("There was an error retrieving the manufacturer products list."));
    }

    function shapeManufactureProductsList(productsList) {
        const manufacturerNames = [];
        _.forEach(productsList,
            product => {
                manufacturerNames.push(product.manufacturerName);
            });
        const uniqueManufacturerNames = _.uniq(manufacturerNames);
        const manufacturerProductGroups = [];
        _.map(uniqueManufacturerNames,
            name => {
                const found = _.filter(productsList, x => x.manufacturerName === name);
                const products = shapeProductsRows(found);
                const deactivatedAtDate = findDeactivatedAtDate(name, found);
                const manufacturerId = findManufacturerId(name, found);
                const newGroup = {
                    manufacturerName: name,
                    manufacturerId: manufacturerId,
                    manufacturerDealerDeactivatedAt: deactivatedAtDate,
                    products: products
                };
                manufacturerProductGroups.push(newGroup);
            });
        return _.orderBy(manufacturerProductGroups, "manufacturerName");
    }

    function shapeProductsRows(products) {
        const productRow = [];
        _.forEach(products,
            product => {
                if (product.associated) {
                    productRow.push(product.productTypeName);
                }
            });
        return productRow;
    }

    function findDeactivatedAtDate(manufacturerName, products) {
        const deactivatedAt = _.find(products, x => x.manufacturerName === manufacturerName);
        return deactivatedAt.manufacturerDealerDeactivatedAt;
    }

    function findManufacturerId(manufacturerName, products) {
        const manufacturer = _.find(products, x => x.manufacturerName === manufacturerName);
        return manufacturer.manufacturerId;
    }

    function getSelectedManufacturer(manufacturer) {
        const selected_option = _.find(manufacturers, x => x.label === manufacturer.label);
        if (selected_option) {
            return [selected_option];
        }
        return null;
    }

    function setSelectedManufacturer(option) {
        // this.setState(prevState => ({
        //     ...prevState,
        //     manufacturerToSave: {
        //         value: option.value,
        //         label: option.label
        //     }
        // }));
        setManufacturerToSave({
            value: option.value,
            label: option.label
        });
    }

    function getProductTypeToggleValue(products, fieldName) {
      const item = _.find(products, x => x === fieldName);
      if (item) {
        return true;
      }
      return false;
    }

    function saveStoreManufacturer() {
        const manufacturer = {
            ManufacturerId: manufacturerToSave.value,
            DealerId: _.toInteger(routeStoreId)
        };
        api
            .post("manufacturer/SaveManufacturerDealer", manufacturer)
            .then(response => {
                // this.setState({
                //     manufacturerToSave: {}
                // });
                setManufacturerToSave({});
                getManufacturerDealerLots();
            })
            .catch(error => Alert.error("There was an error adding the manufacturer."));
    }

    function activateStoreManufacturer(manDealer) {
        const manufacturerDealer = {
            Id: manDealer.Id,
            ManufacturerId: manDealer.manufacturerId,
            DealerId: _.toInteger(routeStoreId)
        };
        api
            .post(`manufacturer/ActivateManufacturerDealer`, manufacturerDealer)
            .then(response => {
                getManufacturerDealerLots();
            })
            .catch(error => Alert.error("There was an error activating the store."));
    }

    function deactivateStoreManufacturer(manDealer) {
        const manufacturerDealer = {
            Id: manDealer.Id,
            ManufacturerId: manDealer.manufacturerId,
            DealerId: _.toInteger(routeStoreId)
        };
        api
            .post(`manufacturer/DeactivateManufacturerDealer`, manufacturerDealer)
            .then(response => {
                getManufacturerDealerLots();
            })
            .catch(error => Alert.error("There was an error deactivating the store."));
    }

    function saveStoreManufacturerProducts(value, manufacturerProductGroup, productTypeName) {
        const editUnShapedProductList = unshapedProductList.slice();
        const product = _.find(editUnShapedProductList, x => x.productTypeName === productTypeName);
        const dealerProduct = {
          DealerId: _.toInteger(routeStoreId),
          ManufacturerId: manufacturerProductGroup.manufacturerId,
          ProductTypeId: product.productTypeId
        };
        if (value === true) {
            api
                .post("store/DeactivateDealerProduct", dealerProduct)
                .then(response => {
                    getManufacturerDealerLots();
                })
                .catch(error => Alert.error("There was an error activating the product."));
        } else {
            api
                .post("store/ActivateDealerProduct", dealerProduct)
                .then(response => {
                    getManufacturerDealerLots();
                })
                .catch(error => Alert.error("There was an error activating the product."));
        }
    }

    //Store Users Screen
    function getStoreUsers() {
        api
            .fetch(`store/GetStoreUsers/${routeStoreId}`)
            .then(response => {
                setUsers(response.data.data);
            })
            .catch(error => Alert.error("There was an error retrieving the users."));
    }

    function handleRoleChange(value, userId, role) {
        let userRoleId = _.find(constants.roles, x => x.label === role).value;
        if (value) {
            //remove role
            const userRoles = _.find(users, x => x.id === userId).roles;
            const roleId = _.find(userRoles, x => x.typeOfUserRole === userRoleId).id;
            const data = {
                "typeOfUserRole": userRoleId,
                "userId": userId,
                "dealerId": routeStoreId,
                "id": roleId
            };
            api.post("store/RemoveRole", data).then(r => {
                if (r.data.success) {
                    setUsers(r.data.data);
                    Alert.success("Roles have been adjusted.")
                } else {
                    Alert.warning("There was an error adjusting roles.")
                }
            });
        } else {
            const data = {
                "typeOfUserRole": userRoleId,
                "userId": userId,
                "dealerId": routeStoreId
            };
            //add role
            api.post("store/AddRole", data).then(r => {
                if (r.data.success) {
                    setUsers(r.data.data);
                    Alert.success("The new role was saved.")
                } else {
                    Alert.warning("There was an error saving the new role.")
                }
            });
        }
    }

  function loadCompanyDealerPriorities(companyId) {
    getCompanyRegions(companyId).then(x => {
      api.post("company/GetCompanyDealerPriorities", {dealerId: routeStoreId, companyId: companyId}).then((r) => {
        setCompanyDealerPriorities(r.data);
      });      
    });
  }

  function onToggleCompanyDealerRegion(companyId, regionId) {
    api.post("company/ToggleCompanyDealerRegion", {dealerId: routeStoreId, companyId: companyId, regionId: regionId}).then((r) => {
      setCompanyDealerPriorities(r.data);
    });
  }

  function onToggleDealerRTO(company) {
    const newValue = !company.isRTO;
    api.post("company/ToggleDealerRTO", { ...company, isRTO: newValue }).then((response) => {
        let list = storeCompanies.slice();
        const idx = _.findIndex(list, (x) => x.companyId === company.companyId);
        list[idx].isRTO = !list[idx].isRTO;
        setStoreCompanies(list);
        getCompanyDealerRegionPriorities();
      })
      .catch((error) => Alert.error("There was an error toggling company RTO sales."));
  }

  function onToggleDealerCashSales(company) {
    api.post("company/ToggleDealerCashSales", { ...company, isCashSales: !company.isCashSales }).then((response) => {
        let list = storeCompanies.slice();
        const idx = _.findIndex(list, (x) => x.companyId === company.companyId);
        list[idx].isCashSales = !list[idx].isCashSales;
        setStoreCompanies(list);
      })
      .catch((error) =>
        Alert.error("There was an error toggling store cash sales.")
      );
  }

function getCompanyDealerRegionPriorities() {
  api.post("company/GetDealerRTOCompanyRegions", {Id: routeStoreId}).then((r) => {
    setPriorities(r.data.message);
  });
}

function getConflictRegions() {
  let results = [];
  const groupedByRegion = _.groupBy(priorities, (e) => e.regionId);
  for (const regionId in groupedByRegion) {
    const regionIdInt = parseInt(regionId, 10);
    const region = _.find(states, (s) => s.id === regionIdInt);
    if (groupedByRegion[regionId].length > 1) {
      results.push({
        regionId: regionId,
        regionName: region.label,
        companies: _.map(groupedByRegion[regionId], p => ({id: p.companyId, name: p.companyName, isPrimary: p.priority === 1}))
      });
    }
  }
  return results;
}

function onSetCompanyDealerRegionPrimary(regionId, companyId) {
  api.post("company/SetCompanyDealerRegionPrimary", {dealerId: routeStoreId, companyId: companyId, regionId: regionId, prioritySeq: 1}).then((r) => {
    getCompanyDealerRegionPriorities();
  }).catch((error) => {
    console.error(error);
    Alert.error("There was an error setting RTO company priority.")
  });
}

const regionsInConflict = priorities.length
  ? getConflictRegions()
  : [];

return (<>
  <Header toolbar admin storeAdmin storeName={store.name} loading={loading}>
      <div className='d-flex justify-content-center'>
          <Button onClick={() => toggle(tabNames.Details)} active={activeTab === tabNames.Details}>
              Details
          </Button>
          {isSysAdmin || isRTOAdmin
            ? (<Fragment>
              <Button onClick={() => toggle(tabNames.Companies)} active={activeTab === tabNames.Companies}>
                Companies
              </Button>
              <Button onClick={() => toggle(tabNames.Manufacturers)} active={activeTab === tabNames.Manufacturers}>
                Manufacturers
              </Button>
              </Fragment>)
            : null
          }
          {isSysAdmin || isAnyCompanyAdmin
            ? (<Button onClick={() => toggle(tabNames.Users)} active={activeTab === tabNames.Users}>
                Users
              </Button>)
            : null
          }          
      </div>
  </Header>
  <Container fluid>
    <Card style={{ borderColor: "transparent" }}>
      <Row>
        <Col>
          <TabContent activeTab={activeTab} className="mt-2">
            <TabPane tabId={tabNames.Details}>
                <Row className="mb-0">
                    <Col sm="12">
                        <Card>
                            <CardHeader>
                                <Row>
                                    <Col>
                                        <h2 className="page-title">{store ? store.name : ""} Details</h2>
                                    </Col>
                                    <Col>
                                        {isEditingStore ? null : (
                                            <Button
                                                size="sm"
                                                className="float-end btn-dark"
                                                onClick={toggleStoreEdit}
                                            >
                                                <FontAwesomeIcon icon="edit" />
                                            </Button>
                                        )}
                                    </Col>
                                </Row>
                            </CardHeader>
                            <CardBody>
                                {isEditingStore ? (
                                    <EditStore
                                      store={store}
                                      states={states}
                                      isSysAdmin={isSysAdmin}
                                      isRTOAdmin={isSysAdmin || isRTOAdmin}
                                      companies={companies}
                                      onSave={(s, cashSaleHeaderImage) => saveStoreDetails(s, cashSaleHeaderImage)}
                                      onCancel={() => setIsEditingStore(false)}
                                    />
                                ) : (
                                    <Row>
                                        <Col>
                                            <DataRow header="Store Name" data={store.name} />
                                            <DataRow header="Abbreviation" data={store.abbr} />
                                            {isSysAdmin || isRTOAdmin
                                                ? (<Fragment>
                                                     <DataRow header="90 Days Same As Cash" data={store.sac ? ui_helpers.greenCheck() : ""}/>
                                                     <DataRow header="Can Set Retained Status" data={store.canSetRetainedStatus ? ui_helpers.greenCheck() : ""}/>
                                                   </Fragment>)
                                                : null
                                            }
                                            <DataRow header="Can Own" data={store.companyId ? ui_helpers.greenCheck() : ""} />
                                            <DataRow header="Use Tablet Signature Capture" data={store.isTabletSignatureCapture ? ui_helpers.greenCheck() : ""} />
                                            <DataRow header="API Key" data={store.apiKey ? ui_helpers.greenCheck() : ui_helpers.redX()} />
                                            <DataRow header="Website API Key" data={store.wcApiKey ? ui_helpers.greenCheck() : ui_helpers.redX()} />
                                            <DataRow header="Website API Secret" data={store.wcApiSecret ? ui_helpers.greenCheck() : ui_helpers.redX()} />
                                            <DataRow header="Website URL" data={store.wcUrl ? ui_helpers.greenCheck() : ui_helpers.redX()} />
                                            <DataRow header="Carefree Api Key" data={store.carefreeApiKey ? ui_helpers.greenCheck() : ui_helpers.redX()} />
                                            <DataRow header="Building Tag Text" data={store.buildingTagText ? ui_helpers.greenCheck() : ui_helpers.redX()} />
                                            <DataRow header="Created At" data={`${date_helpers.formatDateToShortDate(store.createdAt)} By ${store.createdBy ? store.createdBy.name : ""}`} />
                                            <DataRow header="Last Updated At" data={`${date_helpers.formatDateToShortDate(store.lastUpdatedAt)} By ${store.lastUpdatedBy ? store.lastUpdatedBy.name : ""}`} />
                                            <DataRow header="Deactivated At" data={store.deactivatedAt ? `${date_helpers.formatDateToShortDate(store.deactivatedAt)} by ${store.deactivatedBy ? store.deactivatedBy.name : ""}` : "Still Active"} />
                                            <DataRow header="Notify Creator Upon Contract Approval" data={store.notifyUponContractApproval ? ui_helpers.greenCheck() : ""} />
                                            <DataRow header="Track Commissions" data={store.isTrackingCommission ? ui_helpers.greenCheck() : ""} />
                                        </Col>
                                    </Row>
                                )}
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </TabPane>
            <TabPane tabId={tabNames.Companies}>
              <Row>
                <Col sm="12">
                  <Card>
                    <CardHeader>
                      <h2 className="page-title">{store ? store.name : ""} Companies</h2>
                    </CardHeader>
                    <CardBody>
                    {(isSysAdmin || isDealerAdmin)
                        ? (<Row>
                            <Col xs="3">
                              <Label>Company</Label>
                              <Select
                                options={_.reject(companies, cpy => _.some(storeCompanies, cs => cs.company.id === cpy.id))}
                                value={getSelectedCompany(companyToSave)}
                                onChange={(option) => setSelectedCompany(option)}
                              />
                            </Col>
                            <Col xs="1" className="pt-4">
                              <Button
                                size="sm"
                                className="float-right btn-success mt-1 text-light"
                                onClick={saveCompanyToStore}
                              >
                                <FontAwesomeIcon icon="plus" />
                              </Button>
                            </Col>
                            <Col xs="4" className="pt-1">
                              <div className="mt-4">
                                <input
                                  type="checkbox"
                                  style={{transform: "scale(1.4)"}}
                                  checked={activeStoreCompaniesOnly}
                                  onClick={() => setActiveStoreCompaniesOnly(!activeStoreCompaniesOnly)}
                                  className="m-2"
                                />
                                Active Only
                              </div>
                            </Col>
                          </Row>)
                        : null
                      }
                  {loadingStoreCompanies
                    ? (<Loading />)
                    : (<Row className="mt-3">
                    <Col xs="8">
                      <Table size="sm">
                        <thead>
                          <tr>
                            <th>Company Name</th>
                            <th>Cash Sales</th>
                            <th>RTO</th>
                            <th>RTO Regions</th>
                            <th />
                          </tr>
                        </thead>
                        <tbody>
                          {_.map(_.filter(storeCompanies, cs => !activeStoreCompaniesOnly || cs.deactivatedAt === null), (c) => (
                            <tr key={c.id} className={editingStoreRegions && editingStoreRegions.id === c.id ? 'bg-warning' : null}>
                              <td>{c.company.name}</td>
                              <td>
                                {c.deactivatedAt === null ? (
                                  <ToggleButton
                                    value={c.isCashSales}
                                    activeLabel={"Yes"}
                                    inactiveLabel={"No"}
                                    onToggle={() => onToggleDealerCashSales(c)}
                                  />
                                ) : null}
                              </td>
                              <td>
                                {c.deactivatedAt === null ? (
                                  <ToggleButton
                                    value={c.isRTO}
                                    activeLabel={"Yes"}
                                    inactiveLabel={"No"}
                                    onToggle={() => onToggleDealerRTO(c)}
                                  />
                                ) : null}
                              </td>
                              <td>
                                {c.isRTO
                                  ? (<span>{c.regionCSV}
                                      <Button
                                        title="Change region associations"
                                        color="dark"
                                        className="float-end text-light xs"
                                        onClick={() => {
                                          setEditingStoreRegions(c);
                                          loadCompanyDealerPriorities(c.company.id);
                                        }}
                                      >
                                        <FontAwesomeIcon icon='edit' />
                                      </Button>
                                    </span>)
                                  : null
                                }
                              </td>
                              <td>
                                {c.deactivatedAt === null ? (
                                  <Button
                                    size="sm"
                                    className="float-end btn-danger cf-danger text-light"
                                    onClick={() => deactivateCompanyDealer(c)}
                                  >
                                    <FontAwesomeIcon icon="trash-alt" />
                                  </Button>
                                ) : (
                                  <Button
                                    size="sm"
                                    className="float-end btn-success cf-success text-light"
                                    onClick={() => activateCompanyDealer(c)}
                                  >
                                    Revive
                                  </Button>
                                )}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </Col>
                    {editingStoreRegions
                      ? (<Col>
                          <Button
                            size="sm"
                            color="secondary"
                            className="float-end"
                            onClick={() => {
                              setEditingStoreRegions(null);
                              getStoreCompanies();
                              getCompanyDealerRegionPriorities();
                            }}
                          >
                            <FontAwesomeIcon icon="times-circle" />
                          </Button>
                          <h4 className="pt-2" style={{textDecoration: "underline"}}>{editingStoreRegions.company.name} Regions</h4>
                          {_.map(companyRegions, r => (
                            <Row className="mb-2" key={`rgRTO-${r.regionName}`}>
                              <Col xs={6}>
                                {r.regionName}
                              </Col>
                              <Col xs={3}>
                                <ToggleButton
                                  value={_.some(companyDealerPriorities, p => p.dealerId === editingStoreRegions.dealer.id && p.regionId === r.regionId)}
                                  activeLabel="Yes"
                                  inactiveLabel="No"
                                  onToggle={() => onToggleCompanyDealerRegion(editingStoreRegions.company.id, r.regionId)}
                                />
                              </Col>
                            </Row>))}
                        </Col>)
                      : null
                    }
                  </Row>)
                  }
                  {(isSysAdmin || isDealerAdmin) && regionsInConflict.length
                      ? (<Row>
                          <Col xs="12">
                            <h4 className="pt-2" style={{textDecoration: "underline"}}>{store ? store.name : ""} Regions with more than a single RTO Company</h4>
                            <Row>
                            {_.map(regionsInConflict, r => (
                            <Col className="region-panel" xs={3} key={`r-${r.regionName}`}>
                              <div className="title">
                                {r.regionName}
                              </div>
                              {_.map(r.companies, cpy => (
                                <Row className="region-company" key={`rgRTO-${r.regionName}-${cpy.name}`}>
                                  <Col xs={6}>
                                    {cpy.name}
                                  </Col>
                                  <Col xs={6} className="pe-4">
                                    {cpy.isPrimary
                                      ? (<div className="float-end text-success fw-bold"><FontAwesomeIcon icon="check" /> Primary</div>)
                                      : (<Button
                                          color="primary"
                                          className="float-end xs"
                                          style={{fontSize: "0.8rem"}}
                                          title={`Switch ${cpy.name} to be the RTO company to receive all RTO sales process in ${r.regionName}`}
                                          onClick={() => onSetCompanyDealerRegionPrimary(r.regionId, cpy.id)}
                                        >
                                          <FontAwesomeIcon icon="check" /> Set Primary
                                        </Button>)
                                    }
                                  </Col>
                                </Row>))}
                            </Col>))}
                            </Row>
                          </Col>
                        </Row>)
                      : null
                    }
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </TabPane>
            <TabPane tabId={tabNames.Manufacturers}>
                <Row>
                    <Col sm="12">
                        <Card>
                            <CardHeader>
                                <h2 className="page-title">{store ? store.name : ""} Manufacturer Products</h2>
                            </CardHeader>
                            <CardBody>
                                <Row>
                                {isSysAdmin
                                    ? (<Fragment>
                                            <Col xs="3">
                                                <Label>Manufacturer</Label>
                                                <Select
                                                    options={manufacturers}
                                                    value={getSelectedManufacturer(manufacturerToSave)}
                                                    onChange={(option) => setSelectedManufacturer(option)}
                                                />
                                            </Col>
                                            <Col xs="1">
                                                <br />
                                                <Button
                                                    size="sm"
                                                    className="float-end bg-success border-success cf-success"
                                                    onClick={() => saveStoreManufacturer()}
                                                >
                                                    <FontAwesomeIcon icon="plus" />
                                                </Button>
                                            </Col>
                                        </Fragment>)
                                    : null
                                }
                                    <Col xs="8">
                                        <Table size="sm">
                                            <thead>
                                            <tr>
                                                <th>Manufacturer</th>
                                                <th>Gazebo</th>
                                                <th>Carport</th>
                                                <th>Playhouse</th>
                                                <th>Container</th>
                                                <th>Storage Building</th>
                                                <th>Animal Shelter</th>
                                                <th/>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {storeManufacturers.map(m => 
                                              (<tr key={m.manufacturerName}>
                                                    <th>{m.manufacturerName}</th>
                                                    <td align="center">
                                                        <ToggleButton
                                                            value={getProductTypeToggleValue(m.products, "Gazebo")}
                                                            activeLabel={"Yes"}
                                                            inactiveLabel={"No"}
                                                            onToggle={(value) => saveStoreManufacturerProducts(value, m, "Gazebo")}
                                                        />
                                                    </td>
                                                    <td align="center">
                                                        <ToggleButton
                                                            value={getProductTypeToggleValue(m.products, "Carport")}
                                                            activeLabel={"Yes"}
                                                            inactiveLabel={"No"}
                                                            onToggle={(value) => saveStoreManufacturerProducts(value, m, "Carport")}
                                                        />
                                                    </td>
                                                    <td align="center">
                                                        <ToggleButton
                                                            value={getProductTypeToggleValue(m.products, "Playhouse")}
                                                            activeLabel={"Yes"}
                                                            inactiveLabel={"No"}
                                                            onToggle={(value) => saveStoreManufacturerProducts(value, m, "Playhouse")}
                                                        />
                                                    </td>
                                                    <td align="center">
                                                        <ToggleButton
                                                            value={getProductTypeToggleValue(m.products, "Container")}
                                                            activeLabel={"Yes"}
                                                            inactiveLabel={"No"}
                                                            onToggle={(value) => saveStoreManufacturerProducts(value, m, "Container")}
                                                        />
                                                    </td>
                                                    <td align="center">
                                                        <ToggleButton
                                                            value={getProductTypeToggleValue(m.products, "Storage Building")}
                                                            activeLabel={"Yes"}
                                                            inactiveLabel={"No"}
                                                            onToggle={(value) => saveStoreManufacturerProducts(value, m, "Storage Building")}
                                                        />
                                                    </td>
                                                    <td align="center">
                                                        <ToggleButton
                                                          value={getProductTypeToggleValue(m.products, "Animal Shelter")}
                                                          activeLabel={"Yes"}
                                                          inactiveLabel={"No"}
                                                          onToggle={(_value) => {
                                                            const val = m.products.includes("Animal Shelter");
                                                            saveStoreManufacturerProducts(val, m, "Animal Shelter");
                                                          }}
                                                        />
                                                    </td>
                                                    <td>
                                                        {m.manufacturerDealerDeactivatedAt === null ? (
                                                            <Button
                                                                size="sm"
                                                                className="float-end btn-delete cf-danger"
                                                                onClick={() => deactivateStoreManufacturer(m)}
                                                            >
                                                                <FontAwesomeIcon icon="trash-alt" />
                                                            </Button>
                                                        ) : (
                                                            <Button
                                                                size="sm"
                                                                className="float-end bg-success border-success cf-success"
                                                                onClick={() => activateStoreManufacturer(m)}
                                                            >
                                                                Revive
                                                            </Button>
                                                        )}
                                                    </td>
                                                </tr>))}
                                            </tbody>
                                        </Table>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </TabPane>
            <TabPane tabId={tabNames.Users}>
                <Row>
                    <Col sm="12">
                        <Card>
                            <CardHeader>
                                <Row>
                                    <Col>
                                        <h2 className="page-title">{store ? store.name : ""} Users</h2>
                                    </Col>
                                    <Col>
                                        <Button
                                            size="sm"
                                            className="float-end bg-cf-success border-success"
                                            onClick={() => setNewUser(!newUser)}
                                        >
                                            <FontAwesomeIcon icon={newUser ? "minus" : "plus"} />{' '}
                                            User
                                        </Button>
                                    </Col>
                                </Row>
                            </CardHeader>
                            <CardBody>
                                {!newUser && (
                                    <Table size="sm">
                                        <thead>
                                        <tr>
                                            <th>User Name</th>
                                            <th>Administrator</th>
                                            <th>Contract Creator</th>
                                            <th>Contract Viewer</th>
                                            <th>Driver</th>
                                            <th>Dispatcher</th>
                                            <th>Transport Viewer</th>
                                            <th>Inventory Viewer</th>
                                            <th>Report Viewer</th>
                                            <th />
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {users.length && users.map(u => 
                                          (<tr key={u.id}>
                                                <th>{u.firstName} {u.lastName} ({u.name})</th>
                                                <td align="center">
                                                    <ToggleButton
                                                        value={_.some(u.roles, ur => ur.typeOfUserRole === constants.ROLE_IDS.Administrator)}
                                                        activeLabel={"Yes"}
                                                        inactiveLabel={"No"}
                                                        onToggle={(v) => handleRoleChange(v, u.id, "Administrator")}
                                                    />
                                                </td>
                                                <td align="center">
                                                    <ToggleButton
                                                        value={_.some(u.roles, ur => ur.typeOfUserRole === constants.ROLE_IDS.ContractCreator)}
                                                        activeLabel={"Yes"}
                                                        inactiveLabel={"No"}
                                                        onToggle={(v) => handleRoleChange(v, u.id, "Contract Creator")}
                                                    />
                                                </td>
                                                <td align="center">
                                                    <ToggleButton
                                                        value={_.some(u.roles, ur => ur.typeOfUserRole === constants.ROLE_IDS.ContractViewer)}
                                                        activeLabel={"Yes"}
                                                        inactiveLabel={"No"}
                                                        onToggle={(v) => handleRoleChange(v, u.id, "Contract Viewer")}
                                                    />
                                                </td>
                                                <td align="center">
                                                    <ToggleButton
                                                        value={_.some(u.roles, ur => ur.typeOfUserRole === constants.ROLE_IDS.Driver)}
                                                        activeLabel={"Yes"}
                                                        inactiveLabel={"No"}
                                                        onToggle={(v) => handleRoleChange(v, u.id, "Driver")}
                                                    />
                                                </td>
                                                <td align="center">
                                                    <ToggleButton
                                                        value={_.some(u.roles, ur => ur.typeOfUserRole === constants.ROLE_IDS.Dispatcher)}
                                                        activeLabel={"Yes"}
                                                        inactiveLabel={"No"}
                                                        onToggle={(v) => handleRoleChange(v, u.id, "Dispatcher")}
                                                    />
                                                </td>
                                                <td align="center">
                                                    <ToggleButton
                                                        value={_.some(u.roles, ur => ur.typeOfUserRole === constants.ROLE_IDS.TransportViewer)}
                                                        activeLabel={"Yes"}
                                                        inactiveLabel={"No"}
                                                        onToggle={(v) => handleRoleChange(v, u.id, "Transport Viewer")}
                                                    />
                                                </td>
                                                <td align="center">
                                                    <ToggleButton
                                                        value={_.some(u.roles, ur => ur.typeOfUserRole === constants.ROLE_IDS.InventoryViewer)}
                                                        activeLabel={"Yes"}
                                                        inactiveLabel={"No"}
                                                        onToggle={(v) => handleRoleChange(v, u.id, "Inventory Viewer")}
                                                    />
                                                </td>
                                                <td align="center">
                                                    <ToggleButton
                                                        value={_.some(u.roles, ur => ur.typeOfUserRole === constants.ROLE_IDS.ReportViewer)}
                                                        activeLabel={"Yes"}
                                                        inactiveLabel={"No"}
                                                        onToggle={(v) => handleRoleChange(v, u.id, "Report Viewer")}
                                                    />
                                                </td>
                                                <td>
                                                    {/*buttons go here for editing*/}
                                                </td>
                                            </tr>))}
                                        </tbody>
                                    </Table>
                                )}
                                {newUser && (
                                  <UserAddEdit
                                    currentUser={userCtx.currentUser}
                                    returnRoute=""
                                    computedMatch={props.computedMatch}
                                    manufacturer={false}
                                    store={true}
                                    cancel={() => setNewUser(false)}
                                  />
                                )}
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </TabPane>
          </TabContent>
        </Col>
      </Row>
    </Card>
  </Container>
        </>
    );
}