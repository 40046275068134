import React, { Component, useState, useEffect, useContext, Fragment } from "react";
import {
  Row,
  Col,
  Table,
  Container,
  Card,
  CardHeader,
  CardBody,
  Button,
  ButtonGroup,
  Collapse,
} from "reactstrap";
import { api, ui_helpers, filter_helpers } from "../helpers";
import {
  ActiveToggleFilter,
  FilterSet,
  TextFilter,
  StoreAddEdit,
  Pager,
  SortHeader,
  Footer,
  Header,
  AdminHeader,
  FilterSearch, Loading,
} from "../components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import _ from "lodash";
import Alert from "react-s-alert-v3";
import {Link, Redirect} from "react-router-dom";
import { UserContext, constants } from "../utils";

export default function Stores(props) {
  const [filters, setFilters] = useState([
      {filterName: 'activeOnly', value: true, filteredDisplay: 'Active Only?: true'}
    ]);
  const [pagedStores, setPagedStores] = useState([]);
  const [newStore, setNewStore] = useState({});
  const [storeAddEdit, setStoreAddEdit] = useState(false);
  const [sortColumn, setSortColumn] = useState("name");
  const [sortDirection, setSortDirection] = useState("asc");
  const [sortAscending, setSortAscending] = useState(true);
  const [dealers, setDealers] = useState([]);
  const [redirect, setRedirect] = useState('');
  const [loading, setLoading] = useState(false);
  const [pagedList, setPagedList] = useState({
    page: "",
    pageSize: "",
    totalItems: "",
    pageNumber: 1,
  });
  const PATHNAMES = constants.PATH_NAMES;
  const userCtx = useContext(UserContext);

  useEffect(() => {
    let isActive = true;
    refreshList(isActive);

    return () => {
      isActive = false;
    };
  }, [sortColumn, sortDirection, pagedList.pageNumber, filters]);

  function refreshList(isActive) {
    if (loading) return;
    setLoading(true);
    const resolvedFilters = filters;
    const resolvedSort = {
      sortColumn: sortColumn,
      sortAscending: sortDirection === "asc",
    };
    const payload = {
      activeOnly: false,
      name: "",
      companyIdList: [],
      idList: [],
      id: null,
      manufacturerId: null,
      ...resolvedSort,
      ...filter_helpers.filtersToObject(resolvedFilters),
      pageSize: 100,
      page: pagedList.pageNumber,
    };
    api
      .post("store/list", payload)
      .then((r) => {
        if (!r.data.success) {
          Alert.error("An unexpected error occurred: " + r.data.message);
          return;
        }
        if (isActive) {
          let { sortColumn, sortAscending } = resolvedSort;
          setDealers(r.data.data.items);
          setPagedList({
            pageNumber: r.data.data.pageNumber,
            pageSize: r.data.data.pageSize,
            totalItems: r.data.data.totalCount,
          });
        }
      })
      .catch((error) =>
        Alert.error("There was an error loading the store list")
      )
      .finally(() => setLoading(false));
  }


  function filterChange(changed_filter) {
    const newFilters = filter_helpers.getFilters(filters, changed_filter);
    setFilters(newFilters);
  }

  function doSort(field, direction) {
    setSortColumn(Object.assign({}, field));
    setSortDirection(Object.assign({}, direction));
  }

  function toggleStoreAddEdit() {
    setStoreAddEdit(!storeAddEdit);
    refreshList();
  }

  function activateStore(storeId) {
    api
      .post(`store/ActivateDealer/${storeId}`)
      .then((response) => {
        if(!response.data.success) return;
        refreshList(true);
      })
      .catch((error) =>
        Alert.error("There was an error activating the store.")
      );
  }

  function deactivateStore(storeId) {
    api
      .post(`store/DeactivateDealer/${storeId}`)
      .then((response) => {
        if(!response.data.success) return;
        refreshList(true);
      })
      .catch((error) =>
        Alert.error("There was an error activating the store.")
      );
  }

  useEffect(() => {
    if (ui_helpers.isSystemAdministrator(userCtx.currentUser) 
        || ui_helpers.isAnyCompanyAdministrator(userCtx.currentUser)
        || ui_helpers.isAnyDealerAdministrator(userCtx.currentUser)) return;
    setRedirect('/');
  }, []);

  if(redirect) {
    return <Redirect to={redirect}/>
  }

  return (
    <>
      <AdminHeader stores />
      <Container fluid>
          <Row>
            <Col sm={{offset: 6, size: 6}}>
            {storeAddEdit
              ? (<Col><div className="m-5"></div></Col>)
              : (<FilterSearch filters={filters} onClear={() => setFilters([])}>
                  <Row>
                    <Col xs="4">
                      <TextFilter
                        filterName="name"
                        displayName="Store Name"
                        onChangeCallback={filterChange}
                      />
                    </Col>
                    <Col xs="4">
                      <ActiveToggleFilter
                        filterName='activeOnly'
                        displayName='Active Only?'
                        value={filter_helpers.get_value('activeOnly', filters)}
                        onChangeCallback={filterChange}
                      />
                    </Col>
                  </Row>
                </FilterSearch>)
            }
            </Col>
          </Row>
        <Row>
          <Col>
            <Card style={{ borderColor: "transparent" }}>
              <CardHeader
                style={{ borderColor: "transparent", marginTop: "30px" }}
              >
                <Row>
                  <Col>
                    <h2 className="page-title">Stores</h2>
                  </Col>
                  <Col>
                    <ButtonGroup className="float-end">
                      {ui_helpers.isAnyCompanyAdministrator(
                        userCtx.currentUser
                      ) ||
                      ui_helpers.isSystemAdministrator(userCtx.currentUser) ? (
                        <Button
                          size="sm"
                          className="btn-success cf-success buttonGroupSpacing"
                          style={{ color: "white" }}
                          onClick={() => toggleStoreAddEdit()}
                        >
                          <FontAwesomeIcon
                            icon={storeAddEdit ? "minus" : "plus"}
                          />{" "}
                          Store
                        </Button>
                      ) : null}
                      <Link to="/">
                        <Button size="sm" className="btn-dark">
                          <FontAwesomeIcon icon="home" />
                        </Button>
                      </Link>
                    </ButtonGroup>
                  </Col>
                </Row>
              </CardHeader>
            </Card>
          </Col>
        </Row>
        <Row className="rowSpacing">
          <Col>
            <Card style={{ borderColor: "transparent" }}>
              <CardBody className="pt-0">
                {storeAddEdit ? (
                  <StoreAddEdit
                    onSaveCallback={toggleStoreAddEdit}
                    currentUser={userCtx.currentUser}
                  />
                ) : (
                <Fragment>
                  <Table bordered striped>
                    <thead>
                      <tr key={"store-headers"}>
                        <SortHeader
                          displayName="Store Name"
                          field="name"
                          sortDir={sortDirection}
                          sorted={sortColumn === "name"}
                          sortCallback={doSort}
                        />
                        <SortHeader
                          displayName="Store Abbreviation"
                          field="abbr"
                          sortDir={sortDirection}
                          sorted={sortColumn === "abbr"}
                          sortCallback={doSort}
                        />
                        <SortHeader
                          displayName="90 Days Same As Cash"
                          field="sac"
                          sortDir={sortDirection}
                          sorted={sortColumn === "sac"}
                          sortCallback={doSort}
                        />
                        <SortHeader
                          displayName="Notify Upon Contract Approval"
                          field="notifyUponContractApproval"
                          sortDir={sortDirection}
                          sorted={sortColumn === "notifyUponContractApproval"}
                          sortCallback={doSort}
                        />
                        <th />
                      </tr>
                    </thead>
                    <tbody>
                    {loading
                        ? (<tr key={"l-1"}>
                          <td colSpan={7}>
                            <Loading />
                          </td>
                        </tr>)
                      : dealers && dealers.length
                        ? (_.map(dealers, (x) => (
                            <tr key={x.id}>
                              <td>
                                <Link to={`/admin/store/${x.id}`}>
                                  {x.name}
                                </Link>
                              </td>
                              <td>{x.abbr}</td>
                              <td>{x.sac ? ui_helpers.greenCheck() : ""}</td>
                              <td>
                                {x.notifyUponContractApproval
                                  ? ui_helpers.greenCheck()
                                  : ""}
                              </td>
                              <td>
                                {x.deactivatedAt === null ? (
                                  <Button
                                    size="sm"
                                    className="float-right btn-delete cf-danger"
                                    onClick={() => deactivateStore(x.id)}
                                  >
                                    <FontAwesomeIcon icon="trash-alt" />
                                  </Button>
                                ) : (
                                  <Button
                                    size="sm"
                                    className="float-right btn-success cf-success"
                                    style={{ padding: "4px 6px" }}
                                    onClick={() => activateStore(x.id)}
                                  >
                                    <FontAwesomeIcon
                                      icon="heartbeat"
                                      color="white"
                                    />
                                  </Button>
                                )}
                              </td>
                            </tr>
                          )))
                        : null}
                    </tbody>
                  </Table>
               
                <Pager
                  pageNumber={pagedList ? pagedList.pageNumber : 0}
                  pageSize={pagedList ? pagedList.pageSize : 0}
                  onSetPageCallback={(e) => setPagedList({ ...pagedList, pageNumber: e })}
                  totalResults={pagedList ? pagedList.totalItems : 0}
                />
                </Fragment>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  );
}
