import React, { useReducer } from "react";
import { AddOnsContext } from "../contexts";
import { addOnsReducer } from "../reducers";

function AddOnsProvider({ addOnOpts, selectedAddOns, bundleOpts, children }) {
  const [state, dispatch] = useReducer(addOnsReducer, {
    addOnOpts,
    selectedAddOns,
    bundleOpts,
  });

  return (
    <AddOnsContext.Provider value={[state, dispatch]}>
      {children}
    </AddOnsContext.Provider>
  );
}

export default AddOnsProvider;
