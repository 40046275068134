import React, { useContext, useState, useEffect } from "react";
import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Table,
  Tooltip,
} from "reactstrap";
import Draggable from "react-draggable";
import { CalculatorTable } from "./";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Alert from "react-s-alert-v3";
import _ from "lodash";
import { ui_helpers } from "../helpers";
import { api, storage, UserContext } from "../utils";

const CalculatorModal = ({ isOpen, setIsOpen }) => {
  const defaultTaxRate = parseFloat(storage.getItem('lastTaxRate') || "7.0");
  const [unitPrice, setUnitPrice] = useState(2900);
  const [taxRate, setTaxRate] = useState(defaultTaxRate);
  const [atLeast14Wide, setAtLeast14Wide] = useState(false);
  const [atLeast16Wide, setAtLeast16Wide] = useState(false);
  const [productType, setProductType] = useState(null);
  const [includeLDW, setIncludeLDW] = useState(true);
  const [showTotalFinancingFees, setShowTotalFinancingFees] = useState(true);
  const [customDownPayment, setCustomDownPayment] = useState(0);
  const [promotionCode, setPromotionCode] = useState('');
  const [resultsDisplayed, setResultsDisplayed] = useState(false);
  const [regions, setRegions] = useState([]);
  const [companyName, setCompanyName] = useState(null);
  const { currentUser } = useContext(UserContext);
  
  useEffect(() => {
    if (currentUser && 
      regions.length === 0 &&
      (ui_helpers.isSystemAdministrator(currentUser) 
        || ui_helpers.companyCount(currentUser) > 1 
        || currentUser.inferredRTOCompanyRelationCount > 1
        || ui_helpers.manufacturerCompanyCount(currentUser) > 0)
    ) {
      api.fetch("reference/RelatedCompanyRegionList").then(r => {
        if (!r.data.success) return;
        setRegions(
          ui_helpers.idNameToValueLabel(r.data.data));
      }).catch(api.catchHandler);
    }
  }, [])

  function getPayload() {
    return {
      TaxRateString: taxRate,
      ProductTypeId: productType.value,
      UnitPrice: unitPrice,
      IncludeDLW: includeLDW,
      PromoCode: promotionCode,
      IdentifyTotalFinancingFees: showTotalFinancingFees,
      InitialPayment: customDownPayment,
      IsAtLeast14FeetWide: atLeast14Wide,
      IsAtLeast16FeetWide: atLeast16Wide
    };
  }

  function onPrint() {
    const pdfWindow = window.open();
    api.downloadAndOpenFile('contract/PrintPaymentCalculator', getPayload(), pdfWindow, (e) => {
      pdfWindow.close();
      console.error(e);
      Alert.error("There was an error downloading the document");
    });
  }

  return (
      <Modal size="xl" isOpen={isOpen}>
        <ModalHeader>Payment Calculator{companyName ? ` ${companyName}` : null}</ModalHeader>
        <ModalBody>
          <CalculatorTable 
            showInputs={true}
            termsSelectable={false}
            unitPrice={unitPrice}
            taxRate={taxRate}
            atLeast14Wide={atLeast14Wide}
            atLeast16Wide={atLeast16Wide}
            includeLDW={includeLDW}
            customDownPayment={customDownPayment}
            regions={regions}
            onSetUnitPrice={setUnitPrice}
            onSetProductType={setProductType}
            onSetTaxRate={setTaxRate}
            onSetAtLeast14Wide={setAtLeast14Wide}
            onSetAtLeast16Wide={setAtLeast16Wide}
            onSetShowTotalFinancingFees={setShowTotalFinancingFees}
            onSetIncludeLDW={setIncludeLDW}
            onSetCustomDownPayment={setCustomDownPayment}
            onSetPromotionCode={setPromotionCode}
            onResultsDisplayed={setResultsDisplayed}
            onSetCompanyName={setCompanyName}
            showRTOCompanyName
          />
        </ModalBody>
        <ModalFooter>
          {resultsDisplayed
            ? (<Button color="dark" onClick={onPrint}>
                Print
              </Button>)
            : null
          }
          <Button color="light" onClick={() => setIsOpen(!isOpen)}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
  );
};

export default CalculatorModal;
