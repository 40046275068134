import React, { Component, useEffect, useState, useContext } from 'react';
import {
    Row,
    Col,
    Container,
    Card,
    CardHeader,
    CardBody,
    TabContent,
    TabPane,
    Nav,
    NavItem,
    NavLink,
    Button,
    Label,
    Table,
    Input,
    ButtonGroup
} from "reactstrap";
import { api, ui_helpers, date_helpers } from "../helpers";
import {
    Header,
    Footer,
    ResultHeader,
    AdminHeader, 
    Loading
} from "../components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Alert from "react-s-alert-v3";
import classnames from 'classnames';
import ToggleButton from "react-toggle-button";
import Select from "react-select";
import {Link, Redirect} from 'react-router-dom';
import _ from "lodash";
import { UserContext, constants } from "../utils";

export default function Regions(props) {
    const PATHNAMES = constants.PATH_NAMES;
    const [states, setStates] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [redirect, setRedirect] = useState('');
    const userCtx = useContext(UserContext);

    useEffect(() => {
      let isActive = true;
      getAllStates(isActive);
      return () => {
        isActive = false;
      };
    }, []);

    function getAllStates(isActive) {
      api.fetch("reference/FullRegionList").then(response => {
        if (isActive) {
          setStates(ui_helpers.idNameToValueLabel(response.data.data));
        }
      }).catch(error => Alert.error("There was an error loading the states list."));
    }

    function saveRegion(r) {
      api.post("reference/SaveRegion", r).then(response => {
        if (response.data.success) {
          setStates(_.map(states, s => {
            return s.id === r.id ? r : s;
          }));
        } else {
          Alert.error("There was an error saving the region: " + response.data.message);
        }
      }).catch(error => Alert.error("There was an error saving the region."));
    }

    useEffect(() => {
      if (ui_helpers.isSystemAdministrator(userCtx.currentUser)) return;
      setRedirect('/');
    }, []);

    if (redirect) {
      return <Redirect to={redirect}/>
    }

  return (<>
    <AdminHeader regions />
    <Container fluid>
      <CardHeader>
        <Row className='d-flex align-items-center'>
          <Col>
            <ResultHeader heading='States' totalResults={states ? states.length : 0}/>
          </Col>
        </Row>
      </CardHeader>
      <Table size="sm" bordered striped>
        <thead>
          <tr>
            <th>Name</th>
            <th>Allow Purchase Reserve?</th>
            <th>Allow Guess of Tax Rate Based on City and State?</th>
            <th/>
          </tr>
        </thead>
        <tbody>
        {isLoading
          ? (<tr><td><Loading/></td></tr>)
          : (<>
              {states
                ? (_.map(states, s => (<tr key={`st-${s.id}`}>
                  <td>{s.name}</td>
                  <td>
                    <ToggleButton
                      value={s.allowPurchaseReserve}
                      activeLabel="Yes"
                      inactiveLabel="No"
                      onToggle={(value) => saveRegion({...s, allowPurchaseReserve: !value})}
                    />
                  </td>
                  <td>
                    <ToggleButton
                      value={s.allowTaxRateDefaultGuess}
                      activeLabel="Yes"
                      inactiveLabel="No"
                      onToggle={(value) => saveRegion({...s, allowTaxRateDefaultGuess: !value})}
                    />
                  </td>
                </tr>)))
                : null
              }
            </>)
        }
        </tbody>
      </Table>
    </Container>
  </>);
}
