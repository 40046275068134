import React, {
  useState,
  useEffect,
  useContext,
  useRef,
  Fragment,
} from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useLocation, useParams } from "react-router";
import { Link, Redirect, useHistory } from "react-router-dom";
import Dropzone from "react-dropzone";
import Card from "@repay/react-credit-card";
import {
  Button,
  ButtonDropdown,
  DropdownItem,
  DropdownToggle,
  DropdownMenu,
  ButtonGroup,
  Container,
  Row,
  Col,
  Badge,
  Popover,
  PopoverBody,
  Table,
  Accordion,
  AccordionItem,
  AccordionHeader,
  AccordionBody,
  Input,
  InputGroup,
  Label,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Modal,
  Alert as RSAlert,
  UncontrolledAccordion,
} from "reactstrap";
import { confirmAlert } from "react-confirm-alert";
import Select from "react-select";
import moment from "moment";
import _ from "lodash";
import { GrGlobe } from "react-icons/gr";
import DatePicker from "react-datepicker";
import classnames from "classnames";
import {
  FollowUpButtons,
  Footer,
  Header,
  Loading,
  RecurringPaymentModal,
  ModelModal,
  SetSerialModal,
  HistoryList,
  CaptureACH,
  CCSwipeParser,
  SalespersonEdit,
  PriceSummaryTable,
  DetailsTable,
  DetailsRow
} from "./";
import { api, constants, UserContext } from "../utils";
import FollowupIcon from "./FollowupIcon";
import { date_helpers, ui_helpers } from "../helpers";
import Alert from "react-s-alert-v3";
import CurrencyInput from "react-currency-input-field";

const TABS = {
  CUSTOMER: "customer",
  UNIT: "unit",
  PAYMENT: "payment",
  ESSENTIALS: "essentials",
  UPLOADS: "uploads",
  COMMENTS: "comments",
  HISTORY: "history",
  OWNERSHIP: "ownership",
};

const RECIPIENT_TYPE = {
  OTHER: "other",
  CUSTOMER: "customer",
  DEALER_USERS: "dealer-users",
};

const linkVisibilityList = (contractType) => {
  let list = [];
  if (contractType === constants.CONTRACT_TYPE.RTO) {
    list.push({
      name: "Customer Contract Signature",
      id: 2,
      key: "CONTRACT_SIG",
    });
    list.push({
      name: "Property Owner Agreement Signature",
      id: 3,
      key: "PROPERTY",
    });
    // {name: "Customer Contract and Attachments", id: 6, key: "CONTRACT"},
  } else {
    list.push({ name: "Cash Sale Agreement", id: 2, key: "CASH_SALE_SIG" });
    // list.push({name: "Cash Sale Agreement", id: 1, key: "FULL"});
  }
  list.push({
    name: "Delivery Confirmation Signature",
    id: 7,
    key: "DELIVERY",
  });
  return list;
};

const WebSaleVerification = ({
  buildingOrientations,
  buildingOrientationLabel,
  onSetBuildingOrientation,
  salespeople,
  salesperson,
  otherSalesperson,
  onSetSalesperson,
  onDeleteSalesperson,
  onCompletePreverification,
  isRTO
}) => {
  return (
    <Fragment>
      <Row>
        <Col
          xs="12"
          style={{ border: "solid 1px rgb(0, 0, 0, 0.125)", padding: "12px" }}
        >
          <GrGlobe style={{ fontSize: "1.2rem" }} /> Web Sale Verification
        </Col>
      </Row>
      <Row>
        <Col
          xs={{ size: 10, offset: 1 }}
          className="my-2 py-2"
          style={{
            border: "solid 1px #000",
            backgroundColor: "lightgrey",
            borderRadius: "8px",
            fontSize: "1.2rem",
          }}
        >
          Please verify the following details with the customer.
        </Col>
      </Row>
      <Row>
        <Col className="web-verify-panel">
          <span className="web-verify-header">Unit Details</span>
          <ul>
            <li>Unit Size</li>
            <li>Unit Color</li>
            <li>Unit Options</li>
          </ul>
        </Col>
        {isRTO && 
          <Col className="web-verify-panel">
            <span className="web-verify-header">Customer Details</span>
            <ul>
              <li>SS# or License # {ui_helpers.requiredStar()}</li>
              <li>LDW</li>
              <li>Property Owner?</li>
              <li>Additional Contacts</li>
            </ul>
          </Col>
        }
        <Col className="web-verify-panel">
          <span className="web-verify-header">Payment Details</span>
          <ul>
            <li>Rent To Own {ui_helpers.requiredStar()}</li>
            <li>Cash Sale</li>
            <li>Balance due on delivery?</li>
          </ul>
        </Col>
        <Col className="web-verify-panel">
          <span className="web-verify-header">Delivery Details</span>
          <ul>
            <li>Delivery Address</li>
            <li>Access to site</li>
            <li>Site Condition</li>
            <li>Load Orientation</li>
            {isRTO && <li>Landowner Y/N {ui_helpers.requiredStar()}</li>}
            <li>Confirm Blocking</li>
          </ul>
        </Col>
      </Row>
{/*
      <Row>
        <Col xs={{ size: 6, offset: 2 }}>
          Delivery Building Orientation {ui_helpers.requiredStar()}
          <div>
            <Select
              options={buildingOrientations}
              value={_.find(
                buildingOrientations,
                (o) => o.label === buildingOrientationLabel
              )}
              onChange={onSetBuildingOrientation}
            />
          </div>
        </Col>
        <Col xs="3">
          <SalespersonEdit
            salespeople={salespeople}
            salesperson={salesperson}
            otherSalesperson={otherSalesperson}
            onChange={onSetSalesperson}
            onDeleteSalesperson={onDeleteSalesperson}
          />
        </Col>
      </Row>
*/}
      <Row>
        <Col
          xs="12"
          className="text-center"
          style={{ color: "#FF0000", fontStyle: "italic", fontSize: "1.2rem" }}
        >
          I verify that the applicable information as listed above has been
          confirmed and is accurate.
          <div>
            <Button
              color="primary"
              onClick={onCompletePreverification}
              className="px-4"
            >
              Confirm &amp; Submit
            </Button>
          </div>
        </Col>
      </Row>
      <Row>
        <Col
          xs={{ size: 10, offset: 1 }}
          className="my-2 py-2"
          style={{
            border: "solid 1px #000",
            fontWeight: "bold",
            backgroundColor: "orange",
            borderRadius: "12px",
            fontSize: "1.2rem",
          }}
        >
          <div>
            {ui_helpers.requiredStar()} Customer needs to be advised the RTO
            contract is subject to approve from the RTO provider.
          </div>
          <div>
            {ui_helpers.requiredStar()} RTO deliveries to leased/rented
            properties require a completed Landowner Permission sheet.
          </div>
        </Col>
      </Row>
    </Fragment>
  );
};

const ContractView = ({ contractType }) => {
  const history = useHistory();
  let { routeContractId, routeTabId } = useParams();
  if (routeContractId) {
    routeContractId = parseInt(routeContractId, 10);
  }
  const [isLoading, setIsLoading] = useState(false);
  const [contract, setContract] = useState({});
  const [openAccordionItems, setOpenAccordionItems] = useState(
    routeTabId ? routeTabId : TABS.COMMENTS
  );
  const [showSSN, setShowSSN] = useState(false);
  const [showSerialModal, setShowSerialModal] = useState(false);
  const [showCommentModal, setShowCommentModal] = useState(false);
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [showHoldModal, setShowHoldModal] = useState(false);
  const [showModelModal, setShowModelModal] = useState(false);
  const [showLinkModal, setShowLinkModal] = useState(false);
  const [showDeliveryModal, setShowDeliveryModal] = useState(false);
  const [showRecurringModal, setShowRecurringModal] = useState(false);
  const [propertyOwnerPopoverOpen, setPropertyOwnerPopoverOpen] =
    useState(false);
  const [modalContext, setModalContext] = useState({ header: "", body: "" });
  const [comments, setComments] = useState([]);
  const [paymentComment, setPaymentComment] = useState("");
  const [paymentOtherAmountCaption, setPaymentOtherAmountCaption] =
    useState("");
  const [ownershipList, setOwnershipList] = useState([]);
  const [paymentList, setPaymentList] = useState([]);
  const [historyList, setHistoryList] = useState([]);
  const [driverUserList, setDriverUserList] = useState([]);
  const [additionalContacts, setAdditionalContacts] = useState([]);
  const [addOns, setAddOns] = useState([]);
  const [buildSteps, setBuildSteps] = useState([]);
  const [attachmentAndMilestoneList, setAttachmentAndMilestoneList] = useState([]);
  const [files, setFiles] = useState([]);
  const [contractImages, setContractImages] = useState([]);
  const [contractSignatures, setContractSignatures] = useState([]);
  const [serialNum, setSerialNum] = useState("");
  const [cardSwipe, setCardSwipe] = useState("");
  const [cardZip, setCardZip] = useState("");
  const [linkRecipientType, setLinkRecipientType] = useState("");
  const [linkVisibilityTypeId, setLinkVisibilityTypeId] = useState(null);
  const [otherRecipient, setOtherRecipient] = useState("");
  const [modelNumber, setModelNumber] = useState("");
  const [promptingUploadAttachmentTypeId, setPromptingUploadAttachmentTypeId] =
    useState(null);
  const [clickedUpload, setClickedUpload] = useState(false);
  const [deliverOn, setDeliverOn] = useState(moment());
  const [deliverAt, setDeliverAt] = useState(null);
  const [driverId, setDriverId] = useState(null);
  const [comment, setComment] = useState("");
  const [commentPrivate, setCommentPrivate] = useState(false);
  const [wrenchMenuOpen, setWrenchMenuOpen] = useState(false);
  const [switchMenuOpen, setSwitchMenuOpen] = useState(false);
  const [pendingSwitchToType, setPendingSwitchToType] = useState("");
  const [essentialsMenuOpen, setEssentialsMenuOpen] = useState(false);
  const [hasMissingAttachments, setHasMissingAttachments] = useState(false);
  const [redirectTo, setRedirectTo] = useState("");
  const [timeList, setTimeList] = useState([]);
  const [currentUserCanDispatch, setCurrentUserCanDispatch] = useState(false);
  const [paymentTypes, setPaymentTypes] = useState([]);
  const [paymentTypeId, setPaymentTypeId] = useState(null);
  const [selectedCard, setSelectedCard] = useState(null);
  const [selectedBankAccount, setSelectedBankAccount] = useState(null);
  const [paymentIdentifier, setPaymentIdentifier] = useState("");
  const [cards, setCards] = useState([]);
  const [bankAccounts, setBankAccounts] = useState([]);
  const [capturingCard, setCapturingCard] = useState(false);
  const [paymentAmount, setPaymentAmount] = useState(0);
  const [capturingACH, setCapturingACH] = useState(false);
  const [capturingCardManually, setCapturingCardManually] = useState(false);
  const [achPayment, setAchPayment] = useState(constants.defaultPayment);
  const [achFirstName, setAchFirstName] = useState("");
  const [achLastName, setAchLastName] = useState("");
  const [cardNo, setCardNo] = useState("");
  const [cardMMYY, setCardMMYY] = useState("");
  const [cardCVC, setCardCVC] = useState("");
  const [manualCard, setManualCard] = useState(null);
  const [bankInformation, setBankInformation] = useState(constants.defaultBank);
  const [achValidation, setAchValidation] = useState(
    constants.defaultAchValidation
  );
  const [showAchValidation, setShowAchValidation] = useState(false);
  const [
    currentUserIsSysAdminOrCompanyAdmin,
    setCurrentUserIsSysAdminOrCompanyAdmin,
  ] = useState(false);
  const { currentUser } = useContext(UserContext);
  const [isSysAdmin, setIsSysAdmin] = useState(ui_helpers.isSystemAdministrator(currentUser));
  const [isCompanyAdmin, setIsCompanyAdmin] = useState(ui_helpers.isAnyCompanyAdministrator(currentUser));
  const [isMfgAdmin, setIsMfgAdmin] = useState(ui_helpers.isAnyManufacturerAdministrator(currentUser));
  const [isStoreUser, setIsStoreUser] = useState(false);
  const [isDriverOrDispatcher, setIsDriverOrDispatcher] = useState(false);
  const [hasDealerAccess, setHasDealerAccess] = useState(false);
  const [isDealerAdmin, setIsDealerAdmin] = useState(false);
  const [canManageContract, setCanManageContract] = useState(false);
  const [isContractOwner, setIsContractOwner] = useState(false);
  const [isAContractCreator, setIsAContractCreator] = useState(false);
  const [isManufacturerUser, setIsManufacturerUser] = useState(false);
  const [canDispatch, setCanDispatch] = useState(false);
  const [canAddComments, setCanAddComments] = useState(false);
  const [canAddPayment, setCanAddPayment] = useState(false);
  const [processMessage, setProcessMessage] = useState("");
  const [buildingOrientations, setBuildingOrientations] = useState([]);
  const [salespeople, setSalespeople] = useState([]);
  const [isRunning, setIsRunning] = useState(false);
  const [controllerUrl, setControllerUrl] = useState(
    contractType === constants.CONTRACT_TYPE.RTO ? "contract" : "cashsale"
  );
  const [message, setMessage] = useState(null);
  const [contractPasscode, setContractPasscode] = useState(null);
  const [isContractSignatureCaptureSelected, setIsContractSignatureCaptureSelected] = useState(false);
  const [isNewOptionSelected, setIsNewOptionSelected] = useState(false);

  const cardSwipeRef = useRef(null);

  useEffect(() => {
    setTimeList(date_helpers.createTimeList());
    api.fetch("Contract/GetBuildingOrientations").then((r) => {
      setBuildingOrientations(_.reject(r.data, (o) => !o.label));
    });
  }, []);

  useEffect(loadContract, [routeContractId, contractType]);

  useEffect(() => {
    if (!contract) return;
    setPermissions();
  }, [contract]);

  function loadContract() {
    setIsLoading(true);
    if (isLoading) return;
    api.fetch(`Contract/${routeContractId}`).then(r => {
      if (r.data.success) {
        if (contractType === constants.CONTRACT_TYPE.RTO && r.data.message.contract.agreementNumber === 'Cash Sale') {
          setIsLoading(false);
          const reroutePath = `${constants.PATH_NAMES.CASH_SALE_VIEW}/${routeContractId}`;
          history.push(reroutePath);
          return;
        } else if (contractType === constants.CONTRACT_TYPE.CASH_SALE && r.data.message.contract.agreementNumber !== 'Cash Sale') {
          setIsLoading(false);
          const reroutePath = `${constants.PATH_NAMES.CONTRACT_VIEW}/${routeContractId}`;
          history.push(reroutePath);
          return;
        }
        let contractData = r.data.message.contract;
        setComments(r.data.message.commentList);
        setOwnershipList(r.data.message.ownershipList);
        setPaymentList(r.data.message.paymentItems);
        setHistoryList(r.data.message.lifeCycleEventList);
        setAdditionalContacts(r.data.message.additionalContacts);
        setAddOns(r.data.message.unitOptionList);
        setBuildSteps(r.data.message.buildSteps);
        setCurrentUserCanDispatch(r.data.message.currentUserCanDispatch);
        setCurrentUserIsSysAdminOrCompanyAdmin(r.data.message.currentUserIsSysAdminOrCompanyAdmin);
        setDriverUserList(ui_helpers.idNameToValueLabel(r.data.message.driverUserList, null, false));
        setContractImages(r.data.message.contractImageList);
        setContractSignatures(r.data.message.contractSignatureList);
        setAttachmentAndMilestoneList(r.data.message.attachmentAndMilestoneList);
        setHasMissingAttachments(r.data.message.hasMissingAttachments);
        setContractPasscode(r.data.message.contractSignatureAccessToken);
        setSalespeople(ui_helpers.idNameToValueLabel(r.data.message.salespeople, null, false));
        if (r.data.message.contract.salespersonName) {
          const sp = _.find(r.data.message.salespeople, x => x.label === r.data.message.contract.salespersonName);
          if (sp) {
            contractData.salesperson = sp;
            contractData.otherSalesperson = '';
          }
          let contractData = r.data.message.contract;
          setComments(r.data.message.commentList);
          setOwnershipList(r.data.message.ownershipList);
          setPaymentList(r.data.message.paymentItems);
          setHistoryList(r.data.message.lifeCycleEventList);
          setAdditionalContacts(r.data.message.additionalContacts);
          setAddOns(r.data.message.unitOptionList);
          setCurrentUserCanDispatch(r.data.message.currentUserCanDispatch);
          setCurrentUserIsSysAdminOrCompanyAdmin(
            r.data.message.currentUserIsSysAdminOrCompanyAdmin
          );
          setDriverUserList(
            ui_helpers.idNameToValueLabel(
              r.data.message.driverUserList,
              null,
              false
            )
          );
          setContractImages(r.data.message.contractImageList);
          setContractSignatures(r.data.message.contractSignatureList);
          setAttachmentAndMilestoneList(
            r.data.message.attachmentAndMilestoneList
          );
          setHasMissingAttachments(r.data.message.hasMissingAttachments);
          setSalespeople(
            ui_helpers.idNameToValueLabel(
              r.data.message.salespeople,
              null,
              false
            )
          );
          if (r.data.message.contract.salespersonName) {
            const sp = _.find(
              r.data.message.salespeople,
              (x) => x.label === r.data.message.contract.salespersonName
            );
            if (sp) {
              contractData.salesperson = sp;
              contractData.otherSalesperson = "";
            }
          }
          if (!contractData.salesperson) {
            contractData.salesperson = constants.OTHER_OPTION;
            contractData.otherSalesperson =
              r.data.message.contract.salespersonName;
          }
          setContract(contractData);
          let newPaymentTypes = constants.PAYMENT_TYPES.slice();
          if (!r.data.message.companyAcceptsPayment.creditCards)
            _.remove(newPaymentTypes, (x) => x.value === constants.PAYMENT_TYPE_IDS.CREDIT_CARD);
          if (!r.data.message.companyAcceptsPayment.achBank)
            _.remove(newPaymentTypes, (x) => x.value === constants.PAYMENT_TYPE_IDS.ACH_DEBIT);
          if (!isSysAdmin && !isCompanyAdmin)
            _.remove(newPaymentTypes, (x) => x.value === constants.PAYMENT_TYPE_IDS.COMMITMENT);
          if (!isSysAdmin && !r.data.message.enableRetained)
            _.remove(newPaymentTypes, (x) => x.value === constants.PAYMENT_TYPE_IDS.RETAINED);
          setPaymentTypes(newPaymentTypes);
          window.scroll(0, 0);
        } else if (r.data.message === constants.ERROR_MESSAGES.ERR_ACCESS_DENIED) {
          Alert.error(r.data.message);
          setRedirectTo("/");
        }
      } else {
        Alert.error(r.data.message);
      }
    })
    .catch((e) => console.error(e))
    .finally(() => setIsLoading(false));
  }

  function toggleAccordion(targetId) {
    if (_.values(TABS).includes(targetId) && targetId !== openAccordionItems) {
      setOpenAccordionItems(targetId);
    } else {
      setOpenAccordionItems("0");
    }
  }

  function generateNewContractCode() {
    const previousContractPasscode = contractPasscode;
    api
      .post(`contract/GenerateNewContractCode/${routeContractId}`, { contractId: routeContractId, currentUserId: currentUser.id })
      .then((r) => {
        if (r.data.success) {
          setContractPasscode(r.data.message);
          loadContract();
          if (previousContractPasscode != null && previousContractPasscode?.signatureAccessToken == r.data.message.signatureAccessToken) {
            Alert.success("Code duration has been extended successfully!")
          } else if (previousContractPasscode != null && previousContractPasscode?.signatureAccessToken != r.data.message.signatureAccessToken) {
            Alert.success("New code has been generated!");
           } else {
            Alert.success("Code has been generated successfully!");
          }
        } else {
          Alert.error(r.data.message);
        }
      })
      .catch((e) => console.error(e));
  }

  const cleanseAchModal = () => {
    setBankInformation(constants.defaultBank);
    setAchPayment(constants.defaultPayment);
    setAchValidation(constants.defaultAchValidation);
    setCapturingACH(false);
  };

  function onDownloadImage(key, mimeType) {
    const pdfWindow = window.open();
    api.downloadAndOpenFile(
      "contract/DownloadContractDocument",
      { referenceId: routeContractId, documentKey: key },
      pdfWindow,
      (e) => {
        pdfWindow.close();
        console.error(e);
        // Alert.error("There was an error downloading the specified document");
      },
      "detect"
    );
  }

  function deleteAttachment(attachmentId, e, attachmentTypeId = null) {
    e.stopPropagation();
    api
      .post("contract/DeleteAttachment/" + routeContractId, {
        id: attachmentId,
        attachmentTypeId: attachmentTypeId,
      })
      .then((r) => {
        if (r.data.success) {
          loadContract();
        } else {
          Alert.error(r.data.message);
        }
      })
      .catch((e) => console.error(e));
  }

  function commitUpload() {
    let payload = new FormData();
    payload.append("referenceId", routeContractId);
    payload.append("file", files[0]);
    if (promptingUploadAttachmentTypeId) {
      payload.append("typeOfAttachment", promptingUploadAttachmentTypeId);
    }
    const url = promptingUploadAttachmentTypeId === constants.ATTACHMENT_TYPE_IDS.TaxExemptionForm
        ? "contract/UploadTaxExemptionForm"
        : "contract/UploadContractImage";
    setClickedUpload(true);
    api
      .post_form_data(url, payload)
      .then((r) => {
        if (r.data.success) {
          Alert.success("The file was saved successfully");
          if (promptingUploadAttachmentTypeId) {
            setPromptingUploadAttachmentTypeId(null);
          }
          setFiles([]);
          loadContract();
        } else {
          setMessage({
            errorMessage:
              r.data.message || "There was an error saving the upload",
            color: "warning",
          });
          Alert.warning(
            r.data.message || "There was an error saving the upload"
          );
          setTimeout(() => {
            setMessage(null);
          }, 5000);
        }
      })
      .catch((error) => console.error(error))
      .finally(() => setClickedUpload(false));
  }

  function submitComment() {
    api
      .post("contract/addComment", {
        id: routeContractId,
        comment: comment,
        isPrivate: commentPrivate,
      })
      .then((r) => {
        if (r.data.success) {
          setComment("");
          setShowCommentModal(false);
          loadContract();
        } else {
          Alert.error(r.data.message);
        }
      })
      .catch((e) => console.error(e));
  }

  function refreshComments() {
    api.fetch(`contract/GetComments/${routeContractId}`).then((r) => {
      setComments(r.data);
    });
  }

  function deleteComment(commentId) {
    api
      .post("contract/deleteComment", { id: commentId })
      .then((r) => {
        if (r.data.success) {
          refreshComments();
        } else {
          Alert.error(r.data.message);
        }
      })
      .catch((e) => console.error(e));
  }

  function onClone() {
    setIsRunning(true);
    api
      .post("contract/Clone", { id: routeContractId })
      .then((r) => {
        if (r.data.success) {
          Alert.success(
            "The clone has completed successfully. You will be redirected momentarily."
          );
          setTimeout(() => {
            let url =
              contractType === constants.CONTRACT_TYPE.RTO
                ? constants.PATH_NAMES.CONTRACT_EDIT
                : constants.PATH_NAMES.CASH_SALE_EDIT;
            history.push(url + "/" + r.data.message);
          }, 1500);
        } else {
          Alert.error(r.data.message);
        }
      })
      .catch((e) => console.error(e))
      .finally(() => setIsRunning(false));
  }

  function changeSerialNumber() {
    api
      .post("contract/SetSerialNo", {
        id: routeContractId,
        serialNo: serialNum,
      })
      .then((r) => {
        if (r.data.success) {
          setSerialNum("");
          setShowSerialModal(false);
          loadContract();
        } else {
          Alert.error(r.data.message);
        }
      })
      .catch((e) => console.error(e));
  }

  function changeModelNumber() {
    api
      .post("contract/SetModelNo", {
        id: routeContractId,
        modelNo: modelNumber,
      })
      .then((r) => {
        if (r.data.success) {
          setModelNumber("");
          setShowModelModal(false);
          loadContract();
        } else {
          Alert.error(r.data.message);
        }
      })
      .catch((e) => console.error(e));
  }

  function deleteContract() {
    confirmAlert({
      title: "Confirm Deletion",
      message: "Are you sure you wish to permanently delete this contract?",
      buttons: [
        {
          label: "Yes",
          onClick: () => simpleContractOperation("contract/DeleteContract"),
        },
        {
          label: "No",
        },
      ],
    });
  }

  function cancelContract() {
    setComment("");
    setShowCancelModal(true);
  }

  function commitCancelContract() {
    api
      .post("contract/CancelContract", {
        id: routeContractId,
        comment: comment,
      })
      .then((r) => {
        if (r.data.success) {
          setShowCancelModal(false);
          loadContract();
        } else {
          Alert.error(r.data.message);
        }
      })
      .catch((e) => console.error(e));
  }

  function processContract() {
    api
      .post("contract/SetContractProcessed", { id: routeContractId })
      .then((r) => {
        if (r.data.success) {
          if (r.data.message) {
            setProcessMessage(r.data.message);
          }
          loadContract();
        } else {
          Alert.error(r.data.message);
        }
      })
      .catch((e) => console.error(e));
  }

  function onSendMobileLink() {
    if (!linkVisibilityTypeId) {
      Alert.warning("Select a link type to send");
      return;
    }
    if (!linkRecipientType) {
      Alert.warning("Select a link recipient type before attempting to send");
      return;
    }
    if (linkRecipientType === RECIPIENT_TYPE.OTHER && !_.trim(otherRecipient)) {
      Alert.warning("Provide recipient details before attempting to send");
      return;
    }
    let recipientEmail = "";
    if (linkRecipientType === RECIPIENT_TYPE.OTHER) {
      recipientEmail = _.trim(otherRecipient);
    } else if (linkRecipientType === RECIPIENT_TYPE.CUSTOMER) {
      recipientEmail = contract.customerEmail;
    }
    setIsRunning(true);
    api
      .post("contract/SendMobileContractLink", {
        contractId: routeContractId,
        recipientEmail: recipientEmail,
        allRelatedDealerUsers:
          linkRecipientType === RECIPIENT_TYPE.RELATED_DEALERS,
        allRelatedDealerDrivers: false,
        mobileVisibilityTypeId: linkVisibilityTypeId,
      })
      .then((r) => {
        if (r.data.success) {
          setShowLinkModal(false);
          Alert.success(
            recipientEmail
              ? "A restricted link to the mobile contract site has just been sent to " +
                  recipientEmail
              : "The requested link has been sent!"
          );
        } else {
          Alert.error(r.data.message);
        }
      })
      .catch((e) => console.error(e))
      .finally(() => setIsRunning(false));
  }

  function onSendMobileSignatureCapture() {
    if (!linkVisibilityTypeId && contractPasscode) {
      Alert.warning("Select a link type to send");
      return;
    }
    if (!linkRecipientType) {
      Alert.warning("Select a link recipient type before attempting to send");
      return;
    }
    if (linkRecipientType === RECIPIENT_TYPE.OTHER && !_.trim(otherRecipient)) {
      Alert.warning("Provide recipient details before attempting to send");
      return;
    }
    let recipientEmail = "";
    if (linkRecipientType === RECIPIENT_TYPE.OTHER) {
      recipientEmail = _.trim(otherRecipient);
    } else if (linkRecipientType === RECIPIENT_TYPE.CUSTOMER) {
      recipientEmail = contract.customerEmail;
    }
    setIsRunning(true);
    api
      .post("contract/SendMobileSignatureCapture", {
        contractId: routeContractId,
        recipientEmail: recipientEmail,
        allRelatedDealerUsers:
          linkRecipientType === RECIPIENT_TYPE.RELATED_DEALERS,
        mobileVisibilityTypeId: linkVisibilityTypeId,
      })
      .then((r) => {
        if (r.data.success) {
          setShowLinkModal(false);
          Alert.success(
            recipientEmail
              ? "A restricted link to the mobile contract site has just been sent to " +
                  recipientEmail
              : "The requested link has been sent!"
          );
        } else {
          Alert.error(r.data.message);
        }
      })
      .catch((e) => console.error(e))
      .finally(() => setIsRunning(false));
  }

  function onAssignDelivery() {
    setIsRunning(true);
    api
      .post("contract/SetDriverDispatcher", {
        contractId: routeContractId,
        driverDispatcherUserId: driverId,
        estimatedDeliveryOn: deliverOn,
        deliveryTime: parseInt(deliverAt, 10) ? deliverAt : null,
      })
      .then((r) => {
        if (r.data.success) {
          setShowDeliveryModal(false);
          loadContract();
        } else {
          Alert.error(r.data.message);
        }
      })
      .catch((e) => console.error(e))
      .finally(() => setIsRunning(false));
  }

  function onSetMilestoneStatus(milestoneTypeId, setComplete) {
    setIsRunning(true);
    api
      .post("contract/SetMilestoneStatus", {
        referenceId: routeContractId,
        milestoneType: milestoneTypeId,
        complete: setComplete,
      })
      .then((r) => {
        if (r.data.success) {
          loadContract();
        } else {
          Alert.error(r.data.message);
        }
      })
      .catch((e) => console.error(e))
      .finally(() => setIsRunning(false));
  }

  function onRegenerate(attachmentTypeId) {
    setIsRunning(true);
    api
      .post(`${controllerUrl}/RegenerateDocument`, {
        referenceId: routeContractId,
        attachmentType: attachmentTypeId,
      })
      .then((r) => {
        if (r.data.success) {
          onDownloadAttachment(r.data.message, loadContract);
        } else {
          Alert.error(r.data.message);
        }
      })
      .catch((e) => console.error(e))
      .finally(() => setIsRunning(false));
  }

  function onDownloadAttachment(key = null, onComplete = null) {
    const pdfWindow = window.open();
    // null document key means download all
    api.downloadAndOpenFile(
      "contract/DownloadContractDocument",
      { referenceId: routeContractId, documentKey: key },
      pdfWindow,
      (e) => {
        console.error(e);
        // Alert.error("There was an error downloading the attachment");
        if (pdfWindow) {
          pdfWindow.close();
        }
      },
      "application/pdf",
      onComplete
    );
  }

  function renderMilestoneButton(statusId, milestoneTypeId) {
    switch (statusId) {
      case constants.MILESTONE_STATUS_IDS.Complete:
        return (
          <Button
            color="dark"
            size="sm"
            onClick={() => onSetMilestoneStatus(milestoneTypeId, false)}
          >
            Set Incomplete
          </Button>
        );
      case constants.MILESTONE_STATUS_IDS.Incomplete:
        return (
          <Button
            color="success"
            size="sm"
            className="text-light"
            onClick={() => onSetMilestoneStatus(milestoneTypeId, true)}
          >
            Set Complete
          </Button>
        );
      default:
        return <span></span>;
    }
  }

  function renderMilestoneStatusBadge(statusId, lastUpdatedAtLocalized) {
    switch (statusId) {
      case constants.MILESTONE_STATUS_IDS.NotApplicable:
        return (
          <Badge color="default" className="large">
            Not Applicable
          </Badge>
        );
      case constants.MILESTONE_STATUS_IDS.Complete:
        return (
          <span
            title={`Completed ${lastUpdatedAtLocalized}`}
            className="large badge bg-cf-success"
          >
            Complete <FontAwesomeIcon icon="check" />
          </span>
        );
      case constants.MILESTONE_STATUS_IDS.Incomplete:
        return (
          <Badge color="warning" className="large">
            Incomplete
          </Badge>
        );
      default:
        return <span></span>;
    }
  }

  function viewRecurring() {
    confirmAlert({
      title: "Confirm Access",
      message:
        "The information contained in this form is highly sensitive.\n Please delete it from disk after downloading.\n If it is printed please destroy the paper immediately after use.",
      buttons: [
        {
          label: "OK",
          onClick: () => {
            const pdfWindow = window.open();
            api.downloadAndOpenFile(
              "contract/DownloadRecurringPaymentData",
              { id: routeContractId },
              pdfWindow,
              (e) => {
                pdfWindow.close();
                console.error(e);
                // Alert.error("There was an error downloading the recurring payment data");
              }
            );
          },
        },
      ],
    });
  }

  function clearRecurring() {
    confirmAlert({
      title: "Confirm Deletion",
      message:
        "This will permanently and irreversibly delete recurring payment data. Are you sure?",
      buttons: [
        {
          label: "Yes",
          onClick: () =>
            simpleContractOperation("contract/DeleteRecurringPaymentData"),
        },
        {
          label: "No",
        },
      ],
    });
  }

  function onSetRecurringData(d) {
    api
      .post(
        "contract/SetRecurringPaymentData",
        api.getRecurringPayload(routeContractId, d)
      )
      .then((r) => {
        if (r.data.success) {
          setShowRecurringModal(false);
          loadContract();
        } else {
          Alert.error(r.data.message);
        }
      })
      .catch((e) => console.error(e));
  }

  function unassignDriver() {
    api
      .post("contract/SetDriverDispatcher", {
        contractId: routeContractId,
        driverDispatcherUserId: null,
      })
      .then((r) => {
        if (r.data.success) {
          setShowDeliveryModal(false);
          loadContract();
        } else {
          Alert.error(r.data.message);
        }
      })
      .catch((e) => console.error(e));
  }

  function holdContract() {
    setComment("");
    setShowHoldModal(true);
  }

  function switchContractType() {
    setIsRunning(true);
    api.post("contract/SwitchContractType/" + pendingSwitchToType, { id: routeContractId }).then((r) => {
        if (r.data.success) {
          Alert.success(
            "The current agreement has been cancelled and a new one generated based on these details. You will now be given the opportunity to edit the converted agreement."
          );
          const editURL =
            pendingSwitchToType === constants.SEARCH_AREAS.RTO
              ? constants.PATH_NAMES.CONTRACT_EDIT + "/" + r.data.message
              : constants.PATH_NAMES.CASH_SALE_EDIT + "/" + r.data.message;
          setTimeout(() => setRedirectTo(editURL), 5000);
          setPendingSwitchToType("");
        } else {
          Alert.error(r.data.message);
        }
      })
      .catch((e) => console.error(e))
      .finally(() => setIsRunning(false));
  }

  function commitHoldContract() {
    api
      .post("contract/HoldContract", {
        id: routeContractId,
        comment: comment,
      })
      .then((r) => {
        if (r.data.success) {
          setShowHoldModal(false);
          loadContract();
        } else {
          Alert.error(r.data.message);
        }
      })
      .catch((e) => console.error(e));
  }

  function simpleContractOperation(url) {
    setIsRunning(true);
    api
      .post(url, { id: routeContractId })
      .then((r) => {
        if (r.data.success) {
          if (url === "contract/DeleteContract") {
            history.push("/Contracts/Search");
          }
          loadContract();
        } else {
          Alert.error(r.data.message);
        }
      })
      .catch((e) => console.error(e))
      .finally(() => setIsRunning(false));
  }

  function setFollowupStatus(followUpType, isComplete) {
    api
      .post("contract/SetFollowupStatus", {
        id: routeContractId,
        followUpType: followUpType,
        complete: isComplete,
      })
      .then((r) => {
        if (r.data.success) {
          loadContract();
        } else {
          Alert.error(r.data.message);
        }
      })
      .catch((e) => console.error(e));
  }

  function resolveRecipientColor(recipientType) {
    return linkRecipientType === recipientType ? "primary" : "secondary";
  }

  function resolvedHeight() {
    let heightDsc = "";
    if (
      !contract ||
      (!contract.unitHeightFeet && !contract.unitHeightFeetOtherText)
    )
      return heightDsc;
    if (contract.unitHeightFeetOtherText) {
      return ` x ${contract.unitHeightFeetOtherText}' H`;
    }
    return ` x ${contract.unitHeightFeet}' H`;
  }

  function canDeletePayment(typeOfPayment, processedAt) {
    return isSysAdmin
      || isCompanyAdmin
      || ((isDealerAdmin || isAContractCreator)
          // ART, 28-Sep-23, non-admins can only edit payments for unprocessed payments
          && !processedAt
          // ART, 15-Feb-23, non-admins can only edit payments for cash sales
          && contractType !== constants.CONTRACT_TYPE.RTO
          // must be an incomplete contract/cash sale
          && (contract.typeOfContractStatus === constants.CONTRACT_STATUS_ID.Draft
            || contract.typeOfContractStatus === constants.CONTRACT_STATUS_ID.Quote
            || contract.typeOfContractStatus === constants.CONTRACT_STATUS_ID.PreVerification
            || contract.typeOfContractStatus === constants.CONTRACT_STATUS_ID.InReview
            || contract.typeOfContractStatus === constants.CONTRACT_STATUS_ID.OnHold
            || contract.typeOfContractStatus === constants.CONTRACT_STATUS_ID.PendingDelivery)
          // not an electronic payment
          && (typeOfPayment === constants.PAYMENT_TYPE_IDS.CHECK
            || typeOfPayment === constants.PAYMENT_TYPE_IDS.MONEY_ORDER
            || typeOfPayment === constants.PAYMENT_TYPE_IDS.CASH
            || typeOfPayment === constants.PAYMENT_TYPE_IDS.COMMITMENT)
        );
  }

  function onAddPayment() {
    if (
      paymentTypeId === constants.PAYMENT_TYPE_IDS.CREDIT_CARD &&
      !selectedCard
    ) {
      Alert.error(
        "You must first provide a credit card in order to charge a credit card."
      );
      return;
    }
    if (
      paymentTypeId === constants.PAYMENT_TYPE_IDS.ACH_DEBIT &&
      !selectedBankAccount
    ) {
      Alert.error("You must first provide a valid bank account.");
      return;
    }
    let ccId = null;
    if (paymentTypeId === constants.PAYMENT_TYPE_IDS.CREDIT_CARD) {
      ccId = selectedCard.value;
    } else if (paymentTypeId === constants.PAYMENT_TYPE_IDS.ACH_DEBIT) {
      ccId = selectedBankAccount.value;
    }
    setIsRunning(true);
    api
      .post("contract/AddManualPayment", {
        contractId: routeContractId,
        ccId: ccId,
        typeOfPayment: paymentTypeId,
        identifier: paymentIdentifier,
        amount: paymentAmount,
        comment: paymentComment,
        otherAmountCaption: paymentOtherAmountCaption,
        addComment: contractType === constants.CONTRACT_TYPE.CASH_SALE
      })
      .then((r) => {
        if (r.data.success) {
          setPaymentTypeId(null);
          setPaymentIdentifier("");
          setPaymentAmount(0);
          setPaymentComment("");
          setPaymentOtherAmountCaption("");
          loadContract();
        } else {
          Alert.error(r.data.message);
        }
      })
      .catch((e) => console.error(e))
      .finally(() => setIsRunning(false));
  }

  //ACH Information
  const validatedRoutingNumber = (routeNumber) => {
    api
      .fetch(`Contract/ValidateRoutingNumber/${routeNumber}/${routeContractId}`)
      .then((r) => {
        if (!r.data) return;
        // if(r.data.name == null) {
        //   setRoutingNumberIsInValid(true);
        //   setRoutingNumberIsValid(false);
        // } else {
        //   setRoutingNumberIsValid(true);
        //   setRoutingNumberIsInValid(false);
        // }
        setBankInformation(r.data);
      });
  };

  const onAchPaymentChange = (fieldName, fieldValue) => {
    let newPayment = Object.assign({}, achPayment);
    let newValidation = Object.assign({}, achValidation);
    if (fieldName === "accountType") {
      newPayment.accountType = fieldValue.value;
      newPayment.entryClass = fieldValue.entryClass;
    } else if (fieldName === "routeNumber") {
      newPayment.routeNumber = fieldValue;
    } else {
      newPayment[fieldName] = fieldValue;
    }
    validateAch(newPayment);
    setAchPayment(newPayment);
    setAchValidation(newValidation);
  };

  const validateAch = (vAch) => {
    let invalidAch = Object.assign({}, achValidation);
    let warnings = [];
    if (!vAch.routeNumber.length) {
      warnings.push("Please correct your routing number before continuing.");
      invalidAch.routingNumber = true;
    } else {
      invalidAch.routingNumber = false;
    }
    if (!vAch.name.length) {
      warnings.push("Please enter the account holders first name.");
      invalidAch.name = true;
    } else {
      invalidAch.name = false;
    }
    if (!vAch.accountType) {
      warnings.push("Please select an account type.");
      invalidAch.accountType = true;
    } else {
      invalidAch.accountType = false;
    }
    if (!vAch.accountNumber) {
      warnings.push("Please enter an account number.");
      invalidAch.accountNumber = true;
    } else {
      invalidAch.accountNumber = false;
    }

    setAchValidation(invalidAch);
    let result = Object.keys(invalidAch).some((k) => invalidAch[k] === true);
    if (result === true) {
      setShowAchValidation(true);
      return false;
    } else {
      setShowAchValidation(false);
      return true;
    }
  };

  function onDeletePayment(p) {
    api
      .post("contract/DeletePayment", { id: p.id })
      .then((r) => {
        if (r.data.success) {
          loadContract();
        } else {
          Alert.error(r.data.message);
        }
      })
      .catch((e) => console.error(e));
  }

  function swipeCreditCardKeydown(event) {
    if (event.which === 13) {
      var ccInfo = new CCSwipeParser(cardSwipe);
      setCardSwipe("");
      if (ccInfo.ErrorMessage) {
        Alert.error(ccInfo.ErrorMessage);
      } else {
        ccInfo.contractId = routeContractId;
        ccInfo.zip = cardZip;
        api
          .post("contract/CC", ccInfo)
          .then((r) => {
            if (r.data.success) {
              setCapturingCard(false);
              loadCCList();
            } else {
              Alert.error(r.data.message);
            }
          })
          .catch((e) => console.error(e));
      }
      ccInfo = null;
      return false;
    }
  }

  function saveManualCard() {
    if (!manualCard || manualCard.ErrorMessage) return;
    manualCard.contractId = routeContractId;
    api
      .post("contract/CC", manualCard)
      .then((r) => {
        if (r.data.success) {
          setCapturingCardManually(false);
          setManualCard(null);
          loadCCList();
        } else {
          Alert.error(r.data.message);
        }
      })
      .catch((e) => console.error(e));
  }

  function saveACH() {
    let payload = Object.assign({}, achPayment);
    payload.companyId = contract.companyId;
    payload.contractId = routeContractId;
    if (!validateAch(payload)) return;
    api
      .post("contract/ach", payload)
      .then((r) => {
        if (r.data.success) {
          setBankInformation(constants.defaultBank);
          setAchPayment(constants.defaultPayment);
          setAchValidation(constants.defaultAchValidation);
          setCapturingACH(false);
          loadACHList();
        } else {
          Alert.error(r.data.message);
        }
      })
      .catch((e) => console.error(e));
  }

  function loadCCList(selectLast = true) {
    api
      .fetch("contract/ContractCCList/" + routeContractId)
      .then((r) => {
        const cardList = _.map(r.data, (x) => ({
          label: x.secureDisplay,
          value: x.id,
        }));
        setCards(cardList);
        if (selectLast) {
          setSelectedCard(cardList[cardList.length - 1]);
        }
      })
      .catch((e) => console.error(e));
  }

  const loadACHList = (selectLast = true) => {
    api
      .fetch(`contract/ContractACHList/${routeContractId}`)
      .then((r) => {
        const bankList = _.map(r.data, (x) => ({
          label: x.secureDisplay,
          value: x.id,
        }));
        setBankAccounts(bankList);
        if (selectLast) {
          setSelectedBankAccount(bankList[bankList.length - 1]);
        }
      })
      .catch((e) => console.error(e));
  };

  const setPermissions = () => {
    const thisCompanyAdmin = ui_helpers.isCompanyAdministrator(currentUser, contract.companyId);
    const canMngContract =
      isSysAdmin ||
      ui_helpers.canManageContracts(
        currentUser,
        contract.companyId,
        contract.dealerId
      );
    const contractCreator =
      isSysAdmin ||
      ui_helpers.canCreateContracts(
        currentUser,
        contract.companyId,
        contract.dealerId
      );
    const isContractOwner =
      isSysAdmin || ui_helpers.isContractOwner(currentUser, contract.companyId);
    const isStoreUser =
      isSysAdmin || ui_helpers.isStoreUser(currentUser, contract.dealerId);
    const isManufacturerUser =
      isSysAdmin ||
      _.some(
        currentUser.roles,
        (ro) =>
          ro.manufacturerId === contract.manufacturerId &&
          (ro.typeOfUserRole === constants.ROLE_IDS.InventoryCreator ||
            ro.typeOfUserRole === constants.ROLE_IDS.InventoryManager ||
            ro.typeOfUserRole === constants.ROLE_IDS.Administrator)
      );
    const canDispatch =
      isSysAdmin ||
      _.some(
        currentUser.roles,
        (ro) =>
          ((ro.manufacturerId === contract.manufacturerId ||
            ro.companyId === contract.rtoCompanyId ||
            ro.companyId === contract.companyId) &&
            ro.typeOfUserRole === constants.ROLE_IDS.Administrator) ||
          ro.typeOfUserRole === constants.ROLE_IDS.Dispatcher ||
          (ro.dealerId === contract.dealerId &&
            ro.typeOfUserRole === constants.ROLE_IDS.Dispatcher) ||
          (ro.companyId === contract.companyId &&
            ro.typeOfUserRole === constants.ROLE_IDS.Dispatcher)
      );
    const isDriver = _.some(currentUser.roles, ro => 
          ((ro.manufacturerId === contract.manufacturerId ||
            ro.companyId === contract.rtoCompanyId ||
            ro.dealerId === contract.dealerId ||
            ro.companyId === contract.companyId) &&
            ro.typeOfUserRole === constants.ROLE_IDS.Driver)
      );
    const canAddComments = isSysAdmin ||
      canMngContract ||
      isManufacturerUser ||
      canDispatch ||
      isStoreUser;
    const canAddPayment = isSysAdmin ||
      thisCompanyAdmin ||
      ui_helpers.isManufacturerAdministrator(currentUser, contract.manufacturerId) ||
      ((isDealerAdmin || contractCreator) &&
        (contract.typeOfContractStatus === constants.CONTRACT_STATUS_ID.PreVerification ||
          contract.typeOfContractStatus === constants.CONTRACT_STATUS_ID.InReview ||
          contract.typeOfContractStatus === constants.CONTRACT_STATUS_ID.OnHold ||
          contract.typeOfContractStatus === constants.CONTRACT_STATUS_ID.PendingDelivery));
    setHasDealerAccess(
      isSysAdmin ||
        ui_helpers.isDealerContractUser(currentUser, contract.dealerId) ||
        currentUser.id === contract.createdByUserId
    );
    setIsCompanyAdmin(thisCompanyAdmin);
    setIsDealerAdmin(ui_helpers.isDealerAdministrator(currentUser, contract.dealerId));
    setCanManageContract(canMngContract);
    setIsAContractCreator(contractCreator);
    setIsManufacturerUser(isManufacturerUser);
    setCanDispatch(canDispatch);
    setCanAddComments(canAddComments);
    setIsContractOwner(isContractOwner);
    setCanAddPayment(canAddPayment);
    setIsStoreUser(isStoreUser);
    setIsDriverOrDispatcher(canDispatch || isDriver);
  };

  function commitEditPaymentComment(paymentId) {
    let list = paymentList.slice();
    let p = _.find(list, (x) => x.id === paymentId);
    api
      .post("contract/savepaymentcomment", {
        id: paymentId,
        comment: p.comment,
      })
      .then((r) => {
        if (r.data.success) {
          p.editing = false;
          setPaymentList(list);
        } else {
          Alert.error(r.data.message);
        }
      })
      .catch((e) => console.error(e));
  }

  function editPaymentCommentKeyPress(paymentId, e) {
    if (e.keyCode === 13) {
      // ENTER
      commitEditPaymentComment(paymentId);
    } else if (e.keyCode === 27) {
      // ESC
      setPaymentEditMode(paymentId, false);
    }
  }

  function setEditPaymentComment(paymentId, cmt) {
    let list = paymentList.slice();
    let p = _.find(list, (x) => x.id === paymentId);
    p.comment = cmt;
    setPaymentList(list);
  }

  function setPaymentEditMode(paymentId, on = true) {
    let list = paymentList.slice();
    let p = _.find(list, (x) => x.id === paymentId);
    if (on) {
      p.pristine = p.comment;
      p.editing = true;
    } else {
      p.comment = p.pristine;
      delete p.editing;
      delete p.pristine;
    }
    setPaymentList(list);
  }

  function onShowMobileLink() {
    if (contract.customerPhone1) {
      setOtherRecipient(ui_helpers.formatPhoneField(contract.customerPhone1));
      setLinkRecipientType(RECIPIENT_TYPE.OTHER);
    }
    setShowLinkModal(true);
  }

  function onCompletePreverification() {
    api
      .post("contract/CompletePreverification", {
        id: routeContractId,
        salespersonName:
          contract.salesperson &&
          contract.salesperson.value !== constants.OTHER_ID
            ? contract.salesperson.label
            : contract.otherSalesperson,
        deliveryBuildingOrientation: contract.deliveryBuildingOrientation,
      })
      .then((r) => {
        if (r.data.success) {
          loadContract();
        } else {
          Alert.error(r.data.message);
        }
      })
      .catch((e) => console.error(e));
  }

  function onDeleteSalesperson() {
    if (!contract.salesperson) return;
    confirmAlert({
      title: "Confirm Deletion",
      message: `Are you sure you wish to delete ${contract.salesperson.label}?`,
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            api
              .post("contract/DeleteSalesperson", {
                id: contract.salesperson.value,
              })
              .then((r) => {
                setContract({ ...contract, salesperson: null });
                setSalespeople(
                  _.reject(
                    salespeople,
                    (p) => p.value === contract.salesperson.value
                  )
                );
              })
              .catch((e) => console.error(e));
          },
        },
        {
          label: "No",
        },
      ],
    });
  }

  function deactivateContractCode() {
    api.post(`contract/DeactivateContractCode/${routeContractId}`, { id: routeContractId }).then((r) => {
      if (r.data.success) {
        loadContract();
        Alert.success("The contract code has been deactivated.");
      } else {
        Alert.error(r.data.message);
      }
    })
    .catch((e) => console.error(e));
  }

  function clearAgreedPricing() {
    api.post("contract/ClearAgreedPricing", { id: routeContractId }).then((r) => {
      if (r.data.success) {
        loadContract();
      } else {
        Alert.error(r.data.message);
      }
    }).catch((e) => console.error(e));
  }

  if (redirectTo) {
    return <Redirect to={redirectTo} />;
  }

  const unitPriceWithAdjustmentsString = ui_helpers.formatCurrency(
    contract.unitPrice + contract.sumOfPriceAdjustments
  );

  return (
    <div>
      <Header
        agreementNumber={contract ? contract.agreementNumber : ""}
        pos
        toolbar
      >
        {pendingSwitchToType ? (
          <Modal
            fullscreen="sm"
            toggle={() => setPendingSwitchToType("")}
            isOpen
          >
            <ModalHeader>Are you sure you wish to proceed?</ModalHeader>
            <ModalBody>
              This action will cancel this{" "}
              {contractType === constants.CONTRACT_TYPE.RTO
                ? "RTO Contract"
                : "Cash Sale"}{" "}
              and start a new{" "}
              <span style={{ textDecoration: "underline" }}>
                {pendingSwitchToType === constants.CONTRACT_TYPE.RTO
                  ? "RTO Contract"
                  : "Cash Sale"}
              </span>{" "}
              draft open for editing. Customer details, unit information,
              payments and comments will be transferred to the new draft. New
              customer signatures will be required.
            </ModalBody>
            <ModalFooter>
              <Row className={"d-flex justify-content-end"}>
                <Col>
                  <ButtonGroup>
                    <Button color="primary" onClick={switchContractType}>
                      Yes, proceed and convert
                    </Button>
                    <Button onClick={() => setPendingSwitchToType("")}>
                      No, cancel this action
                    </Button>
                  </ButtonGroup>
                </Col>
              </Row>
            </ModalFooter>
          </Modal>
        ) : null}
        {promptingUploadAttachmentTypeId ? (
          <Modal
            fullscreen="sm"
            toggle={() => setPromptingUploadAttachmentTypeId(null)}
            isOpen
          >
            <ModalHeader>Provide your file here</ModalHeader>
            <ModalBody>
              {message ? (
                <RSAlert color={message.color} className="mb-0">
                  {message.errorMessage}
                </RSAlert>
              ) : null}
              <Dropzone
                onDrop={(fileList) => setFiles([fileList[0]])}
                accept={[
                  "image/jpg",
                  "image/jpeg",
                  "image/png",
                  "application/pdf",
                ]}
                style={{
                  width: "100%",
                  backgroundColor: "#F0F0F0",
                  borderRadius: "6px",
                }}
              >
                {({ getRootProps, getInputProps }) => (
                  <div {...getRootProps()} className="text-center">
                    <input {...getInputProps()} />
                    <div style={{ textAlign: "center" }} className="pt-3">
                      <div className="mb-1">
                        Drop file here or click the icon
                      </div>
                      <FontAwesomeIcon
                        icon={files.length ? "check-square" : "file-upload"}
                        size="3x"
                        className="mb-4"
                      />
                    </div>
                  </div>
                )}
              </Dropzone>
              {files.length ? (
                <Button
                  color="primary"
                  disabled={clickedUpload}
                  className="float-end py-2 text-light"
                  onClick={commitUpload}
                >
                  Save
                </Button>
              ) : null}
            </ModalBody>
            <ModalFooter>
              <Row className={"d-flex justify-content-end"}>
                <Col>
                  <Button
                    onClick={() => setPromptingUploadAttachmentTypeId(null)}
                  >
                    Cancel
                  </Button>
                </Col>
              </Row>
            </ModalFooter>
          </Modal>
        ) : null}
        {processMessage ? (
          <Modal isOpen centered>
            <ModalHeader>Confirm Payment</ModalHeader>
            <ModalBody>{processMessage}</ModalBody>
            <ModalFooter>
              <Row>
                <ButtonGroup>
                  <Button
                    className={"bg-success border-success"}
                    onClick={() => setProcessMessage(null)}
                  >
                    OK
                  </Button>
                </ButtonGroup>
              </Row>
            </ModalFooter>
          </Modal>
        ) : null}
        {showSerialModal ? (
          <SetSerialModal
            serialNumber={serialNum}
            setSerialNumber={setSerialNum}
            setShowSerialModal={setShowSerialModal}
            changeSerialNumber={changeSerialNumber}
          />
        ) : null}
        {showDeliveryModal ? (
          <Modal
            fullscreen="sm"
            size="lg"
            toggle={() => {
              setDeliverOn(moment(contract.estimatedDeliveryDate));
              setShowDeliveryModal(false);
            }}
            isOpen
          >
            <ModalHeader>Assign a Driver</ModalHeader>
            <ModalBody style={{ paddingBottom: 0 }}>
              <Row className="mb-1">
                <Col sm="4">Contract Id:</Col>
                <Col sm="6">{routeContractId}</Col>
              </Row>
              <Row className="mb-1">
                <Col sm="4">Customer Name:</Col>
                <Col sm="6">
                  {contract.customerFirstName} {contract.customerLastName}
                </Col>
              </Row>
              <Row className="mb-1">
                <Col sm="4" className="pt-1">
                  Delivery Date &amp; Time:
                </Col>
                <Col sm="6">
                  <div className="datePickerDiv">
                    <DatePicker
                      selected={deliverOn ? deliverOn.toDate() : null}
                      onChange={(date) => setDeliverOn(moment(date))}
                      showMonthDropdown
                      showYearDropdown
                      isClearable
                      withPortal
                      dropdownMode="select"
                    />
                  </div>
                  <div
                    style={{
                      width: "130px",
                      marginLeft: "30px",
                      float: "left",
                    }}
                  >
                    <Select
                      options={timeList}
                      value={_.find(timeList, (x) => x.value === deliverAt)}
                      onChange={(x) => setDeliverAt(x.value)}
                    />
                  </div>
                </Col>
              </Row>
              <Row className="mb-1">
                <Col sm="4" className="pt-1">
                  Select a Driver:
                </Col>
                <Col sm="6">
                  <Select
                    options={driverUserList}
                    value={_.find(driverUserList, (x) => x.value === driverId)}
                    onChange={(x) => setDriverId(x.value)}
                  />
                </Col>
              </Row>
            </ModalBody>
            <ModalFooter>
              <Row className={"d-flex justify-content-end"}>
                <Col>
                  <ButtonGroup className="float-end">
                    <Button color="primary" onClick={onAssignDelivery}>
                      Save
                    </Button>
                    <Button onClick={() => setShowDeliveryModal(false)}>
                      Close
                    </Button>
                  </ButtonGroup>
                </Col>
              </Row>
            </ModalFooter>
          </Modal>
        ) : null}
        {showModelModal ? (
          <ModelModal
            modelNumber={modelNumber}
            changeModelNumber={changeModelNumber}
            setShowModelModal={setShowModelModal}
            setModelNumber={setModelNumber}
          />
        ) : null}
        {showCommentModal ? (
          <Modal
            autoFocus={false}
            fullscreen="sm"
            toggle={() => setShowCommentModal(false)}
            isOpen
          >
            <ModalHeader>Add Comment</ModalHeader>
            <ModalBody>
              <Input
                type="textarea"
                autoFocus={true}
                className="comment-textarea"
                maxLength="800"
                value={comment}
                onChange={(e) => setComment(e.target.value)}
              />
              {ui_helpers.isContextCompanyUser(
                currentUser,
                contract.companyId
              ) && (
                <div
                  className="mb-1"
                  style={{ cursor: "pointer" }}
                  onClick={(e) => {
                    e.stopPropagation();
                    setCommentPrivate(!commentPrivate);
                  }}
                >
                  <Input
                    type="checkbox"
                    checked={commentPrivate}
                    onChange={(e) => {
                      e.stopPropagation();
                      setCommentPrivate(!commentPrivate);
                    }}
                  />{" "}
                  Private to {contract.companyName}
                </div>
              )}
            </ModalBody>
            <ModalFooter>
              <Row className={"d-flex justify-content-end"}>
                <Col>
                  <ButtonGroup className="float-end">
                    <Button color="primary" onClick={submitComment}>
                      Save
                    </Button>
                    <Button onClick={() => setShowCommentModal(false)}>
                      Close
                    </Button>
                  </ButtonGroup>
                </Col>
              </Row>
            </ModalFooter>
          </Modal>
        ) : null}
        {showCancelModal ? (
          <Modal
            autoFocus={false}
            fullscreen="sm"
            toggle={() => setShowCancelModal(false)}
            isOpen
          >
            <ModalHeader>Provide a cancel reason</ModalHeader>
            <ModalBody>
              <Input
                type="textarea"
                autoFocus={true}
                maxLength="800"
                value={comment}
                onChange={(e) => setComment(e.target.value)}
              />
            </ModalBody>
            <ModalFooter>
              <Row className={"d-flex justify-content-end"}>
                <Col>
                  <ButtonGroup className="float-end">
                    <Button color="primary" onClick={commitCancelContract}>
                      Save
                    </Button>
                    <Button onClick={() => setShowCancelModal(false)}>
                      Close
                    </Button>
                  </ButtonGroup>
                </Col>
              </Row>
            </ModalFooter>
          </Modal>
        ) : null}
        {showHoldModal ? (
          <Modal
            autoFocus={false}
            fullscreen="sm"
            toggle={() => setShowHoldModal(false)}
            isOpen
          >
            <ModalHeader>Provide a hold reason</ModalHeader>
            <ModalBody>
              <Input
                type="textarea"
                autoFocus={true}
                maxLength="800"
                value={comment}
                onChange={(e) => setComment(e.target.value)}
              />
            </ModalBody>
            <ModalFooter>
              <Row className={"d-flex justify-content-end"}>
                <Col>
                  <ButtonGroup className="float-end">
                    <Button color="primary" onClick={commitHoldContract}>
                      Save
                    </Button>
                    <Button onClick={() => setShowHoldModal(false)}>
                      Close
                    </Button>
                  </ButtonGroup>
                </Col>
              </Row>
            </ModalFooter>
          </Modal>
        ) : null}
        {showRecurringModal ? (
          <RecurringPaymentModal
            recurringData={{
              paymentDayOfMonth: contract.paymentDueDayOfMonth,
              recurringBillingPhone: contract.customerPhone1,
              recurringBillingAddress: contract.billingAddress1,
              recurringBillingCity: contract.billingCity,
              recurringBillingState: contract.billingState,
              recurringBillingZip: contract.billingZip,
            }}
            onCancel={() => setShowRecurringModal(false)}
            onSetRecurringData={onSetRecurringData}
          />
        ) : null}
        {showLinkModal ? (
          <Modal
            fullscreen="sm"
            size="lg"
            toggle={() => setShowLinkModal(false)}
            isOpen
          >
            <ModalHeader>Send Mobile Link</ModalHeader>
            <ModalBody>
              <Row>
                <Col xs="6">
                  <p>Receive signature for:</p>
                  <div id="send-restricted-link-mobile-visibility-types">
                    {/* {attachmentAndMilestoneList.some(attach => ["Contract", "Cash Sale Invoice"].includes(attach.name) && attach.attachmentId != 0) && ( */}
                      <label
                        className="form-control"
                        style={{ cursor: "pointer", fontSize: "0.8rem", backgroundColor: "lightblue", fontWeight: "bold" }}
                      >
                      <input
                        type="radio"
                        name="visibility-type"
                        checked={isNewOptionSelected}
                        value={contractPasscode?.typeOfMobileVisibility}
                        onChange={(e) => {
                          setLinkVisibilityTypeId(contractPasscode?.typeOfMobileVisibility);
                          setIsContractSignatureCaptureSelected(true);
                          setIsNewOptionSelected(true);
                        }}
                        autoComplete="off"
                      />{" "}
                        {contractType === constants.CONTRACT_TYPE.CASH_SALE 
                        ? "NEW! Cash Sale Signature Capture"
                        : "NEW! Contract Signature Capture"}
                      </label>
                    {/* )} */}
                    {_.map(linkVisibilityList(contractType), (v) => (
                      <label
                        key={v.id}
                        className="form-control"
                        style={{ cursor: "pointer", fontSize: "0.8rem" }}
                      >
                        <input
                          type="radio"
                          name="visibility-type"
                          checked={v.id === linkVisibilityTypeId && !isNewOptionSelected}
                          onChange={(e) => {
                            setLinkVisibilityTypeId(v.id);
                            setIsNewOptionSelected(false);
                            setIsContractSignatureCaptureSelected(false);}}
                          value={v.id}
                          autoComplete="off"
                        />{" "}
                        {v.name}
                      </label>
                    ))}
                  </div>
                    {contractPasscode && contractPasscode?.deactivatedAt == null && (
                    <div style={{fontWeight: "bold", marginTop: "12px", display: "flex", justifyContent: "center", alignItems: "center", gap: 10, fontSize: 18}}>
                      {date_helpers.dateTimeFormat(contractPasscode?.expiresAt) <= date_helpers.dateTimeFormat(moment()) 
                        ? "Code Expired"
                        : (
                        <>
                        CONTRACT CODE:
                        <span style={{color: "yellow", fontSize: 30, letterSpacing: "0.15em", backgroundColor: "black", paddingLeft: 5, borderRadius: 5}}>
                          {contractPasscode?.signatureAccessToken}
                        </span>
                        </>
                      )}
                    </div>
                  )}
                </Col>
                <Col xs="6">
                  <p>To recipient(s):</p>
                  <ButtonGroup vertical className="w-100">
                    <Button
                      block
                      color={resolveRecipientColor(RECIPIENT_TYPE.DEALER_USERS)}
                      onClick={() =>
                        setLinkRecipientType(RECIPIENT_TYPE.DEALER_USERS)
                      }
                    >
                      Related Dealer Users
                    </Button>
                    {contract.customerEmail ? (
                      <Button
                        block
                        color={resolveRecipientColor(RECIPIENT_TYPE.CUSTOMER)}
                        onClick={() =>
                          setLinkRecipientType(RECIPIENT_TYPE.CUSTOMER)
                        }
                      >
                        Customer ({contract.customerEmail})
                      </Button>
                    ) : null}
                    <Button
                      block
                      color={resolveRecipientColor(RECIPIENT_TYPE.OTHER)}
                      onClick={() => setLinkRecipientType(RECIPIENT_TYPE.OTHER)}
                    >
                      Click to enter Phone #
                    </Button>
                  </ButtonGroup>
                  {linkRecipientType === RECIPIENT_TYPE.OTHER ? (
                    <Input
                      type="text"
                      maxLength="150"
                      className="mt-2"
                      placeholder="Recipient Email/Mobile #"
                      value={otherRecipient}
                      onChange={(e) => setOtherRecipient(e.target.value)}
                    />
                  ) : null}
                </Col>
              </Row>
            </ModalBody>
            <ModalFooter>
              <Row className={"d-flex justify-content-end"}>
                <Col>
                  <ButtonGroup className="float-end">
                    <Button color="primary" onClick={() => {
                      if (isContractSignatureCaptureSelected) {
                        onSendMobileSignatureCapture();
                      } else {
                        onSendMobileLink();
                      }
                      }}>
                      Send Mobile Link
                    </Button>
                    <Button onClick={() => setShowLinkModal(false)}>
                      Close
                    </Button>
                  </ButtonGroup>
                </Col>
              </Row>
            </ModalFooter>
          </Modal>
        ) : null}
        <div className="d-flex justify-content-between">
          <div>
            {contractType === constants.QUOTE_STRING || contract.typeOfContractStatus === constants.CONTRACT_STATUS_ID.PreVerification
              ? null
              : (<Button color="dark" onClick={onShowMobileLink}>
                  Send Mobile Link
                </Button>)
            }
            {contract.typeOfContractStatus === constants.CONTRACT_STATUS_ID.Draft && hasDealerAccess ? (
              <Button
                color="light"
                onClick={cancelContract}
                className="text-light bg-cf-red"
              >
                <FontAwesomeIcon icon="times-circle" /> Cancel
              </Button>
            ) : null}
            {isRunning ? null : (
              <Button color="light" onClick={onClone}>
                Clone
              </Button>
            )}
            {contract.typeOfContractStatus !== constants.CONTRACT_STATUS_ID.Processed &&
            (isSysAdmin || canManageContract || hasDealerAccess) ? (
              <ButtonDropdown
                color="info"
                isOpen={switchMenuOpen}
                toggle={() => setSwitchMenuOpen(!switchMenuOpen)}
              >
                <DropdownToggle caret>
                  <FontAwesomeIcon icon="sync" /> Cancel &amp; convert to...
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem
                    onClick={() => setPendingSwitchToType(constants.SEARCH_AREAS.CASH_SALE)}
                  >
                    Cash Sale
                  </DropdownItem>
                  <DropdownItem
                    onClick={() => setPendingSwitchToType(constants.SEARCH_AREAS.RTO)}
                  >
                    RTO Contract
                  </DropdownItem>
                </DropdownMenu>
              </ButtonDropdown>
            ) : null}
            {isSysAdmin ||
            (isContractOwner && (canManageContract || contract.typeOfContractStatus === constants.CONTRACT_STATUS_ID.PreVerification)) ||
            (hasDealerAccess && (contract.typeOfContractStatus === constants.CONTRACT_STATUS_ID.Draft || contract.typeOfContractStatus === constants.CONTRACT_STATUS_ID.PreVerification))
            ? (
              <Button
                color="warning"
                tag={Link}
                to={
                  (contractType === constants.CONTRACT_TYPE.RTO
                    ? constants.PATH_NAMES.CONTRACT_EDIT
                    : contractType === constants.QUOTE_STRING
                    ? constants.PATH_NAMES.QUOTE_EDIT
                    : constants.PATH_NAMES.CASH_SALE_EDIT) +
                  "/" +
                  routeContractId
                }
              >
                Edit
              </Button>
            ) : null}
            {canDispatch ? (
              <Button
                color="primary"
                onClick={() => {
                  setDeliverOn(
                    contract.estimatedDeliveryDate
                      ? moment(contract.estimatedDeliveryDate)
                      : moment()
                  );
                  setDeliverAt(contract.deliveryTime);
                  setDriverId(contract.assignedDriverUserId);
                  setShowDeliveryModal(true);
                }}
              >
                Assign Delivery
              </Button>
            ) : null}
          </div>
          {contract && canManageContract && isContractOwner ? (
            <div className="d-flex border-dark rounded">
              <ButtonDropdown
                color="light"
                className="me-2 rounded border-dark btn-ghost"
                isOpen={wrenchMenuOpen}
                toggle={() => setWrenchMenuOpen(!wrenchMenuOpen)}
              >
                <DropdownToggle caret className={"rounded"}>
                  <FontAwesomeIcon icon="wrench" />
                </DropdownToggle>
                <DropdownMenu>
                  {contract.typeOfContractStatus === constants.CONTRACT_STATUS_ID.Cancelled ? (
                    <DropdownItem
                      onClick={() =>
                        simpleContractOperation("contract/ReviveContract")
                      }
                    >
                      Revive
                    </DropdownItem>
                  ) : (
                    <DropdownItem onClick={cancelContract}>Cancel</DropdownItem>
                  )}
                  {contract.typeOfContractStatus === constants.CONTRACT_STATUS_ID.Draft ? (
                    <DropdownItem onClick={deleteContract}>Delete</DropdownItem>
                  ) : null}
                  {contract.typeOfContractStatus === constants.CONTRACT_STATUS_ID.Processed ? (
                    <DropdownItem
                      onClick={() =>
                        simpleContractOperation("contract/UnprocessContract")
                      }
                    >
                      Un-Process
                    </DropdownItem>
                  ) : null}
                  {(contract.typeOfContractStatus !== constants.CONTRACT_STATUS_ID.Processed
                    && contract.typeOfContractStatus !== constants.CONTRACT_STATUS_ID.Draft
                    && contract.typeOfContractStatus !== constants.CONTRACT_STATUS_ID.Quote
                    && contract.typeOfContractStatus !== constants.CONTRACT_STATUS_ID.Cancelled
                    && contract.typeOfContractStatus !== constants.CONTRACT_STATUS_ID.PreVerification) ? (
                    <DropdownItem onClick={processContract}>
                      Process
                    </DropdownItem>
                  ) : null}
                  {contract.typeOfContractStatus === constants.CONTRACT_STATUS_ID.InReview ? (
                    <DropdownItem onClick={holdContract}>
                      Set On Hold
                    </DropdownItem>
                  ) : null}
                  {contract.typeOfContractStatus === constants.CONTRACT_STATUS_ID.InReview ||
                  contract.typeOfContractStatus === constants.CONTRACT_STATUS_ID.OnHold ? (
                    <DropdownItem
                      onClick={() =>
                        simpleContractOperation("contract/ApproveContract")
                      }
                    >
                      Approve
                    </DropdownItem>
                  ) : null}
                </DropdownMenu>
              </ButtonDropdown>
              <div className="followup-icons bg-light d-flex align-items-center rounded">
                <FollowUpButtons
                  followUpItem={constants.FOLLOW_UP.RTO}
                  followUpStatusCallback={setFollowupStatus}
                  done={contract ? contract.followupStatus_LoadedToRTO : null}
                />
                <FollowUpButtons
                  followUpItem={constants.FOLLOW_UP.CALL}
                  followUpStatusCallback={setFollowupStatus}
                  done={contract ? contract.followupStatus_CourtesyCall : null}
                />
                {contract && contract.followupStatus_Commission != null ? (
                  <FollowUpButtons
                    followUpItem={constants.FOLLOW_UP.COMMISSION}
                    followUpStatusCallback={setFollowupStatus}
                    done={contract ? contract.followupStatus_Commission : null}
                  />
                ) : null }
                <FollowUpButtons
                  followUpItem={constants.FOLLOW_UP.HOLD}
                  followUpStatusCallback={setFollowupStatus}
                  done={contract ? contract.followupStatus_OnHold : null}
                />
              </div>
            </div>
          ) : null}
        </div>
      </Header>
      {isLoading && contract ? (
        <Loading />
      ) : (
        <Container className="mt-3">
          <Row>
            <Col xs={3} className="pt-1">
              <h5>
                {contract.agreementNumber === "Cash Sale" ? (
                  <span>Cash Sale # {routeContractId}</span>
                ) : (
                  <span>
                    Agreement #{" "}
                    <span className="text-primary">
                      {contract.agreementNumber}
                    </span>
                  </span>
                )}
              </h5>
            </Col>
            <Col xs={7} className="pt-1" style={{ textAlign: "center" }}>
              <h5>
                Sold by {contract.dealerName}{" "}
                <span className="text-info">
                  ({_.trim(contract.salespersonName)}) on{" "}
                  {date_helpers.formatDateToShortDate(contract.createdAt)}
                </span>
              </h5>
            </Col>
            <Col xs={2}>
              <h4>
                {ui_helpers.renderContractStatusBadge(
                  contract.typeOfContractStatus,
                  "float-end"
                )}
              </h4>
            </Col>
          </Row>
          <Row className="mt-2">
            <Col>
              {contract.typeOfContractStatus === constants.CONTRACT_STATUS_ID.PreVerification ? (
                <WebSaleVerification
                  buildingOrientations={buildingOrientations}
                  buildingOrientationLabel={
                    contract.deliveryBuildingOrientation
                  }
                  onSetBuildingOrientation={(x) =>
                    setContract({
                      ...contract,
                      deliveryBuildingOrientation: x.label,
                    })
                  }
                  salespeople={salespeople}
                  salesperson={contract.salesperson}
                  otherSalesperson={contract.otherSalesperson}
                  onSetSalesperson={(x) => {
                    let changes = { ...contract };
                    if (typeof x === "object") {
                      changes.salesperson = x;
                      changes.otherSalesperson = "";
                    } else {
                      changes.salesperson = constants.OTHER_OPTION;
                      changes.otherSalesperson = x;
                    }
                    setContract(changes);
                  }}
                  onDeleteSalesperson={onDeleteSalesperson}
                  onCompletePreverification={onCompletePreverification}
                  isRTO={contractType === constants.CONTRACT_TYPE.RTO }
                />
              ) : null}
              <Accordion open={openAccordionItems} toggle={toggleAccordion}>
                <AccordionItem>
                  <AccordionHeader targetId={TABS.CUSTOMER}>
                    <FontAwesomeIcon icon="user" className="me-2" />
                    {contract &&
                      `${contract.customerFirstName} ${contract.customerLastName}`}
                  </AccordionHeader>
                  <AccordionBody accordionId={TABS.CUSTOMER}>
                    <Row>
                      <Col md={6}>
                        <DetailsTable>
                          <DetailsRow
                            heading="First Name"
                            data={contract.customerFirstName}
                          />
                          <DetailsRow
                            heading="Last Name"
                            data={contract.customerLastName}
                          />
                          <DetailsRow
                            heading="Cell Phone"
                            data={ui_helpers.formatPhoneField(
                              contract.customerPhone1
                            )}
                          />
                          <DetailsRow
                            heading="Secondary Phone"
                            data={ui_helpers.formatPhoneField(
                              contract.customerPhone2
                            )}
                          />
                          <DetailsRow
                            heading="Email"
                            data={contract.customerEmail}
                          />
                          <DetailsRow
                            heading="Delivery Address 1"
                            data={contract.deliveryAddress1}
                          />
                          <DetailsRow
                            heading="Delivery Address 2"
                            data={contract.deliveryAddress2}
                          />
                          <DetailsRow
                            heading="Delivery City, State, Zip"
                            data={`${contract.deliveryCity} ${contract.deliveryState}, ${contract.deliveryZip}`}
                          />
                          <DetailsRow
                            heading="Delivery Region"
                            data={contract.regionName}
                          />
                          <DetailsRow
                            heading="Delivery County"
                            data={`${contract.deliveryCounty} (${contract.taxRateDisplay}% tax rate)`}
                          />
                          <DetailsRow
                            heading="In City Limits?"
                            data={contract.isInCityLimits ? "Yes" : "No"}
                          />
                          {contractType === constants.CONTRACT_TYPE.RTO ? (
                            <tr>
                              <th>
                                <span
                                  id="pop1"
                                  onMouseEnter={() =>
                                    setPropertyOwnerPopoverOpen(true)
                                  }
                                  onMouseLeave={() =>
                                    setPropertyOwnerPopoverOpen(false)
                                  }
                                  className="contract-popover"
                                >
                                  Property Owner?
                                </span>
                                <Popover
                                  placement="top"
                                  isOpen={propertyOwnerPopoverOpen}
                                  target="pop1"
                                  toggle={() =>
                                    setPropertyOwnerPopoverOpen(false)
                                  }
                                >
                                  <PopoverBody>
                                    The customer owns the property at which the
                                    unit will reside.
                                  </PopoverBody>
                                </Popover>
                              </th>
                              <td>
                                <Row className="align-items-center">
                                  <Col sm="8">
                                    {contract.isPropertyOwner ? "Yes" : "No"}
                                  </Col>
                                </Row>
                              </td>
                            </tr>
                          ) : null}
                          <DetailsRow
                            heading="Billing Name"
                            data={contract.billingName}
                          />
                          <DetailsRow
                            heading="Billing Address 1"
                            data={contract.billingAddress1}
                          />
                          <DetailsRow
                            heading="Billing Address 2"
                            data={contract.billingAddress2}
                          />
                          <DetailsRow
                            heading="Billing City, State Zip"
                            data={`${contract.billingCity} ${contract.billingState}, ${contract.billingZip}`}
                          />
                          {contractType === constants.CONTRACT_TYPE.RTO ? (
                            <>
                              {_.map(additionalContacts, (ct, idx) => (
                                <Fragment key={`contact-${ct.id}`}>
                                  <DetailsRow
                                    heading={`Additional Contact Name ${
                                      idx + 1
                                    }`}
                                    data={
                                      ct.firstName
                                        ? `${ct.firstName} ${ct.lastName}`
                                        : ""
                                    }
                                  />
                                  <DetailsRow
                                    heading={`Additional Contact Phone ${
                                      idx + 1
                                    }`}
                                    data={
                                      ct.phone &&
                                      ui_helpers.formatPhoneField(ct.phone)
                                    }
                                  />
                                  <DetailsRow
                                    heading={`Additional Contact Email ${
                                      idx + 1
                                    }`}
                                    data={ct.email}
                                  />
                                  <DetailsRow
                                    heading={`Add'l Contact Relationship ${
                                      idx + 1
                                    }`}
                                    data={ct.relationshipToCustomer}
                                  />
                                </Fragment>
                              ))}
                            </>
                          ) : (
                            <DetailsRow
                              heading="Preferred Contract Language"
                              data={contract.cultureName}
                            />
                          )}
                        </DetailsTable>
                      </Col>
                      {contractType === constants.CONTRACT_TYPE.RTO ? (
                        <Col md={6}>
                          <DetailsTable>
                            <tr>
                              <th>SSN</th>
                              <td>
                                <Row className="align-items-center">
                                  <Col sm="8">
                                    {showSSN
                                      ? ui_helpers.formatSSNField(
                                          contract.customerSSN
                                        )
                                      : contract.customerSSNMaskedDisplay}
                                  </Col>
                                  {currentUserIsSysAdminOrCompanyAdmin ? (
                                    <Col>
                                      <Button
                                        size="sm"
                                        color="dark"
                                        className="float-end"
                                        onClick={() => setShowSSN(!showSSN)}
                                      >
                                        {showSSN ? "Hide" : "Show"}
                                      </Button>
                                    </Col>
                                  ) : null}
                                </Row>
                              </td>
                            </tr>
                            <DetailsRow
                              heading="License #"
                              data={contract.customerLicenseNumber}
                            />
                            <DetailsRow
                              heading="Date of Birth"
                              data={date_helpers.formatDateToShortDate(
                                contract.customerDateOfBirth
                              )}
                            />
                            <DetailsRow
                              heading="Employment Type"
                              data={contract.customerEmploymentTypeName}
                            />
                            <DetailsRow
                              heading="Employer Name"
                              data={contract.customerEmployerName}
                            />
                            <DetailsRow
                              heading="Employer Phone"
                              data={contract.customerEmployerPhone}
                            />
                            {contract.corenterFirstName ? (
                              <>
                                <DetailsRow
                                  heading="Corenter First Name"
                                  data={contract.corenterFirstName}
                                />
                                <DetailsRow
                                  heading="Corenter Last Name"
                                  data={contract.corenterLastName}
                                />
                                {contract.corenterDateOfBirth && (
                                  <DetailsRow
                                    heading="Corenter Date Of Birth"
                                    data={
                                      contract.corenterDateOfBirth
                                        ? contract.corenterDateOfBirth
                                        : null
                                    }
                                  />
                                )}
                                {contract.corenterPhone && (
                                  <DetailsRow
                                    heading="Corenter Phone"
                                    data={
                                      contract.corenterPhone &&
                                      ui_helpers.formatPhoneField(
                                        contract.corenterPhone
                                      )
                                    }
                                  />
                                )}
                                {contract.corenterEmail && (
                                  <DetailsRow
                                    heading="Corenter Email"
                                    data={contract.corenterEmail}
                                  />
                                )}
                                {contract.corenderAddress1 && (
                                  <DetailsRow
                                    heading="Corenter Address 1"
                                    data={contract.corenderAddress1}
                                  />
                                )}
                                {contract.corenderAddress2 && (
                                  <DetailsRow
                                    heading="Corenter Address 2"
                                    data={contract.corenterAddress2}
                                  />
                                )}
                                <DetailsRow
                                  heading="Corenter City, State Zip"
                                  data={
                                    contract.corenterFirstName
                                      ? `${contract.corenterCity} ${contract.corenterState}, ${contract.corenterZip}`
                                      : ""
                                  }
                                />
                              </>
                            ) : null}
                            {contract.landownerFirstName ? (
                              <>
                                <DetailsRow
                                  heading="Land Owner First Name"
                                  data={contract.landownerFirstName}
                                />
                                <DetailsRow
                                  heading="Land Owner Last Name"
                                  data={contract.landownerLastName}
                                />
                                {contract.landownerAddress1 && (
                                  <DetailsRow
                                    heading="Land Owner Address 1"
                                    data={contract.landownerAddress1}
                                  />
                                )}
                                {contract.landownerAddress2 && (
                                  <DetailsRow
                                    heading="Land Owner Address 2"
                                    data={contract.landownerAddress2}
                                  />
                                )}
                                {contract.landownerFirstName && (
                                  <DetailsRow
                                    heading="Land Owner City, State, Zip"
                                    data={
                                      contract.landownerFirstName
                                        ? `${contract.landownerCity} ${contract.landownerState}, ${contract.landownerZip}`
                                        : ""
                                    }
                                  />
                                )}
                                {contract.landownerPhone && (
                                  <DetailsRow
                                    heading="Land Owner Phone"
                                    data={
                                      contract.landownerPhone &&
                                      ui_helpers.formatPhoneField(
                                        contract.landownerPhone
                                      )
                                    }
                                  />
                                )}
                                {contract.landownerEmail && (
                                  <DetailsRow
                                    heading="Land Owner Email"
                                    data={contract.landownerEmail}
                                  />
                                )}
                              </>
                            ) : null}
                            <DetailsRow
                              heading="Preferred Contract Language"
                              data={contract.cultureName}
                            />
                          </DetailsTable>
                        </Col>
                      ) : null}
                    </Row>
                  </AccordionBody>
                </AccordionItem>
                <AccordionItem>
                  <AccordionHeader targetId={TABS.UNIT}>
                    <FontAwesomeIcon icon="building" className="me-2" />
                    Unit Information
                  </AccordionHeader>
                  <AccordionBody accordionId={TABS.UNIT}>
                    <Row>
                      <Col md={6}>
                        <DetailsTable>
                          {contractType ===
                          constants.CONTRACT_TYPE.RTO ? null : (
                            <tr>
                              <th style={{ color: "Yellow" }}>Owned By</th>
                              <td>
                                <Row className="align-items-center">
                                  <Col sm="8">{contract.companyName}</Col>
                                </Row>
                              </td>
                            </tr>
                          )}
                          <tr>
                            <th>Serial #</th>
                            <td>
                              <Row className="align-items-center">
                                <Col sm="8">{contract.serialNumber}</Col>
                                {currentUserIsSysAdminOrCompanyAdmin ? (
                                  <Col>
                                    <Button
                                      size="sm"
                                      color="warning"
                                      className="float-end"
                                      onClick={() => {
                                        setSerialNum(contract.serialNumber);
                                        setShowSerialModal(true);
                                      }}
                                    >
                                      Set
                                    </Button>
                                  </Col>
                                ) : null}
                              </Row>
                            </td>
                          </tr>
                          <tr>
                            <th>RTO ID</th>
                            <td>
                              <Row className="align-items-center">
                                <Col sm="8">{contract.resolvedModelNumber}</Col>
                                {currentUserIsSysAdminOrCompanyAdmin ? (
                                  <Col>
                                    <Button
                                      size="sm"
                                      color="warning"
                                      className="float-end"
                                      onClick={() => {
                                        setModelNumber(
                                          contract.resolvedModelNumber
                                        );
                                        setShowModelModal(true);
                                      }}
                                    >
                                      Set
                                    </Button>
                                  </Col>
                                ) : null}
                              </Row>
                            </td>
                          </tr>
                          <DetailsRow
                            heading="Product Type"
                            data={contract.productTypeName}
                          />
                          <DetailsRow
                            heading="New?"
                            data={contract.unitIsNew ? "Yes" : "No"}
                          />
                          <DetailsRow
                            heading="Manufacturer"
                            data={contract.manufacturerName}
                          />
                          <DetailsRow
                              heading='Material'
                              data={contract.unitTypeName}/>
                          <DetailsRow
                            heading="Base Color"
                            data={contract.unitManufacturerBaseColorText}
                          />
                          <DetailsRow
                            heading="Trim Color"
                            data={contract.unitManufacturerTrimColorText}
                          />
                          <DetailsRow
                            heading="Roof Color"
                            data={contract.unitManufacturerRoofColorText}
                          />
                          <DetailsRow
                            heading="Style"
                            data={contract.unitManufacturerSubproductText}
                          />
                          <DetailsRow
                            heading="Dimensions (W x L x H)"
                            data={`${
                              contract.unitWidthFeetOtherText
                                ? contract.unitWidthFeetOtherText
                                : contract.unitWidthFeet
                            }' W x ${
                              contract.unitLengthFeetOtherText
                                ? contract.unitLengthFeetOtherText
                                : contract.unitLengthFeet
                            }' L${resolvedHeight()}`}
                          />
                        </DetailsTable>
                      </Col>
                      <Col md={6}>
                        <DetailsTable>
                          <DetailsRow
                            heading="Related Inventory"
                            data={
                              contract.relatedUnitInventoryInvoiceNo
                                ? contract.relatedUnitInventoryInvoiceNo
                                : "(None)"
                            }
                            url={
                              contract.relatedUnitInventoryInvoiceNo &&
                              contract.userHasPermissionsToRelatedInventory
                                ? `${constants.PATH_NAMES.INVENTORY_VIEW}/${contract.inventoryId}`
                                : null
                            }
                          />
                          <DetailsRow
                            heading="Unit Price"
                            data={unitPriceWithAdjustmentsString}
                          />
                          <tr>
                            <th>Assigned Driver</th>
                            <td>
                              <Row className="align-items-center">
                                <Col sm="6">
                                  {contract.assignedDriverUserFullName}
                                  {contract.assignedDriverUserFullName &&
                                  contract.deliveryTimeDisplay
                                    ? "@" + contract.deliveryTimeDisplay
                                    : null}
                                </Col>
                                {currentUserCanDispatch &&
                                contract.assignedDriverUserFullName ? (
                                  <Col>
                                    <Button
                                      size="sm"
                                      className="float-end bg-cf-red text-light"
                                      onClick={unassignDriver}
                                    >
                                      Unassign Driver
                                    </Button>
                                  </Col>
                                ) : null}
                              </Row>
                            </td>
                          </tr>
                          <tr>
                            <th>Add-Ons</th>
                            <td>
                              <Table size="sm" className="mb-0">
                                <thead>
                                  {addOns && addOns.length ? (
                                    <tr>
                                      <th>Description</th>
                                      <th>Price</th>
                                    </tr>
                                  ) : (
                                    <tr></tr>
                                  )}
                                </thead>
                                <tbody>
                                  {_.map(addOns, (a) => (
                                    <tr key={`ao-${a.id}`}>
                                      <td>
                                        {a.displayNameWithOptionDescription}
                                      </td>
                                      <td>
                                        {a.bundleId
                                          ? "Bundle Item"
                                          : a.totalPrice
                                            ? ui_helpers.formatCurrency(a.totalPrice)
                                            : ui_helpers.formatCurrency(a.price)}
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </Table>
                            </td>
                          </tr>
                          {buildSteps && buildSteps.length
                            ? (<tr>
                                <th>Build Status</th>
                                <td className="px-3">
                                  {_.map(buildSteps, (b) => (
                                    <Row key={`buildStep-${b.id}`} className={b.completedAt ? "bg-success" : ""}>
                                      <Col xs="1">
                                        {b.seq}
                                      </Col>
                                      <Col xs="8">
                                        {b.name}
                                      </Col>
                                      <Col xs="3" className="float-end">
                                        {b.completedAt
                                          ? (<FontAwesomeIcon icon="check" />)
                                          : null
                                        }
                                      </Col>
                                    </Row>))}
                                </td>
                              </tr>)
                            : null
                          }
                        </DetailsTable>
                      </Col>
                    </Row>
                  </AccordionBody>
                </AccordionItem>
                <AccordionItem>
                  <AccordionHeader targetId={TABS.PAYMENT}>
                    <FontAwesomeIcon icon="dollar-sign" className="me-2" />
                    Payment
                  </AccordionHeader>
                  <AccordionBody accordionId={TABS.PAYMENT}>
                    <Row>
                      <Col md={6}>
                        <DetailsTable>
                          <DetailsRow
                            heading="Unit Price"
                            data={unitPriceWithAdjustmentsString}
                          />
                          {contractType === constants.CONTRACT_TYPE.RTO ? (
                            <Fragment>
                              <DetailsRow
                                heading="Purchase Reserve"
                                data={ui_helpers.formatCurrency(
                                  contract.purchaseReserveAmount
                                )}
                              />
                              <DetailsRow
                                heading="Term"
                                data={contract.contractTermName}
                              />
                              <DetailsRow
                                heading="Monthly Payment"
                                data={`${ui_helpers.formatCurrency(
                                  contract.monthlyPaymentAmount
                                )}, due on day ${
                                  contract.paymentDueDayOfMonth
                                } of the month`}
                              />
                            </Fragment>
                          ) : null}
                          {contract.agreedPrice && currentUserIsSysAdminOrCompanyAdmin
                            ? (<tr>
                                <th>Agreed Price @ {date_helpers.dateTimeFormat(contract.agreedPriceAt)}</th>
                                <td>
                                  <Row>
                                    <Col>
                                      <Button
                                        color="danger"
                                        size="sm"
                                        className="text-light"
                                        onClick={clearAgreedPricing}
                                      >
                                        Clear
                                      </Button>                                      
                                    </Col>
                                  </Row>
                                </td>
                              </tr>)
                            : null
                          }
                          <tr>
                            <th>Tax</th>
                            {contractType === constants.CONTRACT_TYPE.RTO ? (
                              <td>
                                <Row className="align-items-center">
                                  <Col>
                                    {contract.taxRateDisplay}% (
                                    {ui_helpers.formatCurrency(contract.monthlyTaxAmount)}{" "}
                                    Monthly)
                                  </Col>
                                  {/*  <Col>*/}
                                  {/*    [Applied {contract.effectiveTaxRateDisplay}%]*/}
                                  {/*  </Col>*/}
                                </Row>
                              </td>
                            ) : (
                              <td>
                                <Row className="align-items-center">
                                  <Col>
                                    {contract.taxRateDisplay}% (
                                    {ui_helpers.formatCurrency(
                                      contract.totalTaxAmount
                                    )}{" "}
                                    Total)
                                  </Col>
                                  {contract.standardTaxRate ? (
                                    <Col>
                                      [{contract.standardTaxRateDisplay}%
                                      Suggested]
                                    </Col>
                                  ) : null}
                                </Row>
                              </td>
                            )}
                          </tr>
                          {contract.priceAdjustments &&
                            _.map(
                              _.filter(
                                contract.priceAdjustments,
                                (pa) => !pa.includeInUnitPrice
                              ),
                              (pa) => {
                                return (
                                  <DetailsRow
                                    key={`pax-${pa.id}`}
                                    numeric
                                    heading={pa.description}
                                    data={ui_helpers.priceAdjustmentCaption(pa)}
                                  />
                                );
                              }
                            )}
                          {contractType === constants.CONTRACT_TYPE.RTO ? (
                            <Fragment>
                              <DetailsRow
                                  heading="LDW"
                                  data={contract.optedOutOfDamageLiabilityWaiver
                                    ? "Opted out ($0)"
                                    : ui_helpers.formatCurrency(contract.damageLiabilityWaiverAmount)}
                              />
                              <DetailsRow
                                heading="Total Monthly Payment"
                                data={ui_helpers.formatCurrency(
                                  contract.totalMonthlyPayment
                                )}
                              />
                              <DetailsRow
                                heading="Total Rental Cost"
                                data={ui_helpers.formatCurrency(
                                  contract.rentalCostAmount
                                )}
                              />
                              <DetailsRow
                                heading="Minimum Security Deposit"
                                data={ui_helpers.formatCurrency(
                                  contract.minimumSecurityDepositAmount
                                )}
                              />
                              <DetailsRow
                                heading="Required For Delivery"
                                data={ui_helpers.formatCurrency(
                                  contract.dueForDeliveryAmount
                                )}
                              />
                            </Fragment>
                          ) : null}
                        </DetailsTable>
                        {contractType !== constants.CONTRACT_TYPE.RTO && 
                        (contract.sumOfPriceAdjustments || contract.priceAdjustments?.length > 0) ? (
                          <PriceSummaryTable
                            unitPrice={contract.unitPrice}
                            priceAdjustments={contract.priceAdjustments}
                            unitPriceWithAdjustmentsString={unitPriceWithAdjustmentsString}
                          />
                        ) : null}
                      </Col>
                      <Col md={6}>
                        <DetailsTable>
                          <DetailsRow
                            heading="Estimated Delivery Date"
                            data={
                              contract.estimatedDeliveryDate
                                ? `${date_helpers.formatDateToShortDate(
                                    contract.estimatedDeliveryDate
                                  )} ${contract.deliveryTimeDisplay}`
                                : ""
                            }
                          />
                          {contractType === constants.CONTRACT_TYPE.RTO ? (
                            <Fragment>
                              <DetailsRow
                                heading="Paperless Billing Email"
                                data={contract.paperlessBillingEmail}
                              />
                              <tr>                             
                                <th>Enable Recurring Payments</th>
                                <td>
                                  {contract.enableRecurringPayments &&
                                  contract.hasRecurringPaymentData
                                    ? ui_helpers.greenCheck()
                                    : "No"}
                                  {canManageContract ? (
                                    <ButtonGroup className="float-end">
                                      {contract.enableRecurringPayments &&
                                      contract.hasRecurringPaymentData ? (
                                        <Fragment>
                                          <Button
                                            color="info"
                                            size="sm"
                                            className="text-light"
                                            onClick={viewRecurring}
                                          >
                                            View
                                          </Button>
                                          <Button
                                            color="danger"
                                            size="sm"
                                            className="text-light"
                                            onClick={clearRecurring}
                                          >
                                            Clear
                                          </Button>
                                        </Fragment>
                                      ) : null}
                                      <Button
                                        color="warning"
                                        size="sm"
                                        className="text-light"
                                        onClick={() =>
                                          setShowRecurringModal(true)
                                        }
                                      >
                                        Set
                                      </Button>
                                    </ButtonGroup>
                                  ) : null}
                                  {(!contract.enableRecurringPayments ||
                                    !contract.hasRecurringPaymentData) &&
                                  hasDealerAccess &&
                                  !canManageContract ? (
                                    <Button
                                      color="warning"
                                      size="sm"
                                      className="text-light float-end"
                                      onClick={() =>
                                        setShowRecurringModal(true)
                                      }
                                    >
                                      Set
                                    </Button>
                                  ) : null}
                                </td>
                              </tr>
                              <DetailsRow
                                heading="Promotion"
                                data={contract.promotionName}
                              />
                              {contract.sac ? (
                                <DetailsRow
                                  heading="90 Days Same As Cash"
                                  data={
                                    <span className="text-success">
                                      <FontAwesomeIcon icon="check" />
                                    </span>
                                  }
                                />
                              ) : null}
                            </Fragment>
                          ) : null}
                          <DetailsRow
                            heading="Initial Payment Amount"
                            data={ui_helpers.formatCurrency(
                              contract.initialPaymentAmount
                            )}
                          />
                          <DetailsRow
                            heading="Is Tax Exempt"
                            data={contract.isTaxExempt ? "Yes" : "No"}/>
                          {contractType ===
                          constants.CONTRACT_TYPE.RTO ? null : (
                            <DetailsRow
                              heading="Balance Due"
                              data={ui_helpers.formatCurrency(
                                contract.balanceDue
                              )}
                            />
                          )}
                        </DetailsTable>
                        {contractType === constants.CONTRACT_TYPE.RTO &&
                        (contract.sumOfPriceAdjustments || contract.priceAdjustments?.length > 0) ? (
                          <PriceSummaryTable
                            unitPrice={contract.unitPrice}
                            priceAdjustments={contract.priceAdjustments}
                            unitPriceWithAdjustmentsString={
                              unitPriceWithAdjustmentsString
                            }
                          />
                        ) : null}
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <DetailsTable>
                          <tr>
                            <th style={{ width: "165px" }}>Payment Details</th>
                            <td>
                              <Table size="sm">
                                <thead>
                                  <tr>
                                    <th>Amount</th>
                                    <th>Type</th>
                                    <th>Number</th>
                                    <th>Paid At</th>
                                    <th />
                                    <th />
                                  </tr>
                                </thead>
                                <tbody>
                                  {_.map(paymentList, (x) => {
                                    const occurredAt = x.processedAt || x.paidAt;
                                    const pt = _.find(constants.PAYMENT_TYPES, (pt) => pt.value === x.typeOfPayment);
                                    const isDigitalPayment = x.typeOfPayment === constants.PAYMENT_TYPE_IDS.CREDIT_CARD 
                                      || x.typeOfPayment === constants.PAYMENT_TYPE_IDS.ACH_DEBIT
                                      || x.typeOfPayment === constants.PAYMENT_TYPE_IDS.CREDIT_CARD_FEE;
                                    const canEdit = canDeletePayment(x.typeOfPayment, x.processedAt);
                                    return (
                                      <Fragment key={x.id}>
                                      <tr>
                                        <td>{ui_helpers.formatCurrency(x.amount)}</td>
                                        <td>
                                          {pt ? pt.label : ""}
                                          {isDigitalPayment && x.recipientCompanyName
                                            ? (<div className="text-muted" style={{fontStyle: 'italic', fontSize: '0.8rem'}}>Paid to {x.recipientCompanyName}</div>)
                                            : null
                                          }
                                        </td>
                                        <td>{x.identifier}</td>
                                        <td>{date_helpers.shortYearDateTimeFormat(occurredAt)}
                                          {isDigitalPayment && !x.processedAt
                                          ? (<span className="ms-1 text-danger">NOT PROCESSED!</span>)
                                          : null
                                        }
                                        </td>
                                        <td style={{minWidth: "50px", backgroundColor: "#f7f9fa"}} onClick={(e) => {
                                          if (canEdit && e.detail === 2) { // double click only
                                            setPaymentEditMode(x.id);
                                          }
                                        }}>
                                        {x.editing
                                          ? (<Fragment>
                                              <Input type='text' maxLength="50" 
                                                style={{width: "80%", float: "left"}}
                                                placeholder="Comment (optional)"
                                                value={x.comment || ""} 
                                                onKeyDown={(event) => editPaymentCommentKeyPress(x.id, event)}
                                                onChange={(e) => setEditPaymentComment(x.id, e.target.value)}
                                              />
                                              <Button className="text-light xs float-end" title="Cancel Edits" 
                                                color="secondary" onClick={() => setPaymentEditMode(x.id, false)}>
                                                <FontAwesomeIcon icon="reply" />
                                              </Button>
                                            </Fragment>)
                                          : (<Fragment>
                                            {isContractOwner && x.comment
                                              ? (<span>
                                                  <FontAwesomeIcon icon="comment" className="text-primary" style={{marginRight: "6px"}} />
                                                  {x.comment}
                                                </span>)
                                              : null
                                            }
                                            </Fragment>)
                                        }
                                        </td>
                                        {canEdit
                                          ? (<td>
                                              <Button className="text-light xs bg-cf-red" onClick={() => onDeletePayment(x)}>
                                                <FontAwesomeIcon icon="times-circle" />
                                              </Button>
                                            </td>
                                          ) : null}
                                        </tr>
                                      </Fragment>
                                    );
                                  })}
                                </tbody>
                              </Table>
                              {contractType !== constants.CONTRACT_TYPE.RTO && canAddPayment ? (
                                <Fragment>
                                  <Row className="mb-1">
                                    <Col xs="3" md="2" className="pt-1">
                                      <Label>Payment Type</Label>
                                    </Col>
                                    <Col
                                      xs="4"
                                      className="form-control-sm"
                                      style={{ padding: "0 12px" }}
                                    >
                                      <Select
                                        options={_.reject(paymentTypes, (x) => x.hidden)}
                                        value={_.find(paymentTypes, (o) => o.value === paymentTypeId)}
                                        onChange={({
                                          value: newPaymentTypeId,
                                        }) => {
                                          setPaymentTypeId(newPaymentTypeId);
                                          if (newPaymentTypeId === constants.PAYMENT_TYPE_IDS.CREDIT_CARD) {
                                            loadCCList();
                                            setCapturingCard(true);
                                          }
                                          if (newPaymentTypeId === constants.PAYMENT_TYPE_IDS.ACH_DEBIT) {
                                            loadACHList();
                                            setCapturingACH(true);
                                          }
                                        }}
                                      />
                                    </Col>
                                  </Row>
                                  {paymentTypeId === constants.PAYMENT_TYPE_IDS.CREDIT_CARD ? (
                                    <Row className="mb-1">
                                      <Col xs="3" md="2" className="pt-1">
                                        <Label>Cards</Label>
                                      </Col>
                                      <Col
                                        xs="4"
                                        className="form-control-sm"
                                        style={{ padding: "0 12px" }}
                                      >
                                        <InputGroup size="sm">
                                          <div style={{ width: "80%" }}>
                                            <Select
                                              options={cards}
                                              value={selectedCard}
                                              onChange={(x) =>
                                                setSelectedCard(x)
                                              }
                                            />
                                          </div>
                                          <Button
                                            color="dark"
                                            className="text-light"
                                            title="Add Credit Card By Swipe"
                                            style={{ zIndex: 0 }}
                                            onClick={() =>
                                              setCapturingCard(true)
                                            }
                                          >
                                            <FontAwesomeIcon icon="credit-card" />
                                          </Button>
                                        </InputGroup>
                                      </Col>
                                    </Row>
                                  ) : null}
                                  {paymentTypeId === constants.PAYMENT_TYPE_IDS.ACH_DEBIT ? (
                                    <Row className="mb-1">
                                      <Col xs="3" md="2" className="pt-1">
                                        <Label>Bank Account</Label>
                                      </Col>
                                      <Col
                                        xs="4"
                                        className="form-control-sm"
                                        style={{ padding: "0 12px" }}
                                      >
                                        <InputGroup size="sm">
                                          <div style={{ width: "100%" }}>
                                            <Select
                                              options={bankAccounts}
                                              value={selectedBankAccount}
                                              onChange={(x) =>
                                                setSelectedBankAccount(x)
                                              }
                                            />
                                          </div>
                                        </InputGroup>
                                      </Col>
                                    </Row>
                                  ) : null}
                                  {paymentTypeId === constants.PAYMENT_TYPE_IDS.CHECK || paymentTypeId === constants.PAYMENT_TYPE_IDS.MONEY_ORDER ? (
                                    <Row className="mb-1">
                                      <Col xs="3" md="2" className="pt-1">
                                        <Label>Number</Label>
                                      </Col>
                                      <Col xs="4">
                                        <Input
                                          type="text"
                                          maxLength="15"
                                          value={paymentIdentifier}
                                          onChange={(e) =>
                                            setPaymentIdentifier(e.target.value)
                                          }
                                        />
                                      </Col>
                                    </Row>
                                  ) : null}
                                  <Row className="mb-1">
                                    <Col xs="3" md="2" className="pt-1">
                                      <Label>Payment Amount</Label>
                                    </Col>
                                    <Col xs="2">
                                      <Input
                                        type="text"
                                        bsSize="sm"
                                        className="text-end"
                                        maxLength="15"
                                        value={paymentAmount}
                                        onChange={(e) =>
                                          setPaymentAmount(e.target.value)
                                        }
                                      />
                                    </Col>
                                  </Row>
                                  {isContractOwner && (
                                    <Row className="mb-1">
                                      <Col>
                                        <Input
                                          type="text"
                                          maxLength="50"
                                          placeholder="Comment (optional)"
                                          value={paymentComment}
                                          onChange={(e) =>
                                            setPaymentComment(e.target.value)
                                          }
                                        />
                                      </Col>
                                    </Row>
                                  )}
                                  <Row>
                                    <Col>
                                      {isRunning ? null : (
                                        <Button
                                          className="text-light xs float-end"
                                          block
                                          color="success"
                                          onClick={onAddPayment}
                                        >
                                          <FontAwesomeIcon icon="plus-circle" />{" "}
                                          Add Payment
                                        </Button>
                                      )}
                                    </Col>
                                  </Row>
                                </Fragment>
                              ) : null}
                            </td>
                          </tr>
                        </DetailsTable>
                      </Col>
                    </Row>
                  </AccordionBody>
                </AccordionItem>
                {contract.typeOfContractStatus === constants.CONTRACT_STATUS_ID.Draft ? null : (
                  <AccordionItem>
                    <AccordionHeader targetId={TABS.ESSENTIALS}>
                      <FontAwesomeIcon icon="print" className="me-2" />
                      Essentials
                    </AccordionHeader>
                    <AccordionBody accordionId={TABS.ESSENTIALS}>
                      <Table>
                        <thead>
                          <tr>
                            <td style={{width: "25%"}}> {/* style={{ width: "35%" }} */}
                              <ButtonDropdown
                                className="me-2 rounded"
                                isOpen={essentialsMenuOpen}
                                toggle={() =>
                                  setEssentialsMenuOpen(!essentialsMenuOpen)
                                }
                              >
                                <DropdownToggle caret color="dark">
                                  <FontAwesomeIcon icon="file-upload" /> Click
                                  to Upload Attachments
                                </DropdownToggle>
                                <DropdownMenu>
                                  <DropdownItem
                                    onClick={() =>
                                      setPromptingUploadAttachmentTypeId(
                                        constants.ATTACHMENT_TYPE_IDS
                                          .DeliveryConfirmation
                                      )
                                    }
                                  >
                                    Delivery Confirmation
                                  </DropdownItem>
                                  {contractType ===
                                  constants.CONTRACT_TYPE.RTO ? (
                                    <DropdownItem
                                      onClick={() =>
                                        setPromptingUploadAttachmentTypeId(
                                          constants.ATTACHMENT_TYPE_IDS
                                            .LandownerPermission
                                        )
                                      }
                                    >
                                      Property Owner Agreement
                                    </DropdownItem>
                                  ) : null}
                                  <DropdownItem
                                    onClick={() =>
                                      setPromptingUploadAttachmentTypeId(
                                        constants.ATTACHMENT_TYPE_IDS
                                          .DriversLicense
                                      )
                                    }
                                  >
                                    Driver's License Scan
                                  </DropdownItem>
                                  {contractType ===
                                  constants.CONTRACT_TYPE.RTO ? (
                                    <DropdownItem
                                      onClick={() =>
                                        setPromptingUploadAttachmentTypeId(
                                          constants.ATTACHMENT_TYPE_IDS
                                            .ContractImage
                                        )
                                      }
                                    >
                                      Contract
                                    </DropdownItem>
                                  ) : null}
                                  <DropdownItem
                                      onClick={() =>
                                          setPromptingUploadAttachmentTypeId(
                                              constants.ATTACHMENT_TYPE_IDS
                                                  .TaxExemptionForm
                                          )
                                      }
                                  >
                                    Tax Exemption Form
                                  </DropdownItem>
                                </DropdownMenu>
                              </ButtonDropdown>
                            </td>
                            {attachmentAndMilestoneList.some(a => a.name === "Contract" || a.name === "Cash Sale Invoice") && (
                              <> 
                              <td colSpan="1">
                                <Button style={{whiteSpace: "nowrap", width: "100%"}} onClick={generateNewContractCode}>
                                  { contractPasscode == null || 
                                    (contractPasscode?.deactivatedAt != null)
                                    ? 'Generate Contract Code' 
                                    : 'Reset Code Duration'
                                  }
                                </Button>
                              </td>
                              <td colSpan={2}>
                                <div style={{alignItems: "center", paddingBottom: "5px", fontWeight: "bold"}}>
                                  {contractPasscode == null || (contractPasscode?.deactivatedAt != null)
                                   ? null
                                   : date_helpers.timeUntilPasscodeExpires(contractPasscode?.expiresAt)}
                                </div>
                              </td>
                              </>
                            )}
                            {contractPasscode != null && contractPasscode?.deactivatedAt == null 
                            && date_helpers.dateTimeFormat(moment()) < date_helpers.dateTimeFormat(contractPasscode?.expiresAt) 
                            && currentUserIsSysAdminOrCompanyAdmin ? (
                              <>
                              <td colSpan={1}>&nbsp;</td>
                              <td colSpan={1}>
                                <Button style={{whiteSpace: "nowrap", width: "100%", backgroundColor: "#db4242"}} onClick={deactivateContractCode}>
                                  Deactivate Contract Code
                                </Button>
                              </td>
                              </>
                              ): (<td colSpan={2}>&nbsp;</td>)}
                          </tr>
                          {hasMissingAttachments && !isRunning ? (
                            <tr>
                              <td colSpan="6">
                                <Button
                                  size="sm"
                                  className="btn-ghost float-end"
                                  onClick={() =>
                                    simpleContractOperation(
                                      `${
                                        contractType ===
                                        constants.CONTRACT_TYPE.RTO
                                          ? "contract"
                                          : "cashsale"
                                      }/GenerateMissingDocuments`
                                    )
                                  }
                                >
                                  Generate Missing Documents
                                </Button>
                              </td>
                            </tr>
                          ) : null}
                        </thead>
                        <tbody>
                          {attachmentAndMilestoneList.length ? (
                            _.map(attachmentAndMilestoneList, (a) => (
                              <tr key={`${a.attachmentId}-${a.milestoneType}`}>
                                <td>
                                  {a.milestoneType ===
                                  constants.MILESTONE_TYPE_IDS.Payment ? (
                                    <span
                                      className="btn"
                                      style={{ fontWeight: "bold" }}
                                    >
                                      {a.name}
                                    </span>
                                  ) : (
                                    <>
                                      <Button
                                        color="link"
                                        className="download-link"
                                        onClick={() =>
                                          onDownloadAttachment(a.key)
                                        }
                                      >
                                        {a.name}
                                      </Button>
                                      { contractPasscode && (a.name === "Contract" || a.name === "Cash Sale Invoice") && (
                                        <span style={{fontWeight: "bold", whiteSpace: "nowrap"}}>
                                          {contractPasscode?.deactivatedAt != null ?
                                          "Code Deactivated" : 
                                          date_helpers.dateTimeFormat(contractPasscode?.expiresAt) <= date_helpers.dateTimeFormat(moment()) ? 
                                          "Code Expired" :
                                          `CODE: ${contractPasscode?.signatureAccessToken}`}
                                        </span>
                                      )}
                                    </>
                                  )}
                                </td>
                                <td className="text-center mt-1">
                                  {renderMilestoneStatusBadge(
                                    a.status,
                                    a.lastUpdatedAtLocalized
                                  )}
                                </td>
                                <td>
                                  {currentUserIsSysAdminOrCompanyAdmin
                                    ? renderMilestoneButton(
                                        a.status,
                                        a.milestoneType
                                      )
                                    : null}
                                </td>
                                <td style={{ width: "170px" }}>
                                  {a.attachmentId
                                    ? a.createdAtLocalized
                                    : a.lastUpdatedAtLocalized}
                                </td>
                                <td style={{ width: "200px", paddingLeft: 50 }}>
                                  {a.createdByUserFirstName}{" "}
                                  {a.createdByUserLastName}
                                </td>
                                <td
                                  style={{
                                    borderLeftStyle: "none",
                                    width: "100px",
                                    textAlign: "right",
                                  }}
                                >
                                  <ButtonGroup>
                                    {a.attachmentId &&
                                    canManageContract &&
                                    isContractOwner ? (
                                      <Button
                                        className="xs text-light bg-cf-red"
                                        title="Delete"
                                        onClick={(e) =>
                                          deleteAttachment(a.attachmentId, e)
                                        }
                                      >
                                        <FontAwesomeIcon icon="times-circle" />
                                      </Button>
                                    ) : null}
                                    {a.milestoneType ===
                                      constants.MILESTONE_TYPE_IDS.Payment ||
                                    (a.status ===
                                      constants.MILESTONE_STATUS_IDS
                                        .NotApplicable &&
                                      a.typeOfAttachment !==
                                        constants.ATTACHMENT_TYPE_IDS
                                          .BuildingPlan) ||
                                    a.typeOfAttachment === constants.ATTACHMENT_TYPE_IDS.TaxExemptionForm ||
                                    a.typeOfAttachment ===
                                      constants.ATTACHMENT_TYPE_IDS
                                        .Quote ? null : (
                                      <Button
                                        color="success"
                                        className="xs text-light"
                                        title="Regenerate"
                                        onClick={() =>
                                          onRegenerate(a.typeOfAttachment)
                                        }
                                      >
                                        <FontAwesomeIcon icon="sync" />
                                      </Button>
                                    )}
                                  </ButtonGroup>
                                </td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td colSpan="6">No printables are available.</td>
                            </tr>
                          )}
                          <tr>
                            <td>
                              <Button
                                color="link"
                                className="download-link"
                                onClick={() => onDownloadAttachment(null)}
                              >
                                (Download all in 1 file)
                              </Button>
                            </td>
                            <td style={{ width: "150px" }}></td>
                            <td style={{ width: "150px" }}></td>
                            <td />
                            <td />
                            <td />
                          </tr>
                        </tbody>
                      </Table>
                    </AccordionBody>
                  </AccordionItem>
                )}
                <AccordionItem>
                  <AccordionHeader targetId={TABS.UPLOADS}>
                    <FontAwesomeIcon icon="image" className="me-2" />
                    Uploads
                  </AccordionHeader>
                  <AccordionBody accordionId={TABS.UPLOADS}>
                    {canManageContract || isSysAdmin || isStoreUser || isDriverOrDispatcher ? (
                      <Dropzone
                        onDrop={(fileList) => setFiles([fileList[0]])}
                        accept={[
                          "image/jpg",
                          "image/jpeg",
                          "image/png",
                          "application/pdf",
                        ]}
                        style={{
                          width: "100%",
                          backgroundColor: "#F0F0F0",
                          borderRadius: "6px",
                        }}
                      >
                        {({ getRootProps, getInputProps }) => (
                          <div {...getRootProps()} className="text-center">
                            <input {...getInputProps()} />
                            <div
                              style={{ textAlign: "center" }}
                              className="pt-3"
                            >
                              <div className="mb-1">
                                Drop file here or click the icon
                              </div>
                              <FontAwesomeIcon
                                color={files.length && "secondary"}
                                icon={
                                  files.length ? "check-square" : "file-upload"
                                }
                                size="3x"
                                className="mb-4"
                              />
                              {files.length
                                ? _.map(files, (f) => (
                                    <div
                                      key={f.name}
                                      className="description-text"
                                    >
                                      {f.name}
                                    </div>
                                  ))
                                : null}
                            </div>
                          </div>
                        )}
                      </Dropzone>
                    ) : null}
                    {files.length ? (
                      <ButtonGroup size="sm" className="float-end text-light">
                        <Button
                          color="secondary"
                          size="sm"
                          disabled={clickedUpload}
                          onClick={() => setFiles([])}
                        >
                          Cancel
                        </Button>
                        <Button
                          color="primary"
                          size="sm"
                          disabled={clickedUpload}
                          className="float-end py-2 text-light"
                          onClick={commitUpload}
                        >
                          Save
                        </Button>
                      </ButtonGroup>
                    ) : null}
                    <div style={{ clear: "both" }}>
                      <h4 className="mb-0">Images</h4>
                      <hr className="mt-0" />
                      {contractImages.length === 0 ? (
                        <div>No images are available.</div>
                      ) : (
                        <Row>
                          {_.map(contractImages, (ci) => (
                            <Col key={`img-${ci.id}`} xs={12} md={3}>
                              <span key={ci.id} className="image-border">
                                <div className="inline-block">
                                  {canManageContract && isContractOwner ? (
                                    <Button
                                      className="xs text-light bg-cf-red position-absolute"
                                      onClick={(e) =>
                                        deleteAttachment(ci.id, e)
                                      }
                                    >
                                      <FontAwesomeIcon icon="times-circle" />
                                    </Button>
                                  ) : null}
                                  <img
                                    style={{ cursor: "pointer" }}
                                    className="img-thumbnail"
                                    width="120"
                                    title={`Added by ${ci.createdByUserFullName} at ${ci.createdAtLocalized} - click to download`}
                                    src={`/api/Contract/ViewImage/${ci.key}?preview=true`}
                                    onClick={() =>
                                      onDownloadImage(
                                        ci.key,
                                        constants.MIME_PDF
                                      )
                                    }
                                  />
                                </div>
                              </span>
                            </Col>
                          ))}
                        </Row>
                      )}
                    </div>
                    <div className="mt-2">
                      <h4 className="mb-0">Signatures</h4>
                      <hr className="mt-0" />
                      {contractSignatures.length === 0 ? (
                        <div>No signatures are available.</div>
                      ) : (
                        <Row>
                          {_.map(contractSignatures, (ci) => (
                            <Col key={`sig-${ci.id}`} xs={12} md={3}>
                              <span key={ci.id} className="image-border">
                                <div className="inline-block">
                                  {canManageContract && isContractOwner ? (
                                    <Button
                                      className="xs text-light bg-cf-red position-absolute"
                                      onClick={(e) =>
                                        deleteAttachment(ci.id, e, ci.typeOfAttachment)
                                      }
                                    >
                                      <FontAwesomeIcon icon="times-circle" />
                                    </Button>
                                  ) : null}
                                  <img
                                    style={{ cursor: "pointer" }}
                                    className="img-thumbnail"
                                    width="200"
                                    title={ci.createdByUserFullName
                                      ? `Added by ${ci.createdByUserFullName} at ${ci.createdAtLocalized} - click to download`
                                      : ''}
                                    src={`/api/Contract/ViewImage/${ci.key}?preview=true`}
                                    onClick={() =>
                                      onDownloadImage(
                                        ci.key,
                                        constants.MIME_PNG
                                      )
                                    }
                                  />
                                </div>
                              </span>
                              <div className="text-muted text-center fst-italic">
                                {ci.name}
                              </div>
                            </Col>
                          ))}
                        </Row>
                      )}
                    </div>
                  </AccordionBody>
                </AccordionItem>
                <AccordionItem>
                  <AccordionHeader targetId={TABS.COMMENTS}>
                    <FontAwesomeIcon icon="comment" className="me-2" />
                    Comments
                    <Badge
                      color="dark"
                      className={
                        "ms-2 align-items-center justify-content-center text-center"
                      }
                    >
                      {comments && comments.length ? comments.length : ""}
                    </Badge>
                  </AccordionHeader>
                  <AccordionBody accordionId={TABS.COMMENTS}>
                    {canAddComments ? (
                      <Row>
                        <Col>
                          <Button
                            color="success"
                            className="float-end text-light mb-1"
                            size="sm"
                            onClick={() => setShowCommentModal(true)}
                          >
                            <FontAwesomeIcon icon="plus" /> Add Comment
                          </Button>
                        </Col>
                      </Row>
                    ) : null}
                    <Table size="sm">
                      <tbody>
                        {_.map(comments, (c) => (
                          <tr
                            key={c.id}
                            className={classnames(
                              { private: c.isPrivate },
                              "comment-row"
                            )}
                          >
                            <td className="content" dangerouslySetInnerHTML={{__html: c.dsc}} />
                            <td style={{ width: "180px" }}>
                              {c.createdAtLocalized}
                            </td>
                            <td style={{ width: "150px" }}>{c.userFullName}</td>
                            <td style={{ width: "50px" }}>
                              {c.userId === currentUser.id ||
                              currentUserIsSysAdminOrCompanyAdmin ? (
                                <Button
                                  className="text-light bg-cf-red"
                                  size="sm"
                                  title="Delete"
                                  onClick={() => deleteComment(c.id)}
                                >
                                  <FontAwesomeIcon icon="times-circle" />
                                </Button>
                              ) : null}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </AccordionBody>
                </AccordionItem>
                <AccordionItem>
                  <AccordionHeader targetId={TABS.HISTORY}>
                    <FontAwesomeIcon icon="arrow-left" className="me-2" />
                    History
                  </AccordionHeader>
                  <AccordionBody accordionId={TABS.HISTORY}>
                    <HistoryList historyList={historyList} />
                  </AccordionBody>
                </AccordionItem>
                <AccordionItem>
                  <AccordionHeader targetId={TABS.OWNERSHIP}>
                    <FontAwesomeIcon icon="arrow-left" className="me-2" />
                    Ownership History
                  </AccordionHeader>
                  <AccordionBody accordionId={TABS.OWNERSHIP}>
                    <Table size="sm">
                      <tbody>
                        {_.map(ownershipList, (o) => (
                          <tr key={o.id} className="comment-row">
                            <td>{o.companyName}</td>
                            <td>from {o.purchasedFromCompanyName}</td>
                            <td>{o.occurredAtLocalized}</td>
                            <td>{o.performedByUserFullName}</td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </AccordionBody>
                </AccordionItem>
              </Accordion>
            </Col>
          </Row>
        </Container>
      )}
      {capturingACH ? (
        <CaptureACH
          achPayment={achPayment}
          bankInformation={bankInformation}
          achValidation={achValidation}
          onAchPaymentChange={onAchPaymentChange}
          showAchValidation={showAchValidation}
          cleanseAchModal={cleanseAchModal}
          saveACH={saveACH}
        />
      ) : null}
      {capturingCard ? (
        <Modal isOpen={true} autoFocus={false} centered>
          <ModalHeader>Swipe Credit Card</ModalHeader>
          <ModalBody>
            <Row className="mt-3 mb-1">
              <Col xs="4">
                <div className="float-end">{ui_helpers.requiredStar()}</div>
                <Input
                  type="text"
                  value={cardZip}
                  maxLength="12"
                  autoFocus={true}
                  style={{ width: "90%" }}
                  placeholder="Zip Code"
                  onChange={(e) => {
                    setCardZip(e.target.value);
                    if (
                      cardSwipeRef &&
                      cardSwipeRef.current &&
                      e.target.value.length === 5
                    ) {
                      try {
                        setTimeout(() => cardSwipeRef.current.focus(), 400);
                      } catch {}
                    }
                  }}
                />
              </Col>
              <Col xs="8">
                <Input
                  type="password"
                  innerRef={cardSwipeRef}
                  autoComplete="new-password"
                  value={cardSwipe}
                  onChange={(e) => setCardSwipe(e.target.value)}
                  onKeyDown={swipeCreditCardKeydown}
                  readOnly={!cardZip || cardZip.length < 5}
                />
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button
              color="success"
              className="text-light"
              onClick={() => {
                // 17-Nov-22, ART, They complained about the above, now clearing it
                setCardNo("");
                setCapturingCard(false);
                setCapturingCardManually(true);
              }}
            >
              Enter card details manually
            </Button>
            <Button
              color="secondary"
              className="float-end"
              onClick={() => setCapturingCard(false)}
            >
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      ) : null}
      {capturingCardManually ? (
        <Modal isOpen={true} autoFocus={false} centered>
          <ModalHeader>Enter Card Information</ModalHeader>
          <ModalBody>
            <Card
              cvc={cardCVC}
              number={cardNo}
              expiration={cardMMYY}
              // name={""}
              focused={"name"}
            />
            <Row className="mt-3 mb-1">
              <Col xs="5">
                <Input
                  type="text"
                  bsSize="sm"
                  value={cardNo}
                  maxLength="20"
                  placeholder="Card number"
                  onChange={(e) => setCardNo(e.target.value)}
                />
              </Col>
              <Col xs="3">
                <Input
                  type="text"
                  bsSize="sm"
                  value={cardZip}
                  maxLength="12"
                  placeholder="Zip Code"
                  onChange={(e) => setCardZip(e.target.value)}
                />
              </Col>
            </Row>
            <Row>
              <Col xs="5">
                <Input
                  type="text"
                  bsSize="sm"
                  value={cardMMYY}
                  maxLength="4"
                  placeholder="MMYY"
                  onChange={(e) => setCardMMYY(e.target.value)}
                />
              </Col>
              <Col xs="3">
                <Input
                  type="text"
                  bsSize="sm"
                  value={cardCVC}
                  maxLength="5"
                  placeholder="CVC"
                  onChange={(e) => setCardCVC(e.target.value)}
                />
              </Col>
            </Row>
            {manualCard ? (
              <div className="mt-2">
                {manualCard.ErrorMessage ? (
                  <RSAlert color="warning" className="mb-0">
                    {manualCard.ErrorMessage}
                  </RSAlert>
                ) : (
                  <RSAlert color="success" className="mb-0">
                    The provided card information is valid.
                  </RSAlert>
                )}
              </div>
            ) : null}
          </ModalBody>
          <ModalFooter>
            <Button
              color="warning"
              className="text-light"
              onClick={() => {
                setManualCard(
                  ui_helpers.parseManualCardInformation(
                    cardZip,
                    cardNo,
                    cardMMYY,
                    cardCVC
                  )
                );
              }}
            >
              Verify Card Information
            </Button>
            <ButtonGroup className="float-end">
              {manualCard && !manualCard.ErrorMessage ? (
                <Button color="primary" onClick={saveManualCard}>
                  Save
                </Button>
              ) : null}
              <Button
                color="secondary"
                onClick={() => setCapturingCardManually(false)}
              >
                Cancel
              </Button>
            </ButtonGroup>
          </ModalFooter>
        </Modal>
      ) : null}
      <Footer />
    </div>
  );
};

export default ContractView;