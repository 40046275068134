import React, {useState, useEffect, useContext, Fragment, useLayoutEffect} from 'react';
import {useParams, useHistory, Link, Redirect} from 'react-router-dom';
import {constants, api, UserContext, helpers} from '../utils';
import classnames from 'classnames';
import Dropzone from "react-dropzone";
import {DebounceInput} from "react-debounce-input";
import {
    Accordion, AccordionBody, AccordionHeader, AccordionItem,
    Badge,
    Button, ButtonGroup,
    Col,
    Container,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Row,
    Table,
    Spinner,
    Modal,
    ModalHeader,
    ModalFooter,
    ModalBody,
    Input, Label, UncontrolledAlert
} from 'reactstrap';
import { confirmAlert } from "react-confirm-alert";
import Alert from "react-s-alert-v3";
import {FollowUpButtons, Footer, Header, Loading, InventoryStatusBadge, 
  InventoryPriceModal, InventoryDiscountModal, CancelInventoryModal, SellInventoryModal, ModelModal, SetSerialModal, UsedBuildingDetails} from './';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import FollowupIcon from "./FollowupIcon";
import {date_helpers, ui_helpers} from "../helpers";
import DatePicker from 'react-datepicker';
import DetailsTable from "./DetailsTable";
import DetailsRow from "./DetailsRow";
import moment from "moment";
import _ from 'lodash';
import CurrencyInput from "react-currency-input-field";
import Select from "react-select";

const TABS = {
  ATTACHMENTS: "attachments",
  COMMENTS: "comments",
  WEB_IMAGES: "web-images",
  HISTORY: "history",
  OWNERSHIP: "ownership",
  UNIT_ADDONS: "addOns",
  UPLOADS: "uploads",
};
const NO_OVERRIDE_S_ID = constants.INVENTORY_STATUS_NOT_TO_OVERRIDE;

export default function InventoryView(props) {
  let {id} = useParams();
  if (id) {
    id = parseInt(id, 10);
  }
  const [resolvedInventoryId, setResolvedInventoryId] = useState(id);
  const [inventory, setInventory] = useState({});
  const [historyList, setHistoryList] = useState([]);
  const [ownershipList, setOwnershipList] = useState([]);
  const [inventoryImages, setInventoryImages] = useState([]);
  const [inventoryImageTypeList, setInventoryImageTypeList] = useState(constants.INVENTORY_IMAGE_TYPES);
  const [comments, setComments] = useState([]);
  const [showHoldModal, setShowHoldModal] = useState(false);
  const [showSerialModal, setShowSerialModal] = useState(false);
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [showPriceModal, setShowPriceModal] = useState(false);
  const [showDiscountModal, setShowDiscountModal] = useState(false);
  const [showModelModal, setShowModelModal] = useState(false);
  const [showCommentModal, setShowCommentModal] = useState(false);
  const [showWebSaleModal, setShowWebSaleModal] = useState(false);
  const [showApproveModal, setShowApproveModal] = useState(false);
  const [modelNumber, setModelNumber] = useState(inventory.modelNumber || '');
  const [showInventorySaleModal, setShowInventorySaleModal] = useState(false);
  const [hasUnprocessedContract, setHasUnprocessedContract] = useState(false);
  const [dropDownOpen, setDropDownOpen] = useState(false);
  const [convertDropDownOpen, setConvertDropDownOpen] = useState(false);
  const [openAccordionItem, setOpenAccordionItem] = useState(TABS.COMMENTS);
  const [isLoading, setIsLoading] = useState(false);
  const [attachments, setAttachments] = useState([]);
  const [inventoryUploadList, setInventoryUploadList] = useState([]);
  const [rtoCompleted, setRtoCompleted] = useState(false);
  const [paidOn, setPaidOn] = useState(moment());
  const [checkNo, setCheckNo] = useState('');
  const [loadingBillOfSale, setLoadingBillOfSale] = useState(false);
  const [loadingBuildingTag, setLoadingBuildingTag] = useState(false);
  const [discount, setDiscount] = useState(0);
  const [editPrice, setEditPrice] = useState(0);
  const [cancelComment, setCancelComment] = useState('');
  const [rejectComment, setRejectComment] = useState('');
  const [serialNumber, setSerialNumber] = useState(inventory.serialNumber || '');
  const [comment, setComment] = useState('');
  const [commentPrivate, setCommentPrivate] = useState(false);
  const [companyEnableCashSales, setCompanyEnableCashSales] = useState(false);
  const [companyEnableRTOContracts, setCompanyEnableRTOContracts] = useState(false);
  const [webDsc, setWebDsc] = useState('');
  const [webSalePrice, setWebSalePrice] = useState('');
  const [webProductName, setWebProductName] = useState('');
  const [webMarkup, setWebMarkup] = useState('');
  const [message, setMessage] = useState(null);
  const [showPnl, setShowPnl] = useState(true);
  const [repo, setRepo] = useState({});
  const [pl, setPl] = useState(null);
  const [originalDealerId, setOriginalDealerId] = useState(null);
  const [originalDealerName, setOriginalDealerName] = useState("");
  const [isNew, setIsNew] = useState();
  const [redirectTo, setRedirectTo] = useState();
  const [showMoveModal, setShowMoveModal] = useState(false);
  const [moveToDealer, setMoveToDealer] = useState(null);
  const [dealerList, setDealerList] = useState(null);
  const [canManageInventory, setCanManageInventory] = useState(false);
  const [canManageComments, setCanManageComments] = useState(false);
  const [addOnList, setAddOnList] = useState([]);
  const [isResidualEditor, setIsResidualEditor] = useState(false);
  const [isSysAdmin, setIsSysAdmin] = useState(false);
  const [isCompanyAdmin, setIsCompanyAdmin] = useState(false);
  const [isPairedContractCompanyAdmin, setIsPairedContractCompanyAdmin] = useState(false);
  const [isAnyRTOCompanyAdmin, setIsAnyRTOCompanyAdmin] = useState(false);
  const [isDealerAdmin, setIsDealerAdmin] = useState(false);
  const [isMfgUser, setIsMfgUser] = useState(false);
  const [isMfgAdmin, setIsMfgAdmin] = useState(false);
  const [canDispatchBeyondAStore, setCanDispatchBeyondAStore] = useState(false);
  const [isInvSaleCompanyAdmin, setIsInvSaleCompanyAdmin] = useState(false);
  const [isInventoryOwner, setIsInventoryOwner] = useState(false);
  const [isRelatedContractOwner, setIsRelatedContractOwner] = useState(false);
  const [files, setFiles] = useState([]);
  const [promptingUploadAttachmentTypeId, setPromptingUploadAttachmentTypeId] = useState(null);
  const [clickedUpload, setClickedUpload] = useState(false);
  const [attachmentToMoveId, setAttachmentToMoveId] = useState(null);
  const [selectedAttachmentType, setSelectedAttachmentType] = useState(null);
  const [isStoreUser, setIsStoreUser] = useState(false);
  const [pendingSaleRecord, setPendingSaleRecord] = useState(null);
  const [loadingSaleInvoice, setLoadingSaleInvoice] = useState(false);
  const [savingWebSale, setSavingWebSale] = useState(false);
  const [showArchivedAttachments, setShowArchivedAttachments] = useState(false);
  const PATHNAMES = constants.PATH_NAMES;
  const S_IDS = constants.INVENTORY_STATUS_ID;
  const history = useHistory();
  const {currentUser} = useContext(UserContext);

  function toggleAccordion(targetId) {
    if (_.values(TABS).includes(targetId) && targetId !== openAccordionItem) {
      setOpenAccordionItem(targetId);
    } else {
      setOpenAccordionItem("0");
    }
  }

  function cancelInventory() {
    api.post(`Inventory/Cancel`, { id: resolvedInventoryId, comment: cancelComment }).then(r => {
      if(!r.data.success) return;
      loadInventory();
      setShowCancelModal(false);
    });
  }

  useEffect(loadInventory, [resolvedInventoryId]);

  useEffect(() => {
    if(!inventory) return;
    getPermissions();
  }, [inventory]);

  useEffect(() => {
    if (!id) return;
    id = parseInt(id, 10);
    setResolvedInventoryId(id);
  }, [id]);

  function getPermissions() {
    const isSystemAdmin = ui_helpers.isSystemAdministrator(currentUser);
    const canMngInventory = inventory && ui_helpers.canCreateInventory(currentUser, inventory.companyId, inventory.dealerId, inventory.manufacturerId);
    const isStoreUser = inventory && ui_helpers.isStoreUser(currentUser, inventory.dealerId);
    const canDispatch = isSystemAdmin
        || _.some(currentUser.roles, ro =>
            ((ro.manufacturerId === inventory.manufacturerId
                    || ro.companyId === inventory.rtoCompanyId
                    || ro.companyId === inventory.companyId)
                && (ro.typeOfUserRole === constants.ROLE_IDS.Administrator)
                || (ro.typeOfUserRole === constants.ROLE_IDS.Dispatcher))
            || ((ro.dealerId === inventory.dealerId)
                && (ro.typeOfUserRole === constants.ROLE_IDS.Dispatcher))
            || ((ro.companyId === inventory.companyId)
                && (ro.typeOfUserRole === constants.ROLE_IDS.Dispatcher)));
  const canDispatchBeyondAStore = _.some(currentUser.roles, ro =>
            (ro.manufacturerId === inventory.manufacturerId || ro.companyId === inventory.rtoCompanyId || ro.companyId === inventory.companyId)
            && (ro.typeOfUserRole === constants.ROLE_IDS.Administrator || ro.typeOfUserRole === constants.ROLE_IDS.Dispatcher)
        );
    const isCpyAdmin = inventory && _.some(currentUser.roles, ro => (ro.companyId === inventory.companyId)
          && (ro.typeOfUserRole === constants.ROLE_IDS.Administrator 
              || ro.typeOfUserRole === constants.ROLE_IDS.InventoryManager));
    const canManageComments = canMngInventory || isStoreUser || canDispatch || isSystemAdmin;
    const isDAdmin = inventory && ui_helpers.isDealerAdministrator(currentUser, inventory.dealerId);
    const mfgUser = inventory && ui_helpers.isManufacturerUser(currentUser, inventory.manufacturerBrandId);
    const mfgAdmin = inventory && ui_helpers.isManufacturerAdministrator(currentUser, inventory.manufacturerId);
    const isRelatedContractOwner = inventory && _.some(currentUser.roles, ro => (ro.companyId === inventory.contractCompanyId)
          && (ro.typeOfUserRole === constants.ROLE_IDS.Administrator
              || ro.typeOfUserRole === constants.ROLE_IDS.InventoryManager));
    setCanManageInventory(canMngInventory);
    setCanManageComments(canManageComments);
    setIsStoreUser(isStoreUser);
    setIsSysAdmin(isSystemAdmin);
    setIsCompanyAdmin(isCpyAdmin);
    setIsAnyRTOCompanyAdmin(ui_helpers.isAnyRTOCompanyAdmin(currentUser));
    if (inventory && inventory.contractId) {
      const isContractCpyAdmin = _.some(currentUser.roles, ro => (ro.companyId === inventory.contractCompanyId)
          && (ro.typeOfUserRole === constants.ROLE_IDS.Administrator 
              || ro.typeOfUserRole === constants.ROLE_IDS.InventoryManager));
      setIsPairedContractCompanyAdmin(isContractCpyAdmin);
    }
    setIsDealerAdmin(isDAdmin);
    setIsMfgUser(mfgUser);
    setIsMfgAdmin(mfgAdmin);
    setCanDispatchBeyondAStore(canDispatchBeyondAStore);
    setIsInvSaleCompanyAdmin(inventory && ui_helpers.isRequestedSaleCompanyAdministrator(currentUser, inventory.saleRequestCompanyId));
    setIsInventoryOwner(inventory && ui_helpers.isInventoryOwner(currentUser, inventory.manufacturerId, inventory.companyId));
    setIsRelatedContractOwner(isRelatedContractOwner);
  }

  function onDropImage(file, attachmentTypeId) {
    setIsLoading(true);
    let formData = new FormData();
    formData.append('UnitInventoryId', resolvedInventoryId);
    formData.append('TypeOfAttachment', attachmentTypeId);
    formData.append('Content', file);
    api.post_form_data('Inventory/UploadImage', formData).then(r => {
      if (r.data.success) {
        loadInventory();
      } else {
        Alert.error(r.data.message);
      }
    }).catch((e) => console.error(e))
    .finally(() => setIsLoading(false));
  }

  function applyDiscount() {
    api.post("Inventory/ApplyDiscount", { id: resolvedInventoryId, price: parseFloat(ui_helpers.cleanseCurrencyCharacters(discount)) }).then(r => {
      if(r.data.success) {
        setDiscount(0);
        setShowDiscountModal(false);
        loadInventory();
      } else {
        Alert.warning(r.data.message);
      }
    });
  }

  function onDeleteWebImage(i) {
    api.post('Inventory/DeactivateAttachment', {id: i.id}).then(r => {
      if (r.data.success) {
        loadInventory();
      } else {
        Alert.error(r.data.message);
      }
    }).catch((e) => console.error(e));
  }

  function editOriginalPrice() {
    api.post(`Inventory/UpdateInventoryOriginalPrice`, { id: resolvedInventoryId, price: parseFloat(ui_helpers.cleanseCurrencyCharacters(editPrice)) }).then(r => {
      if(!r.data.success) return;
      loadInventory();
      setShowPriceModal(false);
    });
  }

  function changeSerialNumber() {
    api.post('inventory/SetSerialNo', {
      id: inventory.id,
      serialNo: serialNumber
    }).then(r => {
      if (r.data.success) {
        loadInventory();
        setShowSerialModal(false);
      } else {
        Alert.error(r.data.message);
      }
    }).catch((e) => console.error(e));
  }

  function changeModelNumber() {
    api.post('inventory/SetModelNo', {
      id: inventory.id,
      modelNo: modelNumber
    }).then(r => {
      if (r.data.success) {
        setModelNumber('');
        setShowModelModal(false);
        loadInventory();
      } else {
        Alert.error(r.data.message);
      }
    }).catch((e) => console.error(e));
  }

  function processContract() {
    confirmAlert({
      title: "Request Process",
      message: "Are you sure you want to Process the contract for this inventory?",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            api.post("Contract/SetContractProcessed", {id: inventory.contractId}).then(r => {
              if(!r.data.success) return;
              loadInventory();
            });
          }
        },
        {
          label: "No"
        }
      ]
    });
  }

  function submitComment() {
    api.post('Inventory/AddComment', {id: inventory.id, comment: comment, isPrivate: commentPrivate}).then(r => {
      if(!r.data.success) return;
      refreshComments();
      setCommentPrivate(false);
      setShowCommentModal(false);
    });
  }

  function deleteComment(commentId) {
    api.delete(`Inventory/DeleteComment/${commentId}`).then(r => {
      if(!r.data.success) return;
      refreshComments();
    });
  }

  function refreshComments() {
    api.fetch(`Inventory/GetInventoryComments/${inventory.id}`).then(r => {
      setComments(r.data);
    });
  }

  function regenBillOfSale() {
    setLoadingBillOfSale(true);
    api.post(`Inventory/RegenerateBillOfSale/${resolvedInventoryId}`).then((r) => {
      if(!r.data.success) return;
        refreshAttachmentList();
        onDownloadAttachment(null, r.data.attachment.key);

    }).finally(() => setLoadingBillOfSale(false));
  }

  function refreshAttachmentList() {
    api.post(`Inventory/GetAttachments/${resolvedInventoryId}`).then(r => {
      setAttachments(r.data.attachmentList);
    });
  }

  function regenBuildingTag() {
    setLoadingBuildingTag(true);
    api.post(`Inventory/RegenerateBuildingTag/${resolvedInventoryId}`).then(r => {
      if (!r.data.success) {
        Alert.error(r.data.message);
        return;
      }
      refreshAttachmentList();
      onDownloadAttachment(null, r.data.attachment.key);
    }).finally(() => setLoadingBuildingTag(false));
  }

  function generatePreSaleWholesaleInvoice(){
    if(!inventory.saleRequestCompanyId) return;
    setLoadingBillOfSale(true);
    const pdfWindow = window.open();

    api.downloadAndOpenFile(`Inventory/GeneratePreSaleBillOfSale/${resolvedInventoryId}/${inventory.saleRequestCompanyId}`, {}, pdfWindow, (e) => {
      pdfWindow.close();
      console.error(e);
      Alert.error("There was an error downloading the document");
    }, constants.MIME_PDF, () => setLoadingBillOfSale(false));
  }

  function loadInventory() {
    setIsLoading(true);
    api.post(`Inventory/GetInventoryItem/${resolvedInventoryId}`).then(r => {
      // access denied error
      if (r.data.success === false && r.data.message === constants.ERROR_MESSAGES.ERR_ACCESS_DENIED) {
        Alert.error(r.data.message);
        setRedirectTo('/');
        return;
      }
      setInventory(r.data.inventory);
      setDiscount(r.data.inventory.retailPrice);
      setEditPrice(r.data.inventory.retailPrice);
      setComments(r.data.commentList);
      setAttachments(r.data.attachmentList && r.data.attachmentList.length ? _.orderBy(r.data.attachmentList, (a) => a.deactivatedAt === null, ["desc"]) : []);
      setRtoCompleted(r.data.inventory.followupStatus_LoadedToRTO);
      setHistoryList(r.data.lifeCycleEventList);
      setOwnershipList(r.data.ownershipList);
      setIsResidualEditor(r.data.inventory.currentUserIsResidualEditor);
      setInventoryImages(r.data.inventoryImageList);
      setSerialNumber(r.data.inventory.serialNumber);
      setModelNumber(r.data.inventory.modelNumber);
      setCompanyEnableCashSales(r.data.enableCashSales);
      setCompanyEnableRTOContracts(r.data.enableRTOContracts);
      setHasUnprocessedContract(r.data.hasUnprocessedContract);
      setRepo(r.data.repo);
      const oDealerId = r.data.repo?.originalDealerId;
      setOriginalDealerId(oDealerId);
      setIsNew(r.data.inventory.unitIsNew);
      setPl(r.data.pl);
      setAddOnList(r.data.unitOptionList);
      setInventoryUploadList(r.data.inventoryUploadList);
      getManufacturerDealerList(r.data.inventory.manufacturerId, oDealerId);
    }).catch((err) => {
      console.error('Error Fetching Inventory Items: ', err);
      Alert.error("There was an error loading the inventory");
    }).finally(() => setIsLoading(false));
  }

  function unPairContract() { 
    confirmAlert({
      title: "Unpair Contract",
      message: "This action will unpair this inventory item from the active contract and restore it back to In Stock status.",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            api.post(`Inventory/UnpairContract/${resolvedInventoryId}`).then(r => {
              if(!r.data.success) return;
              loadInventory();
            });
          }
        },
        {
          label: "No"
        }
      ]
    });    
  }

  function onDelete() {
    confirmAlert({
      title: "Delete this Inventory Item?",
      message: "Are you sure you'd like to permanently and irreversibly delete this inventory?",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            api.post("Inventory/Delete", {id: resolvedInventoryId}).then(r => {
              if (!r.data.success) return;
              history.push(PATHNAMES.INVENTORY);
            });
          }
        },
        {
          label: "No"
        }
      ]
    });    
  }

  function onMoveToStock() {
    confirmAlert({
      title: "Move Inventory to In-Stock",
      message: "Are you sure you want to inventory to In-Stock?",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            api.post("Inventory/Approve", {id: resolvedInventoryId, comment: "", paidOn: new Date(), checkNo: ""}).then(r => {
              if (r.data.success) {
                loadInventory();
              } else {
                Alert.warning(r.data.message);
              }
            });
          }
        },
        {
          label: "No"
        }
      ]
    });    
  }

  function onRemoveFromWebSale() {
    setSavingWebSale(true);
    api.post("Inventory/ToggleInventoryWebVisibility", {inventoryId: resolvedInventoryId, visibility: false, sequence: ''}).then(r => {
      if (r.data.success) {
        loadInventory();
      } else {
        Alert.warning(r.data.message);
      }
    }).catch(() => setMessage({ flavor: 'danger', message: 'Failed to update web sale status' }))
    .finally(() => setSavingWebSale(false));
  }

  function onMarkForWebSale() {
    setSavingWebSale(true);
    api.post("Inventory/ToggleInventoryWebVisibility", {
      inventoryId: resolvedInventoryId, 
      visibility: true, 
      markupPercentage: parseFloat(_.trim(webMarkup)), 
      webPrice: parseFloat(ui_helpers.cleanseCurrencyCharacters(webSalePrice)),
      description: _.trim(webDsc),
      productName:  _.trim(ui_helpers.capitalizeFirstLetter(webProductName))
    }).then(r => {
      if (r.data.success) {
        loadInventory();
        setShowWebSaleModal(false);
      } else {
        setMessage({ flavor: 'danger', message: r.data.message });
      }
    }).catch(() => setMessage({ flavor: 'danger', message: 'Failed to update web sale status' }))
    .finally(() => setSavingWebSale(false));
  }

  function onApprove() {
    api.post("Inventory/Approve", {id: resolvedInventoryId, paidOn: date_helpers.formatDateForServer(paidOn), checkNo: checkNo, comment: comment}).then(r => {
      if (r.data.success) {
        loadInventory();
        setCheckNo('');
        setComment('');
        setPaidOn(moment());
        setShowApproveModal(false);
        onDownloadAttachment(null, r.data.message)
      } else {
          setMessage({ flavor: 'danger', message: r.data.message});
        //Alert.warning(r.data.message);
      }
    });
  }
  function onDeny() {
    if (!_.trim(comment)) {
      setMessage({ message: "Comment is required for denial.", flavor: "danger" });
      return;
    }
    api.post("Inventory/DenySale", {id: resolvedInventoryId, comment: comment}).then(r => {
      if (r.data.success) {
        setMessage(null);
        loadInventory();
        setCheckNo('');
        setComment('');
        setPaidOn(moment());
        setShowApproveModal(false);
      } else {
        Alert.warning(r.data.message);
      }
    });
  }

  function onDownloadAttachment(e, docId, name = null) {
    if (e) e.preventDefault();
    if (!docId) return;
    let mimeType = constants.MIME_PDF; // 'application/octet-stream'
    if (name) {
      name = name.toLowerCase();
      if (name.endsWith('jpg') || name.endsWith('jpeg')) {
        mimeType = constants.MIME_JPG;
      } else if (name.endsWith('png')) {
        mimeType = constants.MIME_PNG;
      }
    }
    const pdfWindow = window.open();
    api.downloadAndOpenFile(`inventory/GetDocument/${resolvedInventoryId}/${docId}`, {}, pdfWindow, (e) => {
      console.error(e);
      if (pdfWindow)
        pdfWindow.close();
      Alert.error("There was an error downloading the document");
    }, mimeType);
  }

  function onRevive() {
    api.post("Inventory/Uncancel", {id: resolvedInventoryId}).then(r => {
      if (r.data.success) {
        setMessage(null);
        loadInventory();
      } else {
        Alert.warning(r.data.message);
      }
    });
  }

  function onWholesaleViewInvoice() {
    const pdfWindow = window.open();
    api.downloadAndOpenFile('inventory/ViewBillOfSale', {id: inventory.billOfSaleAttachmentId}, pdfWindow, (e) => {
      console.error(e);
      if (pdfWindow)
        pdfWindow.close();
      Alert.error("There was an error downloading the document");
    });
  }

  function onViewBuildingTag() {    
    const pdfWindow = window.open();
    api.downloadAndOpenFile('inventory/ViewBuildingTag', {id: inventory.id}, pdfWindow, (e) => {
      console.error(e);
      if (pdfWindow)
        pdfWindow.close();
      Alert.error("There was an error downloading the document");
    });
  }

  function onRemoveHold() {
    api.post("Inventory/RemoveHold", {id: resolvedInventoryId}).then(r => {
      if (r.data.success) {
        loadInventory();
      } else {
        Alert.warning(r.data.message);
      }
    });
  }

  function onSetHold() {
    api.post("Inventory/SetHold", {id: resolvedInventoryId, comment: rejectComment}).then(r => {
      if (r.data.success) {
        setRejectComment('');
        loadInventory();
        setShowHoldModal(false);
      } else {
        Alert.warning(r.data.message);
      }
    });
  }

  function setFollowUpStatus(typeOfFollowUp, completed) {
    api.post('Inventory/SetFollowupStatus', { id: resolvedInventoryId, followUpType: typeOfFollowUp, complete: completed }).then(r => {
      if(!r.data.success) return;
      loadInventory();
    });
  }

  function onChangeWebPrice(fieldName, value) {
    if (fieldName === 'webMarkup') {
      setWebMarkup(value);
      const markupPercent = parseFloat(_.trim(value));
      if (!markupPercent && markupPercent !== 0) {
        setWebSalePrice('');
        return;
      }
      const percent = parseFloat(markupPercent / 100.0);
      const price = parseFloat(inventory.retailPrice * percent) + inventory.retailPrice;
      setWebSalePrice(
        ui_helpers.formatCurrency(price).replace('$', '').replace(',', '')
        // price.toString(10)
      );
    } else if (fieldName === 'webSalePrice') {
      setWebSalePrice(value);
      const price = parseFloat(ui_helpers.cleanseCurrencyCharacters(value));
      if (!price || price < inventory.retailPrice) {
        setWebMarkup('');
        return;
      }
      const increase = price - inventory.retailPrice;
      const markup = parseFloat(increase / inventory.retailPrice) * 100.0;
      setWebMarkup(markup.toFixed(3));
    } 
  }

  // function getDealerList(companyId, oDealerId) {
  //   api.fetch(`Reference/CompanyDealerList/${companyId}?activeOnly=true`).then((response) => {
  //     if (response && response.data && response.data.success) {
  //       setDealerList(
  //         _.map(response.data.data, x => ({value: x.dealer.id, label: x.dealer.name})));
  //       if (oDealerId) {
  //         const name = _.find(response.data.data, x => x.id === oDealerId);
  //         setOriginalDealerName(name?.name);
  //       }
  //     } else {
  //       Alert.error('Failed to load dealer list: ' + response.data.message);
  //     }
  //   }).catch(error => console.error(error));
  // }

  function getManufacturerDealerList(mfgId, oDealerId) {
    api.fetch(`Manufacturer/GetManufacturerDealerList/${mfgId}?activeOnly=true`).then((response) => {
      if (response && response.data && response.data.success) {
        setDealerList(
          _.map(
            _.reject(response.data.data, x => x.deactivatedAt), 
              x => ({value: x.dealerId, label: x.dealerName})));
        if (oDealerId) {
          const name = _.find(response.data.data, x => x.id === oDealerId);
          setOriginalDealerName(name?.name);
        }
      } else {
        Alert.error('Failed to load dealer list: ' + response.data.message);
      }
    }).catch(error => console.error(error));
  }

    function commitUpload() {
        let payload = new FormData();
        payload.append('unitInventoryId', resolvedInventoryId);
        payload.append('content', files[0]);
        if (promptingUploadAttachmentTypeId) {
          payload.append('typeOfAttachment', promptingUploadAttachmentTypeId);
        }
        setClickedUpload(true);
        api.post_form_data('inventory/UploadImage', payload).then(r => {
            if (r.data.success) {
                Alert.success("The file was saved successfully")
                if (promptingUploadAttachmentTypeId) {
                    setPromptingUploadAttachmentTypeId(null);
                }
                setFiles([]);
                loadInventory();
            } else {
              Alert.warning("There was an error saving the upload");
            }
        }).catch(error => console.error(error))
            .finally(() => setClickedUpload(false));
    }

    function deleteAttachment(attachmentId) {
        api.delete(`inventory/DeleteAttachment/${attachmentId}`, {
            id: attachmentId
        }).then(r => {
            if (r.data.success) {
                loadInventory();
            } else {
                Alert.error(r.data.message);
            }
        }).catch((e) => console.error(e));
    }

  function onMoveAttachmentType() {
    if (!selectedAttachmentType) return;
    api.post(`inventory/MoveAttachment/${attachmentToMoveId}/${selectedAttachmentType.value}`).then(r => {
      if (r.data.success) {
        setAttachmentToMoveId(null);
        setSelectedAttachmentType(null);
        loadInventory();
      } else {
        Alert.error(r.data.message);
      }
    }).catch((e) => console.error(e));
  }

  function commitMove() {
    if (!moveToDealer) {
       Alert('Either select a destination store or click Cancel');
       return;
    }
    if (!inventory) return;
    api.post('Inventory/MoveInventory', {
      idList: [inventory.id],
      dealerId: moveToDealer.value
    }).then((response) => {
      if (response.data.success) {
        setShowMoveModal(false);
        loadInventory();
      } else {
        Alert.error(response.data.message);
      }
    }).catch(error => {
      console.error(error);
      setMessage({ flavor: 'danger', message: 'Error when trying to move inventory: ' + error });
    });
  }

  function onNewRto() {
    window.wasInitialCreation = true;
     api
      .fetch(`Contract/NewFromInventory/${inventory.id}`)
      .then((response) => {
        if (response.data.success) {                
          setRedirectTo(`/Contracts/Edit/${response.data.message.contractId}`);
        } else {
          Alert.warning(response.data.message);
        }
      }).catch(error => {
        console.error(error);
        setMessage({ flavor: 'danger', message: 'Error when trying to create cash RTO contract: ' + error });
      });
  }

  function onNewCashSale() {
    window.wasInitialCreation = true;
    api
      .fetch(`CashSale/NewFromInventory/${inventory.id}/${false}`)
      .then((response) => {
        if (response.data.success) {          
          setRedirectTo(`/CashSale/Edit/${response.data.message.contractId}`);
        } else {
          Alert.warning(response.data.message);
        }
      }).catch(error => {
        console.error(error);
        setMessage({ flavor: 'danger', message: 'Error when trying to create cash sale: ' + error });
      });
  }

  function onNewQuote() {
    window.wasInitialCreation = true;
    api
      .fetch(`CashSale/NewFromInventory/${inventory.id}/${true}`)
      .then((response) => {
        if (response.data.success) {
          setRedirectTo(`/Quote/Edit/${response.data.message.contractId}/${inventory.id}`);
        }
      }).catch(error => {
        console.error(error);
        setMessage({ flavor: 'danger', message: 'Error when trying to create quote: ' + error });
      });
  }

  function onApprovePay() {
    if (!resolvedInventoryId) return;
      if (!inventory.saleRequestCompanyId) {
          setShowApproveModal(true);
      } else {
          api.post("Inventory/GetInventoryPendingSaleRecord/" + resolvedInventoryId + "/" + inventory.saleRequestCompanyId)
              .then(res => {
                  if (res && res.data && res.data.success) {
                      setPendingSaleRecord(res.data.message);
                      setShowApproveModal(true);
                  }
              });
      }
  }

  const invStatus = inventory ? inventory.typeOfInventoryStatus : null;
  // const hasRetainedOwnership = ui_helpers.hasManufacturerRetainedOwnership(currentUser, inventory.manufacturerRetainedOwnershipIdList);
  const nonImageAttachments = attachments && attachments.length 
    ? _.reject(attachments, a => constants.INVENTORY_IMAGE_TYPE_IDS.includes(a.typeOfAttachment))
    : [];

  if (redirectTo) {
    return <Redirect to={redirectTo} />;
  }

  let dropDownItems = [];
  if ((invStatus === S_IDS.InReview || invStatus === S_IDS.Hold) && (isSysAdmin || isCompanyAdmin)) {
    dropDownItems.push((<DropdownItem key="on-del" className='btn-inv-context' onClick={onDelete}>Delete</DropdownItem>));
  }
  if (invStatus === S_IDS.InReview && isInventoryOwner) {
    dropDownItems.push((<DropdownItem key="on-move-stock" className='btn-inv-context' onClick={onMoveToStock}>Move to Stock</DropdownItem>));
  }
  if ((invStatus !== S_IDS.Cancelled && invStatus !== S_IDS.Sold && invStatus !== S_IDS.Hold
      && (isSysAdmin || isCompanyAdmin))) {
    dropDownItems.push((<DropdownItem key="on-hold" className='btn-inv-context' onClick={() => setShowHoldModal(true)}>Set On Hold</DropdownItem>));
  }
  if ((invStatus === S_IDS.Hold && (isSysAdmin || isCompanyAdmin))) {
    dropDownItems.push((<DropdownItem key="on-remove-hold" className='btn-inv-context' onClick={onRemoveHold}>Remove From Hold</DropdownItem>));
  }
  if (invStatus === S_IDS.InStock
      && (isSysAdmin || isCompanyAdmin || isInventoryOwner || isMfgAdmin || isResidualEditor || canDispatchBeyondAStore)) {
    dropDownItems.push((<DropdownItem key="on-move-store" className='btn-inv-context' onClick={() => setShowMoveModal(true)}>Move to Store</DropdownItem>));
  }
  if ((invStatus !== S_IDS.Cancelled && invStatus !== S_IDS.Sold && invStatus !== S_IDS.PendingContract) 
      && (isSysAdmin || isCompanyAdmin || isInventoryOwner)) 
  {
    dropDownItems.push((<DropdownItem key="on-cancel" className='btn-inv-context' onClick={() => setShowCancelModal(true)}>Cancel</DropdownItem>));
  }
  if ((inventory.billOfSaleAttachmentId && inventory.unitIsNew === true 
      && (isSysAdmin || isCompanyAdmin || isMfgUser))) {
    dropDownItems.push((<DropdownItem key="on-view-wholesale" className='btn-inv-context' onClick={onWholesaleViewInvoice}>View Wholesale Invoice</DropdownItem>));
  }
  // {(inventory.billOfSaleAttachmentId && inventory.unitIsNew === true 
  //   && (isSysAdmin || isCompanyAdmin || isMfgUser))
  //   ? (<DropdownItem className='btn-inv-context' onClick={onViewBuildingTag}>
  //       View Building Tag
  //     </DropdownItem>)
  //   : null
  // }
  if ((invStatus !== S_IDS.Cancelled && invStatus !== S_IDS.Sold) 
      && (isSysAdmin || isCompanyAdmin || isInventoryOwner)) 
  {
    dropDownItems.push((<DropdownItem key="on-apply-discount" className='btn-inv-context' onClick={() => setShowDiscountModal(true)}>Apply Discount</DropdownItem>));    
  }
  if (invStatus !== S_IDS.InReview && (isSysAdmin || isCompanyAdmin || isInventoryOwner)) {
    dropDownItems.push((<DropdownItem key="on-edit-orig" className='btn-inv-context' onClick={() => setShowPriceModal(true)}>Edit Original Price</DropdownItem>));
  }
  if ((invStatus !== S_IDS.InReview && invStatus !== S_IDS.Sold) && (isSysAdmin || isCompanyAdmin || isInventoryOwner)) {
    dropDownItems.push((<DropdownItem key="on-sell" className='btn-inv-context' onClick={() => setShowInventorySaleModal(true)}>Sell</DropdownItem>));
  }
  if (invStatus === S_IDS.Cancelled
      && (isSysAdmin || isCompanyAdmin || isMfgUser)) {
    dropDownItems.push((<DropdownItem key="on-revive" className='btn-inv-context' onClick={onRevive}>Revive</DropdownItem>));
  }

  function cancelShowApproveModal() {
      setCheckNo('');
      setComment('');
      setPaidOn(moment());
      setShowApproveModal(false);
  }

  function saveSaleCallback(key = null) {
      loadInventory();
      if (key !== null) {
        onDownloadAttachment(null, key);
      }
  }

  function resolvedHeight() {
    let heightDsc = '';
    if (!inventory || (!inventory.unitHeightFeet && !inventory.unitHeightFeetOtherText)) return heightDsc;
    if (inventory.unitHeightFeetOtherText) {
      return ` x ${inventory.unitHeightFeetOtherText}' H`;
    }
    return ` x ${inventory.unitHeightFeet}' H`;
  }

  function toggleShowArchivedAttachments() {
    setShowArchivedAttachments(!showArchivedAttachments);
  }

  function onSetStandardRetailPrice() {
    let msg = `Are you sure you want to set this item's price to ${ui_helpers.formatCurrency(inventory.standardRetailPrice)}?`;
    if (inventory.webVisible) {
      msg = msg + ' This item is being sold on the website. Be sure to update that price separately if necessary.'
    }
    confirmAlert({
      title: "Reset Pricing",
      message: msg,
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            api.post('Inventory/SetStandardRetailPrice', {id: inventory.id}).then((response) => {
              if (response.data.success) {          
                loadInventory();
              } else {
                Alert.error(response.data.message);
              }
            }).catch(error => {
              console.error(error);
              setMessage({ flavor: 'danger', message: 'Error while trying to update price: ' + error });
            });
          }
        },
        {
          label: "No"
        }
      ]
    });
  }

  function calculateWholesaleDiscount(wholesalePrice, retailPrice) {
    if (!wholesalePrice || !retailPrice) return 0;
    const retail = parseFloat(retailPrice);
    const pct = (retail - parseFloat(wholesalePrice)) / retail;
    return (pct * 100).toFixed(2);
  }

  const groupedAddOns = addOnList && addOnList.length
    ? _.groupBy(addOnList, x => x.displayNameWithOptionDescription)
    : {};
  const mfgName = inventory
    ? inventory.manufacturerBrandName || inventory.manufacturerName
    : '';
  const enteredByName = inventory
    ? inventory.createdByUserFullName
    : '';
  return (
  <>
        <Header toolbar inventory invoiceNumber={inventory && inventory.invoiceNumber? inventory.invoiceNumber : null}>
          {attachmentToMoveId 
                  ? (<Modal isOpen fullscreen="sm" toggle={() => setAttachmentToMoveId(null)}>
                          <ModalHeader>
                              Move Attachment To Web Image
                          </ModalHeader>
                          <ModalBody>
                              <Row className="mb-2">
                                  <Col xs="12">
                                  Select an Image Type:
                                  <Select
                                    placeholder="Select" 
                                    options={constants.INVENTORY_IMAGE_TYPES}
                                    value={selectedAttachmentType}
                                    onChange={setSelectedAttachmentType} 
                                  />
                                  </Col>
                              </Row>
                          </ModalBody>
                          <ModalFooter>
                              <Row className={'d-flex justify-content-end'}>
                                  <Col>
                                      <ButtonGroup className="float-end">
                                          <Button color='primary' onClick={onMoveAttachmentType}>Save</Button>
                                          <Button onClick={() => setAttachmentToMoveId(null)}>Close</Button>
                                      </ButtonGroup>
                                  </Col>
                              </Row>
                          </ModalFooter>
                    </Modal>
                  ) : null}
          {showWebSaleModal
            ? (<Modal isOpen fullscreen="sm" toggle={() => setShowWebSaleModal(false)}>
                <ModalHeader>
                  Set Inventory Details
                </ModalHeader>
                <ModalBody>
                  <Row className="mb-2">
                    <Col xs="12">
                      <Input type='text' placeholder="Web Description (Optional)" maxLength="350" value={webDsc} onChange={(e) => setWebDsc(e.target.value)}/>
                    </Col>
                  </Row>
                  <Row className="mb-2">
                    <Col xs="6">
                      <Label>Unit Price</Label>
                    </Col>
                    <Col xs="6">
                      <Input type='text' value={ui_helpers.formatCurrency(inventory.retailPrice)} readOnly disabled />
                    </Col>
                  </Row>
                  <Row className="mb-2">
                    <Col xs="6">
                      <Label>Web Product Name</Label>
                    </Col>
                    <Col xs="6">
                      <Input type='text' maxLength="150" value={webProductName} onChange={(e) => setWebProductName(e.target.value)}/>
                    </Col>
                  </Row>
                  <Row className="mb-2">
                    <Col xs="6">
                      <Label>Web Markup %</Label>
                    </Col>
                    <Col xs="6">
                      <Input type='text' maxLength="10" value={webMarkup} onChange={(e) => onChangeWebPrice('webMarkup', e.target.value)}/>
                    </Col>
                  </Row>
                  <Row className="mb-2">
                    <Col xs="6">
                      <Label>Web Price</Label>
                    </Col>
                    <Col xs="6">
                      <DebounceInput
                        type="text"
                        maxLength="10"
                        minLength="2"
                        className="form-control"
                        value={webSalePrice} 
                        onChange={(e) => onChangeWebPrice('webSalePrice', e.target.value)}
                        debounceTimeout={300}
                      />
                    </Col>
                  </Row>
                </ModalBody>
                <ModalFooter>
                  <Row className={'d-flex justify-content-end'}>
                    <Col>
                      {savingWebSale
                        ? <Spinner size='sm'/>
                        : (<ButtonGroup className="float-end">
                            <Button color='primary' onClick={onMarkForWebSale}>Save</Button>
                            <Button onClick={() => setShowWebSaleModal(false)}>Close</Button>
                          </ButtonGroup>)
                      }                      
                    </Col>
                  </Row>
                </ModalFooter>
              </Modal>)
            : null
          }
          {showApproveModal
            ? (<Modal fullscreen="sm" toggle={() => { setPendingSaleRecord(null); cancelShowApproveModal()}} isOpen>
                <ModalHeader>
                  Provide Payment Details
                </ModalHeader>
                <ModalBody>
                    {message && <UncontrolledAlert color={message.flavor} >{message.message}</UncontrolledAlert >}
                 {pendingSaleRecord ? <>
                  <Row className="mb-2">
                    <Col xs="4">
                      <Label>Paying (Wholesale)</Label>
                    </Col>
                    <Col xs="7">
                      <Input
                        value={pendingSaleRecord.purchaseAmountDisplay}
                        disabled
                      />
                    </Col>
                  </Row>
                    {pendingSaleRecord.discount !== 0 &&
                        <Row className="mb-2">
                            <Col xs="4">
                                <Label>Discount Applied</Label>
                            </Col>
                            <Col xs="7">
                                <Input
                                    value={pendingSaleRecord.discountDisplay}
                                    disabled
                                />
                            </Col>
                        </Row>
                    }
                  <Row className="mb-2">
                    <Col xs="4">
                      <Label>Sale Term</Label>
                    </Col>
                    <Col xs="7">
                      <Input
                        value={pendingSaleRecord.saleTermsName}
                        disabled
                      />
                    </Col>
                  </Row>
                  </> : null}
                  <Row className="mb-2">
                    <Col xs="4">
                      <Label>Paid On</Label>
                    </Col>
                    <Col xs="7">
                      <DatePicker
                        selected={paidOn ? paidOn.toDate() : null}
                        onChange={(date) => setPaidOn(moment(date))}
                        showMonthDropdown
                        showYearDropdown
                        isClearable
                        withPortal
                        dropdownMode="select"
                      />
                    </Col>
                  </Row>
                  <Row className="mb-2">
                    <Col xs="4">
                      <Label>Check #</Label>
                    </Col>
                    <Col xs="7">
                      <Input type='text' maxLength="20" value={checkNo} onChange={(e) => setCheckNo(e.target.value)} />
                    </Col>
                  </Row>
                  <Row>
                    <Col xs="4">
                      <Label>Comment</Label>
                    </Col>
                    <Col xs="7">
                      <Input type='textarea' maxLength="800" value={comment} onChange={(e) => setComment(e.target.value)} placeholder="(Optional)" />
                    </Col>
                  </Row>
                </ModalBody>
                <ModalFooter>
                  <Row className={'d-flex justify-content-end'}>
                    <Col>
                      <ButtonGroup>
                        {pendingSaleRecord &&
                            <Button title="Deny Sale" color='danger' className="text-light" onClick={onDeny}>No Thank You</Button>
                        }
                        <Button title="Approve Sale" color='primary' onClick={onApprove}>Save</Button>
                        <Button onClick={() => { setPendingSaleRecord(null); cancelShowApproveModal()}}>Close</Button>
                      </ButtonGroup>
                    </Col>
                  </Row>
                </ModalFooter>
              </Modal>)
            : null
          }
          {showCommentModal
            ? (<Modal fullscreen="sm" toggle={() => setShowCommentModal(false)} isOpen>
                <ModalHeader>
                  Add Comment
                </ModalHeader>
                <ModalBody>
                  <Input type='textarea' className="comment-textarea" maxLength="800" value={comment} onChange={(e) => setComment(e.target.value)} />
                  {ui_helpers.isContextCompanyUser(currentUser, inventory.companyId) && (
                    <div className='mb-1' style={{cursor: "pointer"}} onClick={(e) => {
                        e.stopPropagation();
                        setCommentPrivate(!commentPrivate);
                      }}>
                      <Input type="checkbox" checked={commentPrivate} onChange={(e) => {
                        e.stopPropagation();
                        setCommentPrivate(!commentPrivate);
                      }} /> Private
                    </div>)
                  }
                </ModalBody>
                <ModalFooter>
                  <Row className={'d-flex justify-content-end'}>
                    <Col>
                      <ButtonGroup className="float-end">
                        <Button color='primary' onClick={submitComment}>Save</Button>
                        <Button onClick={() => setShowCommentModal(false)}>Close</Button>
                      </ButtonGroup>
                    </Col>
                  </Row>
                </ModalFooter>
              </Modal>)
            : null
          }
          {showModelModal
            ? <ModelModal
                modelNumber={modelNumber}
                changeModelNumber={changeModelNumber}
                setShowModelModal={setShowModelModal}
                setModelNumber={setModelNumber}
              />
            : null
          }
          {showPriceModal
            ? (<InventoryPriceModal
                setShowPriceModal={setShowPriceModal}
                editPrice={editPrice}
                setEditPrice={setEditPrice}
                editOriginalPrice={editOriginalPrice}
              />)
            : null
          }
          {showDiscountModal
            ? (<InventoryDiscountModal
                setShowDiscountModal={setShowDiscountModal}
                discount={discount}
                setDiscount={setDiscount}
                applyDiscount={applyDiscount}
                isWebVisible={inventory?.webVisible}
              />)
            : null
          }
          {showCancelModal
            ? <CancelInventoryModal
                setShowCancelModal={setShowCancelModal}
                cancelComment={cancelComment}
                setCancelComment={setCancelComment}
                cancelInventory={cancelInventory}
              />
            : null
          }
          {showHoldModal
            ? (<Modal isOpen fullscreen="sm" toggle={() => setShowHoldModal(false)}>
                <ModalHeader>
                  Set Inventory Into Hold Status
                </ModalHeader>
                <ModalBody>
                  <Input type='text' maxLength="800" placeholder="Comment (Optional)"
                    value={rejectComment} onChange={(e) => setRejectComment(e.target.value)}/>
                </ModalBody>
                <ModalFooter>
                  <Row className={'d-flex justify-content-end'}>
                    <Col>
                      <ButtonGroup className="float-end">
                        <Button color='primary' onClick={onSetHold}>Save</Button>
                        <Button onClick={() => setShowHoldModal(false)}>Close</Button>
                      </ButtonGroup>
                    </Col>
                  </Row>
                </ModalFooter>
              </Modal>)
            : null
          }
          {showSerialModal
            ? <SetSerialModal
                serialNumber={serialNumber}
                setSerialNumber={setSerialNumber}
                setShowSerialModal={setShowSerialModal}
                changeSerialNumber={changeSerialNumber}
                associatedContractId={inventory.contractId}
              />
            : null
          }
          {showInventorySaleModal && (
              <SellInventoryModal
                toggle={setShowInventorySaleModal}
                inventoryId={inventory.id}
                retailPrice={(inventory.contractSalePrice ? inventory.contractSalePrice : inventory.retailPrice)}
                callback={saveSaleCallback}
              />
          )}
          {showMoveModal
                  ? (<Modal fullscreen="sm" toggle={() => setShowMoveModal(false)} isOpen>
                      <ModalHeader>
                        Move inventory to...
                      </ModalHeader>
                      <ModalBody>
                          <Row className="mb-2">
                              <Col xs="3" className="pt-2">
                                <Label>Store</Label>
                              </Col>
                              <Col xs="9">
                                <Select
                                  options={_.reject(dealerList, d => d.value <= 0 || d.value === inventory.dealerId)}
                                  value={moveToDealer}
                                  onChange={(e) => setMoveToDealer(e)}
                                />
                              </Col>
                          </Row>
                      </ModalBody>
                      <ModalFooter>
                          <Row className={'d-flex justify-content-end'}>
                            <Col>
                              <ButtonGroup className="float-end">
                                <Button color='primary' onClick={commitMove}>Move</Button>
                                <Button onClick={() => setShowMoveModal(false)}>Cancel</Button>
                              </ButtonGroup>
                            </Col>
                          </Row>
                      </ModalFooter>
                  </Modal>)
                  : null
              }
          <div className="d-flex justify-content-between">
            <div>
            <ButtonGroup>
              {(invStatus === S_IDS.Hold || invStatus === S_IDS.InStock || invStatus === S_IDS.InProduction || (invStatus === S_IDS.InReview && !inventory.saleRequestCompanyId))
              && (isSysAdmin || isCompanyAdmin || (isInventoryOwner && isMfgUser))
                  ? (<Button tag={Link} to={`${PATHNAMES.EDIT_INVENTORY}/${inventory.id}`} color="warning">
                      Edit
                    </Button>)
                  : null
              }
              {savingWebSale
                ? <Spinner size='sm'/>
                : null
              }
              {invStatus === S_IDS.InStock && (isSysAdmin || isCompanyAdmin || isDealerAdmin) && inventory.dealerHasOnlineStore
                  ? (<Fragment>
                      {inventory.webVisible
                        ? (<Button onClick={onRemoveFromWebSale} color="warning">Remove from Web Sale</Button>)
                        : (<Button onClick={() => {
                          setWebProductName(inventory.unitManufacturerSubProductText);
                          setShowWebSaleModal(true);
                        }} color="primary">Mark for Web Sale</Button>)
                      }
                    </Fragment>)
                  : null
              }
              {(isSysAdmin || isInvSaleCompanyAdmin) //  || isRelatedContractOwner || isInventoryOwner
                 && invStatus === S_IDS.InReview
                ? (<Button color="success" className="text-light px-4 me-2 rounded" onClick={onApprovePay}>
                    Approve &amp; Pay
                  </Button>)
                : null
              }
              {inventory
                && !inventory.contractId
                && invStatus !== S_IDS.Hold
              ? (<div>
                  <Dropdown direction="down"
                    toggle={() => setConvertDropDownOpen(!convertDropDownOpen)}
                    isOpen={convertDropDownOpen}
                    className='me-2 button-round'
                  >
                    <DropdownToggle caret color='dark'>
                      Convert to New
                    </DropdownToggle>
                    <DropdownMenu onMouseLeave={() => setConvertDropDownOpen(!convertDropDownOpen)}>                    
                    {companyEnableRTOContracts
                      ? (<DropdownItem onClick={onNewRto}>  
                          RTO Contract
                         </DropdownItem>)
                      : null
                    }                    
                    {companyEnableCashSales
                      ? (<DropdownItem onClick={onNewCashSale}>
                          Cash Sale
                         </DropdownItem>)
                      : null
                    }
                    <DropdownItem onClick={onNewQuote}>
                          Quote
                    </DropdownItem>
                      </DropdownMenu>
                  </Dropdown>
                </div>)
                : null 
              }
              </ButtonGroup>
            </div>
            <div className="d-flex">
            {inventory && dropDownItems.length
              ? (<div>
                  <Dropdown direction="down" toggle={() => setDropDownOpen(!dropDownOpen)} isOpen={dropDownOpen}
                    className='me-2 button-round'>
                    <DropdownToggle caret={false} color='primary'>
                      <FontAwesomeIcon icon='wrench' style={{color: '#fff'}}/>
                    </DropdownToggle>
                    <DropdownMenu className='bg-primary' onMouseLeave={() => setDropDownOpen(!dropDownOpen)}>
                      {dropDownItems}
                    </DropdownMenu>
                  </Dropdown>
                </div>)
              : null 
            }
              <div className="followup-icons bg-light rounded">
              {inventory ?
                <FollowUpButtons
                  size='md'
                  done={rtoCompleted} 
                  followUpItem={constants.FOLLOW_UP.RTO}
                  followUpStatusCallback={(typeOfFollowUp, completed) => {
                    if (isSysAdmin || isAnyRTOCompanyAdmin) {
                      setFollowUpStatus(typeOfFollowUp, completed);
                    }
                  }}
                />
                : null
              }
              </div>
            </div>
          </div>
        </Header>
            {isLoading ? (<Loading />) 
              : (<Container className="mt-4">
                    <Row className='mb-3'>
                        <Col xs={3} className="pt-1">
                          <h4>
                            Invoice # <span className='dark-blue-text'>{inventory.invoiceNumber}</span>
                          </h4>
                        </Col>
                        <Col xs={3} className="pt-1">
                          <h4>
                            <span className="dark-blue-text" title={mfgName}>
                              {ui_helpers.chop(mfgName, 25)}
                            </span>
                          </h4>
                        </Col>
                        <Col xs={4} className="pt-1">
                          <h4>
                            <span className='text-sm-center'>Entered by</span> <span className="dark-blue-text" title={enteredByName}>
                              {ui_helpers.chop(enteredByName, 25)}
                            </span>
                          </h4>
                        </Col>
                        {inventory
                          ? (<Col xs={2}>
                              <h3 className='float-end'>
                                  <InventoryStatusBadge inventoryStatus={
                                    _.some(NO_OVERRIDE_S_ID, s => s === inventory.typeOfInventoryStatus)
                                      ? inventory.typeOfInventoryStatus
                                      : inventory.resolvedTypeOfInventoryStatus} 
                                  />
                              </h3>
                            </Col>)
                          : null
                        }
                    </Row>
                    <Row>
                        <Col>
                          <DetailsTable>
                            <DetailsRow
                                heading="Owned By"
                                data={inventory.companyName}
                            />
                            <tr>
                              <th>Serial #</th>
                              <td>
                                <Row className='align-items-center'>
                                  <Col sm="8">{inventory.serialNumber}</Col>
                                  {(isSysAdmin || isCompanyAdmin)
                                    ? (<Col>
                                        <Button size='sm' color='warning' className='float-end' 
                                          onClick={() => {
                                            setSerialNumber(inventory.serialNumber);
                                            setShowSerialModal(true);
                                          }}>Set</Button>
                                      </Col>)
                                    : null
                                  }
                                </Row>
                              </td>
                            </tr>
                              <DetailsRow
                                  heading="Store Lot"
                                  data={inventory.dealerName}
                              />
                              <DetailsRow
                                  heading="Inventory Start Date"
                                  data={date_helpers.formatDateToShortDate(inventory.inventoryOn)}
                              />
                              {inventory.contractId
                                ? (<DetailsRow
                                    heading="Contract Price"
                                    data={ui_helpers.formatCurrency(inventory.contractSalePrice)}
                                  />)
                                : null
                              }
                              <tr>
                                <th>Price</th>
                                <td>
                                  {inventory.standardRetailPrice !== inventory.retailPrice && (isSysAdmin || isCompanyAdmin)
                                    ? (<Button size='sm' color='dark' className='float-end' onClick={onSetStandardRetailPrice}>
                                        Refresh to {ui_helpers.formatCurrency(inventory.standardRetailPrice)}
                                      </Button>)
                                    : null
                                  }
                                  {ui_helpers.formatCurrency(inventory.retailPrice)}                                  
                                </td>
                              </tr>
                              {(isSysAdmin || isCompanyAdmin || isInventoryOwner || isResidualEditor)
                                ? (<Fragment>
                                    <DetailsRow
                                      heading="Wholesale Discount"
                                      data={`${calculateWholesaleDiscount(inventory.wholesalePrice, 
                                        inventory.contractId ? inventory.contractSalePrice : inventory.retailPrice)}%`}
                                    />
                                    <DetailsRow
                                      heading="Wholesale Price"
                                      data={ui_helpers.formatCurrency(inventory.wholesalePrice)}
                                    />
                                  </Fragment>)
                                : null
                              }
                              <tr>
                                <th>RTO ID</th>
                                <td>
                                  <Row className='align-items-center'>
                                    <Col sm="8">{inventory.modelNumber}</Col>
                                    {(isSysAdmin || isCompanyAdmin || isInvSaleCompanyAdmin)
                                      ? (<Col>
                                          <Button size='sm' color='warning' className='float-end' 
                                            onClick={() => {
                                              setModelNumber(inventory.modelNumber);
                                              setShowModelModal(true);
                                            }}>Set</Button>
                                        </Col>)
                                      : null
                                    }
                                  </Row>
                                </td>
                              </tr>
                              <tr>
                                <th>Contract</th>
                                <td>
                                  <Row className='d-flex align-items-center justify-content-center'>
                                    <Col sm='6'>
                                      <Link to={`${PATHNAMES.CONTRACT_VIEW}/${inventory.contractId}`}>{inventory.contractId}</Link>
                                    </Col>
                                    <Col>
                                      {(isSysAdmin || isPairedContractCompanyAdmin) && inventory.contractId
                                        ? (<ButtonGroup className="float-end" size='sm'>
                                            {hasUnprocessedContract
                                              ? (<Button size='sm' color='info' className='float-end text-light' onClick={processContract}>Process Contract {inventory.contractId}</Button>)
                                              : null
                                            }
                                            <Button size='sm' color='danger' className='float-end text-light' onClick={unPairContract}>Unpair</Button>
                                          </ButtonGroup>)
                                        : null
                                      }
                                    </Col>
                                  </Row>
                                </td>
                              </tr>
                              <DetailsRow
                                heading="Order Note"
                                data={inventory.orderNote}
                              />
                              {inventory.webVisible && (
                                <Fragment>
                                  <tr>
                                      <th>Web Sale</th>
                                      <td>
                                          <Row className='align-items-center'>
                                              <Col sm='6'>
                                                  {inventory.webPriceDisplay}
                                              </Col>
                                              <Col>
                                                  {isSysAdmin || isCompanyAdmin
                                                      ? (<ButtonGroup className="float-end">
                                                      <Button
                                                          size='sm'
                                                          color='warning'
                                                          className='float-end text-light'
                                                          onClick={() => {
                                                              setWebMarkup(inventory.webMarkupPercent)
                                                              setWebSalePrice(inventory.webPrice)
                                                              setWebDsc(inventory.webDescription)
                                                              setShowWebSaleModal(true);
                                                          }}
                                                      >
                                                          Update
                                                      </Button>
                                                      </ButtonGroup>)
                                                      : null
                                                  }
                                              </Col>
                                          </Row>
                                      </td>
                                  </tr>
                                  <tr>
                                      <th>Web Markup Percentage</th>
                                      <td>
                                          <Row className='align-items-center'>
                                              <Col sm='6'>
                                                  {inventory.webMarkupPercentDisplay && inventory.webMarkupPercentDisplay + " %"}
                                              </Col>
                                              <Col/>
                                          </Row>
                                      </td>
                                  </tr>
                                  <tr>
                                      <th>Web Product Name</th>
                                      <td>
                                          <Row className='align-items-center'>
                                              <Col sm='6'>
                                                  {inventory.webProductName }
                                              </Col>
                                              <Col/>
                                          </Row>
                                      </td>
                                  </tr>
                                  <tr>
                                      <th>Web Description</th>
                                      <td>
                                          <Row className='align-items-center'>
                                              <Col sm='6'>
                                                  {inventory.webDescription || <span style={{fontStyle: "italic"}}>(none)</span>}
                                              </Col>
                                              <Col/>
                                          </Row>
                                      </td>
                                  </tr>
                              </Fragment>)}
                            </DetailsTable>
                        </Col>
                        <Col>
                            <DetailsTable>
                                <DetailsRow
                                    heading='Product Type'
                                    data={inventory.productTypeName}
                                    />
                                <DetailsRow
                                    heading='New?'
                                    data={inventory.unitIsNew ? 'Yes' : 'No'}
                                />
                                <DetailsRow
                                    heading='Manufacturer (Brand)'
                                    data={inventory.manufacturerBrandName || inventory.manufacturerName}
                                />
                                <DetailsRow
                                    heading='Material'
                                    data={inventory.unitTypeName}
                                />
                                <DetailsRow
                                    heading='Base Color'
                                    data={inventory.unitManufacturerBaseColorText}
                                />
                                <DetailsRow
                                    heading='Trim Color'
                                    data={inventory.unitManufacturerTrimColorText}
                                />
                                <DetailsRow
                                    heading='Roof Color'
                                    data={inventory.unitManufacturerRoofColorText}
                                />
                                <DetailsRow
                                    heading='Style'
                                    data={
                                      <div>
                                        {inventory.unitManufacturerSubProductText} {(inventory.porchColorName
                                          ? <span style={{fontStyle: "italic"}}> (Porch Color: {inventory.porchColorName})</span>
                                          : null)}
                                      </div>}
                                />
                                <DetailsRow
                                    heading='Dimensions (W x L x H)'
                                    data={`${inventory.unitWidthFeetOtherText ? inventory.unitWidthFeetOtherText : inventory.unitWidthFeet
                                    }' W x ${inventory.unitLengthFeetOtherText ? inventory.unitLengthFeetOtherText : inventory.unitLengthFeet
                                    }' L${resolvedHeight()}`}/>
                                <DetailsRow
                                  heading='Add-Ons'
                                  data={
                                    (<Table className="mb-0">
                                      <tbody>
                                      {inventory && addOnList.length
                                          ? _.map(_.keys(groupedAddOns), addOnName => ((
                                            <tr key={addOnName}>
                                              <td>
                                                {groupedAddOns[addOnName].length} x {addOnName}
                                              </td>
                                            </tr>)))
                                          : (<tr><td colSpan="2">There are no Add-Ons for this Inventory Item</td></tr>)}
                                      </tbody>
                                    </Table>)
                                  }
                                />
                            </DetailsTable>
                        </Col>
                  </Row>
                  {(!isNew || repo) && (isCompanyAdmin || isSysAdmin) ? (
                    <UsedBuildingDetails
                      inventory={inventory}
                      repoData={repo}
                      readOnly
                      pl={pl}
                      contractSalePrice={inventory?.contractSalePrice}
                    />) 
                    : null
                  }
                    <Row className='d-flex'>
                      {inventory.hasBuildSteps 
                        && (isMfgUser || isSysAdmin)
                        && inventory.typeOfInventoryStatus !== constants.INVENTORY_STATUS_ID.Draft
                        ? (<Col>
                            <ButtonGroup className='float-start'>
                              <Button tag={Link} color='secondary' outline to={`${PATHNAMES.BUILD_DETAILS}/${inventory.id}`} title="Go to Build Details screen">
                                Build Info
                              </Button>
                            </ButtonGroup>
                          </Col>)
                        : null
                      }
                      <Col>
                        <ButtonGroup className='float-end'>
                          {(isSysAdmin || isCompanyAdmin || isInventoryOwner || isResidualEditor || isInvSaleCompanyAdmin)
                            ? (<Fragment>
                              {(isInventoryOwner || isInvSaleCompanyAdmin) && inventory.saleRequestCompanyId
                                ? (<Button color='info' outline className='border' onClick={loadingBillOfSale ? null : generatePreSaleWholesaleInvoice} title="Invoice will NOT be saved to in the system ">
                                    {loadingBillOfSale ? (<Spinner size='sm'/>) : 'Generate Pre-Sale Wholesale Invoice'}
                                  </Button>)
                                : null
                              }
                              {isSysAdmin || isCompanyAdmin
                                ? (<Button color='dark' onClick={loadingBillOfSale ? null : regenBillOfSale}>
                                    {loadingBillOfSale ? (<Spinner size="sm"/>) : 'Re-generate Wholesale Invoice'}
                                  </Button>)
                                : null
                              }
                              </Fragment>)
                            : null
                          }
                          <Button color='light' className='border' onClick={!loadingBuildingTag ? regenBuildingTag : null}>
                            {loadingBuildingTag ? (<Spinner size='sm'/>) : 'Re-generate Building Tag'}
                          </Button>
                        </ButtonGroup>
                      </Col>
                    </Row>                   
                    <Row className="mt-3">
                        <Accordion open={openAccordionItem} toggle={toggleAccordion}>
                            <AccordionItem>
                                <AccordionHeader targetId={TABS.ATTACHMENTS}>
                                    <FontAwesomeIcon icon="paperclip" className="me-2" />
                                    Attachments
                                    <Badge color='dark' className={'ms-2 align-items-center justify-content-center text-center'}>
                                      {nonImageAttachments && _.filter(nonImageAttachments, (a) => a.deactivatedAt === null)?.length}
                                    </Badge>
                                    {/*<FontAwesomeIcon icon="paperclip" className="me-2" />*/}
                                    {/*Attachments {nonImageAttachments.length */}
                                    {/*    ? (<Badge className='ms-2'>{nonImageAttachments.length}</Badge>)*/}
                                    {/*    : null*/}
                                    {/*  }*/}
                                </AccordionHeader>
                                <AccordionBody accordionId={TABS.ATTACHMENTS}>
                                  {isSysAdmin && <Row className={"d-flex justify-content-end"}>
                                    <Col xl={3}>
                                      <Button className={"float-end d-flex justify-content-evenly align-items-center my-1"} onClick={toggleShowArchivedAttachments}>
                                        <FontAwesomeIcon icon={showArchivedAttachments ? "eye-slash" : "eye"}/>
                                        <div className={"mx-1"}>{showArchivedAttachments ? "Hide" : "Show"} Archived</div>
                                        <Badge color={"dark"}>{nonImageAttachments ? _.filter(nonImageAttachments, (a) => a.deactivatedAt !== null)?.length : 0}</Badge>
                                      </Button>
                                    </Col>
                                  </Row>}
                                    <Row>
                                        <Col>
                                      <Table bordered>
                                          <tbody>
                                          {nonImageAttachments && nonImageAttachments.length ?
                                          _.map(nonImageAttachments, a => {
                                            if(!showArchivedAttachments && a.deactivatedAt !== null) return;
                                              return (
                                                  <tr key={`a-${a.key}`}>
                                                  <td>
                                                      <Row>
                                                          <Col className={'d-flex justify-items-evenly'}>
                                                            <div>
                                                              <a href='#' onClick={(e) => onDownloadAttachment(e, a.key, a.name)} className={showArchivedAttachments && a.deactivatedAt !== null ? 'text-muted' : ''}>{a.name}</a>
                                                            </div>
                                                            <div className={'text-muted ms-2'}>
                                                              {a.deactivatedAt !== null ? " - Archived" : null}
                                                            </div>
                                                          </Col>
                                                          <Col className='col-4'>
                                                              <Row><Col className={showArchivedAttachments && a.deactivatedAt !== null ? "text-muted" : null}>{`${date_helpers.dateTimeFormat(a.createdAt)}`}</Col><Col className={showArchivedAttachments && a.deactivatedAt !== null ? "text-muted" : null}>{`${a.createdByUserFirstName} ${a.createdByUserLastName}`}</Col></Row>
                                                          </Col>
                                                          <Col className='col-1'>
                                                            {a.deactivatedAt === null
                                                              ? <Button color='danger' size='sm' className='text-white float-end'
                                                                onClick={() => onDeleteWebImage(a)}>
                                                                <FontAwesomeIcon icon='times-circle'/>
                                                              </Button>
                                                                : null}
                                                          </Col>
                                                      </Row>
                                                  </td>
                                              </tr>)
                                          }) : <tr><td>There are no attachments for this item.</td></tr>}
                                          </tbody>
                                      </Table>
                                        </Col>
                                    </Row>
                                </AccordionBody>
                            </AccordionItem>
                            <AccordionItem>
                              <AccordionHeader targetId={TABS.COMMENTS}>
                                <FontAwesomeIcon icon="comment" className="me-2" />
                                Comments
                                <Badge color='dark' className={'ms-2 align-items-center justify-content-center text-center'}>
                                  {comments && comments.length}
                                </Badge>
                              </AccordionHeader>
                              <AccordionBody accordionId={TABS.COMMENTS} className="pt-1">
                                  {canManageComments
                                    ? (<Row className="mb-1">
                                        <Col>
                                          <Button
                                            color='success'
                                            className="float-end text-light"
                                            size='sm'
                                            onClick={() => {
                                              setComment('');
                                              setShowCommentModal(true);
                                            }}>
                                            <FontAwesomeIcon icon="plus" /> Add Comment
                                          </Button>
                                        </Col>
                                      </Row>)
                                    : null
                                  }
                                  <Table size="sm">
                                    <tbody>
                                      {comments && comments.length
                                        ? _.map(comments, c => 
                                        (<tr key={c.id} className={classnames({private: c.isPrivate}, "comment-row")}>
                                          <td className="content" dangerouslySetInnerHTML={{__html: c.dsc}} />
                                          <td style={{width: "180px"}}>{c.createdAtLocalized}</td>
                                          <td style={{width: "200px"}}>{c.userFullName}</td>
                                          <td style={{ width: "50px" }}>
                                              {(c.userId === currentUser.id || isSysAdmin || isCompanyAdmin) && canManageComments &&
                                                  (<Button className="text-light bg-cf-red" size="sm" title="Delete" onClick={() => deleteComment(c.id)}>
                                              <FontAwesomeIcon icon='times-circle' />
                                            </Button>)}
                                          </td>
                                        </tr>))
                                        : (<tr><td>No Comments have yet been recorded</td></tr>)
                                      }
                                    </tbody>
                                  </Table>
                                </AccordionBody>
                            </AccordionItem>
                          <AccordionItem>
                              <AccordionHeader targetId={TABS.UPLOADS}>
                                  <FontAwesomeIcon icon="file-upload" className="me-2" />
                                  Uploads
                                  <Badge color='dark' className={'ms-2 align-items-center justify-content-center text-center'}>
                                    {inventoryUploadList && inventoryUploadList.length}
                                  </Badge>
                              </AccordionHeader>
                              <AccordionBody accordionId={TABS.UPLOADS}>
                                  {(canManageInventory || isSysAdmin || isStoreUser)
                                    ? (<Dropzone onDrop={fileList => setFiles([fileList[0]])}
                                      accept={["image/png", "image/jpeg", "application/pdf"]} style={{ width: "100%", backgroundColor: "#F0F0F0", borderRadius: "6px" }}>
                                      {({ getRootProps, getInputProps }) => (
                                          <div {...getRootProps()} className="text-center">
                                              <input {...getInputProps()} />
                                              <div style={{ textAlign: "center" }} className="pt-3">
                                                  <div className="mb-1">Drop file here or click the icon</div>
                                                  <FontAwesomeIcon color={files.length && "secondary"} icon={files.length ? "check-square" : "file-upload"} size="3x" className="mb-4" />
                                                  {files.length ? _.map(files, (f) => (<div className="description-text">{f.name}</div>)) : null}
                                              </div>
                                          </div>
                                      )}
                                    </Dropzone>)
                                      : null
                                  }
                                  {files.length
                                      ? (<ButtonGroup size="sm" className="float-end text-light">
                                          <Button
                                              color='secondary'
                                              size="sm"
                                              disabled={clickedUpload}
                                              onClick={() => setFiles([])}
                                          >
                                              Cancel
                                          </Button>
                                          <Button
                                              color='primary'
                                              size="sm"
                                              disabled={clickedUpload}
                                              className="float-end py-2 text-light"
                                              onClick={commitUpload}
                                          >
                                              Save
                                          </Button>
                                      </ButtonGroup>
                                      )
                                      : null
                                  }
                                  <div>
                                    {inventoryUploadList.length
                                        ? (<Fragment>
                                            {_.map(inventoryUploadList, ci => (
                                              <span key={ci.id} className="image-border">
                                                <div className="inline-block" style={{width: "30%"}}>
                                                  <img className="img-thumbnail"
                                                      title={`Added by ${ci.createdByUserFirstName} ${ci.createdByUserLastName} at ${date_helpers.shortYearDateTimeFormat(ci.createdAt)}`}
                                                      src={`/api/Inventory/ViewImage/${ci.key}?preview=true`} />
                                                  {canManageInventory
                                                    ? (<div style={{clear: "both", margin: "4px auto", textAlign: "center"}}>
                                                        <ButtonGroup>
                                                          <Button color='dark' className="xs text-light" onClick={() => onDownloadAttachment(null, ci.key, ci.name)} title="Download">
                                                            <FontAwesomeIcon icon="download" />
                                                          </Button>
                                                          <Button color='info' className="xs text-light" onClick={() => setAttachmentToMoveId(ci.id)} title="Move to Web Images">
                                                            <FontAwesomeIcon icon="exchange-alt" />
                                                          </Button>
                                                          <Button color='danger' className="xs text-light" onClick={() => deleteAttachment(ci.id)} title="Remove">
                                                            <FontAwesomeIcon icon="times-circle" />
                                                          </Button>
                                                        </ButtonGroup>
                                                      </div>)
                                                    : null
                                                  }
                                                </div>
                                              </span>))
                                            }
                                        </Fragment>)
                                      : (<div>No images are available.</div>)
                                    }
                                  </div>
                              </AccordionBody>
                          </AccordionItem>
                            <AccordionItem>
                                <AccordionHeader targetId={TABS.WEB_IMAGES}>
                                  <FontAwesomeIcon icon="image" className="me-2" />
                                    Web Images
                                </AccordionHeader>
                                <AccordionBody accordionId={TABS.WEB_IMAGES}>
                                    <div style={{fontSize: "14px"}}>
                                      {_.map(inventoryImageTypeList, t => {
                                          const att = _.find(attachments, a => a.typeOfAttachment === t.value);
                                          return (
                                            <div key={t.value} className="inv-image-frame" style={att ? {width: "350px"} : null}>
                                              <div style={{textAlign: "center", fontWeight: "bold"}}>{t.label}</div>
                                            {att
                                              ? (<Fragment>
                                                  <div className="del-web-img" title="Delete" onClick={() => onDeleteWebImage(att)}>
                                                    <FontAwesomeIcon icon='times' />
                                                  </div>
                                                  <div className="inv-img">
                                                    <img className="web-img-box img-thumbnail" src={`/api/Inventory/ViewImage/${att.key}`} />
                                                  </div>
                                                </Fragment>)
                                                      : (<div className="web-img-box upload-inventory-image" style={_.some(constants.INVENTORY_IMAGE_ADDITIONAL_IDS, i => i ===t.value) ? { backgroundImage: `url('/Content/img/${constants.INVENTORY_IMAGE_ADDITIONAL_IMG_URL}')`} : {backgroundImage: `url('/Content/img/${t.placeholderImageName}')`}}>
                                                  <Dropzone onDrop={fileList => onDropImage(fileList[fileList.length-1], t.value)} style={{width: "100%", backgroundColor: "#F0F0F0", borderRadius: "6px"}}>
                                                    {({ getRootProps, getInputProps }) => (
                                                      <div {...getRootProps()} className="text-center">
                                                        <input {...getInputProps()} />
                                                        <div style={{ height: "100px", textAlign: "center" }}>
                                                        </div>
                                                      </div>
                                                    )}
                                                  </Dropzone>
                                                </div>)
                                            }
                                          </div>);
                                        })}
                                    </div>
                                </AccordionBody>
                            </AccordionItem>
                            <AccordionItem>
                                <AccordionHeader targetId={TABS.UNIT_ADDONS}>
                                    <FontAwesomeIcon icon={'clinic-medical'} className="me-2" />
                                    Inventory Add-Ons
                                    <Badge className={'ms-2 bg-dark'}>
                                        {addOnList && addOnList.length}
                                    </Badge>
                                </AccordionHeader>
                                <AccordionBody accordionId={TABS.UNIT_ADDONS}>
                                    <Table>
                                        <tbody>
                                        {inventory && addOnList.length
                                            ? _.map(addOnList, u => {
                                                return (
                                                    <tr key={u.id}>
                                                        <td>
                                                            {u.name} {u.addOnWindowDescription ? <span className="desc-text">{u.addOnWindowDescription}</span> : null}
                                                        </td>
                                                        <td>
                                                            {u.bundleId ? 'Bundle Item' : u.totalPrice ? ui_helpers.formatCurrency(u.totalPrice) : ui_helpers.formatCurrency(u.price)}
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                            : (<tr><td colSpan="2">There are no Add-Ons for this Inventory Item</td></tr>)}
                                        </tbody>
                                    </Table>

                                </AccordionBody>
                            </AccordionItem>
                            <AccordionItem>
                                <AccordionHeader targetId={TABS.HISTORY}>
                                    <FontAwesomeIcon icon="arrow-left" className="me-2" />
                                    History
                                </AccordionHeader>
                                <AccordionBody accordionId={TABS.HISTORY}>
                                  <Table size="sm">
                                    <tbody>
                                      {_.map(historyList, h => 
                                        (<tr key={h.id} className="comment-row">
                                          <td>{h.name}</td>
                                          <td style={{width: "180px"}}>{h.occurredAtLocalized}</td>
                                          <td style={{width: "250px"}}>{h.performedByUserFullName}</td>
                                        </tr>))}
                                    </tbody>
                                  </Table>
                                </AccordionBody>
                            </AccordionItem>
                            <AccordionItem>
                                <AccordionHeader targetId={TABS.OWNERSHIP}>
                                    <FontAwesomeIcon icon="arrow-left" className="me-2" />
                                    Ownership History
                                </AccordionHeader>
                                <AccordionBody accordionId={TABS.OWNERSHIP}>
                                  <Table size="sm">
                                    <tbody>
                                    {_.map(ownershipList, o => 
                                      (<tr key={o.id} className="comment-row">
                                        <td>
                                          {o.companyId > 0 ? o.companyName : o.externalCompanyName}
                                        </td>
                                        <td>
                                          from {o.purchasedFromCompanyName}
                                        </td>
                                        <td>
                                          {o.occurredAtLocalized}
                                        </td>
                                        <td>
                                          {o.performedByUserFullName}
                                        </td>
                                      </tr>))}
                                    </tbody>
                                  </Table>
                                </AccordionBody>
                            </AccordionItem>
                        </Accordion>
                    </Row>
                </Container>
            )}
            <Footer />
  </>);
}