import { useEffect, useState } from "react";
import { useParams } from "react-router";
import Alert from "react-s-alert-v3";
import { api } from "../helpers";
import LocationSetter from "../pos-signature-module/location-setter/LocationSetter";

export default function EditSignatureLocations() {
  const { id, regionId, cultureId } = useParams();

  const [companyContent, setCompanyContent] = useState(null);
  const [signatureLocations, setSignatureLocations] = useState(null);

  useEffect(() => {
    if (id) {
      api.fetch(`company/GetCompanyTemplate/${id}`).then((r) => {
        setCompanyContent(r.data.data);
      })
      .catch((error) =>
        Alert.error("There was an error getting the template.")
      );
    }
    refreshSignatureLocations();
  }, []);

  function refreshSignatureLocations() {
    if (id) {
      api.fetch(`Signature/GetSignatureLocationsByCompanyContentId/${id}`).then((r) => {
        setSignatureLocations(r.data.data);
      })
      .catch((error) =>
        Alert.error("There was an error getting the company content signature locations.")
      );      
    } else {
      // default template configuration
      api.fetch(`Signature/GetDefaultTemplateSignatureLocations/${regionId}/${cultureId}`).then((r) => {
        setSignatureLocations(r.data.data);
      })
      .catch((error) =>
        Alert.error("There was an error getting the signature locations.")
      );
    }
  }

  function handleSave(signatureLocations) {
    const payload = signatureLocations.map((x) => {
      const plucked = { ...x };
      plucked.companyContentId = companyContent
        ? companyContent.id
        : -1;
      if (regionId) {
        plucked.regionId = regionId;
      }
      if (cultureId) {
        plucked.cultureId = cultureId;
      }
      delete plucked.domId;
      delete plucked.isIntersecting;
      return plucked;
    });
    api.post("Signature/SaveSignatureLocations", payload).then(() => {
      refreshSignatureLocations();
      Alert.success("Signature locations saved successfully!");
    })
    .catch((error) =>
      Alert.error("There was an error saving the signature locations.")
    );
  }

  const pdfPath = id
    ? "/api/company/downloadtemplate/" + id
    : `/api/company/downloadDefaultTemplate/${regionId}/${cultureId}`;
  return (
    <LocationSetter
      filePath={pdfPath}
      signatureLocations={signatureLocations}
      handleSave={handleSave}
    />
  );
}
