import React, { useState, useEffect, Fragment } from "react";
import {DebounceInput} from "react-debounce-input";
import {
  Button,
  ButtonGroup,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Alert as RSAlert
} from "reactstrap";
import Alert from "react-s-alert-v3";
import classnames from 'classnames';
import Select from 'react-select';
import _ from "lodash";
import { api, ui_helpers } from "../helpers";
import { Loading } from './';
import { constants } from "../utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function CloneSKUDialog({mfgId, itemList, contextSubproductId, subproducts, onClose}) {
  const [items, setItems] = useState(itemList);
  const [working, setWorking] = useState(false);
  const [targetSubproductId, setTargetSubproductId] = useState(contextSubproductId);

  function isValid() {
    return _.every(items, x => x.newName);
  }

  function onClone() {
    setWorking(true);
    if (working) return;
    const itemList = _.map(items, x => ({sourceId: x.id, newSkuName: x.newName}));
    api.post(`manufacturer/CloneSKUs`, { targetSubproductId: targetSubproductId, items: itemList }).then(r => {
      if (r.data.success) {
        onClose();    
      } else {
        Alert.error(r.data.message);
      }
    })
    .catch(error => Alert.error('Error while cloning products: ' + error))
    .finally(() => setWorking(false));
  }

  return (
      <Modal size="lg" isOpen>
        <ModalHeader>Clone SKUs</ModalHeader>
        <ModalBody className="pt-1">
          {working
            ? (<Loading />)
            : (<Fragment>
            <Row>
              <Col xs={12} className="mb-1">
                <RSAlert color='info' className="py-1 my-1">
                  Select a target subproduct and enter a <u>required</u> suffix for each item to be cloned.
                </RSAlert>
              </Col>
            </Row>
            <Row>
              <Col xs={12} className="mb-2">
                <Label>Clone into subproduct</Label>
                <Select
                  options={subproducts}
                  value={_.find(subproducts, sp => sp.value === targetSubproductId)}
                  onChange={(e) => setTargetSubproductId(e.value)}
                />
              </Col>
            </Row>
            <Row className="bg-secondary text-light mb-1">
              <Col xs={{size: 4, offset: 2}}>Old Name</Col>
              <Col xs={4}>New Name</Col>
            </Row>
            {_.map(items, (s, idx) => (
            <Row key={`clone-${s.id}`} className="pb-1">
              <Col xs={{size: 4, offset: 2}}>
                <span className="fw-bold">
                  {s.name}
                </span>
              </Col>
              <Col xs={4}>
                <DebounceInput
                  type='text'
                  value={s.newName}
                  onChange={(e) => setItems(_.map(items, x => {
                    return x.id === s.id
                      ? ({...x, newName: e.target.value})
                      : x;
                  }))}
                  min={0}
                  maxLength="50"
                  className="form-control-sm"
                  debounceTimeout={150}
                  style={{width: '140px', paddingTop: 0, paddingBottom: 0, borderWidth: "1px"}}
                />
              </Col>
            </Row>))}
            </Fragment>)
          }
        </ModalBody>
        <ModalFooter style={{justifyContent: "flex-start"}}>
          <Row style={{width: "100%"}}>
            <Col xs={8}>
              {working || !isValid()
                ? (<span>&nbsp;</span>)
                : (<RSAlert color='warning' className="py-1 my-1">
                    Ensure that {items.length === 1 ? 'this SKU' : `each of these ${items.length} SKUs`} is correct. This operation cannot be undone.
                  </RSAlert>)
              }
            </Col>
            <Col xs={4}>
              <ButtonGroup className="float-end">
                {working || !isValid()
                  ? null
                  : (<Button color="primary" onClick={onClone}>Clone</Button>)
                }
                {working
                  ? null
                  : (<Button color="secondary" onClick={onClose}>Cancel</Button>)
                }
              </ButtonGroup>
            </Col>
          </Row>
        </ModalFooter>
      </Modal>    
  );
}
