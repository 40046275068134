import React, { useEffect } from "react";
import { v4 as uuid } from "uuid";
import { porchTypeOptions, porchTypes } from "../floorplanConstants";
import styles from "../floorplanTool.module.css";
import {
  useActions,
  useAppState,
  useControlTarget,
  useDisplaySettings,
  useExterior,
  usePointerCoordinates,
} from "../hooks";
import {
  cornersToPathReducer,
  radiansToDegrees,
  snap,
  toFeetInchesString,
} from "../utils";
import { ControlPoint } from "./";

const controlPointId = uuid();

function DeluxePorch() {
  const [exterior, exteriorDispatch] = useExterior();
  const [appState, appStateDispatch] = useAppState();
  const [coordinates] = usePointerCoordinates();
  const [controlTarget] = useControlTarget();
  const [displaySettings] = useDisplaySettings();
  const actions = useActions({ exteriorDispatch, appStateDispatch });

  useEffect(() => {
    if (exterior.porchMax < exterior.width) actions.setPorchMax(exterior.width);
    if (exterior.porchWall3Length > 0) actions.setPorchWall3Length(0);
    if (exterior.porchWall4Length > 0) actions.setPorchWall4Length(0);
    if (exterior.porchMin > 0) actions.setPorchMin(0);
    if (exterior.porchMax !== exterior.width) actions.setPorchMax(exterior.width);
    if (exterior.porchType === porchTypes.twoWallCorner1) {
      if (appState.isDraggingPorchHandle && controlTarget === controlPointId) {
        actions.setPorchWall1Length(snap(exterior.height - coordinates.y, 6));
        actions.setPorchWall2Length(snap(coordinates.x, 6));
      }
    }
  }, [controlTarget, coordinates]);

  let corners = [];

  if (exterior.porchType === porchTypes.twoWallCorner1) {
    corners = [
      {
        x: 0,
        y: 0,
      },
      {
        x: exterior.width,
        y: 0,
      },
      {
        x: exterior.width,
        y: exterior.height,
      },
      {
        x: exterior.porchWall2Length,
        y: exterior.height,
      },
      {
        x: exterior.porchWall2Length,
        y: exterior.height - exterior.porchWall1Length,
        controlTarget: controlPointId,
      },
      {
        x: 0,
        y: exterior.height - exterior.porchWall1Length,
      },
      {
        x: 0,
        y: exterior.height - exterior.porchWall1Length,
      },
    ];
  }

  const path = corners.reduce(cornersToPathReducer, "M ");

  return (
    <g>
      <path
        d={path}
        stroke={appState.editingPorch ? "#0d6efd" : "black"}
        strokeWidth={2}
        fill="none"
      />
      {appState.editingPorch
        ? corners
            .filter((c) => c.controlTarget)
            .map((c) => (
              <ControlPoint
                key={c.controlTarget}
                id={c.controlTarget}
                className={c.controlTarget}
                cx={c.x}
                cy={c.y}
                onMouseDown={() => actions.setIsDraggingPorchHandle(true)}
                onTouchStart={() => actions.setIsDraggingPorchHandle(true)}
              />
            ))
        : null}
      <text
        className={styles.svgTxt}
        fontSize={displaySettings.fontSize}
        x={displaySettings.fontSize}
        y={
          exterior.porchType === porchTypes.fourWallDeluxe1
            ? exterior.height - displaySettings.fontSize * 5
            : displaySettings.fontSize * 1.5
        }
      >
        <tspan>
          Porch:{" "}
          {porchTypeOptions.find((o) => o.value === exterior.porchType)?.label}
        </tspan>
        <tspan x={displaySettings.fontSize} dy={displaySettings.fontSize}>
          Wall 1: {toFeetInchesString(exterior.porchWall1Length)}
        </tspan>
        <tspan x={displaySettings.fontSize} dy={displaySettings.fontSize}>
          Wall 2: {toFeetInchesString(exterior.porchWall2Length)}
        </tspan>
      </text>
    </g>
  );
}

export default DeluxePorch;
