import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import _ from "lodash";
import {
  Row, Col,
  Button,
  Card,
  CardBody,
  CardHeader,
  Container,
  Input,
  Table,
  InputGroup,
  Alert as Alert2
} from "reactstrap";
import { MfgAdmin } from "./";
import { api, constants } from "../utils";
import Loading from "./Loading";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Alert from "react-s-alert-v3";

export default function ManufacturerStyleUnitTypes(props) {
  const params = useParams();
  const mfgId = params.mfgId
    ? params.mfgId
    : props.mfgId;

  const [mfgUnitTypes, setMfgUnitTypes] = useState([]);
  const [newUnitTypeName, setNewUnitTypeName] = useState("");
  const [hideUnitOptionsFeature, setHideUnitOptionsFeature] = useState(false);
  const [defaultMfgUnitTypeId, setDefaultMfgUnitTypeId] = useState(null);
  const [manufacturerName, setManufacturerName] = useState('');

  useEffect(getMfgUnitTypes, []);

  function addNewUnitType() {
    if (newUnitTypeName === "") return;
    const payload = {
      manufacturerId: mfgId,
      name: newUnitTypeName,
    };
    api.post("Manufacturer/CreateUnitType", payload).then((res) => {
      getMfgUnitTypes();
      setNewUnitTypeName("");
    });
  }

  async function getMfgUnitTypes() {
    const res = await api.fetch(`Manufacturer/ListOfUnitTypes/${mfgId}`);
    if (res.data.success) {
      setMfgUnitTypes(res.data.message.list);
      setManufacturerName(res.data.message.manufacturerName);
    }
    getMfgBuildFeature();
  }

  const getMfgBuildFeature = () => {
    if (!mfgId) return;
    api.fetch("Reference/GetMfgEnabledFeatures/" + mfgId).then((r) => {
      if (r.data) {
        setHideUnitOptionsFeature(r.data.hideUnitOptions);
        setDefaultMfgUnitTypeId(r.data.defaultUnitTypeId)
      }
    }).catch((err) => console.error(err));
  };

  function removeUnitType(id) {
    api.post(`Manufacturer/ToggleUnitTypeActive/${id}`).then((res) => {
      getMfgUnitTypes();
    });
  }

  function setDefaultUnitType(id) {
    api.post(`Manufacturer/SetDefaultUnitType/${mfgId}/${id}`).then(res => {
      if (res.data && res.data.success) {
        Alert.success(`Default material ${id === null ? "removed" : "saved"}.`);
        getMfgBuildFeature();
      } else {
        Alert.error(res.data.message || "An error occurred. Default material cannot be saved at this time.")
      }
    }).catch(err => console.error(err))
  }

  return (
    <>
    <MfgAdmin mfgId={mfgId} tabName="MATERIALS">
      <Container fluid>
        <Row>
          <Col>
            <h2 className="py-2">
              <Link to={`/admin/manufacturer/${mfgId}`}>{manufacturerName}</Link> Materials
            </h2>
          </Col>
        </Row>
        {hideUnitOptionsFeature && (!defaultMfgUnitTypeId || defaultMfgUnitTypeId === constants.OTHER_ID)
            && <Alert2 color="warning" className="float-right">
                Hide Unit Type Option is 'ON'. Please select a default material.
            </Alert2>}
        <Row>
          <Col xs="4">
            <InputGroup className="mb-2">
              <Input
                type="text"
                placeholder="New Material Name"
                value={newUnitTypeName}
                onChange={(e) => setNewUnitTypeName(e.target.value)}
              />
              <Button
                color="success"
                className="fw-bold text-white"
                title="Add New Material"
                onClick={addNewUnitType}
              >
                <FontAwesomeIcon icon="plus" className="me-2" />
                Add
              </Button>
            </InputGroup>
          </Col>
        </Row>
        <Card>
          <CardBody>
            {mfgUnitTypes && mfgUnitTypes.length > 0 && (
              <>
                <Table hover>
                  <tbody>
                    {_.map(mfgUnitTypes, (c) => (
                      <tr key={c.id}>
                        <td>
                          <strong>{c.name}</strong>
                        </td>
                        <td className="d-flex justify-content-end">
                          <Button
                            title={defaultMfgUnitTypeId  && defaultMfgUnitTypeId === c.id ? `Selected Default` : `Set ${c.name} as default`}
                            size="sm"
                            color={defaultMfgUnitTypeId  && defaultMfgUnitTypeId === c.id ? "info" : "dark"}
                            onClick={() => {
                              const id = defaultMfgUnitTypeId && defaultMfgUnitTypeId === c.id ? null : c.id
                              setDefaultUnitType(id);
                            }}
                          >
                              {defaultMfgUnitTypeId && defaultMfgUnitTypeId === c.id
                                  ? <>
                                      <FontAwesomeIcon icon="check-square" /> Remove Default
                                  </>
                                  : <>
                                      <FontAwesomeIcon icon="bookmark" /> Set As Default
                                  </>
                              }
                          </Button>
                          <Button
                            title={`Delete ${c.name}`}
                            size="sm"
                            className="btn-delete"
                            onClick={() => removeUnitType(c.id)}
                          >
                            <FontAwesomeIcon icon="trash-alt" /> Delete
                          </Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </>
            )}
          </CardBody>
        </Card>
      </Container>
    </MfgAdmin>
    </>
  );
}
