export const infoMessages = {
  default: {
    message:
      'Use the "Add-On Options" panel in the left sidebar to add new elements to your drawing. Use the "Add-On Settings" panel in the right sidebar to edit the settings for the currently selected add-on.',
    link: "#",
  },
  selectedWorkbench: {
    message:
      "You just selected a workbench, which is priced by linear ft. The price will be updated as you adjust the length in the add-on settings area, or by clicking and dragging the control handles.",
    link: "#",
  },
  editingPorch: {
    message:
      "Use the sliders on the right sidebar, or use the selection (arrow) tool to click and drag the control handles on the drawing to edit the dimensions of the porch.",
    link: "#",
  },
  selectedDoor: {
    message:
      'You just selected a door. You can use the "Add-On Settings" panel in the right sidebar to edit its properties or, alternately, click and drag to move it around the drawing or click the control handle to rotate it.',
    link: "#",
  },
  selectedWindow: {
    message:
      'You just selected a window. To add a flowerbox and/or shutters to this window, click the checkboxes in the"Add-On Settings" panel in the right sidebar.',
    link: "#",
  },
  selectedOutlet: {
    message:
      "You just selected an outlet. Click and drag to move it around, and click the control handle to rotate it so the side with the circle faces away from the wall.",
    link: "#",
  },
  selectedLoft: {
    message:
      "You just selected a loft, which is priced by linear feet. The price will be updated as you adjust the length in the add-on settings area, or by clicking and dragging the control handles.",
    link: "#",
  },
  saving: {
    message:
      "The image preview pop-up shows a preview of the final printed image. If you need more space around the edges, use the image padding controls belowe the preview. Once everything looks good, click save!",
    link: "#",
  },
  exporting: {
    message:
      "Please note that the export dialog only downloads an image file to your local computer, it DOES NOT save your drawing. Click the save button in the top left of the screen to save your drawing! ",
    link: "#",
  },
};
