import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faAngleLeft,
  faAngleRight,
  faAngleUp,
  faArrowDown,
  faArrowLeft,
  faArrowRight,
  faArrowsAlt,
  faArrowUp,
  faBars,
  faBarcode,
  faBell,
  faBookmark,
  faBug,
  faBuilding,
  faCalculator,
  faCamera,
  faCaretDown,
  faCaretSquareDown,
  faCaretSquareUp,
  faCaretUp,
  faCheck,
  faCheckSquare,
  faClinicMedical,
  faClone,
  faCog,
  faComment,
  faCommentAlt,
  faCopy,
  faCreditCard,
  faDesktop,
  faDollarSign,
  faDownload,
  faEdit,
  faEllipsisV,
  faExchangeAlt,
  faExclamationCircle,
  faExternalLinkAlt,
  faEye,
  faEyeSlash,
  faFileCsv,
  faFilePdf,
  faFileSignature,
  faFileUpload,
  faFilter,
  faFolderPlus,
  faHandPaper,
  faHandPointRight,
  faHeartbeat,
  faHome,
  faImage,
  faImages,
  faIndustry,
  faInfo,
  faInfoCircle,
  faListAlt,
  faListUl,
  faMinus,
  faMoneyBillWave,
  faPaperclip,
  faPhoneAlt,
  faPlay,
  faPause,
  faPlug,
  faPlus,
  faPlusCircle,
  faPlusSquare,
  faPrint,
  faQuestionCircle,
  faReply,
  faRedoAlt,
  faSave,
  faScrewdriver,
  faSearch,
  faSearchMinus,
  faSearchPlus,
  faSignOutAlt,
  faSitemap,
  faSpinner,
  faStore,
  faSync,
  faTimes,
  faTimesCircle,
  faTrash,
  faTrashAlt,
  faTruck,
  faTruckMoving,
  faUndo,
  faUser,
  faUserPlus,
  faUsers,
  faWrench,
  faChevronCircleDown,
  faChevronCircleUp,
  faCheckCircle,
} from "@fortawesome/free-solid-svg-icons";
import React, { useContext, useEffect, useState } from "react";
import { Redirect, Route, Switch, useHistory } from "react-router-dom";
import Alert from "react-s-alert-v3";
import "./App.css";
import {
  AddOnBundleManagement,
  BuildAddOnManagement,
  BuildCertify,
  BuildDetails,
  BuildManagement,
  Builds,
  CashSales,
  CompanyAddEdit,
  CompanyAdmin,
  ContractEdit,
  ContractView,
  Developer,
  DocIndex,
  EditDocList,
  EditReportParameters,
  EditSubProduct,
  EditSignatureLocations,
  Home,
  InspectionDetailsManagement,
  Inspections,
  Inventory,
  InventorySales,
  InventoryEdit,
  InventoryView,
  Login,
  ManufacturerBuilders,
  ManufacturerBuildSteps,
  ManufacturerColors,
  ManufacturerCompanies,
  ManufacturerSubProducts,
  ManufacturerUnitTypes,
  MfgAdmin,
  PasswordReset,
  Quotes,
  QuoteView,
  ReportList,
  ReportParameters,
  ReportsIndex,
  RTOContracts,
  SignatureCapture,
  StoreAdmin,
  SubProductSKU,
  TemplateManagement,
  UserAddEdit,
  UserAdmin,
  UserProfile,
} from "./components";
import {
  ThankYou
} from "./pos-signature-module/signature-capture/components";
import ManufacturerAddOns from "./components/ManufacturerAddOns";
import ManufacturerSettings from "./components/ManufacturerSettings";
import ManufacturerStores from "./components/ManufacturerStores";
import ManufacturerUsers from "./components/ManufacturerUsers";
import TaxRates from "./components/TaxRates";
import Regions from "./components/Regions";
import {
  AdminLanding,
  Companies,
  Manufacturers,
  PrivacyPolicy,
  Stores,
  Signature,
} from "./pages";
import { api, constants, storage, UserContext } from "./utils";

library.add(
  faIndustry,
  faStore,
  faReply,
  faTruckMoving,
  faUsers,
  faExchangeAlt,
  faAngleLeft,
  faAngleRight,
  faAngleUp,
  faArrowDown,
  faArrowLeft,
  faArrowRight,
  faArrowsAlt,
  faArrowUp,
  faBars,
  faBarcode,
  faBell,
  faBuilding,
  faCalculator,
  faCaretDown,
  faCaretSquareDown,
  faCaretSquareUp,
  faCaretUp,
  faCheck,
  faCog,
  faComment,
  faCreditCard,
  faDesktop,
  faDollarSign,
  faEdit,
  faEllipsisV,
  faExternalLinkAlt,
  faEye,
  faEyeSlash,
  faFileCsv,
  faFileSignature,
  faFileUpload,
  faFilter,
  faFolderPlus,
  faHandPaper,
  faHandPointRight,
  faHeartbeat,
  faHome,
  faImage,
  faInfo,
  faInfoCircle,
  faListAlt,
  faListUl,
  faMoneyBillWave,
  faPaperclip,
  faPhoneAlt,
  faPlay,
  faPause,
  faPlug,
  faPlus,
  faPlusCircle,
  faPlusSquare,
  faPrint,
  faRedoAlt,
  faSave,
  faScrewdriver,
  faSearch,
  faSearchMinus,
  faSearchPlus,
  faSignOutAlt,
  faSitemap,
  faSync,
  faTimes,
  faTimesCircle,
  faTrashAlt,
  faTruck,
  faUndo,
  faUser,
  faUserPlus,
  faWrench,
  faImages,
  faFilePdf,
  faMinus,
  faCommentAlt,
  faClone,
  faBug,
  faFileUpload,
  faDownload,
  faCheckSquare,
  faExclamationCircle,
  faBookmark,
  faCopy,
  faClinicMedical,
  faSpinner,
  faCamera,
  faQuestionCircle,
  faTrash,
  faChevronCircleDown,
  faChevronCircleUp,
  faCheckCircle
);

const { ICONS } = constants;
function App() {
  // const userCtx = useContext(UserContext);
  const tokenFromStorage = api.userToken() || null;
  const parsedUser = tokenFromStorage
    ? JSON.parse(storage.getItem("currentUser"))
    : null;
  const isImpersonating = storage.getItem("adminToken") ? true : false;
  //const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [currentUser, setCurrentUser] = useState(parsedUser);
  const [authToken, setAuthToken] = useState(tokenFromStorage);
  //const [message, setMessage] = useState({ flavor: "success", message: "" });
  const [loading, setLoading] = useState(true);

  const PATHNAMES = constants.PATH_NAMES;
  const history = useHistory();

  useEffect(() => {
    Promise.all([
      api.getCachedImage(ICONS.contract),
      api.getCachedImage(ICONS.delivery),
      api.getCachedImage(ICONS.landlord),
      api.getCachedImage(ICONS.payment),
      api.getCachedImage(ICONS.logo),
    ])
      .then((results) => {
        // console.log('results preloading images completed')
      })
      .catch(api.catchHandler)
      .finally(() => setLoading(false));
  }, []);

  function enhanceUser(u) {
    if (!u) return u;
    if (u.token) {
      delete u.token;
    }
    return u;
  }

  const logOut = () => {
    storage.removeItem("currentUser");
    storage.removeItem("adminUser");
    storage.removeItem("token");
    storage.removeItem("adminToken");
    setCurrentUser(null);
  };

  const user = {
    currentUser: currentUser,
    setCurrentUserContext: (userRole) => {
      let u = Object.assign({}, currentUser);
      u.currentRoleId = userRole.roleId;
      storage.setItem("currentUser", JSON.stringify(u));
      setCurrentUser(u);
      history.push(PATHNAMES.HOME);
    },
    logIn: (newUser, token) => {
      if (token) {
        storage.setItem("token", token);
        setAuthToken(token);
      }
      newUser = enhanceUser(newUser);
      storage.setItem("currentUser", JSON.stringify(newUser));
      setCurrentUser(newUser);
    },
    logOut: logOut,
    impersonate: impersonate,
    clearImpersonation: clearImpersonation,
    impersonating: isImpersonating,
  };

  // function isAuthenticated() {
  //   return currentUser && currentUser.token ? true : false;
  // }

  function impersonate(impersonatedUser, impersonateToken) {
    if (isImpersonating) {
      Alert.error(
        "You are already impersonating a user. Stop impersonating before trying again."
      );
      return;
    }
    const adminUser = JSON.parse(storage.getItem("currentUser"));
    const adminUserToken = storage.getItem("token");
    storage.removeItem("token");
    storage.removeItem("currentUser");
    // user = enhanceUser(impersonatedUser);
    storage.setItem("token", impersonateToken);
    storage.setItem("currentUser", JSON.stringify(impersonatedUser));
    storage.setItem("adminUser", JSON.stringify(adminUser));
    storage.setItem("adminToken", adminUserToken);
    setCurrentUser(impersonatedUser);
    setAuthToken(impersonateToken);
    history.push("/");
  }

  function clearImpersonation() {
    if (!isImpersonating) return;
    const adminUser = JSON.parse(storage.getItem("adminUser"));
    const adminUserToken = storage.getItem("adminToken");
    storage.removeItem("adminToken");
    storage.removeItem("adminUser");
    storage.setItem("token", adminUserToken);
    storage.setItem("currentUser", JSON.stringify(adminUser));
    storage.setItem("untethering", true);
    setCurrentUser(adminUser);
    setAuthToken(adminUserToken);
    history.push("/");
  }

  const AuthRoute = (props) => {
    if (!currentUser) return <Redirect to={PATHNAMES.LOGIN} />;
    return <Route {...props} />;
  };

  if (loading) {
  }
  return (
    <UserContext.Provider value={user}>
      <Switch>
        <Route
          exact
          path={PATHNAMES.PRIVACY_POLICY_MOBILE_APP}
          component={PrivacyPolicy}
        />
        <Route exact path={PATHNAMES.LOGIN} component={Login} />
        <Route
          exact
          path={PATHNAMES.FORGOT_PASSWORD}
          component={PasswordReset}
        />
        <Route
          exact
          path={`${PATHNAMES.SIGNATURE}/:code?`}
          component={SignatureCapture}
        />
        <Route
          exact
          path={PATHNAMES.THANK_YOU}
          component={ThankYou}
        />
        <AuthRoute exact path={PATHNAMES.HOME} component={Home} />
        <AuthRoute exact path={PATHNAMES.BUILDS} component={Builds} />
        <AuthRoute
          exact
          path={PATHNAMES.BUILD_ADDON_MANAGEMENT}
          component={BuildAddOnManagement}
        />
        <AuthRoute
          exact
          path={PATHNAMES.INSPECTION_DETAILS_MANAGEMENT}
          component={InspectionDetailsManagement}
        />
        <AuthRoute
          exact
          path={`${PATHNAMES.BUILD_DETAILS}/:id`}
          component={BuildDetails}
        />
        <AuthRoute
          exact
          path={PATHNAMES.TEMPLATE_MANAGEMENT}
          component={TemplateManagement}
        />
        <AuthRoute
          exact
          path={PATHNAMES.BUILD_CERTIFY}
          component={BuildCertify}
        />
        <AuthRoute exact path={PATHNAMES.INSPECTIONS} component={Inspections} />
        <AuthRoute exact path={PATHNAMES.RTOS} component={RTOContracts} />
        <AuthRoute exact path={PATHNAMES.CASH_SALES} component={CashSales} />
        <AuthRoute exact path={PATHNAMES.INVENTORY} component={Inventory} />
        <AuthRoute exact path={PATHNAMES.INVENTORY_SALES} component={InventorySales} />
        <AuthRoute exact path={PATHNAMES.QUOTES} component={Quotes} />
        <AuthRoute
          exact
          path={
            "/admin/manufacturer/:mfgId/:subProductId/skus/:activeTabIndex?"
          }
          component={SubProductSKU}
        />
        <AuthRoute
          exact
          path={"/admin/manufacturer/:mfgId/:subProductId/edit"}
          component={EditSubProduct}
        />
        <AuthRoute
          exact
          path={"/ad-infinitum/developer"}
          component={Developer}
        />
        <AuthRoute
          exact
          path={`${PATHNAMES.INVENTORY_VIEW}/:id`}
          component={InventoryView}
        />
        <AuthRoute exact path={PATHNAMES.NEW_QUOTE}>
          <ContractEdit contractType={constants.CONTRACT_TYPE.QUOTE} />
        </AuthRoute>
        <AuthRoute exact path={PATHNAMES.NEW_RTO}>
          <ContractEdit contractType={constants.CONTRACT_TYPE.RTO} />
        </AuthRoute>
        <AuthRoute
          exact
          path={`${PATHNAMES.QUOTE_EDIT}/:routeContractId/:fromInventoryId?`}
          render={(props) => (
            <ContractEdit
              {...props}
              contractType={constants.CONTRACT_TYPE.QUOTE}
            />
          )}
        />
        <AuthRoute
          exact
          path={`${PATHNAMES.CONVERT_QUOTE_TO_CASH_SALE}/:routeContractId`}
          render={(props) => (
            <ContractEdit
              {...props}
              contractType={constants.CONTRACT_TYPE.CASH_SALE}
              convertFromQuote={true}
            />
          )}
        />
        <AuthRoute
          exact
          path={`${PATHNAMES.CONVERT_QUOTE_TO_RTO}/:routeContractId`}
          render={(props) => (
            <ContractEdit
              {...props}
              contractType={constants.CONTRACT_TYPE.RTO}
              convertFromQuote={true}
            />
          )}
        />
        <AuthRoute
          exact
          path={`${PATHNAMES.NEW_QUOTE_VERSION}/:routeContractId`}
          render={(props) => (
            <ContractEdit
              {...props}
              contractType={constants.CONTRACT_TYPE.QUOTE}
            />
          )}
        />
        <AuthRoute
          exact
          path={`${PATHNAMES.CASH_SALE_EDIT}/:routeContractId`}
          render={(props) => (
            <ContractEdit
              {...props}
              contractType={constants.CONTRACT_TYPE.CASH_SALE}
            />
          )}
        />
        <AuthRoute
          exact
          path={`${PATHNAMES.CONTRACT_EDIT}/:routeContractId`}
          render={(props) => (
            <ContractEdit
              {...props}
              contractType={constants.CONTRACT_TYPE.RTO}
            />
          )}
        />
        <AuthRoute exact path={PATHNAMES.NEW_CASH_SALE}>
          <ContractEdit contractType={constants.CONTRACT_TYPE.CASH_SALE} />
        </AuthRoute>
        <AuthRoute
          exact
          path={PATHNAMES.QUOTE_VIEW + "/:routeContractId/:routeTabId?"}
          render={(props) => (
            <QuoteView
              {...props}
              contractType={constants.CONTRACT_TYPE.QUOTE}
            />
          )}
        />
        <AuthRoute
          exact
          path={PATHNAMES.CASH_SALE_VIEW + "/:routeContractId/:routeTabId?"}
          render={(props) => (
            <ContractView
              {...props}
              contractType={constants.CONTRACT_TYPE.CASH_SALE}
            />
          )}
        />
        <AuthRoute
          exact
          path={PATHNAMES.CONTRACT_VIEW + "/:routeContractId/:routeTabId?"}
          render={(props) => (
            <ContractView
              {...props}
              contractType={constants.CONTRACT_TYPE.RTO}
            />
          )}
        />
        <AuthRoute
          exact
          path={`${PATHNAMES.NEW_INVENTORY}/:inventoryId`}
          component={InventoryEdit}
        />
        <AuthRoute
          exact
          path={`${PATHNAMES.EDIT_INVENTORY}/:inventoryId`}
          component={InventoryEdit}
        />
        <AuthRoute
          exact
          path={PATHNAMES.USER_PROFILE}
          component={UserProfile}
        />
        <AuthRoute
          exact
          path={PATHNAMES.RESOURCES_DOCINDEX}
          component={DocIndex}
        />
        <AuthRoute
          exact
          path={PATHNAMES.RESOURCES_REPORTSINDEX}
          component={ReportsIndex}
        />
        <AuthRoute
          exact
          path={PATHNAMES.RESOURCES_EDITDOCLIST}
          component={EditDocList}
        />
        <AuthRoute
          exact
          path={PATHNAMES.RESOURCES_EDITREPORTDEF}
          component={ReportList}
        />
        <AuthRoute
          exact
          path="/Report/EditParameters/:reportId"
          component={EditReportParameters}
        />
        <AuthRoute
          exact
          path="/Report/:reportId/Parameters"
          component={ReportParameters}
        />
        <AuthRoute exact path={PATHNAMES.ADMIN} component={AdminLanding} />
        <AuthRoute
          exact
          path={PATHNAMES.COMPANY_ADMIN + "/:id/:tab?"}
          component={CompanyAdmin}
        />
        <AuthRoute
          exact
          path={PATHNAMES.ADMIN_COMPANIES}
          component={Companies}
        />
        <AuthRoute
          exact
          path="/admin/manufacturers"
          component={Manufacturers}
        />
        <AuthRoute
          exact
          path={PATHNAMES.ADMIN_SINGLE_MANUFACTURER + "/:mfgId/products"}
          component={ManufacturerSubProducts}
        />
        <AuthRoute
          exact
          path={PATHNAMES.ADMIN_SINGLE_MANUFACTURER + "/:mfgId/unit-types"}
          component={ManufacturerUnitTypes}
        />
        <AuthRoute
          exact
          path={PATHNAMES.ADMIN_SINGLE_MANUFACTURER + "/:mfgId/colors"}
          component={ManufacturerColors}
        />
        <AuthRoute
          exact
          path={PATHNAMES.ADMIN_SINGLE_MANUFACTURER + "/:mfgId/companies"}
          component={ManufacturerCompanies}
        />
        <AuthRoute
          exact
          path={PATHNAMES.ADMIN_SINGLE_MANUFACTURER + "/:mfgId/stores"}
          component={ManufacturerStores}
        />
        <AuthRoute
          exact
          path={PATHNAMES.ADMIN_SINGLE_MANUFACTURER + "/:mfgId/users"}
          component={ManufacturerUsers}
        />
        <AuthRoute
          exact
          path={PATHNAMES.ADMIN_SINGLE_MANUFACTURER + "/:mfgId/builders"}
          component={ManufacturerBuilders}
        />
        <AuthRoute
          exact
          path={PATHNAMES.ADMIN_SINGLE_MANUFACTURER + "/:mfgId/colors"}
          component={ManufacturerColors}
        />
        <AuthRoute
          exact
          path={PATHNAMES.ADMIN_SINGLE_MANUFACTURER + "/:mfgId/unit-types"}
          component={ManufacturerUnitTypes}
        />
        <AuthRoute
          exact
          path={PATHNAMES.ADMIN_SINGLE_MANUFACTURER + "/:mfgId/add-ons"}
          component={ManufacturerAddOns}
        />
        <AuthRoute
          exact
          path={`${PATHNAMES.ADMIN_SINGLE_MANUFACTURER}/:mfgId/bundles`}
          component={AddOnBundleManagement}
        />
        <AuthRoute
          exact
          path={`${PATHNAMES.ADMIN_SINGLE_MANUFACTURER}/:mfgId/build-steps`}
          component={ManufacturerBuildSteps}
        />
        <AuthRoute
          exact
          path={`${PATHNAMES.ADMIN_SINGLE_MANUFACTURER}/:id/settings`}
          component={ManufacturerSettings}
        />
        <AuthRoute
          exact
          path={`${PATHNAMES.ADMIN_SINGLE_MANUFACTURER}/:mfgId/inspections`}
          component={InspectionDetailsManagement}
        />
        <AuthRoute
          exact
          path={`${PATHNAMES.ADMIN_SINGLE_MANUFACTURER}/:id/build-management`}
          component={BuildManagement}
        />
        <AuthRoute exact path="/admin/stores" component={Stores} />
        <AuthRoute exact path="/admin/taxrates" component={TaxRates} />
        <AuthRoute exact path="/admin/regions" component={Regions} />
        <AuthRoute exact path="/admin/manufacturer/:id" component={MfgAdmin} />
        <AuthRoute exact path={`/admin/store/:id`} component={StoreAdmin} />
        <AuthRoute exact path="/admin/users" component={UserAdmin} />
        <AuthRoute path="/company_add_edit" component={CompanyAddEdit} />
        <AuthRoute path="/user/:edit_user_id?" component={UserAddEdit} />
        <AuthRoute
          exact
          path="/edit-signature-locations/:id"
          component={EditSignatureLocations}
        />
        <AuthRoute
          exact
          path="/edit-default-signature-locations/:regionId/:cultureId"
          component={EditSignatureLocations}
        />
      </Switch>
    </UserContext.Provider>
  );
}

export default App;
