import React, { useEffect } from "react";
import { Page as PdfPage, pdfjs } from "react-pdf";
import { useAppStore, useIntersect } from "../hooks";
import { SignatureLocation } from ".";
pdfjs.GlobalWorkerOptions.workerSrc = "pdf.worker.js";

export default function Page(props) {
  const setCurrentPageIndex = useAppStore((x) => x.setCurrentPageIndex);
  const signatureLocations = useAppStore((x) => x.signatureLocations);
  const showOverlays = useAppStore((x) => x.showOverlays);
  const [ref, entry] = useIntersect({ threshold: 0.5 });

  useEffect(() => {
    if (entry.isIntersecting) setCurrentPageIndex(props.pageIndex);
  }, [entry]);

  return (
    <div className="position-relative" style={{ margin: "0 16px" }}>
      <PdfPage canvasRef={ref} {...props} />
      {ref && (
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: ref.width,
            height: ref.height,
          }}
        >
          {Array.isArray(signatureLocations) && props.allPagesLoaded
            ? signatureLocations
                .filter((s) => s.pageIndex === props.pageIndex)
                .map((sl, i) => (
                  <SignatureLocation key={sl.id} signatureLocation={sl} scale={props.scale}/>
                ))
            : null}
        </div>
      )}
    </div>
  );
}
