import _ from "lodash";
import React, { useEffect, useState, useContext, Fragment } from "react";
import Alert from "react-s-alert-v3";
import { Link, Redirect } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert";
import {
    Badge, Button, Modal, ModalHeader, ModalBody, ModalFooter,
    Spinner, Table, Row, Col, Card, CardBody,
    CardHeader, CardFooter, Label, Input, ButtonGroup
} from "reactstrap";
import {
    Header,
    Footer,
    EssentialIcon,
    FollowupIcon,
    Loading,
    SortHeader,
    ResultHeader,
    Pager,
    FilterSearch,
    SelectFilter,
    TextFilter,
    DateFilter
} from "./";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { api, constants, UserContext } from "../utils";
import { date_helpers, filter_helpers, ui_helpers } from "../helpers";
import classnames from "classnames";
const ICONS = constants.ICONS;

const QuoteRow = ({ c, selectedContractIds, onSelectContractId, onSetRedirect, cancelQuote }) => {
    const navPath = `${constants.PATH_NAMES.QUOTE_VIEW}/${c.contractId}`;
    function onNavigate() {
      onSetRedirect(navPath);
    }
    const daysSinceInception = date_helpers.calculateAgeInDays(c.createdAt);
    return (
        <tr key={`c-${c.contractId}`} className='selectable-row'>
            <td style={{ textAlign: "left" }} onClick={onNavigate} className="clickable">
                {c.dealerName}<br />
                {c.assignedDriverUserFullName ? (
                    <div>
                        <em className="text-muted">
                            {c.assignedDriverUserFullName} ({date_helpers.dateTimeFormat(c.driverLastAssignedAt)})
                        </em>
                    </div>
                ) : null}
            </td>
            <td onClick={onNavigate} className="clickable">
                {c.customerFirstName} {c.customerLastName}
            </td>
            <td onClick={onNavigate}>{ui_helpers.formatCurrencyWithoutDecimal(c.unitPrice + c.sumOfPriceAdjustments)}</td>
            <td onClick={onNavigate}>{c.manufacturerName}</td>
            <td onClick={onNavigate}>{date_helpers.formatDateToShortDate(c.createdAt)}</td>
            <td onClick={onNavigate}>{c.serialNumber}</td>
            <td onClick={onNavigate}>{c.contractId}</td>
            <td onClick={onNavigate}>{date_helpers.formatDateToShortDate(c.mfgExpirationDate)}</td>
            <td className="followup-icons">
                <a href={navPath} target="_blank" title="Open in new tab" className="open-ext">
                    <FontAwesomeIcon icon="external-link-alt" />
                </a>
                {ui_helpers.renderContractStatusBadge(c.typeOfContractStatus)}
            </td>
            <td>
              <Button color='light' size="sm" className="text-light bg-cf-red" onClick={() => cancelQuote(c.contractId)} title="Cancel">
                <FontAwesomeIcon icon="times-circle" />
              </Button>
            </td>
        </tr>);
}

const Quotes = (props) => {
  const [filters, setFilters] = useState([]);
  const [loadingPayload, setLoadingPayload] = useState(null);
  const [loadedPayload, setLoadedPayload] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [sortColumn, setSortColumn] = useState('CreatedAt');
  const [sortDirection, setSortDirection] = useState('desc');
  const [paginatedList, setPaginatedList] = useState();
  const [totalPages, setTotalPages] = useState(0);
  const [companies, setCompanies] = useState([]);
  const [manufacturers, setManufacturers] = useState([]);
  const [dealers, setDealers] = useState([]);
  const [users, setUsers] = useState([]);
  const [redirectTo, setRedirectTo] = useState('');
  const [canClear, setCanClear] = useState(false);
  // const [clear, setClear] = useState(false);
  const [newOrUsedSelection, setNewOrUsedSelection] = useState({});
  const [selectedContractIds, setSelectedContractIds] = useState([]);
  const [filtersLoaded, setFiltersLoaded] = useState(false);
  const [lastSearch, setLastSearch] = useState(null);
  const [pristineLastSearch, setPristineLastSearch] = useState(null);
  const userCtx = useContext(UserContext);
  const currentUser = userCtx?.currentUser;
  const isSysAdmin = ui_helpers.isSystemAdministrator(currentUser);
  const isCompanyAdmin = ui_helpers.isAnyCompanyAdministrator(currentUser);
  const quoteFilter = constants.QUOTE_STRING;
  const userCanCreateContract = ui_helpers.hasContractCreationPermission(currentUser);
  document.title = `Quotes - ${constants.appName}`;

  useEffect(() => {
    api.fetch("CashSale/GetContractSearchFilters/true").then(r => {
      if (!r.data) return;
      let tempUsers = ui_helpers.idNameToValueLabel(r.data.userSelect)
      r.data.companySelect.unshift({ value: 0, label: '[Any Company]' });
      r.data.manufacturerSelect.unshift({ value: 0, label: '[Any Manufacturer]' });
      r.data.dealerSelect.unshift({ label: '[Any Store]', value: 0 });
      tempUsers.unshift({ label: '[Any User]', value: 0 });
      setDealers(r.data.dealerSelect);
      setCompanies(r.data.companySelect);
      setManufacturers(r.data.manufacturerSelect);
      setUsers(tempUsers);
      const userSavedSearch = r.data.savedSearch;
      if (userSavedSearch) {
        const savedSearch = JSON.parse(userSavedSearch.serializedSearch);
        const sortDetails = savedSearch.resolvedSort;
        setPageNumber(sortDetails.page);
        setFilters(savedSearch.filters);
        setSortColumn(sortDetails.sortColumn);
        setSortDirection(sortDetails.sortAscending ? "asc" : "desc");
        setLastSearch(userSavedSearch.serializedSearch);
        setPristineLastSearch(userSavedSearch.serializedSearch);
        setLoadedPayload(
          resolvePayload(sortDetails.page, savedSearch.filters));
        setNewOrUsedSelection(
          ui_helpers.resolveNewOrUsedSelectionFromFilters(savedSearch.filters));
        setPaginatedList(r.data.initialData);
      }
    }).finally(() => setFiltersLoaded(true));
  }, []);

  useEffect(() => {
    if (!filtersLoaded) return;
    refreshData();
  }, [filtersLoaded, pageNumber, sortColumn, sortDirection, filters])

  function doSort(field, direction) {
    setSortColumn(field);
    setSortDirection(direction);
  }

  function onFilterChange(changedFilter) {
    let filterChanges = filter_helpers.getFilters(filters, changedFilter);
    setPageNumber(1);
    setCanClear(filterChanges.length > 0);
    setFilters(filterChanges);
  }

  function handleNewOrUsedSelection(selection) {
    if (selection.label === 'New Or Used')
    {
      let filterChanges = _.reject(
        filter_helpers.getFilters(filters, selection), x => x.filterName === 'NewOnly' || x.filterName === 'UsedOnly');
      setCanClear(filterChanges.length !== 0);
      setFilters(filterChanges);
    }
    else if (selection.label === 'Only New')
    {
      selection.filterName = 'NewOnly'
      selection.value = true;
      let filterChanges = _.reject(
        filter_helpers.getFilters(filters, selection), x => x.filterName === 'UsedOnly');
      setCanClear(filterChanges.length !== 0);
      setFilters(filterChanges);
    }
    else if (selection.label === 'Only Used')
    {
      selection.filterName = 'UsedOnly'
      selection.value = true;
      let filterChanges = _.reject(
        filter_helpers.getFilters(filters, selection), x => x.filterName === 'NewOnly');
      setCanClear(filterChanges.length !== 0);
      setFilters(filterChanges);
    }
  }

  function resolveSort() {
    return {
      sortColumn,
      sortAscending: sortDirection === 'asc',
      quotesOnly: true
    };
  }

  function resolvePayload(pPageNumber = null, pFilters = null) {
    let payload = {
      ...resolveSort(),
      page: pPageNumber || pageNumber
    };
    const filterList = pFilters ? pFilters : filters;
    _.each(filterList, filter => payload[filter.filterName] = filter.value);
    return payload;
  }

  function refreshData(pFilters = null, forceRefresh = false) {
    const payload = resolvePayload(null, pFilters);
    if (_.isEqual(payload, loadedPayload) && !forceRefresh) {
      // console.log('cancelled redundant refresh');
      return;
    }
    // console.log('running w/ payload', payload)
    setLoadingPayload(payload);
    api.post('CashSale/FindQuotes', payload).then((r) => {
      if (r) {
        setPaginatedList(r.data);
      }
    }).catch(error => {
      Alert.error('Failed to load quotes');
    }).finally(() => {
      if (filtersLoaded) {
        setLastSearch(
          filter_helpers.storeSaveSearch(
            filters, resolveSort(), pageNumber, null));
      }
      setLoadedPayload(payload);
      setLoadingPayload(null);
    });
  }

  function onSelectContractId(e, cId) {
      e.stopPropagation();
      let newList = selectedContractIds.slice();
      if (newList.includes(cId)) {
          newList = _.reject(newList, x => x === cId);
      } else {
          newList.push(cId);
      }
      setSelectedContractIds(newList);
  }

  function cancelQuote(quoteId) {
    confirmAlert({
      title: "Confirm Cancellation",
      message: "Are you sure you wish to cancel this quote?",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            api.post('contract/CancelContract', {
              id: quoteId,
              comment: ''
            }).then(r => {
              if (r.data.success) {
                refreshData(null, true);
              } else {
                Alert.error(r.data.message);
              }
            }).catch((e) => console.error(e));
          }
        },
        {
          label: "No"
        }
      ]
    });    
  }

  useEffect(() => {
    if (!filtersLoaded || !lastSearch || _.isEqual(pristineLastSearch, lastSearch)) return;
    filter_helpers.saveSearch(
      constants.SEARCH_AREAS.QUOTES, 
      lastSearch
    );
    setPristineLastSearch(lastSearch);
  }, [pristineLastSearch, lastSearch])

  const clearFilters = () => {
    setFilters([]);
  };

  if (redirectTo) {
    return (<Redirect to={redirectTo} />);
  }

  return (
        <div>
            <Header toolbar pos>
                {userCanCreateContract
                    ? (<Button
                        color="success"
                        className="text-light"
                        tag={Link}
                        to={constants.PATH_NAMES.NEW_QUOTE}
                    >
                      <FontAwesomeIcon icon='plus' /> Add
                    </Button>)
                    : null
                }
            </Header>
            <FilterSearch filters={filters} onClear={clearFilters} canClear={canClear} isLoading={loadingPayload ? true : false}>
                <Row>
                    <Col lg='2' sm='3'>
                        <SelectFilter
                            filterName='CompanyId'
                            displayName='Company'
                            value={filter_helpers.get_value('CompanyId', filters)}
                            options={companies ? companies : null}
                            isSingleSelect={true}
                            isDisable={!companies}
                            onChangeCallback={onFilterChange}
                            placeholder={'[Any Company]'} />

                    </Col>
                    <Col lg='2' sm='3'>
                        <SelectFilter
                            filterName='DealerId'
                            displayName='Store'
                            value={filter_helpers.get_value('DealerId', filters)}
                            options={dealers}
                            isSingleSelect={true}
                            isDisabled={!dealers}
                            onChangeCallback={onFilterChange}
                            placeholder={'[Any Store]'} />
                    </Col>
                    <Col lg='2' sm='3'>
                        <SelectFilter
                            filterName='ManufacturerId'
                            displayName='Manufacturer'
                            value={filter_helpers.get_value('ManufacturerId', filters)}
                            options={manufacturers}
                            isSingleSelect={true}
                            isDisabled={!manufacturers}
                            onChangeCallback={onFilterChange}
                            placeholder={'[Any Manufacturer]'} />
                    </Col>
                    <Col lg='2' sm='3'>
                        <SelectFilter
                            filterName='RelatedToUserId'
                            displayName='Related to'
                            value={filter_helpers.get_value('RelatedToUserId', filters)}
                            options={users}
                            isSingleSelect={true}
                            isDisabled={!users}
                            onChangeCallback={onFilterChange}
                            placeholder={'[Any User]'} />

                    </Col>
                    <Col lg='2' sm='3'>
                        <SelectFilter
                            filterName='newOrUsed'
                            displayName='New Or Used'
                            value={newOrUsedSelection}
                            options={constants.NEW_OR_USED_OPTIONS}
                            isSingleSelect
                            onChangeCallback={handleNewOrUsedSelection}
                            placeholder={'New or Used'} />

                    </Col>
                </Row>
                <Row>
                    <Col lg='2' sm='3'>
                        <TextFilter
                            filterName='CustomerName'
                            displayName='Customer Name'
                            onChangeCallback={onFilterChange}
                            value={filter_helpers.get_value('CustomerName', filters)}
                        />
                    </Col>
                    <Col lg='2' sm='3'>
                        <TextFilter
                            filterName='SerialNumber'
                            displayName='Serial Number'
                            onChangeCallback={onFilterChange}
                            value={filter_helpers.get_value('SerialNumber', filters)}
                        />
                    </Col>
                </Row>
                <Row>
                  <Col lg='2' sm='3'>
          <DateFilter
            filterName='CreatedSince'
            displayName='After'
            value={filter_helpers.get_value('CreatedSince', filters)}
            onChangeCallback={onFilterChange} />
                    </Col>
                    <Col lg='2' sm='3'>
          <DateFilter
            filterName='CreatedBefore'
            displayName='Before'
            value={filter_helpers.get_value('CreatedBefore', filters)}
            onChangeCallback={onFilterChange} />
                    </Col>
                </Row>
            </FilterSearch>
            <Pager pageNumber={pageNumber} pageSize={paginatedList ? paginatedList.pageSize : 0}
                totalResults={paginatedList ? paginatedList.totalCount : 0}
                onSetPageCallback={setPageNumber} />
            <Card>
                <CardHeader>
                    <Row>
                        <Col xs="8">
                            <ResultHeader
                              heading='Quotes'
                              totalResults={paginatedList ? paginatedList.totalCount : 0}
                              badgeClass="bg-dark"
                            />
                        </Col>
                        <Col xs="4" className='d-flex justify-content-end align-items-start'>
                        </Col>
                    </Row>
                </CardHeader>
                <Table bordered striped hover>
                    <thead>
                        <tr>
                            <th>
                              <SortHeader
                                spanOnly
                                displayName="Dealer"
                                field='DealerName'
                                sortDir={sortDirection}
                                sorted={sortColumn === 'DealerName'}
                                sortCallback={doSort}
                                isAlphabetic
                              />
                            </th>
                            <SortHeader
                                displayName='Customer'
                                field='CustomerName'
                                sortDir={sortDirection}
                                sorted={sortColumn === 'CustomerName'}
                                sortCallback={doSort}
                                isAlphabetic />
                            <SortHeader
                                displayName='Total'
                                field='Total'
                                sortDir={sortDirection}
                                sorted={sortColumn === 'Total'}
                                sortCallback={doSort} />
                            <SortHeader
                                displayName='Manufacturer'
                                field='Manufacturer'
                                sortDir={sortDirection}
                                sorted={sortColumn === 'Manufacturer'}
                                sortCallback={doSort}
                                isAlphabetic />
                            <SortHeader
                                displayName='Created'
                                field='CreatedAt'
                                sortDir={sortDirection}
                                sorted={sortColumn === 'CreatedAt'}
                                sortCallback={doSort}
                                isAlphabetic />
                            <SortHeader
                                displayName='Serial #'
                                field='SerialNo'
                                sortDir={sortDirection}
                                sorted={sortColumn === 'SerialNo'}
                                sortCallback={doSort}
                                isAlphabetic />
                            <SortHeader
                                displayName='Quote #'
                                field='Id'
                                sortDir={sortDirection}
                                sorted={sortColumn === 'Id'}
                                sortCallback={doSort}
                                isAlphabetic />
                            <SortHeader
                                displayName='Expiration Date'
                                field='ExpirationDate'
                                sortDir={sortDirection}
                                sorted={sortColumn === 'ExpirationDate'}
                                sortCallback={doSort}
                                isAlphabetic />
                            <SortHeader
                                displayName='Status'
                                field='status'
                                sortDir={sortDirection}
                                sorted={sortColumn === 'status'}
                                sortCallback={doSort}
                                isAlphabetic />
                        </tr>
                    </thead>
                    <tbody>
                        {loadingPayload || !filtersLoaded
                          ? (<tr><td colSpan='12'><Loading /></td></tr>)
                          : paginatedList && paginatedList.items && paginatedList.items.length
                            ? _.map(paginatedList.items, c =>
                              (<QuoteRow
                                key={c.contractId}
                                c={c}
                                selectedContractIds={selectedContractIds}
                                onSelectContractId={onSelectContractId}
                                onSetRedirect={setRedirectTo}
                                cancelQuote={cancelQuote}
                              />))
                            : (<tr><td colSpan='12' className='text-center'>No quotes found.</td></tr>)
                        }
                    </tbody>
                </Table>
                <CardFooter>
                    <Pager pageNumber={pageNumber} pageSize={paginatedList ? paginatedList.pageSize : 0}
                        totalResults={paginatedList ? paginatedList.totalCount : 0}
                        onSetPageCallback={setPageNumber} />
                </CardFooter>
            </Card>
            <Footer />
        </div>);
};

export default Quotes;