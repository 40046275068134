import React, { useEffect } from "react";
import { v4 as uuid } from "uuid";
import { porchTypeOptions, porchTypes } from "../floorplanConstants";
import styles from "../floorplanTool.module.css";
import {
  useActions,
  useAppState,
  useControlTarget,
  useDisplaySettings,
  useExterior,
  usePointerCoordinates,
} from "../hooks";
import {
  cornersToPathReducer,
  radiansToDegrees,
  snap,
  toFeetInchesString,
} from "../utils";
import { ControlPoint } from "./";

const controlPoint1Id = uuid();
const controlPoint2Id = uuid();

function InsetSidePorch() {
  const [exterior, exteriorDispatch] = useExterior();
  const [appState, appStateDispatch] = useAppState();
  const [controlTarget] = useControlTarget();
  const [displaySettings] = useDisplaySettings();
  const [coordinates] = usePointerCoordinates();
  const actions = useActions({ exteriorDispatch, appStateDispatch });

  useEffect(() => {
    if (appState.isDraggingPorchHandle && controlTarget === controlPoint1Id) {
      actions.setPorchWall1Length(snap(exterior.width - coordinates.x, 6));
      actions.setPorchWall2Length(snap(exterior.height - coordinates.y, 6));
    }
    if (appState.isDraggingPorchHandle && controlTarget === controlPoint2Id) {
      actions.setPorchWall4Length(snap(coordinates.x, 6));
      actions.setPorchWall2Length(snap(exterior.height - coordinates.y, 6));
    }
  }, [controlTarget, coordinates]);

  let corners = [
    {
      x: 0,
      y: 0,
    },
    {
      x: exterior.width,
      y: 0,
    },
    {
      x: exterior.width,
      y: exterior.height,
    },
    {
      x: exterior.width - exterior.porchWall1Length,
      y: exterior.height,
    },
    {
      x: exterior.width - exterior.porchWall1Length,
      y: exterior.height - exterior.porchWall2Length,
      controlTarget: controlPoint1Id,
    },
    {
      x: exterior.porchWall4Length,
      y: exterior.height - exterior.porchWall2Length,
      controlTarget: controlPoint2Id,
    },
    {
      x: exterior.porchWall4Length,
      y: exterior.height,
    },
    {
      x: 0,
      y: exterior.height,
    },
    {
      x: 0,
      y: 0,
    },
  ];

  const path = corners.reduce(cornersToPathReducer, "M ");
  return (
    <g>
      <path
        d={path}
        stroke={appState.editingPorch ? "#0d6efd" : "black"}
        strokeWidth={2}
        fill="none"
      />
      {appState.editingPorch
        ? corners
            .filter((c) => c.controlTarget)
            .map((c) => (
              <ControlPoint
                key={c.controlTarget}
                id={c.controlTarget}
                className={c.controlTarget}
                cx={c.x}
                cy={c.y}
                onMouseDown={() => actions.setIsDraggingPorchHandle(true)}
                onTouchStart={() => actions.setIsDraggingPorchHandle(true)}
              />
            ))
        : null}
      <text
        className={styles.svgTxt}
        fontSize={displaySettings.fontSize}
        x={displaySettings.fontSize}
        y={
          exterior.porchType === porchTypes.fourWallDeluxe1
            ? exterior.height - displaySettings.fontSize * 5
            : displaySettings.fontSize * 1.5
        }
      >
        <tspan>
          Porch:{" "}
          {porchTypeOptions.find((o) => o.value === exterior.porchType)?.label}
        </tspan>
        <tspan x={displaySettings.fontSize} dy={displaySettings.fontSize}>
          Length:{" "}
          {toFeetInchesString(
            exterior.width -
              exterior.porchWall1Length -
              exterior.porchWall4Length
          )}
        </tspan>
        <tspan x={displaySettings.fontSize} dy={displaySettings.fontSize}>
          Depth: {toFeetInchesString(exterior.porchWall2Length)}
        </tspan>
      </text>
    </g>
  );
}

export default InsetSidePorch;
