import React from "react";
import _ from "lodash";
import {ui_helpers} from "../helpers";
import {
  DetailsTable,
  DetailsRow
} from "./";

export default function PriceSummaryTable({
  unitPrice,
  unitPriceWithAdjustmentsString,
  priceAdjustments
}) {
  return (
    <DetailsTable>
      <DetailsRow
        heading="Starting Unit Price"
        numeric
        data={ui_helpers.formatCurrency(unitPrice)}
      />
      {_.map(
        _.filter(priceAdjustments, (pa) => pa.includeInUnitPrice),
        (pa) => {
          return (
            <DetailsRow
              key={`pa-${pa.id}`}
              numeric
              heading={
                <div className="ms-3" style={{ color: "#999" }}>
                  {pa.description}
                </div>
              }
              data={ui_helpers.priceAdjustmentCaption(pa)}
            />
          );
        }
      )}
      <DetailsRow
        heading="Unit Price w/ Adjustments"
        numeric
        data={unitPriceWithAdjustmentsString}
      />
    </DetailsTable>
  );
}