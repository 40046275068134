import React, { useState } from "react";
import { Document, pdfjs } from "react-pdf";
pdfjs.GlobalWorkerOptions.workerSrc = "pdf.worker.js";

// import testPdf from "../assets/test.pdf";
import Page from "./Page";
import useStore from "./useStore";

export default function DocumentArea({ filePath }) {
  const numPages = useStore((x) => x.numPages);
  const setNumPages = useStore((x) => x.setNumPages);
  const currentPageIndex = useStore((x) => x.currentPageIndex);
  const setCurrentPageIndex = useStore((x) => x.setCurrentPageIndex);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  return (
    <>
      <div
        className="bg-secondary d-flex justify-content-center"
        style={{ minHeight: "100vh", padding: "6rem 3rem 3rem 3rem" }}
      >
        <Document
          file={{
            url: filePath,
            httpHeaders: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }}
          onLoadSuccess={onDocumentLoadSuccess}
        >
          <Page
            pageIndex={currentPageIndex}
            renderAnnotationLayer={false}
            className="mb-2 shadow"
          />
        </Document>
      </div>
    </>
  );
}
