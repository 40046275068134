import React, { useEffect, useState } from "react";

export default function useMousePosition() {
  const [position, setPosition] = useState({ x: 0, y: 0 });

  useEffect(() => {
    const setFromEvent = (e) => setPosition({ x: e.clientX, y: e.clientY });
    const setFromTouchEvent = (e) => {
      const touch = e.touches[0];
      setPosition({
        x: touch.clientX,
        y: touch.clientY,
      });
    };
    window.addEventListener("mousemove", setFromEvent);
    window.addEventListener("touchmove", setFromTouchEvent);

    return () => {
      window.removeEventListener("mousemove", setFromEvent);
      window.removeEventListener("touchmove", setFromTouchEvent);
    };
  }, []);

  return position;
}
