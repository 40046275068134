import React, { useEffect } from "react";
import { boundingBoxOffset } from "../floorplanConstants";
import styles from "../floorplanTool.module.css";
import {
  useActions,
  useAddOns,
  useAppState,
  useControlTarget,
  useDisplaySettings,
  usePointerCoordinates,
} from "../hooks";
import useMovability from "../hooks/useMovability";
import { snap } from "../utils";
import ControlPoint from "./ControlPoint";
import { addOnTypes, unitsOfMeasure } from "../floorplanConstants";

// let startCoords = { x: 0, y: 0 };
// let startProps = { width: 0, height: 0 };

function OtherAddOn(props) {
  const [displaySettings] = useDisplaySettings();
  const [controlTarget, setControlTarget] = useControlTarget();
  const [transform, handleMove, handleRotate] = useMovability(props);
  const [appState, appStateDispatch] = useAppState();
  const [, addOnsDispatch] = useAddOns();
  const [coordinates] = usePointerCoordinates();
  const actions = useActions({ appStateDispatch, addOnsDispatch });

  const active = controlTarget.includes(props.domId);
  const isOtherSqFt = props 
    && props.type === addOnTypes.other 
    && props.uom === unitsOfMeasure.sqFt;
  let resolvedWidth = props.width;
  let resolvedHeight = props.height;
  // Conrad would like these to always display without dimensions
  if (isOtherSqFt) {
    resolvedWidth = 0;
    resolvedHeight = 0;    
  }
  return (
    <g transform={transform}>
      <rect
        x={-(resolvedWidth / 2)}
        y={-(resolvedHeight / 2)}
        width={resolvedWidth}
        height={resolvedHeight}
        fill="rgba(0,0,0,0.1)"
        stroke="black"
        strokeWidth={1}
        strokeDasharray={2}
      />
      <rect
        id={props.domId}
        onMouseDown={handleMove}
        onTouchStart={handleMove}
        style={{ cursor: active ? "move" : "pointer" }}
        x={-(resolvedWidth / 2) - boundingBoxOffset}
        y={-(resolvedHeight / 2) - boundingBoxOffset}
        width={resolvedWidth + boundingBoxOffset * 2}
        height={resolvedHeight + boundingBoxOffset * 2}
        fill={active ? "rgba(0,0,255,0.05)" : "rgba(0,0,0,0)"}
        stroke={active ? "#0d6efd" : "none"}
        strokeWidth={0.5}
        strokeDasharray={2}
      />
      <text
        className={styles.svgTxt}
        fontSize={displaySettings.fontSize}
        x={-(resolvedWidth / 2) + 4}
        y={-(resolvedHeight / 2) + displaySettings.fontSize * 1.5}
      >
        {props.name}
      </text>
      {active ? (
        <>
          <line
            x1={0}
            y1={-(resolvedHeight / 2) - boundingBoxOffset}
            x2={0}
            y2={-(resolvedHeight / 2) - boundingBoxOffset - 18}
            stroke="#0d6efd"
            strokeWidth={0.5}
          />
          <ControlPoint
            id={"rotate-" + props.domId}
            cx={0}
            cy={-(resolvedHeight / 2) - boundingBoxOffset - 18}
            onClick={() => setControlTarget("rotate-" + props.domId)}
            onMouseDown={handleRotate}
            onTouchStart={handleRotate}
          />
          <text
            x={6}
            y={-(resolvedHeight / 2) - 12}
            fill="#0d6efd"
            className={styles.svgTxt}
            fontSize={displaySettings.fontSize * 1.5}
          >
            {Math.round(props.rotation)}°
          </text>
        </>
      ) : null}
    </g>
  );
}

export default OtherAddOn;
