import React, {
  useState,
  useEffect,
  useCallback,
  useContext,
  useRef,
} from 'react';
import classnames from 'classnames';
import {
  Row,
  Col,
  Table,
  Label,
  Input,
  Container,
  Card,
  CardHeader,
  CardBody,
  Button,
  ButtonGroup,
  Collapse,
  Badge
} from 'reactstrap';
import { api, ui_helpers, filter_helpers, constants } from '../helpers';
import { UserContext } from '../utils';
import { useParams } from 'react-router-dom';
import {
  ActiveToggleFilter,
  SelectFilter,
  TextFilter,
  FilterSet,
  UserAddEdit,
  SortHeader,
  Header,
  Footer,
  Loading,
  MainNav, AdminHeader, FilterSearch
} from '../components';
import Cookies from 'js-cookie';
import {library} from "@fortawesome/fontawesome-svg-core";
import {
  faPlus,
  faHome,
  faFilter,
  faAngleUp,
  faTrashAlt,
  faEdit
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Alert from 'react-s-alert-v3';
import _ from 'lodash';
import { Link } from 'react-router-dom';


export default function Users(props) {
  let { companyId } = useParams();
  companyId = companyId ? parseInt(companyId, 10) : null;

  const userCtx = useContext(UserContext);
  const [filters, setFilters] = useState([
      {filterName: 'activeOnly', value: true, filteredDisplay: 'Active Only?: true'}
    ]);
  const [users, setUsers] = useState([]);
  const [currentUser, setCurrentUser] = useState(
    userCtx ? Object.assign({}, userCtx.currentUser) : {}
  );
  const [companies, setCompanies] = useState([]);
  const [dealers, setDealers] = useState([]);
  const [manufacturers, setManufacturers] = useState([]);
  const [availableRoles, setAvailableRoles] = useState(
    ui_helpers.filterAvailableRoles(currentUser)
  );
  const [showCompany, setShowCompany] = useState(true);
  const [showDealer, setShowDealer] = useState(true);
  const [showManufacturer, setShowManufacturer] = useState(true);
  const [sortingColumn, setSortingColumn] = useState('id');
  const [sortDirection, setSortDirection] = useState('asc');
  const [contextCompanyId, setContextCompanyId] = useState(null);
  const [defaultCompanyId, setDefaultCompanyId] = useState(null);
  const [manufacturerId, setManufacturerId] = useState(null);
  const [dealerId, setDealerId] = useState(null);
  const [userAddEdit, setUserAddEdit] = useState(false);
  const [clear, setClear] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const PATHNAMES = constants.PATH_NAMES;

  const [userName, setUserName] = useState('');
  const controller = new AbortController();

  useEffect(() => {
    let isActive = true;
    if(isActive) {
      setDefaultCompanyId(currentUser.homeCompanyId);
      setShowCompany(
          contextCompanyId === null &&
          _.some(
              currentUser.roles,
              (ur) => ur.typeOfUserRole === constants.ROLE_IDS.SystemAdministrator
          )
      );
      const SELECT_ROLES = [
        constants.ROLE_IDS.SystemAdministrator,
        constants.ROLE_IDS.UserManager,
        constants.ROLE_IDS.Administrator,
      ];
      if (
          _.some(currentUser.roles, (r) =>
              _.includes(SELECT_ROLES, r.typeOfUserRole)
          )
      ) {
        setShowManufacturer(true);
        setShowDealer(true);
      }
    }

    return () => {
      isActive = false;

    }
  }, [userCtx.currentUser]);

  useEffect(() => {
    let isActive = true;
    refreshList(isActive);

    return () => {
      isActive = false;
      controller.abort();
    }

  }, [filters, sortDirection, sortingColumn, clear]);

  useEffect(() => {
    let isActive = true;
    setContextCompanyId(props.companyId);
    let apiCalls = [];
    if (showCompany) apiCalls.push(getCompanies());
    if (showManufacturer) apiCalls.push(getManufacturers());
    if (showDealer) apiCalls.push(getDealers());
    if (apiCalls.length === 0) {
      refreshList(isActive);
    } else {
      Promise.all(apiCalls)
        .then((arrayResults) => {
          if(isActive) {
            let aggResults = {};
            _.each(arrayResults, (x) => Object.assign(aggResults, x));
            const { inDealers, inCompanies, inManufacturers } = aggResults;
            setDealers(inDealers);
            if(showCompany) setCompanies(inCompanies);
            setManufacturers(inManufacturers);
          }
        })
        .catch((error) => {
          console.error('Error in promise', error);
        });
    }

    return () => {
      isActive = false;
    }

  }, []);

  const getDealers = () => {
    return api.fetch('user/GetDealers').then((r) => {
      if (r.data)
        return { inDealers: ui_helpers.idNameToValueLabel(r.data.data) };
    });
  };

  const getManufacturers = () => {
    return api.fetch('user/GetManufacturers').then((r) => {
      if (r.data)
        return { inManufacturers: ui_helpers.idNameToValueLabel(r.data.data) };
    });
  };

  const getCompanies = () => {
    return api.fetch('user/GetCompanies').then((r) => {
      if (r.data)
        return { inCompanies: ui_helpers.idNameToValueLabel(r.data.data) };
    });
  };

  function refreshList(isActive){
    const resolvedSort = {
      sortColumn: sortingColumn,
      sortAscending: sortDirection === 'asc',
    };
    const payload = {
      ...resolvedSort,
      homeCompanyId: props.companyId ? props.companyId : filter_helpers.getFilterValue(filters, "company"),
    };
    _.each(filters, filter => payload[filter.filterName] = filter.values ? filter.values : filter.value );
    setIsLoading(true);
    api
      .post('user/GetFilteredUserList', payload)
      .then((r) => {
        if (r.data.success) {
          if(isActive) {
            setUsers(r.data.data);
          }
        } else {
          Alert.warning(r.data.message);
        }
      })
      .catch((error) => {
        Alert.error('There was an error loading the user list')
      }).finally(() => setIsLoading(false));
  };

  const filterChange = (changed_filter) => {
    const filterList = filter_helpers.getFilters(filters, changed_filter);
    setFilters(filterList);
  };

  const clearFilters = () => {
    setFilters([]);
    setClear(!clear);
  };

  const toggleUserAddEdit = () => {
    setUserAddEdit(!userAddEdit);
  };

  const deactivateUser = (userId) => {
    api
      .fetch(`user/Deactivate/${userId}`)
      .then((response) => {
        if (response.data.success) {
          let userList = users.slice();
          let userIndex = _.findIndex(userList, (x) => x.id === userId);
          userList[userIndex].isActive = false;
          setUsers(userList);
          refreshList();
        } else {
          Alert.error('There was an error deactivating the user');
        }
      })
      .catch((error) => Alert.error('There was an error updating the user'));
  };

  const activateUser = (userId) => {
    api
      .fetch(`user/Activate/${userId}`)
      .then((response) => {
        if (response.data.success) {
          let userList = users.slice();
          let userIndex = _.findIndex(userList, (x) => x.id === userId);
          userList[userIndex].isActive = true;
          setUsers(userList);
          refreshList();
        } else {
          Alert.error('There was an error activating the user');
        }
      })
      .catch((error) => Alert.error('There was an error updating the user'));
  };

  const doSort = (field, direction) => {
    setSortingColumn(field);
    setSortDirection(direction);
  };

  return (
    <>
      <Row>
        <Col>
        { userAddEdit ? null
          : (<FilterSearch filters={filters} onClear={clearFilters}>
              <Row>
                <Col>
                  <TextFilter
                      filterName='name'
                      displayName='Name'
                      onChangeCallback={filterChange}
                      value={filter_helpers.get_value('name', filters)}
                  />
                </Col>
                <Col>
                  <TextFilter
                      filterName='username'
                      displayName='Username'
                      onChangeCallback={filterChange}
                      value={filter_helpers.get_value('username', filters)}
                  />
                </Col>
                <Col>
                  <SelectFilter
                      isSingleSelect={false}
                      filterName='memberOfRoleList'
                      displayName='User Role(s)'
                      options={availableRoles}
                      onChangeCallback={filterChange}
                      value={filter_helpers.get_value('memberOfRoleList', filters)}
                      clear={clear}
                  />
                </Col>
                <Col>
                  <ActiveToggleFilter
                    filterName='activeOnly'
                    displayName='Active Only?'
                    value={filter_helpers.get_value('activeOnly', filters)}
                    onChangeCallback={filterChange}
                    clear={clear}
                  />
                </Col>
              </Row>
              <Row>
                {showCompany
                    ? <Col>
                      <SelectFilter
                          isSingleSelect={true}
                          filterName='company'
                          displayName='Company'
                          isDisabled={!companies || !companies.length}
                          options={companies ? companies : null}
                          onChangeCallback={filterChange}
                          value={filter_helpers.get_value('company', filters)}
                          clear={clear}
                      />
                    </Col>
                    : null}
                {showDealer
                    ? <Col>
                      <SelectFilter
                          isSingleSelect={true}
                          filterName='dealerId'
                          displayName='Store'
                          isDisabled={!dealers || !dealers.length}
                          options={dealers}
                          onChangeCallback={filterChange}
                          value={filter_helpers.get_value('dealerId', filters)}
                          clear={clear}
                      />
                    </Col>
                    : null}
                {showManufacturer
                    ?  <Col>
                      <SelectFilter
                          isSingleSelect={true}
                          filterName='manufacturerId'
                          displayName='Manufacturer'
                          isDisabled={!manufacturers || !manufacturers.length}
                          options={manufacturers}
                          onChangeCallback={filterChange}
                          value={filter_helpers.get_value(
                              'manufacturerId',
                              filters
                          )}
                          clear={clear}
                      />
                    </Col>
                    : null}
              </Row>
            </FilterSearch>)
        }
        </Col>
      </Row>
        <Row className='g-0'>
          <Col>
            <Card style={{ borderColor: "transparent" }}>
              <CardHeader style={{ borderColor: "transparent" }}>
                <Row>
                  <Col>
                    <h2 className="page-title">Users {users && users.length > 0 && !userAddEdit && (<Badge color="dark" className="ms-2 text-light" pill style={{border: "2px solid black"}}>{users.length}</Badge>)}</h2>
                  </Col>
                  <Col>
                    <ButtonGroup className='float-end'>
                      <Button
                          size="sm"
                          className='btn-success cf-success float-end buttonGroupSpacing'
                          style={{ color: "white" }}
                          onClick={() => toggleUserAddEdit()}
                      >
                        {userAddEdit ?
                            <FontAwesomeIcon icon='minus' />
                            : <FontAwesomeIcon icon='plus' />}{" "}
                        User
                      </Button>
                      <Link to="/">
                        <Button size="sm" className="btn-dark">
                          <FontAwesomeIcon icon="home" />
                        </Button>
                      </Link>
                  </ButtonGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  {/*<FilterSet filters={filters} clearFilters={clearFilters}>*/}
                  {/*  <Col>*/}
                  {/*    <TextFilter*/}
                  {/*      filterName='name'*/}
                  {/*      displayName='Name'*/}
                  {/*      onChangeCallback={filterChange}*/}
                  {/*      value={filter_helpers.get_value('name', filters)}*/}
                  {/*    />*/}
                  {/*  </Col>*/}
                  {/*  <Col>*/}
                  {/*    <TextFilter*/}
                  {/*      filterName='user_name'*/}
                  {/*      displayName='Username'*/}
                  {/*      onChangeCallback={filterChange}*/}
                  {/*      value={filter_helpers.get_value('user_name', filters)}*/}
                  {/*    />*/}
                  {/*  </Col>*/}
                  {/*  <Col>*/}
                  {/*    <SelectFilter*/}
                  {/*      isSingleSelect={false}*/}
                  {/*      filterName='user_role'*/}
                  {/*      displayName='User Role(s)'*/}
                  {/*      options={availableRoles}*/}
                  {/*      onChangeCallback={filterChange}*/}
                  {/*      value={filter_helpers.get_value('user_role', filters)}*/}
                  {/*      clear={clear}*/}
                  {/*    />*/}
                  {/*  </Col>*/}
                  {/*  <Col>*/}
                  {/*    <ActiveToggleFilter*/}
                  {/*      filterName='is_active'*/}
                  {/*      displayName='Active Only?'*/}
                  {/*      value={filter_helpers.get_value('is_active', filters)}*/}
                  {/*      onChangeCallback={filterChange}*/}
                  {/*      clear={clear}*/}
                  {/*    />*/}
                  {/*  </Col>*/}
                  {/*  {showCompany*/}
                  {/*    ? <Col>*/}
                  {/*      <SelectFilter*/}
                  {/*        isSingleSelect={true}*/}
                  {/*        filterName='company'*/}
                  {/*        displayName='Company'*/}
                  {/*        isDisabled={!companies && companies.length}*/}
                  {/*        options={companies ? companies : null}*/}
                  {/*        onChangeCallback={filterChange}*/}
                  {/*        value={filter_helpers.get_value('company', filters)}*/}
                  {/*        clear={clear}*/}
                  {/*      />*/}
                  {/*    </Col>*/}
                  {/*  : null}*/}
                  {/*  {showDealer*/}
                  {/*      ? <Col>*/}
                  {/*      <SelectFilter*/}
                  {/*        isSingleSelect={true}*/}
                  {/*        filterName='dealer'*/}
                  {/*        displayName='Store'*/}
                  {/*        isDisabled={!dealers && dealers.length}*/}
                  {/*        options={dealers}*/}
                  {/*        onChangeCallback={filterChange}*/}
                  {/*        value={filter_helpers.get_value('dealer', filters)}*/}
                  {/*        clear={clear}*/}
                  {/*      />*/}
                  {/*    </Col>*/}
                  {/*  : null}*/}
                  {/*  {showManufacturer*/}
                  {/*  ?  <Col>*/}
                  {/*      <SelectFilter*/}
                  {/*        isSingleSelect={true}*/}
                  {/*        filterName='manufacturer'*/}
                  {/*        displayName='Manufacturer'*/}
                  {/*        isDisabled={!manufacturers && manufacturers.length}*/}
                  {/*        options={manufacturers}*/}
                  {/*        onChangeCallback={filterChange}*/}
                  {/*        value={filter_helpers.get_value(*/}
                  {/*          'manufacturer',*/}
                  {/*          filters*/}
                  {/*        )}*/}
                  {/*        clear={clear}*/}
                  {/*      />*/}
                  {/*    </Col>*/}
                  {/*  : null}*/}
                  {/*</FilterSet>*/}
                </Col>
              </Row>
            </CardHeader>
          </Card>
        </Col>
      </Row>
      <Row className='rowSpacing'>
        <Col>
          <Card style={{ borderColor: 'transparent' }}>
            {isLoading ? (
                <Loading />
            ) : (
            <CardBody className='pt-0'>
              {userAddEdit ? (
                <UserAddEdit
                  currentUser={currentUser}
                  returnRoute=''
                  computedMatch={props.computedMatch}
                  manufacturer={false}
                  store={false}
                  cancel={() => setUserAddEdit(false)}
                />
              ) : (

                <Table size='sm' bordered striped>
                  <thead>
                    <tr>
                        <SortHeader
                          displayName='Name'
                          field='name'
                          sortDir={sortDirection}
                          sorted={sortingColumn === 'name'}
                          sortCallback={doSort}
                        />
                        <SortHeader
                          displayName='Username'
                          field='username'
                          sortDir={sortDirection}
                          sorted={sortingColumn === 'username'}
                          sortCallback={doSort}/>
                        <SortHeader
                          displayName='Email'
                          field='email'
                          sortDir={sortDirection}
                          sorted={sortingColumn === 'email'}
                          sortCallback={doSort}
                        />
                        <SortHeader style={{ width: '230px' }}
                          displayName='Phone #'
                          field='phone'
                          sortDir={sortDirection}
                          sorted={sortingColumn === 'phone'}
                          sortCallback={doSort}
                        />
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {users.length > 0 &&
                      users.map((x) => (
                        <tr
                          key={x.id}
                          className={classnames({
                            'tr-inactive': !x.isActive,
                            'text-muted': !x.isActive,
                            'deactivated-row': !x.isActive,
                          })}>
                          <td>
                            {x.firstName} {x.lastName}
                          </td>
                          <td>{x.name}</td>
                          <td>{x.email}</td>
                          <td>{x.phone}</td>
                          <td>
                            <ButtonGroup className='float-end'>
                              {x.isActive ? (
                                <Button
                                  size='sm'
                                  title="Deactivate User"
                                  className='btn-delete cf-danger'
                                  onClick={() => deactivateUser(x.id)}>
                                  <FontAwesomeIcon icon='trash-alt' />
                                </Button>
                              ) : (
                                <Button
                                  size='sm'
                                  title="Revive user"
                                  className='bg-cf-success border-success'
                                  onClick={() => activateUser(x.id)}>
                                  <FontAwesomeIcon icon={'heartbeat'}/>
                                </Button>
                              )}
                              <Link
                                className='btn btn-sm btn-dark'
                                to={{
                                  pathname: `/user/${x.id}`,
                                  state: {
                                    currentUser: currentUser,
                                    // returnRoute: returnRoute
                                  },
                                }}>
                                  <FontAwesomeIcon icon='edit' />
                                  </Link>
                        </ButtonGroup>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          )}
        </CardBody>)}
      </Card>
    </Col>
  </Row>
      <Footer/>
    </>
  );
}
