import React, { useState } from "react";
import { infoMessages } from "../../infoMessages";
import { InfoContext } from "../contexts";

function InfoProvider({ children }) {
  const [info, setInfo] = useState(infoMessages.default);

  return (
    <InfoContext.Provider value={[info, setInfo]}>
      {children}
    </InfoContext.Provider>
  );
}

export default InfoProvider;
