import React, {useState} from 'react';
import {Dropdown, DropdownMenu, DropdownItem, DropdownToggle} from "reactstrap";
import _ from 'lodash';




export default function SubNavigationDropdown(props) {
    const [open, setOpen] = useState(false);


    const delayClose = () => {
        setTimeout(() =>
        setOpen(false), 25)
    };

    return(
        <Dropdown className='sub-navigation-dropdown' isOpen={open}
                  onMouseEnter={() => setOpen(true)}
                  onMouseLeave={() => delayClose()}
                  toggle={() => setOpen(!open)}>
        <DropdownToggle caret>
            {props.buttonText}
        </DropdownToggle>
        <DropdownMenu>
            {props.children}
        </DropdownMenu>
    </Dropdown>)
        ;
}