import React, { useEffect, useState } from "react";
import _ from "lodash";
import { useParams, Link } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Nav,
  NavItem,
  NavLink,
  Row,
} from "reactstrap";
import {
  EditSubProductInfo,
  EditSubProductUnitTypes,
  EditSubProductColorOptions,
  EditSubProductAddOnOpts,
  Loading,
  MfgAdmin
} from "./";
import { api } from "../utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const tabs = [
  "Product Info",
  "Material Options",
  "Color Options"
];

const sidebarWidth = 2;

export default function EditSubProduct(_props) {
  const [activeTab, setActiveTab] = useState(tabs[0]);
  const [manufacturerName, setManufacturerName] = useState('');
  const [subProductName, setSubProductName] = useState('');
  const [existingSubProduct, setExistingSubProduct] = useState(null);
  const [productTypeOpts, setProductTypeOpts] = useState([]);
  const [unitTypeOptions, setUnitTypeOptions] = useState([]);
  const {mfgId, subProductId} = useParams();
  const [colorTypes, setColorTypes] = useState([]);
  const [colorTypeOptions, setColorTypeOptions] = useState([]);
  const [colorOptions, setColorOptions] = useState([]);

  useEffect(() => {
    api.fetch(`Manufacturer/GetSubProduct/${subProductId}`).then((res) => {
      setSubProductName(res.data.data.name);
      setManufacturerName(res.data.data.manufacturerName);
      setExistingSubProduct(res.data.data);
    }).catch((e) => console.error(e));
    api.fetch("Manufacturer/ListProductTypes").then(res => {
      setProductTypeOpts(res.data);
    }).catch((e) => console.error(e));
    loadMfgSubProductUnitTypeOptions();
    getColorOptionData();
  }, [subProductId]);

  function loadMfgSubProductUnitTypeOptions() {
    api.fetch(`Manufacturer/ListMfgSubProductUnitTypeOptions?mfgId=${mfgId}&subProductId=${subProductId}`).then(res => {
      setUnitTypeOptions(
        _.sortBy(res.data.data.unitTypeOptions, ["unitTypeName"]));
    }).catch((e) => console.error(e));
  }

  function getColorOptionData() {
    api.fetch(`Manufacturer/ListManufacturerColorOptions/${mfgId}/${subProductId}`).then((res) => {
      setColorTypes(res.data.data.colorTypeNames);
      let ctOptions = _.map(res.data.data.colorTypeNames, ctn => ({label: ctn + ' Colors', value: ctn}));
      ctOptions = [{label: '(All Color Types)', value: 'all'}, ...ctOptions];
      setColorTypeOptions(ctOptions);
      setColorOptions(res.data.data.colorOptions);
    }).catch((e) => console.error(e));
  }

  return (<>
    <MfgAdmin mfgId={mfgId} tabName="PRODUCTS">
      <Container fluid>
        <Row>
          <Col>
            <h2 className="py-2">
              <Link to={`/admin/manufacturer/${mfgId}`}>{manufacturerName}</Link> / Edit Product: {subProductName}
            </h2>
          </Col>
        </Row>
        <Card>
          <CardHeader>
            <Row>
              <Col sm={2}>
                <Button tag={Link} to={`/admin/manufacturer/${mfgId}/products`}>
                  <FontAwesomeIcon icon={'arrow-left'}/> Products
                </Button>
              </Col>
            </Row>
          </CardHeader>
          <CardBody>
            <Row>
              <Col xs={sidebarWidth}>
                <Nav vertical pills>
                  {_.map(tabs, (t) => (
                    <NavItem key={t}>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        active={activeTab === t}
                        onClick={() => setActiveTab(t)}
                      >
                        {t}
                      </NavLink>
                    </NavItem>
                  ))}
                </Nav>
              </Col>
              <Col xs={12 - sidebarWidth}>
                {activeTab === tabs[0] && productTypeOpts.length && (
                  <EditSubProductInfo
                    mfgId={mfgId}
                    existingSubProduct={existingSubProduct}
                    productTypeOpts={productTypeOpts}
                  />
                )}
                {activeTab === tabs[1] && (
                  <EditSubProductUnitTypes
                    mfgId={mfgId}
                    subProductId={subProductId}
                    subProductName={subProductName}
                    unitTypeOptions={unitTypeOptions}
                    loadMfgSubProductUnitTypeOptions={loadMfgSubProductUnitTypeOptions}
                  />
                )}
                {activeTab === tabs[2] && (
                  <EditSubProductColorOptions
                    mfgId={mfgId}
                    subProductId={subProductId}
                    subProductName={subProductName}
                    colorOptions={colorOptions}
                    colorTypeOptions={colorTypeOptions}
                    colorTypes={colorTypes}
                    onReloadColors={getColorOptionData}
                  />
                )}
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Container>
    </MfgAdmin>
  </>);
}
