import React from "react";
import { boundingBoxOffset } from "../floorplanConstants";
import { useControlTarget, useMovability } from "../hooks";

function Light(props) {
  const [transform, handleMove] = useMovability(props);
  const [controlTarget] = useControlTarget();

  const active =
    controlTarget === props.domId || controlTarget === "rotate-" + props.domId;

  return (
    <g transform={transform}>
      <circle
        cx={0}
        cy={0}
        r={3}
        stroke="black"
        strokeWidth={0.5}
        fill="none"
      />
      <line x1={-3} y1={0} x2={-6} y2={0} stroke="black" strokeWidth={0.5} />
      <line x1={3} y1={0} x2={6} y2={0} stroke="black" strokeWidth={0.5} />
      <line x1={0} y1={-3} x2={0} y2={-6} stroke="black" strokeWidth={0.5} />
      <line x1={0} y1={3} x2={0} y2={6} stroke="black" strokeWidth={0.5} />
      <circle
        id={props.domId}
        cx={0}
        cy={0}
        r={3 + boundingBoxOffset * 2}
        style={{ cursor: active ? "move" : "pointer" }}
        fill={active ? "rgba(0,0,255,0.05)" : "rgba(0,0,0,0)"}
        stroke={active ? "#0d6efd" : "none"}
        strokeDasharray={2}
        strokeWidth={0.5}
        onMouseDown={handleMove}
        onTouchStart={handleMove}
      />
    </g>
  );
}

export default Light;
