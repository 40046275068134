import React, { useEffect, useState, useContext, Fragment } from "react";
import ReactTooltip from "react-tooltip";
import { Link, Redirect, useHistory } from "react-router-dom";
import { Badge, Button, Dropdown, DropdownItem, DropdownToggle, DropdownMenu, 
  Spinner, Table, Row, Col, Card, CardBody, CardHeader, CardFooter, Input, Label, ButtonGroup, ButtonDropdown,
  Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import _ from "lodash";
import {
    Header,
    Footer,
    EssentialIcon,
    FollowupIcon,
    Loading,
    SortHeader,
    ResultHeader,
    Pager,
    FilterSearch,
    SelectFilter,
    TextFilter,
    DateFilter,
    FilterSwitch
} from "./";
import { ResultsList } from ".";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {api, constants, UserContext} from "../utils";
import {date_helpers, filter_helpers, ui_helpers} from "../helpers";
import {useConsole} from "../hooks";
import Alert from "react-s-alert-v3";
import classnames from "classnames";
const PATHNAMES = constants.PATH_NAMES;
const ICONS = constants.ICONS;

const FilterOptions = {
  NONE: -20,
  CONTRACTS: 0,
  DRAFTS: 1,
  PREVERIFICATION: 2,
  INREVIEW: 3,
  ONHOLD: 4,
  INPROGRESS: 5,
  DELIVERED: 6,
  SAVED_FILTER: 7
};

const ContractRow = ({ c, selectedContractIds, onSelectContractId, onSetRedirect, onSetCourtesyCallComplete, currentUser }) => {
  const navPath = c.typeOfContractStatus === constants.CONTRACT_STATUS_ID.Quote
    ? `${constants.PATH_NAMES.QUOTE_VIEW}/${c.contractId}`
    : `${PATHNAMES.CONTRACT_VIEW}/${c.contractId}`;

  function onNavigate() {
    onSetRedirect(navPath);
  }
  const daysSinceInception = date_helpers.calculateAgeInDays(c.createdAt);
  const isSelected = selectedContractIds.includes(c.contractId);
  return (
    <tr className={classnames({selected: isSelected}, 'selectable-row')}>
    <td>
      <Label check>
        <Input type="checkbox" checked={isSelected} onChange={(e) => onSelectContractId(e, c.contractId)} />
      </Label>
    </td>
    <td style={{textAlign: "left"}} onClick={onNavigate}>
       {c.dealerName}<br/>
       {c.assignedDriverUserFullName ? (
         <div>
             <em className="text-muted">
                 {c.assignedDriverUserFullName} ({date_helpers.dateTimeFormat(c.driverLastAssignedAt)})
             </em>
         </div>
       ) : null}
     </td>
     <td onClick={onNavigate}>{c.agreementNumber}</td>
     <td onClick={onNavigate}>
         <Col>
             <div>{c.customerFirstName} {c.customerLastName}</div>
             <div>{c.customerPhone1 ? c.customerPhone1 : c.customerPhone2}</div>
         </Col>
     </td>
     <td onClick={onNavigate}>{ui_helpers.formatCurrencyWithoutDecimal(c.unitPrice + c.sumOfPriceAdjustments)}</td>
     <td onClick={onNavigate}>{c.manufacturerName}</td>
     <td onClick={onNavigate}>{date_helpers.formatDateToShortDate(c.createdAt)}</td>
     <td onClick={onNavigate}>
       <div>{c.serialNumber}</div>
       {c.inventoryId ? <div title="Paired to Inventory"><FontAwesomeIcon icon="home" color="primary"/></div> : null}
     </td>
     <td onClick={onNavigate}>{c.companyName}</td>
     <td onClick={onNavigate} className="essential-icons">
       <Row>
         <div className='d-flex justify-content-center'>
           <EssentialIcon type="contract" id={`c-${c.contractId}`} icon={ICONS.contract} daysSinceInception={daysSinceInception}
              milestoneStatusId={c.milestoneStatus_Contract}/>
           <EssentialIcon type="payment" id={`p-${c.contractId}`} icon ={ICONS.payment} daysSinceInception={daysSinceInception}
              milestoneStatusId={c.milestoneStatus_Payment}/>
           <EssentialIcon type="landlord" id={`l-${c.contractId}`} icon ={ICONS.landlord} daysSinceInception={daysSinceInception}
              milestoneStatusId={c.milestoneStatus_LandlordPermission}/>
           <EssentialIcon type="delivery" id={`d-${c.contractId}`} icon ={ICONS.delivery} daysSinceInception={daysSinceInception}
              milestoneStatusId={c.milestoneStatus_Delivery}/>
         </div>
       </Row>
     </td>
     <td className="followup-icons">
        <a href={navPath} target="_blank" title="Open in new tab" className="open-ext">
          <FontAwesomeIcon icon="external-link-alt"/>
        </a>
        <Row className="mx-0">
          <Col xs="12" className='d-flex mt-2 mb-1'>
            {ui_helpers.renderContractStatusBadge(c.typeOfContractStatus, null, c.lastSetCompleteAt)}
          </Col>
        </Row>
        <Row className="mx-0">
           <Col xs="12" className='d-flex'>
              {ui_helpers.isAnyCompanyAdministrator(currentUser) || 
                ui_helpers.isAnyManufacturerAdministrator(currentUser) || 
                ui_helpers.isSystemAdministrator(currentUser) ? (
                  <>
                    <FollowupIcon 
                      id={`r-${c.contractId}`} 
                      icon={ICONS.rto} 
                      followUpItem={constants.FOLLOW_UP.RTO} 
                      done={c.followupStatus_LoadedToRTO}
                    />
                    {c.followupStatus_CourtesyCall ? (
                      <FollowupIcon 
                        id={`c-${c.contractId}`}
                        icon={ICONS.call}
                        followUpItem={constants.FOLLOW_UP.CALL}
                        done={c.followupStatus_CourtesyCall}
                      />
                    ) : (
                      <>
                        <Button
                            style={{padding: "0 0.35rem", fontSize: "0.75em"}} 
                            className={`bg-${ui_helpers.followUpBackground(constants.FOLLOW_UP.CALL, false)} me-1`} 
                            data-tip data-for="tt-2" onClick={() => onSetCourtesyCallComplete(c.contractId)}>
                          <FontAwesomeIcon icon={ICONS.call} style={{color: '#000'}}/>
                        </Button>
                        <ReactTooltip
                          className='follow-opacity'
                          place='bottom'
                          id="tt-2"
                          type={ui_helpers.resolveTooltipTypeByBgColor(ui_helpers.followUpBackground(constants.FOLLOW_UP.CALL, false))}
                          effect='solid'
                        >
                          <p style={{fontSize: '1rem'}}>No courtesy call has been placed.</p>
                        </ReactTooltip>
                      </>
                    )}
                    {c.followupStatus_Commission != null ? (
                      <FollowupIcon 
                        id={`m-${c.contractId}`} 
                        icon={ICONS.commission} 
                        followUpItem={constants.FOLLOW_UP.COMMISSION} 
                        done={c.followupStatus_Commission}
                      />
                    ) : null }
                  </>
              ) : null }
              <FollowupIcon 
                id={`h-${c.contractId}`} 
                icon={ICONS.hold} 
                followUpItem={constants.FOLLOW_UP.HOLD} 
                done={c.followupStatus_OnHold}
              />
           </Col>
        </Row>
     </td>
 </tr>);
}

const RTOContracts = () => {
  document.title = `RTO - ${constants.appName}`;
  const history = useHistory();
  const [loadingPayload, setLoadingPayload] = useState(null);
  const [loadedPayload, setLoadedPayload] = useState(null);
  const [filtersLoaded, setFiltersLoaded] = useState(false);
  const [filters, setFilters] = useState([]);
  const [sortColumn, setSortColumn] = useState('CreatedAt');
  const [sortDirection, setSortDirection] = useState('desc');
  // const [clear, setClear] = useState(false);
  const [quickFilters, setQuickFilters] = useState({});
  const [processedCounts, setProcessedCounts] = useState({});
  const [lastFilteredUsingQuickSearchKey, setLastFilteredUsingQuickSearchKey] = useState('');
  const [pageNumber, setPageNumber] = useState(1);
  const [paginatedList, setPaginatedList] = useState(null);
  const [totalPages, setTotalPages] = useState(0);
  const [inventory, setInventory] = useState();
  const [canClear, setCanClear] = useState(false);
  const [companies, setCompanies] = useState([]);
  const [manufacturers, setManufacturers] = useState([]);
  const [dealers, setDealers] = useState([]);
  const [users, setUsers] = useState([]);
  const [message, setMessage] = useState({flavor: constants.flavor.success, message: ''});
  const [filterCounts, setFilterCounts] = useState([]);
  const [yearDropdownOpen, setYearDropdownOpen] = useState(false);
  const [newOrUsedSelection, setNewOrUsedSelection] = useState(null);
  const [newDropdownOpen, setNewDropdownOpen] = useState(false);
  const [selectedContractIds, setSelectedContractIds] = useState([]);
  const [selectAllContracts, setSelectAllContracts] = useState(false);
  const [redirectTo, setRedirectTo] = useState('');
  const [processMessageList, setProcessMessageList] = useState(null);
  const [selectedMenuOpen, setSelectedMenuOpen] = useState(false);
  const [selectedFilterOption, setSelectedFilterOption] = useState(FilterOptions.CONTRACTS);
  const [totalBadgeClass, setTotalBadgeClass] = useState('bg-dark');
  const [lastSearch, setLastSearch] = useState(null);
  const [pristineLastSearch, setPristineLastSearch] = useState(null);
  const [isQuickFilter, setIsQuickFilter] = useState(false);
  const [contractStatusTypes, setContractStatusTypes] = useState(
    ui_helpers.idNameToValueLabel(constants.CONTRACT_STATUS_NAME));

  const userCtx = useContext(UserContext);
  const currentUser = userCtx?.currentUser;
  const isSysAdmin = ui_helpers.isSystemAdministrator(currentUser);
  const isCompanyAdmin = ui_helpers.isAnyCompanyAdministrator(currentUser);

  useEffect(() => {
    api.fetch('Contract/GetContractSearchFilters').then(r => {
      if (!r.data || !r.data.companySelect) return;
      let tempUsers = ui_helpers.idNameToValueLabel(r.data.userSelect)
      r.data.companySelect.unshift({value: 0, label: '[Any Company]'});
      r.data.manufacturerSelect.unshift({ value: 0 ,label: '[Any Manufacturer]'});
      r.data.dealerSelect.unshift({label: '[Any Store]', value: 0});
      tempUsers.unshift({label: '[Any User]', value: 0});
      setDealers(r.data.dealerSelect);
      setCompanies(r.data.companySelect);
      setManufacturers(r.data.manufacturerSelect);
      setUsers(tempUsers);
      setQuickFilters(r.data.quickFilterCounts); 
      setProcessedCounts(r.data.processedCounts);
      const userSavedSearch = r.data.savedSearch;
      if (userSavedSearch)
      {
        const savedSearch = JSON.parse(userSavedSearch.serializedSearch);
        const sortDetails = savedSearch.resolvedSort;
        setPageNumber(sortDetails.page);
        setSortColumn(sortDetails.sortColumn);
        setSortDirection(sortDetails.sortAscending ? "asc" : "desc");
        setLastSearch(userSavedSearch.serializedSearch);
        setPristineLastSearch(userSavedSearch.serializedSearch);
        setLoadedPayload(
          resolvePayload(sortDetails.page, savedSearch.filters));
        const defaultNewOrUsed = ui_helpers.resolveNewOrUsedSelectionFromFilters(savedSearch.filters);
        setNewOrUsedSelection(defaultNewOrUsed);
        setFilters(savedSearch.filters);
        setPaginatedList(r.data.initialData);
        if (savedSearch.quickFilterKey) {
          setSelectedFilterOption(savedSearch.quickFilterKey);
          setIsQuickFilter(true);
          setPageNumber(1);
        } else {
          setSelectedFilterOption(FilterOptions.SAVED_FILTER);
        }
      } else {
        setFilters([{filterName: 'OpenOnly', value: true}]);
        setSelectedFilterOption(FilterOptions.CONTRACTS);
      }
    })
    .finally(() => setFiltersLoaded(true));
  }, []);

  useEffect(() => {
    if (!filtersLoaded || _.isEmpty(filters)) return;
    refreshData();
  }, [filters])

  useEffect(() => {
    if (!filtersLoaded) return;
    refreshData(lastFilteredUsingQuickSearchKey
      ? lastFilteredUsingQuickSearchKey
      : null);
  }, [sortColumn, sortDirection, pageNumber])

  useEffect(() => {
    if (!filtersLoaded || !lastSearch || _.isEqual(pristineLastSearch, lastSearch)) return;
    filter_helpers.saveSearch(
      constants.SEARCH_AREAS.RTO, 
      lastSearch
    );
    setPristineLastSearch(lastSearch);
  }, [pristineLastSearch, lastSearch])

  const handleNewOrUsedSelection = (selection) => {
    let filterChanges = [];
    if (selection.label === 'New Or Used')
    {
      filterChanges = _.reject(
        filter_helpers.getFilters(filters, selection), x => x.filterName === 'NewOnly' || x.filterName === 'UsedOnly');
      setCanClear(filterChanges.length !== 0);
      setFilters(filterChanges);
    }
    else if (selection.label === 'Only New')
    {
      selection.filterName = 'NewOnly'
      selection.value = true;
      filterChanges = _.reject(
        filter_helpers.getFilters(filters, selection), x => x.filterName === 'UsedOnly');
      setCanClear(filterChanges.length !== 0);
      setFilters(filterChanges);
    }
    else if (selection.label === 'Only Used')
    {
      selection.filterName = 'UsedOnly'
      selection.value = true;
      filterChanges = _.reject(
        filter_helpers.getFilters(filters, selection), x => x.filterName === 'NewOnly');
      setCanClear(filterChanges.length !== 0);
      setFilters(filterChanges);
    }
    const newSelection = ui_helpers.resolveNewOrUsedSelectionFromFilters(filterChanges);
    setNewOrUsedSelection(newSelection);
  };

  function resolveSort() {
    return {
      sortColumn,
      sortAscending: sortDirection === 'asc'
    };
  }

  function resolvePayload(pPageNumber = null, pFilters = null) {
    let payload = {
      ...resolveSort(),
      page: pPageNumber || pageNumber
    };
    const filterList = pFilters ? pFilters : filters;
    _.each(filterList, filter => payload[filter.filterName] = filter.value);
    return payload;
  }

  function refreshData(quickSearchKey = null, forceRefresh = false) {
    const payload = resolvePayload();
    if (_.isEqual(payload, loadedPayload) && !quickSearchKey && !forceRefresh) return;
    setLoadingPayload(payload);
    if (quickSearchKey) {
      payload.quickSearchKey = quickSearchKey;
      if (quickSearchKey !== lastFilteredUsingQuickSearchKey) {
        setPageNumber(1);
        setLastFilteredUsingQuickSearchKey(quickSearchKey);
        payload.page = 1;        
      }
    } else {
      setLastFilteredUsingQuickSearchKey('');
    }
    api.post('Contract/FindContracts', payload).then((r) => {
      if (r) {
        setPaginatedList(r.data);            
      }
    }).catch(error => {
      setMessage({flavor: 'danger', message: 'Error when trying to refresh data: ', error});
      console.error(error);
    }).finally(() => {
      if (filtersLoaded) {
        setLastSearch(
          filter_helpers.storeSaveSearch(
            filters, resolveSort(), pageNumber, 
              (isQuickFilter ? selectedFilterOption : null)));
      }
      setLoadedPayload(payload);
      setLoadingPayload(null);
    });
  }

  function doSort(field, direction) {
    setSortColumn(field);
    setSortDirection(direction);
  }

  function renderRTODropdown() {
    const years = _.chain(processedCounts)
      .keys()
      .reject(x => x === 'PROCESSED_YTD')
      .map(x => x.substring(11, 15))
      .sortedUniq()
      // .reverse()
      .value();
    // PROCESSED_Y2019_CONTRACTS
    return _.map(years, y => {
      const searchKey = `PROCESSED_Y${y}`;
      return (<DropdownItem key={searchKey} onClick={() => refreshData(searchKey)}>{y}: {processedCounts[searchKey]}</DropdownItem>);
    });
  }

  function onStatusButtonClicked(changedFilter)
  {
    setIsQuickFilter(true);
    setPageNumber(1);
    setLastFilteredUsingQuickSearchKey('');
    setFilters(changedFilter.value === null
      ? []
      : [changedFilter]);
  }

  function onFilterChange(changedFilter) {
    let filterChanges = filter_helpers.getFilters(filters, changedFilter);
    if (isQuickFilter) {
      // clear prior filters if we were in a quick filter mode as we're starting fresh
      setIsQuickFilter(false);
      filterChanges = filter_helpers.getFilters([], changedFilter);
    }
    setPageNumber(1);
    setCanClear(filterChanges.length > 0);
    setFilters(filterChanges);
  }

  function onSelectAllToggle() {
    const newValue = !selectAllContracts;
    setSelectAllContracts(newValue);
    if (newValue) {
      setSelectedContractIds(
        _.map(paginatedList.items, x => x.contractId));
    } else {
      setSelectedContractIds([]);
    }
  }

  function onSetCourtesyCallComplete(contractId) {
    api.post("contract/SetFollowupStatus", {
      id: contractId,
      followUpType: constants.FOLLOW_UP.CALL,
      complete: true
    }).then((r) => {
      if (r.data.success) {
        refreshData(null, true);
      } else {
        setMessage({message: r.data.message, flavor: 'danger'});
      }
    }).catch((e) => console.error(e));
  }


  function onSelectContractId(e, cId) {
    e.stopPropagation();
    let newList = selectedContractIds.slice();
    if (newList.includes(cId)) {
      newList = _.reject(newList, x => x === cId);
    } else {
      newList.push(cId);
    }
    setSelectedContractIds(newList);
  }

  function onDeleteSelected() {
    api.post('contract/DeleteContractList', { idList: selectedContractIds }).then(r => {
      if (r.data.success) {
        refreshData(null, true);
      } else {
        setMessage({message: r.data.message, flavor: 'danger'});
      }
    })
    .catch(error => Alert.error('Error when trying to delete contracts: ' + error));
  }

  function onProcessSelected() {
    api.post('contract/SetContractListProcessed', { idList: selectedContractIds }).then(r => {
      if (r.data.success) {
        if (r.data.message) {
          setProcessMessageList(r.data.message);
        }
        refreshData(null, true);
      } else {
        setMessage({message: r.data.message, flavor: 'danger'});
      }
    });
  }

  function findCSV() {
    if (loadingPayload) return;
    let payload = resolvePayload();
    if (lastFilteredUsingQuickSearchKey) {
      payload.quickSearchKey = lastFilteredUsingQuickSearchKey;
    }
    setLoadingPayload(payload);
    const pdfWindow = window.open();
    api.downloadAndOpenFile('Contract/FindCSV', payload, pdfWindow, (e) => {
      pdfWindow.close();
      setMessage({flavor: 'danger', message: 'Error when trying to export CSV data: ' + e});
    }, constants.MIME_CSV, () => setLoadingPayload(null));
  }

  function onApplyQuickFilter(changedFilter, filterOption, badgeClass) {
    setIsQuickFilter(true);
    setPageNumber(1);
    setFilters(
      filter_helpers.getFilters([], changedFilter));
    setSelectedFilterOption(filterOption);
    setTotalBadgeClass(badgeClass);
  }

  if (redirectTo) {
    return (<Redirect to={redirectTo} />);
  }

  return (
  <div>
    <Header toolbar pos>
      {ui_helpers.hasContractCreationPermission(currentUser) && (
      <Dropdown
        isOpen={newDropdownOpen}
        toggle={() => setNewDropdownOpen(!newDropdownOpen)}
        className="d-inline"
      >
        <DropdownToggle caret color="success" className="text-light">
          <FontAwesomeIcon icon='plus' /> Add
        </DropdownToggle>
        <DropdownMenu>
          <DropdownItem tag={Link} to={PATHNAMES.NEW_RTO}>
            RTO Contract
          </DropdownItem>
          <DropdownItem tag={Link} to={PATHNAMES.NEW_CASH_SALE}>
            Cash Sale
          </DropdownItem>
          <DropdownItem tag={Link} to={PATHNAMES.NEW_QUOTE}>
            Quote
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
      )}
      {loadingPayload
        ? null
        : (<div className="float-end me-0">
            <Button className={classnames({
                'btn-tb-1': true,
                'btn-tb-active': selectedFilterOption === FilterOptions.CONTRACTS})}
              onClick={() => onApplyQuickFilter({filterName: 'OpenOnly', value: true}, FilterOptions.CONTRACTS, null)}>
                All Open{ui_helpers.conditionalCount(quickFilters['all-open'])}
            </Button>
            <Button className={classnames({
                'btn-tb-2': true,
                'btn-tb-active': selectedFilterOption === FilterOptions.DRAFTS})}
              onClick={() => onApplyQuickFilter({filterName: 'statusList', value: [constants.CONTRACT_STATUS_ID.Draft]}, FilterOptions.DRAFTS, null)}>
                Drafts{ui_helpers.conditionalCount(quickFilters.drafts)}
            </Button>
            {currentUser?.canEvaluateWebSales
              ? (<Button className={classnames({
                    'btn-tb-2': true,
                    'btn-tb-active': selectedFilterOption === FilterOptions.PREVERIFICATION})}
                  onClick={() => onApplyQuickFilter({filterName: 'statusList', value: [constants.CONTRACT_STATUS_ID.PreVerification]}, FilterOptions.PREVERIFICATION, null)}>
                    Pre-Verification{ui_helpers.conditionalCount(quickFilters.preverification)}
                </Button>)
              : null
            }
            <Button className={classnames({
                'btn-tb-3': true,
                'btn-tb-active': selectedFilterOption === FilterOptions.INREVIEW})}
              onClick={() => onApplyQuickFilter({filterName: 'statusList', value: [constants.CONTRACT_STATUS_ID.InReview]}, FilterOptions.INREVIEW, null)}>
                In-Review{ui_helpers.conditionalCount(quickFilters['in-review'])}
            </Button>
            <Button className={classnames({
                'btn-tb-3': true,
                'btn-tb-active': selectedFilterOption === FilterOptions.ONHOLD})}
              onClick={() => onApplyQuickFilter({filterName: 'statusList', value: [constants.CONTRACT_STATUS_ID.OnHold]}, FilterOptions.ONHOLD, null)}>
                On Hold{ui_helpers.conditionalCount(quickFilters['on-hold'])}
            </Button>
            <Button className={classnames({
                'btn-tb-4': true,
                'btn-tb-active': selectedFilterOption === FilterOptions.INPROGRESS})}
              onClick={() => onApplyQuickFilter({filterName: 'statusList', value: [constants.CONTRACT_STATUS_ID.InProgress]}, FilterOptions.INPROGRESS, null)}>
                In Progress{ui_helpers.conditionalCount(quickFilters['pending-delivery'])}
            </Button>
            <Button className={classnames({
                'btn-tb-5': true,
                'btn-tb-active': selectedFilterOption === FilterOptions.DELIVERED})}
              onClick={() => onApplyQuickFilter({filterName: 'statusList', value: [constants.CONTRACT_STATUS_ID.Delivered]}, FilterOptions.DELIVERED, null)}>
                Completed{ui_helpers.conditionalCount(quickFilters.delivered)}
            </Button>
{/*            {isQuickFilter || (!pristineLastSearch && !lastSearch) ? null :
              <Button className={classnames({"btn btn-tb-9": true, 'btn-tb-active': selectedFilterOption === FilterOptions.SAVED_FILTER})}
                onClick={() => {
                  const searchJson = lastSearch || pristineLastSearch;
                  let searchCriteria = filter_helpers.getParsedSavedSearchJSON(searchJson);
                  searchCriteria.quickFilterKey = null;
                  const userFilters  = searchCriteria.filters;
                  const sortDetails = searchCriteria.resolvedSort;
                  setIsQuickFilter(false);
                  setPageNumber(sortDetails.page);
                  setFilters(userFilters);
                  setSortColumn(sortDetails.sortColumn);
                  setSortDirection(sortDetails.sortAscending ? "asc" : "desc")
                  setSelectedFilterOption(FilterOptions.SAVED_FILTER);
                  setTotalBadgeClass('bg-dark');
                  setLastSearch(
                    filter_helpers.storeSaveSearch(userFilters, sortDetails, sortDetails.page, null));
                }}>
                Saved Filter 
              </Button>
            }*/}
            <ButtonGroup>
              <Button color="dark" onClick={() => refreshData('PROCESSED_YTD')} style={{marginRight: 0}}>
                YTD: {processedCounts.PROCESSED_YTD}
              </Button>   
              <Dropdown
                isOpen={yearDropdownOpen}
                toggle={() => setYearDropdownOpen(!yearDropdownOpen)}
                className="d-inline btn-group dropstart"
              >
                <DropdownToggle caret color="dark"  style={{marginLeft: 0}}/>
                {processedCounts.PROCESSED_YTD !== undefined
                  ? (<DropdownMenu className="dropdown-menu-lg-end yearDropdownMenu">
                      {renderRTODropdown()}
                    </DropdownMenu>)
                  : null
                }
              </Dropdown>
            </ButtonGroup>  
         </div>)
      }
      {processMessageList && processMessageList.length
        ? (<Modal isOpen centered>
            <ModalHeader>
              Confirm Payment
            </ModalHeader>
            <ModalBody>
              {_.map(processMessageList, x => (
                <Row>
                  <Col>
                    {x}
                  </Col>
                </Row>))}
            </ModalBody>
            <ModalFooter>
              <Row>
                <ButtonGroup>
                  <Button className={'bg-success border-success'} onClick={() => setProcessMessageList(null)}>
                    OK
                  </Button>
                </ButtonGroup>
              </Row>
            </ModalFooter>
          </Modal>)
        : null
      }
    </Header>
    <FilterSearch
      heading="Contracts"
      filters={filters}
      canClear={canClear}
      onClear={() => {
        setPaginatedList(null);
        setFilters([]);
        setLoadedPayload(null);
      }}
      isLoading={loadingPayload ? true : false}
    >
        <Row>
            <Col lg='2' sm='3'>
                <SelectFilter
                    filterName='CompanyId'
                    displayName='Company'
                    value={filter_helpers.get_value('CompanyId', filters)}
                    options={companies ? companies :null}
                    isSingleSelect={true}
                    isDisable={!companies}
                    onChangeCallback={onFilterChange}
                    placeholder={'[Any Company]'}/>

            </Col>
            <Col lg='2' sm='3'>
                <SelectFilter
                    filterName='DealerId'
                    displayName='Store'
                    value={filter_helpers.get_value('DealerId', filters)}
                    options={dealers}
                    isSingleSelect={true}
                    isDisabled={!dealers}
                    onChangeCallback={onFilterChange}
                    placeholder={'[Any Store]'}/>
            </Col>
            <Col lg='2' sm='3'>
                <SelectFilter
                    filterName='ManufacturerId'
                    displayName='Manufacturer'
                    value={filter_helpers.get_value('ManufacturerId', filters)}
                    options={manufacturers}
                    isSingleSelect={true}
                    isDisabled={!manufacturers}
                    onChangeCallback={onFilterChange}
                    placeholder={'[Any Manufacturer]'}/>
            </Col>
            <Col lg='2' sm='3'>
              <SelectFilter
                filterName='statusList'
                displayName='Status(es)'
                value={filter_helpers.get_value('statusList', filters)}
                options={contractStatusTypes}
                onChangeCallback={onFilterChange}
                placeholder={'[Any Status]'}
              />
            </Col>
            <Col lg='2' sm='3'>
                <SelectFilter
                    filterName='RelatedToUserId'
                    displayName='Related to'
                    value={filter_helpers.get_value('RelatedToUserId', filters)}
                    options={users}
                    isSingleSelect={true}
                    isDisabled={!users}
                    onChangeCallback={onFilterChange}
                    placeholder={'[Any User]'}/>

            </Col>
            <Col lg='2' sm='3'>
              <SelectFilter
                filterName='newOrUsed'
                displayName='New Or Used'
                value={newOrUsedSelection}
                //clear={clear}
                options={constants.NEW_OR_USED_OPTIONS}
                isSingleSelect
                onChangeCallback={handleNewOrUsedSelection}
                // placeholder={'New or Used'}
              />
            </Col>
        </Row>
        <Row>
            <Col lg='2' sm='3'>
              <TextFilter
                filterName='AgreementNumber'
                displayName='Agreement Number'
                onChangeCallback={onFilterChange}
                debounceTimeout={2000}
                value={filter_helpers.get_value('AgreementNumber', filters)}
              />
            </Col>
            <Col lg='2' sm='3'>
              <TextFilter
                filterName='CustomerName'
                displayName='Customer Name'
                onChangeCallback={onFilterChange}
                debounceTimeout={2000}
                value={filter_helpers.get_value('CustomerName', filters)}
              />
            </Col>
            <Col lg='2' sm='3'>
              <TextFilter
                filterName='SerialNumber'
                displayName='Serial Number'
                onChangeCallback={onFilterChange}
                debounceTimeout={2000}
                value={filter_helpers.get_value('SerialNumber', filters)}
              />
            </Col>
            <Col lg='2' sm='3'>
              <FilterSwitch
                filterName="OpenOnly"
                displayName="Open Only"
                onChangeCallback={onFilterChange}
                value={filter_helpers.get_value("OpenOnly", filters)}
              />
            </Col>
            <Col lg='2' sm='3'>
              <FilterSwitch
                filterName="PendingCall"
                displayName="Processed &amp; Pending Call"
                onChangeCallback={onFilterChange}
                value={filter_helpers.get_value("PendingCall", filters)}
              />
            </Col>
            <Col lg='2' sm='3'>
              <FilterSwitch
                filterName="UnpaidCommission"
                displayName="Unpaid Commission"
                onChangeCallback={onFilterChange}
                value={filter_helpers.get_value("UnpaidCommission", filters)}
              />
            </Col>
        </Row>
        <Row>
            <Col lg='2' sm='3'>
              <DateFilter
                filterName='CreatedSince'
                displayName='After'
                value={filter_helpers.get_value('CreatedSince', filters)}
                onChangeCallback={onFilterChange}
              />
            </Col>
            <Col lg='2' sm='3'>
              <DateFilter
                filterName='CreatedBefore'
                displayName='Before'
                value={filter_helpers.get_value('CreatedBefore', filters)}
                onChangeCallback={onFilterChange}
              />
            </Col>
            <Col lg='2' sm='3'>
              <FilterSwitch
                filterName="IsRelatedToInventory"
                displayName="Related To Inventory"
                onChangeCallback={onFilterChange}
                value={filter_helpers.get_value("IsRelatedToInventory", filters)}
              />
            </Col>
        </Row>
    </FilterSearch>
    <Pager pageNumber={pageNumber} pageSize={paginatedList ? paginatedList.pageSize : 0} totalResults={paginatedList ? paginatedList.totalCount : 0} onSetPageCallback={setPageNumber}/>
  <Card>
    <CardHeader>
      <Row>
        <Col xs="8">
          <ResultHeader
            heading='Contracts'
            totalResults={paginatedList ? paginatedList.totalCount : 0}
            badgeClass={totalBadgeClass}
          />
        </Col>
        <Col xs="4" className='d-flex justify-content-end align-items-start'>
          <ButtonGroup>
            <Button onClick={findCSV} color={'light'} className='border-dark'><FontAwesomeIcon size="2x" icon={'file-csv'}/></Button>
            {selectedContractIds.length && (isSysAdmin || isCompanyAdmin)
              ? (<ButtonDropdown color="dark" className="me-2 rounded border-dark" isOpen={selectedMenuOpen} toggle={() => setSelectedMenuOpen(!selectedMenuOpen)}>
                  <DropdownToggle caret color="dark">
                    Selected
                  </DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem onClick={onProcessSelected}>Process</DropdownItem>
                    <DropdownItem onClick={onDeleteSelected}>Delete</DropdownItem>
{/*                    <DropdownItem onClick={() => setShowPayModal(true)}>Approve &amp; Pay</DropdownItem>
                    <DropdownItem onClick={() => { setShowSaleModal(true); setIsMultiSale(true); }}>Sell</DropdownItem>
*/}                  </DropdownMenu>
                </ButtonDropdown>)
              : null
            }
          </ButtonGroup>
        </Col>
      </Row>
    </CardHeader>
        <Table bordered striped hover>
            <thead>
            <tr className="sortable-header-row status-center">
              <th>
                <Label check>
                  <Input type="checkbox" checked={selectAllContracts} onChange={onSelectAllToggle} />
                </Label>
              </th>
              <th>
                <SortHeader
                  spanOnly
                  displayName="Dealer"
                  field='DealerName'
                  sortDir={sortDirection}
                  sorted={sortColumn === 'DealerName'}
                  sortCallback={doSort}
                  isAlphabetic
                /><br/>
                <SortHeader
                  spanOnly
                  displayName="Driver/Date Assigned"
                  field='DriverLastAssignedAt'
                  sortDir={sortDirection}
                  sorted={sortColumn === 'DriverLastAssignedAt'}
                  sortCallback={doSort}
                  isAlphabetic
                />
              </th>
                <SortHeader
                    displayName='Agreement #'
                    field='AgreementNo'
                    sortDir={sortDirection}
                    sorted={sortColumn === 'AgreementNo'}
                    sortCallback={doSort}
                    isAlphabetic/>
                <SortHeader
                    displayName='Customer Info'
                    field='CustomerName'
                    sortDir={sortDirection}
                    sorted={sortColumn === 'CustomerName'}
                    sortCallback={doSort}
                    isAlphabetic/>
                <SortHeader
                    displayName='Total'
                    field='Total'
                    sortDir={sortDirection}
                    sorted={sortColumn === 'Total'}
                    sortCallback={doSort}/>
                <SortHeader
                    displayName='Manufacturer'
                    field='Manufacturer'
                    sortDir={sortDirection}
                    sorted={sortColumn === 'Manufacturer'}
                    sortCallback={doSort}
                    isAlphabetic/>
                <SortHeader
                    displayName='Created'
                    field='CreatedAt'
                    sortDir={sortDirection}
                    sorted={sortColumn === 'CreatedAt'}
                    sortCallback={doSort}
                    isAlphabetic/>
                <SortHeader
                    displayName='Serial #'
                    field='SerialNo'
                    sortDir={sortDirection}
                    sorted={sortColumn === 'SerialNo'}
                    sortCallback={doSort}
                    isAlphabetic/>
                <SortHeader
                    displayName='Owned By'
                    field='Company'
                    sortDir={sortDirection}
                    sorted={sortColumn === 'Company'}
                    sortCallback={doSort}
                    isAlphabetic/>
                <SortHeader
                    displayName='Essentials'
                    field='Essentials'
                    sortDir={sortDirection}
                    sorted={sortColumn === 'Essentials'}
                    sortCallback={doSort}
                    isAlphabetic/>
                <SortHeader
                    displayName='Status'
                    field='status'
                    sortDir={sortDirection}
                    sorted={sortColumn === 'status'}
                    sortCallback={doSort}
                    isAlphabetic/>
            </tr>
            </thead>
            <tbody className="status-center">
            {!loadingPayload && filtersLoaded && _.isEmpty(filters)
              ? (<tr><td colSpan='12' className="dim-text" style={{fontStyle: "italic", textAlign: "left"}}>Provide at least one filter.</td></tr>)
              : (loadingPayload || !filtersLoaded
                ? (<tr><td colSpan='12'><Loading /></td></tr>)
                : paginatedList && paginatedList.items && paginatedList.items.length
                  ? _.map(paginatedList.items, c => 
                      (<ContractRow
                        key={c.contractId} 
                        c={c} 
                        selectedContractIds={selectedContractIds}
                        onSelectContractId={onSelectContractId}
                        onSetRedirect={setRedirectTo} 
                        onSetCourtesyCallComplete={onSetCourtesyCallComplete}
                        currentUser={currentUser}
                      />))
                  : (<tr><td colSpan='12' className='text-center'>No contracts found.</td></tr>)
                )
            }
            </tbody>
        </Table>
    <CardFooter>
        <Pager pageNumber={pageNumber} pageSize={paginatedList ? paginatedList.pageSize : 0} totalResults={paginatedList ? paginatedList.totalCount : 0} onSetPageCallback={setPageNumber}/>
    </CardFooter>
  </Card>
      <Footer />
    </div>
  );
};

export default RTOContracts;
