import React from "react";
import { Button, Modal, ModalBody, ModalHeader, ModalFooter } from "reactstrap";
import { useAppStore } from "../hooks";
import useWindowSize from "../hooks/useWindowSize";

export default function LandscapeModeModal() {
    const isOpen = useAppStore((x) => x.landscapeModeModalOpen);
    const setIsOpen = useAppStore((x) => x.setLandscapeModeModalOpen);

    const { width, height } = useWindowSize();
    const MOBILE_SIZE = (width <= 768);
    const IS_PORTRAIT = window.matchMedia("(orientation: portrait)").matches;

    const handleClose = () => {
      if (MOBILE_SIZE || !IS_PORTRAIT) {
          setIsOpen(false);
    }
  };
  
  return (
    <>
      <Modal isOpen={isOpen} centered>
        <ModalBody className="text-center">Please turn your device for a better viewing experience.</ModalBody>
        {!IS_PORTRAIT && (
          <ModalFooter>
            <Button color="dark" onClick={handleClose}>
              Close
            </Button>
          </ModalFooter>
        )}
      </Modal>
    </>
  );
}
