import React, {useContext, useEffect, useState} from "react";
import { Link } from "react-router-dom";
import Select from "react-select";
import { Container, 
    TabContent, 
    Table, 
    TabPane, 
    Nav, 
    NavItem, 
    NavLink, 
    Card, 
    Button, 
    Modal,
    ModalBody,
    ModalHeader,
    ModalFooter,
    CardTitle, 
    CardText, 
    Row, 
    Col, 
    Form, 
    FormGroup, 
    Label,
    ButtonGroup, 
    Accordion,
    Collapse,
    CardBody,
    Input } from 'reactstrap';
import { Header } from ".";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classnames from 'classnames';
import _ from "lodash";
import {constants, UserContext} from "../utils";



export default function Inspections() {
    return(
        <Container>
            <Header />
            <Row className="my-3">
                <Col>
                    <h3 className="d-flex justify-content-center my-3">Inspections</h3>
                </Col>
            </Row>
            <Row>
                <Table>
                    <thead>
                        <tr>
                            <th>Shed Serial Number</th>
                            <th>Date</th>                            
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>#901384</td>
                            <td>01/01/2020</td>                           
                        </tr>
                        <tr>
                            <td>#987654</td>
                            <td>02/02/2020</td>                           
                        </tr>
                        <tr>
                            <td>#757575</td>
                            <td>03/30/2021</td>                            
                        </tr>
                        <tr>
                            <td>#543654</td>
                            <td>04/06/2021</td>                           
                        </tr>
                    </tbody>
                </Table>
            </Row>
        </Container>
    );
}