import React from "react";
import { EditingContextContext } from "../contexts";

function EditingContextProvider({ editingContext, children }) {
  return (
    <EditingContextContext.Provider value={editingContext}>
      {children}
    </EditingContextContext.Provider>
  );
}

export default EditingContextProvider;
