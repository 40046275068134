import React, {useState, useEffect} from "react";
import {
  Row,
  Col,
  FormGroup,
  Form,
  Input,
  Label,
  Button,
  Table, Modal, ModalHeader, ModalBody, ModalFooter, ButtonGroup,
  InputGroup,
  InputGroupText
} from "reactstrap";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classnames from 'classnames';
import { api, ui_helpers } from "../helpers";
import { CalculatorTable, SalespersonEdit, PriceAdjustmentEditor } from "./";
import _ from "lodash";
import {DebounceInput} from "react-debounce-input";
import DatePicker from 'react-datepicker';
import { storage, constants } from "../utils";
import Select from 'react-select';
import Alert from "react-s-alert-v3";
import ToggleButton from "react-toggle-button";

const defaultPricingData = (existing, salespeople) => {
  if (!existing)
    existing = {};
  const lastSalespersonLabel = storage.getItem(constants.STORAGE_KEYS.LAST_SALESPERSON_LABEL);
  let defaultSalesperson = null;
  if (salespeople && salespeople.length 
    && lastSalespersonLabel 
    && _.some(salespeople, x => x.label === lastSalespersonLabel)) {
    defaultSalesperson = _.find(salespeople, x => x.label === lastSalespersonLabel);
  }
  return {
    ...existing,
    unitPrice: (existing.unitPrice || 0.00),
    taxRateString: (existing.taxRateString || "0.000"),
    taxRate: (existing.taxRate || 0.000),
    isTaxExempt: (existing.isTaxExempt || false),
    taxAmount: (existing.taxAmount || 0.0),
    freightAmount: (existing.freightAmount || 0.0),
    subtotal: (existing.subtotal || 0.0),
    ldwAmount: (existing.ldwAmount || 8.0),
    dueForDeliveryAmount: existing.dueForDeliveryAmount || 0.00,
    optedOutOfDamageLiabilityWaiver: (existing.optedOutOfDamageLiabilityWaiver || false),
    paymentDueDayOfMonth: (existing.paymentDueDayOfMonth || "5"),
    initialPaymentAmount: (existing.initialPaymentAmount || 0.00),
    includedInitialPayment: existing.includedInitialPayment,
    selectedTermInfo: (existing.selectedTermInfo || null),
    promotionCode: (existing.promotionCode || ""),
    promotionCodeTemp: (existing.promotionCodeTemp || ""),
    estimatedDeliveryDate: (existing.estimatedDeliveryDate || ""),
    salesperson: (existing.salesperson || defaultSalesperson),
    otherSalesperson: (existing.otherSalesperson || "")
  };
};

const ContractPricing = (props) => {
  const [pricingData, setPricingData] = useState(defaultPricingData(props.contractData, props.salespeople));
  const [screenWarnings, setScreenWarnings] = useState({});
  const [countyLookUpModal, setCountyLookUpModal] = useState(false);
  const [countyTaxList, setCountyTaxList] = useState(null);
  const [taxExempt, setTaxExempt] = useState(props.contractData.isTaxExempt);
  const [taxExemptionForm, setTaxExemptionForm] = useState(null);

  useEffect(() => {
    setPricingData(
      defaultPricingData(props.contractData, props.salespeople));
  }, [props.contractData])

  useEffect(() => {
    let newScreenWarnings = {};
    _.each(props.fieldWarnings, w => Object.assign(newScreenWarnings, {[w.field]: w.text}))
    setScreenWarnings(newScreenWarnings);
  }, [props.fieldWarnings])

  useEffect(()=>{
    if (pricingData.selectedTermInfo && pricingData.promotionCode.length) {
      props.checkTermPromoAvailability(pricingData.promotionCode)
    }
  }, [pricingData.promotionCode, pricingData.selectedTermInfo]);

  function onBlur(value, fieldName) {
    switch(fieldName) {
      case 'promotionCodeTemp':
        const cleanPromoCode = _.trim(value).toUpperCase();
        setPricingData({
          ...pricingData,
          promotionCode: cleanPromoCode,
          promotionCodeTemp: cleanPromoCode
        });
        break;
      case 'otherAmount':
      case 'initialPaymentAmount':
      case 'unitPrice':
      case 'taxRateString':
      // case 'freightAmount':
        let newData = {
          ...pricingData,
          [fieldName]: ui_helpers.cleanseCurrencyCharacters(value)
        };
        // if(fieldName === 'otherAmount'){
        //   newData.unitPriceWithDiscount = parseFloat(value) && parseFloat(value) > 0
        //     ? parseFloat(newData.unitPrice) - parseFloat(value)
        //     : 0;
        // }
        if (fieldName === 'taxRateString') {
          newData.taxRate = parseFloat(value) ? parseFloat(value) : 0;
          if (newData.taxRate > 1.0) {
            newData.taxRate /= 100.0;
          }
          newData.taxRate = newData.isTaxExempt
          ? 0.0
          : parseFloat(newData.taxRate.toFixed(5));
        }
        const numericUnitPrice = parseFloat(newData.unitPrice);
        const unitPriceAdjustmentsSum = _.sumBy(_.filter(props.priceAdjustments, pa => pa.includeInUnitPrice), pa => parseFloat(pa.amount));
        const allAdjustmentsSum = _.sumBy(props.priceAdjustments, pa => parseFloat(pa.amount));
        newData.taxAmount = newData.taxRate * (numericUnitPrice + unitPriceAdjustmentsSum);
        newData.subtotal = numericUnitPrice + newData.taxAmount + allAdjustmentsSum;
        setPricingData(newData);
        break;
    }
  }

  useEffect(onEvaluateDownPayment, [pricingData.selectedTermInfo, pricingData.promotionCode])

  function handleTaxExemptToggle(value) {
    setTaxExempt(!value);
    onChange(!value, 'isTaxExempt');
  }

  function onEvaluateDownPayment() {
    if ((!pricingData && !pricingData.selectedTermInfo)
        || (pricingData && !pricingData.selectedTermInfo)) return;
    const sideFees = ui_helpers.sideFeeSum(props.priceAdjustments);
    const minRequired = pricingData.selectedTermInfo.requiredForDeliveryAmount_NOPR + sideFees;
    if (pricingData.initialPaymentAmount > minRequired) {
      props.setDownPaymentOverPayment();
    }
  }

  const onSelectedTermChange = (term) => {
    const newData = Object.assign({}, pricingData);
    newData.selectedTermInfo = term;
    newData.includedInitialPayment = false;
    newData.initialPaymentAmount = term.requiredForDeliveryAmount_NOPR + ui_helpers.sideFeeSum(props.priceAdjustments);
    setPricingData(newData);
    props.onSetData(newData);
  }

  function onDownloadAttachment(contractId, key = null, onComplete = null) {
    const pdfWindow = window.open();
    // null document key means download all
    api.downloadAndOpenFile(
        "contract/DownloadContractDocument",
        { referenceId: contractId, documentKey: key },
        pdfWindow,
        (e) => {
          console.error(e);
          // Alert.error("There was an error downloading the attachment");
          if (pdfWindow) {
            pdfWindow.close();
          }
        },
        "application/pdf",
        onComplete
    );
  }

  function onUploadTaxExemptionForm(file) {
    const filePayload = {
      referenceId: pricingData.contractId,
      file: file,
      typeOfAttachment: constants.ATTACHMENT_TYPE_IDS.TaxExemptionForm
    };
    setTaxExemptionForm(filePayload);
    onChange(filePayload, "taxExemptionForm");
  }

  function onChange(value, fieldName) {
    const newData = {
      ...pricingData,
      [fieldName]: value
    };
    if(fieldName === 'taxRateString' && parseFloat(value)) {
      newData.taxRate = parseFloat(value);
    }
    if (fieldName === 'selectedTermInfo' && value && value.dlwAmount) {
      newData.ldwAmount = parseFloat(value.dlwAmount);
    }
    setPricingData(newData);
    props.onSetData(newData);
  }

  function findDeliveryCounty() {
    if (pricingData.deliveryCounty === "") {
      Alert.error("Delivery County Name is required");
      return;
    }
    api.post("Contract/FindTaxRate", {
      contractId: props.contractId,
      isInCityLimits: pricingData.isInCityLimits,
      deliveryCounty: pricingData.deliveryCounty
    }).then(res => {
      if (res.data && res.data.success) {
        const taxRateValueString = res.data.message.taxRateValue * 100.0;
        const newData = {
          ...pricingData,
          taxRate: res.data.message.taxRateValue,
          taxRateString: taxRateValueString ? taxRateValueString : ""
        };
        setPricingData(newData);
        props.onSetData(newData);
        //Alert.Info(`${(res.data.message.taxRateValue * 100)}% was found for the delivery county`)
        //onBlur(`${res.data.message.taxRateValue * 100}`, "taxRateString")
        //setCountyTaxList(res.data.message);
        //setCountyLookUpModal(true);
      }
    }).catch(error => {
      Alert.error("An error occurred during county lookup")
      console.error(error)
    });
  }

  const ldwOptions = [
    {label: `${ui_helpers.formatCurrency(pricingData.ldwAmount)} / month`, value: false},
    {label: "OPT OUT of LDW", value: true},
  ];


  return (
  <Row>
    <Col xs="4">
      <Form className="mt-2">
        <Row>
          <Col>
            <div className="mb-1">
              <Row>
                <Col md={"12"} lg={"5"}>
                  <SalespersonEdit
                    salespeople={props.salespeople}
                    salesperson={pricingData.salesperson}
                    otherSalesperson={pricingData.otherSalesperson}
                    screenWarnings={screenWarnings}
                    onChange={onChange}
                    onDeleteSalesperson={props.onDeleteSalesperson}
                  />
                </Col>
                <Col md={"6"} lg={"3"}>
                  <Row>
                    <Col>
                      <Label>
                        Unit Price {ui_helpers.requiredStar()}
                      </Label>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <DebounceInput
                        type="text"
                        maxLength="15"
                        minLength="2"
                        title={screenWarnings.unitPrice ? screenWarnings.unitPrice : null}
                        className={classnames({invalid: (screenWarnings.unitPrice ? true : false)}, "text-end form-control")}
                        value={props.unitPriceDisabled ? ui_helpers.formatCurrency(pricingData.unitPrice) : pricingData.unitPrice} 
                        disabled={true} // props.unitPriceDisabled
                        readOnly="readOnly"
                        onChange={(e) => onChange(e.target.value, 'unitPrice')}
                        onBlur={(e) => onBlur(e.target.value, 'unitPrice')}
                        debounceTimeout={900}
                      />
                    </Col>
                  </Row>
                </Col>
                <Col md={"6"} lg={"4"}>
                  <Row>
                    <Col>
                      <Label>
                        Tax Rate {ui_helpers.requiredStar()}
                      </Label>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <InputGroup>
                      <DebounceInput
                        type="text"
                        minLength="1"
                        readOnly={taxExempt}
                        style={{width: "68px", float: "left"}} 
                        className="text-end form-control" 
                        maxLength="8" 
                        value={taxExempt ? 0 : pricingData.taxRateString || 0}
                        onChange={(e) => onChange(e.target.value, 'taxRateString')} 
                        onBlur={(e) => onBlur(e.target.value, 'taxRateString')} 
                        debounceTimeout={900}
                      />
                        <InputGroupText>%</InputGroupText>
                      </InputGroup>
                      {/*<div style={{float: "left", width: "25px", paddingTop: "4px", paddingLeft: "4px"}}>%</div>*/}
                    </Col>
                  </Row>
                </Col>
                {/*<Col xs="3">*/}
                {/*  <Row>*/}
                {/*    <Col>*/}
                {/*      <Label>*/}
                {/*        Tax Rate {ui_helpers.requiredStar()}*/}
                {/*      </Label>*/}
                {/*    </Col>*/}
                {/*  </Row>*/}
                {/*  <Row>*/}
                {/*    <Col>*/}
                {/*      <InputGroup>*/}
                {/*      <DebounceInput*/}
                {/*          type="text"*/}
                {/*          minLength="1"*/}
                {/*          style={{width: "68px", float: "left"}}*/}
                {/*          className="text-end form-control"*/}
                {/*          maxLength="8"*/}
                {/*          value={pricingData.taxRateString || 0}*/}
                {/*          onChange={(e) => onChange(e.target.value, 'taxRateString')}*/}
                {/*          onBlur={(e) => onBlur(e.target.value, 'taxRateString')}*/}
                {/*          debounceTimeout={900}*/}
                {/*      />*/}
                {/*        <InputGroupText>%</InputGroupText>*/}
                {/*      </InputGroup>*/}
                {/*      <div style={{float: "left", width: "25px", paddingTop: "4px", paddingLeft: "4px"}}>%</div>*/}
                {/*    </Col>*/}
                {/*  </Row>*/}
                {/*</Col>*/}
                <Col md={"6"} xl={"4"}  className="d-none">
                  <Row>
                    <Col>
                      <Label>
                        Tax Amount
                      </Label>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Input type="text" bsSize="sm" className="text-end" readOnly disabled value={ui_helpers.formatCurrency(pricingData.taxAmount)} />
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Row>
                    <Col className={'d-flex justify-content-end align-items-center'}>
                      <ToggleButton value={taxExempt} inactiveLabel={''} activeLabel={''} onToggle={(val) => handleTaxExemptToggle(val)}/>
                      <div className={'ms-2'}>
	               					Tax Exempt
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row>
                <Col>
                  {taxExempt  ?
                     ( <>
                       <Row className="d-flex">
                        <Label>Tax Exemption Form {ui_helpers.requiredStar()}</Label>
                      </Row>
                  <Row>
                    {pricingData?.taxExemptionForm
                        ? <>
                        {pricingData?.taxExemptionForm.file ? <span>{pricingData?.taxExemptionForm.file?.name ?? "Tax Exemption Form"}</span> : null}
                        {pricingData?.taxExemptionForm.name ?
                                    <Button
                                        color="link"
                                        className="download-link text-start"
                                        onClick={() =>
                                            onDownloadAttachment(pricingData?.taxExemptionForm.contractId, pricingData?.taxExemptionForm.key)
                                        }
                                    >
                                      {pricingData?.taxExemptionForm.name}
                                    </Button> : null}</>
              			: <input type="file" id="myFile"
                           name="taxExemptionForm"
                           accept="application/pdf"
                    onChange={(e) => onUploadTaxExemptionForm(e.target.files[0])}/>}
                  </Row>
                     </>) : null}
                </Col>
              </Row>
            </div>
            <PriceAdjustmentEditor
              unitPrice={pricingData.unitPrice}
              priceAdjustments={props.priceAdjustments}
              adjustmentTypeOptions={props.adjustmentTypeOptions}
              pendingPriceAdjustment={props.pendingPriceAdjustment}
              addPriceAdjustment={props.addPriceAdjustment}
              deletePriceAdjustment={props.deletePriceAdjustment}
              setPendingPriceAdjustment={props.setPendingPriceAdjustment}
            />
            <div className="mb-1">
              <Row>
                <Col xs="6" className="pt-1">
                  <Label>
                    Delivery County {ui_helpers.requiredStar()}
                  </Label>
                </Col>
                <Col xs="6">
                  <Input
                    type="text"
                    title={screenWarnings.deliveryCounty ? screenWarnings.deliveryCounty : null}
                    className={classnames({ invalid: (screenWarnings.deliveryCounty ? true : false) }, "form-control")}
                    bsSize="sm"
                    value={pricingData.deliveryCounty}
                    onChange={(e) => onChange(e.target.value, 'deliveryCounty')}
                  />
                </Col>
              </Row>
            </div>
            <div className="mb-1">
              {/*<Row>*/}
              {/*  <Col xs="6" className="pt-1"></Col>*/}
              {/*  <Col xs="6">*/}
              {/*      <Row>*/}
              {/*        <Col>*/}
              {/*          <Label>*/}
              {/*            <span className="d-inline-block">In City Limits?</span>*/}
              {/*            <Input*/}
              {/*              type="checkbox"*/}
              {/*              style={{marginLeft: "6px"}}*/}
              {/*              checked={pricingData.isInCityLimits}*/}
              {/*              onChange={() => onChange(!pricingData.isInCityLimits, 'isInCityLimits')} />*/}
              {/*          </Label>*/}
              {/*        </Col>*/}
              {/*        <Col xs="5">*/}
              {/*            <Button color="primary" className="light-text xs" style={{fontSize: "0.75rem"}}*/}
              {/*              onClick={findDeliveryCounty} title="Look up Delivery County Tax Rate">*/}
              {/*                Find Tax Rate*/}
              {/*            </Button>*/}
              {/*        </Col>*/}
              {/*      </Row>*/}
              {/*  </Col>*/}
              {/*</Row>*/}
            </div>
            <div className="mb-1">
              <Row>
                <Col xs="6" className="pt-1">
                  <Label>
                    Liability Damage Waiver {ui_helpers.requiredStar()}
                  </Label>
                </Col>
                <Col xs="6" style={{padding: "0 12px"}}>
                  <Select
                    options={ldwOptions}
                    value={_.find(ldwOptions, o => o.value === pricingData.optedOutOfDamageLiabilityWaiver)}
                    onChange={(x) => onChange(x.value, 'optedOutOfDamageLiabilityWaiver')}
                  />
                </Col>
              </Row>
            </div>
            <div className="mb-1">
              <Row>
                <Col xs="6" className="pt-1">
                  <Label>
                    Preferred Due Date {ui_helpers.requiredStar()}
                  </Label>
                </Col>
                <Col xs="6" style={{padding: "0 12px"}}>
                  <Select
                    options={props.dueDays}
                    value={_.find(props.dueDays, d => d.value === pricingData.paymentDueDayOfMonth)}
                    onChange={(x) => onChange(x.value, 'paymentDueDayOfMonth')}
                  />
                </Col>
              </Row>
            </div>
            <div className="mb-1">
              <Row>
                <Col xs="6" className="pt-1">
                  <Label>
                    Estimated Delivery Date
                  </Label>
                </Col>
                <Col xs="6">
                  <div className="datePickerDiv">
                    <DatePicker
                      title={screenWarnings.estimatedDeliveryDate ? screenWarnings.estimatedDeliveryDate : null}
                      className={classnames({invalid: (screenWarnings.estimatedDeliveryDate ? true : false)}, 'form-control')}
                      selected={pricingData.estimatedDeliveryDate ? pricingData.estimatedDeliveryDate.toDate() : null}
                      onChange={(date) => onChange(date ? moment(date) : null, 'estimatedDeliveryDate')}
                      showMonthDropdown
                      showYearDropdown
                      isClearable
                      withPortal
                      dropdownMode="select"
                    />
                  </div>
                </Col>
              </Row>
            </div>
            <div className="mb-1">
              <Row>
                <Col xs="6" className="pt-1">
                  <Label>
                    Promo Code
                  </Label>
                </Col>
                <Col xs="6">
                  <Input type="text" maxLength="20" value={pricingData.promotionCodeTemp}
                    title={screenWarnings.promotionCode ? screenWarnings.promotionCode : null}
                    className={classnames({invalid: (screenWarnings.promotionCode ? true : false)}, "text-end form-control")}
                    onChange={(e) => onChange(e.target.value, 'promotionCodeTemp')}
                    onBlur={(e) => onBlur(e.target.value, 'promotionCodeTemp')} />
                    {screenWarnings.promoCodeTermCheck ? (
                      <span className="mt-2 promo-check-small text-warning">{ screenWarnings.promoCodeTermCheck}</span>
                    ) : null}
                </Col>
              </Row>
            </div>
            <div className="mb-1">
              <Row>
                <Col xs="6" className="pt-1">
                  <Label>
                    Down Payment Paid Today {ui_helpers.requiredStar()}
                  </Label>
                </Col>
                <Col xs="6">
                  <DebounceInput
                    type="text"
                    maxLength="15"
                    title={screenWarnings.initialPaymentAmount ? screenWarnings.initialPaymentAmount : null}
                    className={classnames({invalid: (screenWarnings.initialPaymentAmount ? true : false)}, "text-end form-control")}
                    value={pricingData.initialPaymentAmount} 
                    minLength={2}
                    debounceTimeout={900}
                    onChange={(e) => onChange(e.target.value, 'initialPaymentAmount')}
                    onBlur={(e) => onBlur(e.target.value, 'initialPaymentAmount')}
                  />
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Form>
    </Col>
    <Col xs="8">
      {parseFloat(pricingData.unitPrice) > 10.0
        ? (<CalculatorTable
            showInputs={false}
            termsSelectable={true}
            regionId={pricingData.regionId}
            promotionCode={pricingData.promotionCode}
            unitPrice={parseFloat(pricingData.unitPrice) + _.sumBy(_.filter(props.priceAdjustments, pa => pa.includeInUnitPrice), pa => parseFloat(pa.amount))}
            taxRate={parseFloat(pricingData.taxRate)}
            taxRateString={pricingData.taxRateString}
            isTaxExempt={pricingData.isTaxExempt}
            atLeast14Wide={parseFloat(props.width) >= 14.0}
            atLeast16Wide={parseFloat(props.width) >= 16.0}
            includeLDW={pricingData.optedOutOfDamageLiabilityWaiver === false}
            customDownPayment={pricingData.initialPaymentAmount}
            sideFees={ui_helpers.sideFeeSum(props.priceAdjustments)}
            productType={_.find(constants.PRODUCT_TYPES, pt => pt.value === props.productTypeId)}
            onUpdateSelectedTerm={onSelectedTermChange}
            selectedTermId={pricingData.selectedTermInfo ? pricingData.selectedTermInfo.contractTermId : null}
            onSelectedTermUpdate={(t) => onChange(t, 'selectedTermInfo')}
            companyId={pricingData.companyId}
            checkTermPromoAvailability={() => props.checkTermPromoAvailability(pricingData.promotionCode)}
            showRTOCompanyName
          />)
        : (<div className="gigantic">Provide details to the left to see term options.</div>)
      }
          </Col>
  </Row>);
};

export default ContractPricing;