import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  ButtonGroup,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  UncontrolledTooltip,
} from "reactstrap";
import { withRouter } from "react-router-dom";
import { DebounceInput } from "react-debounce-input";
import { api, ui_helpers, constants } from "../helpers";
import Alert from "react-s-alert-v3";
import classnames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SignatureCanvas from "react-signature-canvas";
import _ from "lodash";
import { Document, Page, pdfjs } from "react-pdf";
pdfjs.GlobalWorkerOptions.workerSrc = "pdf.worker.js";

const OPT_IN_OUT = 4;

const VIEWER_HEIGHT_SCALE = 0.5;
//tracking zoom change requests
const zoomIncrement = 0.1;
const zoomTimeOutInterval = 200;
var zoomCounter = 1; //window.innerHeight / windowRatio;;
let initialZoom = 0;

const UCToolTip = (props) => {
  return (
    <UncontrolledTooltip
      placement={props.placement}
      target={props.targetID}
      delay={{ show: 200, hide: 25 }}
    >
      {" "}
      {props.infoText}
    </UncontrolledTooltip>
  );
};

class Signature extends Component {
  constructor(props) {
    super(props);
    let dealerId = null;
    try {
      dealerId = localStorage.getItem("DEALER_ID");
      if (dealerId) {
        dealerId = parseInt(dealerId, 10);
      }
    } catch {}
    const code = this.props.match.params.code;
    // let code = "";
    // if (props?.match?.params?.code) {
    //   code = props?.match?.params?.code;
    // } else if (props?.computedMatch?.params?.code) {
    //   code = props?.computedMatch?.params?.code;
    // }
    this.state = {
      request: null,
      code: code,
      requestList: null,
      isMobile: ui_helpers.isMobile(window),
      dealerId: dealerId,
      hasStroked: false,
      optingIn: null,
      totalPages: 0,
      currentPageNo: 1,
      viewerHeight: window.innerHeight / VIEWER_HEIGHT_SCALE,
      scale: 1,
      pdfDimenions: [],
      lockZoom: false,
    };
    this.canvasLeft = 0;
    this.viewportWidth = 0;
    this.viewportHeight = 0;
    this.pdfViewPort = [];
    this.refreshList = this.refreshList.bind(this);
    this.recordSignature = this.recordSignature.bind(this);
    this.promptSignature = this.promptSignature.bind(this);
    this.onPDFLoad = this.onPDFLoad.bind(this);
    this.submit = this.submit.bind(this);
    this.cancel = this.cancel.bind(this);
    this.clearState = this.clearState.bind(this);

    this.UpdateViewerScale = this.UpdateViewerScale.bind(this);
    this.RescaleViewer = this.RescaleViewer.bind(this);
    this.ZoomIn = this.ZoomIn.bind(this);
    this.ZoomOut = this.ZoomOut.bind(this);
    this.ChangeZoom = this.ChangeZoom.bind(this);
    this.ResetZoom = this.ResetZoom.bind(this);

    this.canvasContainer = React.createRef();
  }

  componentDidMount() {
    const page = document.getElementById("root");
    page.style.backgroundColor = "#444";
    page.style.color = "#FFF";
    page.style.marginBottom = 0;
    this.accessCodeField.focus();
    if (this.state.code && this.state.code.length === 6) {
      this.refreshList();
    }

    window.addEventListener("resize", this.UpdateViewerScale);
    // this.canvasContainer = document.getElementById("canvasContainer");
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.UpdateViewerScale);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.scale !== this.state.scale) {
      zoomCounter = this.state.scale;
      if (!initialZoom) {
        initialZoom = this.state.scale;
      }
    }
  }

  UpdateViewerSize() {
    if (this.state.lockZoom) return;
    this.windowInnerHeight = window.innerHeight;
    setTimeout(this.ResizeViewer, 100, window.innerHeight);
  }

  UpdateViewerScale() {
    if (this.state.lockZoom) return;
    this.windowInnerWidth = window.innerWidth;
    setTimeout(this.RescaleViewer, 100, window.innerWidth);
  }

  RescaleViewer(width = 0) {
    if (this.state.lockZoom) return;
    if (width === window.innerWidth) {
      this.setState((prevState) => ({
        scale: +(width / prevState.pdfDimenions[2] / 3.5).toFixed(2),
      }));
    }
  }

  ChangeZoom(zoomLevel) {
    if (this.state.lockZoom) return;
    if (zoomCounter === zoomLevel) {
      let rndZoomLevel = +zoomLevel.toFixed(2);
      this.setState({ scale: rndZoomLevel });
    }
  }

  ZoomIn() {
    if (this.state.lockZoom) return;
    let currentZoom = zoomCounter + zoomIncrement;
    zoomCounter = currentZoom;
    setTimeout(this.ChangeZoom, zoomTimeOutInterval, currentZoom);
  }

  ZoomOut() {
    if (this.state.lockZoom) return;
    let currentZoom = zoomCounter - zoomIncrement;
    zoomCounter = currentZoom;
    setTimeout(this.ChangeZoom, zoomTimeOutInterval, currentZoom);
  }

  ResetZoom() {
    if (this.state.lockZoom) return;
    let currentZoom = initialZoom;
    zoomCounter = initialZoom;
    setTimeout(this.ChangeZoom, zoomTimeOutInterval, currentZoom);
  }

  onPDFLoad({ numPages }) {
    this.setState({ totalPages: numPages });
  }

  refreshList() {
    if (!this.state.dealerId && !this.state.code) {
      Alert.warning("Please enter a code to continue");
      return;
    }
    if (
      this.state.code &&
      this.state.code.length === 6 &&
      isNaN(parseInt(this.state.code, 10))
    ) {
      Alert.warning("Enter a numeric 6 digit code to continue");
      return;
    }
    api
      .post("Signature/FindRequests", {
        dealerId: this.state.dealerId,
        code: this.state.code,
      })
      .then((r) => {
        if (r.data.success) {
          switch (r.data.data.length) {
            case 0:
              this.setState({ requestList: null, request: null });
              Alert.warning(
                this.state.code && this.state.code.length === 6
                  ? `No active signature requests were found for access code ${this.state.code}.`
                  : "No pending signatures were found."
              );
              break;
            case 1:
              this.setState({ requestList: null, request: r.data.data[0] });
              break;
            default:
              this.setState({ requestList: r.data.data, request: null });
              break;
          }
        } else {
          Alert.error(
            "An unexpected error occurred while refreshing the list of signature requests."
          );
          console.error(r.data.message);
        }
      })
      .catch((e) => {
        Alert.error(
          "An error occurred while refreshing the list of signature requests."
        );
        console.error(e);
      });
  }

  promptSignature(requirementId, title, isOptInOut) {
    this.setState({
      optingIn: null,
      hasStroked: false,
      captureSignatureRequirementId: requirementId,
      captureSignatureRequirementPrompt: title,
      captureSignatureRequirementOptInOut: isOptInOut,
    });
  }

  recordSignature() {
    let payload = new FormData();
    payload.append(
      "signatureRequirementId",
      this.state.captureSignatureRequirementId
    );
    payload.append(
      "isOptIn",
      this.state.captureSignatureRequirementOptInOut
        ? this.state.optingIn
        : null
    );
    payload.append(
      "signature",
      ui_helpers.dataURItoBlob(this.sigCanvas.toDataURL())
    );
    api
      .post("Signature/RecordSignature", payload)
      .then((r) => {
        if (r.data.success) {
          let request = Object.assign({}, this.state.request);
          _.each(request.requirements, (q) => {
            if (q.id === this.state.captureSignatureRequirementId) {
              q.signedAt = new Date();
            }
          });
          this.setState({
            request: request,
            captureSignatureRequirementId: null,
          });
        } else {
          Alert.error("An error occurred while saving your signature.");
        }
      })
      .catch((e) => {
        console.error(e);
        Alert.error(
          "An unexpected error occurred while saving your signature."
        );
      });
  }

  clearState() {
    let newState = {
      request: null,
      code: "",
    };
    // if (this.state.request.requirements && this.state.request.requirements.length) {
    //   _.each(this.state.request.requirements, r => {
    //     if (this.state[`SIG_${r.id}`]) {
    //       newState[`SIG_${r.id}`] = null;
    //     }
    //   })
    // }
    this.setState(newState, () => this.accessCodeField.focus());
  }

  submit() {
    if (_.some(this.state.request.requirements, (r) => !r.signedAt)) {
      Alert.warning(
        "Please complete all required signatures or choose to cancel."
      );
      return;
    }
    api
      .post(`Signature/CompleteRequest/${this.state.request.id}`, {})
      .then((r) => {
        if (r.data.success) {
          this.clearState();
          Alert.success("Thank you, your response has been recorded!");
        } else {
          Alert.error("An error occurred while saving your response.");
        }
      })
      .catch((e) => {
        console.error(e);
        Alert.error("An unexpected error occurred while saving your response.");
      });
  }

  cancel() {
    api
      .post(`Signature/CancelRequest/${this.state.request.id}`, {})
      .then((r) => {
        if (r.data.success) {
          this.clearState();
          Alert.success("Thank you, your response has been recorded!");
        } else {
          Alert.error("An error occurred while saving your response.");
        }
      })
      .catch((e) => {
        console.error(e);
        Alert.error("An unexpected error occurred while saving your response.");
      });
  }

  render() {
    const canSign =
      (this.state.code && this.state.code.length === 6) || this.state.dealerId;
    if (this.state.request) {
      const currentUrl = window.location;
      const previewURL =
        currentUrl.protocol +
        "//" +
        currentUrl.host +
        "/api/Signature/Preview/" +
        this.state.request.id;
      return (
        <Container fluid className="pt-1">
          <Row>
            <Col
              xs="8"
              md="10"
              id="canvasContainer"
              style={{
                textAlign: "center",
                height: "100%",
                overflow: "auto",
              }}
            >
              <Document
                file={previewURL}
                onLoadSuccess={this.onPDFLoad}
                loading={<FontAwesomeIcon icon="spinner" size="6x" spin />}
              >
                {_.range(1, this.state.totalPages + 1).map((pageNumber) => (
                  <div
                    key={`pg-${pageNumber}`}
                    style={pageNumber === 1 ? null : { marginTop: "4px" }}
                  >
                    <Page
                      pageNumber={pageNumber}
                      inputRef={(r) => (this.canvasContainer = r)}
                      // width={1200}
                      scale={this.state.scale}
                      onLoadSuccess={(p) => {
                        if (!p) {
                          return;
                        }
                        this.setState(
                          { pdfDimenions: p._pageInfo.view },
                          this.UpdateViewerScale
                        );
                      }}
                      height={this.state.viewerHeight}
                      loading={
                        <FontAwesomeIcon icon="spinner" size="6x" spin />
                      }
                    />
                  </div>
                ))}
              </Document>
            </Col>
            <Col
              xs="4"
              md="2"
              className="pl-0 pr-4 "
              style={{ position: "fixed", top: 0, right: 0, zIndex: 1030 }}
            >
              {this.state.request.requirements.map((q) => (
                <Button
                  key={q.id}
                  color={q.signedAt ? "light" : "secondary"}
                  className={classnames("btn-block mt-2")}
                  onClick={() =>
                    this.promptSignature(
                      q.id,
                      q.description,
                      q.typeOfSignature === OPT_IN_OUT
                    )
                  }
                >
                  {q.signedAt ? (
                    <span className="text-success">
                      <FontAwesomeIcon icon="check" />
                    </span>
                  ) : null}{" "}
                  {q.description}
                </Button>
              ))}
              <ButtonGroup
                vertical
                className="mt-4"
                style={{ marginBottom: "33em" }}
              >
                <Button
                  onClick={this.ZoomIn}
                  className="mb-2"
                  title="Zoom In"
                  id="zoomIn"
                  size="md"
                >
                  <FontAwesomeIcon icon="search-plus" />
                </Button>
                <Button
                  onClick={() =>
                    this.setState((p) => ({ lockZoom: !p.lockZoom }))
                  }
                  className="mb-2"
                  title="Lock Zoom"
                  id="lockZoom"
                  size="md"
                >
                  <UCToolTip
                    infoText={"Lock Zoom"}
                    targetID={"lockZoom"}
                    placement={"top"}
                  />
                  {this.state.lockZoom ? (
                    <FontAwesomeIcon icon="lock" />
                  ) : (
                    <FontAwesomeIcon icon="lock-open" />
                  )}
                </Button>
                <Button
                  onClick={this.ZoomOut}
                  className="mb-2"
                  title="Zoom Out"
                  id="zoomOut"
                  size="md"
                >
                  <UCToolTip
                    infoText={"Zoom Out"}
                    targetID={"zoomOut"}
                    placement={"top"}
                  />
                  <FontAwesomeIcon icon="search-minus" />
                </Button>
                <Button
                  onClick={this.ResetZoom}
                  className="mb-2"
                  title="Reset Zoom"
                  id="resetZoom"
                  size="md"
                >
                  <UCToolTip
                    infoText={"Reset Zoom"}
                    targetID={"resetZoom"}
                    placement={"top"}
                  />
                  <FontAwesomeIcon icon="undo" />
                </Button>
              </ButtonGroup>
              {/* <Row className="mb-2" style={{position: "fixed", bottom: 0, zIndex: 1030, justifyContent: "space-between"}}> */}
              {/* <ButtonGroup style={{position: "fixed", bottom: 0, zIndex: 1030, marginBottom: 4, justifyContent: "space-between"}} vertical={window.innerWidth < 1920}> */}
              <ButtonGroup
                style={{ marginBottom: 4 }}
                vertical={window.innerWidth < 1920}
              >
                <Button
                  color="success"
                  className={window.innerWidth < 1920 ? "mb-2 mr-2" : "mr-2"}
                  onClick={this.submit}
                >
                  <FontAwesomeIcon icon="check" /> Accept
                </Button>
                <Button
                  color="secondary"
                  className={window.innerWidth < 1920 ? "mb-2 mr-2" : ""}
                  onClick={this.cancel}
                >
                  <FontAwesomeIcon icon="times" /> Cancel
                </Button>
              </ButtonGroup>
              {/* </Row> */}
            </Col>
          </Row>
          {/* <Row className="fixed-bottom">
            <Col xs="10"/>
            <Col xs="2" className="pl-0 pr-4">
              <Row className="mb-2">
                <Col>
                  <Button color="success" onClick={this.submit}>
                    <FontAwesomeIcon icon="check" /> Accept
                  </Button>
                </Col>
                <Col>
                  <Button color='secondary' className="float-right" onClick={this.cancel}>
                    <FontAwesomeIcon icon="times" /> Cancel
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row> */}
          {this.state.captureSignatureRequirementId ? (
            <Modal isOpen={true}>
              <ModalHeader>
                {this.state.captureSignatureRequirementPrompt}
              </ModalHeader>
              <ModalBody>
                {this.state.captureSignatureRequirementOptInOut ? (
                  <Row className="mb-2">
                    <Col xs="6">
                      <Button
                        color={this.state.optingIn ? "success" : "secondary"}
                        onClick={() => this.setState({ optingIn: true })}
                      >
                        <FontAwesomeIcon icon="check" /> Yes, Opt In
                      </Button>
                    </Col>
                    <Col xs="6">
                      <Button
                        color={
                          this.state.optingIn === false ? "danger" : "secondary"
                        }
                        className="float-right"
                        onClick={() => this.setState({ optingIn: false })}
                      >
                        <FontAwesomeIcon icon="times" /> No, Opt Out
                      </Button>
                    </Col>
                  </Row>
                ) : null}
                <Row>
                  <Col
                    xs="12"
                    style={{ borderColor: "grey", border: "1px solid" }}
                  >
                    <SignatureCanvas
                      ref={(ref) => {
                        this.sigCanvas = ref;
                      }}
                      penColor={this.state.optingIn === false ? "red" : "green"}
                      onEnd={() => {
                        if (!this.state.hasStroked) {
                          this.setState({ hasStroked: true });
                        }
                      }}
                      canvasProps={{
                        style: {
                          width: "100%",
                          height: this.state.isMobile
                            ? constants.MOBILE_SIGNATURE_HEIGHT
                            : "100%",
                        },
                        className: "sigCanvas",
                      }}
                    />
                  </Col>
                </Row>
              </ModalBody>
              <ModalFooter>
                <ButtonGroup>
                  <Button
                    color="primary"
                    disabled={
                      !this.state.hasStroked ||
                      (this.state.captureSignatureRequirementOptInOut &&
                        this.state.optingIn === null)
                    }
                    onClick={() => this.recordSignature()}
                  >
                    Save
                  </Button>
                  <Button
                    color="secondary"
                    onClick={() =>
                      this.setState({ captureSignatureRequirementId: null })
                    }
                  >
                    Cancel
                  </Button>
                </ButtonGroup>
              </ModalFooter>
            </Modal>
          ) : null}
        </Container>
      );
    } else if (this.state.requestList) {
      return (
        <Container fluid className="pt-3">
          <Row>
            <Col>
              {this.state.requestList.map((r) => (
                <Button
                  className="btn-primary btn-block"
                  style={{ fontSize: "2rem" }}
                  onClick={() => this.setState({ request: r })}
                >
                  <FontAwesomeIcon icon="file-signature" /> {r.customerFullName}
                </Button>
              ))}
            </Col>
          </Row>
        </Container>
      );
    } else {
      return (
        <Container fluid className="pt-3">
          <Row className="mb-0" style={{ marginTop: "4rem" }}>
            <Col>Enter Access Code</Col>
          </Row>
          <Row>
            <Col>
              <DebounceInput
                inputRef={(input) => {
                  this.accessCodeField = input;
                }}
                style={{
                  fontSize: "3rem",
                  width: "190px",
                  padding: "3px",
                  textAlign: "center",
                }}
                minLength={5}
                value={this.state.code}
                debounceTimeout={100}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    this.refreshList();
                  }
                }}
                onChange={(event) =>
                  this.setState({ code: event.target.value })
                }
              />
              <Button
                color={canSign ? "primary" : "secondary"}
                className="btn-lx"
                style={{ fontSize: "3rem", marginLeft: "8px" }}
                disabled={!canSign}
                onClick={this.refreshList}
              >
                <FontAwesomeIcon icon="signature" /> Sign
              </Button>
            </Col>
          </Row>
        </Container>
      );
    }
  }
}

export default withRouter(Signature);

{
  /* <Container fluid className="pt-1">
          <Row>
            <Col xs="10" id="canvasContainer"
              style={{
                textAlign: "center",
                height: "100%",
                overflow: "auto"
              }}>
              <Document 
                file={previewURL} 
                onLoadSuccess={this.onPDFLoad}
                loading={(<FontAwesomeIcon icon="spinner" size="6x" spin />)}
              >
              {_.range(1, this.state.totalPages + 1).map(pageNumber => 
                (<div key={`pg-${pageNumber}`} style={pageNumber === 1 ? null : {marginTop: "4px"}}>
                  <Page
                    pageNumber={pageNumber}
                    // width={1200}
                    // scale={VIEWER_HEIGHT_SCALE}
                    height={this.state.viewerHeight}
                    loading={(<FontAwesomeIcon icon="spinner" size="6x" spin />)}
                  />
                </div>)
              )}
              </Document>
            </Col>
          </Row>
          <Row className="fixed-top">
            <Col xs="10"/>
            <Col xs="2" className="pl-0 pr-4">
              {this.state.request.requirements.map(q => (
                <Button key={q.id} 
                  color={q.signedAt ? "light" : "secondary"}
                  className={classnames("btn-block mt-2")} 
                  onClick={() => this.promptSignature(q.id, q.description, q.typeOfSignature === OPT_IN_OUT)}>
                  {q.signedAt ? (<span className="text-success"><FontAwesomeIcon icon="check" /></span>) : null} {q.description}
                </Button>))}
            </Col>
          </Row>
          <Row className="fixed-bottom">
            <Col xs="10"/>
            <Col xs="2" className="pl-0 pr-4">
              <Row className="mb-2">
                <Col>
                  <Button color="success" onClick={this.submit}>
                    <FontAwesomeIcon icon="check" /> Accept
                  </Button>
                </Col>
                <Col>
                  <Button color='secondary' className="float-right" onClick={this.cancel}>
                    <FontAwesomeIcon icon="times" /> Cancel
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
          {this.state.captureSignatureRequirementId
            ? (<Modal isOpen={true}>
              <ModalHeader>{this.state.captureSignatureRequirementPrompt}</ModalHeader>
              <ModalBody>
              {this.state.captureSignatureRequirementOptInOut
                ? (<Row className="mb-2">
                    <Col xs="6">
                      <Button color={this.state.optingIn ? "success" : "secondary"} onClick={() => this.setState({optingIn: true})}>
                        <FontAwesomeIcon icon="check" /> Yes, Opt In
                      </Button>
                    </Col>
                    <Col xs="6">
                      <Button
                        color={this.state.optingIn === false ? "danger" : "secondary"} 
                        className="float-right"
                        onClick={() => this.setState({optingIn: false})}>
                        <FontAwesomeIcon icon="times" /> No, Opt Out
                      </Button>
                    </Col>
                  </Row>)
                : null
              }
                <Row>
                  <Col
                    xs="12"
                    style={{ borderColor: "grey", border: "1px solid" }}
                  >
                    <SignatureCanvas
                      ref={(ref) => {
                        this.sigCanvas = ref;
                      }}
                      penColor={this.state.optingIn === false ? "red" : "green"}
                      onEnd={() => {
                        if (!this.state.hasStroked) {
                          this.setState({hasStroked: true});
                        }
                      }}
                      canvasProps={{
                        style: {
                          width: "100%",
                          height: this.state.isMobile ? constants.MOBILE_SIGNATURE_HEIGHT : "100%",
                        },
                        className: "sigCanvas"
                      }}
                    />
                  </Col>
                </Row>
              </ModalBody>
              <ModalFooter>
                <ButtonGroup>
                <Button
                  color="primary"
                  disabled={!this.state.hasStroked || (this.state.captureSignatureRequirementOptInOut && this.state.optingIn === null)}
                  onClick={() => this.recordSignature()}
                >
                  Save
                </Button>
                <Button
                  color="secondary"
                  onClick={() =>
                    this.setState({captureSignatureRequirementId: null})
                  }
                >
                  Cancel
                </Button>
                </ButtonGroup>
              </ModalFooter>
            </Modal>)
            : null
          }
        </Container>
      );
    } else if (this.state.requestList) {
      return (
        <Container fluid className="pt-3">
          <Row>
            <Col>
              {this.state.requestList.map(r => (
                <Button className="btn-primary btn-block" style={{fontSize: '2rem'}} onClick={() => this.setState({request: r})}>
                  <FontAwesomeIcon icon="file-signature" /> {r.customerFullName}
                </Button>
              ))}
            </Col>
          </Row>
        </Container>
      );
    } else {
      return (
        <Container fluid className="pt-3">
          <Row className="mb-0" style={{marginTop: "4rem"}}>
            <Col>
              Enter Access Code
            </Col>
          </Row>
          <Row>
            <Col>
              <DebounceInput
                inputRef={(input) => { this.accessCodeField = input; }} 
                style={{fontSize: '3rem', width: '190px', padding: '3px', textAlign: 'center'}}
                minLength={5}
                value={this.state.code}
                debounceTimeout={100}
                onKeyDown={e => {
                  if (e.key === "Enter") {
                    this.refreshList();
                  }
                }}
                onChange={event => this.setState({code: event.target.value})}
              />
              <Button
                color={canSign ? "primary" : "secondary"}
                className="btn-lx"
                style={{fontSize: '3rem', marginLeft: "8px"}} 
                disabled={!canSign}
                onClick={this.refreshList}
              >
                <FontAwesomeIcon icon="signature" /> Sign
              </Button>
            </Col>
          </Row>
        </Container> */
}
