import useSignalR from "./useSignalR";
import { POS_HUB_URL } from "../constants";
import { useEffect } from "react";

export default function usePosHub(contractId) {
  const { connection, connectionError, isConnected, useHubMethod } = useSignalR(
    POS_HUB_URL
  );

  useEffect(() => {
    if (isConnected && contractId) {
      connection.invoke("JoinGroup", contractId.toString());
    }
  }, [isConnected, contractId]);

  useEffect(() => {
    if (isConnected && contractId) {
      connection.invoke("StartSignatureSession", contractId.toString());
    }
  }, [isConnected, contractId]);

  const completeSignature = (signatureLocationId, isDecline = false) => {
    connection.invoke(
      "CompleteSignature",
      contractId.toString(),
      signatureLocationId,
      isDecline
    );
  };

  const completeRecurringPaySignature = (isDecline = false) => {
    connection.invoke(
      "CompleteRecurringPaySignature",
      contractId.toString(),
      isDecline
    )
  }

  const finalizeContract = () => {
    connection.invoke("FinalizeContract", contractId.toString());
  };

  const sendPaymentInfo = (paymentData) => {
    connection.invoke("SendPaymentInfo", contractId.toString(), paymentData);
  };

  const confirmPaperlessBilling = (paperlessData) => {
    paperlessData.contractId = contractId;
    connection.invoke("ConfirmPaperlessBilling", contractId.toString(), paperlessData);
  };

  const enableRecurring = () => {
    connection.invoke("EnableRecurring", contractId.toString());
  };

  return { 
    completeSignature, 
    finalizeContract, 
    sendPaymentInfo, 
    confirmPaperlessBilling, 
    enableRecurring, 
    completeRecurringPaySignature 
  };
}
