import React from "react";

export default function ThankYou() {
  window.document.body.style.backgroundColor = '#444';
  return (
    <div className="text-center text-white">
      <h1 style={{fontSize: "4rem", fontWeight: "bold", marginTop: "8rem"}}>Thank You!</h1>
      <div>We'll be in contact to arrange delivery soon!</div>
    </div>
  );
}
