import React, {useContext, useState, useEffect} from "react";
import { Link } from "react-router-dom";
import { Row, Button, Col, Container, UncontrolledCarousel } from "reactstrap";
import { Header, Footer, CalculatorModal } from "./";
import CalcImg from '../assets/img/calculator.png';
import {constants, UserContext} from "../utils";
import {api} from "../helpers";
import classnames from "classnames";
import Alert from "react-s-alert-v3";
import _ from "lodash";
const PATHNAMES = constants.PATH_NAMES;

const Home = () => {
  const userCtx = useContext(UserContext);
  const [ showCalc, setShowCalc ] = useState(false);
  const [systemMessages, setSystemMessages] = useState([]);

  document.title = `Home - ${constants.appName}`;

  useEffect(() => {
    api.post('Home/GetSystemMessages', {}).then(r => {
      setSystemMessages(_.map(r.data, x => ({
        altText: x.message,
        // caption: x.message,
        key: x.id,
        src: x.imageURL ? x.publicImageURL : "/Content/img/transp50.png"
      })));
    }).catch((err) => Alert.error("Could not load system messages"));
  }, []);

  return (
    <div>
      <Header />
      <Container fluid className="p-2">
        <Row className={'me-0'}>
          <Col md="3" xs="12" className="text-center">
            <Button
              block
              color="success"
              size="lg"
              className="my-2 text-light"
              tag={Link}
              to={PATHNAMES.RTOS}
            >
              Point of Sale
            </Button>
            <Button
              block
              color="primary"
              size="lg"
              className="my-2"
              tag={Link}
              to={PATHNAMES.INVENTORY}
            >
              Inventory
            </Button>
            <Button
              block
              color="dark"
              size="lg"
              className="my-2"
              tag={Link}
              to={PATHNAMES.RESOURCES_DOCINDEX}
            >
              Resources
            </Button>
            <button id="showCalc" onClick={() => setShowCalc(!showCalc)}>
              <img src={CalcImg} alt="calculator" />
              <br />
              Click Here for Calculator
            </button>
            <CalculatorModal isOpen={showCalc} setIsOpen={setShowCalc} />
          </Col>
          <Col md="9" xs="12">
          {systemMessages.length
            ? (<UncontrolledCarousel items={systemMessages} interval={10000} />)
            : null
          }
          </Col>
        </Row>
        <Row className="mt-3 d-lg-none">
          <Col md={{size: 3, offset: 8}} xs={{size: 10, offset: 1}}>
            <Row>
              <Col className="text-center fw-bold" style={{
                  backgroundColor: "black",
                  borderRadius: "8px 8px 0 0",
                  color: "#FFF"
              }}>
                Download Driver Mobile App
              </Col>
            </Row>
            <Row className="p-2" style={{border: "solid 1px #000"}}>
              <Col xs="6">
                <a href="https://apps.apple.com/app/rto-360/id1659474921" target="_blank" rel="noreferrer">
                  <img src="/Content/img/appstore-download.png" alt="Download app for Apple Appstore" width="120" />
                </a>
              </Col>
              <Col xs="6">
                <a href="https://play.google.com/store/apps/details?id=com.s2.cfreemobile" style={{textDecoration: "none"}} target="_blank" rel="noreferrer">
                  <img src="/Content/img/android-download.png" alt="Download app for Android" width="30" /> Android
                </a>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      <Footer />
    </div>
  );
};

export default Home;
