import React, {useContext, useState, Fragment} from "react";
import { Link } from "react-router-dom";
import Select from "react-select";
import {
    Container, TabContent, TabPane, Nav, NavItem, NavLink, Card, Button, CardTitle, CardText, Row, Col, Form,
    FormGroup, Label, Input, Table, Modal, ModalHeader, ModalBody, ModalFooter, CardBody, CardHeader
} from 'reactstrap';
import {DebounceInput} from "react-debounce-input";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classnames from 'classnames';
import {constants, api} from "../utils";
import Alert from "react-s-alert-v3";
import {date_helpers, ui_helpers} from "../helpers";
import _ from 'lodash';

export default function BuildInfo({buildSteps, toggleTab, onRefreshBuildSteps}) {

  function setBuildStepNotes(buildStepId, notes) {
    api.post('inventory/SetBuildStepNotes', {
      id: buildStepId,
      comment: notes
    }).then(r => {
      if (!r.data.success) {
        Alert.error(r.data.message);
      }
    }).catch((e) => console.error(e));
  }

  function onRevertBuildStep(buildStepId) {
      api.fetch(`inventory/revertBuildStepCompletion/${buildStepId}`)
          .then((res) => {
              if(!res || !res.data.success) {
                  Alert.error(res.data?.message ?? "There was an error reverting this build step.");
                  return;
              }
              onRefreshBuildSteps();
          }).catch(api.catchHandler);
  }

    return (
        <Container className="mt-2" fluid>
            <Card>
                <CardHeader>
                    <Row>
                        <Col>
                            <h3>Build Steps</h3>
                        </Col>
                        <Col className="d-flex justify-content-end">
                            <Button
                                className="mb-2 bg-success border-success"
                                onClick={() => toggleTab(constants.BUILD_TABS.MANAGE_BUILD_STEPS)}
                            >
                                Add{' '}
                                <FontAwesomeIcon icon="plus" />
                            </Button>
                        </Col>
                    </Row>
                </CardHeader>
                    <Table striped bordered>
                        <thead>
                          <tr key={'b-headers'}>
                            <th style={{width: "25%"}}>Build Step</th>
                            <th style={{width: "150px"}} className={'text-center'}>Scheduled</th>
                            <th>Notes</th>
                            <th style={{width: "15%"}}>Completed / By</th>
                          </tr>
                        </thead>
                        <tbody>
                        {buildSteps
                            ? _.map(buildSteps, b => (
                                <tr key={`bs-${b.id}`}>
                                  <td style={{fontWeight: "bold"}}>({b.seq}) {b.name}</td>
                                  <td className={'text-center'}>{date_helpers.formatDateToShortDate(b.plannedFor)}</td>
                                  <td>
                                    <DebounceInput
                                      element='textarea'
                                      value={b.notes || ""}
                                      onChange={(e) => setBuildStepNotes(b.id, e.target.value)}
                                      min={0}
                                      className="w-100 form-control"
                                      style={{minHeight: "55px"}}
                                      maxLength="300"
                                      debounceTimeout={500}
                                      placeholder={b.name === 'Assign Builder' ? 'Notes entered here will appear on the Builder Worksheet' : ''}
                                    />
                                  </td>
                                  <td>
                                      <div className={'d-flex justify-content-evenly'}>

                                      <div>
                                          {date_helpers.formatDateToShortDate(b.completedAt)} {b.completedAt ? ui_helpers.greenCheck() : null}
                                          {b.completedAt
                                              ? (<div>by {b.completedBy || "Unknown"}</div>)
                                              : null
                                          }
                                      </div>
                                   <div>
                                       {b.completedAt
                                           ? <Button color="warning" className="text-light" onClick={() => onRevertBuildStep(b.id)}> Revert </Button> : null}
                                   </div>
                                      </div>
                                  </td>
                                </tr>
                                ))
                            : null}
                        </tbody>
                    </Table>
            </Card>
        </Container>
    );
}