import React, {useState, useEffect, useContext, Fragment, useLayoutEffect} from 'react';
import {
    Button, ButtonGroup,
    Col,
    Row,
    Modal,
    ModalHeader,
    ModalFooter,
    ModalBody,
    Input,
    Label
} from 'reactstrap';
import CurrencyInput from "react-currency-input-field";

export default function InventoryPriceModal({setShowPriceModal, editPrice, setEditPrice, editOriginalPrice}) {

return (<Modal isOpen fullscreen="sm" toggle={() => setShowPriceModal(false)}>
        <ModalHeader>
          Edit Original Price
        </ModalHeader>
        <ModalBody>
          <Label>New Original Price</Label>
          <CurrencyInput className='form-control' prefix='$' value={editPrice} onValueChange={(e) => setEditPrice(e)}/>
        </ModalBody>
        <ModalFooter>
          <Row className={'d-flex justify-content-end'}>
            <Col>
              <ButtonGroup className="float-end">
                <Button color='primary' onClick={editOriginalPrice}>Save Original Price</Button>
                <Button onClick={() => setShowPriceModal(false)}>Close</Button>
              </ButtonGroup>
            </Col>
          </Row>
        </ModalFooter>
      </Modal>);
}