import React from "react";
import styles from "../floorplanTool.module.css";

function Rulers({ displaySettings, exterior }) {
  return (
    <g>
      <line
        x1={0}
        y1={-displaySettings.rulerOffset}
        x2={exterior.width}
        y2={-displaySettings.rulerOffset}
        stroke="gray"
        strokeWidth={0.5}
      />
      {[...Array(Math.ceil(exterior.width / 12 + 1))].map((_, i) => (
        <g key={i}>
          <line
            key={i}
            x1={i * 12}
            y1={-displaySettings.rulerOffset}
            x2={i * 12}
            y2={-displaySettings.rulerOffset + 4}
            stroke="gray"
            strokeWidth={0.5}
          />
          <text
            x={i * 12}
            y={-displaySettings.rulerOffset + 4 + displaySettings.fontSize}
            fontSize={displaySettings.fontSize}
            className={styles.svgTxt}
          >
            {i}
          </text>
        </g>
      ))}
      a
      <line
        y1={0}
        x1={-displaySettings.rulerOffset}
        y2={exterior.height}
        x2={-displaySettings.rulerOffset}
        stroke="gray"
        strokeWidth={0.5}
      />
      {[...Array(Math.ceil(exterior.height / 12 + 1))].map((_, i) => (
        <g key={i}>
          <line
            key={i}
            x1={-displaySettings.rulerOffset}
            y1={i * 12}
            x2={-displaySettings.rulerOffset + 4}
            y2={i * 12}
            stroke="gray"
            strokeWidth={0.5}
          />
          <text
            x={-displaySettings.rulerOffset + 4}
            y={i * 12 + displaySettings.fontSize}
            fontSize={displaySettings.fontSize}
            className={styles.svgTxt}
          >
            {i}
          </text>
        </g>
      ))}
    </g>
  );
}

export default Rulers;
