import React, {useContext, useState, useEffect} from "react";
import {
    Container, Card, CardBody, Button, Row, Col,
    Form, FormGroup, Label, Input, CardHeader, CardFooter
} from 'reactstrap';
import {api} from '../utils';
import { date_helpers } from "../helpers";
import DatePicker from "react-datepicker";
import Alert from "react-s-alert-v3";

export default function GenericStep(props) {
    const [buildStep, setBuildStep] = useState({});

    const onBuildStepSave = () => {
      let payload = Object.assign({}, buildStep);
      payload.plannedFor = date_helpers.formatDateTimeForServer(buildStep.plannedFor);
      payload.completedAt = buildStep.completedAt ? date_helpers.formatDateTimeForServer(buildStep.completedAt) : null;
      payload.customerNotificationType = payload.customerNotificationType.value;
      api.post('Inventory/MarkBuildStepComplete', payload).then(r => {
        if (!r.data.success) {
          Alert.error(`There was an issue saving the status of ${buildStep.name}.`);
        }
        props.getBuildSteps();
      });
    };

    const onBuildStepChange = (fieldName, fieldValue) => {
      let editBuildStep = Object.assign({}, buildStep);
      editBuildStep[fieldName] = fieldValue;
      setBuildStep(editBuildStep);
    };

    useEffect(() => {
      if(!props.buildStep) return;
      setBuildStep(props.buildStep);
    }, [props.buildStep]);

    return (        
        <Container className="mt-5">
                <Card>
                    <CardHeader>
                        <Row className={'d-flex align-items-center'}>
                            <Col>
                                <h3 className="mt-2">{buildStep.name}</h3>
                            </Col>
                            <Col sm={3} className={'d-flex justify-content-end'}>
                                <small className={'text-muted'}>Generic Step</small>
                            </Col>
                        </Row>
                    </CardHeader>
                    <CardBody>
                        <Col>
                            <Form>
                                <FormGroup>
                                  <Row>
                                    <Col>
                                      <div>Scheduled For</div>
                                      <DatePicker showTimeSelect value={date_helpers.shortYearDateTimeFormat(buildStep.plannedFor)}
                                        onChange={(date) => onBuildStepChange('plannedFor', date)} id='plannedFor' timeIntervals={1}/>
                                    </Col>
                                  </Row>
                                </FormGroup>
                                <FormGroup>
                                  <Row>
                                    <Col>
                                      <div>Completed On</div>
                                      <DatePicker showTimeSelect value={date_helpers.shortYearDateTimeFormat(buildStep.completedAt)}
                                         onChange={(date) => onBuildStepChange('completedAt', date)} id='completedAt' timeIntervals={1}/>
                                    </Col>
                                  </Row>
                                </FormGroup>
                                <FormGroup>
                                  <Row>
                                    <Col>
                                      <div>Completed By</div>
                                      <Input type={'text'} value={buildStep.completedBy} onChange={(e) => onBuildStepChange('completedBy', e.target.value)}/>
                                    </Col>
                                  </Row>
                                </FormGroup>
                            </Form>
                        </Col>
                    </CardBody>
                    <CardFooter>
                        <Button className={'bg-success border-success float-end'} onClick={onBuildStepSave}>Save</Button>
                    </CardFooter>
                </Card>
        </Container>
    );
}