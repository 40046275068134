import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import _ from "lodash";
import { Card, Col, Row, Input, Spinner } from "reactstrap";
import { api } from "../utils";

export default function EditSubProductUnitTypes({mfgId, subProductId, subProductName, unitTypeOptions, loadMfgSubProductUnitTypeOptions}) {
  const [loadingIndex, setLoadingIndex] = useState(null);

  function handleChange(uto) {
    const filtered = _.reject(unitTypeOptions, x => x.unitTypeId === uto.unitTypeId);
    setLoadingIndex(
      _.findIndex(unitTypeOptions, x => x.unitTypeId === uto.unitTypeId));
    const url = uto.manufacturerSubProductUnitTypeId === null
      ? `Manufacturer/CreateMfgSubProductUnitType?mfgSubProductId=${subProductId}&mfgUnitTypeId=${uto.unitTypeId}`
      : `Manufacturer/DeleteMfgSubProductUnitType?mfgId=${mfgId}&mfgSubProductUnitTypeId=${uto.manufacturerSubProductUnitTypeId}`;
    api.post(url).then(res => {
      if (res.data.success) {
        loadMfgSubProductUnitTypeOptions();
      } else {
        console.error(res.data.message);
      }
    }).finally(() => setLoadingIndex(null));
  }

  return (
    <>
      <h3>Product Material Options:</h3>
      <div className="d-flex flex-wrap">
        {unitTypeOptions && _.map(unitTypeOptions, (uto, idx) => (
            <Card
              style={{ cursor: "pointer" }}
              key={`utp-${uto.unitTypeId}`}
              color={
                uto.manufacturerSubProductUnitTypeId !== null
                  ? "primary"
                  : "body"
              }
              className={`m-2 p-3 ${
                uto.manufacturerSubProductUnitTypeId !== null
                  ? "text-white"
                  : "text-dark"
              }`}
              onClick={(e) => {
                handleChange(uto);
                e.stopPropagation();
              }}
            >
              <Row>
                <Col sm={1}>
                  {idx === loadingIndex ? (
                    <Spinner size="sm" />
                  ) : (
                    <Input
                      type="checkbox"
                      style={{ cursor: "pointer" }}
                      checked={uto.manufacturerSubProductUnitTypeId !== null}
                    />
                  )}
                </Col>
                <Col className="d-flex justify-content-start">
                  <strong style={{ pointerEvents: "none" }}>
                    {uto.unitTypeName}
                  </strong>
                </Col>
              </Row>
            </Card>
          ))}
      </div>
    </>
  );
}