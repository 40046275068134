import _ from "lodash";
import { COLOR_TYPES } from "../../floorplanConstants";

const initialAppState = {
  editingPorch: false,
  porchColorId: null,
  isDraggingPorchHandle: false,
  isMovingAddOn: false,
  isRotatingAddOn: false,
  isResizingAddOn: false,
  hasExistingFloorPlan: true
};

export default function getInitialAppState(optionColors, porchColorId) {
  return {
    ...initialAppState,
    porchColors: _.filter(optionColors, x => x.associatedColorTypeIds.includes(COLOR_TYPES.PORCH)),
    doorColors: _.filter(optionColors, x => x.associatedColorTypeIds.includes(COLOR_TYPES.DOOR)),
    shutterColors: _.filter(optionColors, x => x.associatedColorTypeIds.includes(COLOR_TYPES.SHUTTERS)),
    flowerboxColors: _.filter(optionColors, x => x.associatedColorTypeIds.includes(COLOR_TYPES.FLOWER_BOX)),
    porchColorId: porchColorId
  }
};
