import React, { useEffect } from "react";
import { addOnTypes } from "../floorplanConstants";
import {
  useActions,
  useAddOns,
  useAppState,
  useControlTarget,
  useExterior,
  usePointerCoordinates,
} from "../hooks";
import { getRotation, limitNumberWithinRange, snap } from "../utils";

let startM = { x: 0, y: 0 };
let startP = { x: 0, y: 0 };

function useMovability(props) {
  const { domId, x = 0, y = 0, rotation = 0 } = props;
  const [coordinates] = usePointerCoordinates();
  const [controlTarget] = useControlTarget();
  const [exterior] = useExterior();
  const [, addOnsDispatch] = useAddOns();
  const [appState, appStateDispatch] = useAppState();
  const actions = useActions({ addOnsDispatch, appStateDispatch });

  useEffect(() => {
    if (controlTarget === "rotate-" + domId && appState.isRotatingAddOn) {
      actions.updateAddOn({
        ...props,
        rotation: snap(
          getRotation(props.x, props.y, coordinates.x, coordinates.y),
          2
        ),
      });
    }
  }, [controlTarget, appState.isRotatingAddOn, coordinates]);

  useEffect(() => {
    if (controlTarget === domId && appState.isMovingAddOn) {
      if (props.type === addOnTypes.loft) {
        actions.updateAddOn({
          ...props,
          x: snap(startP.x + (coordinates.x - startM.x), 6),
        });
      } else {
        actions.updateAddOn({
          ...props,
          x: snap(startP.x + (coordinates.x - startM.x), 6),
          y: snap(startP.y + (coordinates.y - startM.y), 6),
        });
      }
    }
  }, [coordinates, controlTarget]);

  function handleBoundingBoxMouseDown() {
    startM = { x: coordinates.x, y: coordinates.y };
    startP = { x: props.x, y: props.y };
    actions.setIsMovingAddOn(true);
  }

  function handleRotationHandleMouseDown() {
    actions.setIsRotatingAddOn(true);
  }

  const transform = `translate(${x} ${y}) rotate(${rotation})`;

  return [transform, handleBoundingBoxMouseDown, handleRotationHandleMouseDown];
}

export default useMovability;
