import React, { Fragment, Component, useState, useContext, useEffect } from 'react';
import { Table, Row, Col, Card, CardBody, CardHeader, Input, Label, ButtonGroup, Button, Badge } from "reactstrap";
import { api, ui_helpers, constants } from "../helpers";
import { UserContext } from "../utils";
import { Link, Redirect, useHistory } from "react-router-dom";
import Select from "react-select";
import ToggleButton from "react-toggle-button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import _ from "lodash";
import Alert from "react-s-alert-v3";
import {useConsole} from "../hooks";

const shapeReports = (reports) => {
  const reportsList = _.map(reports, report => {
        return {
            ...report,
            isActive: report.deactivatedAt === null && report.id > 0
            // deactivatedAt: report.id ? report.deactivatedAt : false // default to off if this placeholder record does not yet exist
        };
    });
  return _.chain(reportsList)
    .map(r => r.reportReportGroupName)
    .uniq()
    .map(groupName => {
      return {
          groupName: groupName,
          reports: _.filter(reportsList, report => report.reportReportGroupName === groupName)
      };
    })
    .value();
};

const roleGroupStyles = {
  alignItems: 'center',
  fontSize: 14,
  fontWeight: 'bold',
  textDecoration: 'underline',
  margin: '2px 0 0 0'
};

const formatRoleGroupLabel = data => (
  <div style={roleGroupStyles}>
    <span>{data.label}</span>
  </div>
);

const groupRoleOptions = (roleList) => {
  // groups with 1 option each
  let groupedRoles = _.chain(roleList.slice())
    .groupBy(r => r.groupName)
    .toPairs()
    .map(kv => {
      return {
        label: kv[0],
        options: _.map(kv[1], opt => {
          // rename the label to be the user-friendly name property
          opt.label = opt.name;
          return opt;
        })
      };
    }).value();
  let filteredGroupedRoles = [];
  // consolidate all options under a single group
  _.each(groupedRoles, g => {
    // if this group contains at least 1 role among those desired include it in the filtered output
    if (_.some(g.options, role => _.findIndex(roleList, r2 => r2.value === role.value) >= 0)) {
      // filter the contained roles to only those desired
      g.options = _.filter(g.options, role => _.findIndex(roleList, r2 => r2.value === role.value) >= 0);
      filteredGroupedRoles.push(g);
    }
  });
  return filteredGroupedRoles;
};

const resolveRoleContextDescription = (ur) => {
    if (ur.dealerId !== null) {
        return <span>{ur.dealerName} <Badge color='info'>Store</Badge></span>;
    }
    if (ur.manufacturerId !== null) {
        return <span>{ur.manufacturerName} <Badge color='dark'>Manufacturer</Badge></span>;
    }
    if (ur.companyId !== null) {
        return <span>{ur.companyName} <Badge color='primary'>Company</Badge></span>;
    }
    return (<span>-</span>);
};

const roleIdToName = (roleId) => {
    return _.find(constants.roles, ur => ur.value === roleId).label;
};

export default function UserAddEdit(props) {
  let context = "";
  if (props.manufacturer) {
      context = "manufacturer";
  } else if (props.store) {
      context = "store";
  } else if (props.drivers) {
      context = "drivers";
  }

  const {currentUser} = useContext(UserContext);
  const isSysAdmin = _.some(currentUser.roles, ur => ur.typeOfUserRole === constants.ROLE_IDS.SystemAdministrator);
  const defaultUser = {
    id: 0,
    firstName: "",
    lastName: "",
    email: "",
    username: "",
    phone: "",
    initialPassword: "Password1",
    autoSendReports: false,
    sendSMSUponContractApproval: false,
    notifyOnNewContract: false,
    primaryNotificationType: 0,
    secondaryNotificationType: 0,
    roles: [],
    canImpersonate: false,
    timezone: {value: 'Eastern Standard Time', label: 'Eastern Standard Time'}
  };
  const [contextUserId, setContextUserId] = useState(props?.match?.params?.edit_user_id);
  const [enableRoleSelection, setEnableRoleSelection] = useState(_.some(currentUser.roles, ur => _.includes([constants.ROLE_IDS.SystemAdministrator, constants.ROLE_IDS.Administrator], ur.typeOfUserRole)));
  const [currentUserCanAssignCompany, setCurrentUserCanAssignComany] = useState(isSysAdmin || ui_helpers.isAnyCompanyAdministrator(currentUser));
  const [currentUserCanAssignManufacturer, setCurrentUserCanAssignManufacturer] = useState( isSysAdmin || ui_helpers.isAnyCompanyAdministrator(currentUser) || ui_helpers.isAnyManufacturerAdministrator(currentUser));
  const [currentUserCanAssignDealer, setCurrentUserCanAssignDealer] = useState(isSysAdmin || ui_helpers.isAnyCompanyAdministrator(currentUser) || ui_helpers.isAnyDealerAdministrator(currentUser));
  const [canSelectCompany, setCanSelectCompany] = useState(true);
  const [canSelectManufacturer, setCanSelectManufacturer] = useState(true);
  const [canSelectDealer, setCanSelectDealer] = useState(true);
  const [returnRoute, setReturnRoute] = useState(null);
  const [redirectTo, setRedirectTo] = useState(null);
  const [reportGroups, setReportGroups] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [dealers, setDealers] = useState([]);
  const [manufacturers, setManufacturers] = useState([]);
  const [user, setUser] = useState(defaultUser);
  const [notificationTypes, setNotificationTypes] = useState(constants.notificationTypes);
  const [availableRoles, setAvailableRoles] = useState([]);
  const [timezoneList, setTimezoneList] = useState([]);
  const [newRole, setNewRole] = useState({
    typeSelection: null,
    company: null,
    dealer: null,
    manufacturer: null
  });

  useEffect(() => {
    const userAvailableRoles = ui_helpers.filterAvailableRoles(currentUser);
    const filteredRoles = ui_helpers.filterAvailableRolesByContext(context, userAvailableRoles);
    setAvailableRoles(filteredRoles);
  }, [currentUser])

  const getUserReports = (userId) => api.fetch(`user/ReportsList/${userId ? userId : 0}`).then(response => {
    setReportGroups(shapeReports(response.data.data));
  });

  const getCompanies = () => api.fetch("User/GetCompanies").then(r => {
    setCompanies(ui_helpers.idNameToValueLabel(r.data.data))
  });

  const getUser = () => api.fetch(`User/GetUserDefinition/${contextUserId}`).then(r => {
    let u = r.data.data;
    u.timezone = {
      label: u.timezone,
      value: u.timezone
    };
    setUser(u);
  });

  const getTimezones = () => api.fetch('Reference/Timezones').then(r => {
    if (r.data) {
      setTimezoneList(r.data);
    }
  }).catch((error) => console.error(error));

  const getManufacturers = () => {
    return api.fetch('User/GetManufacturers').then(r => {
      return {incomingMfg: r.data.data}
    });
  };

  const getDealers = () => api.fetch("User/GetDealers").then(r => {
    return {
      dealers: ui_helpers.idNameToValueLabel(r.data.data)
    };
  });

  useEffect(() => {
    let apiCalls = [getCompanies(), getUserReports(contextUserId)];
    if (contextUserId > 0) {
      apiCalls.push(getUser());
    }
    if (currentUserCanAssignDealer) {
      apiCalls.push(getDealers());
    }
    if (currentUserCanAssignManufacturer) {
      apiCalls.push(getManufacturers());
    }
    apiCalls.push(getTimezones());
    Promise.all(apiCalls).then(apiResults => {
      let  aggResults= {};
        apiResults.forEach(r => Object.assign(aggResults, r));
      const {incomingMfg} = aggResults;
      setManufacturers(incomingMfg);
      if (aggResults.dealers) {
        setDealers(aggResults.dealers);
      }
    }).catch(error => Alert.error("There was an error loading user data"));
  }, []);

  function addRolesForStoreUser(storeId, storeName) {
    let newUser = Object.assign({}, user);
    newUser.roles = ui_helpers.addAllStoreRoles(newUser.roles, storeId, storeName);
    saveUserDetails(newUser);
  }

    function addRolesForManufacturerUser(manufacturerId, manufacturerName, dealers) {
      let newUser = Object.assign({}, user);
      user.roles = ui_helpers.addAllMfgRoles(newUser.roles, manufacturerId, manufacturerName);
      _.each(dealers, x => {
        newUser.roles.push({
            typeOfUserRole: constants.ROLE_IDS.ContractViewer,
            companyId: null,
            companyName: null,
            dealerId: x.id,
            dealerName: x.name,
            manufacturerId: null,
            manufacturerName: null
        });
      });
      saveUserDetails(newUser);
    }

    function saveUserDetails(u) {
      // in this case we send the user details but do not perform the valiation or save here
      if (props.onUsersChange) {
        props.onUsersChange(u);
        return;
      }
      var warningsString = ui_helpers.isUserValid(u);
      if (warningsString) {
        Alert.warning(warningsString);
        return;
      }
      let payload = ui_helpers.userPayload(u);
      if (!u.id) {
        payload.reportIds = [];
        for(var i=0; i<reportGroups.length; i++) {
          for(var j=0; j<reportGroups[i].reports.length; j++) {
            if (reportGroups[i].reports[j].isActive) {
              payload.reportIds.push(reportGroups[i].reports[j].reportId);
            }
          }
        }
      }
      api.post("User/Save", payload).then(r => {
        if (r.data && r.data.success) {
          setUser({...user, id: r.data.message});
          Alert.success(`${u.firstName} ${u.lastName} has been saved`);
          if (props.cancel) {
            props.cancel();
            if (props.callback) {
              props.callback();
            }
          } else if (props.callback) {
            props.callback();
          } else if (returnRoute) {
            setRedirectTo(returnRoute);
          } else {
            window.history.go(-1);
          }
        } else {
          Alert.warning("There was an error saving the new user: " + r.data.message);
        }
      });
    }

    function onSelectChange(fieldName, selection) {
      let newUser = Object.assign({}, user);
      newUser[fieldName] = selection;
      setUser(newUser);
      if (props.onUsersChange) {
        props.onUsersChange(newUser);
      }
    }

    function onRoleSelectChange(fieldName, selection) {
        let newRoleState = {
          typeSelection: newRole.typeSelection,
          company: null,
          dealer: null,
          manufacturer: null
        };
        let userCanSelectCompany = canSelectCompany;
        let userCanSelectManufacturer = canSelectManufacturer;
        let userCanSelectDealer = canSelectDealer;
        if (fieldName === "typeSelection") {
            switch (selection.value) {
                case constants.ROLE_IDS.UserManager:
                    userCanSelectCompany = true;
                    userCanSelectDealer = false;
                    userCanSelectManufacturer = false;
                    if (companies && companies.length === 1) {
                      newRoleState.company = companies[0];
                    }
                    break;
                case constants.ROLE_IDS.UserImpersonator:
                    userCanSelectCompany = true;
                    userCanSelectDealer = false;
                    userCanSelectManufacturer = false;
                    if (companies && companies.length === 1) {
                      newRoleState.company = companies[0];
                    }
                    break;
                case constants.ROLE_IDS.ContractManager:
                    userCanSelectCompany = true;
                    userCanSelectDealer = true;
                    userCanSelectManufacturer = false;
                    if (!currentUserCanAssignCompany && dealers && dealers.length === 1) {
                      newRoleState.dealer = dealers;
                    }
                    break;
                case constants.ROLE_IDS.ContractViewer:
                    userCanSelectCompany = true;
                    userCanSelectDealer = true;
                    userCanSelectManufacturer = true;
                    if (!currentUserCanAssignCompany
                      && !currentUserCanAssignDealer
                      && manufacturers && manufacturers.length === 1) {
                      newRoleState.manufacturer = manufacturers;
                    }
                    if (!currentUserCanAssignCompany
                      && !currentUserCanAssignManufacturer
                      && dealers && dealers.length === 1) {
                      newRoleState.dealer = dealers[0];
                    }
                    break;
                case constants.ROLE_IDS.ContractCreator:
                    userCanSelectCompany = true;
                    userCanSelectDealer = true;
                    userCanSelectManufacturer = false;
                    if (!currentUserCanAssignCompany && dealers && dealers.length === 1) {
                      newRoleState.dealer = dealers;
                    }
                    break;
                case constants.ROLE_IDS.InventoryCreator:
                    userCanSelectCompany = false;
                    userCanSelectDealer = true;
                    userCanSelectManufacturer = true;
                    if (!currentUserCanAssignDealer 
                      && manufacturers && manufacturers.length === 1) {
                      newRoleState.manufacturer = manufacturers[0];
                    }
                    break;
                case constants.ROLE_IDS.InventoryViewer:
                    userCanSelectCompany = true;
                    userCanSelectDealer = true;
                    userCanSelectManufacturer = true;
                    if (!currentUserCanAssignCompany
                      && !currentUserCanAssignDealer
                      && manufacturers && manufacturers.length === 1) {
                      newRoleState.manufacturer = manufacturers[0];
                    }
                    if (!currentUserCanAssignCompany
                      && !currentUserCanAssignManufacturer
                      && dealers && dealers.length === 1) {
                      newRoleState.dealer = dealers;
                    }
                    break;
                case constants.ROLE_IDS.InventoryManager:
                    userCanSelectCompany = true;
                    userCanSelectDealer = true;
                    userCanSelectManufacturer = false;
                    if (!currentUserCanAssignCompany && dealers && dealers.length === 1) {
                      newRoleState.dealer = dealers[0];
                    }
                    break;
                case constants.ROLE_IDS.Dispatcher:
                    userCanSelectCompany = true;
                    userCanSelectDealer = true;
                    userCanSelectManufacturer = true;
                    if (!currentUserCanAssignCompany
                      && !currentUserCanAssignDealer
                      && manufacturers && manufacturers.length === 1) {
                      newRoleState.manufacturer = manufacturers[0];
                    }
                    if (!currentUserCanAssignCompany
                      && !currentUserCanAssignManufacturer
                      && dealers && dealers.length === 1) {
                      newRoleState.dealer = dealers[0];
                    }
                    break;
                case constants.ROLE_IDS.TransportViewer:
                    userCanSelectCompany = true;
                    userCanSelectDealer = true;
                    userCanSelectManufacturer = false;
                    if (!currentUserCanAssignCompany && dealers && dealers.length === 1) {
                      newRoleState.dealer = dealers[0];
                    }
                    break;
                case constants.ROLE_IDS.Driver:
                    userCanSelectCompany = true;
                    userCanSelectDealer = true;
                    userCanSelectManufacturer = true;
                    if (!currentUserCanAssignCompany
                      && !currentUserCanAssignDealer
                      && manufacturers && manufacturers.length === 1) {
                      newRoleState.manufacturer = manufacturers[0];
                    }
                    if (!currentUserCanAssignCompany
                      && !currentUserCanAssignManufacturer
                      && dealers && dealers.length === 1) {
                      newRoleState.dealer = dealers[0];
                    }
                    break;
                case constants.ROLE_IDS.ReportViewer:
                    userCanSelectCompany = true;
                    userCanSelectDealer = true;
                    userCanSelectManufacturer = true;
                    if (!currentUserCanAssignCompany
                      && !currentUserCanAssignDealer
                      && manufacturers && manufacturers.length === 1) {
                      newRoleState.manufacturer = manufacturers[0];
                    }
                    if (!currentUserCanAssignCompany
                      && !currentUserCanAssignManufacturer
                      && dealers && dealers.length === 1) {
                      newRoleState.dealer = dealers[0];
                    }
                    break;
                case constants.ROLE_IDS.Administrator:
                    userCanSelectCompany = true;
                    userCanSelectDealer = true;
                    userCanSelectManufacturer = true;
                    if (!currentUserCanAssignCompany
                      && !currentUserCanAssignDealer
                      && manufacturers && manufacturers.length === 1) {
                      newRoleState.manufacturer = manufacturers;
                    }
                    if (!currentUserCanAssignCompany
                      && !currentUserCanAssignManufacturer
                      && dealers && dealers.length === 1) {
                      newRoleState.dealer = dealers[0];
                    }
                    break;
                case constants.ROLE_IDS.SystemAdministrator:
                    userCanSelectCompany = false;
                    userCanSelectDealer = false;
                    userCanSelectManufacturer = false;
                    break;
            }
        }
        newRoleState[fieldName] = selection;
        setNewRole(newRoleState);
        setCanSelectCompany(userCanSelectCompany);
        setCanSelectDealer(userCanSelectDealer);
        setCanSelectManufacturer(userCanSelectManufacturer);
    }

    function onAddRole() {
        if (!newRole.typeSelection) {
            Alert.warning("Select a role type before attempting to add the role.");
            return;
        }
        if (newRole.typeSelection.value !== constants.ROLE_IDS.SystemAdministrator
            && newRole.company === null
            && newRole.dealer === null
            && newRole.manufacturer === null)
        {
            Alert.warning(`Select a context/applicability for this ${newRole.typeSelection.name} role before attempting to add it.`);
            return;
        }
        const newCompanyId = newRole.company ? newRole.company.value : null;
        if (_.some(user.roles, ur =>
            ur.typeOfUserRole === newRole.typeSelection.value
            && (
                (newCompanyId !== null && ur.companyId === newCompanyId)
             || (newRole.dealer && _.some(newRole.dealer, nd => ur.dealerId === nd.value))
             || (newRole.manufacturer && _.some(newRole.manufacturer, nm => ur.manufacturerId === nm.value))
            )
        )) {
            Alert.warning('This role has already been given.');
            return;
        }
        if (newRole.typeSelection.value === constants.ROLE_IDS.SystemAdministrator
          && _.some(user.roles, ur => ur.typeOfUserRole === constants.ROLE_IDS.SystemAdministrator))
        {
            Alert.warning('The system administrator role has already been given.');
            return;
        }
        // if the user already has administrator rights for the same context don't let them add redundant roles
        if (newRole.typeSelection.value !== constants.ROLE_IDS.Administrator
          && newRole.typeSelection.value !== constants.ROLE_IDS.Driver
          && _.some(user.roles, ur => 
              ur.typeOfUserRole === constants.ROLE_IDS.Administrator
              && (
                // (newCompanyId !== null && ur.companyId === newCompanyId)
              (newRole.dealer && _.some(newRole.dealer, nd => ur.dealerId === nd.value))
             || (newRole.manufacturer && _.some(newRole.manufacturer, nm => ur.manufacturerId === nm.value))
            )))
        {
          Alert.warning('The Administrator role has already been given for this context. Granting this role would be redundant.');
          return;
        }
        let newUserRoles = user.roles.slice();
        if (newRole.dealer && newRole.dealer.length > 0) {
            _.forEach(newRole.dealer,
                dealer => {
                    newUserRoles.push({
                        typeOfUserRole: newRole.typeSelection.value,
                        companyId: null,
                        companyName: null,
                        dealerId: dealer.value,
                        dealerName: dealer.label,
                        manufacturerId: null,
                        manufacturerName: null
                    });
                });
        } else if (newRole.manufacturer && newRole.manufacturer.length > 0) {
            _.forEach(newRole.manufacturer,
                mfg => {
                    newUserRoles.push({
                        typeOfUserRole: newRole.typeSelection.value,
                        companyId: null,
                        companyName: null,
                        dealerId: null,
                        dealerName: null,
                        manufacturerId: mfg.value,
                        manufacturerName: mfg.label
                    });
                });
        }
        else
        {
            newUserRoles.push({
                typeOfUserRole: newRole.typeSelection.value,
                companyId: newCompanyId,
                companyName: newRole.company ? newRole.company.label : null,
                dealerId: null,
                dealerName: null,
                manufacturerId: null,
                manufacturerName: null
            });
        }
      // when adding reporting for the first time => add these default reports
      if (newRole.typeSelection.value === constants.ROLE_IDS.ReportViewer 
        && _.filter(newUserRoles, r => r.typeOfUserRole === constants.ROLE_IDS.ReportViewer).length === 1) {
        let newReportGroups = _.cloneDeep(reportGroups);
        for(var i=0; i<newReportGroups.length; i++) {
          for(var j=0; j<newReportGroups[i].reports.length; j++) {
            // arbitrarily chose this group's reports as default, 06-Mar-24, ART :(
            newReportGroups[i].reports[j].isActive = (newReportGroups[i].groupName === 'Dealers');
          }
        }
        setReportGroups(newReportGroups);
      }
      setUser({...user, roles: newUserRoles});
      setNewRole({
        typeSelection: Object.assign({}, newRole.typeSelection),
        company: null,
        dealer: null,
        manufacturer: null
      });
    }

    function setSecondaryNotificationType(option) {
      let newUser = Object.assign({}, user);
      newUser.secondaryNotificationType = option;
      setUser(newUser);
      if (props.onUsersChange) {
        props.onUsersChange(newUser);
      }
    }

    function updateUserState(fieldName, value) {
      let newUser = Object.assign({}, user);
      newUser[fieldName] = value;
      // this.setState({ user: newUser });
      setUser(newUser);
      if (props.onUsersChange) {
        props.onUsersChange(newUser);
      }
    }

    function cancelEditUser() {
      if (props.cancel)
      {
        props.cancel()
      }
      else if (returnRoute)
      {
        // setState({redirectTo: this.state.returnRoute});
        setRedirectTo(returnRoute);
      }
      else
      {
        window.history.go(-1);
      }
    }

    function onEmailBlur(e) {
      if (!user.username) {
        let newUser = Object.assign({}, user);
        newUser.username = user.email;
        // this.setState({ user: newUser });
        setUser(newUser);
        if (props.onUsersChange) {
          props.onUsersChange(newUser);
        }
      }
    }

    function onPhoneBlur() {
      if (!user.phone) return;
      let temp = user.phone.replace(/\(/g, '').replace(/\)/g, '').replace(/-/g, '').replace(/ /g,'');
      if (temp.length === 10) {
          temp = '(' + temp.substring(0, 3) + ')' + temp.substring(3, 6) + '-' + temp.substring(6, 10);
      }
      let newUser = Object.assign({}, user);
      newUser.phone = temp;
      // this.setState({ user: newUser });
      setUser(newUser);
      if (props.onUsersChange) {
        props.onUsersChange(newUser);
      }
    }

    function onRemoveRole(role) {
      let newUser = Object.assign({}, user);
      newUser.roles = _.reject(user.roles, r => r.typeOfUserRole === role.typeOfUserRole
            && r.dealerId === role.dealerId
            && r.manufacturerId === role.manufacturerId
            && r.companyId === role.companyId);
      // this.setState({ user: newUser });
      setUser(newUser);
      if (props.onUsersChange) {
        props.onUsersChange(newUser);
      }
    }

    function changeReportStatus(existingValue, groupName, reportId) {
      let newReportGroups = reportGroups.slice();
      const groupWithReport = _.find(newReportGroups, x => x.groupName === groupName);
      let reportToUpdate = _.find(groupWithReport.reports, x => x.reportId === reportId);
      if (user.id) {
        const route = existingValue
          ? "user/DeactivateReport"
          : "user/ActivateReport";
        api.post(route, reportToUpdate).then(response => {
          if (response.data.success) {
            const updatedReport = response.data.data;
            reportToUpdate.id = updatedReport.id;
            reportToUpdate.deactivatedAt = updatedReport.deactivatedAt;
            reportToUpdate.isActive = !existingValue;
            setReportGroups(newReportGroups);
          } else {
            Alert.error("Could not enable this report!");
          }
        }).catch(error => Alert.error("Could not update this report status!"));
      } else {
        // reportToUpdate.id = updatedReport.id;
        // reportToUpdate.deactivatedAt = updatedReport.deactivatedAt;
        reportToUpdate.isActive = !existingValue;
        setReportGroups(newReportGroups);
      }
    }

    const u = user;
    if (u === null) {
      return (<div />);
    }
    if (redirectTo) {
      return <Redirect to={redirectTo} />;
    }
    let newSelectedRoleContext = null;
    const newSelectedRole = newRole.typeSelection
      ? _.find(constants.roles, r => r.value === newRole.typeSelection.value)
      : null;
    if (newSelectedRole) {
      if (newRole.company) {
        newSelectedRoleContext = 'Company';
      } else if (newRole.manufacturer) {
        newSelectedRoleContext = `the ${_.map(newRole.manufacturer, m => m.label).join(', ')} Manufacturer(s)`;
      } else if (newRole.dealer) {
        newSelectedRoleContext = `the ${_.map(newRole.dealer, m => m.label).join(', ')} Store(s)`;
      }
    }
    const reportsEditable = !props.multiAdd &&
      _.some(u.roles, r =>
        _.includes([constants.ROLE_IDS.ReportViewer, constants.ROLE_IDS.Administrator, constants.ROLE_IDS.SystemAdministrator], r.typeOfUserRole));
    return (<Card>
      <CardHeader>
        <Row>
            <Col>
                <h3>{u.id ? u.fullName : "New User"} Details</h3>
            </Col>
            <Col>
                {!props.multiAdd && (
                    <ButtonGroup className="float-end">
                      {props.onUsersChange
                        ? null
                        : (<Button
                              size="sm"
                              className="btn-success cf-primary text-white"
                              onClick={() => saveUserDetails(user)}>
                              <FontAwesomeIcon icon="save" /> Save
                          </Button>)
                      }
                        <Button
                            size="sm"
                            className="btn-secondary"
                            onClick={() => cancelEditUser()}
                        >
                            <FontAwesomeIcon icon="times-circle" /> Close
                        </Button>
                    </ButtonGroup>
                )}
            </Col>
        </Row>
      </CardHeader>
      <CardBody>
        <Row>
            <Col>
              <Label>First Name {ui_helpers.requiredStar()}</Label>
                <Input
                    type="text"
                    name="firstName"
                    maxLength="25"
                    value={u.firstName}
                    onChange={(event) =>
                        updateUserState(
                            event.target.name,
                            event.target.value
                        )
                    }
                />
            </Col>
            <Col>
              <Label>Last Name {ui_helpers.requiredStar()}</Label>
                <Input
                    type="text"
                    name="lastName"
                    maxLength="25"
                    value={u.lastName}
                    onChange={(event) =>
                        updateUserState(
                            event.target.name,
                            event.target.value
                        )
                    }
                />
            </Col>
            <Col>
              <Label>Email {ui_helpers.requiredStar()}</Label>
                <Input
                    type="text"
                    name="email"
                    value={u.email}
                    maxLength="150"
                    onChange={(event) =>
                        updateUserState(
                            event.target.name,
                            event.target.value
                        )
                    }
                    onBlur={onEmailBlur}
                />
            </Col>
            <Col>
              <Label>Username  {ui_helpers.requiredStar()}</Label>
                <Input
                    type="text"
                    name="username"
                    value={u.username}
                    maxLength="25"
                    onChange={(event) =>
                        updateUserState(
                            event.target.name,
                            event.target.value
                        )
                    }
                />
            </Col>
            {u.id
              ? null
              : (<Col>
                  <Label>Initial Password  {ui_helpers.requiredStar()}</Label>
                  <Input
                    type="text"
                    name="initialPassword"
                    value={u.initialPassword}
                    onChange={(event) => updateUserState(event.target.name, event.target.value)}
                  />
              </Col>)
            }
        </Row>
        <Row className="pt-3">
          <Col>
            <Label>Home Company  {ui_helpers.requiredStar()}</Label>
            <Select
                options={companies}
                value={
                  u.homeCompany
                  ? u.homeCompany
                  : _.find(companies, (c) => {
                      return (parseInt(c.value) === u.homeCompanyId)
                    })
                }
                onChange={(option) => onSelectChange('homeCompany', option)}
            />
          </Col>
          <Col>
            <Label>Timezone</Label>
            <Select 
              id="timezone"
              value={u.timezone}
              options={timezoneList}
              onChange={(tz) => onSelectChange('timezone', tz)}
            />
          </Col>
          <Col>
            <Label>Send SMS Upon Contract Approval</Label>
            <ToggleButton
                value={u.sendSMSUponContractApproval}
                activeLabel={"Yes"}
                inactiveLabel={"No"}
                onToggle={(value) => {
                    setUser({
                      ...u,
                      sendSMSUponContractApproval: !value
                    });
                }}
            />
          </Col>
          <Col>
            <Label>Notify upon new contract/cash sale</Label>
            <ToggleButton
                value={u.notifyOnNewContract}
                activeLabel={"Yes"}
                inactiveLabel={"No"}
                onToggle={(value) => {
                    setUser({
                      ...u,
                      notifyOnNewContract: !value
                    });
                }}
            />
          </Col>
            {/* {_.some(u.roles, r => _.includes([constants.ROLE_IDS.Administrator, constants.ROLE_IDS.SystemAdministrator], r.typeOfUserRole))
              ? (<Col>
              <Label>Can Impersonate</Label>
              <ToggleButton
                value={u.canImpersonate}
                activeLabel="Yes"
                inactiveLabel="No"
                onToggle={(value) => {
                    // this.setState(prevState => ({
                    //     ...prevState,
                    //     user: {
                    //         ...prevState.user,
                    //         canImpersonate: !value
                    //     }
                    // }));
                    setUser({
                      ...u,
                      canImpersonate: !value
                    });
                }}
              />
            </Col>)
              : null
            } */}
        </Row>
        <Row className="pt-3">
            <Col>
              <Label>Phone</Label>
                <Input
                    type="text"
                    name="phone"
                    value={u.phone}
                    maxLength="20"
                    onChange={(event) =>
                        updateUserState(
                            event.target.name,
                            event.target.value
                        )
                    }
                    onBlur={onPhoneBlur}
                />
            </Col>
            <Col>
              <Label>Primary Notification Type {ui_helpers.requiredStar()}</Label>
                <Select
                  options={notificationTypes}
                  value={
                    _.find(notificationTypes, (nt) => {
                      return (parseInt(nt.value) === u.primaryNotificationType)
                    })
                  }
                  onChange={(option) => onSelectChange('primaryNotificationType', option)}
                />
            </Col>
            <Col>
              <Label>Secondary Notification Type {ui_helpers.requiredStar()}</Label>
                <Select
                  options={notificationTypes}
                  value={
                    _.find(notificationTypes, (nt) => {
                      return (parseInt(nt.value) === u.secondaryNotificationType)
                    })
                  }
                  onChange={(option) => onSelectChange('secondaryNotificationType', option)}
                />
            </Col>
        </Row>
        {enableRoleSelection && !props.multiAdd
          ? (<Fragment>
              <hr/>
              <Row className="pt-3">
                <Col>
                  <h4>Adjust {u.fullName} Roles</h4>
                </Col>
              </Row>
              <Row className="pt-3">
                <Col>
                  <Label>Roles</Label>
                  <Select
                    options={groupRoleOptions(availableRoles)}
                    formatGroupLabel={formatRoleGroupLabel}
                    value={newRole.typeSelection}
                    onChange={(option) => onRoleSelectChange('typeSelection', option)}
                  />
                  {newSelectedRole
                    ? (<div style={{margin: "4px", padding: "4px", backgroundColor: "#f2f5ff", borderRadius: "4px"}}>
                        <b>{newSelectedRole.name}</b>: {newSelectedRole.dsc}{newSelectedRoleContext ? ` for ${newSelectedRoleContext}` : ''}
                      </div>)
                    : null
                  }
                </Col>
                {currentUserCanAssignCompany && !props.manufacturer && !props.store && canSelectCompany
                  ? (<Col>
                      <Label>Company</Label>
                      <Select
                        options={companies}
                        value={newRole.company}
                        onChange={(option) => onRoleSelectChange('company', option)}
                      />
                  </Col>)
                  : null
                }
                {currentUserCanAssignDealer && !props.manufacturer && canSelectDealer
                  ? (<Col>
                      <Label>Store</Label>
                      <Select
                        isMulti
                        closeMenuOnSelect={false}
                        options={dealers}
                        value={newRole.dealer}
                        onChange={(option) => onRoleSelectChange('dealer', option)}
                      />
                  </Col>)
                  : null
                }
                {currentUserCanAssignManufacturer && !props.store && canSelectManufacturer
                  ? (<Col>
                      <Label>Manufacturer</Label>
                      <Select
                        isMulti
                        closeMenuOnSelect={false}
                        options={_.map(manufacturers, m => {
                            m.label = m.name;
                            m.value = m.id;
                            return m;
                        })}
                        value={newRole.manufacturer}
                        onChange={(option) => onRoleSelectChange('manufacturer', option)}
                      />
                  </Col>)
                  : null
                }
                <Col sm="1" className="pt-4">
                  <Button color="success" className="float-end text-light" onClick={onAddRole} title="Add this role">
                    <FontAwesomeIcon icon="plus" />
                  </Button>
                </Col>
              </Row>
              <Row className="pt-3">
                <Col>
                  <Table size="sm">
                    <thead>
                      <tr>
                        <th>Role Name</th>
                        <th>Context</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                    {_.sortBy(u.roles, r => roleIdToName(r.typeOfUserRole)).map(r => (
                      <tr key={`${r.typeOfUserRole}-${r.companyId}-${r.manufacturerId}-${r.dealerId}`}>
                        <td>{roleIdToName(r.typeOfUserRole)}</td>
                        <td>{resolveRoleContextDescription(r)}</td>
                        <td>
                          <Button onClick={() => onRemoveRole(r)} color="danger" className="text-light" size="sm" title="Remove">
                            <FontAwesomeIcon icon="times-circle" />
                          </Button>
                        </td>
                      </tr>
                      ))}
                    </tbody>
                  </Table>
                </Col>
              </Row>
            </Fragment>)
          : null
        }
        {reportsEditable
          ? (<Fragment>
              <hr/>
              <Row className="pt-3">
                <Col>
                  <h4>Adjust {u.fullName} Reporting</h4>
                </Col>
              </Row>
              <Row className="pt-3">
                <Col sm="6">
                {_.map(reportGroups, g => (
                  <div key={g.groupName}>
                    <h5 className="page-title">{g.groupName}</h5>
                    <Table size="sm">
                      <thead>
                        <tr>
                          <th style={{width: "260px"}}>Report Name</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                      {g.reports.map(r => (
                        <tr key={`${r.id}${r.reportDsc}`}>
                          <td>{r.reportDsc}</td>
                          <td>
                            <ToggleButton
                              value={r.isActive}
                              activeLabel={"Yes"}
                              inactiveLabel={"No"}
                              onToggle={(value) => changeReportStatus(value, g.groupName, r.reportId)}
                            />
                          </td>
                        </tr>
                      ))}
                      </tbody>
                    </Table>
                  </div>))
                }
                </Col>
              </Row>
            </Fragment>)
          : null
        }
      </CardBody>
    </Card>);
}