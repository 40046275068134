import React, { useContext, useEffect, useState, Fragment } from "react";
import { Link, useParams, Redirect } from "react-router-dom";
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Card,
  Button,
  CardTitle,
  CardText,
  Row,
  Col,
  Container,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ButtonGroup
} from "reactstrap";
import {
  Header,
  Footer,
  CalculatorModal,
  BuildCertify,
  BuildInspection,
  BuildOrderCreation,
  BuildProcessManagement,
  BuildInfo,
  Loading,
} from ".";
import CalcImg from "../assets/img/calculator.png";
import CustomerDelivery from "./CustomerDelivery";
import { GenericStep } from ".";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classnames from "classnames";
import { api, constants, UserContext } from "../utils";
import _ from "lodash";
import Alert from "react-s-alert-v3";
import { ui_helpers, date_helpers } from "../helpers";
import ReactTooltip from "react-tooltip";
const PATHNAMES = constants.PATH_NAMES;
const BUILDSTEPS = constants.BUILD_STEPS;

const TabHeader = (props) => {
  const getBuildStepStatus = () => {
    if (props.completedAt) {
      return "complete-build-step";
    }
    if (props.plannedFor) {
      return "in-progress-build-step";
    }
    return "incomplete-build-step";
  };

  return (
    <>
      <NavItem>
        <NavLink
          className={classnames({
            activeBlue: props.activeTab === `${props.index}`,
          })}
          onClick={() => {
            props.toggle(`${props.index}`);
          }}
        >
          <FontAwesomeIcon
            data-tip
            data-for={`n-${props.index}`}
            className={`icon-sidebar ${getBuildStepStatus()}`}
            icon={props.icon ? props.icon : "square"}
          />
        </NavLink>
      </NavItem>
      <ReactTooltip id={`n-${props.index}`} place="bottom" effect={"solid"}>
        <p style={{ fontSize: "1rem", opacity: "1" }}>
          {props.name ? props.name : ""}
        </p>
      </ReactTooltip>
    </>
  );
};

const TabBody = ({tabId, children}) => {
  return <TabPane tabId={tabId}>{children}</TabPane>;
};

export default function BuildDetails(props) {
  const { id } = useParams();
  const [activeTab, setActiveTab] = useState(constants.BUILD_TABS.BUILD_INFO);
  const [buildSteps, setBuildSteps] = useState([]);
  const [inventory, setInventory] = useState({});
  const [addOns, setAddOns] = useState([]);
  const [builders, setBuilders] = useState([]);
  const [paginatedList, setPaginatedList] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [alert, setAlert] = useState(constants.ALERT_OBJECT);
  const [redirectTo, setRedirectTo] = useState("");
  const [deliveryOrientation, setDeliveryOrientation] = useState([]);
  const [hasIncompleteSteps, setHasIncompleteSteps] = useState(false);
  const [showMoveToInStockModal, setShowMoveToInStockModal] = useState(false);

  const { currentUser } = useContext(UserContext);
  useEffect(() => {
    getBuildItem();
    //getAssignedBuilder();
  }, []);

  function toggle(tab) {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  }

  const getBuildItem = () => {
    if (isLoading) return;
    setIsLoading(true);
    api
      .fetch(`Inventory/GetBuild/${id}`)
      .then((r) => {
        if (!r.data) return;
        setBuildSteps(
          ui_helpers.mapBuildStepsForUI(r.data.buildSteps));
        setInventory(r.data.inventory);
        setAddOns(r.data.inventoryAddOns);
        setDeliveryOrientation(r.data.deliveryOrientation);
        setHasIncompleteSteps(_.some(r.data.buildSteps, x => !x.completedAt && !x.deactivatedAt));
      })
      .catch((e) => {
        Alert.error(
          "There was an issue retrieving the inventory info for this build item."
        );
      })
      .finally(() => setIsLoading(false));
  };

  function getBuilders() {
    api.fetch(`Reference/GetManufacturerBuilders/${inventory.manufacturerId}`).then((r) => {
      if (r.data.success) {
        setBuilders(r.data.message.list);
      } else {
        Alert.error("There was an issue retrieving the list of builders: " + r.data.message);
      }
    }).catch((e) => Alert.error("There was an issue retrieving the list of builders."));
  }

  const saveAssignedBuilder = (selectedBuilder) => {
    let buildStepId = _.find(buildSteps, (b) => {
        return b.name === "Assign Builder";
    });
    let payload = {
      unitInventoryId: id,
      builder: selectedBuilder,
      buildStepId: buildStepId.id,
    };
    api.post("Inventory/AssignBuilderToBuildStep", payload).then((r) => {
      if (!r.data.success) {
        Alert.error(
          `There as an issue assigning ${selectedBuilder.firstName} ${selectedBuilder.lastName} to this unit.`
        );
        return;
      }
      getBuildSteps();
      Alert.success(
        `Successfully assigned the builder: ${selectedBuilder.firstName} ${selectedBuilder.lastName}  to this unit.`
      );
    });
  };

  const getBuildSteps = () => {
    api.fetch(`Inventory/GetUnitBuildSteps/${inventory.id}`).then((r) => {
      if (!r.data) return;
      setBuildSteps(
        ui_helpers.mapBuildStepsForUI(r.data));
    });
  };

  const updateBuildStepSeq = (buildItems) => {
    let newBuildItems = buildItems.slice();
    _.forEach(buildItems, (b, idx) => {
      b.seq = idx + 1;
      newBuildItems[idx] = b;
    });
    api.post("inventory/UpdateUnitBuildStepSeq", newBuildItems).then((r) => {
      if (!r.data.success) {
        Alert.error(
          "There was an issue updating the sequence of the Unit Inventory Build Steps."
        );
      }
    });

    return newBuildItems;
  };

  const renderBuildStep = (isHeader, step) => {
    if (step.name === BUILDSTEPS.BUILDER) {
      if (isHeader) {
        return (
          <TabHeader
            index={`${step.id}`}
            icon={"user-plus"}
            toggle={toggle}
            completedAt={step.completedAt}
            activeTab={activeTab}
            name={step.name}
          />
        );
      }

      return (
        <TabBody key={`tab-${step.id}`} tabId={`${step.id}`}>
          <BuildCertify
            builders={builders}
            inventory={inventory}
            assignBuilderCallback={saveAssignedBuilder}
            assignedBuilder={buildSteps ? getBuilderAssigned() : null}
            buildStep={step}
            getBuildSteps={getBuildSteps}
          />
        </TabBody>
      );
    }
    if (step.name === BUILDSTEPS.DELIVERY) {
      return; //Hiding this for now per Conrad 8/19/22
      // if (isHeader) {
      //   return (
      //     <TabHeader
      //       index={`${step.id}`}
      //       icon={"truck"}
      //       toggle={toggle}
      //       completedAt={step.completedAt}
      //       activeTab={activeTab}
      //       name={step.name}
      //     />
      //   );
      // }
      //
      // return (
      //   <TabBody tabId={`${step.id}`}>
      //     <CustomerDelivery buildStep={step} deliveryOrientation={deliveryOrientation}
      //                       inventory={inventory}
      //                       getBuildSteps={getBuildSteps}/>
      //   </TabBody>
      // );
    }
    if (step.name === BUILDSTEPS.BUILD) {
      return; //No page for this yet
    }
    // if (step.name === BUILDSTEPS.SERIAL_NUMBER) {
    //   return; //No page for this yet
    // }
    if (step.name === BUILDSTEPS.INSPECTION) {
      if (isHeader) {
        return (
          <TabHeader
            index={`${step.id}`}
            icon={"search"}
            toggle={toggle}
            completedAt={step.completedAt}
            activeTab={activeTab}
            name={step.name}
            buildStep={step}
          />
        );
      }

      return (
        <TabBody key={`tab-${step.id}`} tabId={`${step.id}`}>
          <BuildInspection
            inventory={inventory}
            assignedBuilder={buildSteps ? getBuilderAssigned() : null}
            buildStep={step}
            getBuildSteps={getBuildSteps}
          />
        </TabBody>
      );
    }

    //Create Generic step if it's not found
    if (isHeader) {
      return (
        <TabHeader
          index={`${step.id}`}
          toggle={toggle}
          completedAt={step.completedAt}
          activeTab={activeTab}
          name={step.name}
          icon={"clone"}
          plannedFor={step.plannedFor}
        />
      );
    }

    return (
      <TabBody key={`tab-${step.id}`} tabId={`${step.id}`}>
        <GenericStep
          name={step.name}
          buildStep={step}
          getBuildSteps={getBuildSteps}
        />
      </TabBody>
    );
  };

  const getBuilderAssigned = () => {
    let builder = _.find(buildSteps, (b) => b.name === "Assign Builder");
    if (!builder) {
      return "";
    }
    return builder;
  };

  useEffect(() => {
    if (!inventory.manufacturerId) return;
    getBuilders();
    if (ui_helpers.isSystemAdministrator(currentUser)) return;
    if (
      ui_helpers.isContextManufacturerUser(
        currentUser,
        inventory.manufacturerBrandId
      )
    )
      return;
    setRedirectTo("/builds");
  }, [inventory]);

  if (redirectTo) {
    return <Redirect to={redirectTo} />;
  }

  function onMoveToStock(){
    if (hasIncompleteSteps) {
        setShowMoveToInStockModal(true)
    } else {
        doMoveToStock();
    }
  }

  function doMoveToStock() {
    if (!inventory || !inventory.id) return;
    api.post(`inventory/moveBuildToInStock/${inventory.id}`)
        .then(res => {
            if (res.data && !res.data.success) {
                Alert.error(res.data.message || "An error occurred moving this unit to 'In-Stock'.")
            }
            Alert.success("Unit has been moved to an 'In-Stock' status.")
            setShowMoveToInStockModal(false);
            getBuildItem();
        }).catch(err => {
            Alert.error("An error occurred. Unit status cannot be changed at this time.")
            console.error(err);
        })
  }

const releasedToBuild = buildSteps && buildSteps.length
  ? _.find(buildSteps, b => b.name === constants.BUILD_STEPS.BUILD) 
  : null;

  return (
    <>
      <Header />
      {alert.message ? (
        <Alert
          flavor={alert.flavor}
          className="text-center"
          toggle={() => setAlert(constants.ALERT_OBJECT)}
        >
          {alert.message}
        </Alert>
      ) : null}
      <Container fluid>
        {isLoading ? (
          <div className={"d-flex justify-content-center align-items-center"}>
            <Loading />
          </div>
        ) : (
          <>
            <Row className="d-flex justify-content-around mt-4 mb-1 mx-5">
              <Col xs="4" className="d-flex justify-content-center">
                <h4>Serial #</h4> <Link className={'ms-1 h4'} to={`/inventory/view/${inventory.id}`}>{inventory ? inventory.serialNumber : ""}</Link>
              </Col>
              <Col xs="4" className="d-flex justify-content-center">
                <h4>
                  Manufacturer: {inventory ? inventory.manufacturerName : ""}
                </h4>
              </Col>
              <Col xs="4" className="d-flex justify-content-center">
                <h4>Store: {inventory ? inventory.dealerName : ""}</h4>
              </Col>
            </Row>
            <Row className="my-2">
              <Col xs="12" className="icon-top">
                {(inventory.typeOfInventoryStatus === constants.INVENTORY_STATUS_ID.InProduction) &&
                  (<Button color="warning" className="text-light float-end" onClick={onMoveToStock}>
                    Move To In-Stock
                  </Button>)
                }
                <Nav tabs pills className="work-process-tab icon-top my-2">
                  <NavItem>
                    <NavLink
                      data-tip
                      data-for={`n-${constants.BUILD_TABS.BUILD_INFO}`}
                      className={classnames({
                        activeBlue: activeTab === constants.BUILD_TABS.BUILD_INFO,
                        "bg-cf-success": releasedToBuild && releasedToBuild.completedAt
                      })}
                      onClick={() => toggle(constants.BUILD_TABS.BUILD_INFO)}
                    >
                      <FontAwesomeIcon
                        className="icon-sidebar default-build-step"
                        icon="plus-square"
                      />
                    </NavLink>
                  </NavItem>
                  <ReactTooltip id={`n-${constants.BUILD_TABS.BUILD_INFO}`} place="bottom" effect={"solid"}>
                    <p style={{ fontSize: "1rem", opacity: "1" }}>
                      Build Info
                    </p>
                  </ReactTooltip>
                  <NavItem>
                    <NavLink
                      data-tip
                      data-for={"n--2"}
                      className={classnames({ activeBlue: activeTab === constants.BUILD_TABS.BUILD_STEP_STATUS })}
                      onClick={() => toggle(constants.BUILD_TABS.BUILD_STEP_STATUS)}
                    >
                      <FontAwesomeIcon
                        className="icon-sidebar default-build-step"
                        icon="info-circle"
                      />
                    </NavLink>
                  </NavItem>
                  <ReactTooltip id={`n-${constants.BUILD_TABS.BUILD_STEP_STATUS}`} place="bottom" effect="solid">
                    <p style={{ fontSize: "1rem", opacity: "1" }}>
                      Build Step Status
                    </p>
                  </ReactTooltip>
                  {buildSteps
                    ? _.map(buildSteps, (b) => 
                      (<Fragment key={b.id}>
                        {renderBuildStep(true, b)}
                      </Fragment>))
                    : null}
                  <NavItem>
                    <NavLink
                      data-tip
                      data-for={`n-${constants.BUILD_TABS.MANAGE_BUILD_STEPS}`}
                      className={classnames({ activeBlue: activeTab === constants.BUILD_TABS.MANAGE_BUILD_STEPS })}
                      onClick={() => toggle(constants.BUILD_TABS.MANAGE_BUILD_STEPS)}
                    >
                      <FontAwesomeIcon
                        className="icon-sidebar default-build-step"
                        icon="folder-plus"
                      />
                    </NavLink>
                  </NavItem>
                  <ReactTooltip id={`n-${constants.BUILD_TABS.MANAGE_BUILD_STEPS}`} place="bottom" effect="solid">
                    <p style={{ fontSize: "1rem", opacity: "1" }}>
                      Manage Build Steps
                    </p>
                  </ReactTooltip>
                </Nav>
              </Col>
            </Row>
            <Row>
              <Col xs="12">
                <TabContent activeTab={activeTab}>
                  <TabPane tabId={constants.BUILD_TABS.BUILD_INFO}>
                    <BuildOrderCreation
                      addOns={addOns}
                      inventory={inventory}
                      refresh={getBuildItem}
                      releasedToBuild={releasedToBuild} 
                      getBuildSteps={getBuildSteps}
                    />
                  </TabPane>
                  <TabPane tabId={constants.BUILD_TABS.BUILD_STEP_STATUS}>
                    <BuildInfo toggleTab={toggle} buildSteps={buildSteps} onRefreshBuildSteps={getBuildItem}/>
                  </TabPane>
                  {buildSteps
                    ? _.map(buildSteps, (b) => {
                        return renderBuildStep(false, b);
                      })
                    : null}
                  <TabPane tabId={constants.BUILD_TABS.MANAGE_BUILD_STEPS}>
                    <BuildProcessManagement
                      updateBuildStepSeq={updateBuildStepSeq}
                      unitBuildSteps={buildSteps}
                      setUnitBuildSteps={setBuildSteps}
                      getBuildSteps={getBuildSteps}
                    />
                  </TabPane>
                </TabContent>
              </Col>
            </Row>
            <Modal isOpen={showMoveToInStockModal} toggle={() => setShowMoveToInStockModal(false)}>
              <ModalHeader>Move Inventory to In-Stock?</ModalHeader>
              <ModalBody>
                This unit has <span className="bold-text">incomplete build steps</span>, are you sure want to move it to In-Stock?
              </ModalBody>
              <ModalFooter>
                <ButtonGroup className="float-right">
                  <Button size="sm" onClick={() => setShowMoveToInStockModal(false)}>Cancel</Button>
                  <Button size="sm" color="primary" onClick={doMoveToStock}>Move</Button>
                </ButtonGroup>
              </ModalFooter>
            </Modal>
          </>
        )}
      </Container>
    </>
  );
}