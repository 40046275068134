import React, {useState} from 'react';
import {Button, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row} from "reactstrap";




export default function ConfirmDeleteModal({onDeleteCallback, onCancelCallback, open}) {




    return (
      <>
          <Modal isOpen={open} centered>
              <ModalHeader>
                  Confirm Delete
              </ModalHeader>
              <ModalBody>
                  Are you sure you want to delete this item?
              </ModalBody>
              <ModalFooter>
                  <Row>
                      <Col>
                          <Button onClick={() => onCancelCallback()}>
                              Cancel
                          </Button>
                      </Col>
                      <Col>
                          <Button className={'btn-delete'} onClick={() => onDeleteCallback()}>
                              Delete
                          </Button>
                      </Col>
                  </Row>
              </ModalFooter>
          </Modal>
      </>
    );
}