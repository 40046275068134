import React, { Component, useContext, useState, useEffect } from "react";
import {
  Row,
  Col,
  Table,
  Container,
  Card,
  CardHeader,
  CardBody,
  Button,
  ButtonGroup,
  Collapse,
} from "reactstrap";
import { api, ui_helpers, filter_helpers, date_helpers } from "../helpers";
import {
  ActiveToggleFilter,
  TextFilter,
  FilterSet,
  Header,
  Footer,
  ManufacturerAddEdit,
  ResultHeader,
  FilterSearch,
  Pager,
  Loading,
  BackButton,
  AdminHeader,
  CompanyAddEdit, SortHeader,
} from "../components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Alert from "react-s-alert-v3";
import {Link, Redirect} from "react-router-dom";
import { UserContext, constants } from "../utils";
import _ from "lodash";
const PATHNAMES = constants.PATH_NAMES;

export default function Manufacturers() {
  const [filters, setFilters] = useState([
      {filterName: 'activeOnly', value: true, filteredDisplay: 'Active Only?: true'}
    ]);
  const [pagedList, setPagedList] = useState();
  const [sortColumn, setSortColumn] = useState("Name");
  const [sortAscending, setSortAscending] = useState(true);
  const [manufacturerAddEdit, setManufacturerAddEdit] = useState(false);
  const [manufacturers, setManufacturers] = useState([]);
  const [clear, setClear] = useState(false);
  const [sortDirection, setSortDirection] = useState("asc");
  const [pageNumber, setPageNumber] = useState(1);
  const [loading, setLoading] = useState(false);
  const [redirect, setRedirect] = useState('');

  const userCtx = useContext(UserContext);

  useEffect(() => {
    if (ui_helpers.isSystemAdministrator(userCtx.currentUser) 
      || ui_helpers.isAnyManufacturerAdministrator(userCtx.currentUser)) return;
    setRedirect('/');
  }, []);

  useEffect(() => {
    let isActive = true;
    refreshList(isActive);
    return () => {
      isActive = false;
    };
  }, [filters, clear, pageNumber, sortColumn, sortDirection]);

  function filterChange(changedFilter) {
    const newFilters = filter_helpers.getFilters(filters, changedFilter);
    setFilters(newFilters);
  }

  function toggleManufacturerAddEdit(isActive = false) {
    setManufacturerAddEdit(!manufacturerAddEdit);
    refreshList(isActive);
  }

  function refreshList(isActive) {
    if(loading) return;
    setLoading(true);
    let payload = {
      activeOnly: false,
      name: null,
      id: null,
      associatedDealerId: null,
      associatedDealerIdList: [],
      sortColumn: sortColumn,
      sortAscending: sortDirection === "asc",
      pageSize: 100,
      page: pageNumber
    };
    _.each(filters, (filter) => (payload[filter.filterName] = filter.value));
    api.post("manufacturer/list", payload).then((r) => {
      if (r.data.success && isActive) {
        setManufacturers(r.data.data.items);
        setPageNumber(r.data.data.pageNumber);
        setPagedList({
          pageSize: r.data.data.pageSize,
          totalItems: r.data.data.totalCount,
        });
      }
    }).catch((error) =>
      Alert.error("There was an error loading the manufacturer list")
    ).finally(() => setLoading(false));
  }

  function doSort(field, direction) {
    setSortColumn(field);
    setSortDirection(direction);
  }

  function toggleActive(manId) {
    api
      .post(`manufacturer/ToggleActive/${manId}`)
      .then((response) => {
        refreshList(true);
      })
      .catch((error) =>
        Alert.error("There was an error activating the store.")
      );
  }

  if (redirect) {
    return <Redirect to={redirect}/>
  }

  return (
    <>
      <AdminHeader mfg />
      <Container fluid>
        <Row>
          <Col>
          </Col>
          <Col>
          {manufacturerAddEdit
            ? <Col><div className="m-2"></div></Col>
            : <FilterSearch filters={filters} onClear={() => setFilters([])}>
                <Row>
                  <Col xs="4">
                    <TextFilter
                      filterName="name"
                      displayName="Manufacturer Name"
                      descriptionPrefix="contains"
                      onChangeCallback={filterChange}
                    />
                  </Col>
                  <Col xs="4">
                    <ActiveToggleFilter
                      filterName='activeOnly'
                      displayName='Active Only?'
                      value={filter_helpers.get_value('activeOnly', filters)}
                      onChangeCallback={filterChange}
                      clear={clear}
                    />
                  </Col>
                </Row>
            </FilterSearch>
          }
          </Col>
        </Row>
        <Row className="g-0">
          <Col>
            <Card style={{ borderColor: "transparent" }}>
              <CardHeader
                style={{ borderColor: "transparent" }}
              >
                <Row>
                  <Col>
                    <ResultHeader
                      heading="Manufacturers"
                      totalResults={pagedList ? pagedList.totalItems : 0}
                    />
                  </Col>
                  <Col>
                    <ButtonGroup className="float-end">
                      {ui_helpers.isAnyCompanyAdministrator(
                        userCtx.currentUser
                      ) ||
                      ui_helpers.isSystemAdministrator(userCtx.currentUser) ? (
                        <Button
                          size="sm"
                          className="btn-success cf-success buttonGroupSpacing"
                          onClick={() => toggleManufacturerAddEdit()}
                          style={{ color: "white" }}
                        >
                          <FontAwesomeIcon
                            icon={manufacturerAddEdit ? "minus" : "plus"}
                            style={{ color: "white" }}
                          />{" "}
                          Manufacturer
                        </Button>
                      ) : null}
                      <Link to="/">
                        <Button size="sm" className="btn-dark">
                          <FontAwesomeIcon icon="home" />
                        </Button>
                      </Link>
                    </ButtonGroup>
                  </Col>
                </Row>
              </CardHeader>
            </Card>
          </Col>
        </Row>
        <Row className="rowSpacing">
          <Col>
            <Card style={{ borderColor: "transparent" }}>
              <CardBody className="pt-0">
                {manufacturerAddEdit ? (
                  <ManufacturerAddEdit
                    onSaveCallback={toggleManufacturerAddEdit}
                    currentUser={userCtx.currentUser}
                  />
                ) : (
                  <>
                    <Table hover striped bordered responsive>
                      <thead>
                        <tr>
                          <SortHeader
                              displayName="Name"
                              field={'Name'}
                              sortDir={sortDirection}
                              sorted={sortColumn === 'Name'}
                              sortCallback={doSort}
                            />
                          <SortHeader
                              displayName="Owner"
                              field={'Owner'}
                              sortDir={sortDirection}
                              sorted={sortColumn === 'Owner'}
                              sortCallback={doSort}
                              size={1}
                          />
                          <SortHeader
                              displayName="Direct Sales"
                              field={'isStore'}
                              sortDir={sortDirection}
                              sorted={sortColumn === 'isStore'}
                              sortCallback={doSort}
                          />
                          <SortHeader
                              displayName="Build Feature"
                              field={'Build'}
                              sortDir={sortDirection}
                              sorted={sortColumn === 'Build'}
                              sortCallback={doSort}
                          />
                          <SortHeader
                              displayName="Last Updated"
                              field={'LastUpdated'}
                              sortDir={sortDirection}
                              sorted={sortColumn === 'LastUpdated'}
                              sortCallback={doSort}
                          />
                          <SortHeader
                              displayName="Stores"
                              field={'AssociatedDealerCount'}
                              sortDir={sortDirection}
                              sorted={sortColumn === 'AssociatedDealerCount'}
                              sortCallback={doSort}
                          />
                          <SortHeader
                              displayName="Inventory Stores"
                              field={'AssociatedInventoryDealerCount'}
                              sortDir={sortDirection}
                              sorted={sortColumn === 'AssociatedInventoryDealerCount'}
                              sortCallback={doSort}
                          />
                          <th />
                        </tr>
                      </thead>
                      <tbody>
                        {loading ? (
                          <tr key={"l-1"}>
                            <td colSpan={8}>
                              <Loading />
                            </td>
                          </tr>
                        ) : manufacturers ? (
                          _.map(manufacturers, (x) => (
                            <tr key={x.id}>
                              <td>
                                <Link to={`/admin/manufacturer/${x.id}`}>
                                  {x.name}
                                </Link>
                              </td>
                              <td xs={1} sm={1}>
                                {x.isOwner ? ui_helpers.greenCheck() : null}
                              </td>
                              <td xs={1} sm={1}>
                                {x.isStore ? ui_helpers.greenCheck() : null}
                              </td>
                              <td sm={1}>
                                {x.enableBuildFeature
                                  ? ui_helpers.greenCheck()
                                  : null
                                }
                                {x.enableBuildFeaturePreview
                                  ? (<div className="badge bg-info">*PREVIEW*</div>)
                                  : null
                                }
                              </td>
                              <td xs={1} sm={1}>
                                {date_helpers.formatDateToTinyDate(x.updatedAt)}{" "}
                                by{" "}
                                {x.updatedByUser
                                  ? `${x.updatedByUser.firstName} ${x.updatedByUser.lastName}`
                                  : "-"}
                              </td>
                              <td sm={1}>{x.associatedDealerCount}</td>
                              <td sm={1}>{x.associatedInventoryDealerCount}</td>
                              <td sm={1}>
                                {x.deactivatedAt === null ? (
                                  <Button
                                    size="sm"
                                    className="float-center btn-delete"
                                    onClick={() => toggleActive(x.id)}
                                  >
                                    <FontAwesomeIcon icon="trash-alt" />
                                  </Button>
                                ) : (
                                  <Button
                                    size="sm"
                                    className="float-end text-light btn-success cf-success"
                                    onClick={() => toggleActive(x.id)}
                                  >
                                    Revive
                                  </Button>
                                )}
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan={8}></td>
                          </tr>
                        )}
                      </tbody>
                    </Table>
                    <Pager
                      pageNumber={pageNumber}
                      pageSize={pagedList ? pagedList.pageSize : 0}
                      totalResults={pagedList ? pagedList.totalItems : 0}
                      onSetPageCallback={setPageNumber}
                    />
                  </>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  );
}
