import React, { useEffect } from "react";
import DocumentArea from "./DocumentArea";
import PdfToolbarTop from "./PdfToolbarTop";
import useStore from "./useStore";
import { v4 as uuid } from "uuid";

export default function LocationSetter({
  filePath,
  handleSave,
  signatureLocations,
}) {
  const setSignatureLocations = useStore((x) => x.setSignatureLocations);

  useEffect(() => {
    if (signatureLocations) {
      setSignatureLocations(
        signatureLocations.map((sl, i) => ({
          ...sl,
          domId: uuid(),
          isIntersecting: i === 0,
        }))
      );
    }
  });

  return (
    <>
      <PdfToolbarTop handleSave={handleSave} />
      <DocumentArea filePath={filePath} />
    </>
  );
}
