import React from "react";
import { useControlTarget, useMovability } from "../hooks";
import { ControlPoint } from "./";

function Outlet(props) {
  const [transform, handleMove, handleRotate] = useMovability(props);
  const [controlTarget, setControlTarget] = useControlTarget();

  const active =
    controlTarget === props.domId || controlTarget === "rotate-" + props.domId;

  return (
    <g transform={transform}>
      <circle
        cx={0}
        cy={-8}
        r={3}
        stroke="black"
        strokeWidth={0.5}
        fill="none"
      />
      <line x1={-1} y1={0} x2={-1} y2={-12} stroke="black" strokeWidth={0.5} />
      <line x1={1} y1={0} x2={1} y2={-12} stroke="black" strokeWidth={0.5} />
      <rect
        id={props.domId}
        x={-5}
        y={-14}
        width={10}
        height={16}
        fill={active ? "rgba(0,0,255,0.05)" : "rgba(0,0,0,0)"}
        stroke={active ? "#0d6efd" : "none"}
        strokeDasharray={2}
        strokeWidth={0.5}
        style={{ cursor: active ? "move" : "pointer" }}
        onMouseDown={handleMove}
        onTouchStart={handleMove}
      />
      {active ? (
        <>
          <line
            x1={0}
            y1={-14}
            x2={0}
            y2={-24}
            stroke="#0d6efd"
            strokeWidth={0.5}
          />
          <ControlPoint
            id={"rotate-" + props.domId}
            cx={0}
            cy={-24}
            onClick={() => setControlTarget("rotate-" + props.domId)}
            onMouseDown={handleRotate}
            onTouchStart={handleRotate}
          />
        </>
      ) : null}
    </g>
  );
}

export default Outlet;
