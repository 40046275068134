import React, { useState } from "react";
import { ControlTargetContext } from "../contexts";
import { initialControlTarget } from "../init";

function ControlTargetProvider({ children }) {
  const [controlTarget, setControlTarget] = useState(initialControlTarget);

  return (
    <ControlTargetContext.Provider value={[controlTarget, setControlTarget]}>
      {children}
    </ControlTargetContext.Provider>
  );
}
export default ControlTargetProvider;
