import React, {Fragment, useState, useEffect, useContext} from 'react';
import {useParams, useLocation, Link} from "react-router-dom";
import {MfgAdmin} from "./";
import {Alert as AlertBanner, Button, Card, CardBody, CardHeader, Col, Container, Label, Row, Table} from "reactstrap";
import {api, date_helpers, ui_helpers} from "../helpers";
import _ from "lodash";
import Select from "react-select";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {constants, UserContext} from "../utils";
import Alert from "react-s-alert-v3";

export default function ManufacturerCompanies(props) {
  const params = useParams();
  const mfgId = params.mfgId
    ? params.mfgId
    : props.mfgId;
  const [companyManufacturers, setCompanyManufacturers] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [companyToSave, setCompanyToSave] = useState({});
  const [loading, setLoading] = useState(false);
  const [manufacturerName, setManufacturerName] = useState('');
  const [message, setMessage] = useState(constants.ALERT_OBJECT);
  const {currentUser} = useContext(UserContext);

  const isSysAdmin = ui_helpers.isSystemAdministrator(currentUser);

  // const getRegions = () => {
  //     return api.fetch('reference/RegionList')
  //         .then(r => {
  //             return { states: ui_helpers.idNameToValueLabel(r.data.data) }
  //         })
  //         .catch(error => Alert.error("There was an error loading the state list"));
  // };

  useEffect(() => {
    getCompaniesList();
    getManufacturerCompanies();
  }, []);

  function getManufacturerCompanies() {
    api.fetch(`manufacturer/ListOfCompanies/${mfgId}`).then(response => {
      setCompanyManufacturers(response.data.message.list);
      setManufacturerName(response.data.message.manufacturerName);
    }).catch(error => Alert.error("There was an error retrieving the mfg companies list."));
  }

  function getCompaniesList() {
    api.fetch(`company/Companies`).then(response => {
      setCompanies(
        ui_helpers.idNameToValueLabel(response.data.data));
    }).catch(error => Alert.error("There was an error retrieving the companies list."));
  }

  function getSelectedCompany(company) {
    const selected_option = _.find(companies, x => x.label === company.label);
    if (selected_option) {
      return [selected_option];
    }
    return null;
  }

  function setSelectedCompany(option) {
    setCompanyToSave({
        value: option.value,
        label: option.label
    });
  }

  function saveCompanyToManufacturer() {
    if (_.isEmpty(companyToSave)) {
      Alert.error("Please select a Company before attempting to add.");
      return;
    }
    if (_.some(companyManufacturers, {"companyId": parseInt(companyToSave.value)})) 
    {
      Alert.error("You cannot add duplicate companies");
      return;
    }
    api.post("manufacturer/AddCompany", {
      CompanyId: companyToSave.value,
      ManufacturerId: mfgId,
      hideCaptiveCompanies: !ui_helpers.isSystemAdministrator(currentUser)
    }).then(response => {
      getManufacturerCompanies();
      setCompanyToSave({});
    }).catch(error => Alert.error("There was an error adding the company."));
  }

  function activateManufacturerCompany(companyManufacturer) {
    const newCompanyManufacturers = companyManufacturers.slice();
    const foundCM = _.find(newCompanyManufacturers, x => x.id === companyManufacturer.id);
    api.post("manufacturer/ActivateCompany", companyManufacturer).then(response => {
      const activated = response.data.data;
      foundCM.deactivatedAt = activated.deactivatedAt;
      foundCM.deactivatedByUserId = activated.deactivatedByUserId;
      setCompanyManufacturers(newCompanyManufacturers);
    }).catch(error => Alert.error("There was an error activating the company."));
  }

  function deactivateManufacturerCompany(companyManufacturer) {
    const newCompanyManufacturers = companyManufacturers.slice();
    const foundCM = _.find(newCompanyManufacturers, x => x.id === companyManufacturer.id);
    api.post("manufacturer/RemoveCompany", companyManufacturer).then(response => {
      const activated = response.data.data;
      foundCM.deactivatedAt = activated.deactivatedAt;
      foundCM.deactivatedByUserId = activated.deactivatedByUserId;
      setCompanyManufacturers(newCompanyManufacturers);
    }).catch(error => Alert.error("There was an error activating the company."));
  }

  return (<>
    <MfgAdmin mfgId={mfgId} tabName="COMPANIES">
      <Container fluid>
        {message.message ? <AlertBanner fade color={message.flavor}>{message.message}</AlertBanner> : null}
        <Row>
          <Col>
            <h2 className="py-2">
              <Link to={`/admin/manufacturer/${mfgId}`}>{manufacturerName}</Link> Companies
            </h2>
          </Col>
        </Row>
        <Row>
          <Col sm="12">
            <Card>
              <CardHeader>
                <h2 className="page-title">Companies</h2>
              </CardHeader>
              <CardBody>
                <Row>
                  {isSysAdmin
                    ? (<Fragment>
                        <Col xs="4">
                            <Label>Company</Label>
                            <Select
                                options={companies}
                                value={getSelectedCompany(companyToSave)}
                                onChange={(option) => setSelectedCompany(option)}
                            />
                        </Col>
                        <Col xs="1">
                            <Button
                                size="sm"
                                className="float-start btn-success cf-success mt-4"
                                onClick={() => saveCompanyToManufacturer()}
                            >
                                <FontAwesomeIcon icon="plus" color="white"/>
                            </Button>
                        </Col>
                      </Fragment>)
                    : null
                  }
                    <Col xs="6">
                        <Table size="sm">
                            <thead>
                            <tr>
                                <th>Company</th>
                                <th>Added</th>
                                <th />
                            </tr>
                            </thead>
                            <tbody>
                            {_.map(companyManufacturers, mc => (
                                <tr key={mc.id}>
                                    <td>{mc.companyName}</td>
                                    <td>{`${date_helpers.formatDateToShortDate(mc.createdAt)} by ${mc.createdByUserName}`}</td>
                                    <td>
                                        {mc.deactivatedAt === null ? (
                                            <Button
                                                size="sm"
                                                className="float-end btn-delete"
                                                onClick={() => deactivateManufacturerCompany(mc)}
                                            >
                                                <FontAwesomeIcon icon="trash-alt" />
                                            </Button>
                                        ) : (
                                            <Button
                                                size="sm"
                                                className="float-end btn-success cf-success"
                                                onClick={() => activateManufacturerCompany(mc)}
                                            >
                                                <FontAwesomeIcon color="white" icon="heartbeat" />
                                            </Button>
                                        )}
                                    </td>
                                </tr>
                            ))}
                            </tbody>
                        </Table>
                    </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </MfgAdmin>
  </>);
}