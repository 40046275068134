import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import {
  Button,
  ButtonGroup,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import styles from "../floorplanTool.module.css";
import { useActions, useAddOns, useAppState, useDisplaySettings, useExterior, useInfo } from "../hooks";
import { infoMessages } from "../infoMessages";
import { AddOn, Exterior, Grid, Rulers } from "./";
import { calculateExteriorPrice} from "../utils";
import { ExteriorProvider } from "../state/providers";
const saveSvgAsPng = require("save-svg-as-png");

function ImageModal({ isOpen, setIsOpen, saveCallback, saveAndExit, onExit }) {
  const [displaySettings] = useDisplaySettings();
  const [exterior, exteriorDispatch] = useExterior();
  const [appState, appStateDispatch] = useAppState();
  const actions = useActions({ exteriorDispatch });
  const [{ selectedAddOns, bundleOpts }] = useAddOns();
  const [, setInfo] = useInfo();
  const [padding, setPadding] = useState({
    top: displaySettings.rulerOffset + 2 * 12,
    right: 3 * 12,
    bottom: 3 * 12,
    left: displaySettings.rulerOffset + 12,
  });

  useEffect(() => {
    if (saveCallback) setInfo(infoMessages.saving);
    else setInfo(infoMessages.exporting);
    if (!isOpen) setInfo(infoMessages.default);
  }, [isOpen]);

  function handleExport() {
    saveSvgAsPng.saveSvgAsPng(
      document.getElementById("exportPreview"),
      "floorplan.png",
      {
        backgroundColor: "#fff",
        top: -padding.top,
        left: -padding.left,
        scale: 5,
      }
    );
    setIsOpen(false);
  }

  async function handleSave() {
    const uri = await saveSvgAsPng.svgAsPngUri(
      document.getElementById("savePreview"),
      {
        backgroundColor: "#fff",
        top: -padding.top,
        left: -padding.left,
        scale: 5,
      }
    );
    const {resolvedPriceTotal} = calculateExteriorPrice(exterior, selectedAddOns, bundleOpts);
    exterior.price = resolvedPriceTotal;
    exterior.porchColorId = appState.porchColorId;
    saveCallback(
      exterior,
      selectedAddOns,
      displaySettings.fontSize,
      displaySettings.rulerOffset,
      uri
    );
    setIsOpen(false);
    if (saveAndExit) onExit();
  }

  return (
    <Modal size="lg" isOpen={isOpen}>
      <ModalHeader>
        {saveCallback ? "Save Drawing" : "Export Image File"}
      </ModalHeader>
      <ModalBody>
        <div className="my-2">
          <Row>
            <h6>Set Image Padding:</h6>
            <Col>
              <Row>
                <Col xs={3}>Top</Col>
                <Col xs={9}>
                  <ButtonGroup size="sm">
                    <Button
                      color="light"
                      onClick={() =>
                        setPadding((p) => ({ ...p, top: p.top - 12 }))
                      }
                    >
                      <FontAwesomeIcon icon="minus" />
                    </Button>
                    <Button
                      color="light"
                      onClick={() =>
                        setPadding((p) => ({ ...p, top: p.top + 12 }))
                      }
                    >
                      <FontAwesomeIcon icon="plus" />
                    </Button>
                  </ButtonGroup>
                </Col>
              </Row>
              <Row>
                <Col xs={3}>Bottom</Col>
                <Col xs={9}>
                  <ButtonGroup size="sm">
                    <Button
                      color="light"
                      onClick={() =>
                        setPadding((p) => ({ ...p, bottom: p.bottom - 12 }))
                      }
                    >
                      <FontAwesomeIcon icon="minus" />
                    </Button>
                    <Button
                      color="light"
                      onClick={() =>
                        setPadding((p) => ({ ...p, bottom: p.bottom + 12 }))
                      }
                    >
                      <FontAwesomeIcon icon="plus" />
                    </Button>
                  </ButtonGroup>
                </Col>
              </Row>
            </Col>
            <Col>
              <Row>
                <Col xs={3}>Left</Col>
                <Col xs={9}>
                  <ButtonGroup size="sm">
                    <Button
                      color="light"
                      onClick={() =>
                        setPadding((p) => ({ ...p, left: p.left - 12 }))
                      }
                    >
                      <FontAwesomeIcon icon="minus" />
                    </Button>
                    <Button
                      color="light"
                      onClick={() =>
                        setPadding((p) => ({ ...p, left: p.left + 12 }))
                      }
                    >
                      <FontAwesomeIcon icon="plus" />
                    </Button>
                  </ButtonGroup>
                </Col>
              </Row>
              <Row>
                <Col xs={3}>Right</Col>
                <Col xs={9}>
                  <ButtonGroup size="sm">
                    <Button
                      color="light"
                      onClick={() =>
                        setPadding((p) => ({ ...p, right: p.right - 12 }))
                      }
                    >
                      <FontAwesomeIcon icon="minus" />
                    </Button>
                    <Button
                      color="light"
                      onClick={() =>
                        setPadding((p) => ({ ...p, right: p.right + 12 }))
                      }
                    >
                      <FontAwesomeIcon icon="plus" />
                    </Button>
                  </ButtonGroup>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
        <div style={{ border: "1px solid black" }}>
          <svg
            id={saveCallback ? "savePreview" : "exportPreview"}
            viewBox={`${-padding.left} ${-padding.top} ${
              exterior.width + padding.left + padding.right
            } ${exterior.height + padding.top + padding.bottom} `}
            style={{ backgroundColor: "#fff" }}
          >
            {displaySettings.showGrid ? <Grid /> : null}
            {displaySettings.showRulers ? (
              <Rulers displaySettings={displaySettings} exterior={exterior} />
            ) : null}
            <text
              textAnchor="middle"
              className={styles.disclaimerTxt}
              fontSize={displaySettings.fontSize}
              x={exterior.width / 2}
              y={0 - displaySettings.rulerOffset - displaySettings.fontSize * 2}
            >
              This drawing may not be exact, it is designed to show a general
              layout and placement of options.
            </text>
            <Exterior />
            {selectedAddOns.map((a) =>
              a.hide ? null : <AddOn key={a.domId} {...a} />
            )}
            <rect
              x={0}
              y={0}
              height={exterior.height}
              width={exterior.width}
              fill="rgba(0,0,0,0)"
            />
          </svg>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button color="light" onClick={() => setIsOpen(false)}>
          Cancel
        </Button>
        <Button
          color="primary"
          onClick={saveCallback ? handleSave : handleExport}
        >
          {saveCallback ? "Save" : "Export"}
        </Button>
      </ModalFooter>
    </Modal>
  );
}

export default ImageModal;
