import React, {Fragment, useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import {Loading, SubNavigationDropdown} from './';
import {
    Card,
    CardBody,
    Col,
    Container,
    DropdownItem,
    ListGroup,
    Row,
    Button,
    TabContent,
    TabPane, 
    Nav,
    NavItem,
    NavLink,
    InputGroup,
    Input,
    InputGroupText,
    CardHeader, ButtonGroup, Label, Table, Collapse, Badge, Form, FormGroup, UncontrolledTooltip
} from 'reactstrap';
import {api, constants, date_helpers, ui_helpers} from "../helpers";
import {UserContext} from "../utils";
import {DebounceInput} from "react-debounce-input";
import _ from "lodash";
import Alert from "react-s-alert-v3";
import moment from "moment";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import DatePicker from "react-datepicker";
import ToggleButton from "react-toggle-button";
import { RadioGroup, Radio } from "react-radio-group";
import {Users} from "../pages";
import Select from "react-select";
import CurrencyInput from "react-currency-input-field";

const tabNames =  constants.PRODUCT_TYPE_IDS;
const defaultTermSettings = {
    companyId: null,
    productType: null,
    contractTerm: null,
    paymentDenominator: 0,
    payOffPercent: 0,
    minimumSecurityDeposit: 0,
    minimumUnitPrice: 0,
    maximumUnitPrice: 0,
    securityDepositPercentage: 0,
    purchaseReserveThresholdPercent: 0,
    additionalSecurityDepositPercentage: 0,
    fourteenFtWideAdditionalSecurityDeposit: 0,
    sixteenFtWideAdditionalSecurityDeposit: 0,
    initialPaymentRequired: false,
    isActive: false,
}

const ContractTermDetails = ({type, term, ctDetails, onRefreshTerms}) => {
  const [companyTerm, setCompanyTerm] = useState(ctDetails);
  const [saving, setSaving] = useState(false);

  const onSave = (cTerm) => {
    if (saving) return;
    const termToSave = !cTerm ? companyTerm : cTerm;
    if (!termToSave.companyId || !termToSave.productType || !termToSave.contractTerm) return;
    let payload = Object.assign({}, termToSave);
    payload.productTypeId = payload.productType ? payload.productType.value : null;
    payload.contractTermId = payload.contractTerm ? payload.contractTerm.value : null;
    setSaving(true);
    api.post("Company/SaveCompanyTerm", payload).then(res => {
      if (res.data && res.data.success) {
        if (res.data.message) {
          setCompanyTerm({...companyTerm, id: res.data.message.id, isActive: res.data.message.isActive});
        }
        onRefreshTerms();
        Alert.success("Saved Successfully");
      } else {
        Alert.error(res.data.message || "An error occurred. Term cannot be saved at this time.")
      }
    })
    .catch(err => console.error(err))
    .finally(() => setSaving(false));
  }

  function onToggleChange(value){
    let cTerm = Object.assign({}, companyTerm);
    cTerm.isActive = value;
    setCompanyTerm(cTerm);
    if (companyTerm.id > 0) {
      onSave(cTerm);
    }
  }

  return (<>     
        {type && term && ctDetails &&                         
            <Card key={`type-${type}-term-${term.value}`} className="p-2">
                <CardHeader>
                    <Row>
                        <Col>
                            <ToggleButton 
                                inactiveLabel={<FontAwesomeIcon icon="times-circle" color="danger"/>}
                                activeLabel={<FontAwesomeIcon icon="check-circle" color="success"/>}
                                value={companyTerm.isActive}
                                onToggle={(value) => {
                                    onToggleChange(!value);
                                }}
                            />
                        </Col>
                        <Col className="text-center"><h5>{term.label}</h5></Col>
                        <Col className="float-end">{
                            <ButtonGroup className="float-end">
                                {companyTerm.collapse 
                                    ? <FontAwesomeIcon className="text-blue cursor mr-2" onClick={()=>onSave()} icon="save" size="2x" tiltle="Save" spin={saving} />
                                    : null}
                                    {" "}
                                <FontAwesomeIcon 
                                    title={`Click to ${companyTerm.collapse ? "Hide" : "Expand"}`}
                                    onClick={()=>setCompanyTerm({...companyTerm, collapse: !companyTerm.collapse})} 
                                    icon={companyTerm.collapse ? "chevron-circle-up" : "chevron-circle-down"} size="2x" className="float-end text-dark cursor ml-2"
                                />
                            </ButtonGroup> 
                            }
                        </Col>
                    </Row>
                </CardHeader>
                {companyTerm.collapse 
                    ? (<>
                        <CardBody className="">
                            <Row className="">
                                <Col md='12'>
                                    <InputGroup className="pricingCenter">
                                        <InputGroupText>
                                        <div>
                                            <Row>
                                                <Col>
                                                    <InputGroupText>
                                                        <span>
                                                            The Payment Divisor is {" "}
                                                            <input size="10" maxlenght="10" className="text-center ml-2 mr-2" value={companyTerm.paymentDenominator} onChange={(e)=>setCompanyTerm({...companyTerm, paymentDenominator: e.target.value})} />,
                                                            and the EPO % of Contract Balance is {" "}
                                                            <input size="10" maxlenght="10" className="text-center  ml-2 mr-2" value={companyTerm.payOffPercent} onChange={(e)=>setCompanyTerm({...companyTerm, payOffPercent: e.target.value})} />
                                                            <b>%</b>
                                                        </span>
                                                    </InputGroupText>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <InputGroupText>
                                                        <span className="mr-2 ml-2">The standard deposit required to open a contract is {" "}
                                                            <input size="10" maxlength="10" className="text-center" value={companyTerm.securityDepositPercentage} onChange={(e)=>setCompanyTerm({...companyTerm, securityDepositPercentage: e.target.value})} />
                                                            <b>%</b>, of unit cash price. 
                                                        </span>
                                                    </InputGroupText>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <InputGroupText>
                                                        <span className="mr-2">
                                                            <Row>
                                                                <Col>
                                                                    <ToggleButton 
                                                                        inactiveLabel={"No"}
                                                                        activeLabel={"Yes"}
                                                                        value={companyTerm.initialPaymentRequired}
                                                                        checked={companyTerm.initialPaymentRequired}
                                                                        onToggle={(value) => {
                                                                            setCompanyTerm({...companyTerm,
                                                                                initialPaymentRequired: !value,
                                                                            })
                                                                        }}
                                                                        colors={{
                                                                            inctive: {
                                                                            base: 'rgb(207,221,245)',
                                                                            hover: 'rgb(177, 191, 215)',
                                                                            },
                                                                            active: {
                                                                            base: 'rgb(1,124,66)', 
                                                                            hover: 'rgb(24,94,32)'
                                                                            }
                                                                        }}
                                                                    />
                                                                </Col>
                                                                <Col><span>initial payment is required to open a contract.</span></Col>
                                                            </Row>
                                                        </span>
                                                    </InputGroupText>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <InputGroupText>
                                                        <span>
                                                            This term is available to a minimum security deposit price of <b>$</b> 
                                                            <input size="18" maxlenght="18" className="text-center" value={companyTerm.minimumSecurityDeposit} onChange={(e)=>setCompanyTerm({...companyTerm, minimumSecurityDeposit: e.target.value})} /> 
                                                        </span>
                                                    </InputGroupText>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <InputGroupText>
                                                        <span>
                                                            <b>$</b><input size="18" maxlenght="18"  className="text-center" value={companyTerm.fourteenFtWideAdditionalSecurityDeposit} onChange={(e)=>setCompanyTerm({...companyTerm, fourteenFtWideAdditionalSecurityDeposit: e.target.value})} /> 
                                                            additional deposit is required on all 14' wide units. 
                                                        </span>
                                                    </InputGroupText>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <InputGroupText>
                                                        <span>
                                                            <b>$</b><input size="18" maxlenght="18" className="text-center" value={companyTerm.sixteenFtWideAdditionalSecurityDeposit} onChange={(e)=>setCompanyTerm({...companyTerm, sixteenFtWideAdditionalSecurityDeposit: e.target.value})} /> 
                                                            additional deposit is required on all 16' wide units. 
                                                        </span>
                                                    </InputGroupText>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <InputGroupText>
                                                        <span>
                                                            Add purchase reserve to the contract when the down payment exceeds 
                                                            <input size="10" maxlenght="10" className="text-center" value={companyTerm.purchaseReserveThresholdPercent} onChange={(e)=>setCompanyTerm({...companyTerm, purchaseReserveThresholdPercent: e.target.value})} /> 
                                                            <span><b>%</b> of unit cash price.</span>
                                                        </span>
                                                    </InputGroupText>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <InputGroupText>
                                                        <span>
                                                            This term is available to a minimum unit cash price of <b>$</b> 
                                                            <input size="18" maxlenght="18" className="text-center" value={companyTerm.minimumUnitPrice} onChange={(e)=>setCompanyTerm({...companyTerm, minimumUnitPrice: e.target.value})} /> 
                                                            <span> and a maximum of <b>$</b></span>
                                                            <input size="18" maxlenght="18" className="text-center" value={companyTerm.maximumUnitPrice} onChange={(e)=>setCompanyTerm({...companyTerm, maximumUnitPrice: e.target.value})} /> 
                                                        </span>
                                                    </InputGroupText>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <InputGroupText>
                                                        <span>
                                                            Apply 
                                                        </span>
                                                        <input size="10" maxlenght="10" className="text-center" value={companyTerm.additionalSecurityDepositPercentage} onChange={(e)=>setCompanyTerm({...companyTerm, additionalSecurityDepositPercentage: e.target.value})} /> 
                                                        <span className="mr-2 ml-2"><b>%</b> of threshold overage to down payment requirement when unit cash price exceeds maximum.</span>
                                                        <span className="mr-2 ml-2">
                                                            <FontAwesomeIcon icon="info" id="info" />
                                                            <UncontrolledTooltip target="info">To disallow contracts over the maximum unit cash price entirely, leave this field empty.</UncontrolledTooltip>
                                                        </span>
                                                    </InputGroupText>
                                                </Col>
                                            </Row>
                                            </div>
                                        </InputGroupText>
                                    </InputGroup>
                                </Col>
                            </Row>
                        </CardBody>
                        </>) 
                    : null}
            </Card>
        }
        </>
    );
};

export default function CompanyPricing(props){
  const [company, setCompany] = useState(props.company);
  const [productTypes, setProductTypes] = useState([]);
  const [activeTab, setActiveTab] = useState(tabNames.STORAGE_BUILDING)
  const [companyTerms, setCompanyTerms] = useState([]);
  const [contractTerms, setContractTerms] = useState(
    _.reject(Object.assign([], constants.CONTRACT_TERMS), t => t.value === constants.CONTRACT_TERM_TYPES.OTHER.value)
  );

  useEffect(() => {
    let types = _.reject(constants.PRODUCT_TYPES, t => t.value < 0);
    types.push(constants.OTHER_OPTION);
    setProductTypes(types);
  }, []);

  useEffect(() => {
    if (!company || !company.id|| !productTypes || !productTypes.length) return;
    getCompanyTerms();
  }, [productTypes]);

  function getCompanyTerms() {
    api.fetch(`Company/GetCompanyTermList/${company.id}`).then(res => {
      if (res.data && res.data.success) {
        if (res.data.message && res.data.message.length) {
          const terms = _.map(res.data.message, d => {
            d.contractTerm = ui_helpers.mapIdNameToValueLabel(d.contractTermId, d.contractTermName);
            d.productType = ui_helpers.mapIdNameToValueLabel(d.productTypeId, d.productTypeName);
            d.payOffPercent = (d.payoffPercent * 100).toFixed(2);
            d.purchaseReserveThresholdPercent = (d.purchaseReserveThresholdPercent * 100);
            d.securityDepositPercentage = (d.securityDepositPercentage * 100);
            d.additionalSecurityDepositPercentage = (d.additionalSecurityDepositPercentage * 100);
            return d;
          });
          setCompanyTerms(resolveCompanyTerms(terms));
        } else { //initialize contractTerms
          setCompanyTerms(resolveCompanyTerms(companyTerms));
        }
      }
    });
  }

  function saveMaxInitialPaymentPercentage() {
    let tmp = parseFloat(company.maxInitialPaymentPercent);
    if (!tmp || tmp < 0.0) {
      tmp = null;
    } else if (tmp < 1.0) {
      tmp = tmp * 100.0;
    }
    api.post("Company/SaveCompanyPricingHeader", {
      companyId: company.id,
      maxInitialPaymentPercent: tmp.toFixed(2)
    }).then(res => {
      if (!res.data.success) {
        Alert.error("Failed to save the new maximum initial payment percentage.");
      }
    });
  }

  function resolveCompanyTerms(termList){
    let list = [];  
    contractTerms.slice().forEach(term => {
      productTypes.slice().forEach(type => {
        let ex = _.find(termList, t => t.productType.value === type.value && t.contractTerm.value === term.value);
        if (!ex) {
          ex = Object.assign({}, defaultTermSettings);
          ex.companyId = company.id;
          ex.productType = type;
          ex.contractTerm = term;
          ex.collapse = false;
        }
        list.push(ex);
      });
    });
    return list;
  }

  return (<>
    {company ? (
      <Card>
        <CardHeader><h2 className="page-title">{company.name} {!props.isCaptiveCompany ? "RTO" : null} Pricing</h2></CardHeader>
        <CardBody>
            {!props.isCaptiveCompany ? 
            <>
              <Row className="mb-2">
                <Col xs="2" className="pt-2">
                  Maximum Initial Down Payment %
                </Col>
                <Col xs="1">
                  <input
                    maxLength="10"
                    className="text-center form-control"
                    value={company.maxInitialPaymentPercent} 
                    onChange={(e) => setCompany({...company, maxInitialPaymentPercent: e.target.value})}
                    onBlur={saveMaxInitialPaymentPercentage}
                  />
                </Col>
              </Row>
              <Nav tabs>
                  {productTypes.length 
                      ? productTypes.map(type =>(
                      <NavItem >
                          <NavLink
                            className={` ${activeTab === type.value ? "active" : ""}`}
                            onClick={() => setActiveTab(type.value)}
                          >
                            {type.label}
                          </NavLink>
                      </NavItem>
                  )) : null}
              </Nav>
              <TabContent activeTab={activeTab} className="mt-2">
              {productTypes.length 
                  ? productTypes.map((type, index) =>(
                      type.value === activeTab 
                      ? (<TabPane tabId={type.value} key={`type=${type.value}-${index}`}>
                          {contractTerms.length
                            ? _.map(contractTerms, (term, idx) => (
                                _.chain(companyTerms)
                                .filter(ct => ct.contractTerm.value === term.value && type.value === ct.productType.value)
                                .map(ct => (<ContractTermDetails key={`t-${term.value}`} type={type} term={term} ctDetails={ct} onRefreshTerms={getCompanyTerms} />))
                                .value()
                              ))
                            : null
                          }
                        </TabPane>)
                      : null
                      
                  )) : null}
              </TabContent>
            </>
            : (<Row className="center"><Col><b>Pricing Rules are only available to RTO Companies</b></Col></Row>)}
        </CardBody>
      </Card>) 
      : null
    }
  </>);
}

