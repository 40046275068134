import React, {useEffect, useState} from 'react';
import {Badge, Col} from 'reactstrap';
import {constants} from '../utils';
import {ui_helpers} from "../helpers";
import _ from "lodash";

export default function InventoryStatusBadge({contractStatus, inventoryStatus}) {
    let statusColor = 'bg-dark';
    let foreColor = '';
    if (contractStatus !== null && contractStatus > -1) { 
      const value =_.find(constants.CONTRACT_STATUS_NAME, c => contractStatus === c.id);
      if (value) {
        statusColor = value.color;
      }
    } else if (inventoryStatus > -1) {
      const value =_.find(constants.INVENTORY_STATUS_NAME, i => inventoryStatus === i.id);
      if (!value) {
        console.error('bad inv status', value)
      } else {
        statusColor = value.color; 
      }
    }
    switch (statusColor) {
      case 'bg-draft':
        foreColor = 'text-dark';
      default:
        break;
    }

    return (
    <div className={`badge ${statusColor}${(foreColor ? (" " + foreColor) : "")}`}>
      {contractStatus !== null && contractStatus > -1
        ? ui_helpers.formatStatusIdToName(constants.CONTRACT_STATUS_NAME, contractStatus)
        : ui_helpers.formatStatusIdToName(constants.INVENTORY_STATUS_NAME, inventoryStatus)
      }
    </div>);
}