import React, { useEffect, useMemo } from "react";
import { Button } from "reactstrap";
import { SIGNATURE_TYPES } from "../constants";
import { useAppStore, useIntersect } from "../hooks";
import { isNil } from "../utils";

export default function SignatureLocation({ signatureLocation, scale }) {
  const showOverlays = useAppStore((x) => x.showOverlays);
  const setSignatureLocationEditing = useAppStore((x) => x.setSignatureLocationEditing);
  const setSignatureModalOpen = useAppStore((x) => x.setSignatureModalOpen);
  const setSignatureLocations = useAppStore((x) => x.setSignatureLocations);
  const signatureLocations = useAppStore((x) => x.signatureLocations);
  const setIsEditingAccept = useAppStore((x) => x.setIsEditingAccept);
  const contractPaymentData = useAppStore((x) => x.contractPaymentData);
  const [ref, entry] = useIntersect({ threshold: 0.7 });

  const currentSignatureLocationIndex = useMemo(() => {
    const firstIntersecting = signatureLocations.findIndex((x) => x.isIntersecting);
    return firstIntersecting >= 0 ? firstIntersecting : 0;
  }, [signatureLocations]);

  const isActive = signatureLocations[currentSignatureLocationIndex]?.id === signatureLocation.id;

  useEffect(() => {
    setSignatureLocations([
      ...signatureLocations.filter((x) => x.id !== signatureLocation.id),
      { ...signatureLocation, isIntersecting: entry.isIntersecting },
    ]);
  }, [entry]);

  const isBinary = useMemo(
    () =>
      signatureLocation.type === SIGNATURE_TYPES.fullNameBinary ||
      signatureLocation.type === SIGNATURE_TYPES.initialBinary,
    [signatureLocation]
  );

  const index = useMemo(() =>
    signatureLocations.findIndex((x) => x.id === signatureLocation.id)
  );

  const indexStyle = {
    fontSize: "0.5rem",
    fontStyle: "italic"
  };

  const buttonStyle = {
    minWidth: "80px",
    fontSize: "0.7rem",
    padding: "2px 4px",
    zIndex: 997
  };

  const showLeft = !isBinary || (isBinary && !contractPaymentData?.hideAccept);
  const showRight = isBinary && !isNil(signatureLocation.xPosition2) && !contractPaymentData?.hideDecline;

  const scaledXPosition = signatureLocation.xPosition * scale;
  const scaledXPosition2 = signatureLocation.xPosition2 * scale;
  const scaledYPosition = signatureLocation.yPosition * scale;
  const scaledWidth = signatureLocation.width * scale;
  const scaledHeight = signatureLocation.height * scale;

  return (
    <>
      {showLeft
        ? (<div
              ref={ref}
              id={"sl-" + index}
              className={
                "d-flex align-items-end border border-3 rounded position-absolute" +
                (showOverlays
                  ? signatureLocation.img || signatureLocation.img2
                    ? " border-success"
                    : isActive
                    ? " border-danger"
                    : " border-dark"
                  : " border-0")
              }
              style={{
                top: scaledYPosition,
                left: scaledXPosition,
                width: scaledWidth,
                height: scaledHeight,
                scrollMargin: "100px",
              }}
              onClick={() => {
                setSignatureLocationEditing(signatureLocation);
                setIsEditingAccept(true);
                // else setIsEditingAccept(false);
                setSignatureModalOpen(true);
              }}
            >
              {signatureLocation.img && (
                <img
                  src={signatureLocation.img}
                  alt="Signature"
                  style={{ maxHeight: "100%", maxWidth: "100%" }}
                />
              )}
            </div>)
        : null
      }
      {showRight ? 
        (<div
          ref={ref}
          id={"sl-" + index}
          className={
            "d-flex align-items-end border border-3 rounded position-absolute" +
            (showOverlays
              ? signatureLocation.img2
                ? " border-success"
                : isActive
                ? " border-danger"
                : " border-dark"
              : " border-0")
          }
          style={{
            top: scaledYPosition,
            left: scaledXPosition2,
            width: scaledWidth,
            height: scaledHeight,
            scrollMargin: "100px",
          }}
          onClick={() => {
            setSignatureLocationEditing(signatureLocation);
            setIsEditingAccept(false);
            setSignatureModalOpen(true);
          }}
        >
          {signatureLocation.img2 && (
            <img
              src={signatureLocation.img2}
              alt="Signature"
              style={{ maxHeight: "100%", maxWidth: "100%" }}
            />
          )}
        </div>)
        : null
      }
      {showOverlays && (
        <>
          {showLeft
            ? (<Button
                color={
                  signatureLocation.img || signatureLocation.img2
                    ? "success"
                    : isActive
                    ? "danger"
                    : "dark"
                }
                size={"sm"}
                className="position-absolute shadow-sm"
                onClick={() => {
                  setSignatureLocationEditing(signatureLocation);
                  setIsEditingAccept(true);
                  // else setIsEditingAccept(false);
                  setSignatureModalOpen(true);
                }}
                style={{
                  ...buttonStyle,
                  left: !isNil(signatureLocation.parentId)
                    ? (scaledXPosition) + (scaledWidth) + "px"
                    : "-16px",
                  top: scaledYPosition - 8
                }}
              >
                {signatureLocation.type === SIGNATURE_TYPES.fullName ||
                signatureLocation.type === SIGNATURE_TYPES.fullNameBinary
                  ? signatureLocation.img || signatureLocation.img2
                    ? "Edit Signature"
                    : "Sign Here"
                  : "Initial Here"}
                {scaledXPosition2 &&
                !(signatureLocation.img || signatureLocation.img2)
                  ? " to Accept"
                  : null}
                <span style={indexStyle}>
                  {" "}
                  {signatureLocations.findIndex((x) => x.id === signatureLocation.id) + 1}
                  /{signatureLocations.length}
                </span>
              </Button>)
            : null
          }          
          {showRight
            ? (<Button
                color={
                  signatureLocation.img || signatureLocation.img2
                    ? "success"
                    : isActive
                    ? "danger"
                    : "dark"
                }
                size="sm"
                className="position-absolute shadow-sm"
                onClick={() => {
                  setSignatureLocationEditing(signatureLocation);
                  setIsEditingAccept(false);
                  setSignatureModalOpen(true);
                }}
                style={{
                  ...buttonStyle,
                  left: (scaledXPosition2) + (scaledWidth) + 8 + "px",
                  top: (scaledYPosition) - 8
                }}
              >
                {signatureLocation.type === SIGNATURE_TYPES.fullName ||
                signatureLocation.type === SIGNATURE_TYPES.fullNameBinary
                  ? signatureLocation.img || signatureLocation.img2
                    ? "Edit Signature"
                    : "Sign Here"
                  : "Initial Here"}
                {scaledXPosition2 &&
                !(signatureLocation.img || signatureLocation.img2)
                  ? " to Decline"
                  : null}
                <span style={indexStyle}>
                  {" "}
                  {signatureLocations.findIndex((x) => x.id === signatureLocation.id) + 1}
                  /{signatureLocations.length}
                </span>
              </Button>)
            : null
          }
        </>
      )}
    </>
  );
}
