import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import Alert from "react-s-alert-v3";
import 'react-tiny-fab/dist/styles.css';
import './Alert.css';
import App from './App';
import "./assets/scss/main.scss";
import "react-datepicker/dist/react-datepicker.css";
import "react-confirm-alert/src/react-confirm-alert.css";
import "@repay/react-credit-card/dist/react-credit-card.css";
import "react-s-alert-v3/dist/s-alert-default.css";
import "react-s-alert-v3/dist/s-alert-css-effects/slide.css";

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root');

ReactDOM.render(
  <BrowserRouter basename={baseUrl}>
    <Alert
      effect="slide"
      position="top-right"
      stack={{ limit: 1 }}
      timeout={4000}
      html={true}
      offset={1}
      z-index={4000}
      preserveContext
    />
    <App />
  </BrowserRouter>,
  rootElement);
