import React, {Fragment, useState, useEffect} from 'react';
import {Redirect, useParams, useHistory, useLocation, Link} from "react-router-dom";
import {
    Container, Col, Row, Card,
    CardBody, CardHeader, CardFooter, Dropdown, DropdownItem, DropdownToggle, DropdownMenu,
    Table, Button, Modal, ModalBody, ModalFooter, ModalHeader, Label, ButtonDropdown,
    Input, FormGroup, ButtonGroup, FormFeedback, CardDeck, CardTitle, CardGroup, Form
} from 'reactstrap';
import {
    AdminHeader,
    ResultHeader, MfgAdmin,
    Loading,
    ActiveToggleFilter,
    TextFilter,
    FilterSet,
    SelectFilter, FilterSearch, SubProductSKU, CloneSubproductDialog
} from "./";
import {constants} from "../utils";
import classnames from "classnames";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {api, filter_helpers, ui_helpers} from "../helpers";
import _ from "lodash";
import CurrencyInput from "react-currency-input-field";
import Select from 'react-select';
import {DebounceInput} from "react-debounce-input";
import ToggleButton from "react-toggle-button";
import {getPropertyName} from "eslint-plugin-react/lib/util/ast";
import Alert from "react-s-alert-v3";

const defaultOption = {
    minimumWidth: 0,
    maximumWidth: 0,
    incrementWidthBy: 1,
    minimumLength: 0,
    maximumLength: 0,
    incrementLengthBy: 1,
}

const defaultSubProduct = {
    name: '',
    abbr: '',
    productTypeId: 0,
    minimumWidth: 0,
    maximumWidth: 0,
    incrementWidthBy: 1,
    minimumLength: 0,
    maximumLength: 0,
    incrementLengthBy: 1,
}

const defaultSubProductAddOn = {
    addOnId: 0,
    manufacturerSubProductId: 0,
    defaultWall: null,
    defaultX: null,
    defaultY: null
};

const SubProductRow = ({s, editCallback, productTypes, addOns, subProductAddOns, editSKUCallback, onClone}) => {
  let productName = '';
  if (s && productTypes) {
    const foundElement = _.find(productTypes, p => p.id === s.productTypeId);
    if (foundElement) {
      productName = foundElement.name;
    }
  }

  return (
    <tr key={`sp-${s.id}-${s.manufacturerId}`}>
      <td>{s.name}</td>
      <td>{s.abbr}</td>
      <td>{productName}</td>
      <td>
        <ButtonGroup className="float-end">
          <Button
            size={"sm"}
            className="bg-secondary"
            onClick={() => editCallback(s)}>
            <FontAwesomeIcon icon={"edit"} /> Edit
          </Button>
          <Button color="dark" onClick={() => onClone(s.id)}>
            <FontAwesomeIcon icon='clone' /> Clone
          </Button>
          <Button
            size={"sm"}
            className="bg-primary"
            onClick={() => editSKUCallback(s)}>
            <FontAwesomeIcon icon='barcode' /> SKU
          </Button>
        </ButtonGroup>
      </td>
    </tr>);
};

export default function ManufacturerSubProducts(props) {
const {mfgId} = useParams();
const [message, setMessage] = useState(constants.ALERT_OBJECT);
const [productTypes, setProductTypes] = useState([]);
const [subProducts, setSubProducts] = useState([]);
const [subProduct, setSubProduct] = useState(defaultSubProduct);
const [subProductId, setSubProductId] = useState(0);
const [isEditing, setIsEditing] = useState(false);
const [loading, setLoading] = useState(false);
const [subProductModalOpen, setSubProductModalOpen] = useState(false);
const [filters, setFilters] = useState([]);
const [clear, setClear] = useState(false);
const [canClear, setCanClear] = useState(false);
const [active, setActive] = useState(true);
const [addOnAssociations, setAddOnAssociations] = useState([]);
const [addOns, setAddOns] = useState([]);
const [sizeOptions, setSizeOptions] = useState([]);
const [incrementOptions, setIncrementOptions] = useState([]);
const [isEditingSubProduct, setIsEditingSubProduct] = useState(false);
const [isEditingSKU, setIsEditingSKU] = useState(false);
const [selectedSubproductIds, setSelectedSubproductIds] = useState([]);
const [isCloning, setIsCloning] = useState(null);

//Validation
const [canShowValidationErrors, setCanShowValidationErrors] = useState(false);
const [nameWarning, setNameWarning] = useState(false);
const [abbrWarning, setAbbrWarning] = useState(false);
const [basePriceWarning, setBasePriceWarning] = useState(false);
const [productTypeWarning, setProductTypeWarning] = useState(false);
const [manufacturerName, setManufacturerName] = useState('');
const history = useHistory();
const PATHNAMES = constants.PATH_NAMES;

useEffect(() => {
  getProductTypes();
  getSizeSelectOptions();
  getIncrementBySelection();
  return cleanUpPage;
}, []);

const cleanUpPage = () => {
  setSubProduct(defaultSubProduct);
  setIsEditingSKU(false);
  setIsEditingSubProduct(false);
};

const getAddOns = () => {
  const payload = {
    id: mfgId,
    activeOnly: true,
  };
  api.post(`manufacturer/ListManufacturerAddons`, payload).then(r => {
    if (!r.data || !r.data.success) {
      setMessage({message: 'There was an error fetching the Add Ons.', flavor: constants.flavor.error});
    }
    else {
      setAddOns(r.data.data);
    }
  });
};

const refreshSubProducts = () => {
  if (loading) return;
  setLoading(true);
  const payload = {
    manufacturerId: mfgId,
    activeOnly: active,
    name: null,
    productTypeId: null
  };
  _.each(filters, filter => payload[filter.filterName] = filter.value);
  api.post('manufacturer/ListOfSubProducts', payload).then(r => {
    if(!r.data.success) {
      Alert.error("There was an error retrieving the Sub Product List");
      return;
    }
    setManufacturerName(r.data.message.manufacturerName);
    setSubProducts(r.data.message.subProductList);
  }).catch((err) => console.error(err))
  .finally(() => setLoading(false));
};

const saveSubProduct = () => {
  const payload = {
    id: subProduct.id,
    manufacturerId: mfgId,
    name: subProduct.name,
    abbr: subProduct.abbr,
    productTypeId: subProduct.productTypeId,
    minimumWidth: subProduct.minimumWidth,
    maximumWidth: subProduct.maximumWidth,
    incrementWidthByAmount: subProduct.incrementWidthBy,
    minimumLength: subProduct.minimumLength,
    maximumLength: subProduct.maximumLength,
    incrementLengthByAmount: subProduct.incrementLengthBy
  };
  if (!validatePayload(payload)) return;
  api.post('manufacturer/SaveSubProduct', payload).then(r => {
    if(!r.data.success)  setMessage({message: r.data.message, flavor: constants.flavor.error});
    refreshSubProducts();
    closeModal();
  });
};

useEffect(refreshSubProducts, [filters, clear, active]);

useEffect(() => {
  setTimeout(() => {
    setMessage(constants.ALERT_OBJECT);
  }, 1000)
}, [message.message]);

const getProductTypes = () => {
  api.fetch('manufacturer/ListProductTypes').then(r => {
    if (!r.data)
      setMessage({message: 'Was not able to retrieve list of Product Types.', flavor: constants.flavor.error});
    setProductTypes(r.data);
  });
};

    const onSubProductChange = (fieldName, fieldValue) => {
        let newSubProduct = Object.assign({}, subProduct);
        if(fieldName === 'minimumWidth' && newSubProduct.maximumWidth > 0){
            if(fieldValue > newSubProduct.maximumWidth) {
                Alert.warning('Your minimum selection cannot be higher than your maximum');
                return;
            }
        }
        if(fieldName === 'maximumWidth' && newSubProduct.minimumWidth > 0) {
            if(fieldValue < newSubProduct.minimumWidth) {
                Alert.warning('Your maximum selection cannot be lower than your minimum');
                return;
            }
        }
        if(fieldName === 'minimumLength' && newSubProduct.maximumLength > 0){
            if(fieldValue > newSubProduct.maximumLength) {
                Alert.warning('Your minimum selection cannot be higher than your maximum');
                return;
            }
        }
        if(fieldName === 'maximumLength' && newSubProduct.minimumLength > 0) {
            if(fieldValue < newSubProduct.minimumLength) {
                Alert.warning('Your maximum selection cannot be lower than your minimum');
                return;
            }
        }
        newSubProduct[fieldName] = fieldValue;
        setSubProduct(newSubProduct);
    };

    const onSubProductSelectChange = (fieldName, option) => {
      let newSubProduct = Object.assign({}, subProduct);
      newSubProduct[fieldName] = option.value;
      setSubProduct(newSubProduct);
    };

    const closeModal = () => {
        setSubProductModalOpen(false);
        setSubProduct(defaultSubProduct);
        resetValidation();
    }

    const editSubProduct = (sp) => {
      setSubProduct(sp);
      setIsEditingSubProduct(true);
    };

    const editSKU = (sp) => {
      setSubProduct(sp);
      setIsEditingSKU(true);
    };

    const backToSubProduct = (sp) => {
      setSubProduct(defaultSubProduct);
      setIsEditingSubProduct(false);
      setIsEditingSKU(false);
    };

    const getSizeSelectOptions = () => {
      let options = [];
      for(let i = 0; i < 101; i++) {
        options.push({ label: i, value: i });
      }
      setSizeOptions(options);
    };

    const getIncrementBySelection = () => {
      let incrementList = [];
      for(let i = 1; i < 7; i++) {
        incrementList.push({ label: i, value: i });
      }
      setIncrementOptions(incrementList);
    };

    const validatePayload = (payload) => {
      let warnings = [];
      // if(payload.minimumWidth === 0) {
      //     warnings.push('Minimum Width can not be 0');
      // }
      // if(payload.maximumWidth === 0) {
      //     warnings.push('Maximum Width can not be 0');
      // }
      if (payload.minimumLength === 0) {
        warnings.push('Minimum Length can not be 0');
      }
      if (payload.maximumLength === 0) {
        warnings.push('Maximum Length can not be 0');
      }
      if (!subProduct.name.length > 0 || subProduct.name === "") {
        warnings.push('Product name cannot be empty.');
        setNameWarning(true);
      }
      if(!subProduct.abbr.length > 0 || subProduct.abbr === "") {
        warnings.push('Product abbreviation cannot be empty.');
        setAbbrWarning(true);
      }
      if (!subProduct.productTypeId) {
        warnings.push('You must select a product type.');
        setProductTypeWarning(true);
      }
      if (warnings.length > 0) {
        Alert.warning(warnings.join('. '));
        return false;
      }
      return true;
    };

    const resetValidation = () => {
      setCanShowValidationErrors(false);
      setNameWarning(false);
      setAbbrWarning(false);
      setProductTypeWarning(false);
    };

    function onFilterChange(changedFilter) {
      const filterChanges = filter_helpers.getFilters(filters, changedFilter);
      if (filterChanges.length === 0)
        setCanClear(false);
      else if(filterChanges.length > 0)
        setCanClear(true);

      setFilters(filterChanges);
    }

    const clearFilters = () => {
      setFilters([]);
    };

  function onSelectSubproductId(e, spId) {
    e.stopPropagation();
    let newList = selectedSubproductIds.slice();
    if (newList.includes(spId)) {
      newList = _.reject(newList, x => x === spId);
    } else {
      newList.push(spId);
    }
    setSelectedSubproductIds(newList);
  }

    if (isEditingSKU && subProduct) {
      return (<Redirect to={`/admin/manufacturer/${mfgId}/${subProduct.id}/skus`} />);
    }

    if (isEditingSubProduct) {
      return (<Redirect to={`/admin/manufacturer/${mfgId}/${subProduct.id}/edit`} />);
    }

    return (
    <Fragment>
      <MfgAdmin mfgId={mfgId} tabName="PRODUCTS">
        <Container fluid>
  {isCloning
    ? (<CloneSubproductDialog
        itemList={_.filter(subProducts, x => x.id === isCloning)}
        onClose={() => {
          setIsCloning(null);
          refreshSubProducts();
        }}
      />)
    : null
  }
          <Row>
            <Col className="ms-0" xs="6" md="5">
              <h2 className="ps-3 py-2">
                <Link to={`/admin/manufacturer/${mfgId}`}>{manufacturerName}</Link> Products
              </h2>
            </Col>
            <Col>
              <FilterSearch canClear={canClear} onClear={clearFilters}>
                  <Row>
                      <Col>
                          <SelectFilter displayName='Product Types' filterName='productTypeId' options={productTypes}
                                        onChangeCallback={onFilterChange}
                                        value={filter_helpers.get_value('productTypeId', filters)} isSingleSelect={true}/>
                      </Col>
                      <Col>
                          <TextFilter
                              filterName="name"
                              displayName="Sub Product Name"
                              descriptionPrefix="contains"
                              value={filter_helpers.get_value('name', filters)}
                              onChangeCallback={onFilterChange}
                          />
                      </Col>
                      <Col>
                          <Label>Active</Label>
                          <ToggleButton
                              value={active}
                              activeLabel={"Yes"}
                              inactiveLabel={"No"}
                              onToggle={(v) => setActive(!v)}
                          />
                      </Col>
                  </Row>
              </FilterSearch>
            </Col>
          </Row>
          {loading 
          ? <Loading/>
          : (<Card>
              <CardHeader>
                <Row>
                  <Col sm={9}>
                    <ResultHeader heading='Products' totalResults={subProducts ? subProducts.length : 0}/>
                  </Col>
                  <Col sm={3}>
                    <ButtonGroup className="float-end">
                      <Button className='bg-success border-success float-end' onClick={() => setSubProductModalOpen(true)}>
                        <FontAwesomeIcon icon='plus' />
                      </Button>
                    </ButtonGroup>
                  </Col>
                </Row>
              </CardHeader>
                  <Table bordered striped>
                      <thead>
                      <tr>
                        <th>
                          Name
                        </th>
                        <th>
                          Abbr
                        </th>
                        <th>
                          Product Type
                        </th>
                        <th />
                      </tr>
                      </thead>
                      <tbody>
                      {subProducts && subProducts.length ? _.map(subProducts, s => 
                        (<SubProductRow
                          key={s.id}
                          s={s}
                          editCallback={editSubProduct}
                          editSKUCallback={editSKU}
                          productTypes={productTypes}
                          onReturnCallback={backToSubProduct}
                          onClone={(spId) => setIsCloning(spId)}
                        />))
                        : null
                      }
                      </tbody>
                  </Table>
              </Card>)
            }
            <Modal centered isOpen={subProductModalOpen} style={{zIndex: 2}} size={'lg'} scrollable={false}>
              <ModalHeader>
                {isEditing ? `Editing ${subProduct.name}` : 'Add New Product'}
              </ModalHeader>
              <ModalBody>
                <Form>
                    <FormGroup row>
                        <Col sm={8}>
                            <Label>Name {ui_helpers.requiredStar()}</Label>
                            <Input type='text' name='name' value={subProduct.name}
                                   onChange={(e) =>
                                       onSubProductChange(e.target.name, e.target.value)}
                                   invalid={canShowValidationErrors && nameWarning}/>
                            <FormFeedback>Please enter a name for this Item.</FormFeedback>
                        </Col>
                        <Col>
                            <Label>Abbreviation {ui_helpers.requiredStar()}</Label>
                            <Input type='text' name='abbr' value={subProduct.abbr}
                                   onChange={(e) =>
                                       onSubProductChange(e.target.name, e.target.value)}
                                   maxLength={10}
                                   invalid={canShowValidationErrors && abbrWarning}/>
                            <FormFeedback>Please enter an abbreviation for this Item.</FormFeedback>
                        </Col>
                    </FormGroup>
                    <FormGroup>
                        <Col>
                            <Label>Product Type {ui_helpers.requiredStar()}</Label>
                            <Select options={_.map(productTypes, p => {
                                p.value = p.id;
                                p.label = p.name;
                                return p;
                            })} name='productTypeId'
                                    value={_.find(productTypes, p => {
                                        return p.id === subProduct.productTypeId;
                                    })} onChange={(option) =>
                                onSubProductSelectChange('productTypeId', option)}/>
                        </Col>
                    </FormGroup>
                    <hr/>
                    <FormGroup row>
                        <Col sm={4}>
                            <Label>Minimum Width</Label>
                            <Select
                              options={sizeOptions}
                              value={_.find(sizeOptions, s => s.value === subProduct.minimumWidth)}
                              onChange={(option) => onSubProductChange('minimumWidth', option.value)}
                            />
                        </Col>
                        <Col sm={4}>
                            <Label>Maximum Width</Label>
                            <Select
                              options={sizeOptions}
                              value={_.find(sizeOptions, s => s.value === subProduct.maximumWidth)}
                              onChange={(option) => onSubProductChange('maximumWidth', option.value)}
                            />
                        </Col>
                        <Col sm={3}>
                            <Label>Increment Width By</Label>
                            <Select options={incrementOptions} value={_.find(incrementOptions, i => {
                                return i.value === subProduct.incrementWidthBy;
                            })} onChange={(option) => onSubProductChange('incrementWidthBy', option.value)}/>
                        </Col>
                    </FormGroup>
                    <hr/>
                    <FormGroup row>
                        <Col sm={4}>
                            <Label>Minimum Length</Label>
                            <Select options={sizeOptions} value={_.find(sizeOptions, s => {
                                return s.value === subProduct.minimumLength;
                            })} onChange={(option) => onSubProductChange('minimumLength', option.value)}/>
                        </Col>
                        <Col sm={4}>
                            <Label>Maximum Length</Label>
                            <Select options={sizeOptions} value={_.find(sizeOptions, s => {
                                return s.value === subProduct.maximumLength;
                            })} onChange={(option) => onSubProductChange('maximumLength', option.value)}/>
                        </Col>
                        <Col sm={3}>
                            <Label>Increment Length By</Label>
                            <Select options={incrementOptions} value={_.find(incrementOptions, i => {
                                return i.value === subProduct.incrementLengthBy;
                            })} onChange={(option) => onSubProductChange('incrementLengthBy', option.value)}/>
                        </Col>
                    </FormGroup>
                </Form>
              </ModalBody>
              <ModalFooter>
                <Row>
                  <Col>
                    <ButtonGroup className='float-end'>
                      <Button color='secondary' onClick={closeModal}>
                        Cancel
                      </Button>
                      <Button color='primary' onClick={saveSubProduct}>
                        Save
                      </Button>
                    </ButtonGroup>
                  </Col>
                </Row>
              </ModalFooter>
            </Modal>
        </Container>
      </MfgAdmin>
    </Fragment>);
}