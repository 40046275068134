function useActions({
  appStateDispatch,
  displaySettingsDispatch,
  exteriorDispatch,
  addOnsDispatch,
}) {
  let actions = {};

  if (appStateDispatch) {
    actions.toggleEditingPorch = () =>
      appStateDispatch({ type: "TOGGLE_EDITING_PORCH" });
    actions.setIsMovingAddOn = (payload) =>
      appStateDispatch({ type: "SET_IS_MOVING_ADDON", payload });
    actions.setIsRotatingAddOn = (payload) =>
      appStateDispatch({ type: "SET_IS_ROTATING_ADDON", payload });
    actions.setIsResizingAddOn = (payload) =>
      appStateDispatch({ type: "SET_IS_RESIZING_ADDON", payload });
    actions.setIsDraggingPorchHandle = (payload) =>
      appStateDispatch({ type: "SET_IS_DRAGGING_PORCH_HANDLE", payload });
    actions.setPorchColorId = (payload) =>
      appStateDispatch({ type: "SET_PORCH_COLOR", payload });
  }

  if (displaySettingsDispatch) {
    actions.incrementFont = () =>
      displaySettingsDispatch({ type: "INCREMENT_FONT" });
    actions.decrementFont = () =>
      displaySettingsDispatch({ type: "DECREMENT_FONT" });
    actions.toggleGrid = () => displaySettingsDispatch({ type: "TOGGLE_GRID" });
    actions.toggleRulers = () =>
      displaySettingsDispatch({ type: "TOGGLE_RULERS" });
    actions.setRulerOffset = (payload) =>
      displaySettingsDispatch({ type: "SET_RULER_OFFSET", payload });
  }

  if (exteriorDispatch) {
    actions.setExtHeight = (payload) =>
      exteriorDispatch({ type: "SET_EXT_HEIGHT", payload });
    actions.setExtWidth = (payload) =>
      exteriorDispatch({ type: "SET_EXT_WIDTH", payload });
    actions.setPorchType = (payload) =>
      exteriorDispatch({ type: "SET_PORCH_TYPE", payload });
    actions.setPorchMin = (payload) =>
      exteriorDispatch({ type: "SET_PORCH_MIN", payload });
    actions.setPorchMax = (payload) =>
      exteriorDispatch({ type: "SET_PORCH_MAX", payload });
    actions.setPorchWall1Length = (payload) =>
      exteriorDispatch({ type: "SET_PORCH_WALL1_LENGTH", payload });
    actions.setPorchWall2Length = (payload) =>
      exteriorDispatch({ type: "SET_PORCH_WALL2_LENGTH", payload });
    actions.setPorchWall3Length = (payload) =>
      exteriorDispatch({ type: "SET_PORCH_WALL3_LENGTH", payload });
    actions.setPorchWall4Length = (payload) =>
      exteriorDispatch({ type: "SET_PORCH_WALL4_LENGTH", payload });
    actions.setExtPrice = (payload) => 
      exteriorDispatch({ type: "SET_EXT_PRICE", payload });
  }

  if (addOnsDispatch) {
    actions.addAddOn = (payload) =>
      addOnsDispatch({ type: "ADD_ADD_ON", payload });
    actions.removeAddOn = (payload) =>
      addOnsDispatch({ type: "REMOVE_ADD_ON", payload });
    actions.removeLast = (payload) =>
      addOnsDispatch({ type: "REMOVE_LAST", payload });
    actions.updateAddOn = (payload) =>
      addOnsDispatch({ type: "UPDATE_ADD_ON", payload });
    actions.showAll = () => addOnsDispatch({ type: "SHOW_ALL" });
    actions.hideAll = () => addOnsDispatch({ type: "HIDE_ALL" });
  }

  return actions;
}

export default useActions;
