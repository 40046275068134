import React, {Fragment, useContext, useLayoutEffect, useState} from 'react';
import { Link, Redirect } from "react-router-dom";
import { Container, Row, Col, Button } from "reactstrap";
import { api, ui_helpers, constants } from "../helpers";
import {BackButton, Footer, Header} from '../components';
import _ from 'lodash';
import Alert from "react-s-alert-v3";
import {UserContext} from "../utils";
import {library} from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import userContext from "../utils/userContext";
const PATHNAMES = constants.PATH_NAMES;

const renderTile = (menuOption) => {
    return (
        <Col xs="6" sm="4" className="px-4" key={`${menuOption.title} - ${menuOption.routePath}`}>
            <Link
                to={menuOption.routePath}
                // to={{
                //     pathname: menuOption.routePath,
                //     tab: menuOption.tabName
                // }}
                className="no-decoration"
            >
                <Row className="expand-md dash-panel mb-3">
                    <Col sm="3" className="icon-col me-0 float-right pe-0">
                        <FontAwesomeIcon
                            size="4x"
                            icon={menuOption.iconKey}
                            className="dashboardIcon"
                        />
                    </Col>
                    <Col>
                        <div className="title">{menuOption.title}</div>
                    </Col>
                </Row>
            </Link>
        </Col>);
};
const resolveUserMenuOptions = (user) => {
    let list = [];
    const isSysAdmin = ui_helpers.isSystemAdministrator(user);
    const isMfgAdmin = ui_helpers.isAnyManufacturerAdministrator(user);
    // const isCompanyAdmin = ui_helpers.isAnyCompanyAdministrator(user);
    const isRTOCompanyAdmin = ui_helpers.isAnyRTOCompanyAdmin(user);
    const isDealerAdmin = ui_helpers.isAnyDealerAdministrator(user);
    if (isSysAdmin || isMfgAdmin) {
        let route = ui_helpers.roleContextRouteId('mfg', user);
        list.push({
            title: "Manufacturers",
            routePath: route,
            iconKey: "industry"
        });
    }
    if (isSysAdmin || isRTOCompanyAdmin) {
        list.push({
            title: "Companies",
            routePath: ui_helpers.roleContextRouteId('company', user),
            iconKey: "building"
        });
    }
    if (isSysAdmin || isDealerAdmin) {
        list.push({
            title: "Stores",
            routePath: ui_helpers.roleContextRouteId('dealer', user),
            iconKey: "store"
        });
    }
    if (isSysAdmin) {
        list.push({
            title: "System Admin",
            routePath: "/admin/Users",
            iconKey: "globe"
        });
    }
    return list;
};
export default function AdminLanding(props){
    const userCtx = useContext(UserContext);
    const [menuOptions, setMenuOptions] = useState(resolveUserMenuOptions(userCtx.currentUser));
    const [hasAccess, setHasAccess] = useState();
    if (!userCtx?.currentUser) return <div />;
    if (menuOptions.length === 1) {
        return (<Redirect to={menuOptions[0].routePath} />);
    }
    return (
        <Fragment>
            <Header/>
            <Container fluid className="pt-3">
                <Row>
                    <Col>
                        <h2>Hi {userCtx.currentUser.firstName} {userCtx.currentUser.lastName}</h2>
                    </Col>
                </Row>
                <Row className="mt-4">
                    {_.map(menuOptions, m => renderTile(m))}
                </Row>
            </Container>
            <Footer/>
        </Fragment>
    );
}