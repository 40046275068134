import React from 'react';
import {Button} from 'reactstrap';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import ReactTooltip from "react-tooltip";
import {constants} from "../utils";
import {ui_helpers} from "../helpers";

export default function FollowUpButtons(props) {
let text;
let icon;
switch (props.followUpItem)
{
    case constants.FOLLOW_UP.RTO:
        icon = 'desktop';
        text = {
          notComplete: 'This unit has NOT been loaded to RTO',
          complete: 'This unit has been loaded to RTO',
        };
        break;
    case constants.FOLLOW_UP.CALL:
        icon = 'phone-alt';
        text = {
            notComplete: 'No courtesy call has been placed.',
            complete: 'A courtesy call has been placed.',
        };
        break;
    case constants.FOLLOW_UP.HOLD:
        icon = 'hand-paper';
        text = {
            notComplete: 'This contract not on hold.',
            complete: 'This contract is on hold.',
        };
        break;
    case constants.FOLLOW_UP.COMMISSION:
        icon = 'dollar-sign';
        text = {
            notComplete: 'Commission has not been paid.',
            complete: 'Commission has been paid.',
        };
        break;
}
const key = `tt-${props.followUpItem}`;
const bgClass = ui_helpers.followUpBackground(props.followUpItem, props.done);
return (
    <div>
      {props.followUpItem ?
        <>
          <Button className={`rounded bg-${bgClass}`} id='rto-button' data-tip data-for={key}
              onClick={() => props.followUpStatusCallback(props.followUpItem, !props.done)}>
            <FontAwesomeIcon icon={icon} style={{color: (props.done ? '#fff' : '#000')}}/>
          </Button>
          <ReactTooltip
            className='follow-opacity'
            place='bottom'
            id={key}
            type={ui_helpers.resolveTooltipTypeByBgColor(bgClass)}
            effect='solid'
          >
            <p style={{fontSize: '1rem'}}>{props.done ? text.complete : text.notComplete}</p>
          </ReactTooltip>
        </>
        : null
      }
    </div>);
}