import React from "react";
import { Table } from "reactstrap";

const DetailsTable = ({ children, className }) => {
  const classes = className
	? "details-table " + className 
	: "details-table";
  return (
      <Table size="sm" className={classes}>
          <tbody>{children}</tbody>
      </Table>
  );
};

export default DetailsTable;
