import React, {Fragment, useState, useContext, useEffect} from "react";
import {
  Table,
  Card,
  CardBody,
  CardHeader,
  Row,
  Col,
  Container,
  ButtonGroup,
  Button,
  Label,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Modal,
  Input,
  Form,
  FormGroup, Nav, NavItem, NavLink
} from 'reactstrap';
import _ from 'lodash';
import {api} from "../utils";
import Alert from "react-s-alert-v3";
import CurrencyInput from "react-currency-input-field";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    ActiveToggleFilter,
    EditSkuAddOnOpts, EditSkuBundleOpts,
    EditSKUInfo,
    EditSubProductInfo,
    EditSubProductUnitTypes,
    FilterSearch,
    Loading, 
    SelectFilter, SortHeader,
    TextFilter, EditSKUAddOnsLayout, MfgAdmin, CloneSkuDialog
} from './';
import ToggleButton from "react-toggle-button";
import { confirmAlert } from "react-confirm-alert";
import {filter_helpers, ui_helpers} from "../helpers";
import classNames from "classnames";
import {useParams, Link, Redirect, useHistory} from "react-router-dom";

const SubProductSkuRow = ({sku, fetchSKUList, showTemplate, handleEditSKU, index, isSelected, onSelectSkuId}) => {
    const [price, setPrice] = useState(sku.basePrice);
    const [name, setName] = useState(sku.name);
    const [isEditing, setIsEditing] = useState(false);
    const [skuItem, setSkuItem] = useState({});
    const [updated, setUpdated] = useState();
    const [tainted, setTainted] = useState(false);
    const history = useHistory();
    useEffect(() => {
      setSkuItem(sku);
    }, [sku]);

    function onBlur(e) {
        if(!tainted) return;
        let payload = Object.assign({}, sku);
        payload.basePrice = parseFloat(price);
        payload.name = name;
        api.post('manufacturer/SaveSubProductSku', payload)
            .then(r => {
                if(!r.data.success) return;
                setTainted(false);
            }).catch((err) => console.error(err));
    }

    const updateSKUName = (e) => {
        const value = e.target.value;
      if(value != sku.name) setTainted(true);
      setName(value);
    };

    const updateSKUPrice = (value) => {
        if(value != sku.basePrice) setTainted(true);
        setPrice(value);
    };

    const onSKUChange = (fieldName, fieldValue) => {
        let updateSKU = Object.assign({}, skuItem);
        updateSKU[fieldName] = fieldValue;
        setSkuItem(updateSKU);
    };

    return (
      <tr className={classNames({'deactivated-row': skuItem.deactivatedAt, selected: isSelected}, 'selectable-row')} key={`sk-${index}`}>
        <td>
          {skuItem.deactivatedAt
            ? null
            : (<Label check>
                <Input type="checkbox" checked={isSelected} onChange={(e) => onSelectSkuId(e, sku.id)} />
              </Label>)
          }
        </td>
        <td><Input type={'text'} value={name} onChange={updateSKUName} onBlur={onBlur}/></td>
        <td>{`${sku.width} x ${sku.length}`}</td>
        <td>{ui_helpers.formatCurrency(sku.defaultPrice === 0 ? price : sku.defaultPrice)}</td>
        <td>
            <CurrencyInput value={price} onBlur={onBlur}
             className={'form-control'}
             onValueChange={updateSKUPrice}
             disabled={skuItem.deactivatedAt}
             decimalsLimit={2} prefix={'$'} tabIndex={index + 1}
            />
        </td>
        <td>
          <Button onClick={() => handleEditSKU(skuItem)} tabIndex={-1} color="primary" className='float-end'>
            <FontAwesomeIcon icon='arrow-right' />
          </Button>
      </td>
    </tr>);
};

const defaultSKU = {
    id: 0,
    basePrice: 0.00,
    width: 0,
    length: 0,
    name: null,
    manufacturerSubProductId: 0,
    deactivatedAt: null,
    manufacturerId: 0
};

const defaultOption = {
    minimumWidth: 0,
    maximumWidth: 0,
    incrementWidthBy: 1,
    minimumLength: 0,
    maximumLength: 0,
    incrementLengthBy: 1
};

const tabs = [
    {title: "SKU Info", buildOnly: false},
    {title: "Add-On Options", buildOnly: false},
    {title: "Default Add-Ons & Layout", buildOnly: true},
    {title: "Bundle Options", buildOnly: false}
];

const EditSubProductSKU = ({onReturnCallback, skuToEdit, fetchSKUList, mfgId, activeTabIndex,
  manufacturerName, subProductId, subProductName, enabledBuild}) => {
  const [sku, setSKU] = useState(null);
  const [activeTab, setActiveTab] = useState(tabs[activeTabIndex || 0]);
  const redirctUrl = `/admin/manufacturer/${mfgId}`;
  useEffect(() => {
    if(!skuToEdit) return;
    setSKU(skuToEdit);
  }, [skuToEdit]);

    function getNavLinks(tab, idx) {
        if(!enabledBuild) {
            if(tab.buildOnly) return;
            return (
                <NavItem key={idx}>
                    <NavLink
                        style={{ cursor: "pointer" }}
                        active={activeTab === tab}
                        onClick={() => setActiveTab(tab)}
                    >
                        {tab.title}
                    </NavLink>
                </NavItem>
            );
        }
        return (
            <NavItem key={idx}>
                <NavLink
                    style={{ cursor: "pointer" }}
                    active={activeTab === tab}
                    onClick={() => setActiveTab(tab)}
                >
                    {tab.title}
                </NavLink>
            </NavItem>
        );
    }

  return (<>
      <Row>
        <h2 className="py-2">
          <Link to={`/admin/manufacturer/${mfgId}`}>{manufacturerName}</Link> / {subProductName} / {sku ? sku.name : ""}
        </h2>
      </Row>
      <Card>
          <CardHeader>
              <Row>
                  <Col sm={2}>
                      <Button onClick={onReturnCallback}>
                          <FontAwesomeIcon icon={'arrow-left'}/> SKU List
                      </Button>
                  </Col>
              </Row>
          </CardHeader>
          <CardBody>
              <Row>
                  <Col xs={2}>
                    <Nav vertical pills>
                      {_.map(tabs, (t, idx) =>  getNavLinks(t, idx))}
                    </Nav>
                  </Col>
                  <Col xs={10}>
                      {activeTab === tabs[0] && (
                        <EditSKUInfo sku={sku} fetchSKUList={fetchSKUList} />
                      )}
                      {activeTab === tabs[1] && (
                        <EditSkuAddOnOpts sku={sku} subProductId={subProductId} mfgId={mfgId} />
                      )}
                      {activeTab === tabs[2] && enabledBuild && (
                        <EditSKUAddOnsLayout sku={sku} mfgId={mfgId} />
                      )}
                      {activeTab === tabs[3] && (
                        <EditSkuBundleOpts sku={sku} subProductId={subProductId} mfgId={mfgId} />
                      )}
                  </Col>
              </Row>
          </CardBody>
      </Card>
  </>);
}

export default function SubProductSKU(props) {
  const {activeTabIndex} = useParams();
  const [skuList, setSkuList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [subProductSizeOptions, setSubProductSizeOptions] = useState(defaultOption);
  const [activeOnly, setActiveOnly] = useState(true);
  const [sku, setSKU] = useState(defaultSKU);
  const [skuTainted, setSkuTainted] = useState(false);
  const [isEditingSKU, setIsEditingSKU] = useState(false);
  const [filters, setFilters] = useState([]);
  const [canClear, setCanClear] = useState(false);
  const [clear, setClear] = useState(false);
  const [showAddSKU, setShowAddSKU] = useState(false);
  const [showGenSkuModal , setShowGenSkuModal] = useState(false);
  const [sortDirection, setSortDirection] = useState('asc');
  const [sortColumn, setSortColumn] = useState('WL');
  const [manufacturerName, setManufacturerName] = useState('');
  const [subProductName, setSubProductName] = useState('');
  const [enabledBuild, setEnabledBuild] = useState(false);
  const [selectedSkuIds, setSelectedSkuIds] = useState([]);
  const [selectedMenuOpen, setSelectedMenuOpen] = useState(false);
  const [isCloning, setIsCloning] = useState(false);
  const [subproducts, setSubproducts] = useState([]);
  const {mfgId, subProductId} = useParams();

  useEffect(() => {
    api.fetch(`Manufacturer/GetSubProductSizeOptions/${subProductId}`).then((res) => {
      setSubProductName(res.data.data.subProductName);
      setManufacturerName(res.data.data.manufacturerName);
      setEnabledBuild(res.data.data.enableBuild || res.data.data.enableBuildPreview);
      setSubProductSizeOptions({
        minimumWidth: res.data.data.minimumWidth,
        maximumWidth: res.data.data.maximumWidth,
        incrementWidthBy: res.data.data.incrementWidthByAmount,
        minimumLength: res.data.data.minimumLength,
        maximumLength: res.data.data.maximumLength,
        incrementLengthBy: res.data.data.incrementLengthByAmount
      });
    });
  }, []);

  useEffect(() => {
    if(isLoading) return;
    getSubproductSkus();
  }, [filters, sortColumn, sortDirection]);

  const onSKUChange = (fieldName, fieldValue) => {
    setSkuTainted(true);
    let updateSKU = Object.assign({}, sku);
    updateSKU[fieldName] = fieldValue;
    setSKU(updateSKU);
  }

  const updateSKU = () => {
      setSkuTainted(true);
      let payload = Object.assign({}, sku);
      _.each(filters, filter => payload[filter.filterName] = filter.value);
      payload.manufacturerSubProductId = subProductId;
      api.post('manufacturer/UpdateSku', payload)
          .then(r => {
              if(!r.data.success) {
                  Alert.error('There was an issue updating this SKU. Please try again.');
                  return;
              }
              getSubproductSkus();
          }).catch(err => console.error(err));
  };

  function doSort(field, direction) {
    setSortColumn(field);
    setSortDirection(direction);
  }

  function onSelectSkuId(e, skuId) {
    e.stopPropagation();
    let newList = selectedSkuIds.slice();
    if (newList.includes(skuId)) {
      newList = _.reject(newList, x => x === skuId);
    } else {
      newList.push(skuId);
    }
    setSelectedSkuIds(newList);
  }

  function onFilterChange(changedFilter) {
      const filterChanges = filter_helpers.getFilters(filters, changedFilter);
      if(filterChanges.length === 0)
          setCanClear(false);
      else
          setCanClear(true);
      setFilters(filterChanges);
  }

    const saveSKU = () => {
        if(!skuTainted) return;
      let payload = {
          ...sku,
          manufacturerId: mfgId
      };
      payload.manufacturerSubProductId = sku.manufacturerSubProductId == 0
          ? subProductId
          : sku.manufacturerSubProductId;
      api.post('manufacturer/SaveSubProductSku', payload)
          .then(r => {
              if(!r.data.success) {
                  Alert.error('There was an error saving the SKU');
                  return;
              }
              getSubproductSkus();
              cancelAddSKU();
          }).catch(err => console.error(err));

    };

    const handleEditSKU = (sku) => {
      setSKU(sku);
      setIsEditingSKU(true);
    };

    const clearFilters = () => {
        setFilters([]);
        setClear(!clear);
    };

    const cancelEditSKU = () => {
      setSKU(defaultSKU);
      setSkuTainted(false);
      setIsEditingSKU(false);
      getSubproductSkus();
    };

    function loadSubproducts() {
      api.post('manufacturer/ListOfSubProducts', {
          manufacturerId: mfgId,
          activeOnly: true,
          name: null,
          productTypeId: null
        }).then(r => {
        if (!r.data.success) {
          Alert.error("There was an error retrieving the Subproduct List");
          return;
        }
        setSubproducts(
          _.map(r.data.message.subProductList, 
            sp => ({label: `${sp.name} (${sp.abbr})`, value: parseInt(sp.id, 10)})));
      }).catch((err) => console.error(err));
    }

    const getSubproductSkus = () => {
        setIsLoading(true);
        let payload = {
          activeOnly: activeOnly,
          mfgSubProductId: subProductId,
          manufacturerId: mfgId,
          sortAscending: (sortDirection === 'asc'),
          sortColumn: sortColumn
        };
        _.each(filters, filter => payload[filter.filterName] = filter.value);
        api.post(`Manufacturer/ListSubProductSkus`, payload).then(r => {
          if(!r.data.success) {
              Alert.error("There was an issue getting the SKUs for this Product");
              return;
          }
          setSkuList(r.data.message);
        }).catch((err) => console.error(err))
        .finally(() => setIsLoading(false));
    };

    const generateSKU = () => {
      let payload = {
        subProductId: subProductId,
        minimumWidth: subProductSizeOptions.minimumWidth,
        maximumWidth: subProductSizeOptions.maximumWidth,
        incrementWidthByAmount: subProductSizeOptions.incrementWidthBy,
        minimumLength: subProductSizeOptions.minimumLength,
        maximumLength: subProductSizeOptions.maximumLength,
        incrementLengthByAmount: subProductSizeOptions.incrementLengthBy,
        manufacturerId: mfgId
      };
    if (!validatePayload(payload)) return;
    api.post("manufacturer/GenerateSubProductSKU", payload)
        .then(r => {
            if(!r.data.success) {
                Alert.error('There was an issue generating the SKU\'s');
                return;
            }
            getSubproductSkus();
            Alert.success("Successfully generated the SKUs for this product.");
        }).catch(err => console.error(err))
        .finally(() => setShowGenSkuModal(false));
    };

    const cancelAddSKU = () => {
      setSkuTainted(false);
      setSKU(defaultSKU);
      setShowAddSKU(false);
    };

  const onSizeChange = (fieldName, fieldValue) => {
    let newSize = Object.assign({}, subProductSizeOptions);
    if (fieldName === "minimumWidth" &&
        subProductSizeOptions.maximumWidth > 0) {
        if (fieldValue >= subProductSizeOptions.maximumWidth) {
            Alert.warning("Your minimum selection can not equal to or higher than your maximum");
            return;
        }
    }
    if (fieldName === "maximumWidth" &&
        subProductSizeOptions.minimumWidth > 0
    ) {
        if (fieldValue <= subProductSizeOptions.minimumWidth) {
            Alert.warning(
                "Your maximum selection can not be equal to or lower than your minimum"
            );
            return;
        }
    }
    if (
        fieldName === "minimumLength" &&
        subProductSizeOptions.maximumLength > 0
    ) {
        if (fieldValue >= subProductSizeOptions.maximumLength) {
            Alert.warning(
                "Your minimum selection can not be equal to or higher than your maximum"
            );
            return;
        }
    }
    if (
        fieldName === "maximumLength" &&
        subProductSizeOptions.minimumLength > 0
    ) {
        if (fieldValue <= subProductSizeOptions.minimumLength) {
            Alert.warning(
                "Your maximum selection can not be equal to or lower than your minimum"
            );
            return;
        }
    }
    newSize[fieldName] = fieldValue;
    setSubProductSizeOptions(newSize);
};

const validatePayload = (payload) => {
    let warnings = [];
    if (payload.minimumWidth === 0) {
        warnings.push("Minimum Width can not be 0");
    }
    if (payload.maximumWidth === 0) {
        warnings.push("Maximum Width can not be 0");
    }
    if (payload.minimumLength === 0) {
        warnings.push("Minimum Length can not be 0");
    }
    if (payload.maximumLength === 0) {
        warnings.push("Maximum Length can not be 0");
    }
    if (warnings.length > 0) {
        Alert.warning(warnings.join(". "));
        return false;
    }
    return true;
};

  function onDeleteSelected() {
    confirmAlert({
      title: "Confirm Deletion",
      message: `Are you sure you wish to delete these ${selectedSkuIds.length} SKU(s)?`,
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            api.post(`manufacturer/DeactivateSKUs`, { idList: selectedSkuIds }).then(r => {
              if (r.data.success) {
                setSelectedSkuIds([]);
                getSubproductSkus();
              } else {
                Alert.error(r.data.message);
              }
            })
            .catch(error => Alert.error('Error while deleting products: ' + error));
          },
        },
        {
          label: "No",
        },
      ],
    });
  }

  function handleChange(e) {
    const { name, value } = e.target;
    const newOpts = {...subProductSizeOptions};
    newOpts[name] = value;
    setSubProductSizeOptions(newOpts);
  }

  return (<>
    <MfgAdmin mfgId={mfgId} tabName="PRODUCTS">
      <Container fluid>
  {isCloning && subproducts && subproducts.length
    ? (<CloneSkuDialog
        itemList={_.filter(skuList, x => selectedSkuIds.includes(x.id))}
        mfgId={mfgId}
        contextSubproductId={parseInt(subProductId, 10)}
        subproducts={subproducts}
        onClose={() => {
          setIsCloning(false);
          getSubproductSkus();
        }}
      />)
    : null
  }
  <Modal centered isOpen={showAddSKU}>
    <ModalHeader>
        {`Add a new SKU for ${subProductName}`}
    </ModalHeader>
    <ModalBody>
      <Form>
        <FormGroup row>
          <Col>
              <Label>
                  Name
              </Label>
              <Input type={'text'} name='name' value={sku.name} onChange={(e) =>
                  onSKUChange(e.target.name, e.target.value)}/>
          </Col>
          <Col>
              <Label>
                  Base Price
              </Label>
              <Input type={'text'} name='basePrice' value={sku.basePrice} onChange={(e) =>
                  onSKUChange(e.target.name, e.target.value)}/>
          </Col>
        </FormGroup>
        <FormGroup row>
          <Col>
              <Label>
                  Width
              </Label>
              <Input type={'text'} name='width' value={sku.width} onChange={(e) =>
                  onSKUChange(e.target.name, e.target.value)}/>
          </Col>
          <Col>
              <Label>
                  Length
              </Label>
              <Input type={'text'} name='length' value={sku.length} onChange={(e) =>
                  onSKUChange(e.target.name, e.target.value)}/>
          </Col>
        </FormGroup>
      </Form>
    </ModalBody>
    <ModalFooter>
      <Row>
        <Col>
          <Button onClick={() => cancelAddSKU()}>
            Cancel
          </Button>
        </Col>
        <Col>
          <Button className={'bg-success border-success'} onClick={() => saveSKU()}>
            Add
          </Button>
        </Col>
      </Row>
    </ModalFooter>
  </Modal>
  <Modal centered isOpen={showGenSkuModal} size={'lg'}>
    <ModalHeader>
      Generate SKUs
    </ModalHeader>
    <ModalBody>
        <Form className={"mt-2"}>
            <Row>
                <h5>Width</h5>
                <FormGroup row>
                    <Col sm={4}>
                        <Label>Minimum Width (feet)</Label>
                        <Input
                            type="select"
                            name="minimumWidth"
                            value={subProductSizeOptions.minimumWidth}
                            onChange={handleChange}
                        >
                            {[...Array(100)].map((_, i) => {
                                if (i === 0) return;
                                else 
                                  return <option key={i} value={i} selected={subProductSizeOptions.minimumWidth}>{i}</option>
                            })}
                        </Input>
                    </Col>
                    <Col sm={4}>
                        <Label>Maximum Width (feet)</Label>
                        <Input
                            type="select"
                            name="maximumWidth"
                            value={subProductSizeOptions.maximumWidth}
                            onChange={handleChange}
                        >
                            {[...Array(100)].map((_, i) => {
                                if (i === 0)
                                  return;
                                else 
                                  return <option key={i} value={i} selected={subProductSizeOptions.maximumWidth}>{i}</option>
                            })}
                        </Input>
                    </Col>
                    <Col sm={3}>
                        <Label>Increment Width By</Label>
                        <Input
                            type="select"
                            name="incrementWidthBy"
                            value={subProductSizeOptions.incrementWidthBy}
                            onChange={handleChange}
                        >
                            {[...Array(7)].map((_, i) => {
                                if (i === 0) return;
                                else 
                                  return <option key={i} value={i}  selected={subProductSizeOptions.incrementWidthBy}>{i}</option>
                            })}
                        </Input>
                    </Col>
                </FormGroup>
            </Row>
            <hr />
            <Row>
                <h5>Length</h5>
                <FormGroup row>
                    <Col sm={4}>
                        <Label>Minimum Length  (feet)</Label>
                        <Input
                            type="select"
                            name="minimumLength"
                            value={subProductSizeOptions.minimumLength}
                            onChange={handleChange}
                        >
                            {[...Array(100)].map((_, i) => {
                                if (i === 0) return;
                                else 
                                  return <option key={i} value={i} selected={subProductSizeOptions.minimumLength}>{i}</option>
                            })}
                        </Input>
                    </Col>
                    <Col sm={4}>
                        <Label>Maximum Length (feet)</Label>
                        <Input
                            type="select"
                            name="maximumLength"
                            value={subProductSizeOptions.maximumLength}
                            onChange={handleChange}
                        >
                            {[...Array(100)].map((_, i) => {
                                if (i === 0) return;
                                else 
                                  return <option key={i} value={i} selected={subProductSizeOptions.maximumLength}>{i}</option>
                            })}
                        </Input>
                    </Col>
                    <Col sm={3}>
                        <Label>Increment Length By</Label>
                        <Input
                            type="select"
                            name="incrementLengthBy"
                            value={subProductSizeOptions.incrementLengthBy}
                            onChange={handleChange}
                        >
                            {[...Array(7)].map((_, i) => {
                                if (i === 0) return;
                                else 
                                  return <option key={i} value={i} selected={subProductSizeOptions.incrementLengthBy}>{i}</option>
                            })}
                        </Input>
                    </Col>
                </FormGroup>
            </Row>
        </Form>
    </ModalBody>
    <ModalFooter>
        <Row>
            <Col>
                <Button onClick={() => setShowGenSkuModal(false)}>
                    Cancel
                </Button>
            </Col>
             <Col>
                 <Button className={'bg-success border-success'} onClick={() => generateSKU()}>
                     Generate
                 </Button>
             </Col>
        </Row>
    </ModalFooter>
  </Modal>
  {isEditingSKU
    ? (<EditSubProductSKU
        onReturnCallback={cancelEditSKU}
        skuToEdit={sku}
        fetchSKUList={getSubproductSkus}
        manufacturerName={manufacturerName}
        mfgId={mfgId}
        subProductName={subProductName}
        subProductId={subProductId}
        enabledBuild={enabledBuild}
        //activeTabIndex={activeTabIndex || props.activeTabIndex || 0}
      />)
    : (<Fragment>
        <Row>
          <Col>
            <h2 className="pb-0 mb-0">
              <Link to={`/admin/manufacturer/${mfgId}`}>{manufacturerName}</Link> / {subProductName} SKUs
            </h2>
          </Col>
        </Row>
        <Row>
          <Col>
            <FilterSearch filters={filters} onClear={clearFilters}>
                <Row>
                    <Col>
                        <TextFilter
                            filterName='name'
                            displayName='SKU:'
                            onChangeCallback={onFilterChange}
                            value={filter_helpers.get_value('name', filters)}/>
                    </Col>
                    <Col>
                        <TextFilter
                            filterName='width'
                            displayName='Width:'
                            onChangeCallback={onFilterChange}
                            forceInt={true}
                            minLength={1}
                            value={filter_helpers.get_value('width', filters)}/>
                    </Col>
                    <Col>
                        <TextFilter
                            filterName='length'
                            displayName='Length:'
                            onChangeCallback={onFilterChange}
                            forceInt={true}
                            minLength={1}
                            value={filter_helpers.get_value('length', filters)}/>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <TextFilter
                            filterName='price'
                            displayName='Price:'
                            onChangeCallback={onFilterChange}
                            forceInt={true}
                            value={filter_helpers.get_value('price', filters)}/>
                    </Col>
                    <Col>
                        <Label>Active</Label>
                        <ToggleButton
                            value={activeOnly}
                            activeLabel={"Yes"}
                            inactiveLabel={"No"}
                            onToggle={(v) => setActiveOnly(!v)}
                        />
                    </Col>
                </Row>
            </FilterSearch>
          </Col>
        </Row>
        <Card>
          <CardHeader>
            <Row>
              <Col xs="6">
                <Button tag={Link} to={`/admin/manufacturer/${mfgId}/products`}>
                  <FontAwesomeIcon icon='arrow-left' /> Products
                </Button>
              </Col>
              <Col xs="6">
                <ButtonGroup className='float-end'>
                  <Button className='bg-success border-success' onClick={() => setShowAddSKU(true)}>
                    <FontAwesomeIcon icon='plus' />
                  </Button>
                  {selectedSkuIds.length
                    ? (<Fragment>
                      <Button color="dark" onClick={() => {
                        if (!subproducts || !subproducts.length) {
                          loadSubproducts();
                        }
                        setIsCloning(true);
                      }}>
                        <FontAwesomeIcon icon='clone' /> Clone Selected
                      </Button>
                      <Button color="danger" onClick={onDeleteSelected} className="text-light">
                        <FontAwesomeIcon icon='times-circle' /> Delete Selected
                      </Button>
                      </Fragment>)
                    : null
                  }
                </ButtonGroup>
              </Col>
            </Row>
          </CardHeader>
          <Table bordered striped>
            <thead>
              <tr>
                <th style={{width: "45px"}} />
                <th>SKU</th>
                <SortHeader
                  displayName='W x L'
                  field='WL'
                  sortDir={sortDirection}
                  sorted={sortColumn === 'WL'}
                  sortCallback={doSort}
                />
                <SortHeader
                  displayName='Price'
                  field='DefaultPrice'
                  className='col-md-2'
                  sortDir={sortDirection}
                  sorted={sortColumn === 'DefaultPrice'}
                  sortCallback={doSort}
                />
                <SortHeader
                  displayName='Base Price'
                  field='BasePrice'
                  className='col-md-2'
                  sortDir={sortDirection}
                  sorted={sortColumn === 'BasePrice'}
                  sortCallback={doSort}
                />
                <th className={'col-md-1 text-center'}>Actions</th>
              </tr>
            </thead>
            <tbody>
              {isLoading
                ? (<tr><td colSpan={6}><Loading/></td></tr>)
                : (<Fragment>
                    {skuList && skuList.length
                      ? _.map(skuList, (s, idx) =>
                          <SubProductSkuRow
                            key={idx}
                            sku={s}
                            handleEditSKU={handleEditSKU}
                            fetchSKUList={getSubproductSkus}
                            index={idx}
                            onSelectSkuId={onSelectSkuId}
                            isSelected={selectedSkuIds.includes(s.id)}
                            enabledBuild={enabledBuild}
                          />)
                      : (<tr>
                          <td colSpan={6} className={'text-center'}>
                            <span className={'d-flex align-items-center justify-content-center'}>
                              There are no SKUs for {subProductName}. Click
                              <Button color='link' className={'px-1'} onClick={() => setShowGenSkuModal(true)}>Here</Button> to generate them.
                            </span>
                          </td>
                        </tr>)
                    }
                  </Fragment>)
              }
            </tbody>
          </Table>
        </Card>
      </Fragment>)
}
      </Container>
    </MfgAdmin>
  </>);
}