import React, { useReducer } from "react";
import { ExteriorContext } from "../contexts";
import { exteriorReducer } from "../reducers";

function ExteriorProvider({ exterior, children }) {
  const [state, dispatch] = useReducer(exteriorReducer, exterior);

  return (
    <ExteriorContext.Provider value={[state, dispatch]}>
      {children}
    </ExteriorContext.Provider>
  );
}

export default ExteriorProvider;
