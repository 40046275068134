import React, {useContext, useEffect, useState, Fragment} from "react";
import { Link, useHistory } from "react-router-dom";
import Select from "react-select";
import { Container, 
    TabContent, 
    Table, 
    TabPane, 
    Nav, 
    NavItem, 
    NavLink, 
    Card, 
    CardFooter,
    CardHeader,
    Button,
    Badge,
    Modal,
    ModalBody,
    ModalHeader,
    ModalFooter,
    CardTitle, 
    CardText, 
    Row, 
    Col, 
    Form, 
    FormGroup, 
    Label,
    ButtonGroup, 
    Accordion,
    Collapse,
    CardBody,
    Input } from 'reactstrap';
import {FilterSearch, FilterSwitch, Header} from ".";
import { 
    Pager, 
    Footer,
    ResultHeader, 
    Loading, 
    SortHeader, 
    SelectFilter, 
    TextFilter,
    DateFilter 
} from "./"; 
import {date_helpers, filter_helpers, ui_helpers} from "../helpers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classnames from 'classnames';
import _ from "lodash";
import {api, constants, UserContext} from "../utils";
const PATHNAMES = constants.PATH_NAMES;
const MAX_COLOR_STRING_LEN = 27;

const BuildRow = ({b}) => {
  const history = useHistory();
  const openBuildItem = (id) => {
    history.push(`${constants.PATH_NAMES.BUILD_DETAILS}/${id}`)
  };
  const colorString = `${b.baseColorName}/${b.trimColorName}/${b.roofColorName}`;
  const choppedString = colorString.length > MAX_COLOR_STRING_LEN
    ? ui_helpers.chop(colorString, MAX_COLOR_STRING_LEN)
    : null;
  return (
    <tr className="selectable-row">
      <td onClick={() => openBuildItem(b.unitInventoryId)}>{b.serialNumber}</td>
      <td onClick={() => openBuildItem(b.unitInventoryId)}><Badge className="bg-ghost">{b.currentBuildStepName}</Badge></td>
      <td onClick={() => openBuildItem(b.unitInventoryId)}><Badge className="bg-ghost">{b.lastCompletedBuildStepName}</Badge></td>
      <td onClick={() => {
        if (b.customerFullName.trim() === "") { openBuildItem(b.unitInventoryId) }
      }}>
      {b.customerFullName.trim()
        ? <Link to={`${b.isRtoContract ? constants.PATH_NAMES.CONTRACT_VIEW : constants.PATH_NAMES.CASH_SALE_VIEW}/${b.contractId}`}>{b.customerFullName}</Link>
        : b.orderNote
      }
      </td>
      <td onClick={() => openBuildItem(b.unitInventoryId)}>{b.dealerName}</td>
      <td style={{width: "105px"}} onClick={() => openBuildItem(b.unitInventoryId)}>{b.lastCompletedBuildStepScheduledFor ?
          date_helpers.formatDateToShortDate(b.lastCompletedBuildStepScheduledFor) : ""}</td>
      <td style={{width: "95px"}} onClick={() => openBuildItem(b.unitInventoryId)}>
        {b.currentBuildStepContactCustomer ? (<span title={`Completion of the ${b.currentBuildStepName} step will notify the customer`}>{ui_helpers.greenCheck()}</span>) : null}
      </td>
      <td onClick={() => openBuildItem(b.unitInventoryId)}>{b.builderName}</td>
      {/*<td onClick={() => openBuildItem(b.unitInventoryId)}>{b.manufacturerName}</td>*/}
      <td onClick={() => openBuildItem(b.unitInventoryId)} title={choppedString ? colorString : null}>{choppedString ? choppedString : colorString}</td>
    </tr>
    );
}

const FilterOptions = {
    ALL_OPEN: 'all',
    SPECIAL_STEPS: 'special-build-steps',
    COMPLETED: 'build-completed'
    // IN_REVIEW: 2,
    // ON_HOLD: 3,
    // IN_STOCK: 4,
    // PENDING: 5,
    // WEB_SALE: 6,
    // SOLD: 7,
    // IN_PRODUCTION: 8,
    // SAVED_FILTER: 9
};

export default function Builds() {
    document.title = `Builds - ${constants.appName}`;
    const [filters, setFilters] = useState([{ filterName: 'OpenOnly', value: true }]);
    const [firstLoad, setFirstLoad] = useState(true);
    const [canClear, setCanClear] = useState(false);
    const [message, setMessage] = useState({flavor: constants.flavor.success, message: ''});
    const [pageNumber, setPageNumber] = useState(1);
    const [lastFilteredUsingQuickSearchKey, setLastFilteredUsingQuickSearchKey] = useState('');
    const [totalPages, setTotalPages] = useState(0);
    const [paginatedList, setPaginatedList] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [totalRetailPrice, setTotalRetailPrice] = useState(0);
    const [sortDirection, setSortDirection] = useState('desc');
    const [sortColumn, setSortColumn] = useState('completedAt');
    const [clear, setClear] = useState(false);
    const [companies, setCompanies] = useState([]);
    const [manufacturers, setManufacturers] = useState([]);
    const [dealers, setDealers] = useState([]);
    const [buildSteps, setBuildSteps] = useState([]);
    const [builders, setBuilders] = useState([]);
    const [selectedMfg, setSelectedMfg] = useState({});
    const {currentUser} = useContext(UserContext);
    const [selectedFilterOption, setSelectedFilterOption] = useState(null);
    const [buildFilterMfgId, setBuildFilterMfgId] = useState(ui_helpers.getMfgIdForBuildQuickFilters(currentUser))
    const [quickFilterCounts, setQuickFilterCounts] = useState(null)
    const [filterOptions, setFilterOptions] = useState([]);
    const [lastSearch, setLastSearch] = useState(null);
    const [pristineLastSearch, setPristineLastSearch] = useState(null);
    const [isQuickFilter, setIsQuickFilter] = useState(true);
    const [totalBadgeClass, setTotalBadgeClass] = useState('bg-dark');
    const cssBtn = [
        "btn-tb-1",
        "btn-tb-2",
        "btn-tb-2",
        "btn-tb-3",
        "btn-tb-3",
        "btn-tb-4",
        "btn-tb-4",
        "btn-tb-5",
        "btn-tb-6",
    ];

    useEffect(() => {
      if (firstLoad) return;
      refreshData();
    }, [filters])

    useEffect(() => {
      if (firstLoad) return;
      refreshData(lastFilteredUsingQuickSearchKey
        ? lastFilteredUsingQuickSearchKey
        : null);
    }, [sortColumn, sortDirection, pageNumber])

    useEffect(() => {
      api.fetch('Inventory/GetBuildFilters').then(r => {
        const { dealerSelect, companySelect, manufacturerSelect, quickFilterCounts, savedSearch, initialData } = r.data.data;
        companySelect.unshift({ label: '[Any Company]', value: 0 });
        manufacturerSelect.unshift({ label: '[Any Manufacturer]', value: 0 });
        dealerSelect.unshift({ label: '[Any Store]', value: 0 });
        setDealers(dealerSelect);
        setCompanies(companySelect);
        setManufacturers(manufacturerSelect);
        if (!_.isEmpty(quickFilterCounts)) {
          setQuickFilterCounts(quickFilterCounts);
          setFilterOptions(Object.keys(quickFilterCounts));
        }
        setFilters([{filterName: 'OpenOnly', value: true}]);
        if (savedSearch)
        {
          const deserializedSearch = JSON.parse(savedSearch.serializedSearch);
          const sortDetails = deserializedSearch.resolvedSort;
          // setLoadedPayload(
          //   resolvePayload(sortDetails.page, savedSearch.filters));
          setPageNumber(sortDetails.page);
          //setFilters(deserializedSearch.filters);
          setSortColumn(sortDetails.sortColumn);
          setSortDirection(sortDetails.sortAscending ? "asc" : "desc");
          setPristineLastSearch(savedSearch.serializedSearch);
          setPaginatedList(initialData);
          setTotalRetailPrice(initialData.totalPrice);
          if (savedSearch.quickFilterKey) {
            setSelectedFilterOption(savedSearch.quickFilterKey);
            setIsQuickFilter(true);
            setPageNumber(1);
          } else {
            setSelectedFilterOption(`ss-${filterOptions['saved-search']}`);
          }
        } else {
          setSelectedFilterOption(FilterOptions.ALL_OPEN);
        }
        setTotalBadgeClass('bg-dark');
      })
      .catch((error) => {
        setMessage({flavor: 'danger', message: 'Error when trying to get filter data: ', error});
      })
      .finally(() => setFirstLoad(false));
    }, []);

    useEffect(() => {
      if (!selectedMfg.value) return;
      getMfgBuildSteps();
      getMfgBuilders();
    }, [selectedMfg]);

    useEffect(() => {
      if (firstLoad || !lastSearch || _.isEqual(pristineLastSearch, lastSearch)) return;
      filter_helpers.saveSearch(constants.SEARCH_AREAS.BUILDS, lastSearch);
      setPristineLastSearch(lastSearch);
    }, [pristineLastSearch, lastSearch])

    function clearFilters() {
      setFilters([]);
      setClear(!clear);
    }

    const getMfgBuildSteps = () => {
      api.fetch(`Inventory/GetBuildStepFilter/${selectedMfg.value}`).then(r => {
        if(!r.data) return;
        setBuildSteps(r.data);
      }).catch((error) => {
        setMessage({flavor: 'danger', message: 'Error when trying to get build step data: ' + error});
      });
    };

    const getMfgBuilders = () => {
      api.fetch(`Reference/GetManufacturerBuilders/${selectedMfg.value}`).then(r => {
        if (!r.data.success) return;
        setBuilders(r.data.message.list);
      }).catch(err => console.error(err));
    };

    function doSort(field, direction){
      setSortColumn(field);
      setSortDirection(direction);
    }

    function onApplyQuickFilter(changedFilter, filterOption, badgeClass) {
      setIsQuickFilter(true);
      setPageNumber(1);
      setFilters(
        filter_helpers.getFilters([{filterName: "manufacturerId", value: buildFilterMfgId}], 
          changedFilter));
      setSelectedFilterOption(filterOption);
      setTotalBadgeClass(badgeClass);
    }

    function resolveSort() {
      return {
        sortColumn,
        sortAscending: sortDirection === 'asc'
      };
    }

    function resolvePayload(pPageNumber = null, pFilters = null) {
      let payload = {
        ...resolveSort(),
        page: pPageNumber || pageNumber,
        ExcludeInventoryStatuses: [constants.INVENTORY_STATUS_ID.Draft, constants.INVENTORY_STATUS_ID.Cancelled]
      };
      const filterList = pFilters ? pFilters : filters;
      _.each(filterList, filter => payload[filter.filterName] = filter.value);
      if (payload.BuilderName) {
        // this is an odd one that uses the label rather than the value
        const labelFilter = _.find(filterList, fl => fl.filterName === 'BuilderName');
        payload.BuilderName = labelFilter.label;
      }
      return payload;
    }

    function refreshData(quickSearchKey = null, forceRefresh = false) {
      if (isLoading) return;
      let payload = resolvePayload();
      if (quickSearchKey) {
        payload.quickSearchKey = quickSearchKey;
        if (quickSearchKey !== lastFilteredUsingQuickSearchKey) {
          setPageNumber(1);
          setLastFilteredUsingQuickSearchKey(quickSearchKey);
          payload.page = 1;        
        }
      } else {
        setLastFilteredUsingQuickSearchKey('');
      }
      setIsLoading(true);
      api.post('Inventory/FindBuilds', payload).then((response) => {
        if (response.data) 
        {
          setPaginatedList(response.data);
          setTotalRetailPrice(response.data.totalPrice);
        }
      }).catch(error => {
        setMessage({flavor: 'danger', message: 'Error when trying to refresh data: ', error});
      }).finally(() => {
        setIsLoading(false);
        if (!firstLoad) {
          setLastSearch(
            filter_helpers.storeSaveSearch(
              filters, resolveSort(), pageNumber, 
                (isQuickFilter ? selectedFilterOption : null)));
        }
        setIsQuickFilter(false);
      });
    }

    function onFilterChange(changedFilter) {
      let filterChanges = filter_helpers.getFilters(filters, changedFilter);
      if (isQuickFilter) {
        // clear prior filters if we were in a quick filter mode as we're starting fresh
        setIsQuickFilter(false);
        filterChanges = filter_helpers.getFilters([], changedFilter);
      }
      setCanClear(filterChanges.length > 0);
      setFilters(filterChanges);
      setPageNumber(1);
    }

    const onMfgSelect = (mfg) => {
      setSelectedMfg(mfg);
      onFilterChange(mfg);
    }
    
    function onStatusButtonClicked(changedFilter) {
      setIsQuickFilter(true);
      setLastFilteredUsingQuickSearchKey('');
      setFilters(changedFilter.value === null
        ? []
        : [changedFilter]);
      setPageNumber(1);
    }

    return (
      <div>
        <Header toolbar inventory>
            <Row className="me-0">
                <Col xs="1">
                    <Button
                        color="success"
                        className="text-light px-3 me-2  float-begin"
                        tag={Link}
                        to={`${PATHNAMES.NEW_INVENTORY}/0`}
                        title="Add New"
                    >
                      <FontAwesomeIcon icon='plus' /> 
                    </Button>
                </Col>
                <Col>
                    <Row>
                        <Col md=''>
                            <div className="d-flex float-end">
                            {!quickFilterCounts || !filterOptions.length ? null : (
                              <ButtonGroup className="">
                                  <Button size="sm" className={classnames({ "btn-tb-7": true, 'btn-tb-active': selectedFilterOption === FilterOptions.ALL_OPEN })}
                                    onClick={() => onApplyQuickFilter({filterName: 'OpenOnly', value: true}, FilterOptions.ALL_OPEN, 'bg-all')}
                                    >
                                        All Open {ui_helpers.conditionalCount(quickFilterCounts[FilterOptions.ALL_OPEN])}
                                    </Button>
                                    {filterOptions && filterOptions.length && filterOptions.map((fo, idx) => (
                                        (fo === FilterOptions.ALL_OPEN || fo === FilterOptions.SPECIAL_STEPS || fo === FilterOptions.COMPLETED) 
                                          ? null
                                          : <Button size="sm"
                                              className={classnames({"btn-tb-active": selectedFilterOption === `${idx}${filterOptions[fo]}`}, 'btn', cssBtn[idx])}
                                              onClick={() => 
                                                onApplyQuickFilter({ filterName: 'CurrentBuildStepName', value: fo }, `${idx}${filterOptions[fo]}`, 'bg-all')}
                                            >
                                            {fo} {ui_helpers.conditionalCount(quickFilterCounts[fo])}
                                          </Button>))}
                                    <Button size="sm" className={classnames({'btn-tb-active': selectedFilterOption === `y-${filterOptions['special-build-steps']}`}, "btn-secondary")}
                                      onClick={() => onApplyQuickFilter({ filterName: 'CurrentBuildStepIsSpecial', value: true }, FilterOptions.SPECIAL_STEPS, 'bg-all')}
                                    >
                                      Special Step {ui_helpers.conditionalCount(quickFilterCounts[FilterOptions.SPECIAL_STEPS])}
                                    </Button>
                                    <Button size="sm" className={classnames({'btn-tb-active': selectedFilterOption === `z-${filterOptions[FilterOptions.COMPLETED]}`}, "btn-dark")}
                                      onClick={() => onApplyQuickFilter({ filterName: 'BuildComplete', value: true }, FilterOptions.COMPLETED, 'bg-all')}
                                    >
                                      Completed {ui_helpers.conditionalCount(quickFilterCounts[FilterOptions.COMPLETED])}
                                    </Button>
                              </ButtonGroup>                        
                            )}
{/*                            {!pristineLastSearch && !lastSearch
                              ? null
                              : (<Button className={classnames({"btn btn-tb-9": true, 'btn-tb-active': selectedFilterOption === `ss-${filterOptions['saved-search']}`})}
                                  onClick={() => {
                                  onSaveFilterClicked();
                                  setSelectedFilterOption(`ss-${filterOptions['saved-search']}`);
                                  setTotalBadgeClass('bg-dark');
                                }}>
                                  Saved Filter 
                                </Button>)
                            }
*/}                            </div>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Header>
        <FilterSearch filters={filters} canClear={canClear} onClear={clearFilters}>
            <Row>
                <Col lg='2' sm='3'>
                    <SelectFilter
                        filterName='manufacturerId'
                        displayName='Manufacturer'
                        value={filter_helpers.get_value('manufacturerId', filters)}
                        clear={clear}
                        options={manufacturers}
                        isSingleSelect={true}
                        isDisable={!manufacturers}
                        onChangeCallback={onMfgSelect}
                        placeholder={'[Any Manufacturer]'}/>
                </Col>
                <Col lg='2' sm='3'>
                    <SelectFilter
                        filterName='currentBuildStepName'
                        displayName='Current Build Step'
                        value={filter_helpers.get_value('currentBuildStepName', filters)}
                        clear={clear}
                        options={buildSteps ? _.map(buildSteps, b => {
                            b.value = b.name;
                            b.label = b.name;
                            return b;
                        }) : null}
                        isSingleSelect
                        isDisabled={!buildSteps.length}
                        onChangeCallback={onFilterChange}
                        placeholder={buildSteps.length ?  '[Any Build Step Name]' : '[Select a Manufacturer]'}/>
                </Col>
                <Col lg='2' sm='3'>
                    <SelectFilter
                        filterName='BuilderName'
                        displayName='Builder'
                        value={filter_helpers.get_value('BuilderName', filters)}
                        clear={clear}
                        options={_.map(builders, b => {
                          b.value = b.id;
                          b.label = `${b.firstName} ${b.lastName}`;
                          return b;
                        })}
                        isSingleSelect
                        isDisabled={!builders.length}
                        onChangeCallback={onFilterChange}
                        placeholder={'[Any Builder]'}/>
                </Col>
                <Col lg='2' sm='3'>
                    <SelectFilter
                        filterName='dealerId'
                        displayName='Dealer'
                        value={filter_helpers.get_value('dealerId', filters)}
                        clear={clear}
                        options={dealers}
                        isSingleSelect={true}
                        isDisable={!dealers}
                        onChangeCallback={onFilterChange}
                        placeholder={'[Any Dealer]'}/>
                </Col>
                <Col lg='2' sm='2'>
                    <FilterSwitch
                        filterName="contactCustomer"
                        displayName="Contact Customer"
                        onChangeCallback={onFilterChange}
                        value={filter_helpers.get_value("contactCustomer", filters)}
                    />
                </Col>
                <Col lg='2' sm='3'>
                    <FilterSwitch
                        filterName="buildComplete"
                        displayName="Build Completed?"
                        onChangeCallback={onFilterChange}
                        value={filter_helpers.get_value("buildComplete", filters)}
                    />
                </Col>
            </Row>
            <Row>
                <Col lg='2' sm='3'>
                    <TextFilter
                        filterName='serialNumber'
                        displayName='Serial Number:'
                        onChangeCallback={onFilterChange}
                        value={filter_helpers.get_value('serialNumber', filters)}
                    />
                </Col>
                <Col lg='2' sm='3'>
                    <TextFilter
                        filterName='customerName'
                        displayName='Customer Name'
                        onChangeCallback={onFilterChange}
                        value={filter_helpers.get_value('customerName', filters)}
                    />
                </Col>
                <Col lg='2' sm='3'>
                    <DateFilter
                        filterName='scheduledFor'
                        displayName='Scheduled For'
                        onChangeCallback={onFilterChange}/>
                </Col>
                <Col lg='2' sm='3'>
                    <DateFilter
                        filterName='completedAt'
                        displayName='Completed On'
                        onChangeCallback={onFilterChange}/>
                </Col>
            </Row>
        </FilterSearch>          
        <Card>
            <CardHeader>
                <Row className='d-flex justify-content-between'>
                    <Col>
                      <ResultHeader 
                        totalCost={totalRetailPrice}
                        heading='Builds' 
                        totalResults={paginatedList ? paginatedList.totalItems : 0}
                        // badgeClass={totalBadgeClass}
                      />
                    </Col>
                    <Col className='d-flex justify-content-end'>
                        <Pager pageNumber={pageNumber} pageSize={paginatedList ? paginatedList.pageSize : 0} totalResults={paginatedList ? paginatedList.totalItems : 0} onSetPageCallback={setPageNumber}/>
                    </Col>
                </Row>
            </CardHeader>
                <Table bordered striped hover>
                    <thead>
                        <tr className="sortable-header-row" key={'sort-headers'}>
                            <SortHeader 
                                displayName='Serial Number'
                                field='SerialNo'
                                sortDir={sortDirection}
                                sorted={sortColumn === 'SerialNo'}
                                sortCallback={doSort}
                                isAlphabetic/>
                             <SortHeader 
                                displayName='Current Step'
                                field='CurrentStep'
                                sortDir={sortDirection}
                                sorted={sortColumn === 'CurrentStep'}
                                sortCallback={doSort}
                                isAlphabetic/>                        
                             <SortHeader 
                                displayName='Last Completed Step'
                                field='LastCompletedStep'
                                sortDir={sortDirection}
                                sorted={sortColumn === 'LastCompletedStep'}
                                sortCallback={doSort}
                                isAlphabetic/>                        
                            <SortHeader 
                                displayName='Order Note'
                                field='ResolvedOrderNote'
                                sortDir={sortDirection}
                                sorted={sortColumn === 'ResolvedOrderNote'}
                                sortCallback={doSort}
                                isAlphabetic/>
                            <SortHeader 
                                displayName='Dealer'
                                field='DealerName'
                                sortDir={sortDirection}
                                sorted={sortColumn === 'DealerName'}
                                sortCallback={doSort}
                                isAlphabetic/>
                             <SortHeader 
                                displayName='Scheduled For'
                                field='Scheduled'
                                sortDir={sortDirection}
                                sorted={sortColumn === 'Scheduled'}
                                sortCallback={doSort}
                                isAlphabetic/>        
                             <SortHeader 
                                displayName='Customer Contacted'
                                field='Contact'
                                sortDir={sortDirection}
                                sorted={sortColumn === 'Contact'}
                                sortCallback={doSort}
                                isAlphabetic/>    
                             <SortHeader 
                                displayName='Builder'
                                field='BuilderName'
                                sortDir={sortDirection}
                                sorted={sortColumn === 'BuilderName'}
                                sortCallback={doSort}
                                isAlphabetic/>  
{/*                             <SortHeader 
                                displayName='Manufacturer'
                                field='ManufacturerName'
                                sortDir={sortDirection}
                                sorted={sortColumn === 'ManufacturerName'}
                                sortCallback={doSort}
                                isAlphabetic/>
*/}
                          <th>
                            Base/Trim/Roof Colors
                          </th>
                        </tr>
                    </thead>
                    <tbody>
                      {isLoading || firstLoad
                        ? (<tr key='loading'><td colSpan='12'><Loading /></td></tr>)
                        : paginatedList && paginatedList.dataSource && paginatedList.dataSource.length
                          ? _.map(paginatedList.dataSource, b => (<BuildRow key={`bld-${b.unitInventoryId}`} b={b} />))
                          : (<tr key="no-results"><td colSpan='12' className='text-center'>No build items to show.</td></tr>)
                      }
                    </tbody>
                </Table>
            <CardFooter>
              <Pager pageNumber={pageNumber} pageSize={paginatedList ? paginatedList.pageSize : 0} totalResults={paginatedList ? paginatedList.totalItems : 0} onSetPageCallback={setPageNumber}/>
            </CardFooter>
        </Card>
        <Footer />
      </div>
    );
}