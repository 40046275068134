import React, { Fragment, useState, useContext, useEffect } from "react";
import {Link, useHistory, useLocation} from "react-router-dom";
import {
  Button,
  Row, Col, ButtonGroup, Table,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  InputGroup, Modal, ModalHeader, ModalBody, ModalFooter,
  Nav,
  Navbar,
  NavItem,
  NavLink, Spinner
} from "reactstrap";
import _ from "lodash"; 
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {CalculatorModal, CommentBell, CommentModal, Loading} from "./";
import {api, constants, UserContext} from "../utils";
import {ui_helpers} from "../helpers";
const PATHNAMES = constants.PATH_NAMES;

const MainNav = (props) => {
  const history = useHistory();
  const location = useLocation();
  const [showComments, setShowComments] = useState(false);
  const [showCalc, setShowCalc] = useState(false);
  const [searchResults, setSearchResults] = useState(null);
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [buildFeature, setBuildFeature] = useState(false);

  const userCtx = useContext(UserContext);

  const routeToMfgAdmin = () => {
    let adminRoles = _.filter(userCtx.currentUser.roles, r => r.typeOfUserRole === constants.ROLE_IDS.Administrator);
    if (adminRoles.length && _.some(adminRoles, r => r.manufacturerId ? true : false))
    {
      const firstMfgAdminRole = _.find(adminRoles, r => r.manufacturerId);
      return `${PATHNAMES.ADMIN_SINGLE_MANUFACTURER}/${firstMfgAdminRole.manufacturerId}`;
    }
  };

  function onSearchKeyDown(e) {
    if (e.which !== 13) return;
    onSearch();
    return false;
  }

  function onSearch() {
    if (loading) return;
    setLoading(true);
    api.fetch('home/GlobalSearch/' + searchTerm).then(r => {
      if (r.data.success) {
        setSearchResults(r.data.message);
      } else {
        console.error(r.data.message);
      }
    }).catch((e) => console.error(e))
    .finally(() => setLoading(false));
  }

  const routeToCompanyAdmin = () => {
    let adminRoles = _.filter(userCtx.currentUser.roles, r => 
      r.typeOfUserRole === constants.ROLE_IDS.Administrator && r.companyId);
    if (adminRoles.length === 1) {
      const firstCompanyAdminRole = _.find(adminRoles, r => r.companyId);
      return `${PATHNAMES.COMPANY_ADMIN}/${firstCompanyAdminRole.companyId}`;
    }
    return PATHNAMES.COMPANY_ADMIN;
  };

  const routeToStoreAdmin = () => {
    let adminRoles = _.filter(userCtx.currentUser.roles, r => 
        r.typeOfUserRole === constants.ROLE_IDS.Administrator && r.dealerId);
    if(adminRoles.length === 1) {
      const firstStoreAdminRole = _.find(adminRoles, r => r.dealerId);
      // history.push(`admin/store/${firstStoreAdminRole.dealerId}`);
      return `admin/store/${firstStoreAdminRole.dealerId}`;
    }
    return PATHNAMES.STORE_ADMIN;
  };

  const displayAdminTab = (isSysAdmin, isCompanyAdmin) => {
    const user = userCtx.currentUser;
    const isDealerAdmin = ui_helpers.isAnyDealerAdministrator(user);
    const isMfgAdmin = ui_helpers.isAnyManufacturerAdministrator(user);
    const companyAdminCount = isCompanyAdmin
      ? ui_helpers.companyAdministratorCount(user)
      : 0;
    const isMultiAdmin = [isDealerAdmin, isMfgAdmin, isCompanyAdmin].filter(Boolean).length > 1;
    if (isSysAdmin || companyAdminCount > 1) {
      return(<NavItem>
        <NavLink
            tag={Link}
            to={PATHNAMES.ADMIN_USERS}
            active={props.storeName || props.mfgAdmin || props.companyAdmin
                ? false
                : _.includes(location.pathname, PATHNAMES.ADMIN)}
            style={{cursor: 'pointer'}}>
          Admin
        </NavLink>
      </NavItem>);
    }
    if(isMultiAdmin) {
      return (
        <NavItem>
          <NavLink
            tag={Link}
            to={PATHNAMES.ADMIN}
            active={_.includes(location.pathname, PATHNAMES.ADMIN)}>
              Admin
          </NavLink>
        </NavItem>
      );
    }
    if(isMfgAdmin && !_.includes(location.pathname, '/admin/manufacturer/')) {
      return (
          <NavItem>
            <NavLink
                tag={Link}
                to={ui_helpers.roleContextRouteId('mfg', userCtx.currentUser)}
                active={props.mfgAdmin ? false : _.includes(location.pathname, PATHNAMES.ADMIN)}
                style={{cursor: 'pointer'}}>
              Admin
            </NavLink>
          </NavItem>
      );
    }
    if(isDealerAdmin && !_.includes(location.pathname, '/admin/store/')) {
        return (
          <NavItem>
            <NavLink
                tag={Link}
                to={ui_helpers.roleContextRouteId('dealer', userCtx.currentUser)}
                active={props.storeAdmin ? false : _.includes(location.pathname, PATHNAMES.ADMIN)}
                style={{cursor: 'pointer'}}>
              Admin
            </NavLink>
          </NavItem>);
    }
    if (isCompanyAdmin && !_.includes(location.pathname, '/admin/company/')) {
     return (
      <NavItem>
        <NavLink
          tag={Link}
          to={ui_helpers.roleContextRouteId('company', userCtx.currentUser)}
          active={props.companyAdmin ? false : _.includes(location.pathname, PATHNAMES.ADMIN)}
        >
         Admin
        </NavLink>
      </NavItem>);
    }
    return;
  };

  function getPermissions() {
    const hasBuildFeature = ui_helpers.hasBuildFeature(userCtx.currentUser);
    setBuildFeature(hasBuildFeature);
  }

  useEffect(getPermissions, []);

  const isSysAdmin = userCtx && userCtx.currentUser && ui_helpers.isSystemAdministrator(userCtx.currentUser);
  const isCompanyAdmin = userCtx && userCtx.currentUser && ui_helpers.isAnyCompanyAdministrator(userCtx.currentUser);

  if (props.login) return <Navbar id="mainNav" />;
  return (
    <>
    {userCtx.currentUser ? (
    <nav id="mainNav" className="navbar navbar-expand-md">
      <Button
        color="dark" 
        className="navbar-toggler bg-white" 
        data-bs-toggle="collapse"
        data-bs-target="#mobileMenuDropdown"
        aria-controls="mobileMenuDropdown"
        aria-expanded="false"
        aria-label="Toggle navigation" >
        <span className="navbar-toggler-icon">
        </span>
      </Button>
      <div className="collapse navbar-collapse" id="mobileMenuDropdown">
        <Nav navbar>
          <NavItem>
            <NavLink
              tag={Link}
              to={PATHNAMES.HOME}
              active={location.pathname === PATHNAMES.HOME}
            >
              Home
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              tag={Link}
              to={PATHNAMES.RTOS}
              active={location.pathname === PATHNAMES.RTOS}
            >
              RTO
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              tag={Link}
              to={PATHNAMES.CASH_SALES}
              active={location.pathname === PATHNAMES.CASH_SALES}
            >
              Cash Sales
            </NavLink>
          </NavItem>
        {ui_helpers.hasContractCreationPermission(userCtx.currentUser) || isSysAdmin
          ? (<NavItem>
            <NavLink
              tag={Link}
              to={PATHNAMES.QUOTES}
              active={location.pathname === PATHNAMES.QUOTES}
            >
              Quotes
            </NavLink>
          </NavItem>)
        : null}
          <NavItem>
            <NavLink
              tag={Link}
              to={PATHNAMES.INVENTORY}
              active={location.pathname === PATHNAMES.INVENTORY}
            >
              Inventory
            </NavLink>
          </NavItem>
          {ui_helpers.isAnyMfgUser(userCtx.currentUser) && buildFeature
         ? (<NavItem>
            <NavLink
              tag={Link}
              to={PATHNAMES.BUILDS}
              active={location.pathname === PATHNAMES.BUILDS}
            >
              Builds
            </NavLink>
          </NavItem>) : null}
{/*          {isCompanyAdmin || isSysAdmin
            ? (<NavItem>
                <NavLink
                  tag={Link}
                  to={PATHNAMES.INVENTORY_SALES}
                  active={location.pathname === PATHNAMES.INVENTORY_SALES}
                >
                  B2B Sales
                </NavLink>
              </NavItem>)
            : null
          }
*/}          {props.companyAdmin 
            || ui_helpers.isReportingUser(userCtx.currentUser) 
            || ui_helpers.isAnyCompanyAdministrator(userCtx.currentUser)
            || ui_helpers.isAnyManufacturerAdministrator(userCtx.currentUser)
            ? (<NavItem>
                <NavLink
                    tag={Link}
                    to={PATHNAMES.RESOURCES_REPORTSINDEX}
                    active={
                        location.pathname === PATHNAMES.RESOURCES_REPORTSINDEX
                    }
                >
                  Reports
                </NavLink>
              </NavItem>)
            : null
          }
          {userCtx.currentUser ? displayAdminTab(isSysAdmin, isCompanyAdmin) : null}
          {props.mfgAdmin ? (
              <NavItem>
                <NavLink
                  active={props.mfgAdmin}>
                  {props.mfgName ? ui_helpers.replaceTextIfTooLong(14, props.mfgName) : <Spinner size='sm'/>}
                </NavLink>
              </NavItem>
          ) : null}
          {props.storeAdmin  ? (
              <NavItem>
                <NavLink
                  active={props.storeAdmin}>
                  {props.storeName ? ui_helpers.replaceTextIfTooLong(14, props.storeName) : <Spinner size='sm'/>}
                </NavLink>
              </NavItem>
          ) : null}
          {props.companyAdmin  ? (
              <NavItem>
                <NavLink
                  active={props.companyAdmin}>
                  {props.companyName ? ui_helpers.replaceTextIfTooLong(14, props.companyName) : <Spinner size='sm'/>}
                </NavLink>
              </NavItem>
          ) : null}
          <NavItem>
            <NavLink
                tag={Link}
                to={PATHNAMES.RESOURCES_DOCINDEX}
                active={location.pathname === PATHNAMES.RESOURCES_DOCINDEX}
            >
              Tutorials
            </NavLink>
          </NavItem>
          {props.inventory && props.invoiceNumber ? (
              <NavItem>
                <NavLink
                  tag={Link}
                  to='#'
                  active>
                  {props.invoiceNumber}
                </NavLink>
              </NavItem>
          ): null}
          {props.agreementNumber ? (
              <NavItem>
                <NavLink
                  tag={Link}
                  to="#"
                  active
                >
                  {props.agreementNumber}
                </NavLink>
              </NavItem>
          ) : null}
          {props.manufacturer ? (
            <NavItem>
              <NavLink
                tag={Link}
                to="#"
                active
              >
                {props.manufacturer}
              </NavLink>
            </NavItem>) : null}
          <NavItem>
            <NavLink
              tag={Button}
              className="nav-link-btn"
              onClick={() => setShowCalc(!showCalc)}
            >
              <FontAwesomeIcon icon="calculator" className="me-2" />
              Calculator
            </NavLink>
          </NavItem>
          <CalculatorModal isOpen={showCalc} setIsOpen={setShowCalc} />        
        </Nav>
        <Row style={{width: "100%", marginRight: 0}}>
          <Col sm="5" />
        {props.comments
          ? (<Col sm="1" className="pb-1">
              <CommentBell
                openCommentModal={() => setShowComments(!showComments)}
                commentCount={props.comments.totalItems}
              />
            </Col>)
          : null
        }
        {userCtx.currentUser ?
          (<Col className="searchCommentArea">
            {loading
              ? (<Spinner animation="border" size={"sm"} />)
              : (<InputGroup className="me-2" size="sm">
                  <Input type="text" placeholder="Search" value={searchTerm} onKeyDown={onSearchKeyDown}
                    maxLength="100" onChange={(e) => setSearchTerm(e.target.value)}></Input>
                  <Button color="primary" onClick={onSearch}>
                    <FontAwesomeIcon icon="search" />
                  </Button>
                </InputGroup>)
            }
            </Col>)
          : null
        }
        </Row>
      </div>
      {showComments
        ? (<CommentModal
            isOpen
            setIsOpen={(nextOpenState) => {
              // on close, refresh and set page back to 1
              if (!nextOpenState) {
                props.onSetPageCallback(1);
              }
              setShowComments(nextOpenState);
            }}
            undoMarkAsSeen={props.undoMarkAsSeen}
            comments={props.comments}
            markAsSeen={props.markAsSeen}
            markAllSeen={props.markAllSeen}
            refresh={props.refresh}
            onSetPageCallback={props.onSetPageCallback}
          />)
        : null
      }
      {searchResults === null
        ? null
        : (<Modal fullscreen="sm" size="lg" toggle={() => setSearchResults(null)} isOpen>
            <ModalHeader>
              Search Results
            </ModalHeader>
            <ModalBody>
              <Table size="sm">
                <thead>
                  <tr>
                    <th>Item Type</th>
                    <th>Serial/Invoice #</th>
                    <th>Status</th>
                    <th>Customer Name</th>
                    <th/>
                  </tr>
                </thead>
                <tbody>
                  {_.map(searchResults, sr => (
                    <tr key={sr.id}>
                      <td>{sr.itemTypeName}</td>
                      <td>{sr.serialNumber || sr.invoiceNumber}</td>
                      <td>{sr.statusName}</td>
                      <td>{sr.customerName}</td>
                      <td>
                        <a href={sr.url}>View</a>
                      </td>
                    </tr>))}
                </tbody>
              </Table>
            </ModalBody>
            <ModalFooter>
              <Row className={'d-flex justify-content-end'}>
                <Col>
                  <ButtonGroup className="float-end">
                    <Button onClick={() => setSearchResults(null)}>Close</Button>
                  </ButtonGroup>
                </Col>
              </Row>
            </ModalFooter>
          </Modal>)
      }
    </nav>)
  : null
}
  </>
);
}
export default MainNav;
