import React, {useState, useEffect, useRef, useContext, Fragment} from "react";
import {
    CardSettings,
    CardSettingsItem,
    CardSettingsParent,
    DropZone,
    CardSelectSetting
} from "./";
import { Fab, Action } from 'react-tiny-fab';
import {
    Button, ButtonGroup,
    Card, CardBody, CardHeader,
    Col, Row,
    Input, FormGroup,
    ModalBody, ModalHeader, Modal, ModalFooter,
    Spinner, UncontrolledTooltip,
    Label, InputGroup, InputGroupText
} from "reactstrap";
import Select from 'react-select';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {api, date_helpers, ui_helpers} from "../helpers";
import {useParams} from "react-router-dom";
import ToggleButton from "react-toggle-button";
import _ from 'lodash';
import {constants, UserContext} from "../utils";
import ReactTooltip from "react-tooltip";
import {MdMenu} from "react-icons/md";
import Alert from "react-s-alert-v3";
import classNames from "classnames";


const todaysDate = date_helpers.getSerialNumberDisplay(new Date());

const templateOptions = [
    {value: 0, label: 'Product Abbrev', display: 'ABBR', template: 'ProductAbbr'},
    // {value: 1, label: 'YYMMDD', display: `${todaysDate}`, template: 'YYMMDD'},
    {value: 2, label: 'Unique Num', display: '12001',  template: 'Seq'},
    // {value: 3, label: 'Length', display: '16', template: 'Length'},
    // {value: 4, label: 'Width', display: '10', template: 'Width'},
    {value: 5, label: 'Sales Initials', display: 'TJ',  template: 'SalesPersonsInitials'},
    {value: 6, label: 'Store Suffix', display: 'EDS',  template: 'StoreSuffix'},
    {value: 7, label: 'Date', display: `${todaysDate}`,  template: 'MMDDYY'},
    {value: 8, label: 'Size', display: '1020',  template: 'WidthxLength'},
];

const delimiterOption = [
    {value: 0, label: '-'},
    {value: 1, label: '+'},
    {value: 2, label: '~'},
    {value: 3, label: 'None'},
    // {value: -2, label: 'Custom'},
];

const SelectFormField = (props) => {
    const [options, setOptions] = useState(props.options);

    const onSelectChange = (value) => {
        props.handleSelectCallback(value, props.index);
    }

    return (
        <>
            <Card className={`serial-container mx-1`}>
                <Col>
                    <Select options={options}
                            onChange={(value) => onSelectChange(value)}
                            value={props.value}
                            className={'serial-select'}
                    />
                </Col>
                {props.index > 2 && props.count > 3
                    ? (<Col sm={1} className='d-flex align-items-start justify-content-center'>
                        <FontAwesomeIcon icon={'plus'} className='serial-close' onClick={() => props.removeCallback(props.index)}
                                         data-tip data-for={`tt-${props.index}`}/>
                    </Col>)
                    : null}
                <ReactTooltip
                    place={'top'}
                    id={`tt-${props.index}`}
                    type={'dark'}
                    effect={'solid'}
                    delayShow={250}>
                    <p style={{fontSize: '1rem', opacity: '1'}}>Click to remove item.</p>
                </ReactTooltip>

            </Card>
        </>
    );
};

const DataRow = props => {
    return (
        <Row className="rowSpacing">
            <Col xs="4">
                <strong>{props.header}</strong>
            </Col>
            <Col xs="8">{props.data}</Col>
        </Row>
    );
};

const maxTemplateItems = 6;

const NoInformation = (props) => {
    return (
        <small>{props.text ? props.text : 'No information provided.'}</small>
    )
};

const CardSettingSubItem = (props) => {
    const [isEditing, setIsEditing] = useState(false);
    const [showDivider, setShowDivider] = useState(false);

    useEffect(() => {
        setIsEditing(props.isEditing);
        // setShowDivider(props.divider);
    }, [props]);

    const getDivider = () => {
        return props.children && props.value
        || props.children && isEditing
            ? <hr/>
            : <></>
    };

    const getCardBooleanComponent = (value) =>
        value ? ui_helpers.greenCheck() : ui_helpers.redX();

    return (
        <>
            <Row>
                <div className={'d-flex justify-content-between'}>
                    <Label>{props.title}</Label>
                    {isEditing
                        ? <ToggleButton
                            value={props.value}
                            activeLabel={"Yes"}
                            inactiveLabel={"No"}
                            onToggle={(value) =>
                                props.onChange(props.name, !value)}/>
                        : getCardBooleanComponent(props.value)}
                </div>
            </Row>
            {getDivider()}
            {props.value || isEditing ? props.children : <></>}
        </>
    );
};


const CardSettingItem = (props) => {
const [isEditing, setIsEditing] = useState(false);
const [showDivider, setShowDivider] = useState(false);

    useEffect(() => {
        setIsEditing(props.isEditing);
        // setShowDivider(props.divider);
    }, [props]);

    const getDivider = () => {
        return props.children && props.value
        || props.children && isEditing
            ? <hr/>
            : <></>
    };

    const getCardBooleanComponent = (value) =>
        value ? ui_helpers.greenCheck() : ui_helpers.redX();

    return (
        <>
      <Row>
          <div className={'d-flex justify-content-between'}>
              <Label>{props.title}</Label>
              {isEditing
                  ? <ToggleButton
                      value={props.value}
                      activeLabel={"Yes"}
                      inactiveLabel={"No"}
                      onToggle={(value) =>
                          props.onChange(props.name, !value)}/>
                  : getCardBooleanComponent(props.value)}
          </div>
      </Row>
            {getDivider()}
            {props.main || props.value || isEditing ? props.children : <></>}
        </>
    );
};

export default function ManufacturerDetails(props) {
  const params = useParams();
  const mfgId = params.mfgId
    ? params.mfgId
    : props.mfgId;
  const [manufacturer, setManufacturer] = useState(props.manufacturer || {});
  const [isEditingDetails, setIsEditingDetails] = useState(false);
  const [states, setStates] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [unitTypeSelectList, setUnitTypeSelectList] = useState([]);
  const [loading, setLoading] = useState(false);
  //const [newlyEnabledElements, setNewlyEnabledElements] = useState([]);
  const [validationMessage, setValidationMessage] = useState([]);
  const [isOwnerIsActive, setIsOwnerIsActive] = useState(false);
  const [scrollElement, setScrollElement] = useState('');
  const [cashSaleHeaderImage, setCashSaleHeaderImage] = useState(null);
  const [clonedManufacturer, setClonedManufacturer] = useState(null);
  const [cancelModal, setCancelModal] = useState(false);
  const [serialNumberBoxCount, setSerialNumberBoxCount] = useState(3);
  const [serialTemplateOptions, setSerialTempalteOptions] = useState(templateOptions);
  const [usedTemplateItems, setUsedTemplateItems] = useState([]);
  const [serialNumber, setSerialNumber] = useState([]);
  const [serialNumberDisplay, setSerialNumberDisplay] = useState([]);
  const [selectedDelimiter, setSelectedDelimiter] = useState('-');
  const [serialNumberDelimiter, setSerialNumberDelimiter] = useState('');
  const [isEditingSerialNumber, setIsEditingSerialNumber] = useState(false);
  const [showCustomDelimiter, setShowCustomDelimiter] = useState(false);
  const [serialNumberIsNotValid, setSerialNumberIsNotValid] = useState(false);
  const [customDelimiter, setCustomDelimiter] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [backupSerial, setBackUpSerial] = useState([]);
  const [nextSerialSeq, setNextSerialSeq] = useState(0);
  const [fabOpen, setFabOpen] = useState(false);
  const {currentUser} = useContext(UserContext);

  const isOwner = useRef(null);
  const enableCashSales = useRef(null);
  const enableCreditCardPayments = useRef(null);
  const enableCustomSerialGeneration = useRef(null);
  const enableRTOContracts = useRef(null);

  const isMfgAdmin = ui_helpers.isManufacturerAdministrator(currentUser, manufacturer.id);
  const isSysAdmin = ui_helpers.isSystemAdministrator(currentUser);

  useEffect(getCompaniesList, []);

  //Manufacturer Details
  useEffect(() => {
    if(!props.manufacturer) return;
    setManufacturer(props.manufacturer);
  }, [props.manufacturer]);

    const companyOptions = companies.map(c => ({value: c.id, label: c.name}));
    const manufacturerCompany = manufacturer.company ? manufacturer.company : {};
    const [tempOwnerMfg, setTempOwnerMfg] = useState({value: manufacturerCompany.id, label: manufacturerCompany.name});

    const toggleEditDetails = () => {
      setIsEditingDetails(!isEditingDetails);
    };

    const getRegions = () => {
      return api.fetch('reference/RegionList')
          .then(r => ({ states: ui_helpers.idNameToValueLabel(r.data.data) }))
          .catch(error => Alert.error("There was an error loading the state list"));
    };

    function getCompaniesList() {
      api.fetch(`company/Companies`).then(response => {
        setCompanies(response.data.data);
      });
    }

    const onChangeMfg = (fieldName, fieldValue) => {
        let newMfg = _.cloneDeep(manufacturer);
        newMfg[fieldName] = fieldValue;
        props.onSetManufacturerCallback(newMfg);
    };

    // const onBlur = (value, fieldName) => {
    //     let newMfg = _.cloneDeep(manufacturer);
    //     newMfg[fieldName] = ui_helpers.capitalizeField(value);
    //     props.onSetManufacturerCallback(newMfg);
    // }

    const onChangeCompany = (fieldName, fieldValue) => {
        let newCompany = _.cloneDeep(manufacturer); 
        newCompany.company[fieldName] = fieldValue;
        props.onSetManufacturerCallback(newCompany);
    };

    const onSettingsMfgChange = (fieldName, fieldValue) => {
        let newMfg = _.cloneDeep(manufacturer);
        newMfg[fieldName] = fieldValue;
        // props.setMessage({message: 'You must save your changes for them to take affect.',
        //     flavor: constants.flavor.warning});
        if(fieldName === 'isOwner' && fieldValue === true)
            setScrollElement(fieldName);
        if(fieldName === 'enableCashSales' && fieldValue === true)
            setScrollElement(fieldName);
        if(fieldName === 'enableRtoContracts' && fieldValue === true)
            setScrollElement(fieldName);
        if(fieldName === 'enableCreditCardPayments' && fieldValue === true)
            setScrollElement(fieldName);
        if(fieldName === 'enableCustomSerialGeneration' && fieldValue === true)
            setScrollElement(fieldName);
        if(fieldName === 'enableRTOContracts' && fieldValue === true)
            setScrollElement(fieldName);
        setManufacturer(newMfg);
        props.onSetManufacturerCallback(newMfg);
    };

    const promptCancellation = () => {
        (!_.isEqual(manufacturer, clonedManufacturer))
            ? openCancelModal()
            : cancelEdit();
    };

    const openCancelModal = () => {
      setCancelModal(true);
    };

    async function saveManufacturerDetails() {
        let isSwitchingMfgOwner = false;
        let vm = _.cloneDeep(manufacturer);
        let mfgWarnings = ui_helpers.validateMfg(vm);
        if (vm.isOwner && vm.enableCashSales) {
            if (vm?.company && vm.company.id > 0 && !vm.company.cashSaleHeaderImageExisted && !cashSaleHeaderImage) {
                mfgWarnings.push("Either provide a cash sale header image or choose to disable cash sales.");
            }
            if (vm?.company && vm.company.id < 0 && !cashSaleHeaderImage) {
                mfgWarnings.push("Either provide a cash sale header image or choose to disable cash sales.");
            }
        }
        if (!vm.id && vm.isOwner && (!vm.company?.states || vm.company?.states.length === 0)) {
            mfgWarnings.push("Select at least 1 state in which this manufacturer does business.");
        }
        // if(vm.enableCustomSerialGeneration && serialNumber)
        if (mfgWarnings.length) {
            Alert.warning(mfgWarnings.join(' '));
            // props.setMessage({message: mfgWarnings.join(' '), flavor: constants.flavor.error});
            return;
        }
        if (vm.isOwner)
        {
            vm.company = _.cloneDeep(vm.company); //Object.assign({}, vm.company);
        }
        vm = ui_helpers.prepareMfgPayload(vm);
        if (vm.isOwner && !isSwitchingMfgOwner)
        {
            vm = ui_helpers.prepareMfgCompanyPayload(vm);
        }
        if (vm.company) vm.isOwner = true;
        if (tempOwnerMfg && tempOwnerMfg.value) {
            if (!vm.company) vm.company = {};
            isSwitchingMfgOwner = true;
            if (isSwitchingMfgOwner) {
                try {
                    const response = await api.fetch(`company/One/${tempOwnerMfg.value}`);
                    vm.company = response.data.data;
                }
                catch (error) {
                    console.error(error);
                    return;
                }
            }
        }
        //vm.defaultManufacturerUnitTypeId = -10;
        setIsLoading(true);
        await api.post(`manufacturer/SaveManufacturerDetails?isSwitchingMfgOwner=${isSwitchingMfgOwner}`, vm)
        .then(response => {
            if (response.data.success) {
                setFabOpen(false);
                Alert.success('Your changes have been saved.');
                // props.setMessage({message: "Your changes have been saved.", flavor: constants.flavor.success});
                //let newMfg = ui_helpers.shapeMfg(response.data.data, companies)
                //   this.setState({manufacturer: newMfg, isEditingDetails: false});

                if (vm.isOwner && cashSaleHeaderImage) {
                    let companyId = response.data.data.company && response.data.data.company.id
                    if(companyId) {
                        api.post_form_data('Company/SaveHeader',
                        ui_helpers.buildCaptiveHeaderPayload(companyId, cashSaleHeaderImage))
                        .then(r => {
                            if (!r.data.success) {
                                Alert.error('There was an error saving the cash sale header image');
                            }
                        }).catch(error => console.error(error));
                    }
                }

                if(vm.isOwner && (vm.enableBuildFeature || vm.enableBuildFeaturePreview) && serialNumber) {
                    saveSerialNumberTemplate();
                }
                // props.refreshMfg();
                props.onSetManufacturerCallback(response.data.data);
                setIsEditingDetails(false);
            }
            else
            {
                Alert.error(response.data.message);
            }
        }).finally(() => setIsLoading(false))
            .catch(api.catchHandler);
    }

    useEffect(() => {
        if(scrollElement === '') return;
        switch(scrollElement)
        {
            case 'isOwner':
                if (isOwner?.current)
                    isOwner.current.scrollIntoView({behavior: 'smooth'});
                break;
            case 'enableCashSales':
                if (enableCashSales?.current)
                    enableCashSales.current.scrollIntoView({behavior: 'smooth'});
                break;
            case 'enableCreditCardPayments':
                if (enableCreditCardPayments?.current)
                    enableCreditCardPayments.current.scrollIntoView({behaviour: 'smooth'});
                break;
            case 'enableCustomSerialGeneration':
                if (enableCustomSerialGeneration?.current)
                    enableCustomSerialGeneration.current.scrollIntoView({behavior: 'smooth'});
                break;
            case 'enableRTOContracts':
                if (enableRTOContracts?.current)
                    enableRTOContracts.current.scrollIntoView({behavior: 'smooth'});
                break;
        }
        setScrollElement('')
    }, [scrollElement]);

    const cancelEdit = () => {
        setIsEditingDetails(false);
        setCancelModal(false);
        // props.onSetManufacturerCallback(clonedManufacturer);
        props.setMessage(constants.ALERT_OBJECT);
        setManufacturer(clonedManufacturer);
        // setClonedManufacturer(null);
    };

    //Serial Numbers
    const handleTemplateSelectChange = (value, index) => {
        let newTemplate = serialNumber.slice();
        let newDisplay = serialNumberDisplay.slice();
        if(newTemplate[index])
        {
            newTemplate[index] = value.template;
            newDisplay[index] = value.display;
        }
        else {
            newTemplate.push(value.template);
            newDisplay.push(value.display);
        }

        setSerialNumber(newTemplate);
        setSerialNumberDisplay(newDisplay);
        setUsedTemplateItems(value);
    };

    const getMfgSerialTemplate = () => {
        setIsLoading(true);
        api.fetch(`manufacturer/GetMfgSerialNumber/${mfgId}`)
            .then(r => {
                if(!r.data) return;
                if(r.data.serialNumberTemplate)
                {
                    let serialNum = r.data.serialNumberTemplate.split('~');
                    setSerialNumber(serialNum);
                    setSerialNumberBoxCount(serialNum.length);
                    setNextSerialSeq(r.data.nextSerialNumberSeq);
                    getSerialDisplay(serialNum);
                }
                if(r.data.serialNumberDelimiter)
                    setSerialNumberDelimiter(r.data.serialNumberDelimiter);
            }).finally(() => setIsLoading(false));
    };

    const saveSerialNumberTemplate = () => {
        let payload = {
            mfgId: mfgId,
            serialNumberTemplate: serialNumber ? convertSerialNumberToString(serialNumber) : null,
            serialNumberDelimiter: serialNumberDelimiter ? serialNumberDelimiter : null,
            nextSerialNumberSeq: parseInt(nextSerialSeq),
        };
        let isNotValid = validateSerialTemplate(serialNumber);
        if(isNotValid) {
            setSerialNumberIsNotValid(true);
        } else {
            api.post('manufacturer/SaveSerialNumberTemplate', payload)
                .then(r => {
                    if(r.data.success){
                        getMfgSerialTemplate();
                        setIsEditingSerialNumber(false);
                        setSerialNumberIsNotValid(false);
                    }
                }).catch(err => console.error(err));
        }
    };

    const getSerialDisplay = (serialNum) => {
        let newArray = [];
        for (let i = 0; i < serialNum.length; i++)
        {
            let found = _.find(templateOptions, t => {
                return t.template === serialNum[i];
            });
            if (found) {
                newArray.push(found.display);
            }

        }
        setSerialNumberDisplay(newArray);
    };

    const handleSelectedDelimiter = (value) => {
      setSerialNumberDelimiter(value.label);
    };

    const convertSerialNumberToString = (serialTemplate) => {
      return serialTemplate.join('~');
    };

    const removeSerialElement = (index) => {
        let newTemplate = serialNumber.slice();
        let newDisplayTemplate = serialNumberDisplay.slice();
        newTemplate.splice(index, 1);
        newDisplayTemplate.splice(index, 1);
        setSerialNumber(newTemplate);
        setSerialNumberDisplay(newDisplayTemplate);
        decrementSerialCount();
    };

    const validateSerialTemplate = (array) => {
        return new Set(array).size !== array.length;
    };

    const addSerialCount= () => {
        let count = serialNumberBoxCount;
        count++;
        setSerialNumberBoxCount(count);
    };

    const decrementSerialCount = () => {
        let count = serialNumberBoxCount;
        count --;
        setSerialNumberBoxCount(count);
    };

    useEffect(getMfgSerialTemplate, []);

    // <span
    //     className={`text-center ${
    //         serialNumber.length ? null : 'text-danger'
    //     }`}>
    //                                   {serialNumber && serialNumberDisplay &&
    //                                   serialNumberDisplay.length &&
    //                                   isEditingDetails ? (
    //                                       <strong>
    //                                           Example: {serialNumberDisplay.join(` ${serialNumberDelimiter} `)}
    //                                       </strong>
    //                                   ) : serialNumber ? (
    //                                       <h5>
    //                                           Below is the current Serial Number template your Manufacturer uses
    //                                           for new Inventory
    //                                       </h5>
    //                                   ) : (
    //                                       'Your Manufacturer does not have a serial number template.' +
    //                                       ' You must create one to create new inventory items.'
    //                                   )}
    //                                 </span>

    function getSerialNumberDisplay() {
        return serialNumberDelimiter === 'None'
            ? serialNumberDisplay.join('')
            : serialNumberDisplay.join(` ${serialNumberDelimiter} `);
    }


    function handleSerialNumberDisplay() {
        if(isEditingDetails) {
            if(serialNumber.length) {
                return (
                    <strong>
                        Example:
                        {getSerialNumberDisplay()}
                    </strong>
                );
            }
        } else {
            if (!serialNumber.length) {
                return (
                    'Your Manufacturer does not have a serial number template.' +
                    'You must create one in order to use this feature.'
                );
            }
        }

    }

    function onEdit() {
      setClonedManufacturer(_.cloneDeep(manufacturer));
      setIsEditingDetails(true);
    }

    return (
    <>
        <Modal
            isOpen={cancelModal}
            toggle={() => setCancelModal(false)}
            centered style={{ zIndex: 10 }}
        >
          <ModalHeader>Cancel Edit</ModalHeader>
          <ModalBody>
            <Row className='d-flex align-items-center'>
              <span>You have unsaved changes. Are you sure you want to cancel?</span>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button className='light' onClick={() => { setCancelModal(false) }}>Close</Button>
            <Button color='primary' onClick={cancelEdit}>Continue</Button>
          </ModalFooter>
        </Modal>
        <Row>
          <Col>
            <h2 className="py-2">
              {manufacturer ? manufacturer.name : ""}
            </h2>
          </Col>
        </Row>
        <Row className='m-1'>
          <Col sm={3}>
            <Card className='mb-2'>
                     <CardHeader className='d-flex align-items-center'>
                     <Col><h5>Settings</h5></Col>
                     <Col>
                         {isEditingDetails
                             ? (<ButtonGroup className='float-end'>
                                 <Button color='secondary' onClick={promptCancellation}>Cancel</Button>
                                 <Button color='success' onClick={saveManufacturerDetails}>
                                    <FontAwesomeIcon icon={'save'} className='text-white'/>
                                 </Button>
                             </ButtonGroup>)
                             : (isSysAdmin || isMfgAdmin ? (
                             <Button size={'sm'} className='float-end' onClick={onEdit}>
                                 <FontAwesomeIcon icon={'edit'}/>
                             </Button>) : null)}
                     </Col>
                 </CardHeader>
                <CardBody>
                    <CardSettings>
                        <CardSelectSetting
                            title={"Owner Manufacturer"}
                            name={"ownerCompanyId"}
                            onChange={(selectedOption) => {
                                setTempOwnerMfg({value: selectedOption.value, label: selectedOption.label});
                                if (manufacturer.isOwner == false) { // !props.optionName && selectedOption.value ||
                                    onChangeMfg('isOwner', true);
                                }
                                onChangeMfg('ownerCompanyId', selectedOption.value);
                            }}
                            value={tempOwnerMfg}
                            placeholder={"Select Owner"}
                            optionName={manufacturerCompany.name}
                            options={companyOptions}
                            isEditing={isEditingDetails}
                            readOnly={!isSysAdmin}
                            divider
                        />
                            {/*<CardSettingsParent onChange={onChangeMfg} value={manufacturer.enableCashSales}*/}
                            {/*                    name={'enableCashSales'} title={'Allows Cash Sales'}*/}
                            {/*                    isEditing={isEditingDetails}*/}
                            {/*                    divider>*/}
                            {/*    <CardSettingsItem onChange={onChangeMfg} value={manufacturer.enableCreditCardPayments}*/}
                            {/*                      name={'enableCreditCardPayments'} title={'Allows Credit Cards'}*/}
                            {/*                      isEditing={isEditingDetails} divider/>*/}

                            {/*</CardSettingsParent>*/}
                            {/*<CardSettingsItem onChange={onChangeMfg} value={manufacturer.enableRTOContracts}*/}
                            {/*                name={'enableRTOContracts'} title={'Allows RTO Contracts'} isEditing={isEditingDetails}*/}
                            {/*                divider/>*/}
                        <CardSettingsParent
                        onChange={onChangeMfg}
                        value={manufacturer.enableBuildFeature}
                        name='enableBuildFeature'
                        title='Enable Build Feature'
                        isEditing={isEditingDetails}
                        divider
                        >
                            <CardSettingsItem
                                onChange={onChangeMfg}
                                value={manufacturer.enableCustomSerialGeneration}
                                name={'enableCustomSerialGeneration'}
                                title={'Allow Custom Serial Numbers'}
                                isEditing={isEditingDetails}
                                divider
                            />
                        </CardSettingsParent>
                        <CardSettingsItem
                          onChange={onChangeMfg}
                          value={manufacturer.enableBuildFeaturePreview}
                          name='enableBuildFeaturePreview'
                          title='Enable Build Feature Preview'
                          isEditing={isEditingDetails}
                          divider
                        />
                        <CardSettingsItem
                          onChange={onChangeMfg}
                          value={manufacturer.hideManufacturerUnitTypeSelection}
                          name='hideManufacturerUnitTypeSelection'
                          title='Hide Material Selection?'
                          isEditing={isEditingDetails}
                        />
                    </CardSettings>
                </CardBody>
            </Card>
          </Col>
          <Col>
              <Card>
                  <CardHeader>
                      <Row>
                          <div className='d-flex justify-content-between'>
                              <Col><h5>Manufacturer Details</h5></Col>
                              <Col>
                                  {isEditingDetails
                                      ? (<ButtonGroup className='float-end'>
                                          <Button color='secondary' onClick={promptCancellation}>Cancel</Button>
                                          <Button color='success' onClick={saveManufacturerDetails}>
                                              <FontAwesomeIcon icon={'save'} className='text-white'/>
                                          </Button>
                                      </ButtonGroup>)
                                      : (isSysAdmin || isMfgAdmin ?  <Button size={'sm'} className='float-end' onClick={onEdit}>
                                          <FontAwesomeIcon icon={'edit'}/>
                                      </Button> : null)}
                              </Col>
                          </div>
                      </Row>
                  </CardHeader>
                  <CardBody>
                      {isEditingDetails
                          ? (<>
                              <FormGroup className='mb-1'>
                                  <Label>Manufacturer Name</Label>
                                  <Input
                                    type='text'
                                    name='name'
                                    value={manufacturer.name}
                                    // onBlur={(e) => onBlur(e.target.value, 'name')}
                                    onChange={(e) => onChangeMfg(e.target.name, e.target.value)}
                                  />
                              </FormGroup>
                              <FormGroup row>
                                <Col>
                                  <Label>Address</Label>
                                  <Input type='text' name='address1' value={manufacturer.address1}
                                         onChange={(e) =>
                                             onChangeMfg(e.target.name, e.target.value)}/>
                                </Col>
                              </FormGroup>
                              <FormGroup row>
                                <Col>
                                  <Label>Address 2</Label>
                                  <Input type='text' name='address2' value={manufacturer.address2}
                                         onChange={(e) =>
                                             onChangeMfg(e.target.name, e.target.value)}/>
                                </Col>
                              </FormGroup>
                              <FormGroup row className='mb-1'>
                                  <Col sm={8}>
                                      <Label>City</Label>
                                      <Input type='text' name='city' value={manufacturer.city}
                                             onChange={(e) =>
                                                 onChangeMfg(e.target.name, e.target.value)}/>
                                  </Col>
                                  <Col sm={2}>
                                      <Label>State</Label>
                                      <Input type='text' name='state' value={manufacturer.state}
                                             onChange={(e) =>
                                                 onChangeMfg(e.target.name, e.target.value)}/>
                                  </Col>
                                  <Col sm={2}>
                                      <Label>Zip</Label>
                                      <Input type='text' name='zip' value={manufacturer.zip}
                                             onChange={(e) =>
                                                 onChangeMfg(e.target.name, e.target.value)}/>
                                  </Col>
                              </FormGroup>
                              <FormGroup row>
                                  <Col>
                                      <Label>Phone Number</Label>
                                      <Input type='text' name='phone' value={manufacturer.phone}
                                             onChange={(e) =>
                                                 onChangeMfg(e.target.name, e.target.value)}/>
                                  </Col>
                                  <Col>
                                      <Label>Fax Number</Label>
                                      <Input type='text' name='fax' value={manufacturer.fax}
                                             onChange={(e) =>
                                                 onChangeMfg(e.target.name, e.target.value)}/>
                                  </Col>
                                  <Col>
                                      <Label>Customer Service Email</Label>
                                      <Input type='text' name='customerServiceEmail' value={manufacturer.customerServiceEmail}
                                        onChange={(e) => onChangeMfg(e.target.name, e.target.value)} maxLength="150" />
                                  </Col>
                              </FormGroup>
                              <FormGroup row>
                                  <Col xs="3">
                                  <Label>Quote Expiration <FontAwesomeIcon icon="info-circle" id="quoteInfo" /></Label>
                                  <InputGroup>
                                      <Input type='number' name='quoteExpirationInDays' value={manufacturer.quoteExpirationInDays}
                                          onChange={(e) =>
                                              onChangeMfg(e.target.name, e.target.value)} />
                                      <InputGroupText>
                                          Days
                                      </InputGroupText>
                                  </InputGroup>
                                  <UncontrolledTooltip
                                      placement="right"
                                      target="quoteInfo"
                                  >
                                      Identifies how many days in the future a quote will expire. Enter 0 for same day expiration. Expiration defaults to 14 days if no days are set.
                                  </UncontrolledTooltip>
                                  </Col>
                                  <Col xs="3">
                                  <Label>Inv Wholesale Discount %</Label>
                                  <InputGroup>
                                      <Input type='number' name='inventoryDiscount' value={manufacturer.inventoryDiscount}
                                          onChange={(e) =>
                                              onChangeMfg(e.target.name, e.target.value)} />
                                      <InputGroupText>
                                          %
                                      </InputGroupText>
                                  </InputGroup>
                                  </Col>
                              </FormGroup>
                          </>)
                          : (<>
                              <Col>

                                  <Row>
                                      <Label><strong>Manufacturer Name</strong></Label>
                                      <span>{manufacturer && manufacturer.name ? manufacturer.name
                                          : <NoInformation/>}</span>
                                  </Row>
                                  <Row className='mt-2'>
                                      <Label><strong>Address</strong></Label>
                                      <span>{manufacturer && manufacturer.address1 ? manufacturer.address1
                                          :  <NoInformation/>}</span>
                                  </Row>
                                  <Row className='mt-2'>
                                      <Label><strong>Address 2</strong></Label>
                                      <span>{manufacturer && manufacturer.address2 ? manufacturer.address2
                                          : <NoInformation/>}</span>
                                  </Row>
                                  <Row className='mt-2'>
                                      <Col>
                                          <Row>
                                              <Label><strong>City</strong></Label>
                                              <span>{manufacturer && manufacturer.city ? manufacturer.city
                                                  : <NoInformation/>}</span>
                                          </Row>
                                      </Col>
                                      <Col>
                                          <Row>
                                              <Label><strong>State</strong></Label>
                                              <span>{manufacturer && manufacturer.state ? manufacturer.state
                                                  : <NoInformation/>}</span>
                                          </Row>
                                      </Col>
                                      <Col>
                                          <Row>
                                              <Label><strong>Zip</strong></Label>
                                              <span>{manufacturer && manufacturer.zip ? manufacturer.zip
                                                  : <NoInformation/>}</span>
                                          </Row>
                                      </Col>
                                  </Row>
                                  <Row className='mt-2'>
                                      <Col>
                                          <div><Label><strong>Phone Number</strong></Label></div>
                                          <span>{manufacturer && manufacturer.phone ? manufacturer.phone
                                              : <NoInformation/>}</span>
                                      </Col>
                                      <Col>
                                          <div><Label><strong>Fax Number</strong></Label></div>
                                          <span>{manufacturer && manufacturer.fax ? manufacturer.fax : <NoInformation/>}</span>
                                      </Col>
                                      <Col>
                                          <div><Label><strong>Customer Service Email</strong></Label></div>
                                          <span>{manufacturer && manufacturer.customerServiceEmail ? manufacturer.customerServiceEmail : <NoInformation/>}</span>
                                      </Col>
                                  </Row>
                                  <Row className='mt-2'>
                                      <Col xs="3">
                                          <Row>
                                              <Label><strong>Quote Expiration</strong></Label>
                                              <span>{
                                                manufacturer && manufacturer.quoteExpirationInDays >= 0 
                                                  ? manufacturer.quoteExpirationInDays === 0
                                                      ? "Same Day"
                                                      : manufacturer.quoteExpirationInDays + " Day(s)"
                                                  : <NoInformation/>
                                              }</span>
                                          </Row>
                                      </Col>
                                      <Col xs="3">
                                          <Row>
                                              <Label><strong>Inv Wholesale Discount</strong></Label>
                                              <span>{manufacturer && manufacturer.inventoryWholesaleDiscountPercent
                                              ? manufacturer.inventoryWholesaleDiscountPercent + "%"
                                              : <NoInformation/>}</span>
                                          </Row>
                                      </Col>
                                  </Row>
                              </Col>
                          </>)}
                  </CardBody>

              </Card>
              {manufacturer.isOwner
                  ? (<Card className={`mt-2`} innerRef={isOwner}>
                      <CardHeader>
                          <h5>Owner Company Information</h5>
                      </CardHeader>
                      <CardBody>
                          {isEditingDetails
                              ? (<>
                                  <FormGroup>
                                      <Label>Address</Label>
                                      <Input type='text' name='remitTo1'
                                             value={manufacturer.company?.remitTo1} onChange={(e) =>
                                          onChangeCompany(e.target.name, e.target.value)}/>
                                      <Label>Address 2</Label>
                                      <Input type='text' name='remitTo2'
                                             value={manufacturer.company?.remitTo2} onChange={(e) =>
                                          onChangeCompany(e.target.name, e.target.value)}/>
                                      <Label>City, State, Zip</Label>
                                      <Input type='text' name='remitTo3'
                                             value={manufacturer.company?.remitTo3} onChange={(e) =>
                                          onChangeCompany(e.target.name, e.target.value)}/>
                                      <Label>Phone, Fax, Email</Label>
                                      <Input type='text' name='remitTo4'
                                             value={manufacturer.company?.remitTo4} onChange={(e) =>
                                          onChangeCompany(e.target.name, e.target.value)}/>
                                  </FormGroup>
                              </>)
                              : <>
                                  <Col>
                                      <Row>
                                          <Label><strong>Address</strong></Label>
                                          <span>
                                        {manufacturer && manufacturer.company && manufacturer.company.remitTo1
                                            ? manufacturer.company.remitTo1
                                            : <NoInformation/>}
                                      </span>
                                      </Row>
                                      <Row className='mt-2'>
                                          <Label><strong>Address 2</strong></Label>
                                          <span>
                                          {manufacturer && manufacturer.company && manufacturer.company.remitTo2
                                              ? manufacturer.company.remitTo2
                                              : <NoInformation/>}
                                      </span>
                                      </Row>
                                      <Row className='mt-2'>
                                          <Label><strong>City, State, Zip</strong></Label>
                                          <span>
                                          {manufacturer && manufacturer.company && manufacturer.company.remitTo3
                                              ? manufacturer.company.remitTo3
                                              : <NoInformation/>}
                                      </span>
                                      </Row>
                                      <Row>
                                          <Col>
                                              <Row className='mt-2'>
                                                  <Label><strong>Phone, Fax, Email</strong></Label>
                                                  <span>
                                                  {manufacturer && manufacturer.company && manufacturer.company.remitTo4
                                                      ? manufacturer.company.remitTo4
                                                      : <NoInformation/>}
                                      </span>
                                              </Row>
                                          </Col>
                                      </Row>
                                  </Col>
                              </>}
                      </CardBody>
                  </Card>)
                  : null}
              {manufacturer && manufacturer.isOwner
              && manufacturer.enableCashSales
                  ? <Card className='mt-2' innerRef={enableCashSales}>
                      <CardHeader>
                          <h5>Cash Sale Image</h5>
                      </CardHeader>
                      <CardBody>
                          {isEditingDetails 
                            ? (<Fragment>
                                {manufacturer?.ownerCompanyId && manufacturer.ownerCompanyId > 0 &&
                                    <img alt='Header' style={{ width: "167px" }}
                                        src={`api/public/GetCompanyHeader/${manufacturer.ownerCompanyId}`} />
                                }
                                <DropZone
                                    acceptTypes={["image/png"]}
                                    hideCancel
                                    contentTypeId={constants.CASH_SALE_INVOICE_TEMPLATE_ID}
                                    captiveContext={{
                                        isDefault: true,
                                        regionId: 0,
                                        cultureId: constants.CULTURE_ENGLISH_ID
                                    }}
                                    onSave={(file) => setCashSaleHeaderImage(file)}
                                    companyId={manufacturer.company?.id}
                                    message1="Template headers must be PNG format."
                                    message2="Preferred width is 1388px wide x 258px high or at least this ratio of Width to Height and smaller."
                                    //message1="Logos must be PNG format."
                                    //message2="Preferred width is 167px wide x 75px high or at least this ratio of Width to Height."
                                    message3="Header will be uploaded on save of manufacturer details"
                                />
                                </Fragment>) 
                            : (<Fragment>
                                {manufacturer?.ownerCompanyId && manufacturer.ownerCompanyId > 0 
                                    ?
                                        <img alt='Header' style={{ width: "167px" }} src={`api/public/GetCompanyHeader/${manufacturer.ownerCompanyId}`} />
                                    : <div className="p-2 justify-content-center">
                                        <FontAwesomeIcon icon='image' size="1x" className="mr-2"/> No Header Available
                                     </div>  
                                }

                            </Fragment>)}
                      </CardBody>
                  </Card> : null}
              {manufacturer
              && manufacturer.isOwner
              && manufacturer.enableCreditCardPayments
                  ? (
                      <>
                          <Card innerRef={enableCreditCardPayments} className='mt-2'>
                              <CardHeader>
                                  <h5>Cash Sale Credit Card Processing</h5>
                              </CardHeader>
                              <CardBody>
                                  <Row>
                                      <Col>
                                          <Label>Payment Processor API Service</Label>
                                      </Col>
                                      <Col>
                                          <Select options={constants.paymentProcessorTypes}
                                                  value={_.find(constants.paymentProcessorTypes, p => p.value === manufacturer.company?.typeOfPaymentProcessor)}
                                                  onChange={(value) => onChangeCompany('typeOfPaymentProcessor', value.value)}/>
                                      </Col>
                                  </Row>
                                  <Row className='mt-2'>
                                      <Col>
                                          <Label>API Key</Label>
                                      </Col>
                                      <Col>
                                          <Input type='text' name="paymentGatewayApiLoginId"
                                                 value={manufacturer.company?.paymentGatewayApiLoginId} onChange={(e) =>
                                              onChangeCompany(e.target.name, e.target.value)}/>
                                      </Col>
                                  </Row>
                                  <Row className='mt-2'>
                                      <Col>
                                          <Label>Transaction Key</Label>
                                      </Col>
                                      <Col>
                                          <Input type='text' name="paymentGatewayTransactionKey"
                                                 value={manufacturer.company?.paymentGatewayTransactionKey} onChange={(e) =>
                                              onChangeCompany(e.target.name, e.target.value)}/>
                                      </Col>
                                  </Row>
                              </CardBody>
                          </Card>
                      </>
                  )
                  : null}
                  {manufacturer && manufacturer.isOwner && manufacturer.enableCustomSerialGeneration
                            ? <><Card innerRef={enableCustomSerialGeneration} className='mt-2'>
                                <CardHeader className='d-flex align-items-center'>
                                    <h5>Serial Number</h5>
                                </CardHeader>
                                <CardBody>
                                    <Row className='d-flex justify-content-center'>
                                    <span className={classNames({'text-danger': !serialNumber.length}, 'text-center')}>
                                        {isEditingDetails ? handleSerialNumberDisplay() : handleSerialNumberDisplay()}
                                    </span>
                                        <hr className='mt-1 w-75' />
                                    </Row>
                                    <Row className='mt-2 d-flex justify-content-center'>
                                        {serialNumberIsNotValid ? (
                                            <Row className='d-flex justify-content-center text-danger text-center mb-1'>
                                                <strong>
                                                    You cannot have repeating template items in your Serial Number.
                                                    Please fix this and hit save again.
                                                </strong>
                                            </Row>
                                        ) : null}
                                        {isEditingDetails ? (
                                            <div className='d-flex justify-content-center align-items-center bg-light'>
                                                {_.times(serialNumberBoxCount, (i) => {
                                                    return (
                                                        <SelectFormField
                                                            index={i}
                                                            options={serialTemplateOptions}
                                                            usedOptions={usedTemplateItems}
                                                            value={_.find(serialTemplateOptions, (s) => {
                                                                return serialNumber[i] === s.template;
                                                            })}
                                                            handleSelectCallback={handleTemplateSelectChange}
                                                            removeCallback={removeSerialElement}
                                                            count={serialNumberBoxCount}
                                                            selectedDelimiter={selectedDelimiter}
                                                        />
                                                    );
                                                })}
                                            </div>
                                        ) : (
                                            <div className='w-100 d-flex justify-content-center bg-light'>
                                                <Row>
                                                    <Col>
                                                        <h4>
                                                            {getSerialNumberDisplay()}
                                                        </h4>
                                                    </Col>
                                                </Row>
                                            </div>
                                        )}
                                    </Row>
                                    <hr />
                                    <Row className='d-flex align-items-center'>
                                        {isEditingDetails ? (
                                            <>
                                                <Col sm={1}>
                                                    <Label>Separator: </Label>
                                                </Col>
                                                <Col sm={2}>
                                                    <Select
                                                        options={delimiterOption}
                                                        value={serialNumberDelimiter ? _.find(delimiterOption, d => {
                                                            return d.label === serialNumberDelimiter;
                                                        }) : null}
                                                        onChange={(value) => handleSelectedDelimiter(value)}
                                                    />
                                                </Col>

                                                {serialNumberDelimiter && customDelimiter ? (
                                                    <>
                                                        <Col sm={1}>
                                                            <Input
                                                                type='text'
                                                                maxLength={1}
                                                                value={serialNumberDelimiter}
                                                                onChange={(e) => setSerialNumberDelimiter(e.target.value)}
                                                            />
                                                        </Col>
                                                    </>
                                                ) : null}
                                                <Col sm={2}>
                                                   <Label>Sequence Number:</Label>
                                                </Col>
                                                <Col sm={1}>
                                                    <Input type={'text'} value={nextSerialSeq}
                                                           onChange={(e) =>
                                                               setNextSerialSeq(e.target.value)}/>
                                                </Col>
                                            </>
                                        ) : null}
                                    </Row>
                                    <Row className='my-1'>
                                        <Col>
                                            {isEditingDetails
                                                ? (serialNumberBoxCount < maxTemplateItems )
                                                    ? (<Button className='bg-success' onClick={() =>
                                                        addSerialCount()}>
                                                        Add Field
                                                    </Button>)
                                                    : null
                                                : null}
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                            </>
                            : null}
                    </Col>

                </Row>
                {isSysAdmin || isMfgAdmin ? (
                <Fab
                    mainButtonStyles={{
                        backgroundColor: `${isEditingDetails ? '#5cb85c' : '#0275d8'}`
                    }}
                    style={{
                        bottom: 0,
                        right: 0,
                    }}
                    isOpen={fabOpen}
                    icon={isLoading ? <Spinner/> : <MdMenu/>}
                    event={'click'}
                    alwaysShowTitle={true}
                >
                    {isEditingDetails
                        ? <Action
                            style={{backgroundColor: '#5cb85c'}}
                            text={'Save'}
                            onClick={saveManufacturerDetails}>
                            <FontAwesomeIcon icon={'save'}/>
                        </Action>
                        : null}
                    {isEditingDetails
                        ? <Action onClick={promptCancellation} text='Cancel Edit' style={{backgroundColor: '#b91f19'}}>
                            <FontAwesomeIcon icon={'plus'} style={{transform: 'rotate(45deg)'}}/>
                          </Action>
                        : null}
                    {isEditingDetails
                        ? null
                        : <Action onClick={onEdit} text={'Edit'}>
                            <FontAwesomeIcon icon={'edit'}/>
                        </Action>}
                </Fab>
                ) : null}
  </>);
}