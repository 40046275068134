import React, { useEffect, useMemo, useRef, useState } from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import SignaturePad from "react-signature-canvas";
import { useAppStore } from "../hooks";
import { TOP_LEVEL_VIEWS } from "../constants";
import { dataURLtoBlob } from "../utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useWindowSize from "../hooks/useWindowSize";

export default function RecurringPaySignatureModal({
  completeRecurringPaySignature,  
  handleSaveRecurringPaySignature,
}) {
  const { width, height } = useWindowSize();
  const MOBILE_SIZE = width <= 768 || height <= 450;
  const IS_LANDSCAPE = window.matchMedia("(orientation: landscape)").matches;
  const IS_PORTRAIT = window.matchMedia("(orientation: portrait)").matches;
  const [isSignatureDrawn, setIsSignatureDrawn] = useState(false);

  const setCurrentView = useAppStore((state) => state.setCurrentView);
  const isOpen = useAppStore((x) => x.recurringPaySignatureModalOpen);
  const setIsOpen = useAppStore((x) => x.setRecurringPaySignatureModalOpen);
  const signDataUrl = useAppStore((x) => x.signDataUrl);
  const setSignDataUrl = useAppStore((x) => x.setSignDataUrl);
  const isRecurringAccept = useAppStore((x) => x.isRecurringAccept);
  const setIsRecurringAccept = useAppStore((x) => x.setIsRecurringAccept);
  const contractId = useAppStore(x => x.contractId);
  const signaturePadRef = useRef(null);

  const handleDraw = () => {
    setIsSignatureDrawn(true);
  };

  useEffect(() => {
    if (signaturePadRef.current) signaturePadRef.current.clear();
  }, [signDataUrl]);

  useEffect(() => {
    if (signDataUrl && isOpen) {
        const blob =  dataURLtoBlob(signDataUrl);
        handleSaveRecurringPaySignature(contractId, blob);
        completeRecurringPaySignature(!isRecurringAccept);
        setIsSignatureDrawn(false);
        setIsOpen(false);
    }
  }, [signDataUrl])

  function clear() {
    signaturePadRef.current.clear();
  }

  function sign() {
    const newSignatureDataUrl = signaturePadRef.current
      .getTrimmedCanvas()
      .toDataURL("image/png");
    setSignDataUrl(newSignatureDataUrl);   
    setIsRecurringAccept(true); 
  }

  return (
    <>
      <Modal
        isOpen={isOpen}
        centered
        className={`${
          IS_LANDSCAPE && MOBILE_SIZE ? "modal-fullscreen" : "modal-xl"
        }`}
      >
        {/* Modal Title & Close Button */}
        <ModalHeader>
          <div className="d-flex flex-row align-items-center justify-content-between">
            <div>Sign Recurring Payment Form</div>
            <div style={{ right: 20, position: "absolute" }}>
              <FontAwesomeIcon
                icon={"times-circle"}
                onClick={() => {
                  setIsOpen(false);
                }}
              />
            </div>
          </div>
        </ModalHeader>

        {/* Modal Body - Signature Pad */}
        <ModalBody>
          <SignaturePad
            ref={signaturePadRef}
            canvasProps={{
              style: {
                width: "100%",
                height: "100%",
                border: "2px solid black",
                borderRadius: "4px",
              },
            }}
            onBegin={handleDraw}
          />
          {IS_PORTRAIT && MOBILE_SIZE && (
            <div style={{textAlign: "center", fontWeight: "lighter", fontStyle: "italic", color: "darkgray"}}>
                Turn device for fullscreen
            </div>
        )}
        </ModalBody>

        {/* Modal Buttons - Clear / Sign Recurring Payments*/}
        <ModalFooter>
          <div className="d-flex justify-content-end">
            <Button
              size="sm"
              color="light"
              onClick={clear}
              className="me-3 shadow-sm"
              disabled={!isSignatureDrawn}
            >
              Clear
            </Button>
            <Button
              size="sm"
              color="light"
              onClick={() => setIsOpen(false)}
              className="me-3 shadow-sm"
            >
              Cancel
            </Button>
            <Button
              color="primary"
              onClick={sign}
              className="shadow-sm"
              disabled={!isSignatureDrawn}
            >
              Sign & Confirm
            </Button>
          </div>
        </ModalFooter>
      </Modal>

    </>
  );
}
