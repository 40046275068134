import React, {Fragment, useState, useEffect} from "react";
import {
  Row,
  Col,
  FormGroup,
  Form,
  Input,
  Label,
  InputGroup,
  Button,
} from "reactstrap";
import _ from "lodash";
import { api, ui_helpers } from "../helpers";
import classnames from 'classnames';
import { constants, storage } from "../utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Select from 'react-select'

const SalespersonEdit = ({salespeople, salesperson, otherSalesperson, onChange, onDeleteSalesperson, screenWarnings, onSetSalespeople}) => {
  function onSelect(s) {
    if (s) {
      storage.setItem(constants.STORAGE_KEYS.LAST_SALESPERSON_LABEL, s.label);
    }
    onChange(s, 'salesperson');
  }
  return (
    <div className="mb-2" style={{clear: "both"}}>
      <div><Label>Salesperson {ui_helpers.requiredStar()}</Label></div>
      <InputGroup size="sm">
        <div style={{width: "70%"}} title={screenWarnings && screenWarnings.salesperson ? screenWarnings.salesperson : null}>
          <Select
            isDisabled={!salespeople}
            options={salespeople ? salespeople : constants.OTHER_OPTION}
            value={salesperson ? _.find(salespeople, s => s.label === salesperson.label) : null}
            classNamePrefix={screenWarnings && screenWarnings.salesperson ? "invalidSelect" : null}
            onChange={onSelect}
          />
        </div>
        {salesperson && salesperson.label !== constants.OTHER
          ? (<Button color="danger" className="text-light" style={{zIndex: 0}} onClick={onDeleteSalesperson}>
              <FontAwesomeIcon icon="times-circle" />
            </Button>)
          : null
        }
      </InputGroup>
      {salesperson && salesperson.label === constants.OTHER
        ? (<div className="control-group pt-1">
            <Input 
              type="text"
              size="sm" 
              maxLength="150" 
              placeholder="Other Details" 
              title={screenWarnings && screenWarnings.otherSalesperson ? screenWarnings.otherSalesperson : null}
              className={classnames({invalid: (screenWarnings && screenWarnings.otherSalesperson ? true : false)})}
              value={otherSalesperson}
              onChange={(e) => onChange(e.target.value, 'otherSalesperson')} 
            />
          </div>)
        : null
      }
    </div>);
}
export default SalespersonEdit;
