import { unitsOfMeasure, addOnTypes } from "../floorplanConstants";
import _ from "lodash";
// import { selectedAddOns } from "../temp";

// export const createEnum = (values) => {
//   const enumObject = {};
//   for (const val of values) {
//     enumObject[val] = val;
//   }
//   return Object.freeze(enumObject);
// };

export const radiansToDegrees = (n) => n * (180 / Math.PI);

export const toFeetInches = (n) => ({
  feet: Math.floor(n / 12),
  inches: Math.round(n % 12),
});

export const toFeetInchesString = (n) =>
  `${toFeetInches(n).feet}'${toFeetInches(n)
    .inches.toString()
    .padStart(2, "0")}"`;

export const snap = (n, i) => Math.round(n / i) * i;

export const limitNumberWithinRange = (n, min, max) =>
  Math.min(Math.max(parseInt(n), min), max);

export const cornersToPathReducer = (prev, curr, idx, arr) => {
  if (idx === 0) return prev + curr.x + " " + curr.y;
  if (idx === arr.length - 1) return prev + " Z";
  return prev + " L " + curr.x + " " + curr.y;
};

export const calculateHypotenuse = (leg1, leg2) =>
  Math.sqrt(leg1 ** 2 + leg2 ** 2);

export const getAngledWallLength = (exterior) =>
  calculateHypotenuse(
    exterior.porchMax - exterior.porchMin - exterior.porchWall2Length,
    exterior.height - exterior.porchWall1Length - exterior.porchWall4Length
  );

export function getRotation(fromX, fromY, toX, toY) {
  let deltaX = fromX - toX;
  let deltaY = fromY - toY;
  let radians = Math.atan2(deltaY, deltaX);
  let degrees = (radians * 180) / Math.PI - 90;
  while (degrees >= 360) degrees -= 360;
  while (degrees < 0) degrees += 360;
  return degrees;
}

 export function priceReducer(total, x, _idx) {
  if (x.bundleId) return total;
  let amountToAdd = 0;
  const resolvedUnitPrice = x.agreedPrice
    ? x.agreedPrice
    : x.price;
  if (x.uom === unitsOfMeasure.linearFt) 
  {
    amountToAdd += resolvedUnitPrice * (x.width / 12.0);
  }
  else if (x.uom === unitsOfMeasure.sqFt) 
  {
    amountToAdd += resolvedUnitPrice * (x.width / 12.0) * (x.height / 12.0);
  }
  // handled separately
  // else if (x.uom === unitsOfMeasure.percentOfBase) 
  // {
  //   amountToAdd += resolvedUnitPrice * basePrice;
  // }
  else 
  {
    amountToAdd += resolvedUnitPrice;
  }
  if (x.additionalOption1On && x.additionalOption1Price)
    amountToAdd += x.additionalOption1Price;
  if (x.additionalOption2On && x.additionalOption2Price)
    amountToAdd += x.additionalOption2Price;
  return total + amountToAdd;
}

export function calculateBundleTotal(selectedAddOns, bundleOpts){
  let bundleTotal = 0;
  let selectedBundleIds =  _.chain(selectedAddOns)
    .filter(o => o.bundleId > 0)
    .map(o => o.bundleId)
    .uniq()
    .value();
  _.forEach(bundleOpts, o => {
    if (selectedBundleIds.includes(o.id)) {
      bundleTotal += o.price;
    }
  });
  return bundleTotal;
}

export function calculateExteriorPrice(exterior, selectedAddOns, bundleOpts, addOnOpts = null) {  
  const bundlePriceTotal = calculateBundleTotal(selectedAddOns, bundleOpts);
  const resolvedBasePrice = exterior.agreedPrice
    ? exterior.agreedPrice
    : exterior.basePrice;
  const standardBasePrice = exterior.standardSkuBasePrice
    ? exterior.standardSkuBasePrice
    : exterior.basePrice;
  let standardPriceTotal = addOnOpts
    ? standardBasePrice + bundlePriceTotal
    : null;
  let resolvedPriceTotal = resolvedBasePrice + bundlePriceTotal;
  if (selectedAddOns && selectedAddOns.length) {
    const pctOfBaseAddOns = _.filter(selectedAddOns, ao => ao.uom === unitsOfMeasure.percentOfBase);
    const nonBundleAddOns = _.reject(selectedAddOns, ao => ao.bundleId || ao.uom === unitsOfMeasure.percentOfBase);
    const addOnSum = nonBundleAddOns.reduce(priceReducer, 0.0) + _.sumBy(pctOfBaseAddOns, ao => {
      // price here is actually a %
      return resolvedPriceTotal * (ao.price / 100.0);
    });
    resolvedPriceTotal += addOnSum;
    // we don't always care about the standard price
    if (addOnOpts) {
      let tmpSelectedAddOns = [];
      for(let i=0; i < nonBundleAddOns.length; i++) {
        const sao = nonBundleAddOns[i];
        if (sao.bundleId) continue;
        const standardOption = _.find(addOnOpts, s => s.addOnId === sao.addOnId);
        if (standardOption) {
          tmpSelectedAddOns.push({
            ...standardOption, 
            agreedPrice: null, 
            width: sao.width, 
            height: sao.height, 
            additionalOption1On: sao.additionalOption1On,
            additionalOption1Price: sao.additionalOption1Price,
            additionalOption2On: sao.additionalOption2On,
            additionalOption2Price: sao.additionalOption2Price
          });
        } else {
          tmpSelectedAddOns.push({...sao, agreedPrice: null});
        }
      }
      standardPriceTotal = tmpSelectedAddOns.reduce(priceReducer, standardPriceTotal);      
    }
  }
  // console.log(exterior, standardPriceTotal, resolvedPriceTotal, 
  //   _.map(selectedAddOns, x => ({name: x.name, bundleId: x.bundleId, price: x.price})));
  // return parseFloat(selectedAddOns.reduce(priceReducer, 0).toFixed(2)) + basePrice + bundlePriceTotal;
  return {standardPriceTotal, resolvedPriceTotal};
}

export function canRemoveAddOn(addOn, selectedAddons, bundleOpts) {
  let addOns = _.filter(selectedAddons, x => x.addOnId === addOn.addOnId && x.bundleId > 0)
  if(addOns.length){
    let bundleIds = _.map(addOns, m => m.bundleId);
    let requiresAddOn = 
    _.chain(bundleOpts)
    .filter(f => bundleIds.includes(f.id))
    .filter(b => {
      let count = _.filter(b.addOns, y => y.addOnId === addOn.addOnId).length;
      let totalAddonCount = _.filter(selectedAddons, s => s.addOnId === addOn.addOnId).length
      return totalAddonCount === count || totalAddonCount < count;
    })
    .value();
    return requiresAddOn && !requiresAddOn.length;
  }

  addOns = _.filter(selectedAddons, x => x.addOnId === addOn.addOnId);
  return addOns.length;
}
