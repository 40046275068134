import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import _ from "lodash";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Container,
  Input,
  Table,
  InputGroup,
  Row, Col
} from "reactstrap";
import { ManufacturerAdminHeader, MfgAdmin } from "./";
import { api } from "../utils";
import Loading from "./Loading";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function ManufacturerStyleColors(props) {
  const params = useParams();
  const mfgId = params.mfgId
    ? params.mfgId
    : props.mfgId;
  const [isLoading, setIsLoading] = useState(true);
  const [mfgColors, setMfgColors] = useState([]);
  const [manufacturerName, setManufacturerName] = useState('');
  const [newColorName, setNewColorName] = useState("");

  useEffect(getMfgColors, []);

  function addNewColor() {
    if (newColorName === "") {
    } else {
      const payload = {
        id: -100,
        manufacturerId: mfgId,
        name: newColorName,
      };
      api.post("Manufacturer/SaveManufacturerColor", payload).then((res) => {
        getMfgColors();
        setNewColorName("");
      });
    }
  }

  async function getMfgColors() {
    setIsLoading(true);
    const res = await api.fetch(`Manufacturer/ListOfManufacturerColor/${mfgId}`);
    if (res.data.success) {
      setMfgColors(res.data.message.list);
      setManufacturerName(res.data.message.manufacturerName);
    }
    setIsLoading(false);
  }

  function removeColor(colorId) {
    api.post(`Manufacturer/ToggleManufacturerColorActive/${colorId}`).then((res) => {
      getMfgColors();
    });
  }

  return (
    <>
    <MfgAdmin mfgId={mfgId} tabName="MFGCOLORS">
      <Container fluid>
        <Row>
          <Col>
            <h2 className="py-2">
              <Link to={`/admin/manufacturer/${mfgId}`}>{manufacturerName}</Link> Colors
            </h2>
          </Col>
        </Row>
        <Row>
          <Col xs="6" className="mb-2">
            <InputGroup>
              <Input
                type="text"
                placeholder="New color name"
                value={newColorName}
                onChange={(e) => setNewColorName(e.target.value)}
                onKeyDown={(e) => {
                  if (e.which !== 13) return;
                  addNewColor();
                  return false;
                }}
              />
              <Button
                color="success"
                className="fw-bold text-white"
                title="Add New Color"
                onClick={addNewColor}
              >
                <FontAwesomeIcon icon="plus" className="me-2" />
                Add
              </Button>
            </InputGroup>
          </Col>
        </Row>
        <Card>
          <CardBody>
            {mfgColors && mfgColors.length > 0 && (
              <>
                <Table hover>
                  <tbody>
                    {_.map(mfgColors, (c) => (
                      <tr key={c.id}>
                        <td>
                          <strong>{c.name}</strong>
                        </td>
                        <td className="d-flex justify-content-end">
                          <Button
                            title={`Delete ${c.name}`}
                            size="sm"
                            className="btn-delete"
                            onClick={() => removeColor(c.id)}
                          >
                            <FontAwesomeIcon icon="trash-alt" /> Delete
                          </Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </>
            )}
          </CardBody>
        </Card>
      </Container>
    </MfgAdmin>
    </>
  );
}
