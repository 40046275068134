import React, {useState, useEffect} from 'react';
import {Footer, Header, MainNav} from "./";
import {Card, CardHeader, Container, Col, CardBody, Row, Form, Input, Button, CardFooter} from "reactstrap";
import Background from "../assets/img/widget-background.jpg";
import {api, constants} from '../utils';
import Alert from "react-s-alert-v3";
import {Redirect} from "react-router-dom";




export default function PasswordReset(props) {
const [userName, setUserName] = useState('');
const [redirect, setRedirect] = useState('');

    const sendForgotPassword = () => {
        if(userName.length) {
            api.post('public/forgotPassword', {username: userName})
                .then(r => {
                    if(!r.data.success) {
                        Alert.error(r.data.message);
                        return;
                    }
                    Alert.success(r.data.message);
                    setTimeout(() => setRedirect(constants.PATH_NAMES.LOGIN), 700);
                }).catch(api.catchHandler);
        } else {
            Alert.warning("You must enter an email/username before requesting.");
        }

    };


if(redirect) {
    return <Redirect to={redirect}/>
}

return (
        <div>
            <Header login/>
            <Container  className='d-flex justify-content-center'>
                <Card id='loginCard'
                      className='mt-5 text-light'
                      style={{ backgroundImage: `url(${Background})` }}>
                    <CardHeader>Forgot Password</CardHeader>
                    <CardBody className={'px-2'}>
                        <Col className={'px-3'}>
                            <Row lg={12} className={'text-center m-2 d-flex align-items-center justify-content-center'}>
                                Type your username below and we'll email you a password.
                            </Row>
                            <Form>
                                <Input value={userName} onChange={(e) => setUserName(e.target.value)}/>
                            </Form>
                        </Col>
                    </CardBody>
                    <CardFooter className={'d-flex justify-content-center'}>
                        <Button color='primary' onClick={sendForgotPassword}>
                            Send Email
                        </Button>
                    </CardFooter>
                </Card>
            </Container>
            <Footer className={'float-bottom'}/>
        </div>
    );


}