import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import _ from "lodash";
import {
  Alert as RSAlert,
  Button, ButtonGroup,
  Form,
  FormGroup,
  Input,
  Label,
  Col,
  Row
} from "reactstrap";
import Alert from "react-s-alert-v3";
import CurrencyInput from "react-currency-input-field";
import Select from 'react-select';
import { api } from "../utils";
import { object_helpers } from "../helpers";
import { constants } from "../utils";
import classNames from "classnames";
const { deepCopyObject } = object_helpers;

const initialSkuState = {
  id: 0,
  manufacturerSubProductId: 0,
  name: "",
  width: 0,
  length: 0,
  basePrice: 0,
  porchType: constants.PORCH_TYPES.NONE
};

function EditSKUInfo(props) {
  const { id } = useParams();
  //const [isLoading, setIsLoading] = useState(true);
  const [sku, setSKU] = useState(initialSkuState);
  const [message, setMessage] = useState(constants.ALERT_OBJECT);

  useEffect(() => {
    if(!props.sku) return;
    initSubProduct();
  }, [props]);

  function initSubProduct() {
    //setIsLoading(true);
    let mfgSku = props.sku;
    mfgSku.porchType = _.find(constants.PORCH_TYPE_LIST, p => { return p.value === mfgSku.typeOfPorch})
    setSKU(mfgSku);
    //setIsLoading(false);
  }

  function handleChange(property, value) {
    const newSku = deepCopyObject(sku);
    newSku[property] = value;
    setSKU(newSku);
  }

  const toggleDelete = (e) => {
    e.preventDefault();
    api.post(`manufacturer/ToggleDeleteSKU/${sku.id}`)
        .then(r => {
          if(!r.data.success) {
            Alert.error(r.data.message);
            return;
          }
          props.fetchSKUList();
          if(props.onReturnCallback){
            props.onReturnCallback();
          }
          getSkuItemAfterUpdate();
          Alert.success(`Successfully ${sku.deactivatedAt ? "revived" : "deleted"} ${sku.name}`);

        }).catch(err => console.error(err));
  };

  async function handleSubmit() {
    let payload = Object.assign({}, sku);
    payload.typeOfPorch = payload.porchType ? payload.porchType.value : constants.PORCH_TYPES.NONE.value;
    payload.manufacturerId = id;
    const res = await api.post("manufacturer/SaveSubProductSku", payload);
    if (!res.data.success) {
      setMessage({ message: res.data.message, flavor: constants.flavor.error });
      return;
    }
      setMessage({
        message: "Changes saved successfully.",
        flavor: constants.flavor.success,
      });
    getSkuItemAfterUpdate();
  }

  const getSkuItemAfterUpdate = () => {
    api.fetch(`manufacturer/GetSKU/${sku.id}`)
        .then(r => {
          if(!r.data.success) {
            setMessage({ message: r.data.message, flavor: constants.flavor.error });
            return;
          }
          setSKU(r.data.message);
        }).catch(err => console.error(err));
  };

  // const toggleDeleteButton = classNames(
  //     {'btn-delete': sku.deactivatedAt},
  //           {'bg-success': !sku.deactivatedAt}
  // );

  return (
    <>
      <h3>SKU Info</h3>
      {message.message ? (
        <RSAlert flavor={message.flavor} className="text-center">
          {message.message}
        </RSAlert>
      ) : null}
      <Form>
        <FormGroup>
          <Label>Name</Label>
          <Input
            type="text"
            placeholder="Enter Name"
            value={sku.name}
            onChange={(e) => handleChange("name", e.target.value)}
          />
        </FormGroup>
        <FormGroup row>
         <Col>
            <Label>Width (feet)</Label>
           <Input type='text' value={sku.width} name='width'
                  onChange={(e) => handleChange('width', e.target.value)}/>
         </Col>
          <Col>
            <Label>Length (feet)</Label>
            <Input type='text' value={sku.length} onChange={(e) =>
                handleChange('length', e.target.value)}/>

          </Col>
        </FormGroup>
        <FormGroup row>
          <Col>
          <Label>Base Price</Label>
          <CurrencyInput value={sku.basePrice}
                         className={'form-control'}
                         onValueChange={(value) =>
                             handleChange('basePrice', value)}
                         decimalsLimit={2} prefix={'$'}/>
          </Col>
          <Col>
              <Label>Porch Type</Label>
              <Select options={constants.PORCH_TYPE_LIST}
                value={sku.porchType}
                onChange={(e) => handleChange('porchType', e)}
                defaultValue={constants.PORCH_TYPES.NONE}/>
          </Col>
        </FormGroup>
        <div>
          <ButtonGroup className="float-end">
            <Button color="primary" size="lg" onClick={handleSubmit}>
              Save Changes
            </Button>
           <Button className={sku.deactivatedAt ? 'bg-success border-success' : 'btn-delete'} size='lg' onClick={(e) => toggleDelete(e)}>
             {sku.deactivatedAt ? 'Revive' : 'Delete'}
           </Button>
         </ButtonGroup>
        </div>
      </Form>
    </>
  );
}

export default EditSKUInfo;
