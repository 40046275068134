import React, { useState } from "react";
import { Redirect } from "react-router-dom";
import SignatureCaptureEntry from "../pos-signature-module/signature-capture/SignatureCaptureEntry";
import Alert from "react-s-alert-v3";
import { api, constants } from "../utils";
import { ui_helpers } from "../helpers";
import {Link, useParams} from "react-router-dom";

export default function SignatureCapture() {
  const [redirectTo, setRedirectTo] = useState('');
  const { code } = useParams();

  /**
   * The callback to handle saving individual signatures.
   *
   * @param {number} contractId The id of the contract
   * @param {number} signatureLocationId The id of the signature locaction on the corresponding contract template
   * @param {Blob} blob A Blob() object containing the png image of the signature.
   * @param {boolean} isAccept Is this a customer accepting or declining? Only applies to binary (Accept/Decline) signatures. Should default to true.
   * @returns {void}
   */
  function handleSaveSignature(
    contractId,
    signatureLocationId,
    blob,
    isAccept = true
  ) {
    let payload = new FormData();
    payload.append("contractId", contractId);
    payload.append("signatureLocationId", signatureLocationId);
    payload.append("isAcceptSignature", isAccept);
    payload.append("isDeclineSignature", !isAccept);
    payload.append("signature", blob);
    api.post("Signature/RecordSignature", payload).then((r) => {
      if (!r.data.success) {
        Alert.error(r.data.message);
      }
    })
    .catch((e) => {
      console.error(e);
      Alert.error("An unexpected error occurred while saving your signature.");
    });
    // console.table({ contractId, signatureLocationId, blob, isAccept });
  }

  function handleSaveRecurringPaySignature(
    contractId,
    blob,
  ) {
      let payload = new FormData();
        payload.append("contractId", contractId);
        payload.append("isAcceptSignature", true);
        payload.append("isDeclineSignature", false);
        payload.append("signature", blob);
      api
      .post("Signature/RecordRecurringPaySignature", payload)
      .then((r) => {
        if (!r.data.success) {
          Alert.error(r.data.message);
        }
      })
      .catch((e) => {
        console.error(e);
        Alert.error("An unexpected error occurred while saving your signature.");
      });
  }
  /**
   * Callback to handle confirming/finalizing the contract.
   *
   * @param {number} contractId The id of the contract
   * @returns {void}
   */
  function handleConfirm(contractId) {
    api.post('signature/ConfirmSignatures', {id: contractId}).then(r => {
      if (r.data.success) {
        setRedirectTo(constants.PATH_NAMES.THANK_YOU);
      }
      else
      {
        Alert.error('There was an error saving the provided signatures.');
      }
    }).catch(err => Alert.error(err));
  }

  if (redirectTo) {
    return <Redirect to={redirectTo} />;
  }

  return (
    <SignatureCaptureEntry
      handleSaveSignature={handleSaveSignature}
      handleSaveRecurringPaySignature={handleSaveRecurringPaySignature}
      handleConfirm={handleConfirm}
      routeToken={code}
    />
  );
}
