import React, { Component } from "react";
import ToggleButton from 'react-toggle-button';
import {Row, Col} from 'reactstrap';

class UnmappedFilter extends Component {
    constructor(props) {
        super(props);
        this.state = {
            filterName: props.filterName,
            displayName: props.displayName,
            value: props.value,
            onChangeCallback: props.onChangeCallback,
            debounceTimeout: props.debounceTimeout || 500,
            useEqualsDisplayName: props.useEqualsDisplayName
        };

        this.onChange = this.onChange.bind(this);
    }

    onChange(value) {
        const filteredDisplay = value
            ? this.state.useEqualsDisplayName
            ? `${this.state.displayName}: ${value}`
            : `${this.state.displayName} starts with ${value}`
            : null;

        this.state.onChangeCallback({
            filterName: this.state.filterName,
            value: !value,
            filteredDisplay: filteredDisplay
        });

        this.setState({
            value: !value
        });
    }

    render() {
        return (
            <Col >
                <Row className='d-flex justify-content-center'>
                    {this.state.displayName}
                </Row>
                <Row className='d-flex justify-content-center'>

                    <ToggleButton
                        value={this.state.value || false}
                        activeLabel={"Yes"}
                        inactiveLabel={"No"}
                        onToggle={(value) => this.onChange(value)}
                    />
                </Row>
            </Col>
        );
    }
}

export default UnmappedFilter;