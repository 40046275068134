import React, {useContext, useState, useEffect} from "react";
import { Link } from "react-router-dom";
import _ from "lodash";
import Select from "react-select";
import { Header, Footer, CalculatorModal } from ".";
import { Container, TabContent, TabPane, Nav, NavItem, NavLink, Card, Button, CardTitle, CardText, Row, Col, Form, FormGroup, Label, Input } from 'reactstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classnames from 'classnames';
import {constants, UserContext} from "../utils";

const options = [
    { value: 'Build Started', label: 'Build Started' },
    { value: 'Electricity Completed', label: 'Electricity Completed' },
    { value: 'Build Completed', label: 'Build Completed' },
    { value: 'Delivery Scheduled', label: 'Delivery Scheduled' },
    { value: 'Delivery Completed', label: 'Delivery Completed' },
  ]

const templateDescriptions = [
    { value: '{{first-name}} we have exciting news, your build has been started!', label: 'Build Started' },
    { value: 'Electricity has successfully been installed on your unit!', label: 'Electricity Completed' },
    { value: 'Your build has been successfully completed! We will contact you for delivery soon!', label: 'Build Completed' },
    { value: 'Delivery has been scheduled for {{date}} please let us know if something comes up and that time no longer works..', label: 'Delivery Scheduled'},
    { value: '{{first-name}} thank you for your business, we hope you love your new unit!', label: 'Delivery Completed'},
  ]



export default function TemplateManagement() {

    const templateOptions=[{ value: 1, label: "{{first-name}}" }, { value: 2, label: "{{last-name}}" }];
    const [templateOption, setTemplateOption] = useState(null);
    const [showNameInput, setShowNameInput] = useState(false);
    const [showNameSelect, setShowNameSelect] = useState(true);
    const [showCancelButton, setShowCancelButton] = useState(false);
    const [showDeleteButton, setShowDeleteButton] = useState(false);
    const [currentBuildTemplate, setCurrentBuildTemplate] = useState(null);
    // const templateDescriptionOptions= ["Test"]
    const [showTemplateDescriptionOption, setShowTemplateDescriptionOption] = useState(null);
    const [templateText, setTemplateText] = useState('');


function handleTemplateTextChange(e){
    setTemplateText(e.target.value);
}

function handleDescriptionChange(options){
    setShowTemplateDescriptionOption(options);
}

function clearTextBox(){
    setTemplateText("")
}

    useEffect(() => {
        if (showTemplateDescriptionOption) {
            let matchingTemplate = _.find(templateDescriptions, opt => {
                return opt.label === showTemplateDescriptionOption.label
            } )
            if (matchingTemplate) {
                setCurrentBuildTemplate(matchingTemplate);
                setTemplateText(matchingTemplate.value);
                setShowDeleteButton(true);
            }
        } else {
            if (currentBuildTemplate) {
                setCurrentBuildTemplate(null)
            }
        }
    }, [showTemplateDescriptionOption, currentBuildTemplate]);


    

    return (
        <div>
            <Header />
            <Container className="mt-5">
            <Row className="mx-2 mt-2 d-flex justify-content-between">
                <Row style={{paddingRight:"30px"}}>
                    <Col xs="11">
                    <h2 className="mt-2">Template Management</h2>
                    </Col>
                        <Col className="mr-2" xs="1">
                        <Button
                        color="success"
                        onClick={() => {
                            setShowNameSelect(false);
                            setShowCancelButton(true);
                            clearTextBox();
                            setShowDeleteButton(false);
                            }}>Add
                        </Button>
                    </Col>
                </Row>
                    <Row>    
                        <Card>
                            <Form className="mt-3">
                                <FormGroup className="mb-1">
                                    {showNameSelect ? (
                                    <div>
                                    <Label for="processName">Select Template To Edit</Label>
                                    <Select className="mt-1" 
                                    options={options}
                                    onChange={(option) => setShowTemplateDescriptionOption(option)}/>
                                    </div>
                                    ) : 
                                    <div>
                                    <Label for="processName">Name</Label>
                                    <Input placeholder="Enter name of new template here"></Input>
                                    </div>}
                                </FormGroup>
                                <FormGroup>
                                    <Row className="my-2">
                                        <Col>
                                            <div>Insert Element</div>
                                            <Row>
                                                <Col xs="9">
                                                    <Select 
                                                        className="mt-1"
                                                        options={templateOptions}
                                                        value={templateOption}
                                                        onChange={(option) => setTemplateOption(option)}
                                                    />
                                                </Col>
                                                <Col className="mt-1" xs="">
                                                    <Button color="success"                                                    >
                                                    <FontAwesomeIcon icon="plus" />
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                    <Row className="my-4">
                                        <Col>
                                          <div>Template Text</div>
                                          <Input
                                            className="mt-1"
                                            type="textarea"
                                            style={{height: "300px"}}
                                            name="template"
                                            id="template"
                                            placeholder="Provide your template content here"
                                            value={templateText}
                                            onChange={(e)=> handleTemplateTextChange(e)}
                                          />
                                        </Col>
                                    </Row>                                                                       
                                </FormGroup>
                                <Row>
                                    <Col className="d-flex justify-content-end">
                                    <Button className="my-2 btn-success">Save</Button>
                                    {showCancelButton ? (
                                      <Button className="my-2" style={{color:"white", marginLeft:10}}
                                      onClick={()=>{
                                          setShowNameSelect(true);
                                          setShowCancelButton(false);
                                        }
                                    }>Cancel</Button>  ) :  null}
                                    {showDeleteButton ? (
                                    <Button className="my-2 btn-danger" style={{color:"white", marginLeft:10}}
                                    onClick={()=>{
                                          setShowNameSelect(true);
                                          setShowDeleteButton(false);
                                        }
                                    }>Delete</Button>  ) :  null}
                                    </Col>
                                </Row>
                            </Form>
                        </Card>
                    </Row>
            </Row>
        </Container>
        </div>
    );
}