import React, {useState, useEffect, Fragment} from "react";
import {
    Container,
    Col,
    Row,
    Card,
    CardBody,
    CardText,
    CardTitle,
    Button,
    ButtonGroup
} from "reactstrap";
import { api, ui_helpers } from '../helpers';
import _ from "lodash";
import Dropzone from "react-dropzone";
import Alert from "react-s-alert-v3";
import ToggleButton from "react-toggle-button";
import Select from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const DropzoneUploader = (props) => {
  const [files, setFiles] = useState([]);
  const [clickedUpload, setClickedUpload] = useState(false);
  const [isDefault, setIsDefault] = useState(props.contentTypeId && props.isDefault);
  const [region, setRegion] = useState(resolveRegion());
  const [culture, setCulture] = useState(resolveCulture());

  useEffect(() => {
    setRegion(resolveRegion());
    setIsDefault(props.contentTypeId && props.isDefault);
    setCulture(resolveCulture());
  }, [props.isDefault, props.contentTypeId, props.cultureId])

  function resolveCulture() {
    return (props.contentTypeId && props.cultureId && props.cultureList && props.cultureList.length)
      ? _.find(props.cultureList, x => x.value === props.cultureId)
      : null;
  }

  function resolveRegion() {
    return (props.contentTypeId && props.regionId && props.regionList && props.regionList.length)
      ? _.find(props.regionList, x => x.value === props.regionId)
      : null;
  }

  function onClose() {
    if (props.close)
      props.close();
  }

  function onDrop(file) {
    setFiles([file]);
    // alternative usage, only capture the file for later upload
    if (props.onFileCapture) {
      props.onFileCapture(file);
    }
  }

  function performUpload() {
    if (!files.length && !props.editingTemplateId) {
      Alert.warning("Provide a file before attempting to upload");
      return;
    }
    if (props.onSave) {
      props.onSave(files[0]);
      setClickedUpload(true);
    } else if (props.route) {
      let payload = new FormData();
      payload.append('companyId', props.companyId);
      payload.append('file', files[0]);
      if (props.contentTypeId) {
        payload.append('typeOfCompanyContent', props.contentTypeId);
        payload.append('id', props.editingTemplateId);
        if (props.captiveContext) {
          payload.append('regionId', props.captiveContext.regionId);
          payload.append('isDefault', props.captiveContext.isDefault);
          payload.append('cultureId', props.captiveContext.cultureId);
        } else {
          payload.append('regionId', region ? region.value : props.regionId);
          payload.append('isDefault', isDefault);
          payload.append('cultureId', culture ? culture.value : props.cultureId);
        }
      } else {
        payload.append('id', null);
        payload.append('isDefault', false);
        payload.append('cultureId', null);
        payload.append('regionId', null);
        payload.append('typeOfCompanyContent', null);
      }
      setClickedUpload(true);
      api.post_form_data(`Company/${props.route}`, payload).then(r => {
        if (r.data.success) {
          Alert.success("The file was saved successfully")
          if (props.closeAndReload)
            props.closeAndReload();
        } else {
          Alert.warning("There was an error saving the template");
        }
      }).catch(error => console.error(error))
      .finally(() => setClickedUpload(false));
    } else {
      console.error('Provide either an onSave callback or a route for uploading files!');
    }
  }

return (
    <Container fluid>
    {props.contentTypeId && !props.captiveContext
      ? (<Row>
          <Col>
            State
            <Select
              options={props.regionList}
              value={region}
              onChange={(option) => setRegion(option)}
            />
          </Col>
          <Col>
            Culture
            <Select
              options={props.cultureList}
              value={culture}
              onChange={(option) => setCulture(option)}
            />
          </Col>
          <Col>
            <div>Default {ui_helpers.getContentTypeName(props.contentTypeId)} for all regions?</div>
            <ToggleButton
                value={isDefault}
                activeLabel="Yes"
                inactiveLabel="No"
                onToggle={(value) => setIsDefault(!value)}
            />
          </Col>
        </Row>)
      : null
    }
    <Row>
      <Col className="pt-3">
        <Dropzone onDrop={fileList => onDrop(fileList[0])} accept={props.acceptTypes} style={{width: "100%"}}>
          {({ getRootProps, getInputProps }) => (
            <div {...getRootProps()} className="text-center">
              <input {...getInputProps()} />
              <div style={{ textAlign: "center" }} className="pt-3">
                <div className="mb-1">Drop file here or click the icon</div>
                <FontAwesomeIcon icon={files.length ? "check-square" : "file-upload"} size="3x" className="mb-4" />
              </div>
            </div>
          )}
        </Dropzone>
      </Col>
    </Row>
    <Row className="pt-3">
      <Col>
        <p>{props.message1}</p>
        {props.message2
          ? <p>{props.message2}</p> 
          : null
        }                    
      </Col>
    </Row>
    <Row>
        <Col>
            <Card>
                <CardBody className="p-0 m-1">
                    <CardTitle>Files to be uploaded</CardTitle>
                    <CardText>
                        {files.map((f, i) => (
                            <li key={`file-${i}`}>
                                <span>{f.name ? f.name : `file-${i}`} </span>
                                <Button
                                    className="btn-danger cf-danger-outline"
                                    size="sm"
                                    onClick={() => setFiles([])}
                                >
                                    <FontAwesomeIcon icon="trash-alt" />
                                </Button>
                            </li>
                        ))}
                    </CardText>
                </CardBody>
            </Card>
        </Col>
    </Row>
    {props.onFileCapture
      ? null 
      : (<Row className="rowSpacing">
        <Col>
            <ButtonGroup className="float-right">
            {props.hideCancel
              ? null
              : (<Button className="btn-secondary buttonGroupSpacing" size="sm" onClick={onClose}><FontAwesomeIcon icon="times-circle" /> Cancel</Button>)
            }
            {clickedUpload && files.length
              ? null
              : <Button className="btn-success" style={{color: "white"}} size="sm" onClick={performUpload}><FontAwesomeIcon icon="save" /> Upload</Button>
            }
            {files.length && clickedUpload && props.message3
              ? <div><span className="text-warning">{props.message3}</span></div>
              : null
            }
            </ButtonGroup>
        </Col>
    </Row>)
    }
  </Container>);
}

export default DropzoneUploader;