import { create } from "zustand";

const useStore = create((set) => ({
  signatureLocations: [],
  setSignatureLocations: (x) =>
    set((state) => ({ ...state, signatureLocations: x })),
  numPages: 0,
  setNumPages: (n) => set((state) => ({ ...state, numPages: n })),
  currentPageIndex: 0,
  setCurrentPageIndex: (n) =>
    set((state) => ({ ...state, currentPageIndex: n })),
  signatureLocationMoving: null,
  setSignatureLocationMoving: (x) =>
    set((state) => ({ ...state, signatureLocationMoving: x })),
}));

export default useStore;
