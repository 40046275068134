import React, { useRef, useState } from "react";
import { Page as PdfPage, pdfjs } from "react-pdf";
import SignatureLocation from "./SignatureLocation";
import useStore from "./useStore";
pdfjs.GlobalWorkerOptions.workerSrc = "pdf.worker.js";

export default function Page(props) {
  const signatureLocations = useStore((x) => x.signatureLocations);
  const setSignatureLocations = useStore((x) => x.setSignatureLocations);
  const signatureLocationMoving = useStore((x) => x.signatureLocationMoving);
  const setSignatureLocationMoving = useStore(
    (x) => x.setSignatureLocationMoving
  );

  const [isDragging, setIsDragging] = useState(false);
  const [mouseDownCoordinates, setMouseDownCoordinates] = useState({
    x: 0,
    y: 0,
  });
  const [isMovingSl2, setIsMovingSl2] = useState(false);

  const ref = useRef(null);

  function handleMouseDown(e) {
    e.stopPropagation();
    e.preventDefault();
    if (e.target?.id.split("-")[0] === "sl") {
      const newSl = signatureLocations.find(
        (x) => "sl-" + x.domId === e.target?.id
      );
      if (newSl) {
        setSignatureLocationMoving(newSl);
        setIsDragging(true);
        setMouseDownCoordinates({
          x: e.nativeEvent.clientX,
          y: e.nativeEvent.clientY,
        });
      }
    } else if (e.target?.id.split("-")[0] === "sl2") {
      setIsMovingSl2(true);
      const newSl = signatureLocations.find(
        (x) => "sl2-" + x.domId === e.target?.id
      );
      if (newSl) {
        setSignatureLocationMoving(newSl);
        setIsDragging(true);
        setMouseDownCoordinates({
          x: e.nativeEvent.clientX,
          y: e.nativeEvent.clientY,
        });
      }
    } else if (e.target.id !== "delete") setSignatureLocationMoving(null);
  }

  function handleMouseMove(e) {
    e.stopPropagation();
    e.preventDefault();
    if (isDragging) {
      const amountMoved = {
        x: e.nativeEvent.clientX - mouseDownCoordinates.x,
        y: e.nativeEvent.clientY - mouseDownCoordinates.y,
      };
      setSignatureLocations(
        isMovingSl2
          ? [
              ...signatureLocations.filter(
                (x) => x.domId !== signatureLocationMoving.domId
              ),
              {
                ...signatureLocationMoving,
                xPosition2: signatureLocationMoving.xPosition2 + amountMoved.x,
                yPosition: signatureLocationMoving.yPosition + amountMoved.y,
              },
            ]
          : [
              ...signatureLocations.filter(
                (x) => x.domId !== signatureLocationMoving.domId
              ),
              {
                ...signatureLocationMoving,
                xPosition: signatureLocationMoving.xPosition + amountMoved.x,
                yPosition: signatureLocationMoving.yPosition + amountMoved.y,
              },
            ]
      );
    }
  }

  function handleMouseUp(e) {
    setIsDragging(false);
    setIsMovingSl2(false);
    e.stopPropagation();
    e.preventDefault();
  }

  return (
    <div
      className="position-relative"
      style={{ margin: "0 16px" }}
      onMouseDown={handleMouseDown}
      onMouseMove={handleMouseMove}
      onMouseUp={handleMouseUp}
    >
      <PdfPage canvasRef={ref} {...props} />
      {ref && (
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: ref.width,
            height: ref.height,
          }}
        >
          {Array.isArray(signatureLocations) &&
            signatureLocations
              .filter((s) => s.pageIndex === props.pageIndex)
              .map((sl) => (
                <SignatureLocation key={sl.domId} signatureLocation={sl} />
              ))}
        </div>
      )}
    </div>
  );
}
