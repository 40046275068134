import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useRef, useState } from "react";
import {
  Button,
  ButtonGroup,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import styles from "../floorplanTool.module.css";
import {
  useActions,
  useDisplaySettings,
  usePointerCoordinates,
  useControlTarget,
  useExterior,
  useAddOns,
  useAppState,
} from "../hooks";
import {
  toFeetInchesString,
  calculateBundleTotal,
  calculateExteriorPrice,
} from "../utils";
import { ImageModal } from "./";

const saveSvgAsPng = require("save-svg-as-png");

function Toolbar({ modelName, saveCallback, onExit, hasExistingFloorPlan }) {
  const [appState, appStateDispatch] = useAppState();
  const [displaySettings, displaySettingsDispatch] = useDisplaySettings();
  const [coordinates] = usePointerCoordinates();
  const [exterior, exteriorDispatch] = useExterior();
  const [{ addOnOpts, selectedAddOns, bundleOpts }, addOnsDispatch] = useAddOns();
  const actions = useActions({
    displaySettingsDispatch,
    addOnsDispatch,
    exteriorDispatch,
  });
  const intervalRef = useRef(null);
  const [controlTarget, setControlTarget] = useControlTarget();
  const [modalOpen, setModalOpen] = useState(false);
  const [saveAndExit, setSaveAndExit] = useState(false);
  const [exitModalOpen, setExitModalOpen] = useState(false);
  const [saveModalOpen, setSaveModalOpen] = useState(false);

  const padding = {
    top: displaySettings.rulerOffset + 2 * 12,
    right: 3 * 12,
    bottom: 3 * 12,
    left: displaySettings.rulerOffset + 12,
  };

  function repeat(callback) {
    intervalRef.current = setInterval(callback, 100);
  }

  function handleRulerOffset(e) {
    actions.setRulerOffset(Number(e.target.value) * 12);
  }

  async function handleSave() {
    const uri = await saveSvgAsPng.svgAsPngUri(
      document.getElementById("savePreview"),
      {
        backgroundColor: "#fff",
        top: -padding.top,
        left: -padding.left,
        scale: 5,
      }
    );
    const { resolvedPriceTotal } = calculateExteriorPrice(
      exterior,
      selectedAddOns,
      bundleOpts
    );
    exterior.price = resolvedPriceTotal;
    exterior.porchColorId = appState.porchColorId;
    saveCallback(
      exterior,
      selectedAddOns,
      displaySettings.fontSize,
      displaySettings.rulerOffset,
      uri
    );
    if (saveAndExit) onExit();
  }

  return (
    <div
      className={`${styles.toolbar} d-flex justify-content-between align-items-center px-3 py-1 shadow`}
    >
      <div className="d-flex justify-content-start align-items-center">
        <div className="me-3 align-items-center">
          <ButtonGroup className="float-end">
            <Button
              size="sm"
              color="dark"
              className="me-3"
              onClick={() => setExitModalOpen(true)}
            >
              <FontAwesomeIcon icon="arrow-left" className="me-2" />
              Exit
            </Button>
            <Modal isOpen={exitModalOpen} size="sm">
              <ModalBody>
                Are you sure you want to exit? Any unsaved changes will be lost.
              </ModalBody>
              <ModalFooter>
                <Button
                  color="dark"
                  onClick={() => {
                    if (!hasExistingFloorPlan) {
                      setControlTarget("none");
                      setTimeout(handleSave, 500);
                    }
                    onExit();
                  }}
                >
                  Exit
                </Button>
                <Button color="primary" onClick={() => setExitModalOpen(false)}>
                  Cancel
                </Button>
                <Button
                  color="primary"
                  onClick={() => {
                    setControlTarget("none");
                    setTimeout(() => {
                      handleSave();
                      onExit();
                    }, 500);
                  }}
                >
                  Save &amp; Exit
                </Button>
              </ModalFooter>
            </Modal>
            <Button
              color="success"
              className="text-light"
              size="sm"
              disabled={appState.editingPorch}
              onClick={() => {
                setControlTarget("none");
                setTimeout(handleSave, 500);
              }}
            >
              <FontAwesomeIcon icon="save" className="me-2 mr-2" />
              Save
            </Button>
            <Button
              size="sm"
              color="primary"
              onClick={() => {
                setControlTarget("none");
                setTimeout(() => {
                  handleSave();
                  onExit();
                }, 500);
              }}
            >
              Save &amp; Exit
            </Button>
          </ButtonGroup>
        </div>
        <div className="me-3">
          <ButtonGroup size="sm">
            <Button
              color="light"
              onClick={actions.decrementFont}
              onMouseDown={() => repeat(actions.decrementFont)}
              onTouchStart={() => repeat(actions.decrementFont)}
              onMouseUp={() => clearInterval(intervalRef.current)}
              onTouchEnd={() => clearInterval(intervalRef.current)}
            >
              <FontAwesomeIcon icon="minus" />
            </Button>
            Font Size
            <Button
              color="light"
              onClick={actions.incrementFont}
              onMouseDown={() => repeat(actions.incrementFont)}
              onTouchStart={() => repeat(actions.incrementFont)}
              onMouseUp={() => clearInterval(intervalRef.current)}
              onTouchEnd={() => clearInterval(intervalRef.current)}
            >
              <FontAwesomeIcon icon="plus" />
            </Button>
          </ButtonGroup>
        </div>
        <div className="me-3">
          <FormGroup check>
            <Label check>Show Grid</Label>
            <Input
              type="checkbox"
              checked={displaySettings.showGrid}
              onChange={() => actions.toggleGrid()}
            />
          </FormGroup>
        </div>
        <div className="me-3">
          <FormGroup check>
            <Label check>Show Rulers</Label>
            <Input
              type="checkbox"
              checked={displaySettings.showRulers}
              onChange={() => actions.toggleRulers()}
            />
          </FormGroup>
        </div>
        <div className="me-2 d-flex align-items-center">
          Ruler Offset: {displaySettings.rulerOffset / 12}'
        </div>
        <div className="me-3 d-flex align-items-center">
          <Input
            type="range"
            min={1}
            max={8}
            defaultValue={displaySettings.rulerOffset / 12}
            onInput={handleRulerOffset}
          />
        </div>
        <div className="me-3" style={{ minWidth: "140px" }}>
          X-Position:{" "}
          <span className="font-monospace fw-bold">
            {toFeetInchesString(coordinates.x)}
          </span>
        </div>
        <div className="me-3" style={{ minWidth: "140px" }}>
          Y-Position:{" "}
          <span className="font-monospace fw-bold">
            {toFeetInchesString(coordinates.y)}
          </span>
        </div>
        {modelName ? (
          <div
            className="me-3"
            style={{
              textAlign: "center",
              minWidth: "80px",
              maxWidth: "140px",
              whiteSpace: "nowrap",
              overflowX: "hidden",
            }}
            title={modelName}
          >
            {modelName}
          </div>
        ) : null}
      </div>
      <div>
        <Button
          size="sm"
          color="dark"
          onClick={() => {
            setControlTarget("none");
            setModalOpen(true);
          }}
        >
          <FontAwesomeIcon icon="camera" className="me-2" />
          Export Image File
        </Button>
        <ImageModal isOpen={modalOpen} setIsOpen={setModalOpen} />
      </div>
      <div>
        <Button color="light">
          <FontAwesomeIcon icon="question-circle" className="me-2" />
          Help
        </Button>
      </div>
    </div>
  );
}

export default Toolbar;
