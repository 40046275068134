import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Select, {StylesConfig} from 'react-select';
import _ from "lodash";
import {DebounceInput} from "react-debounce-input";
import { constants } from "../utils";
import {Input, Label, Form, FormGroup, Modal, ModalHeader, ModalBody, ModalFooter, FormFeedback, Row, Col, Badge, Button, ButtonGroup} from "reactstrap";

export default function CaptureACH({achPayment, bankInformation, achValidation, onAchPaymentChange, showAchValidation, cleanseAchModal, saveACH}) {

  const validSelectStyle = {
    control: base => ({
      ...base,
      borderColor: '#5cb85c',
      // This line disable the blue border
      boxShadow: 'none'
    })
  };

  const inValidSelectStyle = {
    control: base => ({
      ...base,
      borderColor: '#d9534f',
      // This line disable the blue border
      boxShadow: 'none'
    })
  };

  const getAchAccountSelectColor = () => {
    if(showAchValidation) {
      return achValidation.accountType
            ? inValidSelectStyle
            : validSelectStyle;
    } else {
     return  achPayment.accountType
        ? validSelectStyle
        : {};
    }
  };


	return (<Modal isOpen centered size={'lg'}>
	  <ModalHeader>
	    Enter Banking Information
	  </ModalHeader>
	  <ModalBody>
	    <Form>
	      <FormGroup row>
	        <Col>
	          <Label>Routing Number:</Label>
	          <Input type={'text'} value={achPayment.routeNumber} name={'routeNumber'}
	                         onChange={(e) => onAchPaymentChange(e.target.name, e.target.value)} maxLength={9} minLength={9}
			  />
	        </Col>
	        <Col>
	          <Label>Account Number</Label>
	          <Input type={'text'} value={achPayment.accountNumber} name={'accountNumber'}
	                 onChange={(e) => onAchPaymentChange(e.target.name, e.target.value)}
	                 invalid={showAchValidation && achValidation.accountNumber}  valid={achPayment.accountNumber.length > 1}/>
	          <FormFeedback invalid>
	            Please enter an account number.
	          </FormFeedback>
	        </Col>
	      </FormGroup>
	      <FormGroup row>
	        <Col>
	          <Label>Account Type</Label>
	          <Select options={constants.BANKING_ACCOUNT_TYPES} styles={getAchAccountSelectColor()} onChange={(option) =>
	              onAchPaymentChange('accountType', option)} value={_.find(constants.BANKING_ACCOUNT_TYPES, a => {
	            return a.value === achPayment.accountType;
	          })}/>
	        </Col>
	        <Col sm={'4'}>
	          <Label>Account Holders Full Name</Label>
	          <Input type={'text'} value={achPayment.name} name={'name'} onChange={(e) => onAchPaymentChange(e.target.name, e.target.value)}
	                 invalid={showAchValidation && achValidation.name} valid={achPayment.name.length > 1}/>
	          <FormFeedback invalid>
	            Please enter a last name.
	          </FormFeedback>
	        </Col>
	      </FormGroup>
	    </Form>
	  </ModalBody>
	  <ModalFooter>
	    <Row className={'d-flex justify-content-end align-items-center'}>
	      <Col>
	      	<ButtonGroup className="float-end">
		        <Button size={'md'} className={'bg-secondary border-secondary'} onClick={cleanseAchModal}>
		          Cancel
		        </Button>
		        <Button size={'md'} className={'bg-primary border-primary'} onClick={saveACH}>
		          Save
		        </Button>
		      </ButtonGroup>
	      </Col>
	    </Row>
	  </ModalFooter>
	</Modal>);
}