import { porchTypes } from "../../floorplanConstants";

const initialExterior = {
  width: 0,
  height: 0,
  porchType: porchTypes.none,
  porchMin: 4 * 12,
  porchMax: 12 * 12,
  porchColorId: 0,
  porchWall1Length: 0,
  porchWall2Length: 0,
  porchWall3Length: 0,
  porchWall4Length: 0,
  price: 0,
  basePrice: 0
};

export default initialExterior;
