import React, {useState, useEffect, useContext, Fragment, useLayoutEffect} from 'react';
import {
    Button, ButtonGroup,
    Col,
    Row,
    Modal,
    ModalHeader,
    ModalFooter,
    ModalBody,
    Input
} from 'reactstrap';

export default function CancelInventoryModal({setShowCancelModal, cancelComment, setCancelComment, cancelInventory}) {

	return (<Modal isOpen fullscreen="sm" toggle={() => setShowCancelModal(false)}>
          <ModalHeader>
            Cancel Inventory
          </ModalHeader>
          <ModalBody>
            <Input type='text' maxLength="800" placeholder="Comment (Optional)"
              value={cancelComment} onChange={(e) => setCancelComment(e.target.value)}/>
          </ModalBody>
          <ModalFooter>
            <Row className={'d-flex justify-content-end'}>
              <Col>
                <ButtonGroup className="float-end">
                  <Button color='danger' className="text-light" onClick={cancelInventory}>Commit Cancellation</Button>
                  <Button onClick={() => setShowCancelModal(false)}>Close</Button>
                </ButtonGroup>
              </Col>
            </Row>
          </ModalFooter>
        </Modal>);
}