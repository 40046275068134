import React, {
  Fragment,
  useState,
  useEffect,
  useRef,
  useContext,
  useMemo,
} from "react";
import {
  Row,
  Col,
  FormGroup,
  Form,
  Input,
  InputGroup,
  Label,
  Button,
  ButtonGroup,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Alert as RSAlert,
  FormFeedback,
  UncontrolledTooltip, InputGroupText,
} from "reactstrap";
import Alert from "react-s-alert-v3";
import classnames from "classnames";
import { confirmAlert } from "react-confirm-alert";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { api, ui_helpers, date_helpers } from "../helpers";
import Card from "@repay/react-credit-card";
import {
  RecurringPaymentModal,
  CaptureACH,
  CCSwipeParser,
  PriceAdjustmentEditor,
  Loading
} from "./";
import _ from "lodash";
import { storage, constants, UserContext } from "../utils";
import {
  StartTablet,
  GetNumberOfTabletPoints,
  GetTabletPNG64ImageString,
  ClearTabletSurface,
  DeactivateTablet,
} from "../utils/SigWebTabletv1";
import Select, { StylesConfig } from "react-select";
import { DebounceInput } from "react-debounce-input";
import useSignalR from "../hooks/useSignalR";
import usePosHub from "../hooks/usePosHub";
import ToggleButton from "react-toggle-button";
import moment from "moment";

const BLOCK_EXIT_MESSAGE =
  "One or more price adjustments have not been saved. Please save or clear your pending changes.";

const defaultPaymentData = (existing, existingCards) => {
  if (!existing) existing = {};
  if (!existingCards) existingCards = [];
  return {
    unitPrice: existing.unitPrice || 0.0,
    enableRecurring: existing.enableRecurring || false,
    recurringBillingAddress: existing.recurringBillingAddress || "",
    recurringBillingEmail: existing.recurringBillingEmail || "",
    recurringBillingPhone: existing.recurringBillingPhone || "",
    recurringBillingCity: existing.recurringBillingCity || "",
    recurringBillingState: existing.recurringBillingState || "",
    recurringBillingZip: existing.recurringBillingZip || "",
    // creditCardType: _.find(constants.CREDIT_CARD_TYPES, cc => cc.label === 'Visa'),
    ccNumber: existing.ccNumber || "",
    ccExpiryMonth: existing.ccExpiryMonth || "",
    ccExpiryYear: existing.ccExpiryYear || "",
    ccFullName: existing.ccFullName || "",
    achFullName: existing.achFullName || "",
    achRoutingNo: existing.achRoutingNo || "",
    achAccountNo: existing.achAccountNo || "",
    achEntryClass: existing.achEntryClass || "",
    achType: "ACHDebit",
    achAccountType: existing.achAccountType || "",
    cards: existingCards,
    selectedCard: existingCards.length
      ? existingCards[existingCards.length - 1]
      : null,
    selectedBankAccount: existing.selectedBankAccount,
    paymentTypeId: existing.paymentTypeId || constants.PAYMENT_TYPE_IDS.CHECK,
    manualCard: existing.manualCard,
    signatureURL: existing.signatureURL || "",
    taxRateString: existing.taxRateString || "0.000",
    isTaxExempt: existing.isTaxExempt || false,
    taxExemptionForm: existing.taxExemptionForm || null,
    taxRate: existing.taxRate || 0.0,
    taxAmount: existing.taxAmount || 0.0,
    freightAmount: existing.freightAmount || 0.0,
    otherAmount: existing.otherAmount || 0.0,
    otherAmountCaption: existing.otherAmountCaption || "",
    subtotal: existing.subtotal || 0.0,
    bankAccounts: [],
    deliveryCounty: existing.deliveryCounty || "",
    isInCityLimits: existing.isInCityLimits || false,
    totalAddonPrice: existing.totalAddonPrice || 0,
    customerPhone1: existing.customerPhone1,
  };
};

let tabletHandle = null;

export default function Payment(props) {
  const [paymentData, setPaymentData] = useState(
    defaultPaymentData(props.contractData, props.cards)
  );
  const [screenWarnings, setScreenWarnings] = useState({});
  const [paymentList, setPaymentList] = useState(props.paymentList);
  const [capturingSignature, setCapturingSignature] = useState(false);
  const [capturingRecurring, setCapturingRecurring] = useState(false);
  const [capturingCard, setCapturingCard] = useState(false);
  const [capturingACH, setCapturingACH] = useState(false);
  const [capturingCardManually, setCapturingCardManually] = useState(false);
  const [achPayment, setAchPayment] = useState(constants.defaultPayment);
  const [achFirstName, setAchFirstName] = useState("");
  const [achLastName, setAchLastName] = useState("");
  const [bankInformation, setBankInformation] = useState(constants.defaultBank);
  const [routingNumberIsValid, setRoutingNumberIsValid] = useState(false);
  const [routingNumberIsInValid, setRoutingNumberIsInValid] = useState(false);
  const [achValidation, setAchValidation] = useState(
    constants.defaultAchValidation
  );
  const [showAchValidation, setShowAchValidation] = useState(false);
  const [capturingSignatureLinkRecipient, setCapturingSignatureLinkRecipient] =
    useState(false);
  const [capturingContractCodeSignatureLinkRecipient, setCapturingContractCodeSignatureLinkRecipient] =
    useState(false);
  const [capturingSignaturePad, setCapturingSignaturePad] = useState(false);
  const [signatureRequestLinkSent, setSignatureRequestLinkSent] =
    useState(false);
  const [signatureImage, setSignatureImage] = useState(null);
  const [ccFee, setCCFee] = useState(0.0);
  const [signatureLocations, setSignatureLocations] = useState(null);
  const { currentUser } = useContext(UserContext);
  const canvasRef = useRef(null);
  const cardSwipeRef = useRef(null);
  const [countyLookUpModal, setCountyLookUpModal] = useState(false);
  const [countyTaxList, setCountyTaxList] = useState(null);
  const [isPaying, setIsPaying] = useState(false);
  const [contractPasscode, setContractPasscode] = useState("");

  const {
    sessionStarted,
    completedSignatures,
    contractFinalized,
    paymentInfo,
    recurringEnabled,
  } = usePosHub(props.contractId);

// TODO:
  // useEffect(() => {
  //   if (recurringEnabled) {
  //     onChange(true, "enableRecurring");
  //     Alert.info("Customer has enabled recurring payments.");
  //   }
  // }, [recurringEnabled]);

  // useEffect(() => {
  //   if (paymentInfo) {
  //     console.log("PAYMENT INFOOOOOOOOO", paymentInfo);
  //     Alert.success("Customer has submitted recurring payment information.");
  //     onSetRecurringData(paymentInfo);
  //   }
  // }, [paymentInfo]);

  // useEffect(() => {
  //   if (props.contractId) {
  //     api.fetch("Signature/GetSignatureLocations/" + props.contractId)
  //       .then((res) => setSignatureLocations(res.data.data));
  //   }
  // }, [props.contractId]);

  useEffect(() => {
    if (!props.contractId) return;
    let data = _.cloneDeep(paymentData);
    api.post(`contract/GetContractAddonWithPrice/` + props.contractId).then(res => {
      if (res.data && res.data.success) {
        data.unitPrice = res.data.message.price;
        setPaymentData(data);
        setContractPasscode(res.data.message.contractPasscode);
        props.onSetData(data);
      }
    }).catch(err => console.error(err));
  }, []);

  useEffect(() => {
    setPaymentData(defaultPaymentData(props.contractData));
    recalc();
  }, [props.contractData]);

  useEffect(recalc, [props.priceAdjustments]);

  useEffect(() => {
    let newScreenWarnings = {};
    _.each(props.fieldWarnings, (w) =>
      Object.assign(newScreenWarnings, { [w.field]: w.text })
    );
    setScreenWarnings(newScreenWarnings);
  }, [props.fieldWarnings]);

  function recalc(value = null, fieldName = null, newPaymentList = null) {
    let newData =
      fieldName === null
        ? Object.assign({}, paymentData)
        : {
            ...paymentData,
            [fieldName]: ui_helpers.cleanseCurrencyCharacters(value),
          };
    if (fieldName === "taxRateString") {
      newData.taxRate = newData.isTaxExempt ? 0.0 : parseFloat(value);
      if (newData.taxRate > 1.0) {
        newData.taxRate /= 100.0;
      }
      newData.taxRate = newData.isTaxExempt ? 0.0 : parseFloat(newData.taxRate.toFixed(5));
    }
    const numericUnitPrice = parseFloat(newData.unitPrice);
    const unitPriceAdjustmentsSum = _.sumBy(
      _.filter(props.priceAdjustments, (pa) => pa.includeInUnitPrice),
      (pa) => parseFloat(pa.amount)
    );
    const allAdjustmentsSum = _.sumBy(props.priceAdjustments, (pa) =>
      parseFloat(pa.amount)
    );
    newData.taxAmount = newData.isTaxExempt ? 0.0 : newData.taxRate * (numericUnitPrice + unitPriceAdjustmentsSum);
    newData.subtotal = numericUnitPrice + newData.taxAmount + allAdjustmentsSum;
    const pl = newPaymentList ? newPaymentList : paymentList;
    const rawTotalPayments =
      pl && pl.length ? _.sumBy(pl, (x) => x.amount) : 0.0;
    const ccFees =
      pl && pl.length
        ? _.sumBy(pl, (x) => {
            return x.typeOfPayment ===
              constants.PAYMENT_TYPE_IDS.CREDIT_CARD_FEE
              ? x.amount
              : 0;
          })
        : 0.0;
    newData.balanceDue = newData.subtotal - rawTotalPayments + ccFees; // + unitPriceAdjustmentsSum;
    if (newData.balanceDue < 0) newData.balanceDue = 0;
    setPaymentData(newData);
  }

  function onBlur(value, fieldName) {
    switch(fieldName) {
      case 'unitPrice':
      case 'taxRateString':
      case 'initialPaymentAmount':
        recalc(value, fieldName);
        break;
    }
  }

  function onSetRecurringData(d) {
    setPaymentData({
      ...paymentData,
      ...d,
    });
    api
      .post(
        "contract/SetRecurringPaymentData",
        api.getRecurringPayload(props.contractId, d)
      )
      .then((r) => {
        if (r.data.success) {
          setCapturingRecurring(false);
        } else {
          Alert.error(r.data.message);
        }
      })
      .catch((e) => console.error(e));
  }

  function onChange(value, fieldName) {
    const newData = {
      ...paymentData,
      [fieldName]: value,
    };
    if (
      fieldName === "paymentTypeId" &&
      value === constants.PAYMENT_TYPE_IDS.CREDIT_CARD
    ) {
      loadCCList();
      setCapturingCard(true);
    }
    else if (
      fieldName === "paymentTypeId" &&
      value === constants.PAYMENT_TYPE_IDS.ACH_DEBIT
    ) {
      loadACHList();
      setCapturingACH(true);
    }
    if (fieldName === "taxRateString") {
      let newTaxValue = parseFloat(value);
      if (newTaxValue || newTaxValue === 0.0) {
        if (newTaxValue > 1.0) {
          newTaxValue /= 100.0;
        }
        newData.taxRate = parseFloat(newTaxValue.toFixed(5));
      }
    }
    if (fieldName === "cardZip") {
      if (cardSwipeRef && cardSwipeRef.current && value.length === 5) {
        try {
          setTimeout(() => cardSwipeRef.current.focus(), 400);
        } catch {}
      }
    }
    setPaymentData(newData);
    props.onSetData(newData);
  }

  function onUploadTaxExemptionForm(file) {
    const filePayload = {
      referenceId: props.contractData.contractId,
      file: file,
      typeOfAttachment: constants.ATTACHMENT_TYPE_IDS.TaxExemptionForm
    };
    onChange(filePayload, "taxExemptionForm");
  }

  function saveSignature() {
    if (GetNumberOfTabletPoints() === 0) {
      Alert.error("Please sign before continuing");
      return;
    }
    DeactivateTablet(tabletHandle);
    tabletHandle = null;
    // this works when debugging and a delay is allowed, but not consistently without a delay :(
    setTimeout(() => {
      GetTabletPNG64ImageString(function (b64PNGString) {
        api
          .post("contract/SaveSignature", {
            contractId: props.contractId,
            signatureTypeId: constants.SIGNATURE_TYPE_IDS.CUSTOMER_CONTRACT,
            b64PNGString: b64PNGString,
          })
          .then((r) => {
            if (r.data.success) {
              setPaymentData({
                ...paymentData,
                signatureURL: r.data.message,
              });
              closeSignatureCapture();
            } else {
              Alert.error(r.data.message);
            }
          })
          .catch((e) => console.error(e));
      });
    }, 500);
  }

  function loadCCList() {
    api
      .fetch("contract/ContractCCList/" + props.contractId)
      .then((r) => {
        const cardList = _.map(r.data, (x) => ({
          label: x.secureDisplay,
          value: x.id,
        }));
        setPaymentData((pd) => ({
          ...pd,
          cards: cardList,
          selectedCard: cardList.length ? cardList[cardList.length - 1] : null,
        }));
      })
      .catch((e) => console.error(e));
  }

  const loadACHList = () => {
    api
      .fetch(`contract/ContractACHList/${props.contractId}`)
      .then((r) => {
        const bankList = _.map(r.data, (x) => ({
          label: x.secureDisplay,
          value: x.id,
        }));
        setPaymentData((pd) => ({
          ...pd,
          bankAccounts: bankList,
          selectedBankAccount: bankList.length
            ? bankList[bankList.length - 1]
            : null
        }));
      })
      .catch((e) => console.error(e));
  };

  function disposeTablet() {
    try {
      ClearTabletSurface();
      DeactivateTablet(tabletHandle);
      tabletHandle = null;
    } catch (err) {
      // nothing the user will care about
    }
  }

  function startSignaturePad() {
    // startTablet(onSignatureCapture);
    try {
      tabletHandle =
        StartTablet(canvasRef.current.getContext("2d")) || tabletHandle;
    } catch (err) {
      tabletHandle = null;
    }
    if (tabletHandle == null) {
      console.error(
        "The signature pad could not be detected.  Please ensure that it has been properly installed."
      );
      Alert.error(
        "The signature pad could not be detected.  Please ensure that it has been properly installed."
      );
      closeSignatureCapture();
      disposeTablet();
    }
    setCapturingSignaturePad(true);
  }

  function swipeCreditCardKeydown(event) {
    if (event.which === 13) {
      var ccInfo = new CCSwipeParser(paymentData.cardSwipe);
      setPaymentData({
        ...paymentData,
        cardSwipe: "",
      });
      if (ccInfo.ErrorMessage) {
        Alert.error(ccInfo.ErrorMessage);
      } else {
        ccInfo.contractId = props.contractId;
        ccInfo.zip = paymentData.cardZip;
        api
          .post("contract/CC", ccInfo)
          .then((r) => {
            if (r.data.success) {
              setCapturingCard(false);
              loadCCList();
            } else {
              Alert.error(r.data.message);
            }
          })
          .catch((e) => console.error(e));
      }
      ccInfo = null;
      return false;
    }
  }

  function saveManualCard() {
    if (!paymentData.manualCard || paymentData.manualCard.ErrorMessage) return;
    paymentData.manualCard.contractId = props.contractId;
    api
      .post("contract/CC", paymentData.manualCard)
      .then((r) => {
        if (r.data.success) {
          setCapturingCardManually(false);
          setPaymentData({
            ...paymentData,
            manualCard: null,
          });
          loadCCList();
        } else {
          Alert.error(r.data.message);
        }
      })
      .catch((e) => console.error(e));
  }

  const saveACH = () => {
    let payload = Object.assign({}, achPayment);
    payload.companyId = props.companyId;
    payload.contractId = props.contractId;
    if (!validateAch(payload)) return;
    api
      .post("contract/ach", payload)
      .then((r) => {
        if (r.data.success) {
          cleanseAchModal();
          loadACHList();
        } else {
          Alert.error(r.data.message);
        }
      })
      .catch(api.catchHandler);
  };

  function closeSignatureCapture() {
    setCapturingSignature(false);
    setCapturingSignaturePad(false);
    setCapturingSignatureLinkRecipient(false);
    setCapturingContractCodeSignatureLinkRecipient(false);
    setSignatureRequestLinkSent(false);
  }

  function refreshSignatureStatus() {
    api
      .fetch("contract/GetSignatureDisplay/" + props.contractId)
      .then((r) => {
        if (r.data.success) {
          setPaymentData({
            ...paymentData,
            signatureURL: r.data.message,
          });
          if (r.data.message) {
            closeSignatureCapture();
          }
        } else {
          Alert.error(r.data.message);
        }
      })
      .catch(api.catchHandler);
  }

  function sendSignatureRequest() {
    if (_.trim(paymentData.signatureLinkRecipient) === "") {
      Alert.error(
        "You must provide a recipient email address or mobile phone number to send a signature request link."
      );
      return;
    }
    api
      .post("contract/SendMobileContractLink", {
        contractId: props.contractId,
        recipientEmail: paymentData.signatureLinkRecipient,
        allRelatedDealerUsers: false,
        mobileVisibilityTypeId: constants.MOBILE_VISIBILITY_CONTRACT_SIGNATURE_ID,
      })
      .then((r) => {
        if (r.data.success) {
          setSignatureRequestLinkSent(true);
          Alert.success(
            "A restricted link to the mobile contract site has just been sent to " +
              paymentData.signatureLinkRecipient
          );
        } else {
          Alert.error(r.data.message);
        }
      })
      .catch((e) => console.error(e));
  }

  function sendSignatureRequestWithCode() {
    if (_.trim(paymentData.signatureLinkRecipient) === "") {
      Alert.error(
        "You must provide a recipient email address or mobile phone number to send a signature request link."
      );
      return;
    }
    api
      .post("contract/SendMobileSignatureCapture", {
        contractId: props.contractId,
        recipientEmail: paymentData.signatureLinkRecipient,
        allRelatedDealerUsers: false,
        mobileVisibilityTypeId: constants.MOBILE_VISIBILITY_CONTRACT_SIGNATURE_ID,
      })
      .then((r) => {
        if (r.data.success) {
          setSignatureRequestLinkSent(true);
          Alert.success(
            "A restricted link to the mobile contract site has just been sent to " +
              paymentData.signatureLinkRecipient
          );
        } else {
          Alert.error(r.data.message);
        }
      })
      .catch((e) => console.error(e));
  }

  function signContract() {
    localStorage.setItem('signingContract', 'true');
    window.open(window.location.origin + "/sign/" + contractPasscode.signatureAccessToken, "_blank");
  }

  function generateContractCode() {
    api
      .post("contract/GenerateNewContractCode/" + props.contractId, {
        contractId: props.contractId,
        currentUserId: currentUser.id,
      })
      .then((r) => {
        if (r.data.success) {
          setContractPasscode(r.data.message);
          Alert.success("A new contract code has been generated.");
        } else {
          Alert.error(r.data.message);
        }
      })
      .catch((e) => console.error(e));
  }

  function removeSignature() {
    confirmAlert({
      title: "Confirm Deletion",
      message:
        "Are you sure you'd like to permanently and irreversibly delete this customer signature?",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            api
              .post("contract/DeleteSignature", {
                contractId: props.contractId,
                signatureTypeId: constants.SIGNATURE_TYPE_IDS.CUSTOMER_CONTRACT,
              })
              .then((r) => {
                if (r.data.success) {
                  onChange("", "signatureURL");
                } else {
                  Alert.error(r.data.message);
                }
              })
              .catch((e) => console.error(e));
          },
        },
        {
          label: "No",
        },
      ],
    });
  }

  function onApplyPayment() {
    if (
      paymentData.paymentTypeId === constants.PAYMENT_TYPE_IDS.CREDIT_CARD &&
      !paymentData.selectedCard
    ) {
      Alert.error(
        "You must first provide a credit card in order to charge a credit card."
      );
      return;
    }
    if (
      paymentData.paymentTypeId === constants.PAYMENT_TYPE_IDS.ACH_DEBIT &&
      !paymentData.selectedBankAccount
    ) {
      Alert.error("You must first provide a valid bank account.");
      return;
    }
    let ccId = null;
    if (paymentData.paymentTypeId === constants.PAYMENT_TYPE_IDS.CREDIT_CARD) {
      ccId = paymentData.selectedCard.value;
    } else if (paymentData.paymentTypeId === constants.PAYMENT_TYPE_IDS.ACH_DEBIT) {
      ccId = paymentData.selectedBankAccount.value;
    }
    setIsPaying(true);
    api
      .post("contract/AddManualPayment", {
        contractId: props.contractId,
        ccId: ccId,
        typeOfPayment: paymentData.paymentTypeId,
        identifier: paymentData.paymentIdentifier,
        amount: paymentData.paymentAmount,
        otherAmountCaption: paymentData.otherAmountCaption,
      })
      .then((r) => {
        if (r.data.success) {
          setPaymentData({
            ...paymentData,
            paymentIdentifier: "",
            paymentAmount: 0,
          });
          setPaymentList(r.data.message);
          if (props.setPaymentList) {
            props.setPaymentList(r.data.message);
          }
          recalc(null, null, r.data.message);
          const isRTO = props.contractType === constants.CONTRACT_TYPE.RTO;

        } else {
          Alert.error(r.data.message);
        }
      })
      .catch((e) => console.error(e))
      .finally(() => setIsPaying(false));
  }

  function onDeletePayment(p) {
    api
      .post("contract/DeletePayment", { id: p.id })
      .then((r) => {
        if (r.data.success) {
          setPaymentList(r.data.message);
          if (props.setPaymentList) {
            props.setPaymentList(r.data.message);
          }
          recalc(null, null, r.data.message);
        } else {
          Alert.error(r.data.message);
        }
      })
      .catch((e) => console.error(e));
  }

  const onAchPaymentChange = (fieldName, fieldValue) => {
    let newPayment = Object.assign({}, achPayment);
    let newValidation = Object.assign({}, achValidation);
    if (fieldName === "accountType") {
      newPayment.accountType = fieldValue.value;
      newPayment.entryClass = fieldValue.entryClass;
    } else if (fieldName === "routeNumber") {
      // validatedRoutingNumber(fieldValue);
      newPayment.routeNumber = fieldValue;
    } else {
      newPayment[fieldName] = fieldValue;
    }
    validateAch(newPayment);
    setAchPayment(newPayment);
    setAchValidation(newValidation);
  };

  const cleanseAchModal = () => {
    setBankInformation(constants.defaultBank);
    setAchPayment(constants.defaultPayment);
    setAchValidation(constants.defaultAchValidation);
    setCapturingACH(false);
  };

  const validateAch = (vAch) => {
    let invalidAch = Object.assign({}, achValidation);
    let warnings = [];
    if (!vAch.routeNumber.length) {
      warnings.push("Please correct your routing number before continuing.");
      invalidAch.routingNumber = true;
    } else {
      invalidAch.routingNumber = false;
    }
    if (!vAch.name || !vAch.name.length) {
      warnings.push("Please enter the account holders first name.");
      invalidAch.name = true;
    } else {
      invalidAch.name = false;
    }
    if (!vAch.accountType) {
      warnings.push("Please select an account type.");
      invalidAch.accountType = true;
    } else {
      invalidAch.accountType = false;
    }
    if (!vAch.accountNumber) {
      warnings.push("Please enter an account number.");
      invalidAch.accountNumber = true;
    } else {
      invalidAch.accountNumber = false;
    }
    setAchValidation(invalidAch);
    if (Object.keys(invalidAch).some((k) => invalidAch[k] === true)) {
      setShowAchValidation(true);
      return false;
    } else {
      setShowAchValidation(false);
      return true;
    }
  };

  function findDeliveryCounty() {
    if (paymentData.deliveryCounty === "") {
      Alert.error("Delivery County Name is required");
      return;
    }
    api
      .post("Contract/FindTaxRate", {
        contractId: props.contractId,
        isInCityLimits: paymentData.isInCityLimits,
        deliveryCounty: paymentData.deliveryCounty,
      })
      .then((res) => {
        if (res.data && res.data.success) {
          let taxRateString = res.data.message.taxRateValue * 100;
          const newData = {
            ...paymentData,
            taxRate: res.data.message.taxRateValue,
            taxRateString: taxRateString,
          };
          setPaymentData(newData);
          props.onSetData(newData);
          recalc(taxRateString, "taxRateString");
          //Alert.Info(`${taxRateString}% was found for the delivery county`)
        }
      })
      .catch((error) => {
        Alert.error("An error occurred during county lookup");
        console.error(error);
      });
  }

  function onShowMobileLink() {
    if (paymentData.customerPhone1) {
      setPaymentData({
        ...paymentData,
        signatureLinkRecipient: ui_helpers.formatPhoneField(
          paymentData.customerPhone1
        ),
      });
    }
    setCapturingSignatureLinkRecipient(true);
  }

  function onShowContractCodeMobileLink() {
    if (paymentData.customerPhone1) {
      setPaymentData({
        ...paymentData,
        signatureLinkRecipient: ui_helpers.formatPhoneField(
          paymentData.customerPhone1
        ),
      });
    }
    setCapturingContractCodeSignatureLinkRecipient(true);
  }

  function onDeleteSelectedCC() {
    if (!paymentData.selectedCard) return;
    const deleteCCId = paymentData.selectedCard.value;
    confirmAlert({
      title: "Confirm Credit Card Delete",
      message: "Are you sure you'd like to permanently and irreversibly delete this credit card and ALL RELATED PAYMENTS?",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            api.post('contract/DeleteCC', {
              contractId: props.contractId,
              accountId: deleteCCId
            }).then(r => {
              if (r.data.success) {
                setPaymentList(r.data.message);
                if (props.setPaymentList) {
                  props.setPaymentList(r.data.message);
                }
                recalc(null, null, r.data.message);
                loadCCList();
              } else {
                Alert.error(r.data.message);
              }
            }).catch((e) => console.error(e));    
          }
        },
        {
          label: "No"
        }
      ]
    });
  }

  function onDownloadAttachment(contractId, key = null, onComplete = null) {
    const pdfWindow = window.open();
    // null document key means download all
    api.downloadAndOpenFile(
        "contract/DownloadContractDocument",
        { referenceId: contractId, documentKey: key },
        pdfWindow,
        (e) => {
          console.error(e);
          // Alert.error("There was an error downloading the attachment");
          if (pdfWindow) {
            pdfWindow.close();
          }
        },
        "application/pdf",
        onComplete
    );
  }

  function handleTaxExemptToggle(value) {
    // setTaxExempt(!value);
    onChange(!value, 'isTaxExempt');
  }

  function onDeleteSelectedACH() {
    if (!paymentData.selectedBankAccount) return;
    const deleteACHId = paymentData.selectedBankAccount.value;
    confirmAlert({
      title: "Confirm ACH Delete",
      message: "Are you sure you'd like to permanently and irreversibly delete this ACH bank account and ALL RELATED PAYMENTS?",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            api.post('contract/DeleteACH', {
              contractId: props.contractId,
              accountId: deleteACHId
            }).then(r => {
              if (r.data.success) {
                setPaymentList(r.data.message);
                if (props.setPaymentList) {
                  props.setPaymentList(r.data.message);
                }
                recalc(null, null, r.data.message);
                loadACHList();
              } else {
                Alert.error(r.data.message);
              }
            }).catch((e) => console.error(e));    
          }
        },
        {
          label: "No"
        }
      ]
    });
  }

  const isSysAdmin = ui_helpers.isSystemAdministrator(currentUser);
  const isCompanyAdmin = props.companyId
    ? ui_helpers.isCompanyAdministrator(currentUser, props.companyId)
    : false;

  return (
    <Row>
      <Col xs="4">
        <Form className="mt-2">
          <Row>
            <Col>
              <div className="d-grid mb-3">
                {paymentData.signatureURL ? (
                  <Row>
                    <Col xs="6">
                      <Button
                        block
                        color="warning"
                        className="text-light"
                        onClick={() => setCapturingSignature(true)}
                      >
                        <FontAwesomeIcon
                          icon="hand-point-right"
                          className="me-2"
                        />
                        Replace Signature
                      </Button>
                    </Col>
                    <Col xs="6">
                      <Button
                        block
                        color="danger"
                        className="text-light"
                        onClick={removeSignature}
                      >
                        <FontAwesomeIcon icon="times" className="me-2" />
                        Remove Signature
                      </Button>
                    </Col>
                  </Row>
                ) : (
                  <>
                    {sessionStarted &&
                    completedSignatures?.length < signatureLocations?.length &&
                    !contractFinalized ? (
                      <>
                        <h5 className="text-primary">
                          The customer is reviewing the contract on another
                          device.
                        </h5>
                        <strong>
                          This contract document requires{" "}
                          {signatureLocations?.length} signatures.
                        </strong>
                        <ul className="list-group">
                          {signatureLocations?.map((sl, i) => (
                            <li key={sl.id} className="list-group-item">
                              <FontAwesomeIcon
                                icon={
                                  completedSignatures.includes(sl.id)
                                    ? "check"
                                    : "times"
                                }
                                className={
                                  completedSignatures.includes(sl.id)
                                    ? "text-success me-2"
                                    : "text-danger me-2"
                                }
                              />
                              Signature {i + 1}: Page {sl.pageIndex + 1}
                            </li>
                          ))}
                        </ul>
                      </>
                    ) : null}
                    {sessionStarted &&
                    completedSignatures?.length ===
                      signatureLocations?.length &&
                    !contractFinalized ? (
                      <h5 className="text-primary">
                        Waiting for customer to finalize signatures.
                      </h5>
                    ) : null}
                    {sessionStarted &&
                    completedSignatures?.length ===
                      signatureLocations?.length &&
                    contractFinalized ? (
                      <h4 className="text-success fw-bold">
                        Customer has signed the contract.
                      </h4>
                    ) : null}
                    {/* {!sessionStarted ? ( */}
                      <Button
                        color="primary"
                        onClick={() => setCapturingSignature(true)}
                      >
                        <FontAwesomeIcon
                          icon="hand-point-right"
                          className="me-2"
                        />
                        Capture Signature
                      </Button>
                    {/* ) : null} */}
                  </>
                )}
              </div>
              {props.contractType === constants.CONTRACT_TYPE.RTO ? (
                <Fragment>
                  <div className="mb-1">
                    <Row>
                      <Col xs="6" className="pt-1">
                        <Label>
                          Initial Payment{" "}
                          {screenWarnings.initialPaymentAmount ? (
                            <span id="initialPaymentError">
                              {ui_helpers.redExclamation()}
                            </span>
                          ) : null}
                          {screenWarnings.initialPaymentAmount && (
                            <UncontrolledTooltip
                              target="initialPaymentError"
                              placement="top"
                            >
                              {screenWarnings.initialPaymentAmount}
                            </UncontrolledTooltip>
                          )}
                        </Label>
                      </Col>
                      <Col xs="6">
                        <Input
                          type="text"
                          readOnly
                          disabled
                          title={
                            screenWarnings.initialPaymentAmount
                              ? screenWarnings.initialPaymentAmount
                              : null
                          }
                          className={classnames(
                            {
                              invalid: screenWarnings.initialPaymentAmount
                                ? true
                                : false,
                            },
                            "text-end form-control"
                          )}
                          value={ui_helpers.formatCurrency(
                            props.contractData.initialPaymentAmount ?? 0.0
                          )}
                        />
                      </Col>
                    </Row>
                  </div>
                </Fragment>
              ) : (
                <Fragment>
                  <Row className="mb-1">
                    <Col xs="6" className="pt-1">
                      <Label>Unit Price</Label>
                    </Col>
                    <Col xs="6">
                      <Input
                        type="text"
                        maxLength="15"
                        value={
                          props.unitPriceDisabled
                            ? ui_helpers.formatCurrency(paymentData.unitPrice)
                            : paymentData.unitPrice
                        }
                        title={
                          screenWarnings.unitPrice
                            ? screenWarnings.unitPrice
                            : null
                        }
                        className={classnames(
                          { invalid: screenWarnings.unitPrice ? true : false },
                          "text-end form-control"
                        )}
                        onChange={(e) => onChange(e.target.value, "unitPrice")}
                        onBlur={(e) => onBlur(e.target.value, "unitPrice")}
                        disabled={props.unitPriceDisabled}
                      />
                    </Col>
                  </Row>
                  <PriceAdjustmentEditor
                    unitPrice={paymentData.unitPrice}
                    priceAdjustments={props.priceAdjustments}
                    adjustmentTypeOptions={props.adjustmentTypeOptions}
                    pendingPriceAdjustment={props.pendingPriceAdjustment}
                    addPriceAdjustment={props.addPriceAdjustment}
                    deletePriceAdjustment={props.deletePriceAdjustment}
                    setPendingPriceAdjustment={props.setPendingPriceAdjustment}
                  />
                  <Row className="mb-1">
                    <Col xs="6">
                      <Label>Tax Rate {ui_helpers.requiredStar()}</Label>
                    </Col>
                    <Col xs="6">
                      <InputGroup>

                      <Input
                        type="text"
                        bsSize="sm"
                        maxLength="15"
                        readOnly={paymentData.isTaxExempt}
                        value={paymentData.isTaxExempt ? 0.0 : paymentData.taxRateString}
                        className="text-end"
                        style={{ width: "80px", float: "left" }}
                        onChange={(e) =>
                          onChange(e.target.value, "taxRateString")
                        }
                        onBlur={(e) => onBlur(e.target.value, "taxRateString")}
                      />
                        <InputGroupText>%</InputGroupText>
                      </InputGroup>

                      {/*<div*/}
                      {/*  style={{*/}
                      {/*    float: "left",*/}
                      {/*    width: "50px",*/}
                      {/*    paddingTop: "4px",*/}
                      {/*  }}*/}
                      {/*>*/}
                      {/*  {" "}*/}
                      {/*  %*/}
                      {/*</div>*/}
                    </Col>
                  </Row>
                  <Row className="mb-1">
                    <Col xs="6"/>
                    <Col xs="3" className={'d-flex justify-content-end'}>
                      <ToggleButton value={paymentData.isTaxExempt} inactiveLabel={''} activeLabel={''} onToggle={(val) => handleTaxExemptToggle(val)}/>
                    </Col>
                    <Col xs={"3"}>
                      <Label>Tax Exempt</Label>
                    </Col>
                  </Row>
                  {paymentData.isTaxExempt  ?
                      ( <Row>
                      <Row className="d-flex">
                        <Label>Tax Exemption Form {ui_helpers.requiredStar()}</Label>
                      </Row>
                      <Row>
                        {paymentData?.taxExemptionForm
                            ? <>
                              {paymentData?.taxExemptionForm.file ? <span>{paymentData?.taxExemptionForm.file?.name ?? "Tax Exemption Form"}</span> : null}
                              {paymentData?.taxExemptionForm.name ?
                                  <Button
                                      color="link"
                                      className="download-link text-start"
                                      onClick={() =>
                                          onDownloadAttachment(paymentData?.taxExemptionForm.contractId, paymentData?.taxExemptionForm.key)
                                      }
                                  >
                                    {paymentData?.taxExemptionForm.name}
                                  </Button> : null}</>
                            : <input type="file" id="myFile"
                                     name="taxExemptionForm"
                                     accept="application/pdf"
                                     onChange={(e) => onUploadTaxExemptionForm(e.target.files[0])}/>}
                      </Row>
                      </Row>) : null}
                  <Row className="mb-1">
                    <Col xs="6">
                      <Label>Tax Amount</Label>
                    </Col>
                    <Col xs="6">
                      <Input
                        type="text"
                        bsSize="sm"
                        className="text-end"
                        readOnly
                        disabled
                        value={ui_helpers.formatCurrency(paymentData.taxAmount)}
                      />
                    </Col>
                  </Row>
                  <Row className="mb-1">
                    <Col xs="6">
                      <Label>Delivery County {ui_helpers.requiredStar()}</Label>
                    </Col>
                    <Col xs="6">
                      <Row>
                        <Col>
                          <Input
                            type="text"
                            bsSize="sm" //readOnly disabled
                            title={
                              screenWarnings.deliveryCounty
                                ? screenWarnings.deliveryCounty
                                : null
                            }
                            className={classnames(
                              {
                                invalid: screenWarnings.deliveryCounty
                                  ? true
                                  : false,
                              },
                              "text-end form-control"
                            )}
                            value={paymentData.deliveryCounty}
                            onChange={(e) =>
                              onChange(e.target.value, "deliveryCounty")
                            }
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  {/*<Row className="mb-1">*/}
                  {/*  <Col xs="6"></Col>*/}
                  {/*  <Col xs="6">*/}
                  {/*    <Row>*/}
                  {/*      <Col>*/}
                  {/*        <Label>*/}
                  {/*          <span className="mr-2">In City Limits? </span>*/}
                  {/*          <Input*/}
                  {/*            type="checkbox"*/}
                  {/*            className="ml-2"*/}
                  {/*            checked={paymentData.isInCityLimits}*/}
                  {/*            onChange={() =>*/}
                  {/*              onChange(*/}
                  {/*                !paymentData.isInCityLimits,*/}
                  {/*                "isInCityLimits"*/}
                  {/*              )*/}
                  {/*            }*/}
                  {/*          />*/}
                  {/*        </Label>*/}
                  {/*      </Col>*/}
                  {/*      <Col xs="5">*/}
                  {/*        <Button*/}
                  {/*          size="sm"*/}
                  {/*          color="primary"*/}
                  {/*          className="light-text"*/}
                  {/*          onClick={findDeliveryCounty}*/}
                  {/*          title="Look up Delivery County Tax Rate"*/}
                  {/*        >*/}
                  {/*          Find Tax Rate{" "}*/}
                  {/*          /!*<FontAwesomeIcon icon="search" />*!/*/}
                  {/*        </Button>*/}
                  {/*      </Col>*/}
                  {/*    </Row>*/}
                  {/*  </Col>*/}
                  {/*</Row>*/}
                  <Row className="mb-1">
                    <Col xs="6" className="pt-2">
                      <Label>Total</Label>
                    </Col>
                    <Col xs="6">
                      <Input
                        type="text"
                        className="text-end"
                        readOnly
                        disabled
                        value={ui_helpers.formatCurrency(paymentData.subtotal)}
                      />
                    </Col>
                  </Row>
                </Fragment>
              )}
              <div className="mb-1">
                <Row>
                  <Col xs="6" className="pt-1">
                    <Label>
                      {props.contractType === constants.CONTRACT_TYPE.RTO
                        ? ""
                        : "Down "}
                      Payment Type {ui_helpers.requiredStar()}
                    </Label>
                  </Col>
                  <Col
                    xs="6"
                    className="form-control-sm"
                    style={{ padding: "0 12px" }}
                  >
                    <Select
                      options={_.reject(props.paymentTypes, (x) => x.hidden)}
                      value={_.find(constants.PAYMENT_TYPES, (o) => o.value === paymentData.paymentTypeId)}
                      onChange={({ value: newPaymentTypeId }) => onChange(newPaymentTypeId, "paymentTypeId")}
                    />
                  </Col>
                </Row>
              </div>
              {paymentData.paymentTypeId === constants.PAYMENT_TYPE_IDS.CREDIT_CARD ? (
                <div className="mb-1">
                  <Row>
                    <Col xs="6" className="pt-1">
                      <Label>Cards</Label>
                    </Col>
                    <Col
                      xs="6"
                      className="form-control-sm"
                      style={{ padding: "0 12px" }}
                    >
                      <InputGroup size="sm">
                        <div style={{width: "70%"}}>
                          <Select
                            options={paymentData.cards}
                            value={paymentData.selectedCard}
                            onChange={(x) => onChange(x, "selectedCard")}
                          />
                        </div>
                        <Button
                          color="dark"
                          className="text-light"
                          title="Add Credit Card By Swipe"
                          style={{ zIndex: 0 }}
                          onClick={() => setCapturingCard(true)}
                        >
                          <FontAwesomeIcon icon="credit-card" />
                        </Button>
                        {isSysAdmin && paymentData.selectedCard
                          ? (<Button className="text-light" color="danger" title="Delete Credit Card" style={{zIndex: 0}} onClick={onDeleteSelectedCC}>
                              <FontAwesomeIcon icon="times-circle" />
                            </Button>)
                          : null
                        }
                      </InputGroup>
                    </Col>
                  </Row>
                </div>
              ) : null}
              {paymentData.paymentTypeId === constants.PAYMENT_TYPE_IDS.ACH_DEBIT ? (
                <div className="mb-1">
                  <Row>
                    <Col xs="6" className="pt-1">
                      <Label>Bank Account</Label>
                    </Col>
                    <Col
                      xs="6"
                      className="form-control-sm"
                      style={{ padding: "0 12px" }}
                    >
                      <InputGroup size="sm">
                        <div style={{width: "85%"}}>
                          <Select
                            options={paymentData.bankAccounts}
                            value={paymentData.selectedBankAccount}
                            onChange={(x) => onChange(x, 'selectedBankAccount')}
                          />
                        </div>
                        {isSysAdmin && paymentData.selectedBankAccount
                          ? (<Button className="text-light" color="danger" title="Delete ACH" style={{zIndex: 0}} onClick={onDeleteSelectedACH}>
                              <FontAwesomeIcon icon="times-circle" />
                            </Button>)
                          : null
                        }
                      </InputGroup>
                    </Col>
                  </Row>
                </div>
              ) : null}
              {paymentData.paymentTypeId === constants.PAYMENT_TYPE_IDS.CHECK || paymentData.paymentTypeId === constants.PAYMENT_TYPE_IDS.MONEY_ORDER ? (
                <div className="mb-1">
                  <Row>
                    <Col xs="6" className="pt-1">
                      <Label>
                        Check Number {ui_helpers.requiredStar()}
                      </Label>
                    </Col>
                    <Col xs="6">
                      <Input
                        type="text"
                        maxLength="15"
                        value={paymentData.paymentIdentifier}
                        onChange={(e) =>
                          onChange(e.target.value, "paymentIdentifier")
                        }
                        onBlur={(e) =>
                          onBlur(e.target.value, "paymentIdentifier")
                        }
                      />
                    </Col>
                  </Row>
                </div>
              ) : null}
              <div className="mb-1">
                <Row>
                  <Col xs="6" className="pt-1">
                    <Label>
                      {props.contractType === constants.CONTRACT_TYPE.RTO
                        ? ""
                        : "Down "}
                      Payment Amount {ui_helpers.requiredStar()}
                    </Label>
                  </Col>
                  <Col xs="6">
                    <Input
                      type="text"
                      size="sm"
                      className="text-end"
                      maxLength="15"
                      value={paymentData.paymentAmount}
                      onChange={(e) =>
                        onChange(e.target.value, "paymentAmount")
                      }
                      onBlur={(e) => onBlur(e.target.value, "paymentAmount")}
                    />
                  </Col>
                </Row>
              </div>
              {props.contractType === constants.CONTRACT_TYPE.RTO ? (
                <Row className="mb-1">
                  <Col xs="6" className="pt-1">
                    <div
                      className="mb-1"
                      style={{ cursor: "pointer" }}
                      onClick={(e) => {
                        e.stopPropagation();
                        if (!paymentData.enableRecurring) {
                          setCapturingRecurring(true);
                        }
                        onChange(
                          !paymentData.enableRecurring,
                          "enableRecurring"
                        );
                      }}
                    >
                      <Input
                        type="checkbox"
                        checked={paymentData.enableRecurring}
                        onChange={(e) => {
                          e.stopPropagation();
                          if (!paymentData.enableRecurring) {
                            setCapturingRecurring(true);
                          }
                          onChange(
                            !paymentData.enableRecurring,
                            "enableRecurring"
                          );
                        }}
                      />{" "}
                      Enable Recurring Payments
                    </div>
                  </Col>
                  <Col xs="6">
                    {paymentData.enableRecurring ? (
                      <Button
                        block
                        color="warning"
                        className="text-light"
                        onClick={() => setCapturingRecurring(true)}
                      >
                        Set Recurring Payment Information
                      </Button>
                    ) : null}
                  </Col>
                </Row>
              ) : null}
              {isPaying
                ? (<div className={"d-flex justify-content-center align-items-center"}>
                    <Loading messageText="Processing Payment..." />
                  </div>)
                : (<div className="d-grid">
                    <Button
                      color="success"
                      className="text-light"
                      onClick={onApplyPayment}
                    >
                      <FontAwesomeIcon icon="plus-circle" className="me-2" />
                      Apply Payment
                    </Button>
                  </div>)
              }              
            </Col>
          </Row>
          {props.contractType === constants.CONTRACT_TYPE.RTO ? (
            <Row className="my-3">
              <Col>
                <Table bordered className="mb-0">
                  <thead>
                    <tr>
                      <th>Type</th>
                      <th>Paid / Processed</th>
                      <th>Amount</th>
                      <th />
                    </tr>
                  </thead>
                  <tbody>
                    {paymentList && paymentList.length ? (
                      <Fragment>
                        {_.map(paymentList, (x) => (
                          <tr key={x.id}>
                            <td>
                              {
                                _.find(
                                  constants.PAYMENT_TYPES,
                                  (pt) => pt.value === x.typeOfPayment
                                ).label
                              }
                              <br />
                              {x.identifier}
                            </td>
                            <td>
                              <div>Paid {date_helpers.dateTimeFormat(x.paidAt)}</div>
                              {x.processedAt
                                ? (<div className="text-muted fst-italic">Processed {date_helpers.dateTimeFormat(x.processedAt)}</div>)
                                : null
                              }
                            </td>
                            <td className="text-end">
                              {ui_helpers.formatCurrency(x.amount)}
                            </td>
                            <td>
                              {!x.processedAt || isSysAdmin || isCompanyAdmin
                                ? (<Button
                                    className="text-light xs float-end"
                                    color="danger"
                                    onClick={() => onDeletePayment(x)}
                                  >
                                    <FontAwesomeIcon icon="times-circle" />
                                  </Button>)
                                : null
                              }
                            </td>
                          </tr>
                        ))}
                        <tr className="tr-bold active">
                          <td colSpan="3" className="pt-1 text-end">
                            <label style={{ fontWeight: "bold" }}>
                              Payments
                            </label>
                          </td>
                          <td
                            className="text-end"
                            style={{
                              borderTop: "1px #000 solid",
                              borderBottom: "3px #000 double",
                            }}
                          >
                            {ui_helpers.formatCurrency(
                              paymentList.length
                                ? _.sumBy(paymentList, (x) => x.amount)
                                : 0.0
                            )}
                          </td>
                        </tr>
                        {props.contractType ===
                        constants.CONTRACT_TYPE.RTO ? null : (
                          <tr>
                            <td colSpan="3" className="pt-1 text-end">
                              <label
                                style={{
                                  backgroundColor: "Yellow",
                                  fontWeight: "bold",
                                }}
                              >
                                Balance Due
                              </label>
                            </td>
                            <td
                              className="text-end"
                              style={{ fontWeight: "bold" }}
                            >
                              {ui_helpers.formatCurrency(
                                paymentData.balanceDue
                              )}
                            </td>
                          </tr>
                        )}
                      </Fragment>
                    ) : (
                      <tr>
                        <td colSpan="5">No payments have yet been recorded.</td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </Col>
            </Row>
          ) : null}
        </Form>
      </Col>
      <Col xs="8">
        {paymentData.signatureURL ? (
          <div>
            <img
              style={{ width: "250px", float: "left" }}
              src={paymentData.signatureURL}
              alt="Customer Signature"
            />
          </div>
        ) : null}
        {props.contractType === constants.CONTRACT_TYPE.RTO ? (
          <div
            style={{
              backgroundColor: "#E5E5FF",
              border: "solid 1px #999",
              padding: "10px",
              marginTop: "8px",
              clear: "both",
            }}
          >
            <h4>Recurring Payments Will Make Your Life Easier</h4>
            <ul>
              <li>It’s convenient, saving you time and postage</li>
              <li>
                Your payment is always on time, even if you forget about it or
                are traveling
              </li>
              <li>Eliminates late fees, saving you money</li>
            </ul>
            <h4>Here’s How Recurring Payments Work</h4>
            <ul>
              <li>You authorize monthly charges to your card.</li>
              <li>
                You will be charged each billing period for the total amount due
                for that period.
              </li>
              <li>
                You will be emailed a receipt and the charge will appear on your
                credit card or bank statement.
              </li>
            </ul>
          </div>
        ) : (
          <Row className="my-3">
            <Col>
              <Table bordered className="mb-0">
                <thead>
                  <tr>
                    <th>Type</th>
                    <th>Number</th>
                    <th>Paid/Processed</th>
                    <th>Amount</th>
                    <th />
                  </tr>
                </thead>
                <tbody>
                  {paymentList && paymentList.length ? (
                    <Fragment>
                      {_.map(paymentList, (x) => (
                        <tr key={x.id}>
                          <td>
                            {
                              _.find(
                                constants.PAYMENT_TYPES,
                                (pt) => pt.value === x.typeOfPayment
                              ).label
                            }
                          </td>
                          <td>{x.identifier}</td>
                          <td>
                            {date_helpers.dateTimeFormat(x.paidAt)}{" "}
                            {x.processedAt ? ui_helpers.greenCheck() : null}
                          </td>
                          <td className="text-end">
                            {ui_helpers.formatCurrency(x.amount)}
                          </td>
                          <td>
                            {x.processedAt && !isSysAdmin && !isCompanyAdmin
                              ? null
                              : (<Button
                                  className="text-light xs float-end"
                                  color="danger"
                                  onClick={() => onDeletePayment(x)}
                                >
                                  <FontAwesomeIcon icon="times-circle" />
                                </Button>)
                            }
                          </td>
                        </tr>
                      ))}
                      <tr className="tr-bold active">
                        <td colSpan="3" className="pt-1 text-end">
                          <label style={{ fontWeight: "bold" }}>Payments</label>
                        </td>
                        <td
                          className="text-end"
                          style={{
                            borderTop: "1px #000 solid",
                            borderBottom: "3px #000 double",
                          }}
                        >
                          {ui_helpers.formatCurrency(
                            paymentList.length
                              ? _.sumBy(paymentList, (x) => x.amount)
                              : 0.0
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td colSpan="3" className="pt-1 text-end">
                          <label
                            style={{
                              backgroundColor: "Yellow",
                              fontWeight: "bold",
                            }}
                          >
                            Balance Due
                          </label>
                        </td>
                        <td className="text-end" style={{ fontWeight: "bold" }}>
                          {ui_helpers.formatCurrency(paymentData.balanceDue)}
                        </td>
                      </tr>
                    </Fragment>
                  ) : (
                    <tr>
                      <td colSpan="5">No payments have yet been recorded.</td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </Col>
          </Row>
        )}
        {props.creditCardFeePercentage && props.contractType !== constants.CONTRACT_TYPE.RTO
          ? (<table className="mb-0 mt-3 ms-2"><tbody><tr>
              <td colSpan="5" style={{fontWeight: "bold", borderStyle: "none"}}>
                Note that a {props.creditCardFeePercentage}% credit card fee will be added automatically to any credit card transactions
              </td>
            </tr></tbody></table>)
          : null
        }
      </Col>
      {capturingRecurring ? (
        <RecurringPaymentModal
          recurringData={props.contractData}
          onCancel={() => setCapturingRecurring(false)}
          onSetRecurringData={onSetRecurringData}
        />
      ) : null}
      {capturingSignature ? (
        <Modal isOpen={true} centered size="md">
          <ModalHeader>Capture Signature</ModalHeader>
          <ModalBody>
            {capturingSignaturePad || capturingSignatureLinkRecipient || capturingContractCodeSignatureLinkRecipient ? null : (
              <Row>
                <Col xs="6">
                {/* <Button size="lg" color="primary" onClick={startSignaturePad}>
                    Click to Sign
                </Button> */}
                {/* <Button onClick={sendSignatureRequestWithCode}>
                    Send using Contract Code
                  </Button> */}
                <Button
                  onClick={() => onShowContractCodeMobileLink()}
                  color="warning"
                  style={{width: "100%", fontWeight: "bold", fontSize: "0.9rem", marginTop: "10px"}}
                  >
                  Send Mobile Link - New
                </Button>
                <Button
                  onClick={() => onShowMobileLink()}
                  color="dark"
                  style={{width: "100%", fontWeight: "bold", fontSize: "0.9rem", marginTop: "10px" }}
                >
                  Send Mobile Link - Old
                </Button>
                {contractPasscode == null || contractPasscode?.deactivatedAt != null || (date_helpers.dateTimeFormat(moment()) > date_helpers.dateTimeFormat(contractPasscode?.expiresAt)) ? (
                  <Button
                    size="lg"
                    color="primary"
                    onClick={generateContractCode}
                    style={{width: "100%", 
                      fontSize: "0.9rem", 
                      fontWeight: "bold", 
                      marginTop: "10px", 
                    }}
                  >
                    Generate Contract Code
                  </Button>
                  ) : (
                    <Button
                    onClick={generateContractCode}
                    style={{width: "100%", backgroundColor: "rgb(69, 137, 196)", fontSize: "0.9rem", fontWeight: "bold", marginTop: "10px", borderColor: "rgb(69, 137, 196)", }}
                    disabled
                  >
                    Generate Contract Code
                  </Button>
                )}
                </Col>
                <Col xs="6">
                {contractPasscode != null && contractPasscode?.deactivatedAt == null && (date_helpers.dateTimeFormat(moment()) < date_helpers.dateTimeFormat(contractPasscode?.expiresAt)) ? (
                  <div style={{height: "100%", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center"}}>
                  <div style={{textAlign: "center"}}>
                    Contract Code:
                  <span style={{fontWeight: "bold", fontSize: 23, letterSpacing: "0.2em", display: "block"}}>
                    {contractPasscode?.signatureAccessToken}
                  </span>
                  </div>
                  <Button
                    size="md"
                    color="primary"
                    style={{width: "100%", fontSize: "0.9rem", fontWeight: "bold"}}
                    onClick={signContract}
                  >
                    Click to View Contract
                  </Button>
                  </div>
                ) : (
                <div style={{alignItems: "center", display: "flex", flexDirection: "column", justifyContent: "center", height: "100%", color: "gray"}}>
                 No valid contract code set.
                 <span style={{display: "block", fontWeight: "bold"}}>Please generate a new one.</span>
                </div>)}
                </Col>
              </Row>
            )}

            {capturingSignatureLinkRecipient ? (
              <Col>
              <Row>
                <Col>
                  <Input
                    type="text"
                    className="mt-1"
                    value={paymentData.signatureLinkRecipient}
                    maxLength="150"
                    style={{ width: "200px" }}
                    placeholder="Recipient Mobile #"
                    onChange={(e) =>
                      onChange(e.target.value, "signatureLinkRecipient")
                    }
                  />
                </Col>
                <Col>
                  <ButtonGroup className="float-end">
                    <Button color="primary" onClick={sendSignatureRequest}>
                      Send Now
                    </Button>
                    {signatureRequestLinkSent ? (
                      <Button color="dark" onClick={refreshSignatureStatus}>
                        Refresh
                      </Button>
                    ) : null}
                  </ButtonGroup>
                </Col>
              </Row>
            </Col>
            ) : null}

            {capturingContractCodeSignatureLinkRecipient ? (
              <Col>
              <Row>
                <Col>
                  <Input
                    type="text"
                    className="mt-1"
                    value={paymentData.signatureLinkRecipient}
                    maxLength="150"
                    style={{ width: "200px" }}
                    placeholder="Recipient Mobile #"
                    onChange={(e) =>
                      onChange(e.target.value, "signatureLinkRecipient")
                    }
                  />
                </Col>
                <Col>
                  <ButtonGroup className="float-end">
                    <Button color="primary" onClick={sendSignatureRequestWithCode}>
                      Send Now
                    </Button>
                    {signatureRequestLinkSent ? (
                      <Button color="dark" onClick={refreshSignatureStatus}>
                        Refresh
                      </Button>
                    ) : null}
                  </ButtonGroup>
                </Col>
              </Row>
            {contractPasscode && contractPasscode?.deactivatedAt == null 
            && (date_helpers.dateTimeFormat(moment()) < date_helpers.dateTimeFormat(contractPasscode?.expiresAt)) && (
              <Row className="mt-3">
                <Col>
                  Contract Code:{" "}
                  <span style={{fontWeight: "bold", fontSize: 20, letterSpacing: "0.2em"}}>
                    {contractPasscode?.signatureAccessToken}
                  </span>
                </Col>
              </Row>
            )}
            </Col>
            ) : null}
            {capturingSignaturePad ? (
              <div
                id="tablet-container"
                style="width: 500px; height: 100px; display: none;"
              >
                <canvas
                  ref={canvasRef}
                  height="100"
                  width="500"
                  name="signatureCanvas"
                  id="signatureCanvas"
                ></canvas>
              </div>
            ) : null}
          </ModalBody>
          <ModalFooter>
            <Row>
              <Col className="d-flex flex-row justify-content-end">
{/*             <Col className="d-flex justify-content-end">
                  <Button color="secondary" onClick={closeSignatureCapture}>
                    Cancel
                  </Button>
                </Col>
                <Col className="ms-1 d-flex justify-content-end">
                  <Button
                    color="primary"
                    className="float-end"
                    disabled={true}
                    onClick={() => saveSignature()}
                  >
                    Save
                  </Button>
                </Col>
 */}
                <Col className="d-flex justify-content-end">
                  <Button
                    color="secondary"
                    className="float-end"
                    onClick={closeSignatureCapture}
                  >
                    Done
                  </Button>
                </Col>
              </Col>
            </Row>
          </ModalFooter>
        </Modal>
      ) : null}
      {capturingCard ? (
        <Modal isOpen={true} autoFocus={false} centered>
          <ModalHeader>Swipe Credit Card</ModalHeader>
          <ModalBody>
            <Row className="mt-3 mb-1">
              <Col xs="4">
                <div className="float-end">{ui_helpers.requiredStar()}</div>
                <Input
                  type="text"
                  value={paymentData.cardZip}
                  maxLength="12"
                  autoFocus={true}
                  style={{ width: "90%" }}
                  placeholder="Zip Code"
                  onChange={(e) => onChange(e.target.value, "cardZip")}
                />
              </Col>
              <Col xs="8">
                <Input
                  type="password"
                  innerRef={cardSwipeRef}
                  autoComplete="new-password"
                  value={paymentData.cardSwipe}
                  onChange={(e) => onChange(e.target.value, "cardSwipe")}
                  onKeyDown={swipeCreditCardKeydown}
                  readOnly={
                    !paymentData.cardZip || paymentData.cardZip.length < 5
                  }
                />
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button
              color="success"
              className="text-light"
              onClick={() => {
                // onChange(paymentData.cardSwipe, 'cardNo'); // default manual entry to anything typed in here
                // 17-Nov-22, ART, They complained about the above, now clearing it
                onChange("", "cardNo");
                setCapturingCard(false);
                setCapturingCardManually(true);
              }}
            >
              Enter card details manually
            </Button>
            <Button
              color="secondary"
              className="float-end"
              onClick={() => setCapturingCard(false)}
            >
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      ) : null}
      {capturingCardManually ? (
        <Modal isOpen={true} centered>
          <ModalHeader>Enter Card Information</ModalHeader>
          <ModalBody>
            <Card
              cvc={paymentData.cardCVC}
              number={paymentData.cardNo}
              expiration={paymentData.cardMMYY}
              // name={""}
              focused={"name"}
            />
            <Row className="mt-3 mb-1">
              <Col xs="5">
                <Input
                  type="text"
                  bsSize="sm"
                  value={paymentData.cardNo}
                  maxLength="20"
                  placeholder="Card number"
                  onChange={(e) => onChange(e.target.value, "cardNo")}
                />
              </Col>
              <Col xs="3">
                <Input
                  type="text"
                  bsSize="sm"
                  value={paymentData.cardZip}
                  maxLength="12"
                  placeholder="Zip Code"
                  onChange={(e) => onChange(e.target.value, "cardZip")}
                />
              </Col>
            </Row>
            <Row>
              <Col xs="5">
                <Input
                  type="text"
                  bsSize="sm"
                  value={paymentData.cardMMYY}
                  maxLength="4"
                  placeholder="MMYY"
                  onChange={(e) => onChange(e.target.value, "cardMMYY")}
                />
              </Col>
              <Col xs="3">
                <Input
                  type="text"
                  bsSize="sm"
                  value={paymentData.cardCVC}
                  maxLength="5"
                  placeholder="CVC"
                  onChange={(e) => onChange(e.target.value, "cardCVC")}
                />
              </Col>
            </Row>
            {paymentData.manualCard ? (
              <div className="mt-2">
                {paymentData.manualCard.ErrorMessage ? (
                  <RSAlert color="warning" className="mb-0">
                    {paymentData.manualCard.ErrorMessage}
                  </RSAlert>
                ) : (
                  <RSAlert color="success" className="mb-0">
                    The provided card information is valid.
                  </RSAlert>
                )}
              </div>
            ) : null}
          </ModalBody>
          <ModalFooter>
            <Button
              color="warning"
              className="text-light"
              onClick={() => {
                setPaymentData({
                  ...paymentData,
                  manualCard: ui_helpers.parseManualCardInformation(
                    paymentData.cardZip,
                    paymentData.cardNo,
                    paymentData.cardMMYY,
                    paymentData.cardCVC
                  ),
                });
              }}
            >
              Verify Card Information
            </Button>
            <ButtonGroup className="float-end">
              {paymentData.manualCard &&
              !paymentData.manualCard.ErrorMessage ? (
                <Button color="primary" onClick={saveManualCard}>
                  Save
                </Button>
              ) : null}
              <Button
                color="secondary"
                onClick={() => setCapturingCardManually(false)}
              >
                Cancel
              </Button>
            </ButtonGroup>
          </ModalFooter>
        </Modal>
      ) : null}
      {capturingACH ? (
        <CaptureACH
          achPayment={achPayment}
          bankInformation={bankInformation}
          achValidation={achValidation}
          onAchPaymentChange={onAchPaymentChange}
          showAchValidation={showAchValidation}
          cleanseAchModal={cleanseAchModal}
          saveACH={saveACH}
        />)
      : null
     }
  </Row>);
};