import React, { useEffect, useState } from "react";
import { Button, Modal } from "reactstrap";
import { Document, pdfjs } from "react-pdf";
import { useAppStore } from "../hooks";
import { Loading, Page } from ".";
import { TOP_LEVEL_VIEWS } from "../constants";
import useWindowSize from "../hooks/useWindowSize";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import LandscapeModeModal from "./LandscapeModeModal";
pdfjs.GlobalWorkerOptions.workerSrc = "pdf.worker.js";

export default function DocumentArea({handleToggle}) {
  const filePath = useAppStore((x) => x.filePath);
  const numPages = useAppStore((x) => x.numPages);
  const setNumPages = useAppStore((x) => x.setNumPages);
  const currentView = useAppStore((state) => state.currentView);
  const setCurrentView = useAppStore((x) => x.setCurrentView);
  const landscapeModeModalOpen = useAppStore((x) => x.landscapeModeModalOpen);
  const setLandscapeModeModalOpen = useAppStore((x) => x.setLandscapeModeModalOpen);

  const { width, height } = useWindowSize();
  const [loading, setLoading] = useState(true);
  const [numLoaded, setNumLoaded] = useState(0);
  const [isError, setIsError] = useState(false);
  const [pageScale, setPageScale] = useState(1.0);
  const MOBILE_SIZE = (width <= 768);
  const IS_PORTRAIT = window.matchMedia("(orientation: portrait)").matches

  useEffect(() => {
    if (currentView === TOP_LEVEL_VIEWS.HOME) {
      return <Home />
    }
  }, [])

  useEffect(() => {
    if (MOBILE_SIZE && IS_PORTRAIT) {
      setLandscapeModeModalOpen(true);
    } else {
      setLandscapeModeModalOpen(false);
    }
  }, [MOBILE_SIZE, IS_PORTRAIT, landscapeModeModalOpen])

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setLoading(false);
  }

  function onPageLoadSuccess() {
    setNumLoaded((prev) => prev + 1);
  }
  
  function handleError() {
    console.error("Document failed to load");
    setLoading(false);
    setIsError(true);
  }

  const zoomIn = () => {
    if (pageScale < 3.0) {
      setPageScale(pageScale + 0.25)
    }
  }

  const zoomOut = () => {
    if (pageScale > 1.0) {
      setPageScale(pageScale - 0.25)
    }
  }

  return (
    <>
      {loading && <Loading />}
      {isError ? (
        <div className="vw-100 vh-100 bg-body position-fixed d-flex flex-column align-items-center justify-content-center text-center"
        style={{zIndex: 999, fontSize: 30}}>
          <p>There was an issue loading the document.</p>
          <p>Please try again.</p>
          <Button
            color="primary"
            size="lg"
            onClick={() => setCurrentView(TOP_LEVEL_VIEWS.HOME)}
            style={{marginTop: 20, fontSize: 25, paddingInline: 10}}
          >
            Return to Home 
          </Button>
        </div>
        ) : (
        <>
        <LandscapeModeModal />
        <div
          className="bg-secondary d-flex justify-content-center align-items-center overflow-auto"
          style={{width: width , paddingTop: "6rem", paddingBottom: "6rem"}} //minHeight: "100vh", padding: "6rem 3rem 6rem 3rem", 
        >
          <Document 
            file={filePath} 
            onLoadSuccess={onDocumentLoadSuccess} 
            onLoadError={handleError}
            className="overflow-auto"
          >
          {[...Array(numPages)].map((_, i) => (
            <Page
              key={`page_${i + 1}`}
              pageIndex={i}
              renderAnnotationLayer={false}
              className="mb-2 shadow"
              allPagesLoaded={numLoaded === numPages}
              onLoadSuccess={onPageLoadSuccess}
              scale={pageScale}
            />
          ))}
          <div 
            className="d-flex flex-column position-fixed align-items-center"
            style={{zIndex: 998, bottom: 110, right: 20}}
          >
            <button id="toggleBarVisibility" style={{marginBottom: 10, alignItems: "center"}} onClick={handleToggle}><FontAwesomeIcon icon={'eye'} /></button>
            <button id="zoomInButton" onClick={zoomIn}><FontAwesomeIcon icon={"plus"} /></button>
            <button id="zoomOutButton" onClick={zoomOut}><FontAwesomeIcon icon={'minus'} /></button>
          </div>
        </Document>
      </div>
      </>
      )}
    </>
  );
}
