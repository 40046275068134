import { create } from "zustand";

const useContractStore = create((set, get) => ({
  companyId: null,
  setCompanyId: (n) => set((state) => ({ ...state, companyId: n })),
  regionId: null,
  setRegionId: (n) => set((state) => ({ ...state, regionId: n })),
  dealerId: null,
  setDealerId: (n) => set((state) => ({ ...state, dealerId: n })),
  // showOverlays: true,
  // toggleShowOverlays: () =>
  //   set((state) => ({ ...state, showOverlays: !state.showOverlays })),
  // signatureLocations: [],
  // setSignatureLocations: (newSls) =>
  //   set((state) => ({
  //     ...state,
  //     signatureLocations: sortByProperties(newSls, ["pageIndex", "yPosition"]),
  //   })),
}));

export default useContractStore;
