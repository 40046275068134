import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, Fragment } from "react";
import { Button, FormGroup, Input, Label, InputGroup, InputGroupText } from "reactstrap";
import _ from "lodash";
import { Switch } from ".";
import {
  addOnTypes,
  unitsOfMeasure,
  doubleDoorSwingTypeOptions,
  singleDoorSwingTypeOptions,
  windowAdditionalOptionTypes,
  doorAdditionalOptionTypes
} from "../floorplanConstants";
import { constants } from "../../utils";
import {
  useActions,
  useAddOns,
  useControlTarget,
  useEditingContext,
  useExterior,
  useInfo,
  useAppState
} from "../hooks";
import Select from 'react-select';
import { infoMessages } from "../infoMessages";
import { toFeetInchesString } from "../utils";

function AddOnSettings() {
  const [appState] = useAppState();
  const editingContext = useEditingContext();
  const [controlTarget] = useControlTarget();
  const [exterior] = useExterior();
  const [addOns, addOnsDispatch] = useAddOns();
  const [, setInfo] = useInfo();
  const actions = useActions({ addOnsDispatch });
  const selectedAddOn = addOns.selectedAddOns.find((a) =>
    controlTarget.includes(a.domId)
  );
  const isTextNote = selectedAddOn?.type === addOnTypes.textNote;
  const addOnLabel = isTextNote ? "Text Note" : "Add-On";

  useEffect(() => {
    if (
      selectedAddOn?.type === addOnTypes.singleDoor ||
      selectedAddOn?.type === addOnTypes.doubleDoor
    ) {
      setInfo(infoMessages.selectedDoor);
    } else if (selectedAddOn?.type === addOnTypes.window) {
      setInfo(infoMessages.selectedWindow);
    } else if (
      selectedAddOn?.type === addOnTypes.singleWorkbench ||
      selectedAddOn?.type === addOnTypes.doubleWorkbench
    ) {
      setInfo(infoMessages.selectedWorkbench);
    } else if (
      selectedAddOn?.type === addOnTypes.outlet ||
      selectedAddOn?.type === addOnTypes.doubleOutlet
    ) {
      setInfo(infoMessages.selectedOutlet);
    } else if (selectedAddOn?.type === addOnTypes.loft) {
      setInfo(infoMessages.selectedLoft);
    } else {
      setInfo(infoMessages.default);
    }
  }, [controlTarget]);

  if (!selectedAddOn) return "Select an Add On to view settings.";

  function centerHorizontally() {
    actions.updateAddOn({ ...selectedAddOn, x: exterior.width / 2 });
  }

  function centerVertically() {
    actions.updateAddOn({ ...selectedAddOn, y: exterior.height / 2 });
  }

  function handleInputX(e) {
    actions.updateAddOn({ ...selectedAddOn, x: Number(e.target.value) });
  }

  function handleInputY(e) {
    actions.updateAddOn({ ...selectedAddOn, y: Number(e.target.value) });
  }

  function handleInputRotation(e) {
    actions.updateAddOn({ ...selectedAddOn, rotation: Number(e.target.value) });
  }

  function handleRotationClick() {
    actions.updateAddOn({
      ...selectedAddOn,
      rotation:
        selectedAddOn.rotation + 90 >= 360
          ? selectedAddOn.rotation + 90 - 360
          : selectedAddOn.rotation + 90,
    });
  }

  function onColorChange(e) {
    const newColorId = Number(e.target.value);    
    actions.updateAddOn({
      ...selectedAddOn,
      colorId: newColorId > 0
        ? newColorId
        : null
    });
  }

  function handleChangeSwingType(e) {
    actions.updateAddOn({
      ...selectedAddOn,
      swingType: Number(e.target.value),
    });
  }

  function handleChangeAdditionalOption(value) {
    return (e) =>
      actions.updateAddOn({
        ...selectedAddOn,
        ["additionalOption" + value + "On"]: e.target.checked,
      });
  }

  function resolveWindowAdditionalOptionTypeName(optionType){
    let name ='';
    switch(optionType){
      case windowAdditionalOptionTypes.flowerbox: 
        name = "Flowerbox";
        break;
      default: 
        name = "Shutters"
        break;
    }
    return name;
  }

  function resolveDoorAdditionalOptionTypeName(optionType){
    let name ='';
    switch(optionType){
      case doorAdditionalOptionTypes.customColor: 
      default: 
        name = "Custom Color";
        break;
    }
    return name;
  }

  function onSelectOptionColor(colorId, seq) {
    actions.updateAddOn({
      ...selectedAddOn,
      ["additionalOption" + seq + "ColorId"]: colorId
    });
  }

  function renderWindowOptionColors(optionType, optionColorId, seq) {
    const options = optionType === windowAdditionalOptionTypes.flowerbox
      ? appState.flowerboxColors
      : appState.shutterColors;
    return (<div className="pb-2">
      <Label>Color:</Label>
      <Select
        options={options}
        value={_.find(options, pc => pc.value === optionColorId)}
        onChange={(option) => onSelectOptionColor(option.value, seq)}
      />
    </div>);
  }

  function renderDoorOptionColors(_optionType, optionColorId, seq) {
    // only 1 option type, custom color, right now
    const doorColorOptions = [{value: 0, label: 'N/A'}, ...appState.doorColors];
    return (<FormGroup className="mt-3">
            <Label className="m-0 small">Custom Color:</Label>
            <Select
              options={doorColorOptions}
              value={_.find(doorColorOptions, pc => pc.value === optionColorId)}
              onChange={(option) => onSelectOptionColor(option.value, seq)}
            />
          </FormGroup>);
  }

  const isDoor = [addOnTypes.singleDoor, addOnTypes.doubleDoor].includes(selectedAddOn.type);
  const opt1 = selectedAddOn.additionalOption1Type;
  const opt2 = selectedAddOn.additionalOption2Type;
  const isPctOfBase = selectedAddOn.uom === unitsOfMeasure.percentOfBase;
  const isLoft = selectedAddOn.type === addOnTypes.loft;
  return (
    <div className="mt-3">
      <h6>
        {isTextNote ? "Text Note: " : null}
        {selectedAddOn.name}
      </h6>
      {!isLoft && !isPctOfBase ? (
        <>
          <FormGroup style={{ marginBottom: 0 }}>
            <Label className="m-0 small">
              X-Pos:{" "}
              <span className="font-monospace fw-bold">
                {toFeetInchesString(selectedAddOn.x)}
              </span>
            </Label>
            <Input
              type="range"
              bsSize="sm"
              min={1}
              max={exterior.width}
              value={selectedAddOn.x}
              onInput={handleInputX}
            />
          </FormGroup>
          <FormGroup>
            <Label className="m-0 small">
              Y-Pos:{" "}
              <span className="font-monospace fw-bold">
                {toFeetInchesString(selectedAddOn.y)}
              </span>
            </Label>
            <Input
              type="range"
              bsSize="sm"
              min={1}
              max={exterior.height}
              value={selectedAddOn.y}
              onInput={handleInputY}
            />
          </FormGroup>
        </>
      ) : null}
      {![addOnTypes.loft, addOnTypes.breakerBox, addOnTypes.light].includes(selectedAddOn.type) && !isPctOfBase ? (
        <>
          <FormGroup>
            <Label className="m-0 small">
              Rotation:{" "}
              <span className="font-monospace fw-bold">
                {selectedAddOn.rotation}°
              </span>
            </Label>
            <Input
              type="range"
              bsSize="sm"
              min={0}
              max={360}
              value={selectedAddOn.rotation}
              onInput={handleInputRotation}
            />
          </FormGroup>
          <div className="d-grid mt-3">
            <Button
              size="sm"
              color="light"
              className="shadow"
              onClick={handleRotationClick}
            >
              <FontAwesomeIcon icon="redo-alt" className="me-2" />
              Rotate 90°
            </Button>
          </div>
        </>
      ) : null}
      <div className="d-grid mt-3">
        {!isLoft && !isPctOfBase ? (
          <>
            <Button
              size="sm"
              color="dark"
              className="mb-1"
              onClick={centerHorizontally}
            >
              Center Horizontally
            </Button>
            <Button size="sm" color="dark" onClick={centerVertically}>
              Center Vertically
            </Button>
          </>
        ) : null}
        {selectedAddOn.type === addOnTypes.singleDoor ? (
          <FormGroup className="mt-3">
            <Label className="m-0 small">Swing Type:</Label>
            <Input
              size="sm"
              type="select"
              value={selectedAddOn.swingType}
              onChange={handleChangeSwingType}
            >
              {singleDoorSwingTypeOptions.map((o) => (
                <option key={o.value} value={o.value}>
                  {o.label}
                </option>
              ))}
            </Input>
          </FormGroup>
        ) : null}
        {selectedAddOn.type === addOnTypes.doubleDoor ? (
          <FormGroup className="mt-3">
            <Label className="m-0 small">Swing Type:</Label>
            <Input
              size="sm"
              type="select"
              value={selectedAddOn.swingType}
              onChange={handleChangeSwingType}
            >
              {doubleDoorSwingTypeOptions.map((o) => (
                <option key={o.value} value={o.value}>
                  {o.label}
                </option>
              ))}
            </Input>
          </FormGroup>
        ) : null}
        {selectedAddOn.type === addOnTypes.window && (opt1 || opt2)
          ? (<div className="my-3">
              <div>Window Options:</div>
                {opt1
                  ? (<Fragment>
                    <FormGroup check>
                      <Label check>{resolveWindowAdditionalOptionTypeName(selectedAddOn.additionalOption1Type)}</Label>
                      <Input
                        type="checkbox"
                        checked={selectedAddOn.additionalOption1On}
                        onChange={handleChangeAdditionalOption(1)}
                      />
                    </FormGroup>
                    {selectedAddOn.additionalOption1On && editingContext !== constants.FPContext.skuTemplate
                      ? renderWindowOptionColors(opt1, selectedAddOn.additionalOption1ColorId, 1)
                      : null
                    }
                    </Fragment>)
                  : null}
                {opt2
                  ? (<Fragment>
                    <FormGroup check>
                      <Label check>{resolveWindowAdditionalOptionTypeName(selectedAddOn.additionalOption2Type)}</Label>
                      <Input
                        type="checkbox"
                        checked={selectedAddOn.additionalOption2On}
                        onChange={handleChangeAdditionalOption(2)}
                      />
                    </FormGroup>
                    {selectedAddOn.additionalOption2On && editingContext !== constants.FPContext.skuTemplate
                      ? renderWindowOptionColors(opt2, selectedAddOn.additionalOption2ColorId, 2)
                      : null
                    }
                    </Fragment>) 
                  : null}
              </div>) 
          : null
        }
        {isDoor && opt1
          ? (<div className="my-3">
              <div>Door Options:</div>
                {opt1
                  ? (<Fragment>
                    <FormGroup check>
                      <Label check>{resolveDoorAdditionalOptionTypeName(selectedAddOn.additionalOption1Type)}</Label>
                      <Input
                        type="checkbox"
                        checked={selectedAddOn.additionalOption1On}
                        onChange={handleChangeAdditionalOption(1)}
                      />
                    </FormGroup>
                    {selectedAddOn.additionalOption1On && editingContext !== constants.FPContext.skuTemplate
                      ? renderDoorOptionColors(opt1, selectedAddOn.additionalOption1ColorId, 1)
                      : null
                    }
                    </Fragment>)
                  : null}
              </div>)
          : null
        }
        {isTextNote
          ? (<InputGroup className="my-3">
              <InputGroupText>$</InputGroupText>
              <Input
                type="text"
                value={selectedAddOn.price}
                onChange={(e) => 
                  actions.updateAddOn({
                    ...selectedAddOn,
                    price: e.target.value
                  })
                }
                onBlur={() => {
                  actions.updateAddOn({
                    ...selectedAddOn,
                    price: selectedAddOn.price ? parseFloat(selectedAddOn.price) : 0.0
                  })                  
                }}
                placeholder="Text Note Optional Price..."
              />
            </InputGroup>) 
          : null
        }
        <Button
          size="sm"
          color="dark"
          className="mt-1"
          onClick={() =>
            actions.updateAddOn({
              ...selectedAddOn,
              hide: selectedAddOn.hide ? false : true,
            })
          }
        >
          <FontAwesomeIcon
            icon={selectedAddOn.hide ? "eye" : "eye-slash"}
            className="me-2"
          />
          {selectedAddOn.hide ? "Show " + addOnLabel : "Hide " + addOnLabel}
        </Button>
        <Button
          size="sm"
          color="danger"
          className="mt-1 text-light"
          onClick={() => actions.removeAddOn(selectedAddOn)}
        >
          <FontAwesomeIcon icon="trash" className="me-2" />
          Delete {addOnLabel}
        </Button>
      </div>
    </div>
  );
}

export default AddOnSettings;
