import { porchTypes } from "../../floorplanConstants";
import {
  calculateHypotenuse,
  getAngledWallLength,
  limitNumberWithinRange,
} from "../../utils";

function exteriorReducer(state, { type, payload }) {
  const isInset = state.porchType === porchTypes.insetSide;
  switch (type) {
    case "SET_EXT_WIDTH":
      return setAngledWall({ ...state, width: payload });
    case "SET_EXT_HEIGHT":
      return setAngledWall({ ...state, height: payload });
    case "SET_PORCH_TYPE":
      return setAngledWall({ ...state, porchType: payload });
    case "SET_PORCH_MIN":
      return setAngledWall({
        ...state,
        porchMin:
          state.porchType === porchTypes.side ||
          state.porchType === porchTypes.end
            ? limitNumberWithinRange(
                payload,
                12,
                state.porchType === porchTypes.side
                  ? state.height - 12
                  : state.width - 12
              )
            : limitNumberWithinRange(
                payload,
                12,
                state.porchMax - state.porchWall2Length - 12
              ),
      });
    case "SET_PORCH_MAX":
      return setAngledWall({
        ...state,
        porchMax: limitNumberWithinRange(payload, 12, state.width),
      });
    case "SET_PORCH_WALL1_LENGTH":
      const minVal1 = isInset
        ? state.porchMin
        : 12;
      const maxVal1 = isInset
        ? state.width - state.porchMin - state.porchWall4Length
        : state.height - state.porchWall4Length - 12;
      return setAngledWall({
        ...state,
        porchWall1Length: limitNumberWithinRange(payload, minVal1, maxVal1),
      });
    case "SET_PORCH_WALL2_LENGTH":
      return setAngledWall({
        ...state,
        porchWall2Length: limitNumberWithinRange(
          payload,
          12,
          (isInset
            ? state.height - 12
            : state.porchMax - state.porchMin - 12)
        ),
      });
    case "SET_PORCH_WALL3_LENGTH":
      return setAngledWall({
        ...state,
        porchWall2Length: limitNumberWithinRange(
          state.porchMax -
            state.porchMin -
            Math.sqrt(
              payload ** 2 -
                (state.height -
                  state.porchWall1Length -
                  state.porchWall4Length) **
                  2
            ),
          12,
          state.width
        ),
      });
    case "SET_PORCH_WALL4_LENGTH":
      const minVal4 = isInset
        ? state.porchMin
        : 12;
      const maxVal4 = isInset
        ? state.width - state.porchMin - state.porchWall4Length
        : state.height - state.porchWall1Length - 12;
      return setAngledWall({
        ...state,
        porchWall4Length: limitNumberWithinRange(payload, minVal4, maxVal4),
      });
    case "SET_EXT_PRICE":
      return setAngledWall({
        ...state,
        price: payload,
      });

    default:
      return state;
  }
}

function setAngledWall(state) {
  if (
    [porchTypes.fourWallDeluxe1, porchTypes.fourWallDeluxe2].includes(
      state.porchType
    )
  ) {
    let porchWall3Length = limitNumberWithinRange(
        getAngledWallLength(state),
        calculateHypotenuse(
          state.height - state.porchWall4Length - state.porchWall1Length,
          12
        ),
        calculateHypotenuse(state.porchMax - state.porchMin - 12, 12)
      );
    porchWall3Length = parseInt(Math.round(porchWall3Length, 0), 10);
    return {
      ...state,
      porchWall3Length: porchWall3Length
    };
  }
  return state;
}

export default exteriorReducer;
