import _ from "lodash";

function deepCopyObject(obj) {
  return JSON.parse(JSON.stringify(obj));
}

function objectIsNotNull(obj) {
    if (obj === null) return true;
  return Object.values(obj).every(value => {
      if (value === null) {
        return true;
      }

    return false;
  });
}

const object_helpers = {
  deepCopyObject,
    objectIsNotNull,
};

export default object_helpers;