import React, {useEffect, useState, useContext} from "react";
import { Button, Form, FormGroup, Row, Label, Col, Input, Alert } from "reactstrap";
import { Header, Footer } from "./";
import {api, UserContext, constants} from "../utils";
import Select from 'react-select';
import _ from 'lodash';
import {ui_helpers} from "../helpers";
import {DebounceInput} from "react-debounce-input";
import {useHistory} from "react-router-dom";

const UserProfile = () => {
  const history = useHistory();
  const { currentUser, logIn, impersonate } = useContext(UserContext);
  const [users, setUsers] = useState([]);
  const initialUser = {
    ...currentUser,
    timezone: currentUser.timezone ? currentUser.timezone : constants.DEFAULT_TIMEZONE.value
  };
  const [user, setUser] = useState(initialUser);
  const [selectedUser, setSelectedUser] = useState();
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [verifyPassword, setVerifyPassword] = useState('');
  const [passwordsMatch, setPasswordsMatch] = useState(false);
  const [lastSaved, setLastSaved] = useState(new Date().getTime());
  const [tainted, setTainted] = useState(false);  
  const [message, setMessage] = useState({text: '', flavor: 'success'});
  const [timezoneList, setTimezoneList] = useState([]);
  const canImpersonate = ui_helpers.isSystemAdministrator(currentUser) 
    || ui_helpers.isAnyCompanyAdministrator(currentUser);

  useEffect(() => {
    if (!canImpersonate) return;
    api.fetch('User/GetUsersToImpersonate').then(r => {
      if (r.data) {
       setUsers(r.data);
      }
    }).catch((error) => console.error(error));
    api.fetch('Reference/Timezones').then(r => {
      if (r.data) {
        setTimezoneList(r.data);
      }
    }).catch((error) => console.error(error));
  }, []);

  const handleVerifyPassword = () => {
    if(verifyPassword.length > 4)
    {
      if (verifyPassword === newPassword) {
        setPasswordsMatch(true);
      }
      else {
        setPasswordsMatch(false);
      }
    }
  };

  useEffect(handleVerifyPassword, [verifyPassword]);

  const closeAlert = () => {
    setMessage({text: '', flavor: 'success'});
  };

  const handleChangePassword = () => {
    if(currentPassword && passwordsMatch)
    {
      const payload = {
        oldPassword: currentPassword,
        newPassword: newPassword,
      };
      api.post(`home/SetPassword`, payload).then(r => {
        if(r.data.success)
        {
          setMessage({text: 'You have successfully changed your password.', flavor: 'success'});
          cleanseFields();
        }
        else {
          setMessage({text: r.data.message, flavor: 'danger'});
        }
      });
    }
  };

  const cleanseFields = () => {
    setCurrentPassword('');
    setVerifyPassword('');
    setNewPassword('');
  };

  function onUserChange(fieldName, fieldValue, saveImmediately = false) {
    setTainted(true);
    let newUser = Object.assign({}, user);
    const oldUser = Object.assign({}, newUser);
    newUser[fieldName] = fieldValue;
    setUser(newUser);
    if (!saveImmediately) return;
    saveUserChanges(newUser);
  }

  function saveUserChanges(saveThis = null) {
    // do not save unless something has changed
    if (!tainted && !saveThis) return;
    // prevent saves more frequently than once every 1.5s
    if ((new Date().getTime() - lastSaved) < 1500) return;
    const payload = saveThis
      ? saveThis
      : user;
    api.post('Auth/SaveProfile', payload).then(r => {
      setLastSaved(new Date().getTime());
      setTainted(false);
      logIn(payload);
      if (!r.data.success) {
        setMessage({text: r.data.message, flavor: 'danger'});
      }
    }).catch(e => {
      console.error('Error saving user profile', e);
      setMessage({text: 'Unknown error attempting to save', flavor: 'danger'});
    });
  }

  function handleUserChange(e) {
    onUserChange(e.target.name, e.target.value);
  }

  function onImpersonate() {
    if (!selectedUser) return;
    api.post('Auth/ImpersonateUser', {username: selectedUser}).then((response) => {
      if (response.data.success){
        impersonate(response.data.user, response.data.user.token);
      } else {
        setMessage({text: response.data.message, flavor: 'danger'});
      }
    }).catch((e) => {
      console.error('error trying to impersonate', e);
      setMessage({text: 'Unknown error attempting to impersonate', flavor: 'danger'});
    });
  }

  return (
    <div>
      <Header />
      {message && message.text
        ? <Alert toggle={closeAlert} color={message.flavor}>{message.text}</Alert>
        : null
      }
      <div className="p-5">
        <h2>Update Profile</h2>
        <Row>
          <Col xs={5}>
            <Form className="mt-4">
              <FormGroup row>
                <Label xs={4} className="text-end">
                  First Name
                </Label>
                <Col xs={8}>
                  <Input type="text" name='firstName' bsSize="sm" value={user.firstName} onChange={handleUserChange} onBlur={saveUserChanges} />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label xs={4} className="text-end">
                  Last Name
                </Label>
                <Col xs={8}>
                  <Input type="text" name='lastName' bsSize="sm" value={user.lastName} onChange={handleUserChange} onBlur={saveUserChanges}/>
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label xs={4} className="text-end">
                  Username
                </Label>
                <Col xs={8}>
                  <Input type="text" bsSize="sm" name='username' value={user.username} onChange={handleUserChange} onBlur={saveUserChanges}/>
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label xs={4} className="text-end">
                  Phone
                </Label>
                <Col xs={8}>
                  <Input type="tel" bsSize="sm" name='phone' value={user.phone && ui_helpers.formatPhoneField(user.phone)} onChange={handleUserChange} onBlur={saveUserChanges}/>
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label xs={4} className="text-end">
                  Email
                </Label>
                <Col xs={8}>
                  <Input type="email" bsSize="sm" name='email' value={user.email} onChange={handleUserChange} onBlur={saveUserChanges}/>
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label xs={4} className="text-end">
                  Timezone
                </Label>
                <Col xs={8}>
                  <Select 
                    id="timezone"
                    value={_.find(timezoneList, tz => tz.value === user.timezone)}
                    options={timezoneList}
                    onChange={(tz) => onUserChange('timezone', tz.value, true)}
                  />
                </Col>
              </FormGroup>
              <FormGroup row className="mt-2">
                <Label xs={4} className="text-end">
                  Current Password
                </Label>
                <Col xs={8}>
                  <Input type="password" bsSize="sm" value={currentPassword} autoComplete="new-password" onChange={(e) => setCurrentPassword(e.target.value)} />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label xs={4} className="text-end">
                  New Password
                </Label>
                <Col xs={8}>
                  <Input type="password" bsSize="sm" value={newPassword} valid={passwordsMatch && verifyPassword.length > 4}
                     invalid={!passwordsMatch && verifyPassword.length > 4}
                     onChange={(e) => setNewPassword(e.target.value)}
                  />
                  {!passwordsMatch && verifyPassword.length > 4 ? <small>Passwords do not match</small> : null}
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label xs={4} className="text-end">
                  Verify Password
                </Label>
                <Col xs={8}>
                  <Input type="password" bsSize="sm" value={verifyPassword} valid={passwordsMatch && verifyPassword.length > 4}
                         invalid={!passwordsMatch  && verifyPassword.length > 4}
                                 onChange={(e) => setVerifyPassword(e.target.value)}/>
                  {!passwordsMatch && verifyPassword.length > 4 ? <small>Passwords do not match</small> : null}
                </Col>
              </FormGroup>
              <FormGroup>
                <Row>
                  <Col xs={4}></Col>
                  <Col xs={8} className='d-flex justify-content-end'>
                    <Button color="warning" className="text-light mb-4" onClick={handleChangePassword}>
                      Change Password
                    </Button>
                  </Col>
                </Row>
              </FormGroup>
              <FormGroup row>
                <Col className="d-flex">
                  <Col xs={3} className='d-flex justify-content-end me-2'>
                    <Input type="checkbox" checked={user.autoSendReports} 
                      onChange={e => onUserChange('autoSendReports', e.target.checked, true)}/>
                  </Col>
                  <Col>
                    <Label check className="text-end">
                      Send reports
                    </Label>
                  </Col>
                </Col>
              </FormGroup>
              <FormGroup row>
                <Col className="d-flex">
                  <Col xs={3} className='d-flex justify-content-end me-2'>
                    <Input type="checkbox" checked={user.sendSMSUponContractApproval}
                      onChange={e => onUserChange('sendSMSUponContractApproval', e.target.checked, true)} />
                  </Col>
                  <Col>
                    <Label check className="text-nowrap">
                      Send text upon contract approval
                    </Label>
                  </Col>
                </Col>
              </FormGroup>
              <FormGroup row>
                <Col className="d-flex">
                  <Col xs={3} className='d-flex justify-content-end me-2'>
                    <Input type="checkbox" checked={user.notifyOnNewContract}
                      onChange={e => onUserChange('notifyOnNewContract', e.target.checked, true)} />
                  </Col>
                  <Col>
                    <Label check className="text-nowrap">
                      Notify me whenever a new contract or cash sale is created at any of my directly related stores
                    </Label>
                  </Col>
                </Col>
              </FormGroup>
            </Form>
          </Col>
        </Row>
        {canImpersonate
            ?  <Row className="p-3 bg-light border border-danger d-flex">
              <Col xs={5}>
                <FormGroup row className='align-items-center'>
                  <Label xs={4}>Become Username</Label>
                  <Col xs={8}>
                    <Select options={users} isDisabled={(users) ? false : true} onChange={(u) => setSelectedUser(u.value)} />
                  </Col>
                </FormGroup>
              </Col>
              <Col xs={7}>
                <Button color="primary" onClick={onImpersonate}>Impersonate</Button>
              </Col>
            </Row>
            : null
        }
      </div>
      <Footer />
    </div>
  );
};

export default UserProfile;
