import React from "react";
import { swingTypes } from "../floorplanConstants";

function DoorSwing(props) {
  if (props.swingType === swingTypes.leftHand)
    return (
      <path
        d={`M ${props.width / 2}, 0 
        a ${-props.width} ${-props.width} 0 0 0 ${-props.width} ${-props.width} 
        L ${-(props.width / 2)} 0 
        Z
        `}
        fill="none"
        stroke="black"
        strokeWidth={0.5}
      />
    );

  if (props.swingType === swingTypes.rightHand)
    return (
      <path
        d={`M ${-props.width / 2}, 0 
      a ${-props.width} ${-props.width} 0 0 1 ${props.width} ${-props.width} 
      L ${props.width / 2} 0 
      Z
      `}
        fill="none"
        stroke="black"
        strokeWidth={0.5}
      />
    );

  if (
    props.swingType === swingTypes.active ||
    props.swingType === swingTypes.leftHandActive ||
    props.swingType === swingTypes.rightHandActive
  )
    return (
      <>
        {props.swingType === swingTypes.active ||
        props.swingType === swingTypes.leftHandActive ? (
          <path
            d={`
            M 0, 0 
            a ${-props.width / 2} ${-props.width / 2} 0 0 0 ${
              -props.width / 2
            } ${-props.width / 2} 
            L ${-(props.width / 2)} 0 
            Z
        `}
            fill="none"
            stroke="black"
            strokeWidth={0.5}
          />
        ) : null}
        {props.swingType === swingTypes.active ||
        props.swingType === swingTypes.rightHandActive ? (
          <path
            d={`
            M 0, 0
            a ${-props.width / 2} ${-props.width / 2} 0 0 1 ${
              props.width / 2
            } ${-props.width / 2} 
            L ${props.width / 2} 0 
            Z
        `}
            fill="none"
            stroke="black"
            strokeWidth={0.5}
          />
        ) : null}
      </>
    );

  return null;
}

export default DoorSwing;
