import React, { useState } from "react";
import { PointerCoordinatesContext } from "../contexts";
import { initialPointerCoordinates } from "../init";

function PointerCoordinatesProvider({ children }) {
  const [coordinates, setCoordinates] = useState(initialPointerCoordinates);

  return (
    <PointerCoordinatesContext.Provider value={[coordinates, setCoordinates]}>
      {children}
    </PointerCoordinatesContext.Provider>
  );
}

export default PointerCoordinatesProvider;
