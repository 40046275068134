import React, {useContext, useEffect, useState} from 'react';
import {AdminHeader} from "./";
import {Users} from '../pages';
import {Container, Row, Col, Card, CardBody, CardHeader} from "reactstrap";
import {UserContext} from "../utils";
import {ui_helpers} from "../helpers";
import {Redirect} from "react-router-dom";


export default function UserAdmin(props) {
  const [redirect, setRedirect] = useState('');
  const {currentUser} = useContext(UserContext);

  useEffect(() => {
    if(ui_helpers.isSystemAdministrator(currentUser) || ui_helpers.isAnyCompanyAdministrator(currentUser)) return;
    setRedirect('/');
  }, [currentUser]);

   if (redirect) {
     return (<Redirect to={redirect}/>);
   }
  return (<>
      <AdminHeader users/>
      <Container fluid>
          <Users/>
      </Container>
  </>)

}