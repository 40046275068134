import React from "react";

const Footer = () => {
  return (
    <div className="p-2">
      <hr />
      <footer className='d-flex justify-content-center'>
        <p>
          &copy; {new Date().getFullYear()} - Evergreen RTO Management LLC All rights reserved.
        </p>
      </footer>
    </div>
  );
};

export default Footer;
