import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { Button } from "reactstrap";

function SidebarCollapse({ title, defaultOpen, children }) {
  const [open, setOpen] = useState(defaultOpen);

  return (
    <div>
      <div className="d-flex align-items-center justify-content-between">
        <h5>{title} </h5>
        <Button color="light" size="sm" onClick={() => setOpen(!open)}>
          <FontAwesomeIcon icon={open ? "angle-up" : "angle-down"} />
        </Button>
      </div>
      {open ? <div className="p-1 m-0">{children}</div> : null}
      <hr />
    </div>
  );
}

export default SidebarCollapse;
