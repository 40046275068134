import React, {Fragment} from 'react'
import {Link} from 'react-router-dom';
import {Button, Row, Col} from 'reactstrap';
import {constants} from "../utils";
import _ from "lodash";
const DetailsRow = ({ heading, data, url, numeric }) => {
    return (
      <tr>
        <th>{heading}</th>
        <td className={numeric ? "num-value" : null}>
        {url 
          ? (<Row className='align-items-center'><Col><Link to={url}>{data}</Link></Col></Row>)
          : data
        }
        </td>
      </tr>
    );
}

export default DetailsRow
