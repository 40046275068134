import React, { useEffect, useState, useContext } from "react";
import {Link, useParams} from "react-router-dom";
import { Row, Col, Button, Table, Badge, ButtonGroup, Input, ModalHeader, ModalBody, ModalFooter, Modal } from "reactstrap";
import { api, constants, UserContext } from "../utils";
import { date_helpers, ui_helpers } from "../helpers";
import {DebounceInput} from "react-debounce-input";
import { Footer, Header, Loading } from "./";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import _ from 'lodash';
import moment from "moment";
import Select from "react-select";
import DatePicker from 'react-datepicker';
import XLS from "../assets/img/excel.png";
import CSV from "../assets/img/csv.jpg";
import PDF from "../assets/img/pdf.png";
import Alert from "react-s-alert-v3";

const CONTROL_TYPES = {
  SELECT: "select",
  CHECKBOX: "checkbox",
  CHECKBOXLIST: "checkbox-list",
  TEXTBOX: "textbox",
  DATE_RANGE: "date-range",
  DATE: "date"
};

const cannedDateRangeOptions = [
  {value: "", label: "(Choose Date Range Type)"},
  {value: "choose-my-own", label: "Choose My Own Dates"},
  {value: "month-to-date", label: "This Month-to-Date"},
  {value: "qtd", label: "This Quarter-to-Date"},
  {value: "qtd-last-year", label: "This Quarter-to-Date Last Year"},
  {value: "ytd", label: "This Year-to-Date"},
  {value: "last-month", label: "Last Month"},
  {value: "last-quarter", label: "Last Quarter"},
  {value: "last-year", label: "Last Year"},
  {value: "ytd-last-year", label: "Last Year-to-Date"},
  {value: "seven-days-ago", label: "Since 7 Days Ago"},
  {value: "since-12-months-ago", label: "Since 12 Months Ago"}
];

const ReportParameters = () => {
  const {reportId} = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [report, setReport] = useState(null);
  const [parameters, setParameters] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [filteredItemIds, setFilteredItemIds] = useState([]);
  const [editingParameter, setEditingParameter] = useState(null);
  const [selectedCannedDateRange, setSelectedCannedDateRange] = useState(cannedDateRangeOptions[0]);
  const {currentUser} = useContext(UserContext);

  function loadParameters() {
    setIsLoading(true);
    api.fetch('Resources/Parameters/' + reportId).then(res => {
      setParameters(res.data.parameters);
      setReport({
        id: res.data.reportId,
        name: res.data.reportName,
        exportableToExcel: res.data.exportableToExcel,
        exportableToPDF: res.data.exportableToPDF,
        exportableToCSV: res.data.exportableToCSV,
        exportableToXLXS: res.data.exportableToXLXS
      })
    }).catch((e) => console.error(e))
    .finally(() => setIsLoading(false));
  }

  useEffect(loadParameters, []);

  function onSaveParameter() {
    switch(editingParameter.controlType) {
      case CONTROL_TYPES.CHECKBOXLIST:
        const list = _.chain(editingParameter.listItems)
          .filter(x => editingParameter.checkboxListSelectedValues.includes(x.id))
          .map(x => ({Id: x.id, Name: x.name}))
          .value();
        api.post('Resources/SaveListParameter', {
            ReportParameterId: editingParameter.reportParameterId, 
            ParameterValueList: list
          }).then(res => {
          loadParameters();
          setEditingParameter(null);
        }).catch((e) => console.error(e));
        break;
      case CONTROL_TYPES.SELECT:
        api.post('Resources/SaveSimpleDtoParameter', {
            ReportParameterId: editingParameter.reportParameterId, 
            ParameterValueDto: {
              Id: parseInt(editingParameter.value.value, 10),
              Name: editingParameter.value.label
            }
          }).then(res => {
          loadParameters();
          setEditingParameter(null);
        }).catch((e) => console.error(e));
        break;
      case CONTROL_TYPES.DATE_RANGE:
        api.post('Resources/SaveDateRangeParameter', {
            ReportParameterId: editingParameter.reportParameterId, 
            dateFrom: date_helpers.formatDateForServer(editingParameter.value),
            dateThrough: date_helpers.formatDateForServer(editingParameter.value2),
            selectionType: (selectedCannedDateRange ? selectedCannedDateRange.value : "")
          }).then(res => {
          loadParameters();
          setEditingParameter(null);
        }).catch((e) => console.error(e));
        break;
      case CONTROL_TYPES.DATE:
        console.log('saving', editingParameter)
        api.post('Resources/SaveDateParameter', {
            ReportParameterId: editingParameter.reportParameterId, 
            ParameterValueString: date_helpers.formatDateForServer(editingParameter.value)
          }).then(res => {
          loadParameters();
          setEditingParameter(null);
        }).catch((e) => console.error(e));
        break;
      // case CONTROL_TYPES.CHECKBOX:
      //   break;
      case CONTROL_TYPES.TEXTBOX:
      default:
        api.post('Resources/SaveStringParameter', {
            ReportParameterId: editingParameter.reportParameterId, 
            ParameterValue: editingParameter.value
          }).then(res => {
          loadParameters();
          setEditingParameter(null);
        }).catch((e) => console.error(e));
        break;
    }
  }

  function onSelectCheckboxItem(iId) {
    let newList = editingParameter.checkboxListSelectedValues.slice();
    if (newList.includes(iId)) {
      newList = _.reject(newList, x => x === iId);
    } else {
      newList.push(iId);
    }
    setEditingParameter({
      ...editingParameter,
      checkboxListSelectedValues: newList
    });
  }

  function onSelectAllToggle() {
    const newValue = !selectAll;
    setSelectAll(newValue);
    if (newValue) {
      const newSelectedValues = searchText
        ? _.map(_.filter(editingParameter.listItems, x => filteredItemIds.includes(x.id)), x => x.id)
        : _.map(editingParameter.listItems, x => x.id);
      setEditingParameter({
        ...editingParameter,
        checkboxListSelectedValues: newSelectedValues
      });
    } else {
      setEditingParameter({
        ...editingParameter,
        checkboxListSelectedValues: []
      });
    }
  }

  function onFilterCheckboxList(searchTerm) {
    const lowerCaseSearchTerm = searchTerm.toLowerCase();
    const matchingItemIds = _.chain(editingParameter.listItems)
      .filter(x => x.name.toLowerCase().includes(lowerCaseSearchTerm))
      .map(x => x.id)
      .value();
    setFilteredItemIds(matchingItemIds);
    setSearchText(searchTerm);    
  }

  function onChangeParameterValue(newValue) {
    setEditingParameter({
      ...editingParameter,
      value: newValue
    });
  }

  function onClearParam(reportParam) {
    api.post('Resources/ClearParameter', {id: reportParam.reportParameterId}).then(res => {
      if (res.data.success) {
        loadParameters();
      } else {
        Alert.error(res.data.message);
      }
    }).catch((e) => console.error(e));
  }          

  function allRequiredParametersProvided() {
    return _.every(parameters, p => p.isOptional || p.isHidden || 
        (p.settingDescription && p.settingDescription !== 'All')
      );
  }

  function onRender(outputFormat) {
    if (!allRequiredParametersProvided()) {
      Alert.warning('Please provide a value for each required parameter (each of those with a &#9734;) before running the report.');
      return;
    }
    setIsLoading(true);
    const pdfWindow = window.open();
    switch (outputFormat) {
      case 'PDF':
        api.downloadAndOpenFile('Resources/RenderPDF', { id: reportId }, pdfWindow, (e) => {
          pdfWindow.close();
          console.error(e);
          Alert.error("There was an error downloading the document");
        }, constants.MIME_PDF, () => setIsLoading(false));
        break;
      case 'XLS':
        api.downloadAndOpenFile('Resources/RenderXLS', { id: reportId }, pdfWindow, (e) => {
          pdfWindow.close();
          console.error(e);
          Alert.error("There was an error downloading the document");
        }, constants.MIME_XLS, () => setIsLoading(false));
        break;
      case 'XLSX':
        api.downloadAndOpenFile('Resources/RenderXLSX', { id: reportId }, pdfWindow, (e) => {
          pdfWindow.close();
          console.error(e);
          Alert.error("There was an error downloading the document");
        }, constants.MIME_XLSX, () => setIsLoading(false));
        break;
      case 'CSV':
      default:
        api.downloadAndOpenFile('Resources/RenderCSV', { id: reportId }, pdfWindow, (e) => {
          pdfWindow.close();
          console.error(e);
          Alert.error("There was an error downloading the document");
        }, constants.MIME_CSV, () => setIsLoading(false));
        break;
    }
  }

  function onChangeRequest(reportParam) {
    api.post('Resources/GetParameterOptions', {id: reportParam.reportParameterId}).then(res => {
      /*
        ControlId = reportParameter.ControlName,
        ListItems = list,
        ParameterDisplayName = reportParameter.Prompt,
        Value = Converter.DeserializeNullableInt(reportParameter.TypedSettingValue),
        SettingDescription = reportParameter.SettingDescription

        Value2 = dto.To,
      */
      // augment data for the UI as needed
      let paramData = Object.assign({}, res.data.message);
      switch(paramData.controlType) {
        case CONTROL_TYPES.CHECKBOXLIST:
          paramData.checkboxListSelectedValues = paramData.checkboxListSelectedValues
            ? _.map(paramData.checkboxListSelectedValues, x => parseInt(x, 10))
            : [];
          break;
        case CONTROL_TYPES.SELECT:
          // paramData.value = paramData.value;
          paramData.listItems = ui_helpers.idNameToValueLabel(paramData.listItems);
          break;
        case CONTROL_TYPES.DATE:
          paramData.value = paramData.value
            ? moment(paramData.value)
            : '';
          break;
        case CONTROL_TYPES.DATE_RANGE:
          paramData.value = moment(paramData.value);
          paramData.value2 = moment(paramData.value2);
          break;
        case CONTROL_TYPES.CHECKBOX:
          paramData.value = paramData.value ? true : false;
          break;
        case CONTROL_TYPES.TEXTBOX:
        default:
          break;
      }
      setEditingParameter({
        ...reportParam,
        ...paramData
      });
    }).catch((e) => console.error(e));
  }

  return (
    <div>
      <Header />
      {editingParameter
        ? (<Modal fullscreen="sm" toggle={() => setEditingParameter(null)} isOpen>
            <ModalHeader>
              Set {editingParameter.parameterDisplayName}
            </ModalHeader>
            <ModalBody>
              {editingParameter.controlType === CONTROL_TYPES.DATE_RANGE
                ? (<div id="divDateRange" className="paramElement">
                    <Select
                      options={cannedDateRangeOptions}
                      value={selectedCannedDateRange}
                      onChange={(newOption) => setSelectedCannedDateRange(newOption)}
                    />
                    {selectedCannedDateRange && selectedCannedDateRange.value === 'choose-my-own'
                      ? (<Row className="paramValue mt-2">
                          <Col xs="2" className="pt-1">Between</Col>
                          <Col xs="4">
                            <div className="datePickerDiv">
                              <DatePicker
                                selected={editingParameter.value ? editingParameter.value.toDate() : null}
                                onChange={(date) => setEditingParameter({
                                  ...editingParameter,
                                  value: moment(date)
                                })}
                                showMonthDropdown
                                showYearDropdown
                                // withPortal
                                dropdownMode="select"
                              />
                            </div>
                          </Col>
                          <Col xs="1" className="pt-1">and</Col>
                          <Col xs="4">
                            <div className="datePickerDiv">
                              <DatePicker
                                selected={editingParameter.value2 ? editingParameter.value2.toDate() : null}
                                onChange={(date) => setEditingParameter({
                                  ...editingParameter,
                                  value2: moment(date)
                                })}
                                showMonthDropdown
                                showYearDropdown
                                // withPortal
                                dropdownMode="select"
                              />
                            </div>
                          </Col>
                        </Row>)
                      : null
                    }
                  </div>)
                : null
              }
              {editingParameter.controlType === CONTROL_TYPES.DATE
                ? (<div id="divDateRange" className="paramElement">
                    <Row className="paramValue mt-2">
                      <Col>
                        <div className="datePickerDiv">
                          <DatePicker
                            selected={editingParameter.value ? editingParameter.value.toDate() : null}
                            onChange={(date) => setEditingParameter({
                              ...editingParameter,
                              value: moment(date)
                            })}
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                          />
                        </div>
                      </Col>
                    </Row>
                  </div>)
                : null
              }
              {editingParameter.controlType === CONTROL_TYPES.CHECKBOXLIST
                ? (<div id="divChkList" className="divChkList paramElement">
                    <div className="paramCaption" style={{textAlign:"right", paddingBottom:"5px", borderBottom:"solid 1px #999999"}}>
                      <DebounceInput
                        type="text" 
                        value={searchText}
                        onChange={(e) => onFilterCheckboxList(e.target.value)} 
                        min={1} 
                        debounceTimeout={250}
                        className="searchList"
                        placeholder="Search"
                      />
                    </div>
                    <div className="paramValue">
                      <div className="checkbox-list" style={{height:"240px", overflow:"auto"}}>
                        <div title="Select / Deselect All">
                          <input type="checkbox" onChange={onSelectAllToggle} />
                          <span className="toggleDsc"> {selectAll ? "Deselect" : "Select"} All</span>
                        </div>
                        {_.map(_.filter(editingParameter.listItems, item => {
                            return searchText
                              ? filteredItemIds.includes(item.id)
                              : true;
                          }), item => (
                          <div className="chkItem" key={`ci${item.id}`} id={`ci${item.id}`}>
                            <input type="checkbox" className="chk" 
                              checked={editingParameter.checkboxListSelectedValues.includes(item.id)} 
                              onChange={(e) => onSelectCheckboxItem(item.id)}
                            /> <span className="item-text">{item.name}</span>
                          </div>
                        ))}
                    </div>
                  </div>
                </div>)
                : null
              }
              {editingParameter.controlType === CONTROL_TYPES.SELECT
                ? (<div id="divDD" className="paramElement">
                    <div className="paramValue">
                      <Select
                        options={editingParameter.listItems}
                        value={editingParameter.value
                          ? _.find(editingParameter.listItems, (x) => x.id === editingParameter.value.value)
                          : null}
                        onChange={(option) => onChangeParameterValue(option)}
                      />
                    </div>
                  </div>)
                : null
              }
              {editingParameter.controlType === CONTROL_TYPES.TEXTBOX
                ? (<Input type='text' maxLength="100" value={editingParameter.value} onChange={(e) => onChangeParameterValue(e.target.value)} />)
                : null
              }
              {editingParameter.controlType === CONTROL_TYPES.CHECKBOX
                ? (<div className='mb-1' style={{cursor: "pointer"}} onClick={(e) => {
                      e.stopPropagation();
                      onChangeParameterValue(!editingParameter.value);
                    }} >
                    <Input type="checkbox" checked={editingParameter.value} onChange={(e) => {
                      e.stopPropagation();
                      onChangeParameterValue(!editingParameter.value);
                    }} /> Private
                  </div>)
                : null
              }
            </ModalBody>
            <ModalFooter>
              <Row className={'d-flex justify-content-end'}>
                <Col>
                  <ButtonGroup className="float-end">
                    <Button color='primary' onClick={onSaveParameter}>Save</Button>
                    <Button onClick={() => setEditingParameter(null)}>Cancel</Button>
                  </ButtonGroup>
                </Col>
              </Row>
            </ModalFooter>
          </Modal>)
        : null
      }
      {isLoading 
        ? (<Loading />) 
        : (<Row className="p-2">
            <Col xs={{offset: 2, size: 8}}>
              <Table id="user-parameters-table">
              <thead>
                <tr>
                  <th colSpan="3" style={{textAlign: "left", paddingBottom:"15px"}}>
                    <span className="reportTitle" style={{fontSize:"18pt"}}>
                        {report && report.name} Criteria
                    </span>
                   </th>
                </tr>
              </thead>
              <tbody>
              {_.map(_.reject(parameters, p => p.isHidden), p => (
                <tr className="summary-tr" key={p.reportParameterId}>
                  <td className='paramPrompt'>
                      <span className='paramTitle'>{p.prompt}</span>
                      {p.isOptional
                        ? null
                        : (<span title='Required!'>&#9734;</span>)
                      }
                  </td>
                  <td className="paramLinks">
                    {p.prompt
                      ? (<div>
                          <span style={{padding: 0}} className="showParam btn btn-link" onClick={() => onChangeRequest(p)}>Change</span>
                          {p.settingDescription && p.settingDescription !== 'All'
                            ? (<span> | <span style={{padding: 0}} className="clearParam btn btn-link" onClick={() => onClearParam(p)}>Reset to all</span></span>)
                            : null
                          }
                        </div>)
                      : null
                    }
                  </td>
                  <td className="paramSetting">{p.settingDescription}</td>
              </tr>))
              }
                <tr>
                  <td style={{paddingTop: "15px"}}>
                    <Button tag={Link} to="/Resources/Reports" className="btn-mini">
                      <FontAwesomeIcon icon='angle-left' /> Back to Reports
                    </Button>
                  </td>
                  <td colSpan='2' style={{textAlign: "right"}}>
                    {report && report.exportableToPDF
                      ? (<span className='runButtonPDF' onClick={() => onRender('PDF')}>
                          <img src={PDF} className="float-end" />
                          <span>Run Report PDF</span>
                        </span>)
                      : null
                    }
                    {report && report.exportableToExcel
                      ? (<span className='runButtonXLS' onClick={() => onRender('XLS')}>
                          <img src={XLS} className="float-end" />
                          <span>Run Report Excel</span>
                        </span>)
                      : null
                    }
                    {report && report.exportableToXLXS
                      ? (<span className='runButtonXLS' onClick={() => onRender('XLSX')}>
                          <img src={XLS} className="float-end" />
                          <span>Run Report XLSX</span>
                        </span>)
                      : null
                    }
                    {report && report.exportableToCSV
                      ? (<span className='runButtonCSV' onClick={() => onRender('CSV')}>
                          <img src={CSV} className="float-end" />
                          <span>Run Report CSV</span>
                        </span>)
                      : null
                    }
                  </td>
                </tr>
              </tbody>
              </Table>
            </Col>
          </Row>)
      }
      <Footer />
    </div>
  );
};

export default ReportParameters;
