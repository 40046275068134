import React, { useEffect } from "react";
import { v4 as uuid } from "uuid";
import styles from "../floorplanTool.module.css";
import {
  useActions,
  useAppState,
  useControlTarget,
  useDisplaySettings,
  useExterior,
  usePointerCoordinates,
} from "../hooks";
import { snap, toFeetInchesString } from "../utils";
import { ControlPoint } from "./";

const controlPoint1Id = uuid();
const controlPoint2Id = uuid();

function EndPorch() {
  const [appState, appStateDispatch] = useAppState();
  const [exterior, exteriorDispatch] = useExterior();
  const [controlTarget] = useControlTarget();
  const [displaySettings] = useDisplaySettings();
  const [coordinates] = usePointerCoordinates();
  const actions = useActions({ exteriorDispatch, appStateDispatch });

  useEffect(() => {
    if (appState.isDraggingPorchHandle) {
      if (
        controlTarget === controlPoint1Id ||
        controlTarget === controlPoint2Id
      ) {
        actions.setPorchMax(snap(exterior.width - 12, 6));
        actions.setPorchMin(snap(coordinates.x, 6));
      }
    }
  }, [controlTarget, coordinates]);

  return (
    <g>
      <rect
        x={exterior.porchMin}
        y={0}
        width={exterior.width - exterior.porchMin}
        height={exterior.height}
        fill="none"
        stroke={appState.editingPorch ? "#0d6efd" : "black"}
        strokeWidth={2}
      />
      <text
        x={displaySettings.fontSize}
        y={displaySettings.fontSize}
        fontSize={displaySettings.fontSize}
        className={styles.svgTxt}
        transform={`rotate(90,${displaySettings.fontSize},${displaySettings.fontSize})`}
      >
        End Porch ({toFeetInchesString(exterior.porchMin)} Depth)
      </text>
      {appState.editingPorch ? (
        <>
          <ControlPoint
            id={controlPoint1Id}
            className="endPorchControlPoint"
            cx={exterior.porchMin}
            cy={0}
            onMouseDown={() => actions.setIsDraggingPorchHandle(true)}
            onTouchStart={() => actions.setIsDraggingPorchHandle(true)}
          />
          <ControlPoint
            id={controlPoint2Id}
            className="endPorchControlPoint"
            cx={exterior.porchMin}
            cy={exterior.height}
            onMouseDown={() => actions.setIsDraggingPorchHandle(true)}
            onTouchStart={() => actions.setIsDraggingPorchHandle(true)}
          />
        </>
      ) : null}
    </g>
  );
}

export default EndPorch;
