function appStateReducer(state, { type, payload }) {
  switch (type) {
    case "TOGGLE_EDITING_PORCH":
      return { ...state, editingPorch: !state.editingPorch };
    case "SET_IS_DRAGGING_PORCH_HANDLE":
      return { ...state, isDraggingPorchHandle: payload };
    case "SET_IS_MOVING_ADDON":
      return { ...state, isMovingAddOn: payload };
    case "SET_IS_ROTATING_ADDON":
      return { ...state, isRotatingAddOn: payload };
    case "SET_IS_RESIZING_ADDON":
      return { ...state, isResizingAddOn: payload };
    case "SET_PORCH_COLOR":
      return {
        ...state,
        porchColorId: payload
      };
    default:
      return state;
  }
}

export default appStateReducer;
