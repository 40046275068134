import React, {useEffect, useState} from 'react';
import {ui_helpers} from "../helpers";
import {Label, Row} from "reactstrap";
import Select from "react-select";

function CardSelectSetting(props) {
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    setIsEditing(props.isEditing);
  }, [props]);

  return (<>
    <Row>
      <div className='d-flex justify-content-between'>
        <Label>{props.title}</Label>
        {isEditing && !props.readOnly
          ? <Select
              name={props.name}
              isClearable={props.isClearable}
              options={props.options}
              value={props.value || ""}
              onChange={props.onChange}
              placeholder={props.placeholder}
              styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  minWidth: 130
                })
              }}
            />
          : props.optionName
            ? <span style={{textAlign: 'right'}}>{props.optionName}</span>
            : ui_helpers.redX()
        }
      </div>
    </Row>
    {props.divider || isEditing
      ? <hr/>
      : null
    }
    {props.main
        ? props.children
        : props.value && props.children}
  </>);
}

export default CardSelectSetting;