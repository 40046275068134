import React from "react";
import {
  Button, ButtonGroup,
  Row, Col, Input, Label, ModalHeader, ModalBody, ModalFooter, Modal
} from "reactstrap";
import { confirmAlert } from "react-confirm-alert";

export default function SetSerialModal({ serialNumber, changeSerialNumber, setShowSerialModal, setSerialNumber }) {
    function confirmSerialChange() {
            confirmAlert({
                title: "Confirm Serial Number Change",
                message: `Changing the Serial Number will result in a change on any paired contract or inventory. Are you sure?`,
                buttons: [
                    {
                        label: "Yes",
                        onClick: () => {
                            changeSerialNumber();
                        }
                    },
                    {
                        label: "No"
                    }
                ]
            });
    }

	return (<Modal isOpen fullscreen="sm" autoFocus={false} toggle={() => {
            setSerialNumber("");
            setShowSerialModal(false);
          }}>
          <ModalHeader>
            Set Serial #
          </ModalHeader>
          <ModalBody>
            <Input type='text' maxLength="40" autoFocus={true}
              value={serialNumber}
              onKeyDown={(e) => {
                if (e.which === 13) {
                  confirmSerialChange();
                }
              }}
              onChange={(e) => setSerialNumber(e.target.value)}/>
          </ModalBody>
          <ModalFooter>
            <Row className={'d-flex justify-content-end'}>
              <Col>
                <ButtonGroup className="float-end">
                 <Button color='primary' onClick={confirmSerialChange}>Save</Button>
                  <Button onClick={() => {
                    setSerialNumber("");
                    setShowSerialModal(false);
                  }}>Close</Button>
                </ButtonGroup>
              </Col>
            </Row>
          </ModalFooter>
        </Modal>);
}