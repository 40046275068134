import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import _ from 'lodash';
import {Link, useHistory} from 'react-router-dom';
import classnames from 'classnames';
import React, {useEffect, useState} from "react";
import {
  Badge,
  Row, Col,
  Button,
  ButtonGroup,
  Modal,
  ModalBody,
  ModalFooter,
  Table,
  Spinner
} from "reactstrap";
import {Pager} from './';
import {date_helpers, ui_helpers} from "../helpers";
import {constants} from "../utils";
import ReactTooltip from "react-tooltip";

const CommentRow =  ({comment, markAsSeen, id, onCloseModal}) => {
  const history = useHistory();
  const [markedAsSeen, setMarkedAsSeen] = useState(false);

  function onLinkClicked(e) {
    if(e.button == 0 && !e.ctrlKey) {
      setTimeout(() => onCloseModal(), 170);
    }
  }

  function generateLink() {
    let itemRoute = constants.PATH_NAMES.CONTRACT_VIEW;
    if (comment.referenceType === 'Cash Sale') {
      itemRoute = constants.PATH_NAMES.CASH_SALE_VIEW;
    } else if (comment.referenceType === 'UnitInventory') {
      itemRoute = constants.PATH_NAMES.INVENTORY_VIEW;
    }
    return `${itemRoute}/${comment.referenceId}`;
  }

  return (
    <tr key={`com-${comment.commentPromptId}`}>
      <td>
        <Button
          title={`${comment.referenceType} ${comment.referenceId}`}
          tag={Link}
          to={generateLink}
          onClick={(e) => onLinkClicked(e)}
          size={'sm'}
          className={classnames({
          "bg-primary": comment.referenceType === 'Contract',
          "border-primary": comment.referenceType === 'Contract',
          "bg-dark": comment.referenceType === 'Cash Sale',
          "border-dark": comment.referenceType === 'Cash Sale',
          "bg-ghost": comment.referenceType === 'UnitInventory',
          "border-ghost": comment.referenceType === 'UnitInventory'})}>{comment.invoiceNumber || comment.referenceId}</Button>
      </td>
      <td>{comment.customerName}<br /><small>{comment.serialNumber}</small></td>
      <td dangerouslySetInnerHTML={{__html: comment.dsc}} />
      <td>{`${comment.userFirstName} ${comment.userLastName}`}</td>
      <td>{comment.createdAtLocalized}</td>
      <td>
        {markedAsSeen
          ? (<Spinner size="sm" />)
          : (<Button size="sm" color="dark" id={`m-${comment.id}`} data-tip data-for={`tt-m-${comment.id}`}
              onClick={() => {
                setMarkedAsSeen(true);
                markAsSeen({id: comment.commentPromptId, referenceType: comment.referenceType});
              }}>
                <ReactTooltip
                  className={'follow-opactiy'}
                  place={'bottom'}
                  id={`tt-m-${comment.id}`}
                  type={'dark'}
                  effect={'float'}>
                  <p style={{fontSize: '1rem'}}>Mark as Read</p>
                </ReactTooltip>
                <FontAwesomeIcon icon="check" />
            </Button>)
        }
      </td>
    </tr>
  );
}

export default function CommentModal({ isOpen, setIsOpen, comments, refresh, markAsSeen, markAllSeen, undoMarkAsSeen, onSetPageCallback }){
  const totalFormatted = ui_helpers.formatNumber(comments.totalItems);
  return (
    <Modal size="xl" isOpen={isOpen}>
      <div className="modal-header">
        <div className="w-100 d-flex justify-content-between">
          <h3>
            Latest Comments - <Badge color="dark">{totalFormatted}</Badge> Total
          </h3>
          <ButtonGroup size="sm">
            {comments.totalItems
              ? (<Button color="secondary" onClick={() => markAllSeen(totalFormatted)} title="Mark all comments as seen/read">
                  <FontAwesomeIcon icon="check" className="me-2" />
                  Mark all read
                </Button>)
              : null
            }
            <Button color="primary" onClick={refresh} title="Refresh this list">
              <FontAwesomeIcon icon="sync" className="me-2" />
              Refresh
            </Button>
            <Button color="dark" onClick={undoMarkAsSeen} title="Undo marking recent comments seen">
              <FontAwesomeIcon icon="undo" className="me-2" />
              Undo
            </Button>
          </ButtonGroup>
        </div>
      </div>
      <ModalBody className="pb-0">
        <Table size="sm" striped>
          <thead>
            <tr key='c-header'>
              <th>Reference</th>
              <th style={{width: "185px"}}>Customer Name</th>
              <th>Comment</th>
              <th style={{width: "130px"}}>By</th>
              <th style={{width: "7rem"}}>At</th>
            </tr>
          </thead>
          <tbody>
          {comments.dataSource && comments.dataSource.length
            ? _.map(comments.dataSource, (c) => 
              (<CommentRow id={c.id} key={`cr-${c.id}`} comment={c} markAsSeen={markAsSeen} onCloseModal={() => setIsOpen(false)} />))
            : null
          }
          </tbody>
        </Table>
      </ModalBody>
      <ModalFooter>
        <Row className="w-100">
          <Col>
            <Pager totalResults={comments.totalItems} pageNumber={comments.pageNumber} pageSize={comments.pageSize} onSetPageCallback={onSetPageCallback}/>
          </Col>
          <Col>
            <Button color="secondary" className="float-end" onClick={() => setIsOpen(false)} title="Close">
              Close
            </Button>
          </Col>
        </Row>
      </ModalFooter>
    </Modal>
  );
}
