import React, {useState, useEffect, useContext} from 'react';
import {Header} from  './';
import {
    Container,
    Row,
    Col,
    Button,
    Label,
    Card,
    CardBody,
    CardHeader,
    Form,
    FormGroup,
    Input,
    FormFeedback
} from 'reactstrap';
import {UserContext, api} from "../utils";
import {Redirect} from "react-router-dom";
import {ui_helpers} from "../helpers";
import Select from "react-select";
import _ from 'lodash';
import CurrencyInput from "react-currency-input-field";
import {DebounceInput} from "react-debounce-input";

const defaultPayment = {
    merchantId: 1012,
    name: '',
    routeNumber: '',
    accountNumber: '',
    accountType: 'ACHDebit',
    amount: null,
    entryClass: '',
    type: 'ACHDebit',
}

const AccountTypes = [
{label: 'Personal Checking', value: 'PersonalChecking', entryClass: 'PPD'},
{label: 'Personal Savings', value: 'PersonalSavings', entryClass: 'PPD'},
{label: 'Business Checking', value: 'BusinessChecking', entryClass: 'CCD'},
{label: 'Business Savings', value: 'BusinessSavings', entryClass: 'CCD'},
];

const defaultBank = {
  name: '',
  routeNumber: '',
  addressLine1: '',
  city: '',
  state: '',
  zipCode: '',
  phoneNumber: '',
  achAllowed: '',
    companyId: 1
}

export default function Developer(props) {
    const {currentUser} = useContext(UserContext);
    const [redirectTo, setRedirectTo] = useState('');
    const [achPayment, setAchPayment] = useState(defaultPayment);
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [bankInformation, setBankInformation] = useState(defaultBank);
    const [routingNumberIsValid, setRoutingNumberIsValid] = useState(false);
    const [routingNumberIsInValid, setRoutingNumberIsInValid] = useState(false);



    const testAuthorize = () => {
      api.fetch('Inventory/TestAuthAch')
          .then(r => {
              if(!r.data.success) return;
          }) ;
    };

    const validatedRoutingNumber = (e) => {
        e.preventDefault();
        let payload = Object.assign({}, achPayment);
        // let payload = {
        //     companyId: 1,
        //     accountNumber: achPayment.accountNumber,
        //     routingNumber: achPayment.routeNumber
        // }
        api.post(`Contract/TestAccountValidation/1`, payload)
            .then(r => {
                if(!r.data) return;
                // setBankInformation(r.data);
            });
    };

    const testCreatePayment = (e) => {
        e.preventDefault();
        let payload = Object.assign({}, achPayment);
        payload.companyId = 1;
        payload.name = firstName + ' ' + lastName;
        api.post('Contract/CreateAchPayment', payload)
            .then(r => {
                if(!r.data && !r.data.success) return;
            })
    };

    const onPaymentChange = (fieldName, fieldValue) => {
      let newPayment = Object.assign({}, achPayment);
        if(fieldName === 'accountType') {
            newPayment.accountType = fieldValue.value;
            newPayment.entryClass = fieldValue.entryClass;
        } else if (fieldName === 'routeNumber') {
            // validatedRoutingNumber(fieldValue);
            newPayment.routeNumber = fieldValue;
        }
        else {
            newPayment[fieldName] = fieldValue;
        }

        console.table(newPayment);
        setAchPayment(newPayment);

    };

    const handlePaymentChange = (e) => {
        onPaymentChange(e.target.name, e.target.value);
    };




    useEffect(() => {
        if(!currentUser) return;
        if(ui_helpers.isSystemAdministrator(currentUser)) return;
        setRedirectTo('/');
    }, [currentUser]);

    if(redirectTo) {
        return <Redirect to={redirectTo}/>
    }
    return (
      <>
          <Header admin toolbar>

          </Header>
          <Container fluid>
              <Row className={'mt-3 mx-3'}>
                  <Card className={'p-0'}>
                      <CardHeader>
                          <h3>ACH Testing</h3>
                      </CardHeader>
                      <CardBody>
                            <Row>
                                <Col sm={2}>
                                    <Label>
                                        Test Authorize
                                    </Label>
                                </Col>
                                <Col>
                                    <Button onClick={() => testAuthorize()}>
                                        Test
                                    </Button>
                                </Col>
                            </Row>
                          <Form>
                              <FormGroup row>
                                  <Col>
                                      <Label>Routing Number:</Label>
                                      <DebounceInput type={'text'} value={achPayment.routeNumber} name={'routeNumber'}
                                             onChange={handlePaymentChange} maxLength={9} minLength={9} element={Input}
                                                     // invalid={achPayment.routeNumber.length > 8 && !bankInformation.name}
                                                     // valid={bankInformation.name}
                                      />
                                      <FormFeedback valid>
                                          {bankInformation.name}
                                      </FormFeedback>
                                      <FormFeedback invalid>
                                          It doesn't seem like that routing number is correct. Please try again.
                                      </FormFeedback>
                                  </Col>
                                  <Col>
                                      <Label>Account Number</Label>
                                      <Input type={'text'} value={achPayment.accountNumber} name={'accountNumber'}
                                             onChange={handlePaymentChange}/>
                                  </Col>
                              </FormGroup>
                              <FormGroup row>
                                  <Col>
                                      <Label>Account Type</Label>
                                      <Select options={AccountTypes} onChange={(option) =>
                                          onPaymentChange('accountType', option)} value={_.find(AccountTypes, a => {
                                              return a.value === achPayment.accountType;
                                      })}/>
                                  </Col>
                                  <Col sm={'4'}>
                                      <Label>Account Holders First Name</Label>
                                      <Input tpye={'text'} value={firstName} onChange={(e) => setFirstName(e.target.value)}/>
                                  </Col>
                                  <Col sm={'4'}>
                                      <Label>Account Holders Last Name</Label>
                                      <Input tpye={'text'} value={lastName} onChange={(e) => setLastName(e.target.value)}/>
                                  </Col>
                              </FormGroup>
                              <FormGroup row>
                                  <Col sm={3}>
                                      <Label>Amount</Label>
                                      <CurrencyInput value={achPayment.amount} name='amount' className={'form-control'}
                                                     onValueChange={(value, name) =>
                                                         onPaymentChange(name, value)} decimalsLimit={2} prefix={'$'}/>
                                  </Col>
                              </FormGroup>
                              <Row>
                                  <Col>
                                      <Button size={'sm'} className={'float-end'} onClick={(e) => validatedRoutingNumber(e)}>
                                          Submit
                                      </Button>
                                  </Col>
                              </Row>
                          </Form>
                      </CardBody>
                  </Card>
              </Row>
          </Container>
      </>
    );
}