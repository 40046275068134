import React, { useState } from "react";
import { Button, ButtonGroup, Input } from "reactstrap";
import { TOP_LEVEL_VIEWS } from "../constants";
import { useAppStore } from "../hooks";
import Alert from "react-s-alert-v3";
import { input_validation } from "../../../helpers";

export default function PaperlessBilling({confirmPaperlessBilling}) {
  const setCurrentView = useAppStore((state) => state.setCurrentView);
  const setPaperlessEmail = useAppStore((x) => x.setPaperlessEmail);
  const defaultEmail = useAppStore((x) => x.paperlessEmail);
  const [showEmailInput, setShowEmailInput] = useState(false);
  const [userInput, setUserInput] = useState(defaultEmail);

  function handleNo() {
    setCurrentView(TOP_LEVEL_VIEWS.RECURRING_PAYMENTS);
  }

  function handleYes() {
    setShowEmailInput(true);
  }

  function handleSubmit() {
    if (!input_validation.is_email(userInput)) {
      Alert.warning('Either enter a valid email address or choose to cancel and skip.');
      return;
    }
    confirmPaperlessBilling({email: userInput});
    setPaperlessEmail(userInput);
    setCurrentView(TOP_LEVEL_VIEWS.RECURRING_PAYMENTS);
  }

  return (
    <div className="vw-100 vh-100 position-fixed d-flex flex-column align-items-center justify-content-center text-center">
      {!showEmailInput ? (
        <>
          <h3>Would you like to enable paperless billing?</h3>
          <div className="my-5">
            <Button size="lg" color="dark" className="me-3" onClick={handleNo}>
              No, thanks
            </Button>
            <Button
              size="lg"
              color="primary"
              className="ms-3"
              onClick={handleYes}
            >
              Yes!
            </Button>
          </div>
        </>
      ) : (
        <>
          <h3>Enter email address for paperless billing:</h3>
          <Input
            bsSize="lg"
            className="mb-3"
            style={{ maxWidth: "20rem" }}
            value={userInput}
            onChange={(e) => setUserInput(e.target.value)}
          />
          <ButtonGroup>
            <Button color="primary" size="lg" onClick={handleSubmit}>
              Next
            </Button>
            <Button color="secondary" size="lg" onClick={handleNo}>
              Cancel
            </Button>
          </ButtonGroup>
        </>
      )}
    </div>
  );
}
