import React, {useEffect, useState} from 'react';
import {ui_helpers} from "../helpers";
import {Label, Row} from "reactstrap";
import ToggleButton from "react-toggle-button";

const getCardBooleanComponent = (value) =>
    value ? ui_helpers.greenCheck() : ui_helpers.redX();

export function CardSettingsParent(props) {
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    setIsEditing(props.isEditing);
  }, [props]);

  return (<>
    <Row>
      <div className='d-flex justify-content-between'>
        <Label>{props.title}</Label>
        {isEditing && !props.readOnly
          ? <ToggleButton
              value={props.value}
              activeLabel={"Yes"}
              inactiveLabel={"No"}
              onToggle={(value) => props.onChange(props.name, !value)}
            />
          : getCardBooleanComponent(props.value)
        }
      </div>
    </Row>
    {props.divider || isEditing
      ? <hr/>
      : null
    }
    {props.main
        ? props.children
        : props.value && props.children}
  </>);
}

export function CardSettingsItem(props) {
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    setIsEditing(props.isEditing);
  }, [props]);

  return (
    <>
      <Row>
        <div className='d-flex justify-content-between'>
          <Label>{props.title}</Label>
          {isEditing
            ? <ToggleButton
                value={props.value}
                activeLabel={"Yes"}
                inactiveLabel={"No"}
                onToggle={(value) => props.onChange(props.name, !value)}
              />
            : getCardBooleanComponent(props.value)
          }
        </div>
      </Row>
      {props.divider
        ? <hr/>
        : null
      }
    </>);
}

export function CardSettings(props) {
  const [showDivider, setShowDivider] = useState(false);
  const getCardBooleanComponent = (value) =>
      value ? ui_helpers.greenCheck() : ui_helpers.redX();
  return props.children;
};