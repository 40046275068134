import React, {useContext, createContext} from 'react';


const UserContext = createContext({
    currentUser: null,
    logIn: (_user) => {},
    logOut: i => {},
    impersonate: i => {},
    clearImpersonation: i => {},
    // isLoggedIn: false,
    // setIsLoggedIn: () => {},
    // firstName: null,
    // setFirstName: () => {},
    // lastName: null,
    // setLastName: () => {},
});


export default UserContext;