import React, {useState, useEffect, useContext} from 'react';
import {useHistory, useParams} from "react-router-dom";
import {Alert as AlertBanner, TabContent, TabPane, Container, Row, Col} from "reactstrap";
import {
  AddOnBundleManagement, Loading, ManufacturerAdminHeader, ManufacturerDetails
} from "./";
import {api, ui_helpers} from "../helpers";
import {constants} from "../utils";
import _ from "lodash";
import Alert from "react-s-alert-v3";

const tabNames = {
  Details: "DETAILS",
  Companies: "COMPANIES",
  Stores: "STORES",
  Users: "USERS",
  AddOns: "ADDONS",
  Bundles: "BUNDLES",
  BuildSteps: "BUILDSTEPS",
  Builders: "BUILDERS",
  Inspections: "INSPECTIONS",
  Products: "PRODUCTS",
  Materials: "MATERIALS",
  MfgColors: "MFGCOLORS"
};

export default function MfgAdmin(props) {
  const {id} = useParams();
  const mfgId = id
    ? id
    : props.mfgId;
  const history = useHistory();
  const [activeTab, setActiveTab] = useState(props.tabName || tabNames.Details);
  const [isLoading, setIsLoading] = useState(false);
  const [manufacturer, setManufacturer] = useState({});
  // const [states, setStates] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [manufacturerName, setManufacturerName] = useState('');
  const [message, setMessage] = useState(constants.ALERT_OBJECT);
  const [clonedManufacturer, setClonedManufacturer] = useState({});

  useEffect(getInitialMfgData, []);

  function getCompaniesList() {
    return api.fetch(`company/Companies`).then(response => {
      return {companies: ui_helpers.idNameToValueLabel(response.data.data)}
    });
  }

  function getInitialMfgData() {
    setIsLoading(true);
    getCompaniesList().then((companies) => {
      setCompanies(companies);
      // getRegions().then(o => setStates(o));
      Promise.all([
        getManufacturerDetails(companies)
      ]).then(results => {
          let aggResults = {};
          _.each(results, (x) => Object.assign(aggResults, x));
          const {mfgDetails, mfgCompanies} = aggResults;
          if(mfgDetails) {
            if (mfgDetails.quoteExpirationInDays === null || mfgDetails.quoteExpirationInDays === undefined) {
              mfgDetails.quoteExpirationInDays = 14;
            }
            setManufacturer(mfgDetails);
            setManufacturerName(mfgDetails.name);
          }
      }).finally(() => setIsLoading(false));
    }).catch((err) => console.error(err));
  }

  //MFG Details
  function getManufacturerDetails(pCompanies) {
    if (!pCompanies) {
      pCompanies = companies;
    }
    return api.fetch(`manufacturer/One/${mfgId}`).then(response => {
      if (!response.data.success) {
        Alert.error('There was an error retrieving the Manufacturer Details.');
        return {};
      }
      return {mfgDetails: ui_helpers.shapeMfg(response.data.data, pCompanies)}
    }).catch(error => console.error(error));
  }

  // function refreshMfgDetails() {
  //     api.fetch(`manufacturer/One/${mfgId}`)
  //         .then(r => {
  //             setManufacturer(ui_helpers.shapeMfg(r.data.data, companies));
  //         }).catch(err => console.error(err));
  // }
  return (
    <>
      <ManufacturerAdminHeader
        setActiveTab={setActiveTab}
        tabNames={tabNames}
        activeTab={activeTab}
        //enabledBuild={manufacturer.enableBuildFeature}
        //enableBuildFeaturePreview={manufacturer.enableBuildFeaturePreview}
        manufacturerName={manufacturerName}
        mfgId={mfgId}
      />
      {message.message ? <AlertBanner fade flavor={message.flavor}>{message.message}</AlertBanner> : null}
      <Container fluid>
      {isLoading 
        ? (<Loading/>)
        : (<Row>
            <TabContent activeTab={activeTab} className="mt-2">
              <TabPane tabId={tabNames.Details}>
                {activeTab === tabNames.Details
                  ? (<ManufacturerDetails
                      manufacturer={manufacturer}
                      mfgId={mfgId}
                      setMessage={setMessage}
                      companies={companies}
                      details
                      onSetManufacturerCallback={setManufacturer}
                      refreshMfg={() => getManufacturerDetails(null)}
                      manufacturerName={manufacturerName}
                    />)
                  : null
                }
              </TabPane>
              <TabPane tabId={tabNames.Companies}>
                {props.children}
              </TabPane>
              <TabPane tabId={tabNames.Stores}>
                {props.children}
              </TabPane>
              <TabPane tabId={tabNames.Users}>
                {props.children}
              </TabPane>
              <TabPane tabId={tabNames.AddOns}>
                {props.children}
              </TabPane>
              <TabPane tabId={tabNames.Bundles}>
                {props.children}
              </TabPane>
              {manufacturer && (manufacturer.enableBuildFeature || manufacturer.enableBuildFeaturePreview) &&
                  (<>
                  <TabPane tabId={tabNames.BuildSteps}>
                    {props.children}
                  </TabPane>
                   <TabPane tabId={tabNames.Builders}>
                    {props.children}
                  </TabPane>
                  </>)}
              <TabPane tabId={tabNames.Inspections}>
                {props.children}
              </TabPane>
              <TabPane tabId={tabNames.Products}>
                {props.children}
              </TabPane>
              <TabPane tabId={tabNames.Materials}>
                {props.children}
              </TabPane>
              <TabPane tabId={tabNames.MfgColors}>
                {props.children}
              </TabPane>
            </TabContent>
          </Row>)}
        </Container>
    </>);
}