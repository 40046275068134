import React, { useState, useEffect, Fragment } from "react";
import {
    Row,
    Col,
    Button,
    ButtonGroup,
    Input,
    Label,
    Form,

} from "reactstrap";
import Alert from "react-s-alert-v3";
import classnames from 'classnames';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ui_helpers, date_helpers } from "../helpers";
import { DebounceInput } from "react-debounce-input";
import { CalculatorTable } from "./";
import _ from "lodash";
import { constants, storage } from "../utils";
import DatePicker from 'react-datepicker';

const defaultPricingData = (existing) => {
    if (!existing)
        existing = {};
    const defaultTaxRate = parseFloat(storage.getItem('lastTaxRate') || "7.0");
    return {
        ...existing,
        unitPrice: (existing.unitPrice || 0.00),
        freightAmount: (existing.freightAmount || 0.0),
        otherAmount: (existing.otherAmount || 0.0),
        otherAmountCaption: (existing.otherAmountCaption || ""),
        subtotal: (existing.subtotal || existing.balanceDue || 0.0),
        taxRateString: (existing.taxRateString || String(defaultTaxRate)),
        taxRate: (existing.taxRate || defaultTaxRate),
        taxAmount: (existing.taxAmount || 0.0),
        ldwAmount: (existing.ldwAmount || 8.0),
        dueForDeliveryAmount: existing.dueForDeliveryAmount || 0.00,
        optedOutOfDamageLiabilityWaiver: (existing.optedOutOfDamageLiabilityWaiver || false),
        initialPaymentAmount: (existing.initialPaymentAmount || 0.00),
        includedInitialPayment: existing.includedInitialPayment,
        selectedTermInfo: (existing.selectedTermInfo || null),
        promotionCode: (existing.promotionCode || ""),
        promotionCodeTemp: (existing.promotionCodeTemp || ""),
        estimatedDeliveryDate: (existing.estimatedDeliveryDate || ""),
        mfgExpirationDate: (existing.mfgExpirationDate || date_helpers.addDays(90)),
        //salesperson: (existing.salesperson || defaultSalesperson),
        otherSalesperson: (existing.otherSalesperson || ""),
        isRTO: (existing.isRTO || false)
    };
};

const QuotePricing = (props) => {
    const [pricingData, setPricingData] = useState(defaultPricingData(props.contractData));
    const [screenWarnings, setScreenWarnings] = useState({});
    const [rtoView, setRtoView] = useState(props.contractData && props.contractData.agreementNumber && props.contractData.agreementNumber !== "Cash Sale");
    const [rtoSelected, setRtoSelected] = useState(null);

    useEffect(() => {
        setPricingData(
            defaultPricingData(props.contractData, props.salespeople));
    }, [props.contractData])

    useEffect(() => {
        let newScreenWarnings = {};
        _.each(props.fieldWarnings, w => Object.assign(newScreenWarnings, { [w.field]: w.text }))
        setScreenWarnings(newScreenWarnings);
    }, [props.fieldWarnings])

    function onBlur(value, fieldName) {
        switch (fieldName) {
            case 'promotionCodeTemp':
                const cleanPromoCode = _.trim(value).toUpperCase();
                setPricingData({
                    ...pricingData,
                    promotionCode: cleanPromoCode,
                    promotionCodeTemp: cleanPromoCode
                });
                break;
            case 'initialPaymentAmount':
            case 'unitPrice':
            case 'otherAmount':
            case 'freightAmount':
                let newData = {
                    ...pricingData,
                    [fieldName]: ui_helpers.cleanseCurrencyCharacters(value)
                };
                newData.subtotal = parseFloat(newData.unitPrice) + parseFloat(newData.freightAmount) + parseFloat(newData.otherAmount);
                setPricingData(newData);
                break;
        }
    }

    useEffect(() => {
        if ((!pricingData && !pricingData.selectedTermInfo)
            || (pricingData && !pricingData.selectedTermInfo)) return;
        if (!pricingData.includedInitialPayment && pricingData.initialPaymentAmount
            > pricingData.selectedTermInfo.requiredForDeliveryAmount) {
            props.setDownPaymentOverPayment();
        }
    }, [pricingData.selectedTermInfo])

    const onSelectedTermChange = (term) => {
        const newData = Object.assign({}, pricingData);
        newData.selectedTermInfo = term;
        newData.includedInitialPayment = false;
        newData.initialPaymentAmount = term.requiredForDeliveryAmount;
        setPricingData(newData);
        props.onSetData(newData);
    }

    function onChange(value, fieldName) {
        const newData = {
            ...pricingData,
            [fieldName]: value
        };
        setPricingData(newData);
        props.onSetData(newData);
    }

    const ldwOptions = [
        { label: `${ui_helpers.formatCurrency(pricingData.ldwAmount)} / month`, value: false },
        { label: "OPT OUT of LDW", value: true },
    ];

    return (<>
        <div className="quote-pricing-info">Please select a pricing option below</div>
            <Row>
                <Col xs="4">
                    <Form className="mt-2">
                        <Row>
                            <Col>
                                <div className="d-grid mb-3">
                                    <Row>
                                        <Col xs="6">
                                        <Button block title="Click to select Cash Sale Pricing Option" outline={rtoSelected === false} color="primary" className={rtoSelected === false ? "text-primary" : "text-light"} onClick={() => { onChange(false, "isRTO"); setRtoSelected(false) }}>
                                            Cash Sale
                                        </Button>
                                        </Col>
                                    </Row>
                                </div>
                                <Fragment>
                                        <Row className="mb-1">
                                            <Col xs="6" className="pt-1">
                                                <Label>
                                                    Unit Price 
                                                    { screenWarnings.selectedContractTerm 
                                                        ? <FontAwesomeIcon 
                                                            className="ml-2" 
                                                            icon="info"
                                                            color="danger" 
                                                            title={screenWarnings.selectedContractTerm}
                                                          /> 
                                                        : null}
                                                </Label>
                                            </Col>
                                            <Col xs="6">
                                                <DebounceInput type="text" maxLength="15" value={pricingData.unitPrice}  debounceTimeout={500}
                                                    title={screenWarnings.unitPrice ? screenWarnings.unitPrice : null}
                                                    className={classnames({ invalid: (screenWarnings.unitPrice ? true : false) }, "text-end form-control")}
                                                    onChange={(e) => onChange(e.target.value, 'unitPrice')} onBlur={(e) => onBlur(e.target.value, 'unitPrice')} />
                                            </Col>
                                        </Row>
                                            <Row className="mb-1">
                                                <Col xs="6" className="pt-1">
                                                    <Label>
                                                        Freight (if applicable)
                                                    </Label>
                                                </Col>
                                                <Col xs="6">
                                                    <Input type="text" bsSize="sm" maxLength="15" value={pricingData.freightAmount}
                                                        className="text-end form-control"
                                                        onChange={(e) => onChange(e.target.value, 'freightAmount')} onBlur={(e) => onBlur(e.target.value, 'freightAmount')} />
                                                </Col>
                                            </Row>
                                            <Row className="mb-1">
                                                <Col xs="6" className="pt-1">
                                                    <Input type="text" bsSize="sm" maxLength="50" value={pricingData.otherAmountCaption}
                                                        className="form-control" placeholder="Other Caption (if applicable)"
                                                        onChange={(e) => onChange(e.target.value, 'otherAmountCaption')} />
                                                </Col>
                                                <Col xs="6" className="pt-1">
                                                    <Input type="text" bsSize="sm" maxLength="15" value={pricingData.otherAmount}
                                                        className="text-end form-control"
                                                        onChange={(e) => onChange(e.target.value, 'otherAmount')} onBlur={(e) => onBlur(e.target.value, 'otherAmount')} />
                                                </Col>
                                            </Row>
                                        
                                        <Row className="mb-1">
                                            <Col xs="6" className="pt-2">
                                                <Label>
                                                    Total
                                                </Label>
                                            </Col>
                                            <Col xs="6">
                                                <Input type="text" className="text-end" readOnly disabled
                                                    value={ui_helpers.formatCurrency(pricingData.subtotal)} />
                                            </Col>
                                        </Row>
                                        <Row className="mb-1">
                                            <Col xs="6" className="pt-2">
                                                <Label>
                                                    Expiration Date
                                                </Label>
                                            </Col>
                                            <Col xs="6">
                                                <DatePicker 
                                                   selected={pricingData.mfgExpirationDate}
                                                   onChange={(date) => onChange(date, "mfgExpirationDate")}
                                               />
                                           </Col>
                                       </Row>
                                    </Fragment>
                            </Col>
                        </Row>
                    </Form>
                </Col>
            <Col xs="8">
                <div className="d-grid mb-3">
                    <Row>
                        <Col xs="4">
                            <Button block title="Click to select RTO Pricing Option" outline={rtoSelected} color="primary" className={rtoSelected ? "text-primary mt-2" : "text-light mt-2"} onClick={() => { onChange(true, "isRTO"); setRtoSelected(true) }}>
                                RTO
                            </Button>
                        </Col>
                    </Row>
                </div>

                    <div>
                        {/* SHOW TERMS CALCULATOR*/}
                        {parseFloat(pricingData.unitPrice) > 10.0
                            ? (<CalculatorTable
                                showInputs={false}
                                termsSelectable={true}
                                promotionCode={pricingData.promotionCode}
                                unitPrice={pricingData.unitPrice}
                                taxRate={pricingData.taxRate}
                                atLeast14Wide={parseFloat(props.contractData.widthFeet) > 14.0}
                                atLeast16Wide={parseFloat(props.contractData.widthFeet) > 16.0}
                                includeLDW={pricingData.optedOutOfDamageLiabilityWaiver === false}
                                customDownPayment={pricingData.initialPaymentAmount}
                                productType={_.find(constants.PRODUCT_TYPES, pt => pt.value === props.contractData.productTypeId)}
                                onUpdateSelectedTerm={onSelectedTermChange}
                                selectedTermId={pricingData.selectedTermInfo ? pricingData.selectedTermInfo.contractTermId : null}
                                onSelectedTermUpdate={(t) => onChange(t, 'selectedTermInfo')}
                                hideTax
                                showRTOCompanyName
                            />)
                            : (<div className="gigantic">Provide details to the left to see term options.</div>)
                        }

                    </div>
                </Col>

        </Row>
    </>);
}
export default QuotePricing;