import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, FormGroup, Input, Label } from "reactstrap";
import {
  editingContexts,
  porchTypeOptions,
  porchTypes,
} from "../floorplanConstants";
import styles from "../floorplanTool.module.css";
import {
  useActions,
  useAppState,
  useEditingContext,
  useExterior,
  useInfo,
} from "../hooks";
import _ from "lodash";
import Select from 'react-select';
import { infoMessages } from "../infoMessages";
import { snap, toFeetInchesString } from "../utils";
import AddOnSettings from "./AddOnSettings";
import SidebarCollapse from "./SidebarCollapse";
import React from "react";

function SecondarySidebar() {
  const [appState, appStateDispatch] = useAppState();
  const [exterior, exteriorDispatch] = useExterior();
  const editingContext = useEditingContext();
  const [, setInfo] = useInfo();
  const actions = useActions({ appStateDispatch, exteriorDispatch });

  function handlePorchChange(e) {
    actions.setPorchType(Number(e.target.value));
    actions.setPorchMax(12 * 12);
    actions.setPorchMin(4 * 12);
    actions.setPorchWall1Length(exterior.height * 0.25);
    actions.setPorchWall2Length(exterior.height * 0.25);
    actions.setPorchWall4Length(exterior.height * 0.25);
  }

  return (
    <div className={`${styles.secondarySidebar} p-3 pt-4 shadow`}>
      {/*{editingContext === editingContexts.skuTemplate ? (*/}
        <SidebarCollapse title="Exterior Settings">
          {appState.editingPorch ? (
            <>
              <FormGroup>
                <Label className="m-0 small">Porch Style:</Label>
                <Input
                  bsSize="sm"
                  type="select"
                  value={exterior.porchType}
                  onChange={handlePorchChange}
                  className="mb-2"
                >
                  {porchTypeOptions.map((o) => (
                    <option key={o.value} value={o.value}>
                      {o.label}
                    </option>
                  ))}
                </Input>
              </FormGroup>
              {exterior.porchType !== porchTypes.none && (
                <>
                  {exterior.porchType !== porchTypes.twoWallCorner1 ? (
                    <FormGroup>
                      <Label className="m-0 small">
                        {exterior.porchType === porchTypes.end ||
                        exterior.porchType === porchTypes.side
                          ? "Depth: "
                          : "Minimum Depth: "}
                        {toFeetInchesString(exterior.porchMin)}
                      </Label>
                      <Input
                        type="range"
                        min={1}
                        max={
                          exterior.porchType === porchTypes.side
                            ? exterior.height - 1
                            : exterior.width - 1
                        }
                        value={exterior.porchMin}
                        onInput={(e) =>
                          actions.setPorchMin(snap(Number(e.target.value), 6))
                        }
                      />
                    </FormGroup>
                  ) : null}
                  {exterior.porchType !== porchTypes.end &&
                    exterior.porchType !== porchTypes.side && (
                      <>
                        {exterior.porchType !== porchTypes.twoWallCorner1 && (
                          <FormGroup>
                            <Label className="m-0 small">
                              Maximum Depth:{" "}
                              {toFeetInchesString(exterior.porchMax)}
                            </Label>
                            <Input
                              type="range"
                              min={1}
                              max={exterior.width}
                              value={exterior.porchMax}
                              onInput={(e) =>
                                actions.setPorchMax(
                                  snap(Number(e.target.value), 6)
                                )
                              }
                            />
                          </FormGroup>
                        )}
                        <FormGroup>
                          <Label className="m-0 small">
                            Wall 1 Length:{" "}
                            {toFeetInchesString(exterior.porchWall1Length)}
                          </Label>
                          <Input
                            type="range"
                            min={1}
                            max={exterior.height}
                            value={exterior.porchWall1Length}
                            onInput={(e) =>
                              actions.setPorchWall1Length(
                                snap(Number(e.target.value), 6)
                              )
                            }
                          />
                        </FormGroup>
                        <FormGroup>
                          <Label className="m-0 small">
                            Wall 2 Length:{" "}
                            {toFeetInchesString(exterior.porchWall2Length)}
                          </Label>
                          <Input
                            type="range"
                            min={1}
                            max={exterior.width}
                            value={exterior.porchWall2Length}
                            onInput={(e) =>
                              actions.setPorchWall2Length(
                                snap(Number(e.target.value), 6)
                              )
                            }
                          />
                        </FormGroup>
                        {exterior.porchType !== porchTypes.twoWallCorner1 && (
                          <FormGroup>
                            <Label className="m-0 small">
                              Wall 3 Length:{" "}
                              {toFeetInchesString(exterior.porchWall3Length)}
                            </Label>
                            <Input
                              disabled
                              type="range"
                              min={12 * Math.sqrt(2)}
                              max={exterior.width}
                              value={exterior.porchWall3Length}
                              onInput={(e) =>
                                actions.setPorchWall3Length(
                                  snap(Number(e.target.value), 6)
                                )
                              }
                            />
                          </FormGroup>
                        )}
                        {exterior.porchType === porchTypes.fourWallDeluxe1 ||
                        exterior.porchType === porchTypes.fourWallDeluxe2 ? (
                          <FormGroup>
                            <Label className="m-0 small">
                              Wall 4 Length:{" "}
                              {toFeetInchesString(exterior.porchWall4Length)}
                            </Label>
                            <Input
                              type="range"
                              min={1}
                              max={exterior.height}
                              value={exterior.porchWall4Length}
                              onInput={(e) =>
                                actions.setPorchWall4Length(
                                  snap(Number(e.target.value), 6)
                                )
                              }
                            />
                          </FormGroup>
                        ) : null}
                      </>
                    )}
                </>
              )}
            </>
          ) : (
            <>
              <FormGroup>
                <Label className="m-0 small">
                  Width: {toFeetInchesString(exterior.height)}
                </Label>
                <Input
                  type="range"
                  min={1}
                  max={60 * 12}
                  value={exterior.height}
                  onInput={(e) =>
                    actions.setExtHeight(snap(Number(e.target.value), 12))
                  }
                />
              </FormGroup>
              <FormGroup>
                <Label className="m-0 small">
                  Length: {toFeetInchesString(exterior.width)}
                </Label>
                <Input
                  type="range"
                  min={1}
                  max={60 * 12}
                  value={exterior.width}
                  onInput={(e) =>
                    actions.setExtWidth(snap(Number(e.target.value), 12))
                  }
                />
              </FormGroup>
            </>
          )}
          {appState.editingPorch ? null : (
            <div>
              Porch Style:{" "}
              {
                porchTypeOptions.find((o) => o.value === exterior.porchType)
                  .label
              }
            </div>
          )}
          <div className="d-grid pt-2">
            <Button
              size="sm"
              color={appState.editingPorch ? "success" : "dark"}
              className="text-white"
              onClick={() => {
                setInfo(
                  appState.editingPorch
                    ? infoMessages.default
                    : infoMessages.editingPorch
                );
                actions.toggleEditingPorch();
              }}
            >
              <FontAwesomeIcon
                icon={appState.editingPorch ? "check" : "edit"}
                className="me-2"
              />
              {appState.editingPorch ? "Done Editing" : "Edit Porch"}
            </Button>
          </div>
        </SidebarCollapse>
      {/*) : null}*/}
      {(editingContext === editingContexts.contract || editingContext === editingContexts.inventory) 
        && exterior.porchType 
        && exterior.porchType !== porchTypes.none
        ? (<div className="pb-2">
            <Label>Porch Color:</Label>
            <Select
              options={appState.porchColors}
              value={_.find(appState.porchColors, pc => pc.value === appState.porchColorId)}
              onChange={(option) => actions.setPorchColorId(option.value)}
            />
          </div>)
        : null
      }
      <SidebarCollapse title="Add-On Settings" defaultOpen>
        <AddOnSettings />
      </SidebarCollapse>
    </div>
  );
}

export default SecondarySidebar;
