import React, {useEffect, useState} from "react";
import {Button, Col, Row, Label, Spinner} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import classnames from "classnames";
import DatePicker from 'react-datepicker';
import FloorplanTool from "../floorplan-module-2.0/FloorplanTool";
import {api, constants} from "../utils";
import {date_helpers, ui_helpers, floorplan_helpers} from "../helpers";
import Alert from "react-s-alert-v3";
import {DebounceInput} from "react-debounce-input";

function BuildOrderCreation({ addOns, inventory, releasedToBuild, getBuildSteps, refresh }) {
  const [showEditor, setShowEditor] = useState(false);
  const [buildRelease, setBuildRelease] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [inv, setInv] = useState(inventory);
  const [addOnOptions, setAddOnOptions] = useState([]);
  //For FP
  const [selectedFPAddOns, setSelectedFPAddOns] = useState([]);
  const [mfgFPAddOnOptions, setMfgFPAddOnOptions] = useState([]);
  const [optionColors, setOptionColors] = useState([]);
  const [bundleOptions, setBundleOptions] = useState([]);
  const [hasExistingFloorPlan, setHasExistingFloorPlan] = useState(false);

  useEffect(() => {
    if (!inventory || !inventory.unitManufacturerSubProductSkuId || !inventory.manufacturerId) return;
    getMfgSkuOptions();
  }, []);

  useEffect(() => {
    if (!releasedToBuild) return;
    setBuildRelease(releasedToBuild);
  }, [releasedToBuild]);

  const markBuildStepComplete = () => {
    if(!buildRelease) return;
    setIsLoading(true);
    let payload = {
      ...buildRelease,
    };
    // if(!payload.completedBy.length) {
    //     Alert.warning('You must enter the name of the person who completed this step.');
    //     return;
    // }
    payload.customerNotificationType = payload.customerNotificationType.value;
    api.post('inventory/MarkBuildStepComplete', payload).then(r => {
      if(r.data.success) {
        getBuildSteps();
        Alert.success(`${buildRelease.name} marked complete`);
      } else {
        Alert.error('There was an issue marking this build step as complete');
      }
    }).catch(err => console.error(err))
    .finally(() => setIsLoading(false));
  };

  function onDueDateChange(d) {
    const payload = {
      id: inv.id,
      d: date_helpers.formatDateForServer(d)
    };
    api.post('inventory/SetBuildDueOn', payload).then(r => {
      if (r.data.success) {
        setInv({...inv, buildDueOn: d});
      }
      else {
        Alert.error('There was an error attempting to update the due date: ' + r.data.message);
      }
    }).catch(err => {
      console.error(err);
      Alert.error('There was an error attempting to update the due date.');
    });
  }

  function onSaveNote() {
    const payload = {
      id: inv.id,
      comment: inv.orderNote
    };
    api.post('inventory/SetOrderNote', payload).then(r => {
      if (!r.data.success) {
        Alert.error('There was an error attempting to update the order note: ' + r.data.message);
      }
    }).catch(err => {
      console.error(err);
      Alert.error('There was an error attempting to update the order note.');
    });
  }

  function onDownloadBuilderWorksheet() {
    const pdfWindow = window.open();
    api.downloadAndOpenFile('inventory/GetBuilderWorksheet', {id: inv.id}, pdfWindow, (e) => {
      pdfWindow.close();
      console.error(e);
      Alert.error("There was an error attempting to get the builder worksheet");
    });
  }
    function getMfgSkuOptions() {
        if ((inventory.manufacturerBrandId
            && inventory.manufacturerBrandName !== constants.OTHER)
            && (inventory.unitManufacturerSubProductSkuId
            && inventory.unitManufacturerSubProductSkuName !== constants.OTHER)) {
            api.fetch(`Inventory/GetMfgProductSkuAddOnOptions/${inventory.manufacturerBrandId}/${inventory.unitManufacturerSubProductSkuId}`)
                .then(res => {
                    if (res.data) {
                        setAddOnOptions(res.data);
                    }
                    return;
                }).catch(api.catchHandler);
        }
        if (inventory.unitManufacturerSubProductSkuId && inventory.unitManufacturerSubProductSkuId === -10
            && inventory.manufacturerBrandId !== -10) {
            const mfgId = inventory.manufacturerBrandId
                ? inventory.manufacturerBrandId
                : inventory.manufacturerId;
            if (!mfgId) return;
            const payload = {
                activeOnly: true,
                id: mfgId,
            };
            api.post('inventory/ListManufacturerAddons', payload)
                .then(r => {
                    if (!r.data.success) return;
                    setAddOnOptions(r.data.message);
                    return;
                }).catch(api.catchHandler);
        }
    }

  function onShowEditor() {
    let mfgId = inventory && inventory.manufacturerBrandId && inventory.manufacturerBrandId !== constants.OTHER_ID
      ? inventory.manufacturerBrandId
      : null;
    let skuId = inventory.unitManufacturerSubProductSkuId && inventory.unitManufacturerSubProductSkuId !== constants.OTHER_ID
      ? inventory.unitManufacturerSubProductSkuId
      : null;
    const payload = {
      ManufacturerId: mfgId,
      SubProductSKUId: skuId,
      InventoryId: inventory.id,
      ActiveOnly: true
    };
    api.post("Reference/GetMfgListForFloorPlan", payload).then(res => {
      if (res.data.success) {
        setSelectedFPAddOns(res.data.message.selectedAddOnOptions)
        setMfgFPAddOnOptions(res.data.message.addonOptions)
        setOptionColors(ui_helpers.idNameToValueLabel(res.data.message.optionColors, null, false));
        setBundleOptions(res.data.message.bundleOptions || []);
        setHasExistingFloorPlan(res.data.message.hasExistingFloorPlan);
        let newInv = {...inv};
        if (res.data.message.mfgSku) {
          const sku = res.data.message.mfgSku;
          newInv.basePrice = sku.basePrice;
        }
        newInv.agreedPrice = res.data.message.agreedPrice;
        newInv.standardRetailPrice = res.data.message.inventory.standardRetailPrice;
        newInv.standardSkuBasePrice = res.data.message.inventory.standardSkuBasePrice;
        setInv(newInv);
      }
      setShowEditor(true);
    }).catch(err => console.error(err));
  }

  function resolveModelName() {
    return inventory.unitManufacturerSubProductSkuId && inventory.unitManufacturerSubProductSkuId !== constants.OTHER_ID
      ? inventory.unitManufacturerSubProductSkuName
      : inventory.serialNumber;
  }

  const onFPSave = (exterior, selectedAddOns, rulerOffset, fontSize, imgUrl) => {
      floorplan_helpers.saveFloorPlanChanges(
          constants.FPContext.inventory,
          exterior,
          selectedAddOns,
          fontSize,
          rulerOffset,
          imgUrl,
          null,
          null
      );
  };

  function renderFloorplan() {
    const exterior = floorplan_helpers.mapExteriorForFP(inv, constants.FPContext.inventory);
    return (<FloorplanTool
            editingContext={constants.FPContext.inventory}
            exterior={exterior}
            optionColors={optionColors}
            addOnOpts={floorplan_helpers.mapAddonOptionsForFP(mfgFPAddOnOptions, constants.FPContext.inventory)}
            selectedAddOns={floorplan_helpers.mapSelectedAddonOptionsForFP(selectedFPAddOns, constants.FPContext.inventory, exterior.height)}
            saveCallback={onFPSave}
            rulerOffset={inv.rulerOffSet || floorplan_helpers.defaultRulerOffset} //no inventory defined
            fontSize={inv.fontSize || floorplan_helpers.defaultFontSize}
            onExit={() => setShowEditor(false)}
            bundleOptions={floorplan_helpers.mapBundlesForFP(bundleOptions, constants.FPContext.inventory)}
            hasExistingFloorPlan={hasExistingFloorPlan}
            modelName={resolveModelName()}
          />);
  }

  return (
    <>
      <Row>
        <Col>
          <Button size="lg" onClick={onDownloadBuilderWorksheet} color="primary" className="float-end">
            <FontAwesomeIcon icon='download' /> Builder Worksheet
          </Button>
        </Col>
      </Row>
      <Row>
        <Col xs="4" className="pt-2">
          <Label className="float-end">Due On</Label>
        </Col>
        <Col xs="3">
          <div className="datePickerDiv">
            <DatePicker
              selected={inv.buildDueOn ? moment(inv.buildDueOn).toDate() : null}
              onChange={onDueDateChange}
              showMonthDropdown
              showYearDropdown
              isClearable
              withPortal
              dropdownMode="select"                                                                  
              placeholderText=""
            />
          </div>
        </Col>
      </Row>
      <Row className="my-2">
        <Col xs="4" className="pt-2">
          <Label className="float-end">Order Note</Label>
        </Col>
        <Col xs="4">
          <DebounceInput 
            type='text'
            min={2} 
            maxLength={150}
            className="form-control"
            value={inv.orderNote} 
            onChange={(e) => setInv({...inv, orderNote: e.target.value})} 
            onBlur={onSaveNote}
            debounceTimeout={500}
          />
        </Col>
      </Row>
      <div className="h-100 d-flex justify-content-center">
        <Row style={{clear: "both"}}>
          <Col>
            {buildRelease
            ? (<Row>
                <Col>
                  <Button
                    block
                    onClick={markBuildStepComplete}
                    color={buildRelease.completedAt ? "success" : "primary"}
                    disabled={buildRelease.completedAt ? true : false}
                    className={classnames({"text-light": buildRelease.completedAt ? true : false})}
                  >
                    {isLoading && (<Spinner size={'sm'}/>)}
                    {!isLoading && buildRelease.completedAt
                      ? (<span>
                          Build Approved <span className="text-dark"><FontAwesomeIcon icon='check' /></span>
                        </span>)
                      : null
                    }
                    {!isLoading && !buildRelease.completedAt
                      ? (<span>
                          Approve Build
                        </span>)
                      : null
                    }
                  </Button>
                </Col>
              </Row>)
            : null
            }
            <Row className={'mt-2'}>
              <Col>
                <Button size="lg" color="dark" onClick={()=>onShowEditor()}>
                  Launch Add-Ons &amp; Layout Editor{" "}
                  <FontAwesomeIcon icon="arrow-right" />
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
      {showEditor && (
        <div style={constants.popOutStyle}>
          {renderFloorplan()}
        </div>
      )}
    </>
  );
}

export default BuildOrderCreation;
