import React, { useState, Fragment } from "react";
import {DebounceInput} from "react-debounce-input";
import {
  Button,
  ButtonGroup,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Alert as RSAlert
} from "reactstrap";
import Alert from "react-s-alert-v3";
import classnames from 'classnames';
import _ from "lodash";
import { api, ui_helpers } from "../helpers";
import { Loading } from './';
import { constants } from "../utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function CloneSubproductDialog({itemList, onClose}) {
  const [items, setItems] = useState(itemList);
  const [working, setWorking] = useState(false);

  function isValid() {
    return _.every(items, x => x.skuSuffix);
  }

  function onClone() {
    setWorking(true);
    if (working) return;
    const itemList = _.map(items, x => ({sourceId: x.id, prefix: x.prefix, suffix: x.suffix, skuSuffix: x.skuSuffix}));
    api.post("manufacturer/CloneSubproducts", { items: itemList }).then(r => {
      if (r.data.success) {
        onClose();    
      } else {
        Alert.error(r.data.message);
      }
    })
    .catch(error => Alert.error('Error while cloning products: ' + error))
    .finally(() => setWorking(false));
  }

  function getNewName(item) {
    return [item.prefix || "", item.name, item.suffix || ""].join(' ');
  }

  return (
      <Modal size="lg" isOpen>
        <ModalHeader>Clone Subproducts</ModalHeader>
        <ModalBody className="pt-1">
          {working
            ? (<Loading />)
            : (<Fragment>
            <Row>
              <Col xs={12} className="mb-1">
                <RSAlert color='info' className="py-1 my-1">
                  Enter either a new subproduct prefix or suffix to make the new subproduct name unique and a <u>required</u> SKU suffix to apply for each SKU that will cloned.
                </RSAlert>
              </Col>
            </Row>
            <Row className="bg-secondary text-light mb-1">
              <Col xs={7}>Prefix - Name - Suffix</Col>
              <Col xs={3}>New Name</Col>
              <Col xs={2}>SKU Suffix</Col>
            </Row>
            {_.map(items, (s, idx) => (
            <Row key={`clone-${s.id}`} className="pb-1">
              <Col xs={7}>
                <DebounceInput
                  type='text'
                  value={s.prefix}
                  onChange={(e) => setItems(_.map(items, x => {
                    return x.id === s.id
                      ? ({...x, prefix: e.target.value})
                      : x;
                  }))}
                  min={0}
                  placeholder="Prefix"
                  maxLength="50"
                  className="form-control-sm"
                  debounceTimeout={150}
                  style={{width: '100px', paddingTop: 0, paddingBottom: 0, borderWidth: "1px", marginRight: "6px"}}
                />
                <span className="fw-bold" style={{minWidth: "10rem", textAlign: "center", display: "inline-block"}}>
                  {s.name}
                </span>
                <DebounceInput
                  type='text'
                  value={s.suffix}
                  onChange={(e) => setItems(_.map(items, x => {
                    return x.id === s.id
                      ? ({...x, suffix: e.target.value})
                      : x;
                  }))}
                  min={0}
                  placeholder="Suffix"
                  maxLength="50"
                  className="form-control-sm"
                  debounceTimeout={150}
                  style={{width: '100px', paddingTop: 0, paddingBottom: 0, borderWidth: "1px"}}
                />
              </Col>
              <Col xs={3}>
                {getNewName(s)}
              </Col>
              <Col xs={2}>
                <DebounceInput
                  type='text'
                  value={s.skuSuffix}
                  onChange={(e) => setItems(_.map(items, x => {
                    return x.id === s.id
                      ? ({...x, skuSuffix: e.target.value})
                      : x;
                  }))}
                  min={0}
                  placeholder="SKU Suffix"
                  maxLength="10"
                  className="form-control-sm"
                  debounceTimeout={150}
                  style={{width: '100px', paddingTop: 0, paddingBottom: 0, borderWidth: "1px"}}
                />
              </Col>
            </Row>))}
            </Fragment>)
          }
        </ModalBody>
        <ModalFooter style={{justifyContent: "flex-start"}}>
          <Row style={{width: "100%"}}>
            <Col xs={8}>
              {working || !isValid()
                ? (<span>&nbsp;</span>)
                : (<RSAlert color='warning' className="py-1 my-1">
                    Ensure that {items.length === 1 ? 'this item' : `each of these ${items.length} items`} is correct. This operation cannot be undone. Also note that every SKU associated with each of these products will be cloned. If you wish to clone only selected SKUs you should visit the SKU list screen instead.
                  </RSAlert>)
              }
            </Col>
            <Col xs={4}>
              <ButtonGroup className="float-end">
                {working || !isValid()
                  ? null
                  : (<Button color="primary" onClick={onClone}>Clone</Button>)
                }
                {working
                  ? null
                  : (<Button color="secondary" onClick={onClose}>Cancel</Button>)
                }
              </ButtonGroup>
            </Col>
          </Row>
        </ModalFooter>
      </Modal>    
  );
}
