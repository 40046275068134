import React, { useEffect } from "react";
import { boundingBoxOffset } from "../floorplanConstants";
import styles from "../floorplanTool.module.css";
import {
  useActions,
  useAddOns,
  useAppState,
  useControlTarget,
  useDisplaySettings,
  usePointerCoordinates,
} from "../hooks";
import useMovability from "../hooks/useMovability";
import { snap } from "../utils";
import ControlPoint from "./ControlPoint";

function getDistance(x1, y1, x2, y2, rotation) {
  let y = x2 - x1;
  let x = y2 - y1;
  if (rotation >= 180) {
    y = -1 * y;
    x = -1 * x;
  }
  const xsq = x < 0 ? -(x ** 2) : x ** 2;
  const ysq = y < 0 ? -(y ** 2) : y ** 2;
  const h = xsq + ysq;
  if (h < 0) return -Math.sqrt(Math.abs(h));
  return Math.sqrt(h);
}

function preventNegative(width) {
  if (width < 1) return 1;
  return width;
}

let startCoords = { x: 0, y: 0 };
let startProps = { width: 0, height: 0 };

function Workbench(props) {
  const [displaySettings] = useDisplaySettings();
  const [controlTarget, setControlTarget] = useControlTarget();
  const [transform, handleMove, handleRotate] = useMovability(props);
  const [appState, appStateDispatch] = useAppState();
  const [, addOnsDispatch] = useAddOns();
  const [coordinates] = usePointerCoordinates();
  const actions = useActions({ appStateDispatch, addOnsDispatch });

  useEffect(() => {
    if (
      appState.isResizingAddOn &&
      (controlTarget === "resize1-" + props.domId ||
        controlTarget === "resize3-" + props.domId)
    ) {
      const distance = getDistance(
        startCoords.x,
        startCoords.y,
        coordinates.x,
        coordinates.y,
        props.rotation
      );
      actions.updateAddOn({
        ...props,
        width: preventNegative(snap(startProps.width - distance, 12)),
      });
    }
    if (
      appState.isResizingAddOn &&
      (controlTarget === "resize2-" + props.domId ||
        controlTarget === "resize4-" + props.domId)
    ) {
      const distance = getDistance(
        startCoords.x,
        startCoords.y,
        coordinates.x,
        coordinates.y,
        props.rotation
      );
      actions.updateAddOn({
        ...props,
        width: preventNegative(snap(startProps.width + distance, 12)),
      });
    }
  }, [coordinates, controlTarget, appState.isResizingAddOn]);

  const active = controlTarget.includes(props.domId);

  function handleResizeMousedown() {
    actions.setIsResizingAddOn(true);
    startCoords = coordinates;
    startProps = props;
  }

  return (
    <g transform={transform}>
      <rect
        x={-(props.width / 2)}
        y={-(props.height / 2)}
        width={props.width}
        height={props.height}
        fill="rgba(0,0,0,0.1)"
        stroke="black"
        strokeWidth={1}
      />
      <rect
        id={props.domId}
        onMouseDown={handleMove}
        onTouchStart={handleMove}
        style={{ cursor: active ? "move" : "pointer" }}
        x={-(props.width / 2) - boundingBoxOffset}
        y={-(props.height / 2) - boundingBoxOffset}
        width={props.width + boundingBoxOffset * 2}
        height={props.height + boundingBoxOffset * 2}
        fill={active ? "rgba(0,0,255,0.05)" : "rgba(0,0,0,0)"}
        stroke={active ? "#0d6efd" : "none"}
        strokeDasharray={2}
        strokeWidth={0.5}
      />
      {props.double ? (
        <line
          x1={-(props.width / 2)}
          y1={props.height / 2 - 6}
          x2={props.width / 2}
          y2={props.height / 2 - 6}
          stroke="black"
          strokeWidth={1}
          strokeDasharray={3}
        />
      ) : null}
      <text
        className={styles.svgTxt}
        fontSize={displaySettings.fontSize}
        x={-(props.width / 2) + 4}
        y={-(props.height / 2) + displaySettings.fontSize * 1.5}
      >
        {props.name}
      </text>
      {active ? (
        <>
          <line
            x1={0}
            y1={-(props.height / 2) - boundingBoxOffset}
            x2={0}
            y2={-(props.height / 2) - boundingBoxOffset - 18}
            stroke="#0d6efd"
            strokeWidth={0.5}
          />
          <ControlPoint
            id={"rotate-" + props.domId}
            cx={0}
            cy={-(props.height / 2) - boundingBoxOffset - 18}
            onClick={() => setControlTarget("rotate-" + props.domId)}
            onMouseDown={handleRotate}
            onTouchStart={handleRotate}
          />
          <text
            x={6}
            y={-(props.height / 2) - 12}
            fill="#0d6efd"
            className={styles.svgTxt}
            fontSize={displaySettings.fontSize * 1.5}
          >
            {Math.round(props.rotation)}°
          </text>
          <ControlPoint
            id={"resize1-" + props.domId}
            cx={-props.width / 2 - boundingBoxOffset}
            cy={-props.height / 2 - boundingBoxOffset}
            onMouseDown={handleResizeMousedown}
            onTouchStart={handleResizeMousedown}
          />
          <ControlPoint
            id={"resize2-" + props.domId}
            cx={props.width / 2 + boundingBoxOffset}
            cy={-props.height / 2 - boundingBoxOffset}
            onMouseDown={handleResizeMousedown}
            onTouchStart={handleResizeMousedown}
          />
          <ControlPoint
            id={"resize3-" + props.domId}
            cx={-props.width / 2 - boundingBoxOffset}
            cy={props.height / 2 + boundingBoxOffset}
            onMouseDown={handleResizeMousedown}
            onTouchStart={handleResizeMousedown}
          />
          <ControlPoint
            id={"resize4-" + props.domId}
            cx={props.width / 2 + boundingBoxOffset}
            cy={props.height / 2 + boundingBoxOffset}
            onMouseDown={handleResizeMousedown}
            onTouchStart={handleResizeMousedown}
          />
        </>
      ) : null}
    </g>
  );
}

export default Workbench;
