import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useMemo, useState } from "react";
import { Button } from "reactstrap";
import constants from "../../utils/constants";
import useStore from "./useStore";

const { POS_SIGNATURE_TYPES: SIGNATURE_TYPES } = constants;

export default function SignatureLocation({ signatureLocation }) {
  const signatureLocations = useStore((x) => x.signatureLocations);
  const setSignatureLocations = useStore((x) => x.setSignatureLocations);
  const signatureLocationMoving = useStore((x) => x.signatureLocationMoving);

  // useEffect(() => console.log(signatureLocations), [signatureLocations]);

  const isBinary = useMemo(
    () =>
      (signatureLocation.type === SIGNATURE_TYPES.fullNameBinary ||
        signatureLocation.type === SIGNATURE_TYPES.initialBinary) &&
      signatureLocation.xPosition2 !== null &&
      signatureLocation.xPosition2 !== undefined,
    [signatureLocation]
  );

  return (
    <>
      <div
        id={"sl-" + signatureLocation?.domId}
        className={
          "d-flex align-items-end border border-3 rounded position-absolute " +
          (signatureLocationMoving?.domId === signatureLocation?.domId
            ? "border-primary"
            : "border-secondary")
        }
        style={{
          top: signatureLocation.yPosition,
          left: signatureLocation.xPosition,
          width: signatureLocation.width,
          height: signatureLocation.height,
          backgroundColor: "rgba(13, 110, 253, 0.1)",
          cursor: "grab",
        }}
      />

      {isBinary ? (
        <div
          id={"sl2-" + signatureLocation?.domId}
          className={
            "d-flex align-items-end border border-3 rounded position-absolute " +
            (signatureLocationMoving?.domId === signatureLocation?.domId
              ? "border-primary"
              : "border-secondary")
          }
          style={{
            top: signatureLocation.yPosition,
            left: signatureLocation.xPosition2,
            width: signatureLocation.width,
            height: signatureLocation.height,
            backgroundColor: "rgba(13, 110, 253, 0.1)",
            cursor: "grab",
          }}
        />
      ) : null}

      {signatureLocationMoving?.domId === signatureLocation?.domId && (
        <Button
          id="delete"
          title="Delete"
          color="danger"
          size="sm"
          className="position-absolute shadow-sm text-nowrap p-1 text-light"
          onClick={(e) => {
            e.stopPropagation();
            setSignatureLocations(
              signatureLocations.filter(
                (x) => x.domId !== signatureLocation.domId
              )
            );
          }}
          style={{
            left:
              (
                (isBinary
                  ? signatureLocation.xPosition2
                  : signatureLocation.xPosition) +
                signatureLocation.width +
                8
              ).toString() + "px",
            top: signatureLocation.yPosition,
            zIndex: 997,
          }}
        >
          <small id="delete">
            <FontAwesomeIcon id="delete" icon="trash-alt" />
          </small>
        </Button>
      )}
    </>
  );
}
