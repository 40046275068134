import React, {useContext, useEffect, useState} from "react";
import {Link, useParams} from "react-router-dom";
import _, {isNumber} from 'lodash';
import Select from "react-select";
import {
    Container,
    TabContent,
    Table,
    TabPane,
    Nav,
    NavItem,
    NavLink,
    Card,
    Button,
    CardTitle,
    CardText,
    Row,
    Col,
    Form,
    FormGroup,
    ButtonGroup,
    Label,
    Collapse,
    Input,
    CardHeader,
    CardBody, Modal, ModalBody, ModalFooter, ModalHeader, Alert
} from 'reactstrap';
import {Header, MfgAdmin, ResultHeader, Loading, SortHeader} from ".";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classnames from 'classnames';
import {constants, UserContext, api} from "../utils";

const defaultDefect = {
    id: 0,
    name: '',
    penaltyValue: 0,
    deactivatedAt: null,
    deactivatedByUserId: null,
    manufacturerId: 0
};

export default function InspectionDetailsManagement(props) {
  const params = useParams();
  const mfgId = params.mfgId
    ? params.mfgId
    : props.mfgId;
  const [showAddNew, setShowAddNew] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [defects, setDefects] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const [defect, setDefect] = useState(defaultDefect);
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState(constants.ALERT_OBJECT);
  const [sortDirection, setSortDirection] = useState('asc');
  const [sortColumn, setSortColumn] = useState('');
  const [manufacturerName, setManufacturerName] = useState('');

  useEffect(getDefects, [])

  function getDefects() {
    if (loading) return;
    setLoading(true);
    api.fetch(`Reference/ListMfgDefects/${mfgId}`).then(r => {
      if(!r.data)
      {
          setAlert({message: 'There was an error retrieving the defects.', flavor: constants.flavor.error});
          return;
      }
      setDefects(r.data.message.list);
      setManufacturerName(r.data.message.manufacturerName);
    }).catch(err => {
      console.error(err);
      setAlert({message: 'There was an error retrieving the defects.', flavor: constants.flavor.error});
    }).finally(() => setLoading(false))
  }

    const editDefect = (item) => {
      let newDefect = Object.assign({}, item);
      setDefect(newDefect);
      setIsEditing(true);
      setIsOpen(true);
    };

    const closeModal = () => {
      setIsOpen(false);
      setIsEditing(false);
      setDefect(defaultDefect);
    };

    function doSort(field, direction){
        setSortColumn(field);
        setSortDirection(direction);
    }

    const saveDefect = () => {
      if(!defect.name)
      {
          setAlert({message: 'Enter a Description if you want to save this item.', flavor: constants.flavor.warning});
          return;
      }
      if(!Number.isInteger(defect.penaltyValue))
      {
          setAlert({message: 'Penalty value must be a number', flavor: constants.flavor.warning});
          return;
      }
      let payload = Object.assign({}, defect);
      payload.manufacturerId = mfgId;
      api.post('manufacturer/SaveMfgDefect', payload).then(r => {
        if(!r.data.success)
        {
            setAlert({message: r.data.message, flavor: constants.flavor.error});
            closeModal();
            return;
        }
        getDefects();
        closeModal();
      });
    };

    const toggleDefectActive = (itemId) => {
        api.post(`manufacturer/ToggleDefectActive/${itemId}`)
            .then(r => {
                if(!r.data.success)
                {
                    setAlert({message: r.data.message, flavor: constants.flavor.error});
                    return;
                }
                getDefects();
            })
    };

    const onDefectChange = (fieldName, fieldValue) => {
      let newDefect = Object.assign({}, defect);
      newDefect[fieldName] = fieldValue;
      setDefect(newDefect);
    };

    return (<>
    <Modal isOpen={isOpen} centered>
        <ModalHeader>
            {isEditing ? 'Edit Defect' : 'Add Defect'}
        </ModalHeader>
        <ModalBody>
            <FormGroup>
                <Label>Description</Label>
                <Input type='textarea' name='name' value={ defect ? defect.name : ''}
                    onChange={(e) =>
                        onDefectChange(e.target.name, e.target.value)}/>
                <Label>Point Value</Label>
                <Input type='text' name='penaltyValue' value={defect ? defect.penaltyValue : 0}
                       onChange={(e) =>
                           onDefectChange(e.target.name, Number(e.target.value))}/>
            </FormGroup>
        </ModalBody>
        <ModalFooter>
            <Row>
                <Col>
                    <Button className='bg-secondary' onClick={() => closeModal()}>
                        Cancel
                    </Button>
                </Col>
                <Col>
                    <Button className='bg-primary' onClick={() => saveDefect()}>
                        Save
                    </Button>
                </Col>
            </Row>
        </ModalFooter>
    </Modal>
    <MfgAdmin mfgId={mfgId} tabName="INSPECTIONS">
      <Container fluid>
        <Row>
          <Col>
            <h2 className="py-2">
              <Link to={`/admin/manufacturer/${mfgId}`}>{manufacturerName}</Link> Inspection Defects
            </h2>
          </Col>
        </Row>
        <Table bordered striped>
            <thead>
                <tr>
                    <th>
                      Description
                    </th>
                    <SortHeader
                      displayName='Score'
                      field='Score'
                      sortDir={sortDirection}
                      sorted={sortColumn === 'Score'}
                      sortCallback={doSort}
                      isAlphabetic
                      centered
                    />
                  <th className='col-1'>
                    <Button className='float-end bg-success' onClick={() => setIsOpen(true)}>
                      <FontAwesomeIcon icon={'plus'}/>
                    </Button>
                  </th>
                </tr>
            </thead>
            <tbody>
            {loading ? <tr><td colSpan={3}><Loading/></td></tr>
            : defects
                    ? _.map(defects, d => {
                        return (<tr key={`d=${d.id}`}>
                            <td>{d.name}</td>
                            <td className='text-center'>{d.penaltyValue}</td>
                            <td>
                                <ButtonGroup className='float-end'>
                                    <Button className='bg-secondary' size={'sm'} onClick={() => editDefect(d)}>
                                        <FontAwesomeIcon icon={'edit'}/>
                                    </Button>
                                    {d.deactivatedAt !== null
                                        ? <Button className='bg-success' size={'sm'} onClick={() => toggleDefectActive(d.id)}>
                                            <FontAwesomeIcon icon={'heartbeat'}/>
                                        </Button>
                                    : <Button className='btn-delete' size={'sm'} onClick={() => toggleDefectActive(d.id)}>
                                            <FontAwesomeIcon icon={'trash-alt'}/>
                                        </Button>}
                                </ButtonGroup>
                                    </td>
                        </tr>)
            }) : <tr>
                <td colSpan={3}> No Defects to Show</td>
            </tr>}
            </tbody>
        </Table>
    {/*<Row className="my-3">*/}
    {/*            <Col>*/}
    {/*                <h3 className="d-flex justify-content-center my-3">Inspection Details Management</h3>*/}
    {/*            </Col>*/}
    {/*        </Row>*/}
    {/*        <Row>*/}
    {/*            <Col className="p-0">*/}
    {/*                <Button className="btn-success" onClick={() => setShowAddNew(true)}>*/}
    {/*                    New Inspection Detail{' '}*/}
    {/*                    <FontAwesomeIcon icon="plus" />*/}
    {/*                </Button>*/}
    {/*            </Col>*/}
    {/*        </Row>*/}
    {/*        {showAddNew ? (*/}
    {/*        <Row className="my-3">*/}
    {/*            <Row className="my-1">*/}
    {/*                <Col xs="2">*/}
    {/*                    <Label for="addOnDescription">Description</Label>*/}
    {/*                </Col>*/}
    {/*                <Col>*/}
    {/*                    <Input type="text" name="addOnDescription"></Input>*/}
    {/*                </Col>*/}
    {/*            </Row>*/}
    {/*            <Row className="my-1">*/}
    {/*                <Col xs="2">*/}
    {/*                    <Label for="addOnPrice">Score</Label>*/}
    {/*                </Col>*/}
    {/*                <Col>*/}
    {/*                    <Input type="text" name="addOnPrice"></Input>*/}
    {/*                </Col>*/}
    {/*            </Row>*/}
    {/*            <Row className="my-3">*/}
    {/*                <Col className="p-0">*/}
    {/*                    <Button className="btn-success" onClick={() => setShowAddNew(false)}>*/}
    {/*                        Save*/}
    {/*                    </Button>*/}
    {/*                </Col>*/}
    {/*            </Row>*/}
    {/*        </Row>*/}
    {/*        ) : null}*/}
    {/*        <Row className="my-3">*/}
    {/*            <Table borderless>*/}
    {/*                <thead>*/}
    {/*                    <tr>*/}
    {/*                        <th style={{width: "60%"}}>Description</th>*/}
    {/*                        <th style={{width: "20%"}}>Score</th>*/}
    {/*                        <th style={{width: "20%"}}></th>*/}
    {/*                    </tr>*/}
    {/*                </thead>*/}
    {/*                <tbody>*/}
    {/*                    <tr>*/}
    {/*                        <td>No H and S Tag, other craftsmanship qualities may be scored as well</td>*/}
    {/*                        <td>3</td>*/}
    {/*                        <td>*/}
    {/*                            <ButtonGroup>*/}
    {/*                                <Button color="success" onClick={() => setIsOpen(!isOpen)}>*/}
    {/*                                    <FontAwesomeIcon icon="edit" />*/}
    {/*                                </Button>*/}
    {/*                                <Button color="danger">*/}
    {/*                                    <FontAwesomeIcon icon="times-circle" />*/}
    {/*                                </Button>*/}
    {/*                            </ButtonGroup>*/}
    {/*                        </td>*/}
    {/*                    </tr>*/}
    {/*                    <tr>*/}
    {/*                    <td>*/}
    {/*                        <Collapse isOpen={isOpen}>                                */}
    {/*                            <Input type="text">*/}
    {/*                            </Input>                            */}
    {/*                        </Collapse>*/}
    {/*                    </td>*/}
    {/*                    <td>*/}
    {/*                        <Collapse isOpen={isOpen}>                                */}
    {/*                            <Input type="text">*/}
    {/*                            </Input>                                    */}
    {/*                        </Collapse>*/}
    {/*                    </td>                      */}
    {/*                    <td>*/}
    {/*                        <Collapse isOpen={isOpen}>*/}
    {/*                            <Button color="success"  onClick={() => setIsOpen(false)}>*/}
    {/*                                Save*/}
    {/*                            </Button>*/}
    {/*                        </Collapse>*/}
    {/*                    </td>*/}
    {/*                    </tr>*/}
    {/*                    <tr>*/}
    {/*                        <td>Un-clipped nails, Carelessness, Buildings paid for but not ready for inspections</td>*/}
    {/*                        <td>4</td>*/}
    {/*                        <td>*/}
    {/*                            <ButtonGroup>*/}
    {/*                                <Button color="success">*/}
    {/*                                    <FontAwesomeIcon icon="edit" />*/}
    {/*                                </Button>*/}
    {/*                                <Button color="danger">*/}
    {/*                                    <FontAwesomeIcon icon="times-circle" />*/}
    {/*                                </Button>*/}
    {/*                            </ButtonGroup>*/}
    {/*                        </td>*/}
    {/*                    </tr>*/}
    {/*                    <tr>*/}
    {/*                        <td>Doors or windows not properly aligned/bolt not operating properly</td>*/}
    {/*                        <td>5</td>*/}
    {/*                        <td>*/}
    {/*                            <ButtonGroup>*/}
    {/*                                <Button color="success">*/}
    {/*                                    <FontAwesomeIcon icon="edit" />*/}
    {/*                                </Button>*/}
    {/*                                <Button color="danger">*/}
    {/*                                    <FontAwesomeIcon icon="times-circle" />*/}
    {/*                                </Button>*/}
    {/*                            </ButtonGroup>*/}
    {/*                        </td>*/}
    {/*                    </tr>*/}
    {/*                    <tr>*/}
    {/*                        <td>Un-caulked windows, doors, excessive caulk beading</td>*/}
    {/*                        <td>5</td>*/}
    {/*                        <td>*/}
    {/*                            <ButtonGroup>*/}
    {/*                                <Button color="success">*/}
    {/*                                    <FontAwesomeIcon icon="edit" />*/}
    {/*                                </Button>*/}
    {/*                                <Button color="danger">*/}
    {/*                                    <FontAwesomeIcon icon="times-circle" />*/}
    {/*                                </Button>*/}
    {/*                            </ButtonGroup>*/}
    {/*                        </td>*/}
    {/*                    </tr>*/}
    {/*                    <tr>*/}
    {/*                        <td>Un-nailed flooring, Lofts, plates, Gaps in flooring</td>*/}
    {/*                        <td>5</td>*/}
    {/*                        <td>*/}
    {/*                            <ButtonGroup>*/}
    {/*                                <Button color="success">*/}
    {/*                                    <FontAwesomeIcon icon="edit" />*/}
    {/*                                </Button>*/}
    {/*                                <Button color="danger">*/}
    {/*                                    <FontAwesomeIcon icon="times-circle" />*/}
    {/*                                </Button>*/}
    {/*                            </ButtonGroup>*/}
    {/*                        </td>*/}
    {/*                    </tr>*/}
    {/*                </tbody>*/}
    {/*            </Table>*/}
    {/*        </Row>*/}
        </Container>
      </MfgAdmin>
  </>);
}