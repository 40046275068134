import React, { useMemo } from "react";
import { Button } from "reactstrap";
import { useAppStore } from "../hooks";
import { BsFillArrowRightCircleFill } from "react-icons/bs";
import classNames from "classnames";

export default function PageNumberDisplay({isVisible}) {
  const numPages = useAppStore((state) => state.numPages);
  const currentPageIndex = useAppStore((x) => x.currentPageIndex);
  const signatureLocations = useAppStore((x) => x.signatureLocations);
  const confirmModalOpen = useAppStore((x) => x.confirmModalOpen);
  const setConfirmModalOpen = useAppStore((x) => x.setConfirmModalOpen);

  const isDisabled = useMemo(
    () =>
      signatureLocations.filter((x) => x.img || x.img2).length !==
      signatureLocations.length,
    [signatureLocations]
  );

  return (
    <>
      <div
        className={classNames("bg-light position-fixed shadow fw-bold p-3 border text-center w-100 justify-content-between align-items-center", isVisible ? 'd-flex' : 'd-none')}
        style={{ bottom: 0, left: 0, zIndex: 998 }}
      >
        Page {currentPageIndex + 1}/{numPages}
        <div>
          <Button
            size="lg"
            color={isDisabled ? "secondary" : "primary"}
            disabled={isDisabled}
            onClick={() => setConfirmModalOpen(true)}
          >
            Submit <BsFillArrowRightCircleFill className="ms-2" />
          </Button>
        </div>
      </div>
    </>
  );
}
