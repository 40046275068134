import React, {Component, Fragment, useState, useEffect} from 'react';
import { Link, Redirect } from "react-router-dom";
import { Row, Col, Container, Label, Input, Card, CardHeader, CardBody, ButtonGroup, Button } from "reactstrap";
import { api, ui_helpers, constants } from "../helpers";
import ToggleButton from "react-toggle-button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { UserAddEdit, DropZone } from "../components";
import Select from "react-select";
import _ from "lodash";
import Alert from "react-s-alert-v3";

export default function ManufacturerAddEdit(props) {
    const [companies, setCompanies] = useState([]);
    const [states, setStates] = useState([]);
    const [stores, setStores] = useState([]);
    const [cashSaleHeaderImage, setCashSaleHeaderImage] = useState(null);
    const [manufacturer, setManufacturer] = useState(constants.EMPTY_MFG);
    const [redirectTo, setRedirectTo] = useState();
    const [stickyMessage, setStickyMessage] = useState();
    const [newUser, setNewUser] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [newUserDefinition, setNewUserDefinition] = useState();

    useEffect(() => {
        let apiCalls = [];
        apiCalls.push(getStores());
        apiCalls.push(getRegions());
        apiCalls.push(getCompanies());
        setIsLoading(true);
        Promise.all(apiCalls)
            .then()
            .finally(() => setIsLoading(false));
        getStores();

    }, []);

    function getStores() {
        api.fetch("User/GetDealers")
            .then(r => {
                if(!r.data.success) return;
                setStores(ui_helpers.idNameToValueLabel(r.data.data));
            })
            .catch(api.catchHandler);
    }

    function getRegions() {
        api.fetch("reference/RegionList")
            .then(r => {
                if(!r.data.success) return;
                setStates(ui_helpers.idNameToValueLabel(r.data.data));
            })
            .catch(api.catchHandler);
    }

    function getCompanies() {
        api.fetch("company/Companies")
            .then(r => {
                if(!r.data.success) return;
                setCompanies(ui_helpers.idNameToValueLabel(r.data.data));
            })
            .catch(api.catchHandler);
    }

    function handleStores(store) {
        setManufacturer({
            ...manufacturer,
            stores: store
        });
    }

    function handleManufacturer(fieldName, value) {
        const updatedMfg = newManufacturerState(manufacturer, fieldName, value);
        setManufacturer(updatedMfg);
    }

    function handleCompany(fieldName, fieldValue) {
        let newMfg = _.cloneDeep(manufacturer);
        newMfg.company = {
            ...manufacturer.company,
            [fieldName]: fieldValue,
        };

        setManufacturer(newMfg);
    }

    function onBlur(value, fieldName) {
        let newMfg = {
            ...manufacturer,
            [fieldName]: value
        };
        setManufacturer(newMfg);
    }

    function onUsersChange(user) {
        setNewUserDefinition(user);
    }

    function saveManufacturerDetails() {
        let vm = Object.assign({}, manufacturer);
        let mfgWarnings = ui_helpers.validateMfg(vm);
        if (!vm.isStore && (!vm.stores || vm.stores.length === 0)) {
            mfgWarnings.push("Select at least 1 associated store for this manufacturer in order to save.");
        }
        if (vm.isOwner && vm.enableCashSales) {
            if (vm?.company && vm.company.id > 0 && !vm.company.cashSaleHeaderImageExisted && !cashSaleHeaderImage) {
                mfgWarnings.push("Either provide a cash sale header image or choose to disable cash sales.");
            }
            if (vm?.company && vm.company.id < 0 && !cashSaleHeaderImage) {
                mfgWarnings.push("Either provide a cash sale header image or choose to disable cash sales.");
            }
        }
        if (!vm.id && vm.isOwner && (!vm.company?.states || vm.company?.states.length === 0)) {
            mfgWarnings.push("Select at least 1 state in which this manufacturer does business.");
        }
        // if(vm.enableCustomSerialGeneration && serialNumber)
        //     if (mfgWarnings.length) {
        //         Alert.warning(mfgWarnings.join(' '));
        //         // props.setMessage({message: mfgWarnings.join(' '), flavor: constants.flavor.error});
        //         return null;
        //     }
        if (mfgWarnings.length) {
            Alert.warning(mfgWarnings.join(' '));
            return;
        }
        vm.associatedDealers = _.map(vm.stores, x => ({
            "id": x.value,
            "name": x.label
        }));
        if (vm.isOwner)
        {
            vm.company = Object.assign({}, vm.company);
        }
        vm = ui_helpers.prepareMfgPayload(vm);
        if (vm.isOwner)
        {
            vm = ui_helpers.prepareMfgCompanyPayload(vm);
        }
        //vm.defaultManufacturerUnitTypeId = -10;
        setIsLoading(true);
        api.post("manufacturer/SaveManufacturerDetails", vm).then(response => {
            let newMfg = ui_helpers.shapeMfg(response.data.data, companies);
            if (response.data.success) {
                if (vm.isOwner && cashSaleHeaderImage) {
                    api.post_form_data('Company/SaveHeader',
                        ui_helpers.buildCaptiveHeaderPayload(newMfg.companyId, cashSaleHeaderImage))
                        .then(r => {
                            if (!r.data.success) {
                                Alert.warning("There was an error saving the cash sale header image")
                            }
                            setManufacturer(newMfg);
                            setCashSaleHeaderImage(null);
                            Alert.success(`The new manufacturer named ${newMfg.name} has been created.`);

                            window.setTimeout(props.onSaveCallback(true), 3000);
                        }).catch(api.catchHandler);
                } else {
                    setManufacturer(newMfg);
                    setCashSaleHeaderImage(null);
                    Alert.success(`The new manufacturer named ${newMfg.name} has been created.`);
                    window.setTimeout(props.onSaveCallback(true), 3000);
                }
            }
            else
            {
                Alert.error(response.data.message);
                return;
            }
            if (newUser) {
                let user = Object.assign({}, newUserDefinition);
                user.roles = ui_helpers.addAllMfgRoles(user.roles, newMfg.id, newMfg.name);
                if (newMfg.dealerId !== null)
                {
                    vm.associatedDealers.push({
                        id: newMfg.dealerId,
                        name: newMfg.name
                    });
                }
                _.each(vm.associatedDealers, x => {
                    if (!_.some(user.roles, r => r.typeOfUserRole === constants.ROLE_IDS.ContractViewer && r.dealerId === x.id))
                    {
                        user.roles.push({
                            typeOfUserRole: constants.ROLE_IDS.ContractViewer,
                            companyId: null,
                            companyName: null,
                            dealerId: x.id,
                            dealerName: x.name,
                            manufacturerId: null,
                            manufacturerName: null
                        });
                    }
                });
                api.post("User/Save", ui_helpers.userPayload(user)).then(r => {
                  if (!r.data || !r.data.success) {
                    Alert.warning("There was an error saving the new user: " + r.data?.message);
                  }
                });
            }
            // only hide edit immediately if we aren't showing a message
            if (!vm.isOwner) {
                props.onSaveCallback();
            }
        }).finally(() => setIsLoading(false))
            .catch(api.catchHandler);
    }

    function toggleMfgOwner(value) {
        const newValue = !value;
        let company = null;
        if (newValue) {
            const oldMfgState = {
                ...manufacturer,
                isOwner: true,
                company: Object.assign({}, constants.EMPTY_COMPANY)
            };

            const newMfgState = newManufacturerState(oldMfgState, 'address1', manufacturer.address1);
            company = newMfgState.company;
        }
        setManufacturer({
            ...manufacturer,
            isOwner: newValue,
            company: company
        });
    }

    function toggleMfgStore(value) {
        const newValue = !value;
        setManufacturer({
           ...manufacturer,
            isStore: newValue
        });
    }

    const newManufacturerState = (oldState, fieldName, value) => {
        const priorFieldValue = manufacturer[fieldName];
        let newMfg = {
            ...manufacturer,
            [fieldName]: value
        };
        // default mfg fields if appropriate
        if (newMfg.isOwner && ['address1', 'address2', 'city', 'state', 'zip', 'phone', 'fax'].includes(fieldName)) {
            // newMfg.company = Object.assign({}, newMfg.company);
            switch(fieldName) {
                case 'address1':
                    if (newMfg.company && (!newMfg.company.remitTo1 || newMfg.company.remitTo1 === priorFieldValue)) {
                        newMfg.company.remitTo1 = value;
                    }
                    break;
                case 'address2':
                    if (newMfg.company && (!newMfg.company.remitTo2 || newMfg.company.remitTo2 === priorFieldValue)) {
                        newMfg.company.remitTo2 = value;
                    }
                    break;
                case 'city':
                case 'state':
                case 'zip':
                    const priorCityStateZip = `${manufacturer.city}, ${manufacturer.state} ${manufacturer.zip}`;
                    if (newMfg.company && (!newMfg.company.remitTo3 || newMfg.company.remitTo3 === priorCityStateZip)) {
                        newMfg.company.remitTo3 = `${newMfg.city}, ${newMfg.state} ${newMfg.zip}`;
                    }
                    break;
                case 'phone':
                case 'fax':
                    const priorPhoneFax = `${manufacturer.phone}  Fax ${manufacturer.fax}`;
                    if (newMfg.company && (!newMfg.company.remitTo4 || newMfg.company.remitTo4 === priorPhoneFax)) {
                        newMfg.company.remitTo4 = `${newMfg.phone}  Fax ${newMfg.fax}`;
                    }
                    break;
            }
        }
        return newMfg;
    };

    return (
        <Container fluid>
            <Card>
                <CardHeader>
                    <Row>
                        <Col>
                            <h3>{manufacturer ? manufacturer.name : "New Manufacturer"} Details</h3>
                        </Col>
                        <Col className={'d-flex justify-content-end'}>
                                <Button
                                    size="sm"
                                    className="btn-success cf-success me-2"
                                    style={{ color: "white" }}
                                    onClick={() => setNewUser(!newUser)}>
                                    <FontAwesomeIcon icon={newUser ? "minus" : "plus"} />{" "}
                                    User
                                </Button>
                                <ButtonGroup className={'float-end'}>

                                    <Button
                                        size="sm"
                                        className="btn-success cf-primary"
                                        style={{ color: "white" }}
                                        onClick={saveManufacturerDetails}
                                    >
                                        <FontAwesomeIcon icon="save" />{" "}
                                        Save
                                    </Button>
                                    <Button
                                        size="sm"
                                        className="btn-secondary"
                                        onClick={props.onSaveCallback}
                                    >
                                        <FontAwesomeIcon icon="times-circle" />{" "}
                                        Close
                                    </Button>
                                </ButtonGroup>
                            </Col>
                    </Row>
                </CardHeader>
                <CardBody>
                    <Row>
                        <Col>
                            <Label>Manufacturer Name</Label>
                            <Input
                                type="text"
                                name="name"
                                value={manufacturer.name}
                                onBlur={(e) => onBlur(e.target.value, 'name')}
                                onChange={(event) =>
                                    handleManufacturer(event.target.name, event.target.value)
                                }
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Row>
                                <Col>
                                    <Label>Address 1</Label>
                                    <Input
                                        type="text"
                                        name="address1"
                                        value={manufacturer.address1}
                                        onChange={(event) =>
                                            handleManufacturer(event.target.name, event.target.value)
                                        }
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Label>Address 2</Label>
                                    <Input
                                        type="text"
                                        name="address2"
                                        value={manufacturer.address2}
                                        onChange={(event) =>
                                            handleManufacturer(event.target.name, event.target.value)
                                        }
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Label>City</Label>
                                    <Input
                                        type="text"
                                        name="city"
                                        value={manufacturer.city}
                                        onChange={(event) =>
                                            handleManufacturer(event.target.name, event.target.value)
                                        }
                                    />
                                </Col>
                                <Col>
                                    <Label>State</Label>
                                    <Input
                                        type="text"
                                        name="state"
                                        value={manufacturer.state}
                                        onChange={(event) =>
                                            handleManufacturer(event.target.name, event.target.value)
                                        }
                                    />
                                </Col>
                                <Col>
                                    <Label>Zip Code</Label>
                                    <Input
                                        type="text"
                                        name="zip"
                                        value={manufacturer.zip}
                                        onChange={(event) =>
                                            handleManufacturer(event.target.name, event.target.value)
                                        }
                                    />
                                </Col>
                            </Row>
                        </Col>
                        <Col>
                            <Row className="mt-3">
                                <Col>
                                    <Label>Hide Material Selection?</Label>
                                    <ToggleButton
                                        value={manufacturer.hideManufacturerUnitTypeSelection}
                                        activeLabel={"Yes"}
                                        inactiveLabel={"No"}
                                        onToggle={(value) => handleManufacturer('hideManufacturerUnitTypeSelection', !value)}
                                    />
                                </Col>
                                <Col>
                                    <Label>Enable Inventory</Label>
                                    <ToggleButton
                                        value={manufacturer.enableInventory}
                                        activeLabel={"Yes"}
                                        inactiveLabel={"No"}
                                        onToggle={(value) => handleManufacturer('enableInventory', !value)}
                                    />
                                </Col>
                                <Col>
                                    <Label>Enable Retail Invoice</Label>
                                    <ToggleButton
                                        value={manufacturer.enableShippingInvoice}
                                        activeLabel={"Yes"}
                                        inactiveLabel={"No"}
                                        onToggle={(value) => handleManufacturer('enableShippingInvoice', !value)}
                                    />
                                </Col>
                            </Row>
                            <Row className="mt-3">
                                <Col sm="4">
                                    <Label>Direct Sales</Label>
                                    <ToggleButton
                                        value={manufacturer.isStore}
                                        activeLabel={"Yes"}
                                        inactiveLabel={"No"}
                                        onToggle={(value) => toggleMfgStore(value)}
                                    />
                                </Col>
                                {manufacturer.isStore
                                  ? (<Col sm="4">
                                        <Label>90 Days SAC</Label>
                                        <ToggleButton
                                            value={manufacturer.SAC}
                                            activeLabel={"Yes"}
                                            inactiveLabel={"No"}
                                            onToggle={(value) => handleManufacturer('SAC', !value)}
                                        />
                                    </Col>)
                                  : (<Col sm="4"></Col>)
                                }
                                <Col sm="4">
                                    <Label>Owner Manufacturer</Label>
                                    {manufacturer.id
                                      ? (manufacturer.isOwner ? ui_helpers.greenCheck() : ui_helpers.redX())
                                      : (<ToggleButton
                                          value={manufacturer.isOwner}
                                          activeLabel={"Yes"}
                                          inactiveLabel={"No"}
                                          onToggle={(value) => toggleMfgOwner(value)}
                                        />)
                                    }
                                </Col>
                            </Row>
                            {manufacturer.isOwner
                                ? (<Row className="mt-3">
                                    {/*                                        <Col sm="4">
                                            <Label>Allows RTO Contracts</Label>
                                            <ToggleButton
                                                value={this.state.manufacturer.enableRTOContracts}
                                                activeLabel={"Yes"}
                                                inactiveLabel={"No"}
                                                onToggle={(value) => this.updateManufacturerState('enableRTOContracts', !value)}
                                            />
                                        </Col>
*/}                                        <Col sm="4">
                                    <Label>Allows Cash Sales</Label>
                                    <ToggleButton
                                        value={manufacturer.enableCashSales}
                                        activeLabel={"Yes"}
                                        inactiveLabel={"No"}
                                        onToggle={(value) => {
                                            setManufacturer({
                                                ...manufacturer,
                                                enableCashSales: !value
                                            });
                                        }}
                                    />
                                </Col>
                                    {manufacturer.enableCashSales
                                        ? (<Col sm="4">
                                            <Label>Accept Credit Card Payments</Label>
                                            <ToggleButton
                                                value={manufacturer.enableCreditCardPayments}
                                                activeLabel={"Yes"}
                                                inactiveLabel={"No"}
                                                onToggle={(value) => {
                                                    setManufacturer({
                                                        ...manufacturer,
                                                        enableCreditCardPayments: !value,
                                                    });
                                                }}
                                            />
                                        </Col>)
                                        : null
                                    }
                                </Row>)
                                : null
                            }

                        </Col>
                    </Row>
                    <Row>
                        <Col sm="3">
                            <Label>Phone Number</Label>
                            <Input
                                type="text"
                                name="phone"
                                value={manufacturer.phone}
                                onChange={(event) =>
                                    handleManufacturer(event.target.name, event.target.value)
                                }
                            />
                        </Col>
                        <Col sm="3">
                            <Label>Fax Number</Label>
                            <Input
                                type="text"
                                name="fax"
                                value={manufacturer.fax}
                                onChange={(event) =>
                                    handleManufacturer(event.target.name, event.target.value)
                                }
                            />
                        </Col>
                        <Col sm="2">
                            <Label>Inv Wholesale Discount %</Label>
                            <Input
                                type="text"
                                name="inventoryWholesaleDiscountPercent"
                                value={manufacturer.inventoryWholesaleDiscountPercent}
                                onChange={(event) =>
                                    handleManufacturer(event.target.name, event.target.value)
                                }
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Label>Associated Stores</Label>
                            <Select
                                options={stores}
                                value={manufacturer.stores}
                                onChange={(option) => handleStores(option)}
                                isMulti={true}
                                hideSelectedOptions={false}
                            />
                        </Col>
                    </Row>
                    {manufacturer.isOwner
                        ? (<React.Fragment>
                            <Row className="pt-3">
                                <Col>
                                    <h4>Owner Company Information</h4>
                                    <Row>
                                        <Col>
                                            <Label>Address 1</Label>
                                            <Input
                                                type="text"
                                                name="remitTo1"
                                                maxLength="100"
                                                value={manufacturer.company?.remitTo1}
                                                onChange={(e) => handleCompany(e.target.name, e.target.value)}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <Label>Address 2</Label>
                                            <Input
                                                type="text"
                                                name="remitTo2"
                                                maxLength="100"
                                                value={manufacturer.company?.remitTo2}
                                                onChange={(e) => handleCompany(e.target.name, e.target.value)}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <Label>City State, Zip</Label>
                                            <Input
                                                type="text"
                                                name="remitTo3"
                                                maxLength="100"
                                                value={manufacturer.company?.remitTo3}
                                                onChange={(e) => handleCompany(e.target.name, e.target.value)}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <Label>Phone Number, Fax Number, Email</Label>
                                            <Input
                                                type="text"
                                                name="remitTo4"
                                                maxLength="100"
                                                value={manufacturer.company?.remitTo4}
                                                onChange={(e) => handleCompany(e.target.name, e.target.value)}
                                            />
                                        </Col>
                                    </Row>
                                    {/*                                        {this.state.manufacturer.enableRTOContracts
                                            ? (<Row className="pt-3">
                                                <Col sm="4">
                                                    <Label>RTO Processing Company</Label>
                                                    <Select
                                                        value={mfgCompany.rtoProcessingCompany}
                                                        options={_.filter(this.state.companies, c => c.value !== this.state.manufacturer.company?.id)}
                                                        onChange={(option) => {
                                                            this.setState({
                                                                manufacturer: {
                                                                    ...this.state.manufacturer,
                                                                    company: {
                                                                        ...this.state.manufacturer.company,
                                                                        rtoProcessingCompany: option
                                                                    }
                                                                }
                                                            });
                                                        }}
                                                    />
                                                </Col>
                                            </Row>)
                                            : null
                                        }
*/}                                    </Col>
                                <Col>
                                    {!manufacturer.id ? (<Row>
                                            <Col xs="12">
                                                <Label>States</Label>
                                                <Select
                                                    closeMenuOnSelect={false}
                                                    isMulti={true}
                                                    isClearable={true}
                                                    onBlurResetsInput={false}
                                                    onSelectResetsInput={false}
                                                    onCloseResetsInput={false}
                                                    classNamePrefix="react-select"
                                                    options={states}
                                                    value={manufacturer.company?.states}
                                                    onChange={(selections) => {
                                                        let values = [];
                                                        if (selections && selections.length) {
                                                            values = selections;
                                                        }
                                                        handleCompany('states', values)
                                                    }}
                                                />
                                            </Col>
                                        </Row>)
                                        : null
                                    }
                                    {manufacturer.enableCashSales && manufacturer.enableCreditCardPayments ?
                                        (<Fragment>
                                            <h4>Cash Sale Credit Card Processing</h4>
                                            <Row>
                                                <Col>
                                                    <Label>Payment Processor API Service</Label>
                                                    <Select
                                                        value={manufacturer.company?.paymentProcessor}
                                                        options={constants.paymentProcessorTypes}
                                                        onChange={(option) => {
                                                            handleCompany('paymentProcessor', option);
                                                        }}
                                                    />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <Label>API Key</Label>
                                                    <Input
                                                        type="text"
                                                        name="apiKey"
                                                        value={manufacturer.company?.apiKey}
                                                        onChange={(e) => handleCompany(e.target.name, e.target.value)}
                                                    />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <Label>Transaction Key</Label>
                                                    <Input
                                                        type="text"
                                                        name="transactionKey"
                                                        value={manufacturer.company?.transactionKey}
                                                        onChange={(e) => handleCompany(e.target.name, e.target.value)}
                                                    />
                                                </Col>
                                            </Row>
                                        </Fragment>) : null
                                    }
                                </Col>
                            </Row>
                            {manufacturer.enableCashSales
                                ? (<Row className="mt-3">
                                    <Col xs="6">
                                        <h4>Cash Sale Header Image</h4>
                                        <DropZone
                                            acceptTypes={["image/png"]}
                                            hideCancel
                                            contentTypeId={constants.CASH_SALE_INVOICE_TEMPLATE_ID}
                                            captiveContext={{
                                                isDefault: true,
                                                regionId: 0,
                                                cultureId: constants.CULTURE_ENGLISH_ID
                                            }}
                                            onSave={(file) => setCashSaleHeaderImage(file)}
                                            companyId={manufacturer.company?.id}
                                            message1="Template headers must be PNG format."
                                            message2="Preferred width is 1388px wide x 258px high or at least this ratio of Width to Height and smaller."
                                            //message1="Logos must be PNG format."
                                            //message2="Preferred width is 167px wide x 75px high or at least this ratio of Width to Height."
                                        />
                                    </Col>
                                </Row>)
                                : null
                            }
                        </React.Fragment>)
                        : null
                    }
                </CardBody>
                <CardBody>
                    {newUser && (
                        <UserAddEdit
                            currentUser={props.currentUser}
                            returnRoute=""
                            computedMatch={props.computedMatch}
                            manufacturer
                            manufacturerDto={manufacturer}
                            onUsersChange={onUsersChange}
                            cancel={() => setNewUser(false)}
                        />
                    )}
                </CardBody>
            </Card>
        </Container>
    );
}