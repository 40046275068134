import React, { useContext, useEffect, useState } from "react";
import {Link, Redirect} from "react-router-dom";
import {
  Row,
  Col,
  Table,
  Container,
  Card,
  CardHeader,
  CardBody,
  Button,
  ButtonGroup,
} from "reactstrap";
import { ui_helpers, filter_helpers } from "../helpers";
import {
  TextFilter,
  CompanyAddEdit,
  AdminHeader,
  FilterSearch,
  Footer, FilterSwitch,
} from "../components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { UserContext, api, constants } from "../utils";
import _ from "lodash";
import Alert from "react-s-alert-v3";

export default function Companies(props) {
  const userCtx = useContext(UserContext);
  const [pagedList, setPagedList] = useState({
    pageNumber: 1,
    totalCount: 1,
  });
  const [sortColumn, setSortColumn] = useState("Id");
  const [companies, setCompanies] = useState([]);
  const [sortAscending, setSortAscending] = useState(true);
  const [addEditCompany, setAddEditCompany] = useState(false);
  const [filters, setFilters] = useState([{filterName: "ActiveOnly", value: true, filteredDisplay: "Active Only"}]);
  const [redirect, setRedirect] = useState('');

  useEffect(() => {
    refreshList();
  }, [filters]);

  function refreshList(
    sortedColumn,
    sortedAscending,
    sortedFilters,
    pageNumber
  ) {
    const resolvedFilters = sortedFilters || filters;
    const resolvedSort = {
      sortedColumn: sortedColumn || sortColumn,
      sortAscending: sortedAscending || sortAscending,
    };
    const payload = {
      activeOnly: null,
      includeCaptiveCompanies: true,
      name: null,
      ...resolvedSort,
      ...filter_helpers.filtersToObject(resolvedFilters),
      pageSize: 100,
      page: pageNumber || (pagedList ? pagedList.pageNumber : 1),
    };
    api
      .post("Company/List", payload)
      .then((r) => {
        if (!r.data.success) {
          Alert.error("There was an error loading the Company list");
        }
        setCompanies(r.data.data.items);
        setPagedList({
          page: r.data.data.pageNumber,
          totalItems: r.data.data.totalCount,
        });
        sortedColumn ? setSortColumn(sortedColumn) : setSortColumn(sortColumn);
        sortedAscending
          ? setSortAscending(sortedAscending)
          : setSortAscending(sortAscending);
        // setFilters(resolvedFilters);
      })
      .catch((error) =>
        Alert.error("There was an error loading the company list")
      );
  }

  function clearFilters() {
    setFilters([]);
    refreshList(null, null, filters);
  }

  function toggleAddEditCompany() {
    setAddEditCompany(!addEditCompany);
    refreshList();
  }

  function onChangeFilter(changedFilter) {
    const newFilters = filter_helpers.getFilters(filters, changedFilter);
    setFilters(newFilters);
    refreshList(null, null, newFilters, 1);
  }

  function activateCompany(companyId) {
    const company = _.find(companies, (x) => x.id === companyId);
    api
      .post(`company/ActivateCompany/${companyId}`)
      .then((response) => {
        refreshList();
      })
      .catch((error) =>
        Alert.error("There was an error activating the store.")
      );
  }

  function deactivateCompany(companyId) {
    const company = _.find(companies, (x) => x.id === companyId);
    api
      .post(`company/DeactivateCompany/${companyId}`)
      .then((response) => {
        refreshList();
      })
      .catch((error) =>
        Alert.error("There was an error activating the store.")
      );
  }

  useEffect(() => {
    if (ui_helpers.isSystemAdministrator(userCtx.currentUser) || ui_helpers.isAnyCompanyAdministrator(userCtx.currentUser)) return;
    setRedirect('/');
  }, []);

  if(redirect) {
    return <Redirect to={redirect}/>
  }

  return (
    <>
      <AdminHeader companies />
      <Container fluid>
        <Row>
          <Col>
          </Col>
          <Col>
          { addEditCompany ?
          <Col>{<div className="m-5"></div>}</Col>
          : <FilterSearch filters={filters} onClear={clearFilters}>
            <Row>
              <Col xs="4">
                <TextFilter
                    filterName='name'
                    displayName='Company Name'
                    onChangeCallback={onChangeFilter}
                    value={filter_helpers.get_value('name', filters)}
                />
                {/*<TextFilter*/}
                {/*  filterName="name"*/}
                {/*  displayName="Company Name"*/}
                {/*  descriptionPrefix="contains"*/}
                {/*  onChangeCallback={onChangeFilter}*/}
                {/*/>*/}
              </Col>
              <Col xs="4">
                <FilterSwitch
                    filterName="ActiveOnly"
                    displayName="Active Only"
                    onChangeCallback={onChangeFilter}
                    value={filter_helpers.get_value("ActiveOnly", filters)}
                />
                {/*<ActiveToggleFilter*/}
                {/*  filterName="activeOnly"*/}
                {/*  displayName="Active Only?"*/}
                {/*  onChangeCallback={onChangeFilter}*/}
                {/*/>*/}
              </Col>
            </Row>
          </FilterSearch> }
          </Col>
        </Row>

        <Row className="g-0">
          <Col>
            <Card style={{ borderColor: "transparent" }}>
              <CardHeader style={{ borderColor: "transparent", marginTop:"30px" }}>
                <Row>
                  <Col>
                    <h2 className="page-title">Companies</h2>
                  </Col>
                  <Col>
                    <ButtonGroup className="float-end">
                      {ui_helpers.isSystemAdministrator(userCtx.currentUser) ? (
                        <Button
                          size="sm"
                          className="btn-success cf-success buttonGroupSpacing"
                          style={{ color: "white" }}
                          onClick={() => toggleAddEditCompany()}
                        >
                          {addEditCompany ? 
                          <FontAwesomeIcon icon='minus' />
                          : <FontAwesomeIcon icon="plus" />} {" "}
                          Company
                        </Button>
                      ) : null}
                      <Link to="/">
                        <Button size="sm" className="btn-dark">
                          <FontAwesomeIcon icon="home" />
                        </Button>
                      </Link>
                    </ButtonGroup>
                  </Col>
                </Row>
                <Row className="rowSpacing">
                  <Col></Col>
                </Row>
              </CardHeader>
            </Card>
          </Col>
        </Row>
        <Row className="rowSpacing">
          <Col>
            <Card style={{ borderColor: "transparent" }}>
              <CardBody className="pt-0 rowSpacing">
                {addEditCompany ? (
                  <CompanyAddEdit onSaveCallback={toggleAddEditCompany} />
                ) : (
                  <Table size="sm" bordered striped>
                    <thead className="rowSpacing">
                      <tr>
                        <th>Company Name</th>
                        <th>Address 1</th>
                        <th>Address 2</th>
                        <th>Address 3</th>
                        <th>Phone Number</th>
                        <th />
                      </tr>
                    </thead>
                    <tbody>
                      {companies ? (
                        _.map(companies, (c) => (
                          <tr key={c.id}>
                            <td style={{display: "flex", flexDirection: "row"}}>
                              <Link
                                to={`${constants.PATH_NAMES.COMPANY_ADMIN}/${c.id}`}
                              >
                                {c.name}
                              </Link>
                              {c.captiveToManufacturerId != null ? (
                                <div className="badge bg-info" style={{marginInline: 5, alignItems: "center"}}>
                                  Manufacturer Owner
                                </div>
                              ) : null}
                              {c.captiveToStoreId != null ? (
                                <div className="badge bg-success" style={{marginInline: 5, alignItems: "center"}}>
                                  Store Owner
                                </div>
                              ) : null}
                            </td>
                            <td>{c.remitTo1}</td>
                            <td>{c.remitTo2}</td>
                            <td>{c.remitTo3}</td>
                            <td>{c.remitTo4}</td>
                            <td>
                              {c.deactivatedAt === null ? (
                                <Button
                                  size="sm"
                                  className="float-end btn-delete"
                                  onClick={() => deactivateCompany(c.id)}
                                >
                                  <FontAwesomeIcon icon="trash-alt" />
                                </Button>
                              ) : (
                                <Button
                                  size="sm"
                                  className="float-end bg-success border-success"
                                  onClick={() => activateCompany(c.id)}
                                >
                                  <FontAwesomeIcon icon={"heartbeat"} />
                                </Button>
                              )}
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td>No Companies to Show.</td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
      <Footer/>
    </>
  );
}
