import { boundingBoxOffset, swingTypes } from "../floorplanConstants";
import styles from "../floorplanTool.module.css";
import { useControlTarget, useDisplaySettings } from "../hooks";
import useMovability from "../hooks/useMovability";
import ControlPoint from "./ControlPoint";
import DoorSwing from "./DoorSwing";
import React from "react";

function SingleDoor(props) {
  const { name, width, domId, rotation = 0 } = props;
  const [displaySettings] = useDisplaySettings();
  const [controlTarget, setControlTarget] = useControlTarget();
  const [transform, handleMove, handleRotate] = useMovability(props);

  const active = controlTarget === domId || controlTarget === "rotate-" + domId;

  return (
    <g transform={transform}>
      <DoorSwing {...props} />
      <rect
        x={-(width / 2)}
        y={-1.5}
        width={width}
        height={3}
        fill="white"
        stroke="black"
        strokeWidth={0.5}
      />
      <text
        className={styles.svgTxt}
        fontSize={displaySettings.fontSize}
        textAnchor="middle"
        x={0}
        y={displaySettings.fontSize * 1.5}
        transform={
          props.rotation > 90
            ? `rotate(180)translate(0,${-displaySettings.fontSize * 2.5})`
            : null
        }
      >
        {name}
      </text>
      <rect
        id={domId}
        style={{ cursor: active ? "move" : "pointer" }}
        onMouseDown={handleMove}
        onTouchStart={handleMove}
        x={-(width / 2) - boundingBoxOffset}
        y={
          props.swingType === swingTypes.none
            ? -boundingBoxOffset - 1.5
            : -width - boundingBoxOffset
        }
        width={width + boundingBoxOffset * 2}
        height={
          props.swingType === swingTypes.none
            ? 3 + boundingBoxOffset * 2
            : width + boundingBoxOffset * 2
        }
        fill={active ? "rgba(0,0,255,0.05)" : "rgba(0,0,0,0)"}
        stroke={active ? "#0d6efd" : "none"}
        strokeDasharray={2}
        strokeWidth={0.5}
      />
      {active ? (
        <>
          <line
            x1={0}
            y1={
              props.swingType === swingTypes.none
                ? -boundingBoxOffset
                : -width - boundingBoxOffset
            }
            x2={0}
            y2={
              props.swingType === swingTypes.none
                ? -boundingBoxOffset - 18
                : -width - boundingBoxOffset - 18
            }
            stroke="#0d6efd"
            strokeWidth={0.5}
          />
          <ControlPoint
            id={"rotate-" + domId}
            cx={0}
            cy={
              props.swingType === swingTypes.none
                ? -boundingBoxOffset - 18
                : -width - boundingBoxOffset - 18
            }
            onClick={() => setControlTarget("rotate-" + domId)}
            onMouseDown={handleRotate}
            onTouchStart={handleRotate}
          />
          <text
            x={6}
            y={props.swingType === swingTypes.none ? -16 : -width - 12}
            fill="#0d6efd"
            className={styles.svgTxt}
            fontSize={displaySettings.fontSize * 1.5}
          >
            {Math.round(rotation)}°
          </text>
        </>
      ) : null}
    </g>
  );
}

export default SingleDoor;
