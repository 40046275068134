import React from "react";
import styles from "../floorplanTool.module.css";
import { useControlTarget, useMovability } from "../hooks";

function Switch(props) {
  const [transform, handleMove] = useMovability(props);
  const [controlTarget] = useControlTarget();

  const active =
    controlTarget === props.domId || controlTarget === "rotate-" + props.domId;

  return (
    <g transform={transform}>
      <text
        x={-3}
        y={-2}
        fontSize={8}
        fontWeight="bold"
        fontFamily="sans-serif"
        className={styles.unSelectable}
      >
        S
      </text>
      <rect
        id={props.domId}
        x={-5}
        y={-9}
        width={9}
        height={9}
        fill={active ? "rgba(0,0,255,0.05)" : "rgba(0,0,0,0)"}
        stroke={active ? "#0d6efd" : "none"}
        strokeDasharray={2}
        strokeWidth={0.5}
        style={{ cursor: active ? "move" : "pointer" }}
        onMouseDown={handleMove}
        onTouchStart={handleMove}
      />
    </g>
  );
}

export default Switch;
