import React, {useState, Fragment} from "react";
import classnames from 'classnames';
import {
  Row,
  Col,
  Label,
  Button,
  Input,
  InputGroup,
  InputGroupText,
  Alert
} from "reactstrap";
import {DebounceInput} from "react-debounce-input";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ui_helpers } from "../helpers";
import { constants } from "../utils";
import _ from "lodash";
import Select, { components } from 'react-select';

const Option = (props) => (
  <components.Option {...props}>
    <div className="fw-bold">{props.label}</div>
    {props.data.label2
      ? (<div className="fst-italic fs-6">{props.data.label2}</div>)
      : null
    }
  </components.Option>
);

const AdjustmentSelect = (props) => (
  <Select
    {...props}
    components={{Option}}
  />);

const PriceAdjustmentEditor = (props) => {
  const [priceAdjustmentType, setPriceAdjustmentType] = useState(constants.DEFAULT_PRICE_ADJUSTMENT_OPTION);

  function commitPricingAdjustment() {
    if (!props.pendingPriceAdjustment) return;
    let adj = {
      ...props.pendingPriceAdjustment
    };
    if (!_.trim(adj.description).length) {
      props.setPendingPriceAdjustment({...adj, invalidMessage: 'Provide a description for this adjustment'});
      return;
    }    
    let amt = parseFloat(adj.amount);
    if (!amt) {
      props.setPendingPriceAdjustment({...adj, invalidMessage: 'Provide a valid amount in order to save an adjustment'});
      return;
    }
    if (priceAdjustmentType.isNegative) {
      amt = -1 * amt;
      adj.amount = String(amt);
      if (priceAdjustmentType.isPercent) {
        let pct = Math.abs(parseFloat(adj.percentage));
        if (priceAdjustmentType.isNegative) {
          pct = -1 * pct;
        }
        adj.percentage = String(pct);
      }
    }
    props.addPriceAdjustment(adj);
    setPriceAdjustmentType(constants.DEFAULT_PRICE_ADJUSTMENT_OPTION);
  }

	return (<Fragment>
  <div className="mb-1">
    <Row>
      <Col xs="12" className="pt-1">
        <AdjustmentSelect
          options={props.adjustmentTypeOptions}
          value={priceAdjustmentType}
          onChange={(x) => {
            setPriceAdjustmentType(x);
            props.setPendingPriceAdjustment(x);
          }}
        />
      </Col>
    </Row>
    <Row>
    	<Col className="pt-1">
        {// a description on the selected option means that the user should not be able to type one in
          priceAdjustmentType && priceAdjustmentType.value && !priceAdjustmentType.description && props.pendingPriceAdjustment
          ? (<DebounceInput
              className={classnames({invalid: props.pendingPriceAdjustment && props.pendingPriceAdjustment.invalidMessage}, "form-control")}
              title={props.pendingPriceAdjustment && props.pendingPriceAdjustment.invalidMessage ? props.pendingPriceAdjustment.invalidMessage : null}
              placeholder="Adjustment Description"
              maxLength="200"
              minLength={2}
              debounceTimeout={500}
              value={props.pendingPriceAdjustment.description}
              onChange={(e) => props.setPendingPriceAdjustment({...props.pendingPriceAdjustment, description: e.target.value})}
            />)
          : null
        }
      </Col>
      {priceAdjustmentType && priceAdjustmentType.value
      	? (<Fragment>
      		<Col xs="4" className="pt-1">
		        {priceAdjustmentType.isPercent
		          ? (<DebounceInput
		              type="number"
		              maxLength="10"
		              minLength={1}
		              debounceTimeout={500}
		              value={props.pendingPriceAdjustment ? props.pendingPriceAdjustment.percentage : ''}
		              className={classnames({invalid: props.pendingPriceAdjustment && props.pendingPriceAdjustment.invalidMessage}, "text-end form-control")}
		              placeholder="Percent (%)"
		              title={props.pendingPriceAdjustment && props.pendingPriceAdjustment.invalidMessage ? props.pendingPriceAdjustment.invalidMessage : null}
		              onChange={(e) => {
		                const pctString = e.target.value;
		                let pct = parseFloat(pctString);
		                // remember the amount, but always as a positive number
		                let amt = 0.0;
		                if (pct) {
		                  pct = Math.abs(pct);
		                  if (pct >= 1.0) {
		                    pct = pct / 100.0;
		                  }
		                  amt = parseFloat(props.unitPrice) * pct;
		                }
		                props.setPendingPriceAdjustment({
		                  ...props.pendingPriceAdjustment, 
		                  percentage: pctString,
		                  amount: String(amt)
		                });
		              }}
		            />)
		          : (<DebounceInput
		              type="number"
		              maxLength="18"
		              minLength={1}
		              disabled={!priceAdjustmentType}
		              debounceTimeout={500}
		              value={props.pendingPriceAdjustment ? props.pendingPriceAdjustment.amount : ''}
		              className={classnames({invalid: props.pendingPriceAdjustment && props.pendingPriceAdjustment.invalidMessage}, "text-end form-control")}
		              placeholder="Amount"
		              title={props.pendingPriceAdjustment && props.pendingPriceAdjustment.invalidMessage ? props.pendingPriceAdjustment.invalidMessage : null}
		              onChange={(e) => props.setPendingPriceAdjustment({
                      ...props.pendingPriceAdjustment, 
                      amount: e.target.value
                    })}
		            />)
		        }
		      </Col>
					<Col xs="2" className="pt-1">
		        <Button color="primary" className="text-light float-end" onClick={commitPricingAdjustment}>
		          <FontAwesomeIcon icon="plus" />
		        </Button>
		      </Col>
		    </Fragment>)
      	: null
      }
    </Row>
    {priceAdjustmentType
      && priceAdjustmentType.value 
      && (props.pendingPriceAdjustment.description || props.pendingPriceAdjustment.percentage || props.pendingPriceAdjustment.amount)
      ? (<div style={{fontStyle: "italic", color: "#FF0000"}}>
          Don't forget to click the + button to save this adjustment! <FontAwesomeIcon icon="arrow-right" />
        </div>)
      : null
    }
    {props.pendingPriceAdjustment && props.pendingPriceAdjustment.invalidMessage
    	? (<Alert color="danger" className="my-1">
    			{props.pendingPriceAdjustment.invalidMessage}
		    </Alert>)
    	: null
    }
  </div>
  {_.map(props.priceAdjustments, pa => (
    <div className="mb-1" key={pa.id}>
      <Row>
        <Col xs="6" className="py-2">
          <Label>{pa.description}</Label>
        </Col>
        <Col xs="4" className="text-end py-2">
          {ui_helpers.priceAdjustmentCaption(pa)}
        </Col>
        <Col xs="2">
          <Button color="danger" className="text-light float-end xs mt-1" onClick={() => props.deletePriceAdjustment(pa.id)}>
            <FontAwesomeIcon icon="times" />
          </Button>
        </Col>
      </Row>
    </div>))
  }
  {props.priceAdjustments.length
    ? (<div className="mb-1">
        <Row>
          <Col xs="6" className="pt-1">
            <Label>Unit Price w/ Adjustments</Label>
          </Col>
          <Col>
            <InputGroup>
              <InputGroupText>$</InputGroupText>
              <Input
                value={ui_helpers.formatCurrency(
                  parseFloat(props.unitPrice) + _.sumBy(_.filter(props.priceAdjustments, pa => pa.includeInUnitPrice), pa => parseFloat(pa.amount))
                )}
                disabled={true}
                className="text-end form-control"
              />
            </InputGroup>
          </Col>
        </Row>
      </div>)
    : null
  }
</Fragment>);
};

export default PriceAdjustmentEditor;
