import React, { useReducer } from "react";
import { DisplaySettingsContext } from "../contexts";
import { initialDisplaySettings } from "../init";
import { displaySettingsReducer } from "../reducers";

function DisplaySettingsProvider({ children }) {
  const [state, dispatch] = useReducer(
    displaySettingsReducer,
    initialDisplaySettings
  );

  const root = document.documentElement;
  root.style.setProperty("--header-height", state.headerHeight + "px");
  root.style.setProperty("--sidebar-width", state.sidebarWidth + "px");
  root.style.setProperty(
    "--secondary-sidebar-width",
    state.secondarySidebarWidth + "px"
  );
  root.style.setProperty("--alert-height", state.alertHeight + "px");

  return (
    <DisplaySettingsContext.Provider value={[state, dispatch]}>
      {children}
    </DisplaySettingsContext.Provider>
  );
}

export default DisplaySettingsProvider;
