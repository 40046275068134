export const POS_HUB_URL = "/Pos";
export const API_URL = `/api/`;

export const TOP_LEVEL_VIEWS = {
  IDLE: "idle",
  WATCHING_VIDEO: "watching-video",
  SIGNING: "signing",
  PAPERLESS_BILLING: "paperless-billing",
  RECURRING_PAYMENTS: "recurring-payments",
  HOME: "home"
};

export const SIGNATURE_TYPES = {
  fullName: 1,
  initial: 2,
  fullNameBinary: 3,
  initialBinary: 4,
};

export const CREDIT_CARD_TYPES = [
  { label: "Visa", value: 1 },
  { label: "MasterCard", value: 2 },
  { label: "Discover", value: 3 },
  { label: "American Express", value: 4 },
];
