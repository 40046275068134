import { boundingBoxOffset, windowAdditionalOptionTypes } from "../floorplanConstants";
import styles from "../floorplanTool.module.css";
import { useControlTarget, useDisplaySettings } from "../hooks";
import useMovability from "../hooks/useMovability";
import ControlPoint from "./ControlPoint";
import React from "react";

function Window(props) {
  const [displaySettings] = useDisplaySettings();
  const [controlTarget, setControlTarget] = useControlTarget();
  const [transform, handleMove, handleRotate] = useMovability(props);

  const active =
    controlTarget === props.domId || controlTarget === "rotate-" + props.domId;

  return (
    <g transform={transform}>
      <rect
        x={-(props.width / 2)}
        y={-1.5}
        width={props.width}
        height={3}
        fill="white"
        stroke="black"
        strokeWidth={0.5}
      />
      <line
        x1={-(props.width / 2)}
        y1={0}
        x2={props.width / 2}
        y2={0}
        stroke="black"
        strokeWidth={0.5}
      />
      <line x1={0} y1={-1.5} x2={0} y2={1.5} stroke="black" strokeWidth={0.5} />
      {((props.additionalOption1On && props.additionalOption1Type === windowAdditionalOptionTypes.shutters) 
        || (props.additionalOption2On && props.additionalOption2Type === windowAdditionalOptionTypes.shutters)) ? (
        <>
          <line
            x1={-props.width / 2}
            y1={-3}
            x2={-props.width / 2 - 12}
            y2={-3}
            stroke="black"
            strokeWidth={1}
            strokeDasharray={2}
          />
          ) :{" "}
          <line
            x1={props.width / 2}
            y1={-3}
            x2={props.width / 2 + 12}
            y2={-3}
            stroke="black"
            strokeWidth={1}
            strokeDasharray={2}
          />
        </>
      ) : null}
      {((props.additionalOption1On && props.additionalOption1Type === windowAdditionalOptionTypes.flowerbox) 
        || (props.additionalOption2On && props.additionalOption2Type === windowAdditionalOptionTypes.flowerbox)) ? (
        <rect
          x={-(props.width / 2)}
          y={-12}
          width={props.width}
          height={6}
          fill="#fff"
          stroke="black"
          strokeWidth={1}
        />
      ) : null}
      <rect
        id={props.domId}
        style={{ cursor: active ? "move" : "pointer" }}
        onMouseDown={handleMove}
        onTouchStart={handleMove}
        x={-(props.width / 2) - boundingBoxOffset}
        y={props.flowerbox ? -15 : -boundingBoxOffset}
        width={props.width + boundingBoxOffset * 2}
        height={props.flowerbox ? 20 : 6}
        fill={active ? "rgba(0,0,255,0.05)" : "rgba(0,0,0,0)"}
        stroke={active ? "#0d6efd" : "none"}
        strokeDasharray={2}
        strokeWidth={0.5}
      />
      <text
        className={styles.svgTxt}
        fontSize={displaySettings.fontSize}
        textAnchor="middle"
        x={0}
        y={displaySettings.fontSize * 1.5}
        transform={
          props.rotation > 90
            ? `rotate(180)translate(0,${-displaySettings.fontSize * 2.5})`
            : null
        }
      >
        {props.name}
      </text>
      {active ? (
        <>
          <line
            x1={0}
            y1={props.flowerbox ? -15 : -3}
            x2={0}
            y2={props.flowerbox ? -1.5 * 12 - 15 : -1.5 * 12}
            stroke="#0d6efd"
            strokeWidth={0.5}
          />
          <ControlPoint
            id={"rotate-" + props.domId}
            cx={0}
            cy={props.flowerbox ? -1.5 * 12 - 15 : -1.5 * 12}
            onClick={() => setControlTarget("rotate-" + props.domId)}
            onMouseDown={handleRotate}
            onTouchStart={handleRotate}
          />
          <text
            x={6}
            y={props.flowerbox ? -2 * 12 : -1 * 12}
            className={styles.svgTxt}
            fontSize={displaySettings.fontSize * 1.5}
            fill="#0d6efd"
          >
            {Math.round(props.rotation)}°
          </text>
        </>
      ) : null}
    </g>
  );
}

export default Window;
