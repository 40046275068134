import React, { useState } from "react";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Button,
} from "reactstrap";
import useStore from "./useStore";
import constants from "../../utils/constants";
import { v4 as uuid } from "uuid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const { POS_SIGNATURE_TYPES: SIGNATURE_TYPES } = constants;

export default function PdfToolbarTop({ handleSave }) {
  const currentPageIndex = useStore((x) => x.currentPageIndex);
  const setCurrentPageIndex = useStore((x) => x.setCurrentPageIndex);
  const numPages = useStore((x) => x.numPages);
  const signatureLocations = useStore((x) => x.signatureLocations);
  const setSignatureLocations = useStore((x) => x.setSignatureLocations);

  const [dropdownOpen, setDropdownOpen] = useState(false);

  function prevPage() {
    const prevIndex = currentPageIndex - 1;
    if (prevIndex >= 0 && prevIndex < numPages) setCurrentPageIndex(prevIndex);
    else setCurrentPageIndex(numPages - 1);
  }

  function nextPage() {
    const nextIndex = currentPageIndex + 1;
    if (nextIndex >= 0 && nextIndex < numPages) setCurrentPageIndex(nextIndex);
    else setCurrentPageIndex(0);
  }

  function addNewSignature() {
    setSignatureLocations([
      ...signatureLocations,
      {
        domId: uuid(),
        type: SIGNATURE_TYPES.fullName,
        pageIndex: currentPageIndex,
        xPosition: 0,
        yPosition: 0,
        width: 130,
        height: 40,
      },
    ]);
  }

  function addNewSignatureAD() {
    setSignatureLocations([
      ...signatureLocations,
      {
        domId: uuid(),
        type: SIGNATURE_TYPES.fullNameBinary,
        pageIndex: currentPageIndex,
        xPosition: 0,
        xPosition2: 180,
        yPosition: 0,
        width: 130,
        height: 40,
      },
    ]);
  }

  function addNewInitial() {
    setSignatureLocations([
      ...signatureLocations,
      {
        domId: uuid(),
        type: SIGNATURE_TYPES.initial,
        pageIndex: currentPageIndex,
        xPosition: 0,
        yPosition: 0,
        width: 80,
        height: 40,
      },
    ]);
  }

  function addNewInitialAD() {
    setSignatureLocations([
      ...signatureLocations,
      {
        domId: uuid(),
        type: SIGNATURE_TYPES.initialBinary,
        pageIndex: currentPageIndex,
        xPosition: 0,
        xPosition2: 110,
        yPosition: 0,
        width: 80,
        height: 40,
      },
    ]);
  }

  return (
    <>
      <div
        className="vw-100 pe-4 bg-light position-fixed shadow p-2 d-flex justify-content-between align-items-center"
        style={{ top: 0, left: 0, height: "4rem", zIndex: 998 }}
      >
        <h4>Set Signature Locations</h4>
        <div className="d-flex align-items-center">
          <Button size="sm" onClick={prevPage}>
            Prev
          </Button>
          <div className="mx-3 fw-bold">
            Page {currentPageIndex + 1}/{numPages}
          </div>
          <Button size="sm" onClick={nextPage}>
            Next
          </Button>
        </div>
        <div className="d-flex">
          <Dropdown
            isOpen={dropdownOpen}
            toggle={() => setDropdownOpen(!dropdownOpen)}
            direction="down"
            className="me-3"
          >
            <DropdownToggle color="success" className="text-white" caret>
              Add New
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem onClick={addNewSignature}>
                Signature (Single)
              </DropdownItem>
              <DropdownItem onClick={addNewSignatureAD}>
                Signature (Accept/Decline)
              </DropdownItem>
              <DropdownItem onClick={addNewInitial}>
                Initial (Single)
              </DropdownItem>
              <DropdownItem onClick={addNewInitialAD}>
                Initial (Accept/Decline)
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>
          <Button
            color="primary"
            className="text-light"
            onClick={() => handleSave(signatureLocations)}
          >
            <FontAwesomeIcon icon="save" /> Save Changes
          </Button>
        </div>
      </div>
    </>
  );
}
