import React, {useState, cloneElement} from "react";
import {Row, Col} from "reactstrap";
import {constants} from "../utils";

export default function Toolbar({children}) {


  return (
    <div id="toolbar" className="p-1 h-100">
      <Row className="me-0">
        <Col>{children}</Col>
      </Row>
    </div>
  );
};
