import React, {useContext, useEffect, useState} from "react";
import {Link, useParams} from "react-router-dom";
import {
    UncontrolledDropdown, DropdownToggle, Button, Form, FormGroup, Label, Input,
    DropdownItem, Dropdown, Row, Col, Container, Table, CardBody, CardHeader, Card, ButtonGroup
} from 'reactstrap';
import _ from 'lodash';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Alert from "react-s-alert-v3";

import {api, constants, UserContext} from "../utils";
import {FaFontAwesome} from "react-icons/fa";
import {date_helpers, input_validation} from "../helpers";
import {DebounceInput} from "react-debounce-input";
import moment from "moment";
const PATHNAMES = constants.PATH_NAMES;

const defaultDefect = {
    unitInventoryId: 0,
    defectId: 0,
    defectCount: 0,
    defectPenaltyValue: 0,
};

const InspectionRow = ({i, unitId, defectItem, step, isEditing}) => {
    const [defect, setDefect] = useState(defectItem ? defectItem : defaultDefect);
    const [active, setActive] = useState(false);
    const [loading, setLoading] = useState(false);

    const updateInspection = (defectItem) => {
        setLoading(true);
        defectItem.unitInventoryId = unitId;
        defectItem.defectId = i.id;
      api.post('Inventory/UpdateInspectionScore', defectItem)
          .then(r => {
              if(!r.data) {
                  Alert.error('There was an error updating the Penalty Value.');
                  return;
              }
              setDefect(r.data);
          }).finally(() => setLoading(false))
    };

    const onDefectChange = (fieldName, fieldValue) => {
        let newDefect = Object.assign({}, defect);
        newDefect[fieldName] = fieldValue;
        newDefect.defectId = i.id;
        setDefect(newDefect);
        updateInspection(newDefect);
        //onAddCallback(newDefect);
    };

    const handlePenaltyValueChange = (number) => {
        let retNumber = parseInt(number, 10);
        if(!input_validation.is_int(retNumber))
        {
            Alert.error('The number you entered is not a number');
            return;
        }
        let retDefect = Object.assign({}, defect);
        retDefect.defectPenaltyValue = retNumber;
        setDefect(retDefect);
        updateInspection(retDefect);
    };

    useEffect(() => {
        if(!defectItem) return;
        setDefect(defectItem);
    }, [defectItem]);


    const addToPenaltyValue = () => {
      let newDefect = Object.assign({}, defect);
      newDefect.defectPenaltyValue++;
        updateInspection(newDefect);
    };

    const subtractFromPenaltyValue = () => {
        let newDefect = Object.assign({}, defect);
        if(newDefect.defectPenaltyValue > 0)
        {
            newDefect.defectPenaltyValue--;
            updateInspection(newDefect);
        }
    };




    return (
        <tr key={`i-${i.id}`}>
            <td>
                {i.name}
            </td>
            <td className={'d-flex justify-content-center'}>
                <Row className={'d-flex justify-content-center'}>
                    <Col sm={4} className='d-flex justify-content-end m-0'>
                        <Button size={'sm'} onClick={() => subtractFromPenaltyValue()}
                                disabled={loading || !defect.defectPenaltyValue > 0 || step.completedAt && !isEditing}
                                className={'bg-primary'}>
                            <FontAwesomeIcon icon={'minus'}/>
                        </Button>
                    </Col>
                    <Col sm={3} className={'p-0 m-0 d-flex justify-content-center'}>
                        <DebounceInput type={'text'} value={defect.defectPenaltyValue} onChange={(e) =>
                            handlePenaltyValueChange(e.target.value)} min={0} debounceTimeout={500}
                               className={'text-center'} style={{width: '45px'}}/>
                    </Col>
                    <Col sm={4} className='d-flex justify-content-start m-0'>
                        <Button size={'sm'} onClick={() => addToPenaltyValue()} disabled={loading || step.completedAt
                            && !isEditing} className={'bg-primary'}>
                            <FontAwesomeIcon icon={'plus'}/>
                        </Button>
                    </Col>
                </Row>
            </td>
        </tr>
    );
};

export default function BuildInspection(props) {
    const {id} = useParams();
    const [alert, setAlert] = useState(constants.ALERT_OBJECT);
    const [inspections, setInspections] = useState([]);
    const [defects, setDefects] = useState([]);
    const [defectList, setDefectList] = useState([]);
    const [assignedBuilder, setAssignedBuilder] = useState('');
    const [buildStep, setBuildStep] = useState(Object.assign({}, props.buildStep));
    const [isEditing, setIsEditing] = useState(false);

    const getDefectList = (isActive) => {
      api.fetch(`Reference/ListMfgDefects/${props.inventory.manufacturerId}`).then(r => {
          if (!r.data)
          {
              setAlert({message: 'There was an error retrieving the defects.', flavor: constants.flavor.error});
              return;
          }
          if (isActive) {
            setInspections(r.data.message.list);
          }
      });
    };

    const getInspectionScores = (isActive) => {
      api.fetch(`Inventory/ListInventoryInspectionScores/${props.inventory.id}`).then(r => {
        if(!r.data) return;
        if(isActive) {
          setDefectList(r.data);
        }
      })
    };

    useEffect(() => {
      if(!props.inventory.id) return;
      let isActive = true;
      getDefectList(isActive);
      getInspectionScores(isActive);
      return () => {
        isActive = false;
      };
    }, [props.inventory]);

    const onBuildStepChanged = (fieldName, fieldValue) => {
        let newBuildStep = Object.assign({}, buildStep);
        newBuildStep[fieldName] = fieldValue;
        setBuildStep(newBuildStep);
    };

    const markBuildStepComplete = () => {
      let payload = Object.assign({}, buildStep);
      if(!payload.completedBy.length) {
        Alert.warning('You must enter the name of the person who completed this step.');
        return;
      }
      payload.customerNotificationType = payload.customerNotificationType.value;
      api.post('inventory/MarkBuildStepComplete', payload).then(r => {
              if(r.data.success) {
                  props.getBuildSteps();
                  Alert.success(`${buildStep.name} marked complete`);
              } else {
                  Alert.error('There was an issue marking this build step as complete');
              }
          }).catch(err => console.error(err));
    };

    const getEdiitngButtons = () => {
        if(isEditing) {
            return (
                <ButtonGroup>
                    <Button className={'bg-success border-success'} onClick={markBuildStepComplete}>
                        Save Score
                    </Button>
                    <Button onClick={() => setIsEditing(false)}>
                        <FontAwesomeIcon icon={'times-circle'}/>
                    </Button>
                </ButtonGroup>
            );
        } else {
            return (
              <Button onClick={() => setIsEditing(true)}>
                  <FontAwesomeIcon icon={'edit'}/>
              </Button>
            );
        }

    };



    return (
      <div> 
          <Container className="mt-5">

              <Card>
                  <CardHeader>
                      <Row>
                          <Col xs="10" sm="10" md="8">
                              <h3 className="mt-2">Inspection Form</h3>
                          </Col>
                      </Row>
                      <Row>
                      </Row>
                  </CardHeader>
                  <CardBody>
                      <Row>
                          <Col>
                              <Input type={'text'} placeholder={'Completed by..'} name={'completedBy'}
                                     value={buildStep.completedBy} onChange={(e) =>
                              onBuildStepChanged(e.target.name, e.target.value)}/>
                          </Col>
                          <Col>
                              {buildStep.completedAt ?
                                  getEdiitngButtons()
                                  : (
                                      <Button className={'bg-success border-success'} onClick={markBuildStepComplete}>
                                          Mark as Complete
                                      </Button>)}
                          </Col>
                      </Row>
                      <Col sm={{ size: 12 }}>
                          <Table size={'sm'} className={'mt-4'} borderless>
                              <thead>
                              <tr key={'i-headers'}>
                                  <th>
                                      <Row>
                                          <div className="bold">
                                              Inspection Defect Checklist
                                          </div>
                                      </Row>
                                      <Row>
                                          <h6 className="mt-3">Builder: {props.assignedBuilder.completedBy ? props.assignedBuilder.completedBy : 'No Builder Assigned'}</h6>
                                      </Row>
                                  </th>
                                  <th className={'text-center'}># of Defects</th>
                              </tr>
                              </thead>
                              <tbody>
                              {inspections
                                  ? _.map(inspections, i => {
                                      return (<InspectionRow key={`irw-${i}`} i={i} unitId={props.inventory.id} step={props.buildStep}
                                                             isEditing={isEditing} defectItem={
                                          _.find(defectList,d =>
                                          d.defectId === i.id)}/>)
                                  })
                                  : null}
                              </tbody>
                          </Table>
                      </Col>
                  </CardBody>
              </Card>


            {/*  <Label className="label-full">*/}
            {/*    <div className="pt-3"><Input type="checkbox" id="checkbox1" />{'No H and S Tag, other craftsmanship qualities may be scored as well'} <input type="number" id="number1" className="number-arrows" placeholder="0" /> </div>*/}
            {/*    <div className="pt-3"><Input type="checkbox" id="checkbox2" />{' Un-clipped nails, Carelessness, Buildings paid for but not ready for inspections'}<input type="number" id="number2" className="number-arrows" placeholder="0" /></div>*/}
            {/*    <div className="pt-3"><Input type="checkbox" id="checkbox3" />{' Doors or windows not properly aligned/bolt not operating properly'}<input type="number" id="number3" className="number-arrows" placeholder="0" /></div>*/}
            {/*    <div className="pt-3"><Input type="checkbox" id="checkbox4" />{' Un-caulked windows, doors, excessive caulk beading'}<input type="number" id="number4" className="number-arrows" placeholder="0" /></div>*/}
            {/*    <div className="pt-3"><Input type="checkbox" id="checkbox5" />{' Un-nailed flooring, Lofts, plates, Gaps in flooring'}<input type="number" id="number5" className="number-arrows" placeholder="0" /></div>*/}
            {/*    <div className="pt-3"><Input type="checkbox" id="checkbox6" />{' Unswept floors, cleanliness'}<input type="number" id="number6" className="number-arrows" placeholder="0" /></div>*/}
            {/*    <div className="pt-3"><Input type="checkbox" id="checkbox7" />{' Screws missing on roof, stripped screws, Rough cut trim'} <input type="number" id="number7" className="number-arrows" placeholder="0" /></div>*/}
            {/*    <div className="pt-3"><Input type="checkbox" id="checkbox8" />{' Paint drips, splatter, sloppy paint,overspray, smudged shutters, flower boxes'} <input type="number" id="number8" className="number-arrows" placeholder="0" /></div>*/}
            {/*    <div className="pt-3"><Input type="checkbox" id="checkbox9" />{' Loose siding, nails too close to lip, Excessive nailing, spaced too far apart, un-nailed'}<input type="number" id="number9" className="number-arrows" placeholder="0" /></div>*/}
            {/*  </Label>*/}
            {/*</FormGroup>*/}
    
    <Col sm="12" md={{ size: 8, offset: 2 }}>
    {/*<img className="floorplan-image" src={FloorImg} alt="Floorplan" />*/}
    </Col>
    </Container>
    </div>
  );
}


