import React, {useEffect, useState} from 'react';
import {ManufacturerAdminHeader, Loading} from "./";
import {Col, Row, Button, Input, Container, Card, CardHeader, CardBody, CardFooter, ButtonGroup, Label} from "reactstrap";
import Select from 'react-select';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import _ from 'lodash';
import {date_helpers} from "../helpers";
import {api} from '../utils';
import ReactTooltip from "react-tooltip";
import {useParams} from "react-router-dom";

const todaysDate = date_helpers.getSerialNumberDisplay(new Date());

const templateOptions = [
    {value: 0, label: 'Product Abbrev', display: 'ABBR', template: 'ProductAbbr'},
    {value: 1, label: 'YYMMDD', display: `${todaysDate}`, template: 'YYMMDD'},
    {value: 2, label: 'Unique Num', display: '12001',  template: 'Seq'},
    {value: 3, label: 'Length', display: '16', template: 'Length'},
    {value: 4, label: 'Width', display: '10', template: 'Width'},
    {value: 5, label: 'Sales Persons Initials', display: 'TJ',  template: 'SalesPersonsInitials'},
    {value: 6, label: 'Store Suffix', display: 'EDS',  template: 'StoreSuffix'},
];

const delimiterOption = [
    {value: 0, label: '-'},
    {value: 1, label: '+'},
    {value: 2, label: '~'},
    {value: -2, label: 'Custom'},
];

const SelectFormField = (props) => {
    const [options, setOptions] = useState(props.options);

    const filterAvailableOptions = () => {
        let newOptions = _.filter(options, o => {
            return o.value !== props.usedOptions.value;
        });
        setOptions(newOptions);
    }

    const onSelectChange = (value) => {
        props.handleSelectCallback(value, props.index);
    }

    const Delimiter = () => {
      return (<span className='mx-1 d-flex justify-content-center'>{props.selectedDelimiter}</span>);
    };

    return (
        <>
      <Card className={`serial-container mx-2`}>
          <Col>
              <Select options={options}
                      onChange={(value) => onSelectChange(value)}
                      value={props.value}
              />
          </Col>
          {props.index > 2 && props.count > 3
          ? (<Col sm={1} className='d-flex align-items-start justify-content-end'>
                  <FontAwesomeIcon icon={'plus'} className='serial-close' onClick={() => props.removeCallback(props.index)}
                                   data-tip data-for={`tt-${props.index}`}/>
          </Col>)
              : null}
          <ReactTooltip
              place={'top'}
              id={`tt-${props.index}`}
              type={'dark'}
              effect={'solid'}
              delayShow={250}>
              <p style={{fontSize: '1rem', opacity: '1'}}>Click to remove item.</p>
          </ReactTooltip>
      </Card>
        </>
    );
};

const maxTemaplateItems = 6;

export default function ManufacturerSettings(props) {
    const {id} = useParams();
    const mfgId = id
      ? id
      : props.mfgId;
    const [serialNumberBoxCount, setSerialNumberBoxCount] = useState(3);
    const [serialTemplateOptions, setSerialTempalteOptions] = useState(templateOptions);
    const [usedTemplateItems, setUsedTemplateItems] = useState([]);
    const [serialNumber, setSerialNumber] = useState([]);
    const [serialNumberDisplay, setSerialNumberDisplay] = useState([]);
    const [selectedDelimiter, setSelectedDelimiter] = useState('-');
    const [serialNumberDelimiter, setSerialNumberDelimiter] = useState('');
    const [isEditingSerialNumber, setIsEditingSerialNumber] = useState(false);
    const [showCustomDelimiter, setShowCustomDelimiter] = useState(false);
    const [serialNumberIsNotValid, setSerialNumberIsNotValid] = useState(false);
    const [customDelimiter, setCustomDelimiter] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [backupSerial, setBackUpSerial] = useState([]);

    const handleTemplateSelectChange = (value, index) => {
        let newTemplate = serialNumber.slice();
        let newDisplay = serialNumberDisplay.slice();
        if(newTemplate[index])
        {
            newTemplate[index] = value.template;
            newDisplay[index] = value.display;
        }
        else {
            newTemplate.push(value.template);
            newDisplay.push(value.display);
        }

        setSerialNumber(newTemplate);
        setSerialNumberDisplay(newDisplay);
        setUsedTemplateItems(value);
    };

    const getMfgSerialTemplate = () => {
      setIsLoading(true);
      api.fetch(`manufacturer/GetMfgSerialNumber/${mfgId}`)
          .then(r => {
             if(r.data.serialNumberTemplate)
             {
                 let serialNum = r.data.serialNumberTemplate.split('~');
                 setSerialNumber(serialNum);
                 setSerialNumberBoxCount(serialNum.length);
                 getSerialDisplay(serialNum);
             }
             if(r.data.serialNumberDelimiter)
                 setSerialNumberDelimiter(r.data.serialNumberDelimiter);
          }).finally(() => setIsLoading(false));
    };

    const saveSerialNumberTemplate = () => {
      let payload = {
        mfgId: mfgId,
        serialNumberTemplate: serialNumber ? convertSerialNumberToString(serialNumber) : null,
        serialNumberDelimiter: serialNumberDelimiter ? serialNumberDelimiter : null,
      };
      let isNotValid = validateSerialTemplate(serialNumber);
      if(isNotValid) {
          setSerialNumberIsNotValid(true);
      } else {
          api.post('manufacturer/SaveSerialNumberTemplate', payload)
              .then(r => {
                  if(r.data.success){
                      getMfgSerialTemplate();
                      setIsEditingSerialNumber(false);
                      setSerialNumberIsNotValid(false);
                  }
              });
      }
    };

    const getSerialDisplay = (serialNum) => {
        let newArray = [];
        for (let i = 0; i < serialNum.length; i++)
        {
           let found = _.find(templateOptions, t => {
                return t.template === serialNum[i];
            });
           newArray.push(found.display);
        }
        setSerialNumberDisplay(newArray);
    };

    const handleSelectedDelimiter = (value) => {
      if(value.value === -2)
      {
          setShowCustomDelimiter(true);
      } else {
          setShowCustomDelimiter(false);
          setSerialNumberDelimiter(value.label);
      }
    };

    const convertSerialNumberToString = (serialTemplate) => {
      return serialTemplate.join('~');
    };

    const removeSerialElement = (index) => {
        let newTemplate = serialNumber.slice();
        let newDisplayTemplate = serialNumberDisplay.slice();
        newTemplate.splice(index, 1);
        newDisplayTemplate.splice(index, 1);
        setSerialNumber(newTemplate);
        setSerialNumberDisplay(newDisplayTemplate);
        decrementSerialCount();
    };

    const validateSerialTemplate = (array) => {
      return new Set(array).size !== array.length;
    };

    const addSerialCount= () => {
        let count = serialNumberBoxCount;
        count++;
        setSerialNumberBoxCount(count);
    };

    const decrementSerialCount = () => {
      let count = serialNumberBoxCount;
      count --;
      setSerialNumberBoxCount(count);
    };

    const editSerialNumber = () => {
        setIsEditingSerialNumber(true);
        setBackUpSerial(serialNumber);
    };

    const cancelSerialEdit = () => {
      setIsEditingSerialNumber(false);
      setSerialNumber(backupSerial);
    };

    useEffect(() => {
        getMfgSerialTemplate();
    }, [])

    return (
        <>
      <ManufacturerAdminHeader settings />
      <Container fluid>
          {isLoading ? <Loading/>
           : <Row>
              <Col>
                  <Card>
                      <CardHeader className='d-flex align-items-center'>
                          <h5>Serial Number</h5>
                      </CardHeader>
                      <CardBody>
                            <Row className='d-flex justify-content-center'>
                            <span className={`text-center ${serialNumber.length ? null : 'text-danger'}`}>
                                {serialNumberDisplay && serialNumberDisplay.length && isEditingSerialNumber
                                    ? <strong>Example: {serialNumberDisplay.join(` ${serialNumberDelimiter} `)}</strong>
                                    : serialNumber ? <h5>Below is the current Serial Number template your Manufacturer uses for new Inventory</h5>
                                            : 'Your Manufacturer does not have a serial number template.' +
                                    ' You must create one to create new inventory items.'}
                            </span>
                        <hr className='mt-1 w-75'/>
                        </Row>
                          <Row className='mt-2 d-flex justify-content-center'>
                              {serialNumberIsNotValid ? <Row className='d-flex justify-content-center text-danger text-center mb-1'>
                                      <strong>You cannot have repeating template items in your Serial Number. Please fix this and hit save again.</strong>
                              </Row>
                                  : null}
                              {isEditingSerialNumber
                              ? <div className='d-flex justify-content-center align-items-center bg-light'>
                                  {_.times(serialNumberBoxCount, i => {
                                      return (<SelectFormField index={i} options={serialTemplateOptions}
                                                               usedOptions={usedTemplateItems}
                                                               value={_.find(serialTemplateOptions, s => {
                                                                   return serialNumber[i] === s.template;
                                                               })}
                                                               handleSelectCallback={handleTemplateSelectChange}
                                      removeCallback={removeSerialElement} count={serialNumberBoxCount} selectedDelimiter={selectedDelimiter}/>)
                                  } )}
                                  {serialNumberBoxCount < maxTemaplateItems
                                  ? (<><Button size={'sm'} onClick={() => addSerialCount()} className={'bg-success'}
                                               data-tip data-for={`tt-add`}>
                                      <FontAwesomeIcon icon={'plus'}/>
                                  </Button>
                                      <ReactTooltip
                                          place={'right'}
                                          id={`tt-add`}
                                          type={'dark'}
                                          effect={'solid'}
                                          delayShow={250}>
                                          <p style={{fontSize: '1rem', opacity: '1'}}>Click to add another box.</p>
                                      </ReactTooltip></>)
                                      :null}
                              </div>
                                  : <div className='w-100 d-flex justify-content-center bg-light'>
                                     <Row>
                                         <Col>
                                             <h4>{serialNumber.join(serialNumberDelimiter ? ` ${serialNumberDelimiter} ` : ' - ')}</h4>
                                         </Col>
                                     </Row>
                                  </div>}
                          </Row>
                          <hr/>
                          <Row className='d-flex align-items-center'>
                              {isEditingSerialNumber
                                  ? <><Col sm={1}>
                                      <Label>Seperator: </Label>
                                  </Col>
                                  <Col sm={2}>
                                      <Select options={delimiterOption} value={_.find(delimiterOption, d => {
                                          return d === selectedDelimiter;
                                      })} onChange={(value) => handleSelectedDelimiter(value)}/>
                                  </Col>

                                      {showCustomDelimiter ? <><Col sm={1}><Input type='text' maxLength={1} value={serialNumberDelimiter}
                                                                    onChange={(e) =>
                                                                        setSerialNumberDelimiter(e.target.value)}/></Col></>
                                      : null}
                                  </>
                              : null}
                          </Row>
                          <Row className='my-1'>
                              <Col>
                                  {isEditingSerialNumber
                                      ? <ButtonGroup className='float-end'>
                                          <Button className='bg-secondary' size={'sm'} onClick={() => cancelSerialEdit()}>
                                              Cancel
                                          </Button>
                                          <Button className='bg-success' size={'md'} onClick={() =>
                                                    saveSerialNumberTemplate()}>
                                                Save
                                        </Button>
                                  </ButtonGroup>
                                      : <Button className='float-end ' onClick={() => editSerialNumber()}>
                                          <FontAwesomeIcon icon={'edit'}/>
                                      </Button>}
                              </Col>
                          </Row>
                      </CardBody>
                  </Card>
              </Col>
          </Row>}
      </Container>
            </>
    );
}