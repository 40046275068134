import React, { useState, useEffect } from "react";
import {
    Row,
    Col,
    ButtonGroup,
    Button,
    InputGroup,
    InputGroupText,
    Input,
} from "reactstrap";
import Alert from "react-s-alert-v3";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import _ from "lodash";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
import { VscOpenPreview } from "react-icons/vsc";
import { constants } from "../utils";
// import { ImageLibrary, DocumentLibrary, ZBButton } from "./";
import { useRef } from "react";
import {ui_helpers} from "../helpers";

const Image = Quill.import('formats/image');
Quill.register({ 'formats/image': Image });

class ImageBlot extends Image {
    static get ATTRIBUTES() {
        return ['alt', 'height', 'width', 'class', 'data-original', 'data-width', 'data-height', 'style-data']
    }

    static create(value) {
        let node = super.create();
        node.setAttribute('alt', value.alt);
        node.setAttribute('src', value.src);
        node.setAttribute('class', value.class);
        return node;
    }

    static value(node) {
        return {
            alt: node.getAttribute('alt'),
            src: node.getAttribute('src'),
            class: node.getAttribute('class')
        };
    }
}
ImageBlot.blotName = "image";
ImageBlot.tagName = 'img';

Quill.register(ImageBlot);

const editSectionTypes = {
    linkURL: {
        label: "linkURL",
        type: "URL",
    },
    linkPhone: {
        label: "linkPhone",
        type: "Phone",
    },
    linkEmail: {
        label: "linkEmail",
        type: "Email",
    },
    imageLibrary: {
        label: "imageLibrary",
        type: "Image",
    },
    documentLibrary: {
        label: "documentLibrary",
        type: "Document",
    },
};

const EditSection = ({
                         sectionToEdit,
                         resetCustomSection,
                         onChangeURLLink,
                         selectedOrg,
                         handleImageInsert,
                         handleDocumentInsert,
                     }) => {
    const [urlLink, setUrlLink] = useState(null);
    const [emailLink, setEmailLink] = useState(null);
    const [telPhoneLink, setTelPhoneLink] = useState(null);

    function verifyPhoneNo() {
        let warnings = [];
       // const warnings = .isPhoneNoValid(telPhoneLink);
        if (warnings.length > 0) {
            const message = warnings.join(" \n");
            Alert.warning(message);
            return false;
        }
        return true;
    }

    function subPhoneNo(e) {
        e.preventDefault();
        if (!verifyPhoneNo()) return;
        onChangeURLLink(`tel:${telPhoneLink}`);
    }

    function verifyURLLink() {
        let warnings = [];
        //const warnings = helpers.isLinkSomewhatValid(urlLink);
        if (warnings.length > 0) {
            const message = warnings.join(" \n");
            Alert.warning(message);
            return false;
        }
        return true;
    }

    function subLink(e) {
        e.preventDefault();
        if (!verifyURLLink()) return;
        onChangeURLLink(urlLink);
    }

    function verifyEmailLink() {
        let warnings = [];
        //const warnings = helpers.isEmailSomewhatValid(emailLink);
        if (warnings.length > 0) {
            const message = warnings.join(" \n");
            Alert.warning(message);
            return false;
        }
        return true;
    }

    function subEmail(e) {
        e.preventDefault();
        if (!verifyEmailLink()) return;
        onChangeURLLink(`mailto:${emailLink}`);
    }

    function onCancel() {
        setEmailLink(null);
        setTelPhoneLink(null);
        setUrlLink(null);
        resetCustomSection();
    }

    const activeSection = () => {
        switch (sectionToEdit) {
            case editSectionTypes.linkPhone.label:
                return (
                    <Row className="py-2">
                        <Col xs={12} className="pr-2">
                            <InputGroup className="widthMaxPx275">
                                    <InputGroupText className="px-1">
                                        <FontAwesomeIcon icon="phone" className="mr-1" />#
                                    </InputGroupText>
                                <Input
                                    maxLength={13}
                                    className="form-control widthMinPx150 px-1"
                                    value={telPhoneLink || ""}
                                    placeholder="i.e. (555)555-5555"
                                    onChange={(e) => setTelPhoneLink(e.target.value)}
                                />
                                    <ButtonGroup>
                                        <Button
                                            onClick={onCancel}
                                            color="secondary"
                                            className="px-1 widthMinPx25"
                                        >
                                            <FontAwesomeIcon icon="times" />
                                        </Button>
                                        <Button
                                            onClick={subPhoneNo}
                                            color="success"
                                            className="px-1"
                                        >
                                            <FontAwesomeIcon icon="check" />
                                        </Button>
                                    </ButtonGroup>
                            </InputGroup>
                        </Col>
                    </Row>
                );
            case editSectionTypes.linkURL.label:
                return (
                    <Row className="py-2">
                        <Col xs={12} className="pr-2">
                            <InputGroup className="widthMaxPx550">
                                    <InputGroupText className="px-1">URL</InputGroupText>
                                <Input
                                    maxLength={2083}
                                    className="form-control widthMinPx225 widthMaxPx275 px-1"
                                    value={urlLink || ""}
                                    placeholder="i.e. https://carefreerentals.com"
                                    onChange={(e) => setUrlLink(e.target.value)}
                                />
                                    <ButtonGroup>
                                        <Button
                                            onClick={onCancel}
                                            color="secondary"
                                            className="px-1 widthMinPx25"
                                        >
                                            <FontAwesomeIcon icon="times" />
                                        </Button>
                                        <Button onClick={subLink} color="success" className="px-1">
                                            <FontAwesomeIcon icon="check" />
                                        </Button>
                                    </ButtonGroup>
                            </InputGroup>
                        </Col>
                    </Row>
                );
            case editSectionTypes.linkEmail.label:
                return (
                    <Row className="py-2">
                        <Col xs={12} className="pr-2">
                            <InputGroup className="widthMaxPx550">
                                    <InputGroupText className="px-1">Email</InputGroupText>
                                <Input
                                    maxLength={320}
                                    className="form-control widthMinPx225 widthMaxPx275 px-1"
                                    value={emailLink || ""}
                                    placeholder={`i.e. ${constants.EXAMPLE_EMAIL}`}
                                    onChange={(e) => setEmailLink(e.target.value)}
                                />
                                    <ButtonGroup>
                                        <Button
                                            onClick={onCancel}
                                            color="secondary"
                                            className="px-1 widthMinPx25"
                                        >
                                            <FontAwesomeIcon icon="times" />
                                        </Button>
                                        <Button onClick={subEmail} color="success" className="px-1">
                                            <FontAwesomeIcon icon="check" />
                                        </Button>
                                    </ButtonGroup>
                            </InputGroup>
                        </Col>
                    </Row>
                );
            //   case editSectionTypes.imageLibrary.label:
            //     return (
            //       <ImageLibrary
            //         showInsertOptions={true}
            //         selectedOrg={selectedOrg}
            //         handleImageInsert={handleImageInsert}
            //       />
            //     );
            //   case editSectionTypes.documentLibrary.label:
            //     return (
            //       <DocumentLibrary
            //         showInsertOptions={true}
            //         selectedOrg={selectedOrg}
            //         handleDocumentInsert={handleDocumentInsert}
            //       />
            //     );
            default:
                return Alert.warning("Unknown Content Type");
        }
    };
    return (
        <div id="editSection" className="px-2">
            {activeSection()}
        </div>
    );
};

export default function ContentEditor(props) {
    const [customHTMLOn, setCustomHTMLOn] = useState(false);
    const [showEditSection, setShowEditSection] = useState(false);
    // const [externalQuillRef, setExternalQuillRef] = useState(null);
    const [editSectionFlavor, setEditSectionFlavor] = useState(null);
    const [toolbarId, setToolbarId] = useState(`qtoolbar`);
    // const [currentFocusPointForImages, setCurrentFocusPointForImages] =
    //   useState(0);
    const [formFillSelection, setFormFillSelection] = useState(null);
    // const [selectedSurveyLink, setSelectedSurveyLink] = useState(null);
    // const surveyURLSelected =
    //   props.zwellChecks &&
    //   props.zwellChecks.length &&
    //   formFillSelection === constants.TEXT_TEMPLATE_FORM_FILLS[3];

    useEffect(() => {
        setToolbarId(
            `qtoolbar${props.extraContainerId 
                ? props.extraContainerId 
                : ""}`
        );
    }, [props.extraContainerId]);

    const externalQuillRef = useRef(null);

    // function toggleCustomHTML() {
    //   setCustomHTMLOn(!customHTMLOn);
    // }

    function resetCustomSection() {
        setShowEditSection(null);
        setEditSectionFlavor(null);
    }

    useEffect(() => {
        if (editSectionFlavor) {
            setShowEditSection(true);
        } else {
            setShowEditSection(false);
        }
    }, [editSectionFlavor]);

    function openLinkPopupSection(sectionLabel) {
        if (editSectionFlavor === sectionLabel && showEditSection) {
            resetCustomSection();
            return;
        }
        setEditSectionFlavor(sectionLabel);
    }

    function handleTextAreaChange({ target: { value } }) {
        props.onChange(value);
    }

    function noTextSelected(type) {
        Alert.warning(
            `Please highlight the text you would like to turn into a ${type} link`
        );
    }

    function onEditURL(urlLink) {
        if (externalQuillRef.current) {
            var range = externalQuillRef.current.getEditor().getSelection();
            if (!range || range.length === 0) {
                noTextSelected(editSectionTypes.linkURL.type);
                return;
            }
            if (!(_.startsWith(urlLink, `https://`) || _.startsWith(urlLink, `http://`))) {
                Alert.warning("Please include either 'https://' or 'http://' at the beginning of your URL.");
                return;
            }
            addLinkToEditor(range, urlLink);
        }
    }

    // function onInsertField() {
    //   const { latestCursorPoint, content } = props
    //   const templateLength = content?.length;
    //   const prefix =
    //     latestCursorPoint > 0 ? props.content.substr(0, latestCursorPoint) : "";
    //   const newTemplate = `${prefix}${
    //     formFillSelection.label
    //   }${props.content.substring(latestCursorPoint)}`;
    //   props.onChange(newTemplate);
    //   setFormFillSelection(null);
    // }

    // function onInsertSurveyField() {
    //   const { latestCursorPoint, content } = props;
    //   const templateLength = content?.length;
    //   const prefix =
    //     latestCursorPoint > 0 ? props.content.substr(0, latestCursorPoint) : "";
    //   const newTemplate = `${prefix}${
    //     formFillSelection.label
    //   }/${selectedSurveyLink.urlSlug}${props.content.substring(latestCursorPoint)}`;
    //   props.onChange(newTemplate);
    //   setFormFillSelection(null);
    // }

    function addLinkToEditor(range, href, downloadName) {
        const originalDelta = externalQuillRef.current.editor.getContents(
            range.index,
            range.length
        );
        const selection =
            // this is in case they select a space in the beginning after editing the link once before
            originalDelta.ops &&
            originalDelta.ops.length &&
            originalDelta.ops.length > 1
                ? originalDelta.ops[1]
                : originalDelta.ops[0];
        const isImage =
            selection && selection.insert && selection.insert.image ? true : false;
        let text = isImage
            ? null
            : externalQuillRef.current.editor.getText(range.index, range.length);
        const attributes = selection.attributes ? selection.attributes : {};
        const linkObj = Object.assign(attributes, { link: href });
        const needsEndingSpace = _.endsWith(text, " ");
        text = needsEndingSpace ? _.trimEnd(text, " ") : text;
        const needsStartingSpace = _.startsWith(text, " ");
        text = needsStartingSpace ? _.trimStart(text, " ") : text;
        const newItem = {
            insert: text ? text : selection.insert,
            attributes: linkObj,
        };
        if (downloadName) {
            newItem.attributes.download = downloadName;
        }
        if (text) {
            let newDelta =
                range.index === 0
                    ? {
                        ops: [Object.assign({ retain: range.index }, newItem)],
                    }
                    : { ops: [{ retain: range.index }, newItem] };
            if (needsStartingSpace) {
                let list = newDelta.ops.slice();
                const originalInsert = list[1];
                list[1] = { insert: " " };
                list[2] = originalInsert;
                newDelta.ops = list;
            }
            const deleteLength = needsEndingSpace ? range.length - 1 : range.length;
            externalQuillRef.current.editor.deleteText(range.index, deleteLength);
            externalQuillRef.current.editor.updateContents(newDelta);
        }
        resetCustomSection();
    }

    function handleEditorChanges(e) {
        props.onChange(e)
        if (props.setLatestCursorPoint) {
            var range = externalQuillRef?.current?.getEditor()?.getSelection();
            const latestCursorPoint = range && range.index ? range.index : 0;
            props.setLatestCursorPoint(latestCursorPoint)
        }
    }

    return (
        <Row className="m-0 p-0">
            <Col className='g-0 m-0 p-0'>
                {props.label || props.togglePreview
                    ? (<Row className="mt-2 mx-0">
                        <Col xs={6} className={'w-100'}>
                            <label>{props.label}</label>
                        </Col>
                        <Col xs={6}>
                            <Row>
                                {props.togglePreview ? (
                                    <Col xs={6} className="pl-1">
                                        <Button
                                            onClick={props.togglePreview}
                                            size="sm"
                                            className="projectPrimary"
                                        >
                                            <VscOpenPreview className="mr-1" />
                                            Preview
                                        </Button>
                                    </Col>
                                ) : null}
                                <Col xs={props.togglePreview ? 6 : 12} className="pr-0">
                                    <ButtonGroup className="float-right" size="sm">
                                    </ButtonGroup>
                                </Col>
                            </Row>
                        </Col>
                    </Row>)
                    : null
                }
                {customHTMLOn ? (
                    <textarea
                        style={{ width: "100%", borderColor: "#ccc", height: '300px'}}
                        value={props.content}
                        onChange={handleTextAreaChange}
                    />
                ) : (
                    <div className="text-editor w-100">
                        {/* <div id={toolbarId}> 
                            <select
                                className="ql-size"
                                defaultValue={""}
                                onChange={(e) => e.persist()}>
                                <option value="1" />
                                <option value="2" />
                                <option selected />
                            </select>
                            <button className="ql-bold" />
                             <button classname="ql-italic" />
                            <button className="ql-underline" />
                            <select className="ql-color" >
                                <option value="red" />
                                <option value="green" />
                                <option value="blue" />
                                <option value="orange" />
                                <option value="violet" />
                                <option value="#d0d1d2" />
                                <option selected />
                            </select>
                            <select className="ql-background ql-picker ql-colorpicker" />
                            <select className="ql-align" />
                            <button className="ql-list" value="ordered" />
                            <button className="ql-list" value="bullet" /> 
*/}
                            {/* <button
                                    className="ql-openURLLinkPopup"
                                    onClick={() =>
                                    openLinkPopupSection(editSectionTypes.linkURL.label)
                                    }
                                >
                                    <span title="Select text or image and then press this to add url link">
                                    <FontAwesomeIcon icon="link" />
                                    </span>
                                </button>
                                <button
                                    className="ql-openEmailLinkPopup"
                                    onClick={() =>
                                    openLinkPopupSection(editSectionTypes.linkEmail.label)
                                    }
                                >
                                    <span title="Select text or image and then press this to add Email link">
                                    <FontAwesomeIcon icon="envelope-open" />
                                    </span>
                                </button>
                                <button
                                    className="ql-openTelephoneLinkPopup"
                                    onClick={() =>
                                    openLinkPopupSection(editSectionTypes.linkPhone.label)
                                    }
                                >
                                    <span title="Select text or image and then press this to add telephone link">
                                    <FontAwesomeIcon icon="phone-square" />
                                    </span>
                                </button> */}

                                {/* {props.hideDocInsert ? null :
                                    (<button
                                        className="ql-openImageLibraryPopup"
                                        onClick={() =>
                                            openLinkPopupSection(editSectionTypes.imageLibrary.label)
                                        }
                                    >
                                        <span title="Click to add new image or insert one from the library">
                                            <FontAwesomeIcon icon="images" />
                                        </span>
                                    </button>
                                )}
                                {props.hideImageInsert ? null : (
                                    <button
                                        className="ql-openDocumentLibraryPopup"
                                        onClick={() =>
                                            openLinkPopupSection(editSectionTypes.documentLibrary.label)
                                        }
                                    >
                                        <span title="Click to add new document or insert one from the library">
                                            <FontAwesomeIcon icon="file-pdf" />
                                        </span>
                                    </button>
                                )}
                        </div> */}
                            {showEditSection && editSectionFlavor ? (
                                <EditSection
                                    sectionToEdit={editSectionFlavor}
                                    resetCustomSection={resetCustomSection}
                                    onChangeURLLink={onEditURL}
                                    selectedOrg={props.selectedOrg}
                                    // handleImageInsert={handleImageInsert}
                                    // handleDocumentInsert={handleDocumentInsert}
                                />
                            ) : null}
                        <ReactQuill
                            theme="snow"
                            value={props.content || ""}
                            onChange={handleEditorChanges}
                            style={{height: '200px'}}
                            modules={{
                                // toolbar: {
                                //     container: `#${toolbarId}`
                                // },
                                clipboard: {
                                    matchVisual: false,
                                },
                            }}
                            // // ref={(ex) => {
                            // //   setExternalQuillRef(ex);
                            // // }}
                            ref={externalQuillRef}
                            formats={[
                                "header",
                                "font",
                                "size",
                                "bold",
                                "italic",
                                "underline",
                                "strike",
                                "blockquote",
                                "indent",
                                "list",
                                "bullet",
                                "link",
                                "image",
                                "color",
                                "align",
                                "ql-image-sm",
                                "ql-image-md",
                                "ql-image-lg",
                                "alt",
                                "src",
                                "class",
                                "imagewithstyle",
                                "img",
                                "nameClass",
                                "background",
                                "picker",
                                "colorpicker"
                            ]}
                        />
                    </div>
                )}
            </Col>
        </Row>
    );
}