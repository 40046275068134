import React from "react";
import _ from "lodash";
import { Col, FormGroup, Row, Input, Label } from "reactstrap";
import { constants } from "../utils";

function WLHInput({
  wValue,
    wOptions,
  lValue,
    lOptions,
  hValue,
  setWValue,
  setLValue,
  setHValue,
  wOtherTxt,
  lOtherTxt,
  hOtherTxt,
  setWOtherTxt,
  setLOtherTxt,
  setHOtherTxt,
  otherTextOnly
}) {

  return (
    <>
      {!otherTextOnly &&
          <Row className={'mb-0'}>
            <Col className={'mb-0'}>
              <FormGroup row className={'mb-0 gx-0'}>
                <Label sm={2}>W</Label>
                <Col sm={10}>
                  <Input
                      defaultValue={'- L -'}
                    bsSize="sm"
                    type="select"
                    value={wValue}
                    disabled={!wOptions.length}
                    onChange={(e) => setWValue(e.target.value)}
                  >
                    <option value='' disabled> - W - </option>
                    {_.map(wOptions, (x) => (
                      <option key={x.value} value={x.value}>
                        {x.label}
                      </option>
                    ))}
                  </Input>
                </Col>
              </FormGroup>
            </Col>
            <Col>
              <FormGroup row>
                <Label sm={2}>L</Label>
                <Col sm={10}>
                  <Input
                    bsSize="sm"
                    type="select"
                    value={lValue}
                    disabled={!lOptions.length}
                    onChange={(e) => setLValue(e.target.value)}
                  >
                    {lOptions.length  ? null : <option value='' disabled>' - L - '</option>}
                    {_.map(lOptions, (x) => (
                      <option key={x.value} value={x.value}>
                        {x.label}
                      </option>
                    ))}
                  </Input>
                </Col>
              </FormGroup>
            </Col>
          </Row>
      }
      {wValue === 0 || wValue === "0"  || otherTextOnly? (
        <Row>
          <FormGroup>
            <Label>Width</Label>
            <Input
              type="text"
              bsSize="sm"
              value={wOtherTxt}
              onChange={(e) => setWOtherTxt(e.target.value)}
            />
          </FormGroup>
        </Row>
      ) : null}
      {lValue === 0 || lValue === "0"  || otherTextOnly ? (
        <Row>
          <FormGroup className="mb-1">
            <Label>Length</Label>
            <Input
              type="text"
              bsSize="sm"
              value={lOtherTxt}
              onChange={(e) => setLOtherTxt(e.target.value)}
            />
          </FormGroup>
        </Row>
      ) : null}
      {hValue === 0 || hValue === "0" || otherTextOnly ? (
        <Row>
          <FormGroup>
            <Label>Height</Label>
            <Input
              type="text"
              bsSize="sm"
              value={hOtherTxt}
              onChange={(e) => setHOtherTxt(e.target.value)}
              placeholder="Optional"
            />
          </FormGroup>
        </Row>
      ) : null}
    </>
  );
}

export default WLHInput;
