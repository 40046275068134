function addOnsReducer(state, { type, payload }) {
  switch (type) {
    case "ADD_ADD_ON":
      return {
        ...state,
        selectedAddOns: [...state.selectedAddOns, payload],
      };
    case "REMOVE_ADD_ON":
      return {
        ...state,
        selectedAddOns: state.selectedAddOns.filter(
          (a) => a.domId !== payload.domId
        ),
      };
    case "REMOVE_LAST":
      return {
        ...state,
        selectedAddOns: [
          ...state.selectedAddOns.slice(
            0,
            state.selectedAddOns
              .map((a) => (a.addOnId === payload.addOnId ? true : false))
              .lastIndexOf(true)
          ),
          ...state.selectedAddOns.slice(
            state.selectedAddOns
              .map((a) => (a.addOnId === payload.addOnId ? true : false))
              .lastIndexOf(true) + 1
          ),
        ],
      };
    case "UPDATE_ADD_ON":
      return {
        ...state,
        selectedAddOns: [
          ...state.selectedAddOns.slice(
            0,
            state.selectedAddOns
              .map((a) => a.domId === payload.domId)
              .lastIndexOf(true)
          ),
          payload,
          ...state.selectedAddOns.slice(
            state.selectedAddOns
              .map((a) => a.domId === payload.domId)
              .lastIndexOf(true) + 1
          ),
        ],
      };
    case "HIDE_ALL":
      return {
        ...state,
        selectedAddOns: state.selectedAddOns.map((a) => ({ ...a, hide: true })),
      };
    case "SHOW_ALL":
      return {
        ...state,
        selectedAddOns: state.selectedAddOns.map((a) => ({
          ...a,
          hide: false,
        })),
      };
    default:
      return state;
  }
}

export default addOnsReducer;
