import React, {Fragment, useState, useEffect, useContext} from 'react';
import {Button} from "reactstrap";
import {Link} from "react-router-dom";
import {Header} from './';
import {constants} from '../utils';
import {ui_helpers} from "../helpers";
import {UserContext} from "../utils";
const PATHNAMES = constants.PATH_NAMES;

export default function AdminHeader(props) {
  document.title = `Admin - ${constants.appName}`;

  const {currentUser} = useContext(UserContext);
  const isSysAdmin = ui_helpers.isSystemAdministrator(currentUser);
  const isMfgAdmin = ui_helpers.isAnyManufacturerAdministrator(currentUser);
  const isStoreAdmin = ui_helpers.isAnyDealerAdministrator(currentUser);
  return (
    <Header admin toolbar>
        <div className='d-flex justify-content-center me-0'>
            <Button tag={Link} to={PATHNAMES.ADMIN_USERS} className='cf-sidebar' active={props.users}>USERS</Button>
            <Button tag={Link} to={PATHNAMES.ADMIN_COMPANIES} className='cf-sidebar' active={props.companies}>COMPANIES</Button>
            {isSysAdmin || isMfgAdmin
              ? (<Button tag={Link} to={PATHNAMES.ADMIN_MANUFACTURERS} className='cf-sidebar' active={props.mfg}>MANUFACTURERS</Button>)
              : null
            }
            {isSysAdmin || isStoreAdmin
              ? (<Button tag={Link} to={PATHNAMES.ADMIN_STORES} className='cf-sidebar' active={props.stores}>STORES</Button>)
              : null
            }
            {isSysAdmin
              ? (<Fragment>
                  <Button tag={Link} to={PATHNAMES.ADMIN_TAXRATES} className='cf-sidebar' active={props.taxes}>TAX RATES</Button>
                  <Button tag={Link} to={PATHNAMES.ADMIN_REGIONS} className='cf-sidebar' active={props.regions}>STATES</Button>
                </Fragment>)
              : null
            }
        </div>
    </Header>
    );
}