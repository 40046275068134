import React, { useEffect, useState, useContext, Fragment } from "react";
import {Link, useParams} from "react-router-dom";
import { Input, Badge, Table, Button, ButtonGroup, Row, Col, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import classnames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Select from "react-select";
import { date_helpers, ui_helpers } from "../helpers";
import { api, constants, UserContext } from "../utils";
import { Footer, Header, Loading } from "./";
import { confirmAlert } from "react-confirm-alert";
import Alert from "react-s-alert-v3";
import _ from "lodash";

const EditReportParameters = () => {
  const {reportId} = useParams();
  const [parameterList, setParameterList] = useState([]);
  const [types, setTypes] = useState([]);
  const [editParameter, setEditParameter] = useState(null);
  const [reportName, setReportName] = useState('');
  const {currentUser} = useContext(UserContext);

  useEffect(loadList, []);

  function loadList() {
    api.fetch('Resources/EditParameters/' + reportId).then(res => {
      setParameterList(_.sortBy(res.data.parameters, x => x.seq));
      setReportName(res.data.reportName);
      setTypes(ui_helpers.idNameToValueLabel(res.data.parameterTypes, null, false));
    }).catch((e) => console.error(e));
  }

  function onDelete(r) {
    confirmAlert({
      title: "Confirm Deletion",
      message: `Are you sure you want to permanently delete the ${r.prompt} parameter?`,
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            api.post('Resources/DeleteParameter', { id: r.reportParameterId }).then(res => {
              if (res.data.success) {
                loadList();
              } else {
                Alert.error(res.data.message);
              }
            }).catch((e) => console.error(e));
          }
        },
        {
          label: "No"
        }
      ]
    });    
  }

  function onSave() {
    api.post('Resources/SaveParameter', {
      ...editParameter,
      id: editParameter.reportParameterId,
      reportParameterTypeId: parseInt(editParameter.typeOfReportParameter),
      reportId: reportId
    }).then(res => {
      if (res.data.success) {
        setEditParameter(null);
        loadList();
      } else {
        Alert.error(res.data.message);
      }
    }).catch((e) => console.error(e));
  }

  function onChange(fieldName, value) {
    setEditParameter({
      ...editParameter,
      [fieldName]: value
    });
  }

  return (
    <div>
      <Header />
      {editParameter
        ? (<Modal toggle={() => setEditParameter(null)} isOpen>
            <ModalHeader>
              Edit Parameter
            </ModalHeader>
            <ModalBody>
              <Row>
                <Col>Seq
                </Col>
                <Col>
                  <Input type='number' value={editParameter.seq} 
                    onChange={(e) => onChange('seq', e.target.value)} />
                </Col>
              </Row>
              <Row>
                <Col>Type</Col>
                <Col>
                  <Select
                    options={types}
                    value={_.find(types, g => g.value === editParameter.typeOfReportParameter)}
                    onChange={(newOption) => onChange('typeOfReportParameter', newOption.value)}
                  />
                </Col>
              </Row>
              <Row>
                <Col>Prompt</Col>
                <Col>
                  <Input type='text' maxLength="100" value={editParameter.prompt} 
                    onChange={(e) => onChange('prompt', e.target.value)} />
                </Col>
              </Row>
              <Row className="pb-2">
                <Col>
                  <Button onClick={() => onChange('isOptional', !editParameter.isOptional)} 
                    className={classnames({"btn-success": editParameter.isOptional}, "me-2 text-light")}>
                    Optional
                  </Button>
                  <Button onClick={() => onChange('isHidden', !editParameter.isHidden)} 
                    className={classnames({"btn-success": editParameter.isHidden}, "me-2 text-light")}>
                    Hidden
                  </Button>
                </Col>
              </Row>
            </ModalBody>
            <ModalFooter>
              <Row className={'d-flex justify-content-end'}>
                <Col>
                  <ButtonGroup>
                    <Button color='primary' onClick={onSave}>Save</Button>
                    <Button onClick={() => setEditParameter(null)}>Cancel</Button>
                  </ButtonGroup>
                </Col>
              </Row>
            </ModalFooter>
          </Modal>)
        : null
      }
      <div className="p-2">
      <Row>
        <Col xs="8">
          <h3 className="my-3">
            {reportName} Parameters
            <Badge color="dark" className="ms-2" pill>
              {parameterList.length}
            </Badge>
          </h3>
        </Col>
        <Col xs="4">
          <ButtonGroup className="float-end">
            <Button color="dark" tag={Link} to="/Resources/ReportList">
              Back
            </Button>
            <Button color="success" className="text-light" title="Add Report" onClick={() => setEditParameter({
              id: 0,
              typeOfReportParameter: types[0].value,
              isOptional: true,
              isHidden: false
            })}>
              <FontAwesomeIcon icon='plus' /> Parameter
            </Button>
          </ButtonGroup>
        </Col>
      </Row>
      <Table>
      <thead>
        <tr>
          <th style={{width: "60px"}}>Seq</th>
          <th>Prompt</th>
          <th>Parameter Type</th>
          <th>Optional</th>
          <th>Hidden</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
      {_.map(parameterList, x => (
        <tr key={`r-${x.id}`}>
          <td>#{x.seq}</td>
          <td>
            <span style={{fontWeight: "bold"}}>{x.prompt}</span>
          </td>
          <td>{x.reportParameterTypeName}</td>
          <td>{x.isOptional ? "Y" : "N"}</td>
          <td>{x.isHidden ? "Y" : "N"}</td>
          <td className="pt-0">
            <ButtonGroup className="float-end tiny">
              <Button color="primary" onClick={() => setEditParameter(x)}>Edit</Button>
              <Button color="danger" className="text-light" onClick={() => onDelete(x)}>Delete</Button>
            </ButtonGroup>
          </td>
        </tr>))}
      </tbody>
      </Table>
    </div>
      <Footer />
    </div>
  );
};

export default EditReportParameters;
